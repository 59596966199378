import React from "react";
import { PraTable } from "../portfolioAnalysis";
import ReportInputs from "./reportInputs";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const WaitingScreen = () => {
  return (
    <div className="loading-progress">
      <Typography align="center">Loading hedge fund list</Typography>
      <LinearProgress />
    </div>
  );
};

export default function ReportContainer({
  work_ready,
  loading,
  loaded,
  ...props
}) {
  return (
    <>
      {!work_ready && <WaitingScreen />}
      {work_ready && <ReportInputs {...props} />}
      {loading && (
        <div className="loading-progress">
          <LinearProgress />
        </div>
      )}
      {loaded && (
        <div className="analysis">
          <PraTable {...props} />
        </div>
      )}
    </>
  );
}
