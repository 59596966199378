import {
  get, post, del, patch,
} from './api'

export const getPortfolioList = (payload) => get('/api/v1/portfolio/', payload)
export const getWfPortfolioList = (payload) => get('/api/v1/webfolio-portfolio/')

export const getSinglePortfolio = (payload) => get(`/api/v1/portfolio/${payload.id}/`)
export const updatePortfolio = (payload) => post('/api/v1/portfolio/', payload)
export const deletePortfolio = (payload) => del('/api/v1/portfolio/', payload)
export const getPortfolioTypes = (payload) => get('api/v1/portfolio-type/', payload)

export const getWebfolioWeights = (payload) => get('/api/v1/webfolio-weights/')

export const generatePortfolioFactSheet = (id) => get(`/api/v1/portfolio/${id}/factsheet/`, null, { responseType: 'blob' })

export const getPortfolioOptimiser = (archived = false) => get(`/api/v1/portfolio-optimiser/?archived=${archived}`)
export const getPortfolioOptimiserRun = (runId) => get(`/api/v1/portfolio-optimiser/${runId}`)
export const createPortfolioOptimiserRun = (payload) => post('/api/v1/portfolio-optimiser/new-run/', payload)
export const updatePortfolioOptimiserRun = (runId, payload) => patch(`/api/v1/portfolio-optimiser/${runId}/`, payload)
