import { Box, Typography } from '@material-ui/core'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import SkelatonTextLoader from '../../components/skeletons/SkelatonTextLoader'
import {
  HUBSPOT_BLOG_POST_ENDPOINT,
  HUBSPOT_PROXY_URL,
} from '../../constants/env'
import { useSecurities } from '../../hooks'
import useAccessRights from '../../hooks/useAccessRights'
import { useMixpanel } from '../../hooks/useMixpanel'
import { convertSnakeToCamelCase } from '../../utils/convertSnakeToCamelCase'
import { isEmail } from '../../utils/emailUtils'

export const enum HighlightType {
  HEDGE_FUND = 'hedge-fund',
  PRIVATE_EQUITY = 'private-equity',
}

const highlightTypeLinkMap = {
  [HighlightType.HEDGE_FUND]: '/asset-detail',
  [HighlightType.PRIVATE_EQUITY]: '/private-markets/funds',
}

interface HighlightsCardProps {
  type: HighlightType
}

interface Highlight {
  createdAt: Date
  draft: boolean
  link?: string
  message: string
  expiredAt?: Date
  links: string[]
  isOpenInNewTab?: boolean
  isSingleLink: boolean
}

const HighlightsCard: React.FC<HighlightsCardProps> = ({ type }) => {
  const { aaap_cell_list_map, aaap_classes, loadingStates, search_list_map } =
    useSecurities()
  const { mpTrack } = useMixpanel()
  const { hasPrivateEquityAccess } = useAccessRights()
  const { data: hubSpotHighlightsPayload, isLoading } = useQuery(
    ['highlights', type],
    () =>
      axios.post(HUBSPOT_PROXY_URL, {
        method: 'GET',
        url: `${HUBSPOT_BLOG_POST_ENDPOINT}?slug=hub-highlights/${type}&state=PUBLISHED`,
      }),
    {
      enabled: !!type,
      staleTime: Infinity,
      select: (res) => convertSnakeToCamelCase(res?.data?.results?.[0] || {}),
    },
  )
  const highlights: Highlight[] | null =
    hubSpotHighlightsPayload?.widgets?.highlights?.body?.highlight
  const filteredHighlights = useMemo(() => {
    if (!aaap_classes || !highlights) {
      return []
    }

    const now = new Date().getTime()

    const isNotExpired = (expiredAt?: Date) =>
      !expiredAt || new Date(expiredAt).getTime() > now
    const findFundIdMatched = (links?: string[]) =>
      links?.find((lk) => search_list_map[lk])
    const shouldOpenInNewTab = (link?: string) =>
      (!link?.includes(window.location.origin) && link?.startsWith('http')) ||
      isEmail(link)
    const getFundId = (url?: string) => {
      const regex = /(\d+)(?!.*\d)/
      const matches = url?.match(regex)
      return matches?.[0]
    }
    const shouldFilterOutPeRelated = (link?: string) => {
      if (type === HighlightType.PRIVATE_EQUITY) {
        return true
      }

      if (!hasPrivateEquityAccess && link?.includes('private-markets')) {
        return false
      }

      return true
    }

    return highlights
      .filter(
        ({ draft, expiredAt, link }) =>
          !draft && isNotExpired(expiredAt) && shouldFilterOutPeRelated(link),
      )
      .map(({ isSingleLink, link, links, ...rest }) => {
        let updatedLink = link

        if (!isSingleLink && links?.length > 0) {
          const fundIdMatched = findFundIdMatched(links)
          if (fundIdMatched) {
            updatedLink = `${highlightTypeLinkMap[type]}/${fundIdMatched}`
          }
        }

        if (isSingleLink) {
          const fundId = getFundId(updatedLink)
          if (fundId) {
            updatedLink = findFundIdMatched([fundId])
              ? `${highlightTypeLinkMap[type]}/${fundId}`
              : undefined
          }
        }

        return {
          ...rest,
          isOpenInNewTab: shouldOpenInNewTab(updatedLink),
          isSingleLink,
          link: updatedLink,
          links,
        }
      })
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )
  }, [
    highlights,
    aaap_cell_list_map,
    aaap_classes,
    search_list_map,
    hasPrivateEquityAccess,
    type,
  ])
  const isLoadingHighlightsOrDependencies =
    isLoading || loadingStates.aaap_cell_list
  const clickHighlightItemHandler =
    ({ link, message }: Partial<Highlight>) =>
    () => {
      mpTrack({
        eventName: 'Click Highlight Item',
        properties: {
          'Click Type': link ? 'Link' : 'Text',
          Message: message,
          'Route Type': type,
          Type: 'Click Highlight Item',
        },
      })
    }

  return (
    <Box className="highlights-pane">
      <Box className="highlights-header-wrapper" px={3.5} py={2}>
        <Typography component="h6" variant="h6">
          Highlights
        </Typography>
      </Box>
      <Box className="highlights-items-wrapper">
        {isLoadingHighlightsOrDependencies || filteredHighlights?.length == 0
          ? Array(4)
              .fill(null)
              .map((_, index) => (
                <Box
                  key={index}
                  className={clsx('highlights-item')}
                  style={{ padding: '16px 28px' }}
                >
                  <SkelatonTextLoader my={0} rows={2} />
                </Box>
              ))
          : filteredHighlights?.length > 0
          ? filteredHighlights.map(({ isOpenInNewTab, link, message }, idx) => (
              <Box
                className={clsx('highlights-item', {
                  'highlights-item-link': link,
                })}
                component={link ? Link : 'div'}
                display="flex"
                key={`highlight-${idx}`}
                onClick={clickHighlightItemHandler({ link, message })}
                px={3.5}
                py={2}
                target={isOpenInNewTab ? '_blank' : undefined}
                to={isOpenInNewTab ? { pathname: link } : link}
              >
                <Typography variant="body1">{message}</Typography>
              </Box>
            ))
          : null}
      </Box>
    </Box>
  )
}

export default HighlightsCard
