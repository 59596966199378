import { useContext } from 'react'
import { FundsContext } from '../contexts'

export function useSecurities() {
  const context = useContext(FundsContext)
  if (context === undefined) {
    throw new Error('useSecurities must be used within a FundsContextProvider')
  }
  return context
}
