import { Card, CardContent } from '@material-ui/core'
import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const StyledMediaSection = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const StyledMedia = styled.div`
    width: 100%;
    color: #134466;
    
    & h3 {
        padding-left: 0.7rem;
    }
`

const StyledNoMedia = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`


const MediaTab = ({ mediaData }) => {
    const renderMedia = (mediaData) => {
        if (Object.values(mediaData).length > 0) {
            return Object.values(mediaData).map((media) => {
                return (
                    <StyledMedia>
                        <h3>{media.name}</h3>
                        <ReactPlayer url={media.file}
                            width="400px"
                            height="50px"
                            playing={false}
                            controls={true}
                        />
                    </StyledMedia>
                )
            })
        } else {
            return (
                <StyledNoMedia>
                    <span>
                        There is no media for this fund.
                    </span>
                </StyledNoMedia>
            )
        }
    }

    return (
        <Card>
            <CardContent>
                <StyledMediaSection>
                    {renderMedia(mediaData)}
                </StyledMediaSection>
            </CardContent>
        </Card>
    )
}

export default MediaTab
