function isStringArray(arr: any[]): arr is string[] {
  if (!Array.isArray(arr)) {
    return false
  }

  if (arr.length === 0) {
    return true
  }

  return arr.every(item => typeof item === 'string')
}

export default isStringArray