export const current_cash_button_list = [
  { id: "percentage", title: "%" },
  { id: "usd", title: "USD" },
];

export const projected_cash_button_list = [
  { id: "interest", title: "%" },
  { id: "projected_usd", title: "USD" },
];

export const single_current_cash_percentage = {
  index: "LMR",
  November: {
    value: "-0.12%",
    account_values: {
      trading: "85%",
      investor_account: "9%",
      holdback: "4%",
      ops_account: "3%",
    },
  },
  December: {
    value: "-0.16%",
    account_values: {
      trading: "0.085%",
      investor_account: "0.09%",
      holdback: "0.04%",
      ops_account: "0.03%",
    },
  },
  January: {
    value: "-0.55%",
    account_values: {
      trading: "85%",
      investor_account: "9%",
      holdback: "4%",
      ops_account: "3%",
    },
  },
  February: {
    value: "1.56%",
    account_values: {
      trading: "85%",
      investor_account: "9%",
      holdback: "4%",
      ops_account: "3%",
    },
  },
  March: {
    value: "4%",
    account_values: {
      trading: "85%",
      investor_account: "9%",
      holdback: "4%",
      ops_account: "3%",
    },
  },
  April: {
    value: "8%",
    account_values: {
      trading: "85%",
      investor_account: "9%",
      holdback: "4%",
      ops_account: "3%",
    },
  },
};

// export const mapDataRowToValue = (row) => {
//   let newRow = { index: row.index };
//   delete row.index;
//   for (let [key, value] of Object.entries(row)) {
//     newRow[`${key}`] = value.value;
//   }
//   return newRow;
// };

export const single_current_cash_usd = {
  index: "LMR",
  November: {
    value: "78349",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
  December: {
    value: "715000",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
  January: {
    value: "715000",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
  February: {
    value: "715000",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
  March: {
    value: "715000",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
  April: {
    value: "715000",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
};

export let current_cash_usd = [];
for (let i = 0; i < 150; i++) {
  current_cash_usd.push(single_current_cash_usd);
}

export let current_cash_percentage = [];
for (let i = 0; i < 150; i++) {
  current_cash_percentage.push(single_current_cash_percentage);
}

export const single_projected_cash_usd = {
  index: "LMR",
  November: {
    value: "78349",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
  December: {
    value: "715000",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
  January: {
    value: "715000",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
  February: {
    value: "715000",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
  March: {
    value: "715000",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
  April: {
    value: "715000",
    account_values: {
      trading: "205000",
      investor_account: "450000",
      holdback: "50000",
      ops_account: "20000",
    },
  },
};

export let projected_cash_usd = [];
for (let i = 0; i < 150; i++) {
  projected_cash_usd.push(single_projected_cash_usd);
}

export const single_projected_cash_percentage = {
  index: "LMR",
  November: {
    value: "-0.12%",
    account_values: {
      trading: "85%",
      investor_account: "9%",
      holdback: "4%",
      ops_account: "3%",
    },
  },
  December: {
    value: "-0.16%",
    account_values: {
      trading: "0.085%",
      investor_account: "0.09%",
      holdback: "0.04%",
      ops_account: "0.03%",
    },
  },
  January: {
    value: "-0.55%",
    account_values: {
      trading: "85%",
      investor_account: "9%",
      holdback: "4%",
      ops_account: "3%",
    },
  },
  February: {
    value: "1.56%",
    account_values: {
      trading: "85%",
      investor_account: "9%",
      holdback: "4%",
      ops_account: "3%",
    },
  },
  March: {
    value: "4%",
    account_values: {
      trading: "85%",
      investor_account: "9%",
      holdback: "4%",
      ops_account: "3%",
    },
  },
  April: {
    value: "8%",
    account_values: {
      trading: "85%",
      investor_account: "9%",
      holdback: "4%",
      ops_account: "3%",
    },
  },
};

export let projected_cash_percentage = [];
for (let i = 0; i < 150; i++) {
  projected_cash_percentage.push(single_projected_cash_percentage);
}
