import React from "react";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { useField } from "formik";
import ErrorMessage from "./ErrorMessage";

const CheckBoxInput = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;

  return (
    <>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={(e) => {
                setValue(e.target.checked);
              }}
              name={name}
              color="primary"
              {...props}
            />
          }
          label={label}
        />
      </FormGroup>
      {meta.touched && meta.error && <ErrorMessage error={meta.error} />}
    </>
  );
};

export default CheckBoxInput;
