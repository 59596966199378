import { Box } from '@material-ui/core'
import React from 'react'

const ChipContainer = ({
  children,
  ...boxProps
}) => (
  <Box
    display="flex"
    flexWrap="wrap"
    gridGap={4}
    {...boxProps}
  >
    {children}
  </Box>
)

export default ChipContainer
