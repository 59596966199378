import { Box } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import React, { useState } from "react"

import GlobalFundSelector from "../../components/forms/GlobalFundSelector"
import { useSecurities } from "../../hooks"
import { updateRelatedSecurities } from "../../services"
import { getSecurityDetails } from "../../services/helpers"
import ReportSnippets from "../../utils/reportInputSnippets"
import NewFundForm from "./newFundForm"
import isStringArray from "../../utils/isStringArray"
import { notifications } from "@mantine/notifications"

const SecuritySetup = ({
  saveLists,
  saved,
  saving,
  security_lists,
  securityData,
  setSecurityData,
  switchMode,
  updateInputs,
  ...props
}) => {
  console.log('securityData', securityData)
  const reportSnippets = new ReportSnippets({
    ...props,
    data: security_lists,
    submitFunction: saveLists,
    updateInputs: updateInputs,
  })
  const { security } = security_lists || {}
  const disabled = !!(!security || saving || saved)
  let btnText = "Save security"
  if (saving) {
    btnText = "Saving..."
  } else if (saved) {
    btnText = "Saved"
  }

  const changePeerInputHandler = (peers) => {
    console.log('peers', peers)
    // setSecurityData({...securityData})
    setSecurityData((i) => ({ ...i, peers }))
  }


  return (
    <div className="security-setup-container">
      <div className="title">
        <Typography
          align="center"
          variant="h6"
        >
          Security Setup
        </Typography>
        <Button
          className="set-up-mode-button"
          color="primary"
          disableElevation
          onClick={switchMode}
          size="small"
          startIcon={<AddIcon />}
          variant="contained"
        >
          Add new fund
        </Button>
      </div>
      {reportSnippets.globalSearch("security", "Fund")}
      {reportSnippets.globalMultiSearch("benchmarks", "Benchmarks", {
        disabled,
      })}
      <Box mb="sm">
        <GlobalFundSelector
          disabled={disabled}
          onChange={changePeerInputHandler}
          placeholder="Peers"
          showPeerGroup
          selectedFunds={securityData.peers}
        />
      </Box>
      {/* {reportSnippets.globalMultiSearch("peers", "Peers", { disabled })} */}
      {reportSnippets.globalMultiSearch("factors", "Factors", { disabled })}
      {reportSnippets.globalSearch("primary_benchmark", "Primary Benchmark", {
        disabled,
      })}
      {reportSnippets.checkBox("exposures_available", "Exposures Available", {
        disabled,
      })}
      {reportSnippets.textField("priority", "Priority", { disabled })}
      {reportSnippets.submitButton(btnText, { disabled })}
    </div>
  )
}

function FundLists({ ...props }) {
  const [securityData, setSecurityData] = useState({
    benchmarks: [],
    exposures_available: false,
    factors: [],
    peers: [],
    primary_benchmark: null,
    priority: null,
    security: "",
  })

  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)

  const { search_list: searchList } = useSecurities()

  const getDetails = async (payload) => {
    const { benchmarks, exposures_available, factors, peers, priority } =
      await getSecurityDetails(payload, searchList)
    console.log('peers', peers)
    setSecurityData((i) => ({
      ...i,
      benchmarks,
      exposures_available,
      factors,
      peers,
      priority,
    }))
  }

  const updateInputs = ({ name, value }) => {
    setSaved(false)
    setSecurityData((i) => ({ ...i, [name]: value }))
    if (name === "security" && value) {
      const { id: fund_id, type: security_type } = value || {}
      getDetails({ fund_id, security_type })
    }
  }

  const saveLists = async () => {
    setSaving(true);
    const { exposures_available, primary_benchmark, priority, security } =
      securityData || {};
    const { id } = security;
    if (!id) {
      return;
    }
    const fields = ["benchmarks", "peers", "factors"];
    let payload = { exposures_available, id, priority };
    if (priority === "") {
      payload.priority = null;
    }
    fields.forEach((element) => {
      if (securityData[element] && securityData[element].length !== 0) {
        payload[element] = isStringArray(securityData[element])
          ? securityData[element]
          : securityData[element].map((o) => o.id);
      }
    });
    if (primary_benchmark && primary_benchmark !== null) {
      payload.primary_benchmark = primary_benchmark.id;
    }
    try {
      await updateRelatedSecurities(payload);
      notifications.show({
        title: "Security Updated",
        message: "The security details have been successfully updated.",
      });
    } catch (error) {
      notifications.show({
        title: "Update Failed",
        message: error.response?.data?.detail || "An error occurred while updating the security details. Please try again.",
        color: "red",
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="funds-input">
      <SecuritySetup
        saveLists={saveLists}
        saved={saved}
        saving={saving}
        search_list={searchList}
        securityData={securityData}
        security_lists={securityData}
        setSecurityData={setSecurityData}
        updateInputs={updateInputs}
        {...props}
      />
    </div>
  )
}

export default function FundSettings() {
  const [newSecurityMode, setNewSecurityMode] = useState(false)
  const switchMode = () => setNewSecurityMode((i) => !i)
  return (
    <>
      {newSecurityMode ? (
        <NewFundForm switchMode={switchMode} />
      ) : (
        <FundLists switchMode={switchMode} />
      )}
    </>
  )
}
