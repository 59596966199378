import { Anchor, Checkbox } from "@mantine/core";
import { ColumnDef } from "@tanstack/react-table"
import { ScreenerView } from "../../redux/peerGroupsSlice";
import moment from "moment";
import { Link } from "react-router-dom";


const formatValue = (info: any, key: string, asPercentage: boolean = true) => {
  const value = info.getValue()?.[key]

  if (value === null || value === undefined) {
    return <i>NA</i>
  }

  if (asPercentage) {
    return `${(value * 100).toFixed(2)}%`
  } else {
    return value.toFixed(2)
  }
}

const statsColWidth = 100
const xlStatsColWidth = 140
const extraColWidth = 20
const currentDate = moment();

export const screenerColDef: ColumnDef<any, any>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    accessor: 'select',
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    size: 50,
    enableSorting: false,
    views: [ScreenerView.All],
  },
  {
    id: 'name',
    header: 'Name',
    accessor: 'name',
    cell: info => (
      <Anchor
        component={Link}
        size="sm"
        to={`/asset-detail/${info.row.original.id}`}
      >
        {info.getValue()}
      </Anchor>
    ),
    size: 450,
    views: [ScreenerView.Overview, ScreenerView.Performance, ScreenerView.KeyStatistics, ScreenerView.Returns, ScreenerView.WinLossAnalysis, ScreenerView.MonthlyPerformance, ScreenerView.AnnualPerformance, ScreenerView.RiskMetrics, ScreenerView.DataQuality],
  },
  {
    id: 'analyst',
    header: 'Analyst',
    accessor: 'analyst',
    cell: info => info.getValue(),
    size: 180,
    views: [ScreenerView.Overview],
  },
  {
    id: 'primaryVendor',
    header: 'Vendor',
    accessor: 'primaryVendor',
    cell: info => info.getValue(),
    size: 200,
    views: [ScreenerView.Overview],
  },
  {
    id: 'strategy',
    header: 'Strategy',
    accessor: 'strategy',
    cell: info => info.getValue(),
    size: 200,
    views: [ScreenerView.Overview],
  },
  {
    id: 'sectorFocus',
    header: 'Sector Focus',
    accessor: 'sectorFocus',
    cell: info => info.getValue(),
    size: 200,
    views: [ScreenerView.Overview],
  },
  {
    id: 'status',
    header: 'Status',
    accessor: 'status',
    cell: info => info.getValue(),
    size: 200,
    views: [ScreenerView.Overview],
  },
  {
    id: 'gate',
    header: 'Gate',
    accessor: 'gate',
    cell: info => info.getValue(),
    size: 200,
    views: [ScreenerView.Overview],
  },
  {
    id: 'redemptionFreq',
    header: 'Redemption Frequency',
    accessor: 'redemptionFreq',
    cell: info => info.getValue(),
    size: 200,
    views: [ScreenerView.Overview],
  },
  {
    id: 'redemptionNotice',
    header: 'Redemption Notice',
    accessor: 'redemptionNotice',
    cell: info => info.getValue(),
    size: 200,
    views: [ScreenerView.Overview],
  },
  {
    id: 'managementFee',
    header: 'Management Fee',
    accessor: 'managementFee',
    cell: info => info.getValue(),
    size: 200,
    views: [ScreenerView.Overview],
  },
  {
    id: 'performanceFee',
    header: 'Performance Fee',
    accessor: 'performanceFee',
    cell: info => info.getValue(),
    size: 200,
    views: [ScreenerView.Overview],
  },
  {
    id: 'geoFocus',
    header: 'Geo Focus',
    accessor: 'geoFocus',
    cell: info => info.getValue(),
    size: 200,
    views: [ScreenerView.Overview],
  },
  {
    id: 'researchStatus',
    header: 'Research Status',
    accessor: 'researchStatus',
    cell: info => info.getValue(),
    size: 200,
    views: [ScreenerView.Overview],
  },
  {
    id: 'typicalExposureRanges',
    header: 'Typical Exposure Ranges',
    accessor: 'typicalExposureRanges',
    cell: info => info.getValue(),
    size: 250,
    views: [ScreenerView.Overview],
  },
  {
    id: 'statsDate',
    header: 'Stats Date',
    accessor: 'calculatedStatistics',
    cell: info => moment(info.getValue()?.statsDate).format('DD MMM YYYY'),
    size: 150,
    views: [ScreenerView.Performance, ScreenerView.KeyStatistics],
  },
  {
    id: 'startDate',
    header: 'Start Date',
    accessor: 'calculatedStatistics',
    cell: info => moment(info.getValue()?.startDate).format('DD MMM YYYY'),
    size: 150,
    views: [ScreenerView.All, ScreenerView.DataQuality],
  },
  {
    id: 'endDate',
    header: 'End Date',
    accessor: 'calculatedStatistics',
    cell: info => moment(info.getValue()?.endDate).format('DD MMM YYYY'),
    size: 150,
    views: [ScreenerView.All, ScreenerView.DataQuality],
  },
  {
    id: 'mean',
    header: 'Mean',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'mean'),
    size: statsColWidth,
    views: [ScreenerView.KeyStatistics],
  },
  {
    id: 'stdDev',
    header: 'SD',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'stdDev'),
    size: statsColWidth,
    views: [ScreenerView.KeyStatistics],
  },
  {
    id: 'skew',
    header: 'Skew',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'skew', false),
    size: statsColWidth,
    views: [ScreenerView.KeyStatistics],
  },
  {
    id: 'kurt',
    header: 'Kurtosis',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'kurt', false),
    size: statsColWidth,
    views: [ScreenerView.KeyStatistics],
  },
  {
    id: 'ytd',
    header: 'YTD',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'ytd'),
    size: statsColWidth,
    views: [ScreenerView.Performance, ScreenerView.Returns],
  },
  {
    id: 'lastMonthRet',
    header: '1M Returns',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'lastMonthRet'),
    size: statsColWidth,
    views: [ScreenerView.Performance, ScreenerView.Returns],
  },
  {
    id: 'threeMonthRet',
    header: '3M Returns',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'threeMonthRet'),
    size: statsColWidth,
    views: [ScreenerView.Performance, ScreenerView.Returns],
  },
  {
    id: 'sixMonthRet',
    header: '6M Returns',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'sixMonthRet'),
    size: statsColWidth,
    views: [ScreenerView.Performance, ScreenerView.Returns],
  },
  {
    id: 'twelveMonthRet',
    header: '1Y Returns',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'twelveMonthRet'),
    size: statsColWidth,
    views: [ScreenerView.Performance, ScreenerView.Returns],
  },
  {
    id: 'twoYearsRet',
    header: '2Y Returns',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'twoYearsRet'),
    size: statsColWidth,
    views: [ScreenerView.Performance, ScreenerView.Returns],
  },
  {
    id: 'threeYearsRet',
    header: '3Y Returns',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'threeYearsRet'),
    size: statsColWidth,
    views: [ScreenerView.Performance, ScreenerView.Returns],
  },
  {
    id: 'fourYearsRet',
    header: '4Y Returns',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'fourYearsRet'),
    size: statsColWidth,
    views: [ScreenerView.Performance, ScreenerView.Returns],
  },
  {
    id: 'fiveYearsRet',
    header: '5Y Returns',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'fiveYearsRet'),
    size: statsColWidth,
    views: [ScreenerView.Performance, ScreenerView.Returns],
  },
  {
    id: 'annRet',
    header: 'ITD Returns',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'annRet'),
    size: statsColWidth,
    views: [ScreenerView.Performance, ScreenerView.KeyStatistics, ScreenerView.Returns],
  },
  {
    id: 'annVol',
    header: 'ITD SD',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'annVol'),
    size: statsColWidth,
    views: [ScreenerView.Performance, ScreenerView.KeyStatistics, ScreenerView.RiskMetrics],
  },
  {
    id: 'sharpe',
    header: 'Sharpe Ratio',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'sharpe', false),
    size: xlStatsColWidth,
    views: [ScreenerView.Performance, ScreenerView.KeyStatistics, ScreenerView.RiskMetrics],
  },
  {
    id: 'maxDd',
    header: 'Max Drawdown',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'maxDd'),
    size: xlStatsColWidth,
    views: [ScreenerView.Performance, ScreenerView.KeyStatistics, ScreenerView.RiskMetrics],
  },
  {
    id: 'positiveMonths',
    header: 'Positive Months',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'positiveMonths'),
    size: xlStatsColWidth,
    views: [ScreenerView.WinLossAnalysis],
  },
  {
    id: 'negativeMonths',
    header: 'Negative Months',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'negativeMonths'),
    size: xlStatsColWidth,
    views: [ScreenerView.WinLossAnalysis],
  },
  {
    id: 'winRatio',
    header: 'Win Ratio',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'winRatio', false),
    size: statsColWidth,
    views: [ScreenerView.WinLossAnalysis],
  },
  {
    id: 'avgWin',
    header: 'Avg Win',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'avgWin'),
    size: statsColWidth,
    views: [ScreenerView.WinLossAnalysis],
  },
  {
    id: 'avgLoss',
    header: 'Avg Loss',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'avgLoss'),
    size: statsColWidth,
    views: [ScreenerView.WinLossAnalysis],
  },
  {
    id: 'largestWin',
    header: 'Max Win',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'largestWin'),
    size: statsColWidth,
    views: [ScreenerView.WinLossAnalysis],
  },
  {
    id: 'largestLoss',
    header: 'Max Loss',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'largestLoss'),
    size: statsColWidth,
    views: [ScreenerView.WinLossAnalysis],
  },
  {
    id: 'gainDev',
    header: 'Gain Dev',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'gainDev'),
    size: statsColWidth,
    views: [ScreenerView.WinLossAnalysis],
  },
  {
    id: 'lossDev',
    header: 'Loss Dev',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'lossDev'),
    size: statsColWidth,
    views: [ScreenerView.WinLossAnalysis],
  },
  {
    id: 'gainToLossDev',
    header: 'Gain/Loss Dev',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'gainToLossDev', false),
    size: xlStatsColWidth,
    views: [ScreenerView.WinLossAnalysis],
  },
  {
    id: 'monthlyReturn0m',
    header: `${currentDate.format('MMM')}`,
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'monthlyReturn0m'),
    size: statsColWidth,
    views: [ScreenerView.MonthlyPerformance],
  },
  {
    id: 'monthlyReturn1m',
    header: `${currentDate.clone().subtract(1, 'month').format('MMM')}`,
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'monthlyReturn1m'),
    size: statsColWidth,
    views: [ScreenerView.MonthlyPerformance],
  },
  {
    id: 'monthlyReturn2m',
    header: `${currentDate.clone().subtract(2, 'month').format('MMM')}`,
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'monthlyReturn2m'),
    size: statsColWidth,
    views: [ScreenerView.MonthlyPerformance],
  },
  {
    id: 'monthlyReturn3m',
    header: `${currentDate.clone().subtract(3, 'month').format('MMM')}`,
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'monthlyReturn3m'),
    size: statsColWidth,
    views: [ScreenerView.MonthlyPerformance],
  },
  {
    id: 'annualReturn0y',
    header: `${currentDate.format('YYYY')}`,
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'annualReturn0y'),
    size: statsColWidth,
    views: [ScreenerView.AnnualPerformance],
  },
  {
    id: 'annualReturn1y',
    header: `${currentDate.clone().subtract(1, 'year').format('YYYY')}`,
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'annualReturn1y'),
    size: statsColWidth,
    views: [ScreenerView.AnnualPerformance],
  },
  {
    id: 'annualReturn2y',
    header: `${currentDate.clone().subtract(2, 'years').format('YYYY')}`,
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'annualReturn2y'),
    size: statsColWidth,
    views: [ScreenerView.AnnualPerformance],
  },
  {
    id: 'annualReturn3y',
    header: `${currentDate.clone().subtract(3, 'years').format('YYYY')}`,
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'annualReturn3y'),
    size: statsColWidth,
    views: [ScreenerView.AnnualPerformance],
  },
  {
    id: 'rollingVolatility12m',
    header: '12M SD',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'rollingVolatility12m'),
    size: statsColWidth,
    views: [ScreenerView.RiskMetrics],
  },
  {
    id: 'rollingVolatility36m',
    header: '36M SD',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'rollingVolatility36m'),
    size: statsColWidth,
    views: [ScreenerView.RiskMetrics],
  },
  {
    id: 'rollingVolatility60m',
    header: '60M SD',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'rollingVolatility60m'),
    size: statsColWidth,
    views: [ScreenerView.RiskMetrics],
  },
  {
    id: 'monthsOfData',
    header: 'Months of Data',
    accessor: 'calculatedStatistics',
    cell: info => info.getValue()?.monthsOfData,
    size: xlStatsColWidth,
    views: [ScreenerView.DataQuality],
  },
  {
    id: 'dataCompletenessRatio',
    header: 'Data Completeness',
    accessor: 'calculatedStatistics',
    cell: info => formatValue(info, 'dataCompletenessRatio'),
    views: [ScreenerView.DataQuality],
    size: xlStatsColWidth + extraColWidth,
  },
  {
    id: 'monthsStale',
    header: 'Months Stale',
    accessor: 'calculatedStatistics',
    cell: info => info.getValue()?.monthsStale,
    views: [ScreenerView.DataQuality],
    size: xlStatsColWidth,
  },
]