import "./styles/kendo_antarctica.css"
import "hammerjs"
import "handsontable/dist/handsontable.full.css"
import "react-toastify/dist/ReactToastify.css"
import "./styles/index.scss"
import './utils/extensions'
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/dates/styles.css'
import 'mantine-react-table/styles.css'
import "./utils/extensions"

import DateFnsUtils from "@date-io/date-fns"
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from "@mantine/modals"
import { Notifications } from "@mantine/notifications"
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from "react"
import { positions, Provider as AlertProvider, transitions } from "react-alert"
import AlertTemplate from "react-alert-template-basic"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"
import { ThemeProvider } from "styled-components"

import BUILD_ENV from './constants/env'
import App from './containers/App'
import {
  AuthContextProvider,
  ConstantsContextProvider,
  FundsContextProvider,
  PortfolioContextProvider,
  PrivateEquityProvider
} from "./contexts"
import { FactSheetsProvider } from "./contexts/fact-sheets.context"
import { MixpanelProvider } from "./contexts/mixpanel.context"
import { SnackbarProvider } from "./contexts/snackbar.context"
import { store } from "./redux/store"
import AppTheme from "./styles/AppTheme"
import mantineTheme, { resolver } from "./styles/mantine-theme"

// optional cofiguration
const alert_options = {
  offset: '30px',
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  // you can also just use 'scale'
  transition: transitions.SCALE,
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.NODE_ENV !== 'development',
  environment: BUILD_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  profilesSampleRate: 1,
  release: '1.0.0',
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  tracePropagationTargets: ['localhost', /.*antarcticaam.app\/(api|auth)/],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
})

export default function Providers() {
  return (
    <MantineProvider
      cssVariablesResolver={resolver}
      theme={mantineTheme}
    >
      <ModalsProvider>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <AuthContextProvider>
              <StylesProvider injectFirst>
                <MuiThemeProvider theme={AppTheme}>
                  <ThemeProvider theme={AppTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <AlertProvider
                        template={AlertTemplate}
                        {...alert_options}
                      >
                        <MixpanelProvider>
                          <SnackbarProvider>
                            <FundsContextProvider>
                              <PortfolioContextProvider>
                                <PrivateEquityProvider>
                                  <FactSheetsProvider>
                                    <ConstantsContextProvider>
                                      <App />
                                      <Notifications zIndex={5000} />
                                    </ConstantsContextProvider>
                                  </FactSheetsProvider>
                                </PrivateEquityProvider>
                              </PortfolioContextProvider>
                            </FundsContextProvider>
                          </SnackbarProvider>
                        </MixpanelProvider>
                      </AlertProvider>
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </MuiThemeProvider>
              </StylesProvider>
              <ToastContainer />
            </AuthContextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Provider>

      </ModalsProvider>
    </MantineProvider>
  )
}
