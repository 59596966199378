import { Box } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import * as Yup from "yup"

import { TextInput } from "../../components/forms"
import { confirmNewPassword } from "../../services"
import { passwordValidation } from "./../../components/forms/validation"
import { NewSubmitButton } from "./styles"
import { StyledForm } from "./styles"
import { Alert } from "@mantine/core"
import { IconInfoCircle } from '@tabler/icons-react';
import AuthPageWrapper from "../../components/layout/AuthPageWrapper"

const validationSchema = Yup.object().shape({
  new_password: passwordValidation,
  new_password_conf: Yup.string().oneOf(
    [Yup.ref("new_password"), null],
    "Passwords must match",
  ),
})

const Form = ({ onSubmit }) => (
  <Formik
    initialValues={{
      new_password: "",
      new_password_conf: "",
    }}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {({ errors }) => {
      return (
        <StyledForm>
          <TextInput
            className="item"
            name="new_password"
            placeholder="New password"
            type="password"
            wrapperProps={{
              style: {
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '12px'
              }
            }}
          />
          <TextInput
            className="item"
            name="new_password_conf"
            placeholder="Repeat password"
            type="password"
            wrapperProps={{
              style: {
                display: 'flex',
                flexDirection: 'column',
              }
            }}
          />
          <NewSubmitButton
            disableElevation
            type="submit"
          >Set password</NewSubmitButton>
        </StyledForm>
      )
    }}
  </Formik>
)

const ResetPassword = ({ match }) => {
  const [userInfo, setUserInfo] = useState({})
  const [success, setSuccess] = useState(false)
  const [errMsg, setErrMsg] = useState()

  useEffect(() => {
    const { params } = match
    const { token, userId } = params
    setUserInfo({ token, uid: userId })
  }, [match])

  const onSubmit = async ({ new_password, new_password_conf }) => {
    setErrMsg(null)
    if (new_password !== new_password_conf) {
      setErrMsg("Passwords do not match")
      return
    }
    try {
      const payload = {
        new_password,
        ...userInfo,
      }
      await confirmNewPassword(payload)
      setSuccess(true)
    } catch (error) {
      const msg = error.message == "Request failed with status code 400"
        ? "The link you are using has expired. Please request 'Reset password' again and click the link in the new email you will receive from us."
        : "We were unable to reset your password. Please try requesting it again. If the error persists, please contact Investor Relation"
      setErrMsg(msg)
    }
  }
  return (
    <AuthPageWrapper>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
      >
        {
          errMsg && (
            <Alert color="red" variant="filled" mb={12} title="Oops, Something Went Wrong" icon={<IconInfoCircle />} withCloseButton onClose={() => setErrMsg(null)}>
              {errMsg}
            </Alert>
          )
        }
        {success ? (
          <>
            <Typography
              align="center"
              className="item"
            >
              You have successfully changed your password
            </Typography>
            <Button
              className="back-to-login-btn"
              color="primary"
              component={Link}
              to="/"
              variant="text"
            >
              Back to login
            </Button>
          </>
        ) : (
          <>
            <Form onSubmit={onSubmit} />
            <Button
              className="back-to-login-btn"
              color="primary"
              component={Link}
              to="/"
              variant="text"
            >
              Back to login
            </Button>
          </>
        )}
      </Box>
    </AuthPageWrapper>
  )
}

export default ResetPassword
