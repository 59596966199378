import { Box } from "@mantine/core"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React from "react"

import FundSelect from "../../components/forms/FundSelect"
import GlobalFundSelector from "../../components/forms/GlobalFundSelector"
import { newImplmentationAnalyticsReportOptions } from "../../constants/analytics-reports.constants"
import { useStatisticsReport } from "../../contexts/statistics-report.context"
import { analysis_lists } from "../../data/analysis"
import FraudDetectionAnalysisForm from "./forms/FraudDetectionAnalysisForm"

export const newImplementFormRegistry = {
  [newImplmentationAnalyticsReportOptions.fraudAnalysis]: (
    <FraudDetectionAnalysisForm />
  ),
}

const fund_analysis = [
  'Factor Analysis',
  'Statistical Analysis',
  'Peer Group Analysis',
  'RoC',
  'Regression Analysis',
  'Statistical Analysis (PDF)',
  'Statistical Analysis (Excel)',
  'Performance Table',
  'Downside Analysis',
  ...Object.keys(newImplementFormRegistry),
]

const portfolio_analysis = [
  'Correlation Analysis',
  'VaR',
  'Volatility Decomposition',
]

export default function InputSelectors({
  analysis_inputs,
  hideFund,
  noPortfolioAnalysis,
  onGenerateReport,
  portfolio_list,
  setShowInputs,
  showInputs,
  updateSelector,
  loading,
  ...otherProps
}) {
  const context = useStatisticsReport()
  const { reportSnippets, setAnalysisInputs } = context
  let { report_type: rt = '', var_type } = analysis_inputs
  if (rt == null) {
    rt = ''
  }
  const stats = rt.includes('Statistical Analysis')
  const peers = rt === 'Peer Group Analysis'
  const correlation = rt === 'Correlation Analysis'
  const regression = rt === 'Regression Analysis'
  const downside = rt === 'Downside Analysis'
  const var_report = rt === 'VaR'
  const decomposition = rt === 'Volatility Decomposition'
  const roc = rt === 'RoC'
  const isNewImplementationForm = !!newImplementFormRegistry[rt]
  const show_benchmarks = !!(stats || peers || roc)
  const show_port_list = !!(correlation || decomposition || var_report)
  const show_factors = !!(
    regression ||
    decomposition ||
    (var_report && var_type === 'fund_summary')
  )

  const no_weight = !!(
    correlation ||
    (var_report && var_type === 'fund_summary')
  )

  const available_analysis = noPortfolioAnalysis
    ? fund_analysis
    : [...fund_analysis, ...portfolio_analysis]


  const changePeerInputHandler = (value) => {
    setAnalysisInputs((prev) => ({ ...prev, peers: value }))
  }

  const changeFundInputHandler = (value) => updateSelector({ name: 'fund', value })

  return reportSnippets ? (
    <div className="performance-input report-selectors-container">
      <div className="heading">
        <Typography align="center" noWrap paragraph={false} variant="h6">
          Report Set-Up
          {!showInputs && (
            <IconButton
              onClick={() => {
                setShowInputs(true)
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          )}
        </Typography>
      </div>
      <Collapse in={showInputs}>
        {reportSnippets.dropdownSelector(
          'report_type',
          'Report Type',
          available_analysis,
          { defaultItem: 'Select report...' },
        )}
        {!isNewImplementationForm && !show_port_list &&
          !hideFund && (
            <Box mb="md">
              <FundSelect
                onChange={changeFundInputHandler}
                value={analysis_inputs.fund}
              />
            </Box>
          )}
        {show_port_list &&
          reportSnippets.comboboxSelector(
            'selected_portfolio',
            'Portfolio',
            portfolio_list,
            {
              dataItemKey: 'id',
              textField: 'name',
            },
          )}
        {show_port_list &&
          reportSnippets.portfolioFundsSelector('weights', {
            no_weight,
          })}
        {regression &&
          reportSnippets.buttonGroupSelector(
            'reg_type',
            analysis_lists.reg_type,
          )}
        {downside && (
          <>
            {reportSnippets.textField(
              'simulation_years',
              'Years to simulate (default: 5)',
            )}
            {reportSnippets.textField(
              'jump_probability',
              'Probability of a jump, % (default: 5%)',
            )}
            {reportSnippets.textField(
              'jump_mean',
              'Mean of the jump, % (default: -10%)',
            )}
            {reportSnippets.textField(
              'jump_vol',
              'Vol of the jump, % (default: 2.5%)',
            )}
          </>
        )}

        {var_report &&
          reportSnippets.buttonGroupSelector(
            'var_type',
            analysis_lists.var_type,
          )}
        {decomposition &&
          reportSnippets.buttonGroupSelector(
            'decomposition_type',
            analysis_lists.decomposition_type,
          )}
        {show_benchmarks &&
          reportSnippets.globalMultiSearch("benchmarks", "Benchmarks")}
        {peers && <Box mb="sm">
          <GlobalFundSelector
            onChange={changePeerInputHandler}
            placeholder="Peers"
            selectedFunds={analysis_inputs.peers}
            showPeerGroup
          />
        </Box>}
        {show_factors && reportSnippets.globalMultiSearch("factors", "Factors")}

        {!isNewImplementationForm &&
          rt &&
          reportSnippets.starEndSelector('start_date', 'end_date')}

        {!isNewImplementationForm &&
          reportSnippets.submitButton('Generate Report', {
            disabled:
              loading ||
              !analysis_inputs?.report_type ||
              (show_port_list && !analysis_inputs?.selected_portfolio) ||
              (!show_port_list && !hideFund && !analysis_inputs?.fund) ||
              (show_benchmarks && !analysis_inputs?.benchmarks?.length) ||
              (show_factors && !analysis_inputs?.factors?.length) ||
              (peers && !analysis_inputs?.peers?.length),
          })}

        {isNewImplementationForm && newImplementFormRegistry[rt]}
      </Collapse>
    </div>
  ) : null
}
