import React from 'react'
import { Box, withStyles } from '@material-ui/core'

import chartPlaceholder from '../../styles/assets/images/blurred_chart.png' // Adjust the path as necessary

const styles = (theme) => ({
  '@keyframes muiPulse': {
    '0%': {
      opacity: 0.6,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.6,
    },
  },
  chartSkeleton: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${chartPlaceholder})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '8px',
    animation: '$muiPulse 1.5s ease-in-out infinite',
  },
})

const SkelatonExposuresChartLoader = withStyles(styles)(({ classes }) => (
  <Box className={classes.chartSkeleton}></Box>
))

export default SkelatonExposuresChartLoader
