const clientId =
  "1063481237946-ia3rt7gi7cvahjnl3j6equj9o6338ap4.apps.googleusercontent.com";

const viewId = "225189233";

const initAuth = () => {
  return window.gapi.auth2.init({
    client_id: clientId,
    scope: "https://www.googleapis.com/auth/analytics.readonly",
  });
};

export const checkSignedIn = () => {
  return new Promise((resolve, reject) => {
    initAuth()
      .then(() => {
        const auth = window.gapi.auth2.getAuthInstance();
        resolve(auth.isSignedIn.get());
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const renderButton = () => {
  window.gapi.signin2.render("signin-button", {
    scope: "profile email",
    width: 240,
    height: 50,
    longtitle: true,
    theme: "dark",
    onsuccess: (e) => console.log(e.getBasicProfile().getName()),
    onfailure: (error) => console.error(error),
  });
};

const gaRequest = (body) => {
  const baseRequest = {
    path: "/v4/reports:batchGet",
    root: "https://analyticsreporting.googleapis.com/",
    method: "POST",
    body,
  };
  return window.gapi.client.request(baseRequest);
};

const gaUserRequest = (body) => {
  const baseRequest = {
    path: "/v4/userActivity:search",
    root: "https://analyticsreporting.googleapis.com/",
    method: "POST",
    body,
  };
  return window.gapi.client.request(baseRequest);
};

const getDateRange = (startDate, endDate) => [
  {
    startDate,
    endDate,
  },
];

const getMetrics = (arr) =>
  arr.map((name) => ({ expression: name[0], alias: name[1] }));
const getDimensions = (arr) => arr.map((name) => ({ name }));

export const gaDashReport = ({ startDate, endDate }) => {
  const body = {
    reportRequests: [
      {
        dateRanges: getDateRange(startDate, endDate),
        metrics: getMetrics([
          ["ga:users", "Users"],
          ["ga:sessions", "Sessions"],
        ]),
        dimensions: getDimensions(["ga:date"]),
        viewId,
      },
      {
        dateRanges: getDateRange(startDate, endDate),
        metrics: getMetrics([["ga:7dayUsers", "7 Day Active Users"]]),
        dimensions: getDimensions(["ga:date"]),
        viewId,
      },
      {
        dateRanges: getDateRange(startDate, endDate),
        metrics: getMetrics([["ga:30dayUsers", "30 Day Active Users"]]),
        dimensions: getDimensions(["ga:date"]),
        viewId,
      },
      {
        dateRanges: getDateRange(startDate, endDate),
        metrics: getMetrics([
          ["ga:pageviews", "Page Views"],
          ["ga:avgTimeOnPage", "Avg Time (s)"],
        ]),
        dimensions: getDimensions(["ga:pageTitle"]),
        viewId,
      },
      {
        dateRanges: getDateRange(startDate, endDate),
        metrics: getMetrics([
          ["ga:users", "Users"],
          ["ga:sessions", "Sessions"],
        ]),
        dimensions: getDimensions(["ga:country"]),
        viewId,
      },
    ],
  };
  return gaRequest(body);
};

export const gaUserReport = ({ id, startDate, endDate }) => {
  const body = {
    dateRange: getDateRange(startDate, endDate)[0],
    viewId: "225189233",
    user: {
      type: "USER_ID",
      userId: `${id}`,
    },
    activityTypes: ["PAGEVIEW"],
  };
  return gaUserRequest(body);
};
