import React, { Component } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { CurrentCashTab, ProjectedCashTab } from "./";

export class cashContainer extends Component {
  state = {
    current_view: "current_cash",
  };

  changeView = (e, new_view) => {
    this.setState({ current_view: new_view });
  };

  renderTab = () => {
    const { current_view } = this.state;
    switch (current_view) {
      case "current_cash":
        return <CurrentCashTab />;
      case "projected_cash":
        return <ProjectedCashTab />;
      default:
        break;
    }
  };

  render() {
    const { current_view } = this.state;
    return (
      <React.Fragment>
        <div className="cash-management-page">
          <Tabs
            value={current_view}
            onChange={this.changeView}
            indicatorColor="primary"
          >
            <Tab label="Current Cash" value="current_cash" />
            <Tab label="Projected Cash" value="projected_cash" />
          </Tabs>
        </div>
        <div>{this.renderTab()}</div>
      </React.Fragment>
    );
  }
}

export default cashContainer;
