export interface Option<T = string | number> {
  label: string;
  value: T;
}

type OptionValueType<T, ParseValueToString extends boolean> = ParseValueToString extends true ? string : T;

export const createOptions = <T = any, ParseValueToString extends boolean = false>(
  list: T[],
  labelKey: keyof T,
  valueKey: keyof T,
  parseValueToString: ParseValueToString = false as ParseValueToString,
): Option<OptionValueType<T[keyof T], ParseValueToString>>[] => {
  return list?.map((item) => ({
    label: String(item[labelKey]),
    value: parseValueToString ? String(item[valueKey]) : (item[valueKey] as OptionValueType<T[keyof T], ParseValueToString>)
  })) || [];
};