import { useQuery } from '@tanstack/react-query'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { getAaapDocuments } from '../services'
import { ConstantsContext } from '../contexts'
import { notifyExceptionRaised } from '../containers/assetDetail/utils'
import { toast } from 'react-toastify'
import { FUND_DOCS_TYPES } from '../constants/env'
import { useProfile } from './useProfile'

export interface Document {
  id: number
  as_of_date: Date
  name: string
  story_type: string
}

interface AaapDocsHookReturn {
  legalDocuments: Document[]
  researchDocuments: Document[]
  fundReportingDocuments: Document[]
  presentationDocs: Document[]
  isFetched?: boolean
}

/**
 * Renames the fund names in research document titles to match a root fund name, ensuring consistency.
 * Operates in a non-destructive manner by returning a new array with updated document names.
 *
 * @param docs - The original array of research documents
 * @param fundRootNameString - The root fund name to be used for renaming
 * @return A new array of research documents with potentially updated names
 */
const renameResearchDocs = (
  docs: Document[],
  fundRootNameString: string,
): Document[] => {
  const fundRootName = fundRootNameString.match(/AAAP (.+?) /)?.[1]

  return docs.map((doc) => {
    const matched = doc.name.match(/AAAP (.+?) /)
    const fundChildName = matched?.[1]

    if (fundChildName && fundRootName && fundRootName !== fundChildName) {
      return { ...doc, name: doc.name.replace(fundChildName, fundRootName) }
    }
    return doc
  })
}

const defaultAaapDocsHookReturn: AaapDocsHookReturn = {
  legalDocuments: [],
  researchDocuments: [],
  fundReportingDocuments: [],
  presentationDocs: [],
}

const securityTypeDocsTypeMap: Record<string, string> = {
  'AAAP Cell': 'AAAP Presentations',
  'FoF Share Class': 'FoF Presentations',
}

const useFetchAaapDocs = (
  securityId: string,
  securityName: string,
  securityType: string,
): AaapDocsHookReturn | null | undefined => {
  const constants = useContext(ConstantsContext)
  const profile = useProfile()
  const [aaapCategorisedDocs, setAaapCategorisedDocs] =
    useState<AaapDocsHookReturn>(defaultAaapDocsHookReturn)
  const hasAccessToResearch = profile?.has_access_to_research
  const signedNda = profile.has_signed_nda || profile?.company?.has_signed_nda

  const { data: apiResponse, isFetched } = useQuery(
    ['aaap-docs', securityId],
    () => getAaapDocuments({ security: securityId }),
    {
      enabled: !!securityId,
      staleTime: Infinity,
      onError: (error) => {
        notifyExceptionRaised({
          error,
          context: { id: 0, data: null },
          to: constants.TECH_EMAIL_NOTIFICATION_ADDRESSES,
        })
        toast.error(
          'Something went wrong! Documents were not loaded successfully.',
        )
      },
    },
  )

  useEffect(() => {
    const allDocs: Document[] = apiResponse?.data?.filter(
      (doc) =>
        !(
          (constants.NDA_REQUIRED_DOCUMENTS.includes(doc.story_type) &&
            !signedNda) ||
          (constants.RESEARCH_REQUIRED_DOCUMENTS.includes(doc.story_type) &&
            !hasAccessToResearch)
        ),
    )

    if (allDocs) {
      const filterByTypes = (docs: Document[], types: string[]) =>
        docs.filter((doc) => types.includes(doc.story_type))
      const categorisedDocs = {
        legalDocuments: filterByTypes(allDocs, FUND_DOCS_TYPES.legalDocsTypes),
        researchDocuments: renameResearchDocs(
          filterByTypes(allDocs, FUND_DOCS_TYPES.researchDocsTypes),
          securityName,
        ),
        fundReportingDocuments: filterByTypes(
          allDocs,
          FUND_DOCS_TYPES.fundReportingDocsTypes,
        ),
        presentationDocs: filterByTypes(
          allDocs,
          FUND_DOCS_TYPES.presentationDocsTypes,
        )?.filter(
          (doc) =>
            (!!securityTypeDocsTypeMap[securityType] &&
              securityTypeDocsTypeMap[securityType] === doc.story_type) ||
            !securityTypeDocsTypeMap[securityType],
        ),
      }
      setAaapCategorisedDocs(categorisedDocs)
    }
  }, [apiResponse, securityType])

  return useMemo(
    () => ({
      ...aaapCategorisedDocs,
      ndaRequiredDocsTypes: constants.NDA_REQUIRED_DOCUMENTS,
      isFetched,
    }),
    [aaapCategorisedDocs, isFetched, constants],
  )
}

export default useFetchAaapDocs
