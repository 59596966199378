/* eslint-disable sort-keys/sort-keys-fix */
import { SegmentedControl } from "@mantine/core"
import { Typography } from "@material-ui/core"
import mixpanel from "mixpanel-browser"
import React, { useEffect, useMemo } from "react"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"

import { TabContentContainer } from "../../components/misc/tab-content-container"
import { usePrivateEquityFunds } from "../../hooks"
import useAccessRights from "../../hooks/useAccessRights"
import { useMixpanel } from "../../hooks/useMixpanel"
import { useParamsQueryObject } from "../../hooks/useParamsQuery"
import PeFaqTab from "../assetDetail/PeFaqTab"
import { LegalTab, OverviewTab, TeamTab } from "../dashboard"
import {
  formatNumberToCurrency,
  fundStatusMap,
  setFundStatus,
} from "../dashboard/PrivateEquityCard/PrivateEquityCard"
import {
  StyledCardStatus,
  StyledData,
} from "../dashboard/PrivateEquityCard/PrivateEquityCard.styled"
import PrivateEquityDocumentTab from "../dashboard/PrivateEquityDocumentTab"
import PeDisclaimers from "./PeDisclaimers"

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
`

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.25rem 0;
`

const StyledColumnTitle = styled(StyledColumn)`
  width: 90%;
  & h1,
  h4 {
    margin: 0;
    color: #707070;
  }
`

const StyledColumnStatus = styled(StyledColumn)`
  width: 10%;
`

const StyledBasicInfoStatus = styled(StyledCardStatus)`
  width: 100%;
`

const StyledKeyStats = styled(StyledData)`
  align-items: center;
  background: ${({ theme }) => theme.palette.aamui.oceanNavy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  margin-bottom: 8px;
  width: 100%;
`

const StyledKeyStatsHeading = styled(Typography)`
  color: white;
`

const StyledKeyStatsValue = styled(Typography)`
  color: white;
  font-size: 24px;
`

export const StyledTabView = styled.div`
  margin-top: 10px;
`

const TabComponentMap = {
  'Overview': OverviewTab,
  'Leadership Team': TeamTab,
  'Terms & Legal': LegalTab,
  'Documents': PrivateEquityDocumentTab,
  'FAQs': PeFaqTab,
  'Disclaimer': PeDisclaimers,
}

const tabs = Object.keys(TabComponentMap)

const View = ({ selected, ...props }) => {
  const Component = TabComponentMap[selected] || (() => <div />)
  return <Component {...props} />
}

const defaultTab = 'Overview'

export default function AssetDetail() {
  const history = useHistory()
  const { mpTrack } = useMixpanel()
  const { fund_id } = useParams()
  const { data: privateEquityFunds } = usePrivateEquityFunds()
  const { hasPrivateEquityAccess } = useAccessRights()
  const privateEquityFundObj = useMemo(() => {
    if (fund_id && privateEquityFunds?.length > 0) {
      return privateEquityFunds.find((it) => String(it.id) === fund_id)
    }
    return null
  }, [fund_id, privateEquityFunds])
  const currency = privateEquityFundObj?.terms_legal?.currency
  const targetSize = privateEquityFundObj?.target_size
  const minInvestment = formatNumberToCurrency(
    privateEquityFundObj?.min_investment,
    currency,
  )
  const { tab } = useParamsQueryObject()
  const selectedTab = tab || defaultTab
  const tabOptions = tabs

  useEffect(() => {
    if (privateEquityFundObj) {
      mixpanel.time_event(`Time on HF Fund - ${privateEquityFundObj.name}`)
      mpTrack({
        eventName: `View HF Fund Details - ${privateEquityFundObj.name}`,
        properties: {
          ID: privateEquityFundObj.id,
          Name: privateEquityFundObj.name,
          Type: "View HF Fund Details",
        },
      })
    }
  }, [privateEquityFundObj, mpTrack])

  useEffect(() => {
    return () => {
      if (privateEquityFundObj?.name) {
        mixpanel.track(`Time on HF Fund - ${privateEquityFundObj.name}`)
      }
    }
  }, [privateEquityFundObj])

  const tabContainerProps = {
    pageName: privateEquityFundObj?.name,
    properties: {
      "Fund ID": privateEquityFundObj?.id,
      "Fund Life": privateEquityFundObj?.fund_life,
      "Fund Name": privateEquityFundObj?.name,
      "Fund Type": privateEquityFundObj?.fund_type,
      Geography: privateEquityFundObj?.geography,
    },
    tabName: selectedTab,
    tabsType: "PE Fund detail",
  }

  const changeTabHandler = (view) => history.push(`/private-markets/funds/${privateEquityFundObj?.id}?tab=${encodeURIComponent(view)}`)

  return privateEquityFundObj ? (
    <>
      {hasPrivateEquityAccess ? (
        <div className="asset-detail-page">
          <div className="page-container">
            <StyledRow>
              <StyledColumnTitle>
                <Typography variant="h6">
                  {privateEquityFundObj.name}
                </Typography>
                <Typography variant="h6">
                  {privateEquityFundObj.vehicle}
                </Typography>
              </StyledColumnTitle>
              <StyledColumnStatus>
                <StyledBasicInfoStatus
                  isOpen={setFundStatus(privateEquityFundObj.fund_status)}
                >
                  {fundStatusMap[privateEquityFundObj.fund_status]}
                </StyledBasicInfoStatus>
              </StyledColumnStatus>
            </StyledRow>
            <StyledRow>
              <StyledKeyStats>
                <StyledKeyStatsHeading variant="subtitle1">
                  TARGET SIZE
                </StyledKeyStatsHeading>
                <StyledKeyStatsValue>{targetSize}</StyledKeyStatsValue>
              </StyledKeyStats>
              <StyledKeyStats>
                <StyledKeyStatsHeading variant="subtitle1">
                  FUND LIFE
                </StyledKeyStatsHeading>
                <StyledKeyStatsValue>
                  {privateEquityFundObj.fund_life}
                </StyledKeyStatsValue>
              </StyledKeyStats>
              <StyledKeyStats>
                <StyledKeyStatsHeading variant="subtitle1">
                  MIN INVESTMENT
                </StyledKeyStatsHeading>
                <StyledKeyStatsValue>
                  {minInvestment.includes("$ 0") ? "TBD" : minInvestment}
                </StyledKeyStatsValue>
              </StyledKeyStats>
            </StyledRow>
            <SegmentedControl
              data={tabOptions}
              fullWidth
              my="sm"
              onChange={changeTabHandler}
              size="xl"
              value={selectedTab}
              withItemsBorders={false}
            />
            <StyledTabView>
              <TabContentContainer {...tabContainerProps}>
                <View
                  selected={selectedTab}
                  {...privateEquityFundObj}
                />
              </TabContentContainer>
            </StyledTabView>
          </div>
        </div>
      ) : (
        <h1>Unauthorised Access</h1>
      )}
    </>
  ) : null
}
