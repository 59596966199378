export interface TradingCalendarSkippedDateReason {
  holidayName: string;
  country: string;
  date: Date;
  holidayDescription: string;
}

export interface TradingCalendarNoticeDate {
  noticeDate: Date;
  reasons: TradingCalendarSkippedDateReason[];
  targetDate: Date;
}

export type TradingCalendarDataResponseData = TradingCalendarNoticeDate[]

export enum SubscriptionFrequency {
  Monthly = 'Monthly',
  Annually = 'Annually',
  SemiAnnually = 'Semi-Annually',
  Quarterly = 'Quarterly'
}

export interface TradingCalendarInputFormData<T extends { value: string }[] | string[]> {
  daysBefore: number;
  countries: T;
  interval: SubscriptionFrequency;
  year?: number;
  isFirstDate?: boolean;
  mode: string;
  startDate?: Date | string;
  numIntervals: number;
  dayType: TradingCalendarDayType
}

export interface TradingCalendarByFundFormUIData {
  fund?: number;
}

export type TradingCalenderInputFormUIData = TradingCalendarInputFormData<{ value: string }[]> & TradingCalendarByFundFormUIData
export type TradingCalendarInputFormApiRequestData = TradingCalendarInputFormData<string[]> & {
  intervalAnchor: 'first' | 'last' | 'custom';
}

export enum TradingCalendarFormMode {
  Custom = 'Custom',
  Regular = 'Regular',
  Fund = 'Fund'
}

export enum TradingCalendarDayType {
  BUSINESS_DAYS = 'BUSINESS_DAYS',
  CALENDAR_DAYS = 'CALENDAR_DAYS'
}