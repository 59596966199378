import React, { useState } from 'react'
import ExposureAnalysis from './repotContainer'
import { getSecurityDetails } from '../../services/helpers'
import { getExposureAnalysis } from '../../services'
import { dateTimeManager as dt } from '../../utils'
import moment from 'moment'
import { useSecurities } from '../../hooks'
import { useMixpanel } from '../../hooks/useMixpanel'

export default function ReportWrapper() {
  const { mpTrack } = useMixpanel()
  let { search_list: searchList } = useSecurities()
  searchList = searchList.filter((o) => o.exposures_available)
  const [securityDetails, setSecurityDetails] = useState({})
  const [analysisInputs, setAnalysisInputs] = useState({
    report_type: 'charts',
    fund: '',
    start_date: dt.defaultStartDate(),
    end_date: dt.defaultEndDate(),
    report_date: null,
    category: null,
    table_mode: { id: 'monthly', name: 'Monthly' },
    table: { id: 'quarterly_change_table', name: 'Quarterly Change' },
  })
  const [analysis, setAnalysis] = useState({})
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const getDetails = async (payload) => {
    setLoading(true)
    const details = await getSecurityDetails(payload, searchList)
    setLoading(false)
    const { meta_lists } = details || {}
    let { quarterly_months = [], classifications = [] } = meta_lists || {}
    quarterly_months = quarterly_months.map((entry) => {
      entry.id = entry.perf_date
      entry.name = moment(entry.perf_date).format('MMM-YY')
      return entry
    })
    classifications = classifications.map((entry) => {
      entry.id = entry.classification
      entry.name = entry.classification
      return entry
    })

    setSecurityDetails({ ...details, quarterly_months, classifications })
  }

  const updateSelector = ({ value, name }) => {
    setLoaded(false)
    setAnalysisInputs((i) => ({ ...i, [name]: value }))
    if (name === 'fund' && value) {
      const { id: fund_id, type: security_type } = value || {}
      getDetails({ fund_id, security_type })
    }
  }

  const fetchExposureAnalysis = async (payload) => {
    setLoading(true)
    try {
      const response = await getExposureAnalysis(payload)
      setAnalysis(response.data)
      setLoaded(true)
      mpTrack({
        eventName: 'Generate Internal Report',
        Type: 'Exposure Analysis',
        'Report Type': analysisInputs.report_type,
        'Start Date': moment(analysisInputs.start_date)?.format('YYYY-MM-DD'),
        'End Date': moment(analysisInputs.end_date)?.format('YYYY-MM-DD'),
        'Fund ID': analysisInputs?.fund?.id,
      })
    } catch (error) { }
    setLoading(false)
  }

  return (
    <div className="exposure-analysis-route">
      <ExposureAnalysis
        updateSelector={updateSelector}
        exposure_analysis_input={analysisInputs}
        search_list={searchList}
        security_details={securityDetails}
        getAnalysis={fetchExposureAnalysis}
        exposure_analysis={analysis}
        loading={loading}
        loaded={loaded}
      />
    </div>
  )
}
