import React from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

// import TextField from "@material-ui/core/TextField";
import { Input as TextField } from '@progress/kendo-react-inputs'
import Badge from '@material-ui/core/Badge'
import router from '../../resources/history'
import {
  screener_button_list,
  screener_mode_button_list,
} from '../../data/funds'
import FilterIcon from '@material-ui/icons/Tune'
import WorkIcon from '@material-ui/icons/Work'
import AssessmentIcon from '@material-ui/icons/Assessment'
import ClearIcon from '@material-ui/icons/Clear'
import { Link } from 'react-router-dom'
import qs from 'qs'

export default function ScreenerToolbar({
  clearSelectedSecurities,
  currentView,
  setCurrentView,
  selected_securities,
  nameFilter,
  setNameFilter,
  changeScreenerMode,
  setPortfolio,
  full_access,
  screenerMode,
  filterBarOpen,
  setFilterBarOpen,
}) {
  const generateButtonGroup = () => {
    return screener_button_list.map((btn, idx) => {
      const color = currentView === btn.id ? 'primary' : 'default'
      return (
        <Button
          onClick={() => {
            setCurrentView(btn.id)
          }}
          color={color}
          key={idx}
        >
          {btn.title}
        </Button>
      )
    })
  }

  const updateNameFilter = ({ target }) => {
    const { value } = target
    setNameFilter(value)
  }

  const generateFundSwitchGroup = () => {
    return screener_mode_button_list.map((btn, idx) => {
      const color = screenerMode === btn.id ? 'primary' : 'default'
      return (
        <Button
          onClick={() => {
            changeScreenerMode(btn.id)
          }}
          color={color}
          key={idx}
        >
          {btn.title}
        </Button>
      )
    })
  }

  const openAnalysis = () => {
    const ids = selected_securities.map((o) => o.id)
    const params = qs.stringify({ fund_list: ids, report_type: 'Quick Chart' })
    router.push({
      pathname: '/quick-analysis',
      search: params,
    })
  }

  const updatePortfolioWeights = () => {
    const weights = selected_securities.map((security) => ({
      security,
      weight: null,
    }))
    setPortfolio((i) => ({
      ...i,
      portfolio_weights: [...weights, { security: '', weight: null }],
    }))
  }

  const selected_length = selected_securities.length
  const nothing_selected = selected_length < 1

  return (
    <div className="toolbar">
      <ButtonGroup
        className="view-buttons"
        variant="contained"
        color="default"
        align="center"
        size="small"
        disableElevation
        style={{ marginRight: '10px' }}
      >
        {generateButtonGroup()}
      </ButtonGroup>
      {full_access && (
        <ButtonGroup
          className="view-buttons"
          variant="contained"
          color="default"
          align="center"
          size="small"
          disableElevation
        >
          {generateFundSwitchGroup()}
        </ButtonGroup>
      )}
      <Button
        className="filter-button"
        variant="contained"
        color={filterBarOpen ? 'primary' : 'default'}
        endIcon={<FilterIcon />}
        onClick={() => {
          setFilterBarOpen((i) => !i)
        }}
        disableElevation
      >
        Filters
      </Button>
      <TextField
        className="search-bar"
        value={nameFilter}
        onChange={updateNameFilter}
        placeholder="Fund, cell, manager or ISIN..."
      />
      <ButtonGroup
        className="compare-buttons"
        variant="contained"
        align="right"
        color="primary"
        disabled={nothing_selected}
        size="small"
        disableElevation
      >
        <Button
          endIcon={<ClearIcon />}
          variant="contained"
          className="compare-btn"
          onClick={clearSelectedSecurities}
          disabled={nothing_selected}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<AssessmentIcon />}
          className="compare-button compare-btn"
          onClick={openAnalysis}
          disabled={nothing_selected}
        >
          Compare
        </Button>

        <Button
          to="screener/portfolio-builder"
          variant="contained"
          className="portfolio-button compare-btn"
          color="primary"
          onClick={() => updatePortfolioWeights()}
          endIcon={
            <Badge
              badgeContent={selected_length}
              color="secondary"
              overlap="rectangular"
            >
              <WorkIcon fontSize="small" />
            </Badge>
          }
          component={Link}
          style={{
            color: nothing_selected ? 'rgba(0, 0, 0, 0.26)' : 'white',
          }}
          disabled={nothing_selected}
        >
          New Portfolio
        </Button>
      </ButtonGroup>
    </div>
  )
}
