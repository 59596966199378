import React, { useEffect, useMemo } from 'react'
import { usePopUpData } from '../../hooks/usePopUpData'
import { useProfile, useSecurities } from '../../hooks'
import { useDisclosure } from '@mantine/hooks'
import { Box, Button, CloseButton, Divider, Group, Loader, Modal, Stack, Text } from '@mantine/core'
import { usePopupStatus } from '../../hooks/usePopUpStatus'
import { aamui } from '../../styles/AppTheme'
import { Link } from 'react-router-dom'
import { useModal } from '../../hooks/useModal'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { fundLaunchPopUpToggled } from '../../redux/uiSlice'
import { useMixpanel } from '../../hooks/useMixpanel'

interface ButtonData {
  label: string;
  classOrder: string;
  fundName: string;
  id?: number;
}

interface FundData {
  name: string;
  id: number;
}

function mapClassesToButtons(buttonData: ButtonData[], fundData: FundData[]): ButtonData[] {
  return buttonData.map(button => {
    const classes = button.classOrder.toLowerCase().split(',');
    const fundPrefix = button.fundName.split(' ').slice(0, -1).join(' ');
    for (const classLetter of classes) {
      const matchingFund = fundData.find(fund =>
        fund.name.startsWith(fundPrefix) &&
        fund.name.toLowerCase().includes(`class ${classLetter}`)
      );

      if (matchingFund) {
        return { ...button, id: matchingFund.id };
      }
    }

    return button;
  });
}

const shortDismissedDuration = 24 * 60 * 60 * 1000

const PopUp = () => {
  const dispatch = useAppDispatch()
  const { mpTrack } = useMixpanel()
  const { data: popUpData } = usePopUpData()
  const { id } = useProfile()
  const { aaap_cell_list: aaapFunds, loadingStates } = useSecurities()
  const { updatePopupStatus, showPopup } = usePopupStatus(id)
  const opened = useAppSelector(state => state.ui.openFundLaunchPopUp)
  const isLoadingAAAPFunds = loadingStates.aaap_cell_list
  const lastShortDismissed = useMemo(() => localStorage.getItem(`dismissed:${id}`), [id])

  const buttonData = useMemo(() => {
    return popUpData?.buttons && mapClassesToButtons(popUpData?.buttons, aaapFunds)
  }, [popUpData, aaapFunds])

  const open = () => dispatch(fundLaunchPopUpToggled(true))
  const close = () => dispatch(fundLaunchPopUpToggled(false))

  useEffect(() => {
    const lastShortDismissedDate = lastShortDismissed ? new Date(lastShortDismissed) : null;
    const shouldOpen = showPopup && (!lastShortDismissedDate || lastShortDismissedDate < new Date());

    if (shouldOpen) {
      open()
    }
  }, [showPopup, lastShortDismissed])

  const handleClickLaterButton = async () => {
    close()
    await updatePopupStatus(true)
    mpTrack({
      event: 'Click Dismiss Popup',
      properties: {
        Type: 'Remind me in 5 days'
      }
    })
  }

  const handleClickCloseButton = async () => {
    close()
    const tomorrowDate = new Date(Date.now() + shortDismissedDuration);
    localStorage.setItem(`dismissed:${id}`, tomorrowDate.toISOString())

    mpTrack({
      event: 'Click Dismiss Popup',
      properties: {
        Type: '24hrs'
      }
    })
  }

  const handleClickFundButton = (fundId: number | undefined) => () => {
    close()
    mpTrack({
      event: 'Click Popup Fund Button',
      properties: {
        'Fund ID': fundId
      }
    })
  }

  return popUpData && popUpData.currentlyPublished ? (
    <>
      <Modal
        id="hubPopup"
        centered
        size="auto"
        opened={opened}
        onClose={close}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <CloseButton pos="absolute" right={20} top={20} onClick={handleClickCloseButton} />
        <Stack justify='center' align='center' gap="xs">
          <Box
            component='img'
            w="150px" h="100%"
            src='/antarctica_logo.svg'
            alt="private equity logo"
          />
          <Text fw={700} size="40px" my="sm">{popUpData.topLabel}</Text>
          <Divider color={aamui.sapphire} size="sm" h={2} w="200px" />
          <Text c={aamui.sapphire} component='h1' ta="center" fw={700} size="xl">{popUpData.title}</Text>
          <Text mt={12} mb={16}>{popUpData.description}</Text>
          <Group>
            {
              isLoadingAAAPFunds ? (
                <Group justify="center">
                  <Loader size={30} />
                </Group>
              ) :
                buttonData?.map((button, idx) => (
                  <Button onClick={handleClickFundButton(button?.id)} key={idx} component={!button.id ? 'button' : Link} to={`/asset-detail/${button.id}`} disabled={!button.id}>
                    {button.label}
                  </Button>
                ))
            }
          </Group>
          <Button onClick={handleClickLaterButton} mt={12} variant="transparent" size="xs" h={32}>Remind me in 5 days</Button>
        </Stack>
      </Modal>
    </>
  ) : null
}

export default PopUp