import { Avatar, Menu, Stack } from '@mantine/core'
import { Text } from '@mantine/core'
import { IconBookmark, IconBriefcase2, IconLogout, IconSettings } from '@tabler/icons-react'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { AuthContext } from '../../contexts'
import { useProfile } from '../../hooks'
import useAccessRights from '../../hooks/useAccessRights'
import useIsPeRoutes from '../../hooks/useIsPeRoutes'

const menuItems = [
  {
    icon: IconSettings,
    id: 'profile-settings',
    label: 'Profile & Settings',
    path: '/me',
  }
]

const authedMenuItems = [
  {
    icon: IconBookmark,
    id: 'fact-sheets',
    label: 'Fact Sheets',
    path: '/fact-sheets',
  },
  {
    icon: IconBriefcase2,
    id: 'my-portfolios',
    label: 'My Portfolios',
    path: '/my-portfolios',
  }
]

const MenuItemComponent = ({ icon: Icon, label, path }) => (
  <Menu.Item
    component={Link}
    leftSection={
      <Icon
        size={18}
        stroke={1.5}
      />
    }
    to={path}
  >
    {label}
  </Menu.Item>
)


const AvatarMenu = () => {
  const { hasHedgeFundsAccess } = useAccessRights()
  const isPeRoutes = useIsPeRoutes()
  const profile = useProfile()
  const { logout } = useContext(AuthContext)
  const { company, email, first_name: firstName, last_name: lastName } = profile
  const avatarName = `${firstName?.[0]}${lastName?.[0]}`

  return (
    <>
      <Menu
        arrowPosition="center"
        offset={4}
        position="bottom-end"
        shadow="md"
        width={250}
        withArrow
        withinPortal={false}
        zIndex={1400}
      >
        <Menu.Target>
          <Avatar
            color="var(--mantine-color-skyBlue)"
            radius="xl"
            style={{ cursor: 'pointer' }}
          >
            {avatarName}
          </Avatar>
        </Menu.Target>
        <Menu.Dropdown >
          <Stack
            gap={0}
            px="sm"
            py="xs"
          >
            <Text
              c="var(--mantine-color-oceanNavy)"
              size="sm"
            >
              {firstName} {lastName}
            </Text>
            <Text
              c="gray"
              size="xs"
              style={{ wordBreak: 'break-all' }}
            >{email}</Text>
            {
              company?.name && (
                <Text
                  c="var(--mantine-color-oceanNavy)"
                  mt={4}
                  size="xs"
                  style={{ wordBreak: 'break-all' }}
                >
                  {company.name}
                </Text>
              )
            }
          </Stack>
          <Menu.Divider />
          {
            menuItems.map((item) => (
              <MenuItemComponent
                icon={item.icon}
                key={item.id}
                label={item.label}
                path={item.path}
              />
            ))
          }
          {
            hasHedgeFundsAccess && !isPeRoutes && authedMenuItems.map((item) => (
              <MenuItemComponent
                icon={item.icon}
                key={item.id}
                label={item.label}
                path={item.path}
              />
            ))
          }
          <Menu.Divider />
          <Menu.Item
            leftSection={
              <IconLogout
                size={18}
                stroke={1.5}
              />
            }
            onClick={logout}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu >
    </>
  )
}

export default AvatarMenu
