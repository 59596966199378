import React, { Component } from "react";
import { GenericTable } from "../../components";
import Grid from "@material-ui/core/Grid";

export default class varReport extends Component {
  render() {
    const { internal_analysis, narrow } = this.props;
    const { report } = internal_analysis || {};
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12} lg={narrow || 6}>
            <GenericTable
              table_name={"Funds"}
              data={report["var_table"]}
              index_width={`${200}px`}
              equal_width
              // index_auto
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
