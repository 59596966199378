import FileSaver from "file-saver"

const buildFileFromPayload = (payload) => {
  const { headers } = payload
  let file = new File([payload.file], payload.filename, {
    type: headers["content-type"],
  })
  FileSaver.saveAs(file)
}

export const buildFileFromResponse = (response, customFileName) => {
  const { data: file, headers } = response
  const filename = headers["content-filename"]
  buildFileFromPayload({ file, filename: customFileName || filename, headers })
}

export default buildFileFromPayload
