import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

const ActionButton = styled(IconButton)`
  background: none;
  border: none !important;
  border-radius: 0;
  color: ${({ theme }) => theme.palette.aamui.sapphire} !important;
  height: 40px;
  padding: 0;
  width: 36px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.aamui.eggShell};
    color: white !important;
  }

  &:active,
  &:focus {
    background: ${({ theme }) => theme.palette.aamui.sapphire};
    color: white !important;
  }
`;

export default ActionButton;
