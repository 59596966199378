/* eslint-disable no-param-reassign */
import * as yup from 'yup'

import { getNumberOrNull } from './isNumber'

export const nullableNumber = () => yup.number()
  .nullable(true)
  .transform(getNumberOrNull)
// .required('Required');

export const withRequired = (schema, disableRequired) => (disableRequired ? schema : schema?.required('Required'))

export const requiredField = () => yup.mixed().required('Required')

export const nullablePositiveFloat = () => nullableNumber().positive('Must be a positive number')
export const nullablePositiveInteger = () => nullablePositiveFloat().integer('Must be an integer')
export const positivePercentage = () => nullableNumber().min(0, 'Must be greater than or equal to 0').max(100, 'Must be less than or equal to 100%')
export const positiveDecimials = () => nullableNumber().min(0, 'Must be greater than or equal to 0').max(1, 'Must be less than or equal to 1')
export const requiredString = () => yup.string().typeError('').required('Required')

export const validUpperBound = (lowerBoundName = 'lowerBound', extraValidations, disableRequired) => yup.lazy((value, obj) => withRequired(nullableNumber()
  .min(obj.parent[lowerBoundName], 'Upper bound must be larger or equal to lower bound'), disableRequired).concat(withRequired(extraValidations, disableRequired)))
export const validPositiveLowerBound = () => nullableNumber().min(0, 'Must be greater or equal to zero')
export const validLowerBound = () => nullableNumber()

export const deletePropertyIfCondition = ({
  conditionKey, conditionValue, originalValue, property, value,
}) => {
  if (originalValue[conditionKey] !== conditionValue) {
    delete value[property]
  }
}

export const setPropertyIfCondition = ({
  conditionKey, conditionValue, defaultValue, originalValue, property, value,
}) => {
  if (originalValue[conditionKey] === conditionValue) {
    if (!originalValue[property]) {
      value[property] = defaultValue
    }
  } else {
    delete value[property]
  }
}

export const setDefaultValueIfNull = ({
  defaultValue, key, originalValue, value,
}) => {
  value[key] = originalValue[key] ? originalValue[key] : defaultValue
}

export const setFormPropertyIfCondition = ({
  conditionProperty,
  conditionValue,
  formData,
  newValue,
  property,
}) => {
  if (formData[conditionProperty] === conditionValue) {
    formData[property] = newValue
  }
}

export const deleteFormPropertyIfCondition = ({
  conditionProperty,
  conditionValue,
  formData,
  property,
}) => {
  if (formData[conditionProperty] === conditionValue) {
    delete formData[property]
  }
}
