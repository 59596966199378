import { fetcher } from '../utils/fetcher'

export const request = () => {}

function unwindParams(params) {
  if (params == null) {
    return ''
  }

  return Object.keys(params).reduce((accum, currVal) => {
    if (!params[currVal] === undefined || params[currVal] === null) {
      return accum
    }
    return `${accum}${currVal}=${params[currVal]}&`
  }, '?')
}

const buildUrl = (url, params) => `${url}${unwindParams(params)}`

export const get = (url, params, props) => {
  return fetcher.get(buildUrl(url, params), { ...props })
}

export const post = (url, payload, action = '', props) => {
  let id
  let data
  if (payload instanceof FormData) {
    id = payload.get('id')
    payload.delete('id')
    data = payload
  } else {
    ;({ id, ...data } = payload)
  }
  if (id) {
    return fetcher.patch(`${url}${id}/${action}`, data)
  }
  return fetcher.post(`${url}${action}`, data, { ...props })
}

export const patch = (url, payload) => fetcher.patch(`${url}`, payload)

export const put = (url, payload, action = '') => {
  let id
  let data
  if (payload instanceof FormData) {
    id = payload.get('id')
    payload.delete('id')
    data = payload
  } else {
    ;({ id, ...data } = payload)
  }
  if (id) {
    return fetcher.put(`${url}${id}/${action}`, data)
  }
}

export const del = (url, payload) => {
  const { id, ...data } = payload
  if (id) {
    return fetcher.delete(`${url}${id}/`, data)
  }
}
