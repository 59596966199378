/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-len */
import { Box, MenuItem } from '@material-ui/core'
import { useMemo } from 'react'

import { useConstants } from '../../../contexts'
import convertKebabToCapitalised from '../../../utils/convertKebabToCapitalised'
import { rebalancingTypes } from '../../portfolioBuilder/forms'
import { VISIBILITY_ICON_MAP } from '../optimiser.constants'



const useOptimiserConstants = () => {
  const constants = useConstants()

  return useMemo(() => {
    const createOption = (value) => ({ label: convertKebabToCapitalised(value), value })
    const objFuncOptionsMap = (constants?.PORTFOLIO_OPTIMISER_OBJECTIVE_FUNCTION_ENUM || []).reduce((acc, key) => {
      acc[key] = convertKebabToCapitalised(key)
      return acc
    }, {})
    const objFuncOptions = Object.entries(objFuncOptionsMap).map(([value, label]) => ({ label, value }))
    const visibilityOptions = (constants?.PORTFOLIO_OPTIMISER_RUN_VISIBILITY_ENUM || []).map(createOption)
    const rebalancingFreqOptions = rebalancingTypes
    const visibilityMenuItems = visibilityOptions?.map(({ label, value }) => (
      <MenuItem
        key={value}
        value={value}
      >
        <Box
          alignItems="center"
          display="flex"
          gridGap={8}
        >
          {VISIBILITY_ICON_MAP[label]}
          {label}
        </Box>
      </MenuItem>
    ))
    return {
      objFuncOptions,
      objFuncOptionsMap,
      rebalancingFreqOptions,
      visibilityMenuItems,
      visibilityOptions
    }
  }, [constants])
}

export default useOptimiserConstants
