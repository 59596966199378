import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { USER_LOGOUT } from '../constants'
import {
  funds as fundsReducer,
  initialState as reducerState,
} from '../reducers/funds'
import { AuthContext } from './auth'

const FundsContext = createContext()

const FundsContextProvider = (props) => {
  const [state, dispatch] = useReducer(fundsReducer, reducerState)
  const [storyData, setStoryData] = useState({})
  const { isLoggedIn } = useContext(AuthContext)

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch({ type: USER_LOGOUT })
    }
  }, [isLoggedIn])

  return (
    <FundsContext.Provider
      value={{
        ...state,
        dispatch,
        setStoryData,
        storyData,
      }}
    >
      {props.children}
    </FundsContext.Provider>
  )
}

export { FundsContext, FundsContextProvider }
