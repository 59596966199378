/* eslint-disable react/jsx-key */
import {
  Flex,
  Group,
  MultiSelect,
  Pill,
  Select,
  Stack,
  TextInput
} from '@mantine/core'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import React, { useContext, useMemo, useState } from 'react'

import DataTable, { fuzzyFilter } from '../../components/tables/DataTable'
import { ConstantsContext } from '../../contexts'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import {
  PeerGroup,
  selectAllPeerGroups,
  selectedPeerGroupChanged
} from '../../redux/peerGroupsSlice'
import useReactTableManager from '../../hooks/useReactTableManager'
import kebabToTitleCase from '../../utils/kebabToTitleCase'

const columnHelper = createColumnHelper<PeerGroup>()

const columns = [
  columnHelper.accessor('name', {
    cell: info => info.getValue(),
    header: 'Name',
    id: 'name'
  }),
  columnHelper.accessor('tags', {
    cell: (info) => (<Flex gap="xs">{info.getValue()?.map((it, idx) => <Pill
      key={idx}
    >{it}</Pill>)}</Flex>),
    enableSorting: false,
    filterFn: 'filterByTags',
    header: 'Tags',
    id: 'tags',
  }),
  columnHelper.accessor('visibilityType', {
    cell: info => kebabToTitleCase(info.getValue()),
    filterFn: 'filterByVisibilityType',
    header: 'Visibility',
    id: 'visibilityType'
  }),
  columnHelper.accessor('username', {
    cell: info => info.getValue(),
    filterFn: 'filterByUsername',
    header: 'User',
    id: 'username',
  }),
  columnHelper.accessor('createdAt', {
    cell: info => moment(info.getValue()).format('YYYY-MM-DD'),
    header: 'Created At',
    id: 'createdAt'
  }),
  columnHelper.accessor('updatedAt', {
    cell: info => moment(info.getValue()).format('YYYY-MM-DD'),
    header: 'Updated At',
    id: 'updatedAt'
  }),
]

const PeerGroupsTable: React.FC = () => {
  const dispatch = useAppDispatch()
  const selectedPeerGroupId = useAppSelector(state => state.peerGroups.selectedGroupId)
  const peerGroups = useAppSelector(selectAllPeerGroups)
  const constants = useContext(ConstantsContext)

  const [globalSearchValue, setGlobalSearchValue] = useState('')
  const [columnVisibility, setColumnVisibility] = useState({})

  const tagOptions = useMemo(() => {
    const tags = peerGroups.flatMap(it => it.tags)
    return Array.from(new Set(tags))
  }, [peerGroups])
  const userOptions = useMemo(() => {
    const users = peerGroups.map(it => it.username)
    return Array.from(new Set(users))
  }, [peerGroups])

  const { table } = useReactTableManager<PeerGroup>({
    columns,
    data: peerGroups,
    filterFns: {
      filterByTags: (row, columnId, filterValue) => {
        const tags = row.getValue(columnId)
        return tags.some((tag: any) => filterValue.includes(tag)) || filterValue.length === 0
      },
      filterByUsername: (row: any, columnId: any, filterValue: any) => {
        const username = row.getValue(columnId)
        return filterValue.includes(username) || filterValue.length === 0
      },
      filterByVisibilityType: (row: any, columnId: any, filterValue: any) => {
        const visibilityType = row.getValue(columnId)
        console.log('filterValue', filterValue)
        return filterValue === null || filterValue?.includes(visibilityType) || filterValue?.length === 0
      }
    },
    globalFilterFn: fuzzyFilter,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalSearchValue,
    state: {
      columnVisibility,
      globalFilter: globalSearchValue
    },
  })

  const changeArrayFilterHandler = (key: string) => (values: string[]) => table.getColumn(key)?.setFilterValue(values)
  const changeGlobalSearchValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => setGlobalSearchValue(e.target.value)
  const clickTableRowHandler = (data: PeerGroup) => dispatch(selectedPeerGroupChanged(data.id))

  return (
    <Stack gap={20}>
      <Group>
        <TextInput
          onChange={changeGlobalSearchValueHandler}
          placeholder="Search"
          value={globalSearchValue}
        />
        <MultiSelect
          data={tagOptions}
          onChange={changeArrayFilterHandler('tags')}
          placeholder="Tags"
        />
        <MultiSelect
          data={userOptions}
          onChange={changeArrayFilterHandler('username')}
          placeholder="User"
        />
        <Select
          data={constants.PEER_GROUP_VISIBILITY_TYPE_ENUM}
          onChange={changeArrayFilterHandler('visibilityType')}
          placeholder="Visibility"
        />
      </Group>
      <DataTable
        activeRow={selectedPeerGroupId}
        activeRowIdType="id"
        onClickRow={clickTableRowHandler}
        table={table}
      />
    </Stack>
  )
}

export default PeerGroupsTable
