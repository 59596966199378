import React, { createContext, useState, useEffect, useContext } from "react"
import { getAppConstants } from "../services"
import { AuthContext } from "./auth"

const ConstantsContext = createContext()

export const useConstants = () => {
  const context = useContext(ConstantsContext)

  if (context === undefined) {
    throw new Error("useConstants must be used within a ConstantsContextProvider")
  }

  return context
}

const ConstantsContextProvider = (props) => {
  const { isAuthorized } = useContext(AuthContext)

  const [constants, setConstants] = useState({})

  useEffect(() => {
    const fetchAppConstants = async () => {
      try {
        const response = await getAppConstants()
        setConstants(response.data.constants)
      } catch (error) {
        console.log(error)
      }
    }

    if (isAuthorized && Object.keys(constants).length < 1) {
      fetchAppConstants()
    }
  }, [isAuthorized])

  return (
    <ConstantsContext.Provider value={constants}>
      {props.children}
    </ConstantsContext.Provider>
  )
}

export { ConstantsContext, ConstantsContextProvider }
