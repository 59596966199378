import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { formatPercentage } from '../../utils'

const KeyStatsSkeleton = () => (
  <Skeleton
    variant="text"
    width={60}
    height={50}
    style={{ backgroundColor: '#ced9e0' }}
  />
)

const KeyStats = ({ security_details, loading }) => {
  const { calculated_statistics } = security_details || {}
  const { last_month_ret, ytd, ann_ret } = calculated_statistics || {}

  return (
    <div className="key-stats">
      <div className="stats-container">
        <Typography variant="subtitle1">MTD RETURN</Typography>
        <Typography variant="h5">
          {typeof last_month_ret === 'number' ? (
            formatPercentage(last_month_ret)
          ) : loading ? (
            <KeyStatsSkeleton />
          ) : null}
        </Typography>
      </div>
      <div className="stats-container middle">
        <Typography variant="subtitle1">YTD RETURN</Typography>
        {typeof ytd === 'number' ? (
          <Typography variant="h5">{formatPercentage(ytd)}</Typography>
        ) : loading ? (
          <KeyStatsSkeleton />
        ) : null}
      </div>
      <div className="stats-container">
        <Typography variant="subtitle1">ANNUALISED RETURN</Typography>
        {typeof ann_ret === 'number' ? (
          <Typography variant="h5">{formatPercentage(ann_ret)}</Typography>
        ) : loading ? (
          <KeyStatsSkeleton />
        ) : <span>N/A</span>}
      </div>
    </div>
  )
}

export default KeyStats
