import React, { Component } from "react"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
// import PlainTable from "../../components/tables/plainTable";
import { Link } from "react-router-dom"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import useMixpanelTimeOnComponent from "../../hooks/useMixpanelTimeOnComponent"

export const OperationsTab = () => {
  return (
    <div className="operations-container">
      <div className="flows">
        <div className="manager-flows">
          <Card>
            <Typography variant="h6">Manager Flows</Typography>
            <CardContent>
              <Button
                className="view-all-button"
                color="primary"
                component={Link}
                endIcon={<ChevronRightIcon />}
                to="/operating-flows"
              >
                View All
              </Button>
            </CardContent>
          </Card>
        </div>
        <div className="investor-flows">
          <Card>
            <Typography variant="h6">Investor Flows</Typography>
            <CardContent>
              <Button
                className="view-all-button"
                color="primary"
                component={Link}
                endIcon={<ChevronRightIcon />}
                to="/operating-flows"
              >
                View All
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="cash-flow">
        <div className="current-cash">
          <Card>
            <Typography variant="h6">Current Cash</Typography>
            <CardContent>
              <Button
                className="view-all-button"
                color="primary"
                component={Link}
                endIcon={<ChevronRightIcon />}
                to="/cash-management"
              >
                View All
              </Button>
            </CardContent>
          </Card>
        </div>
        <div className="projected-cash">
          <Card>
            <Typography variant="h6">Projected Cash</Typography>
            <CardContent>
              <Button
                className="view-all-button"
                color="primary"
                component={Link}
                endIcon={<ChevronRightIcon />}
                to="/cash-management"
              >
                View All
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="diagnostics">
        <div className="tracking-error">
          <Card>
            <Typography variant="h6">Tracking Error</Typography>
            <CardContent>
              <Button
                className="view-all-button"
                color="primary"
                component={Link}
                endIcon={<ChevronRightIcon />}
                to="/platform-diagnostic"
              >
                View All
              </Button>
            </CardContent>
          </Card>
        </div>
        <div className="expense-ratio">
          <Card>
            <Typography variant="h6">Total Expense Ratio</Typography>
            <CardContent>
              <Button
                className="view-all-button"
                color="primary"
                component={Link}
                endIcon={<ChevronRightIcon />}
                to="/platform-diagnostic"
              >
                View All
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default OperationsTab