import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import EditIcon from '@material-ui/icons/Edit'
import DuplicateIcon from '@material-ui/icons/FileCopy'
import DownloadIcon from '@material-ui/icons/GetApp'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Skeleton from '@material-ui/lab/Skeleton'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { useContext, useState } from 'react'

import { DELETE_PORTFOLIO } from '../../constants'
import { FundsContext, PortfolioContext } from '../../contexts'
import { useMixpanel } from '../../hooks/useMixpanel'
import router from '../../resources/history'
import {
  deletePortfolio,
  generateAaapFactSheet,
  generateFundFactSheet,
  generatePortfolioFactSheet,
} from '../../services'
import { buildFileFromResponse } from '../../utils'
import { fundStatusStylesMap } from '../screener/filterBar'

const factSheetGenerators = {
  'AAAP Cell': generateAaapFactSheet,
  Portfolio: generatePortfolioFactSheet,
}

async function generateFactSheet(security_type, id) {
  const generate = factSheetGenerators[security_type] || generateFundFactSheet
  return await generate(id)
}

const BasicDetails = (props) => {
  const { mpTrack } = useMixpanel()
  const [portfolioPopOver, setPortfolioPopOver] = useState(false)
  const [portfolioPopOverAnchor, setPortfolioPopOverAnchor] = useState(null)
  const { clonePortfolio } = useContext(PortfolioContext)
  const { dispatch } = useContext(FundsContext)

  const onPortfolioMenuClick = (e) => {
    setPortfolioPopOver(!portfolioPopOver)
    setPortfolioPopOverAnchor(e.currentTarget)
  }

  const constructPortfolio = () => {
    const { search_list_map, security_details } = props

    let portfolio = cloneDeep(security_details)

    const {
      benchmarks,
      cost,
      description,
      hurdle,
      id,
      inception_date,
      management_fee,
      name,
      performance_fee,
      portfolio_type: original_type,
      portfolio_weights: original_weights,
      prorated,
      rebalancing_type,
    } = portfolio || {}

    let portfolio_type
    if (original_type === 'AAAP Model Portfolio') {
      portfolio_type = 'AAAP Portfolio'
    } else if (original_type === 'Internal Public Portfolio') {
      portfolio_type = 'Internal Private Portfolio'
    } else {
      portfolio_type = original_type
    }

    let portfolio_weights = original_weights.map((o) => ({
      security: search_list_map?.[o.security],
      weight: o.weight * 100,
    }))
    portfolio_weights.push({ security: '', weight: null })

    const single_portfolio = {
      benchmarks,
      cost,
      description,
      hurdle,
      id,
      inception_date,
      management_fee,
      name,
      performance_fee,
      portfolio_type,
      portfolio_weights,
      prorated,
      rebalancing_type,
    }

    return single_portfolio
  }

  const handleClone = () => {
    const single_portfolio = constructPortfolio()
    const cloned_portfolio = {
      ...single_portfolio,
      description: '',
      id: null,
      name: '',
    }
    clonePortfolio(cloned_portfolio)
    router.push('/screener/portfolio-builder')
  }

  const checkStatus = () => {
    const { security_details } = props
    const { status } = security_details || {}
    let color
    color = fundStatusStylesMap[status] || fundStatusStylesMap['OTHERS']

    if (status) {
      return (
        <Button
          className="status"
          style={{
            backgroundColor: color,
            cursor: 'default',
          }}
        >
          {status}
        </Button>
      )
    }
  }

  const handleEdit = () => {
    const single_portfolio = constructPortfolio()
    clonePortfolio(single_portfolio)
    router.push('/screener/portfolio-builder')
  }

  const handleDelete = async () => {
    const { security_details } = props
    try {
      await deletePortfolio(security_details)
      router.push('/my-portfolios')
      dispatch({
        payload: security_details,
        type: DELETE_PORTFOLIO,
      })
    } catch (error) {}
  }

  const renderMenu = () => {
    const { security_details } = props
    const { portfolio_type } = security_details || {}
    let available_actions = [
      { action: handleClone, icon: <DuplicateIcon />, title: 'Clone' },
    ]
    if (
      portfolio_type !== 'AAAP Model Portfolio' &&
      portfolio_type !== 'Internal Public Portfolio' &&
      portfolio_type !== 'Webfolio'
    ) {
      available_actions.push({
        action: handleEdit,
        icon: <EditIcon />,
        title: 'Edit',
      })
      available_actions.push({
        action: handleDelete,
        icon: <DeleteOutlinedIcon />,
        title: 'Delete',
      })
    }
    return (
      <Popover
        anchorEl={portfolioPopOverAnchor}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        id={'portfolio-popover'}
        onClose={onPortfolioMenuClick}
        open={portfolioPopOver}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <List>
          {available_actions.map((item, idx) => (
            <ListItem button key={idx} onClick={item.action}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </Popover>
    )
  }

  const downloadFactSheet = async () => {
    const { security_details, security_type = '' } = props
    const { id } = security_details || {}
    const response = await generateFactSheet(security_type, id)

    buildFileFromResponse(response)

    mpTrack({
      eventName: 'Download Fact Sheet',
      properties: {
        Fund: security_details.name,
        'Fund ID': id,
      },
    })
  }

  const {
    access_internal,
    security_details,
    security_type = '',
    loading,
  } = props
  const { calculated_statistics, strategy } = security_details
  const is_portfolio = security_type === 'portfolio'
  const is_fund = ['AAAP Cell', 'Market Index'].indexOf(security_type) === -1
  const is_cell = security_type === 'AAAP Cell'
  const is_fof = security_type === 'FoF'
  const downloadable = !!((is_portfolio || is_fund) && !is_fof)
  const { stats_date } = calculated_statistics || {}
  const formatted_date = moment(stats_date).format('DD MMM YYYY')
  let show_download = !!((access_internal && is_cell) || downloadable)
  if (!security_type) {
    show_download = false
  }
  return (
    <>
      <div className="basic-details">
        <div className="asset-name-date">
          <div className="asset-name">
            <Typography variant="h6">
              {loading && !security_details.underlying_name ? (
                <Skeleton variant="text" />
              ) : (
                security_details.underlying_name
              )}
            </Typography>
            <Typography variant="h6">
              {loading && !security_details.name ? (
                <Skeleton variant="text" />
              ) : (
                security_details.name
              )}{' '}
              {show_download && (
                <IconButton
                  className="download-button"
                  onClick={downloadFactSheet}
                >
                  <DownloadIcon />
                </IconButton>
              )}
            </Typography>
            <Typography className="asset-date" variant="caption">
              Last updated: {formatted_date}
              {/* <CalendarIcon className="calendar-icon" /> */}
            </Typography>
          </div>
          {security_type === 'Portfolio' && (
            <div className="action-button">
              <IconButton
                aria-describedby="portfolio-popover"
                onClick={onPortfolioMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          )}

          {renderMenu()}
          <div className="status-date">
            <div className="strategy">{strategy}</div>
            {checkStatus()}
          </div>
        </div>
        <div className="summary-underlying-fund"></div>
      </div>
    </>
  )
}

export default BasicDetails
