import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useMemo, useState } from 'react'

import Transition from '../../components/misc/SlideUpDialogTransition'
import { useSecurities } from '../../hooks'
import useCreateBooklet from '../../hooks/useCreateBooklet'
import { useMixpanel } from '../../hooks/useMixpanel'
import useSnackbar from '../../hooks/useSnackbar'
import useUpdateBooklet from '../../hooks/useUpdateBooklet'
import { getFundGroup } from '../../services'

export const factSheetsUpdateMode = {
  addFunds: 'addFunds',
  newList: 'newList',
  updateName: 'updateName',
}

const modeDialogHeaderMap = {
  [factSheetsUpdateMode.updateName]: 'Edit Booklet Name',
  [factSheetsUpdateMode.addFunds]: 'Add Funds',
  [factSheetsUpdateMode.newList]: 'Create New Booklet',
}

const modeSnackbarMsgMap = {
  [factSheetsUpdateMode.updateName]: 'Name updated',
  [factSheetsUpdateMode.addFunds]: 'Funds added',
  [factSheetsUpdateMode.newList]: 'New booklet created',
}

const defaultSelectedFunds = {}

const FactSheetsUpdateDialog = ({
  open,
  selectedBooklet,
  setOpen,
}) => {
  const { mpTrack } = useMixpanel()
  const { openSnackbar } = useSnackbar()
  const { aaap_cell_list, aaap_cell_list_map, aaap_classes } = useSecurities()
  const { data: groupFundData } = useQuery(
    ['groupFunds'], () => getFundGroup(),
    {
      select: (res) => ({
        groupFundIds: [...new Set(res?.data?.flatMap(({ funds }) => funds))],
        shareClasses: [...new Set(res?.data?.flatMap(({ share_classes }) => share_classes?.map((it) => it.replace(/^Class\s(.).*$/, 'Class $1'))))]?.sort(),
      }),
    },
  )
  const [selectedShareClass, setSelectedShareClass] = useState('')
  const [selectedFunds, setSelectedFunds] = useState(defaultSelectedFunds)
  const { isUpdatingBooklet, updateFactSheets } = useUpdateBooklet(() => {
    setSelectedFunds(defaultSelectedFunds)
    setOpen(false)
    openSnackbar(modeSnackbarMsgMap[open])
  })
  const { createBooklet, isCreatingBooklet } = useCreateBooklet(() => {
    setSelectedFunds(defaultSelectedFunds)
    setOpen(false)
    openSnackbar(modeSnackbarMsgMap[open])
  })
  const [filteredFundOptions, setFilteredFundOptions] = useState([])
  const selectedFundsCount = useMemo(() => Object.values(selectedFunds).filter((it) => it).length, [selectedFunds])
  const { groupFundIds, shareClasses } = groupFundData || {}
  const disableDialog = isUpdatingBooklet || isCreatingBooklet

  useEffect(() => {
    if (selectedBooklet && open === factSheetsUpdateMode.addFunds) {
      setSelectedShareClass(selectedBooklet.share_class)
    }
  }, [selectedBooklet, selectedShareClass, open])

  useEffect(() => {
    let list = groupFundIds?.map((id) => aaap_cell_list_map[id])
    const selected = {}
    if (open === factSheetsUpdateMode.addFunds && selectedShareClass && list) {
      list = JSON.parse(JSON.stringify(list))
      list?.forEach((fund) => {
        if (selectedBooklet.funds.some(({ fund_id }) => fund_id === fund.id) && selectedBooklet.share_class === fund?.class_name) {
          fund.alreadySelected = true
          selected[fund.id] = true
        }
      })
    }
    setFilteredFundOptions(list?.filter((fund) => fund?.class_name === selectedShareClass))
    setSelectedFunds(selected)
  }, [open, aaap_cell_list, selectedBooklet, aaap_classes, selectedShareClass, setSelectedFunds, aaap_cell_list_map])

  const closeDialogHandler = () => {
    if (disableDialog) {
      return
    }

    setOpen(null)
  }

  const clickFundItemHandler = (fundId) => () => {
    setSelectedFunds({
      ...selectedFunds,
      [fundId]: !selectedFunds[fundId],
    })
  }

  const createNewBooklet = ({ newOrUpdatedBookletName, newSelectedFunds }) => {
    createBooklet({
      funds: newSelectedFunds,
      name: newOrUpdatedBookletName,
      share_class: selectedShareClass,
    })
  }

  const updateBooklet = ({ newOrUpdatedBookletName, newSelectedFunds }) => {
    updateFactSheets({
      ...selectedBooklet,
      funds: [...selectedBooklet.funds.map(({ id }) => ({ fund_id: id, id })), ...newSelectedFunds],
      name: newOrUpdatedBookletName || selectedBooklet.name,
    })
  }

  const clickSubmitButtonHandler = (event) => {
    event.preventDefault()
    const newOrUpdatedBookletName = event.target?.name?.value
    const newSelectedFunds = Object.entries(selectedFunds)?.filter(([key, value]) => value)?.map(([key]) => ({ fund_id: key }))

    switch (open) {
      case factSheetsUpdateMode.newList: {
        mpTrack({
          eventName: 'Created New booklet',
          properties: {
            'Booklet Name': newOrUpdatedBookletName,
            'Fund IDs': newSelectedFunds.map(({ fund_id }) => fund_id),
            'Fund Names': newSelectedFunds.map(({ fund_id }) => aaap_cell_list_map[fund_id]?.name),
          },
        })
        createNewBooklet({
          newOrUpdatedBookletName,
          newSelectedFunds,
        })
        break
      }
      case factSheetsUpdateMode.updateName: {
        mpTrack({
          eventName: 'Changed Booklet Name',
          properties: {
            'Booklet ID': selectedBooklet.id,
            'New Booklet Name': newOrUpdatedBookletName,
            'Old Booklet Name': selectedBooklet.name,
          },
        })
        updateBooklet({
          newOrUpdatedBookletName,
          newSelectedFunds,
        })
        break
      }
      case factSheetsUpdateMode.addFunds: {
        mpTrack({
          eventName: 'Added to existing booklet',
          properties: {
            'Added Fund IDs': newSelectedFunds.map(({ fund_id }) => fund_id),
            'Added Fund Names': newSelectedFunds.map(({ fund_id }) => aaap_cell_list_map[fund_id]?.name),
            'Booklet ID': selectedBooklet.id,
            'Booklet Name': selectedBooklet.name,
          },
        })
        updateBooklet({
          newOrUpdatedBookletName,
          newSelectedFunds,
        })
        break
      }
      default: {
        break
      }
    }
  }

  const changeShareClassHandler = (event) => {
    setSelectedShareClass(event.target.value)
  }

  const dialogExitedHandler = () => {
    setSelectedShareClass('')
  }

  const clickSelectAllButtonHandler = () => {
    const selected = {}
    filteredFundOptions.forEach(({ id }) => { selected[id] = true })
    setSelectedFunds(selected)
  }

  const clickDeselectAllButtonHandler = () => {
    const selected = {}
    filteredFundOptions.forEach(({ alreadySelected, id }) => {
      if (alreadySelected) {
        selected[id] = true
      }
    })
    setSelectedFunds(selected)
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      TransitionProps={{
        onExited: dialogExitedHandler,
      }}
      fullWidth
      maxWidth="sm"
      onClose={closeDialogHandler}
      open={!!open}
    >
      <DialogTitle>
        {open && modeDialogHeaderMap[open]}
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <form
            disabled={disableDialog}
            id="wlUpdateForm"
            onSubmit={clickSubmitButtonHandler}
          >
            {
              open === factSheetsUpdateMode.newList && (
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap={12}
                  mb={2}
                >
                  <TextField
                    disabled={disableDialog}
                    fullWidth
                    name="name"
                    placeholder="Booklet Name"
                    required
                  />
                  <TextField
                    SelectProps={{
                      displayEmpty: true,
                    }}
                    fullWidth
                    onChange={changeShareClassHandler}
                    required
                    select
                    value={selectedShareClass}
                  >
                    <MenuItem
                      disabled
                      value=""
                    >
                      Select Share Class
                    </MenuItem>
                    {
                      shareClasses?.map((opt) => (
                        <MenuItem
                          key={opt}
                          value={opt}
                        >
                          {opt}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </Box>
              )
            }
            {
              open === factSheetsUpdateMode.updateName
                ? (
                  <TextField
                    defaultValue={selectedBooklet?.name}
                    disabled={disableDialog}
                    fullWidth
                    name="name"
                    placeholder="Name"
                    required
                  />
                )
                : selectedShareClass && (
                  <Box>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      mb={1}
                    >
                      <Button onClick={clickSelectAllButtonHandler}>Select All</Button>
                      <Button onClick={clickDeselectAllButtonHandler}>Deselect All</Button>
                    </Box>
                    {
                      filteredFundOptions?.length > 0
                        ? (
                          <Box className="booklet-funds-selection-list">
                            <Box>
                              <List>
                                {
                                  filteredFundOptions?.sort((a, b) => a?.name?.localeCompare(b.name))?.map(({
                                    alreadySelected, id, name, underlying_name,
                                  }) => (
                                    <ListItem
                                      button
                                      dense
                                      disabled={disableDialog || alreadySelected}
                                      key={id}
                                      onClick={clickFundItemHandler(id)}
                                      role={undefined}
                                    >
                                      <ListItemIcon>
                                        <Checkbox
                                          checked={!!selectedFunds[id]}
                                          disableRipple
                                          edge="start"
                                          size="small"
                                          tabIndex={-1}
                                        />
                                      </ListItemIcon>
                                      <ListItemText>
                                        <Typography
                                          color="secondary"
                                          variant="body2"
                                        >
                                          {name}
                                        </Typography>
                                        <Typography variant="caption">
                                          {underlying_name}
                                        </Typography>
                                      </ListItemText>
                                    </ListItem>
                                  ))
                                }
                              </List>
                            </Box>
                          </Box>
                        )
                        : (
                          <Typography>
                            No funds available
                          </Typography>
                        )
                    }
                  </Box>
                )
            }
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          alignItems="center"
          display="flex"
          gridGap={12}
          pr={2}
        >
          {
            open !== factSheetsUpdateMode.updateName
            && (
              <Typography>
                {selectedFundsCount}
                {' '}
                selected
              </Typography>
            )
          }
          <Button
            color="primary"
            disabled={(open === factSheetsUpdateMode.addFunds && selectedFundsCount < 1) || disableDialog}
            form="wlUpdateForm"
            type="submit"
            variant="contained"
          >
            Confirm
          </Button>
          <Button
            color="primary"
            disabled={disableDialog}
            onClick={closeDialogHandler}
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default FactSheetsUpdateDialog
