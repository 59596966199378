/* eslint-disable indent */
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import {
  HUBSPOT_BLOG_POST_ENDPOINT,
  HUBSPOT_PROXY_URL,
  IS_PROD_ENV,
} from '../constants/env'
import { BASE_ROUTE_TYPES } from '../constants/routes.constants'
import { getNews, getSingleDocument } from '../services'

const insightsPerPage = 12
const tagIds = {
  hedgeFund: process.env.REACT_APP_HEDGE_FUND_HS_TAG_ID,
  privateEquity: process.env.REACT_APP_PRIVATE_EQUITY_HS_TAG_ID,
}

export const getHubspotInsightsUrl = ({
  childrenPath = '',
  type,
  ...props
}) => {
  const queryParams = {
    ...props,
    contentGroupId: process.env.REACT_APP_HS_CONTENT_GROUP_ID,
    state: IS_PROD_ENV ? 'PUBLISHED' : null,
    sort: '-publishDate',
  }

  if (tagIds[type]) {
    queryParams.tagId = tagIds[type]
  }

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      delete queryParams[key]
    }
  })

  const queryParamsString = new URLSearchParams(queryParams).toString()

  return `${HUBSPOT_BLOG_POST_ENDPOINT}${childrenPath}?${queryParamsString}`
}

const useInsights = ({ insightId, isLegacy, type, maxSlice } = {}) => {
  const queryClient = useQueryClient()
  const [insight, setInsight] = useState()
  const [recentInsights, setrecentInsights] = useState([])
  const { data: legacyInsightData, isLoading: isLoadingSingleLegacyInsight } =
    useQuery(
      ['insights', 'legacy', insightId],
      async () => {
        const res = await getSingleDocument(insightId)
        return res.data
      },
      {
        enabled: !!isLegacy,
        staleTime: Infinity,
        onSuccess: (res) => {
          queryClient.setQueryData(['insights', 'legacy', insightId], res.data)
        },
      },
    )

  const {
    error: hsInsightsError,
    data: hubspotInsights,
    isLoading: isLoadingHsInsights,
    isFetching: isFetchingHSInsights,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['insights', 'hubspot', type],
    async ({ pageParam = 0 }) => {
      const res = await axios.post(HUBSPOT_PROXY_URL, {
        url: getHubspotInsightsUrl({
          offset: pageParam * insightsPerPage,
          limit: insightsPerPage,
          type,
        }),
        method: 'GET',
      })

      res?.data?.results?.forEach((it) => {
        if (it?.id) {
          queryClient.setQueryData(['insights', 'hubspot', String(it.id)], it)
        }
      })

      return {
        data: res?.data?.results || [],
        totalInsights: res?.data?.total,
      }
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (
          pages.length * insightsPerPage === lastPage.totalInsights ||
          lastPage.data.length < insightsPerPage
        ) {
          return undefined
        }

        return pages.length
      },
      select: (data) => {
        const hsInsightsData = []
        data?.pages.forEach((it) => {
          hsInsightsData.push(...(it?.data || []))
        })
        return hsInsightsData
      },
      keepPreviousData: true,
    },
  )
  const {
    data: legacyInsights,
    isLoading: isLoadingLegacyInsights,
    error: legacyInsightError,
  } = useQuery(
    ['insights', 'legacy'],
    async () => {
      const res = await getNews()
      return res.data
    },
    {
      staleTime: Infinity,
    },
  )

  const { refetch: fetchInsightById, isFetching: isFetchingHsInsight } =
    useQuery(
      ['insights', 'hubspot', insightId],
      async () => {
        const res = await axios.post(HUBSPOT_PROXY_URL, {
          url: getHubspotInsightsUrl({
            childrenPath: `/${insightId}`,
          }),
          method: 'GET',
        })

        return res?.data
      },
      {
        enabled: false,
        staleTime: Infinity,
        onSuccess: (data) => {
          setInsight(data)
        },
      },
    )

  const sortedInsights = useMemo(() => {
    if (hubspotInsights && legacyInsights) {
      const allInsights =
        type === BASE_ROUTE_TYPES.PRIVATE_MARKET
          ? hubspotInsights
          : [...hubspotInsights, ...legacyInsights]

      return allInsights
        ?.sort(
          (a, b) =>
            new Date(b.publishDate || b.as_of_date) -
            new Date(a.publishDate || a.as_of_date),
        )
        ?.slice(0, maxSlice)
    }
    return []
  }, [hubspotInsights, legacyInsights, maxSlice, type])

  useEffect(() => {
    if (!isLegacy && insightId && !isLoadingHsInsights) {
      const insightFound = queryClient.getQueryData([
        'insights',
        'hubspot',
        insightId,
      ])

      if (insightFound) {
        setInsight(insightFound)
      } else {
        fetchInsightById()
      }
    }

    if (isLegacy && legacyInsightData) {
      setInsight({
        name: legacyInsightData?.name,
        publishDate: legacyInsightData?.as_of_date,
        featuredImage: legacyInsightData?.cover_image,
        postBody: legacyInsightData?.body,
        ...legacyInsightData,
      })
    }
  }, [insightId, isLegacy, legacyInsightData, isLoadingHsInsights])

  useEffect(() => {
    if (insightId && hubspotInsights) {
      setrecentInsights(
        hubspotInsights
          .filter(({ id }) => String(id) !== insightId)
          .slice(0, 3),
      )
    }
  }, [insightId, hubspotInsights])

  return {
    legacyInsights,
    hubspotInsights,
    insight,
    isLoadingHsInsights: isLoadingHsInsights || isFetchingHsInsight,
    isFetchingHSInsights,
    isLoadingLegacyInsights,
    isLoadingSingleLegacyInsight,
    error: hsInsightsError || legacyInsightError,
    recentInsights,
    hasNextPage,
    fetchNextPage,
    sortedInsights,
  }
}

export default useInsights
