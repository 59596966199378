import React from 'react'
import { IconStar } from '@tabler/icons-react'
import { ActionIcon } from '@mantine/core'
import { useAppDispatch } from '../../hooks/useRedux'
import { fundLaunchPopUpToggled } from '../../redux/uiSlice'
import { usePopUpData } from '../../hooks/usePopUpData'

const HubPopUpButton = () => {
  const dispatch = useAppDispatch()
  const { data: popUpData } = usePopUpData()
  const open = () => dispatch(fundLaunchPopUpToggled(true))
  return popUpData?.currentlyPublished ? (
    <ActionIcon variant="transparent" onClick={() => open()}>
      <IconStar color="#FFD700" />
    </ActionIcon>
  ) : null
}

export default HubPopUpButton