import moment from "moment"

import { TradingCalendarFormMode } from "../models"

const useSerialiseTradingCalendarFormData = () => {
  const serialiseTradingCalendarFormData = (formData: TradingCalenderInputFormUIData) => {
    let serialisedData: TradingCalendarInputFormApiRequestData = { ...formData as any }

    switch (formData.mode) {
      case TradingCalendarFormMode.Custom:
        serialisedData.startDate = moment(formData.startDate).format("DD-MM-YYYY")
        serialisedData.intervalAnchor = 'custom'
        break
      case TradingCalendarFormMode.Regular:
        serialisedData.startDate = `01-01-${formData.year}`
        serialisedData.intervalAnchor = formData.isFirstDate ? 'first' : 'last'
        break
      case TradingCalendarFormMode.Fund:
        serialisedData.intervalAnchor = formData.isFirstDate ? 'first' : 'last'
        break
      default:
        return
    }

    delete serialisedData.year
    delete serialisedData.isFirstDate
    delete serialisedData.mode

    serialisedData.countries = formData.countries.map(it => it.value)
    return serialisedData
  }

  return {
    serialiseTradingCalendarFormData
  }
}

export default useSerialiseTradingCalendarFormData