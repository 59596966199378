import { useMemo } from 'react';
import { useSecurities } from './useSecurities';
import { classOrder } from './useProcessedInsightArticle';

const useAAAPUnderlyingIdClassMap = () => {
  const { aaap_cell_list: aaapFunds } = useSecurities()
  return useMemo(() => {
    const map: { [key: number]: number[] } = {};

    aaapFunds.forEach((fund) => {
      const underlyingFundId = fund?.underlying_securities?.[0]?.original_security?.id
      if (underlyingFundId) {
        if (!map[underlyingFundId]) {
          map[underlyingFundId] = [];
        }

        map[underlyingFundId].push(fund.id);
      }
    });

    // Sort the arrays based on the class order
    Object.keys(map).forEach((key) => {
      map[Number(key)].sort((a, b) => {
        const classA = aaapFunds.find(f => f.id === a)?.name.split(' - Class ')[1];
        const classB = aaapFunds.find(f => f.id === b)?.name.split(' - Class ')[1];
        return (classOrder[classA] ?? Infinity) - (classOrder[classB] ?? Infinity);
      });
    });

    return map;
  }, [aaapFunds]);
};

export default useAAAPUnderlyingIdClassMap;