import React, { useState, useEffect } from "react";
import {
  getGroups,
  getAvailableBooklets,
  addToAvailableBooklets,
  deleteAvailableBooklets,
  downloadUnwatermarkedBooklet,
} from "../../services";
import { MonthlyDatePicker } from "../../components/misc";
import { dateTimeManager as dt, buildFileFromResponse } from "../../utils";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";

const BookletsCheckList = ({
  groupList,
  availableBooklets,
  handleCheck,
  handleDownload,
}) => {
  return (
    <Paper elevation={4}>
      <List className="toolbar-menu">
        {groupList.map((value) => {
          const labelId = `checkbox-list-label-${value}`;
          return (
            <ListItem key={value} role={undefined} dense button>
              <ListItemIcon>
                <Checkbox
                  onClick={() => {
                    handleCheck({ group: value });
                  }}
                  edge="start"
                  checked={availableBooklets.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
              <ListItemSecondaryAction>
                <IconButton
                  className="icon-button"
                  edge="end"
                  aria-label="comments"
                  onClick={() => handleDownload({ group: value })}
                >
                  <CloudDownloadOutlinedIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

export default function BookletsManagement() {
  const [groupList, setGroupList] = useState([]);
  const [availableBooklets, setAvailableBooklets] = useState([]);
  const [currentDate, setCurrentDate] = useState(dt.previousMonthEnd());

  const downloadBooklet = async ({ group }) => {
    const toastId = toast.info("Preparing the booklet...", {
      autoClose: 10000,
    });
    try {
      const response = await downloadUnwatermarkedBooklet({
        group,
        booklet_date: dt.jsToDb(currentDate),
      });
      buildFileFromResponse(response);
    } catch (error) { }

    toast.dismiss(toastId);
  };

  const addToBooklest = async (payload) => {
    try {
      const response = await addToAvailableBooklets(payload);
      const newGroup = response.data;
      setAvailableBooklets((i) => [
        ...i,
        { ...newGroup, date: dt.dbToJs(newGroup.date) },
      ]);
    } catch (error) { }
  };

  const deleteBooklet = async (payload) => {
    try {
      await deleteAvailableBooklets(payload);
      setAvailableBooklets((i) => i.filter((o) => o.id !== payload.id));
    } catch (error) { }
  };

  const handleCheck = ({ group }) => {
    const exist = availableBooklets.filter(
      (o) => o.group === group && o.date.getTime() === currentDate.getTime()
    );
    if (exist.length > 0) {
      deleteBooklet(exist[0]);
    } else {
      addToBooklest({ group, date: dt.jsToDb(currentDate) });
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await getGroups();
      const cellGroups = response.data
        .map((o) => o.name)
        .filter((o) => !o.includes("Role"))
        .sort();
      setGroupList(cellGroups);
    } catch (error) { }
  };
  const fetchBooklets = async () => {
    try {
      let response = await getAvailableBooklets({ full: true });
      for (let i = 0; i < response.data.length; i++) {
        let entry = response.data[i];
        entry.date = dt.dbToJs(entry.date);
      }
      setAvailableBooklets(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    fetchGroups();
    fetchBooklets();
  }, []);
  const filteredBooklets = availableBooklets
    .filter((o) => o.date.getTime() === currentDate.getTime())
    .map((o) => o.group);
  return (
    <div className="booklets-management">
      <div className="booklet-date">
        <Typography variant="h6">Date: </Typography>
        <MonthlyDatePicker
          value={currentDate}
          onChange={({ target }) => setCurrentDate(dt.toMonthEnd(target.value))}
        />
      </div>
      <div className="available-booklets">
        <BookletsCheckList
          groupList={groupList}
          availableBooklets={filteredBooklets}
          handleCheck={handleCheck}
          handleDownload={downloadBooklet}
        />
      </div>
    </div>
  );
}
