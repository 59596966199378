import { useState } from 'react'

const useOptimiserTags = (defaultTags = []) => {
  const [inputTags, setInputTags] = useState(defaultTags)

  const addTag = (newTagValue) => setInputTags([...inputTags, newTagValue])

  const removeTag = (deleteTagIndex) => () => setInputTags([...inputTags.filter((tag, idx) => idx !== deleteTagIndex)])

  const resetTags = (value) => setInputTags(value || defaultTags)

  return {
    addTag,
    removeTag,
    inputTags,
    setInputTags,
    resetTags,
  }
}

export default useOptimiserTags
