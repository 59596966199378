import { useMemo, useContext } from 'react';

import { AuthContext } from '../contexts';

const useAccessRights = () => {
  const { accessRights } = useContext(AuthContext);
  return useMemo(() => accessRights, [accessRights]);
};

export default useAccessRights;
