import React from 'react'
import Typography from '@material-ui/core/Typography'
import ReportSnippets from '../../utils/reportInputSnippets'

export default function PerformanceInput({
  performance_inputs,
  updateInputs,
  getPerformance,
  fetched,
  ...props
}) {
  const reportSnippets = new ReportSnippets({
    ...props,
    data: performance_inputs,
    updateInputs,
    submitFunction: getPerformance,
  })

  return (
    <div className="security-setup-container">
      <Typography align="center" variant="h6">
        Performance Update
      </Typography>
      {reportSnippets.globalSearch('security', 'Fund', null, null, [
        'Market Index',
        'AAAP Cell',
        'Portfolio',
        'FoF Share Class',
        'FoF',
        'Webfolio',
        'Bloomberg',
        'Eurekahedge',
        'Backfill',
        'Fund',
      ])}
      {reportSnippets.submitButton('Get performance', { disabled: fetched })}
    </div>
  )
}
