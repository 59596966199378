import React, { useState, useEffect } from 'react'
import { SmartGrid, KendoDropDown } from '../../components'
import { getEvaluations, getEvaluationDates } from '../../services'
import { GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid'
import { Checkbox } from '@progress/kendo-react-inputs'
import { orderBy } from '@progress/kendo-data-query'
import { dateTimeManager as dt } from '../../utils'

const CheckboxCell = (props) => {
  return (
    <td>
      <Checkbox onChange={() => {}} checked={props.dataItem[props.field]} />
    </td>
  )
}

export default function EvaluationsOverview() {
  const [evaluationDates, setEvaluationDates] = useState([])
  const [evaluationData, setEvaluationData] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const fetchDates = async () => {
    try {
      const response = await getEvaluationDates()
      setEvaluationDates(response.data)
    } catch (error) {}
  }

  const fetchEvaluations = async (evaluation_date) => {
    try {
      const response = await getEvaluations({ evaluation_date })
      let { data } = response
      for (let i = 0; i < data.length; i++) {
        data[i]['stale'] = data[i]['evaluation_date'] !== evaluation_date
        data[i]['evaluation_date'] = dt.dbToJs(data[i]['evaluation_date'])
      }
      const sorted = orderBy(response.data, [
        { field: 'portfolio', dir: 'asc' },
      ])
      setEvaluationData(sorted)
    } catch (error) {}
  }

  useEffect(() => {
    if (evaluationDates.length === 0) {
      fetchDates()
    }
    if (selectedDate && selectedDate !== 'Selected Evaluation Date...') {
      fetchEvaluations(selectedDate)
    }
  }, [selectedDate, evaluationDates])

  return (
    <div className="evaluations-overview-route">
      <SmartGrid data={evaluationData}>
        <GridToolbar>
          <div className="toolbar">
            <KendoDropDown
              defaultItem="Selected Evaluation Date..."
              value={selectedDate}
              items={evaluationDates}
              sortable
              onChange={({ target }) => {
                setSelectedDate(target.value)
              }}
            />
          </div>
        </GridToolbar>
        <Column width={312} field="portfolio" title="Portfolio" />
        <Column field="evaluation_date" title="Date" format="{0: dd-MMM-yy}" />
        <Column field="evaluation_type" title="Type" />
        <Column
          width={100}
          field="is_locked"
          title="Locked"
          cell={CheckboxCell}
        />
        <Column width={100} field="stale" title="Stale" cell={CheckboxCell} />
      </SmartGrid>
    </div>
  )
}
