import React, { Component } from "react";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
} from "@progress/kendo-react-charts";

class RegressionChart extends Component {
  extractValuesNames = (scatter) => {
    return Object.keys(scatter[0]);
  };

  render() {
    // const {mapSeries} = this;
    const { data } = this.props;
    const { scatter, title, ols, wls } = data;
    const [yName, xName] = this.extractValuesNames(scatter);

    return (
      <Chart pannable={false} zoomable={false} transitions={false}>
        <ChartTitle text={title} />
        <ChartLegend visible="true" position="bottom" />
        <ChartSeries>
          <ChartSeriesItem
            type="scatter"
            data={scatter}
            xField={yName}
            yField={xName}
            markers={{
              size: 8,
              background: "#97a6cd",
              border: "#97a6cd",
            }}
          />
          {ols && (
            <ChartSeriesItem
              type="scatterLine"
              width={5}
              data={ols}
              name={"OLS"}
              xField="Best Fit X"
              yField="Best Fit Y"
              color="#113d88"
              markers={{ size: 0 }}
            />
          )}
          {wls && (
            <ChartSeriesItem
              type="scatterLine"
              width={3}
              data={wls}
              name={"WLS"}
              xField="Best Fit X"
              yField="Best Fit Y"
              color={"#ff0026"}
              markers={{ size: 0 }}
            />
          )}
        </ChartSeries>
        <ChartXAxis>
          <ChartXAxisItem
            title={{ text: xName }}
            axisCrossingValue={-1000}
            labels={{ format: "{0:p2}" }}
          />
        </ChartXAxis>
        <ChartYAxis>
          <ChartYAxisItem
            title={{ text: yName }}
            axisCrossingValue={-1000}
            labels={{ format: "{0:p2}" }}
          />
        </ChartYAxis>
      </Chart>
    );
  }
}

export default RegressionChart;
