import { Formik } from 'formik'
import { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { UPDATE_PORTFOLIO_AND_LIST } from '../../constants'
import {
  defaultPortfolioWeight,
  FundsContext,
  PortfolioContext,
} from '../../contexts'
import { useSecurities } from '../../hooks'
import useAccessRights from '../../hooks/useAccessRights'
import { useMixpanel } from '../../hooks/useMixpanel'
import router from '../../resources/history'
import { updatePortfolio } from '../../services'
import { dateTimeManager as dt } from '../../utils'
import useOptimiserRunData from '../portfolioOptimiser/hooks/useOptimiserRunData'
import Stepper from './stepperContainer'
import { useQueryClient } from '@tanstack/react-query'

const minSelectedFund = 2

const conditionalRequiredTest = (item, context) => {
  const portfolio_weights = context.options.context.portfolio_weights

  const arrayLength = portfolio_weights.length
  const itemIndex = context.path.match(/\[(\d+)\]/)[1]

  if (arrayLength > minSelectedFund && +itemIndex === arrayLength - 1) {
    return true
  }

  return item !== null && item !== undefined
}

const validationSchemas = [
  Yup.object().shape({
    description: Yup.string().required().label('Portfolio Description'),
    name: Yup.string().required().label('Portfolio Name'),
  }),
  Yup.object().shape({
    portfolio_weights: Yup.array().of(
      Yup.object().shape({
        security: Yup.object()
          .optional()
          .nullable()
          .test('conditional-required', 'Required', function (item) {
            return conditionalRequiredTest(item, this)
          })
          .label('Security'),
        weight: Yup.number()
          .nullable()
          .test('conditional-required', 'Required', function (item) {
            return conditionalRequiredTest(item, this)
          })
          .label('Weight'),
      }),
    ),
  }),
]

export default function Wrapper() {
  const queryClient = useQueryClient()
  const formikRef = useRef(null)
  const { runData } = useOptimiserRunData(true)
  const { mpTrack } = useMixpanel()
  const [saving, setSaving] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const { resetPortfolio, single_portfolio } = useContext(PortfolioContext)
  const { search_list: searchList } = useSecurities()
  const {
    hasAccessAnalytics: partialAccess,
    hasAccessPortfolioBuilder: fullAccess,
  } = useAccessRights()
  const { dispatch: fundDispatch } = useContext(FundsContext)

  useEffect(() => {
    return () => {
      resetPortfolio()
    }
  }, [resetPortfolio])

  useEffect(() => {
    mpTrack({
      eventName: 'View Build Portfolio Page',
    })
  }, [])

  useEffect(() => {
    if (!runData) {
      return
    }

    const optimalWeights = runData?.result?.weights?.['Optimal']
    const optimalWeightNames = Object.keys(optimalWeights || {})
    const benchmarkNames = runData?.input?.benchmark_constraints?.map(
      (it) => it?.benchmark,
    )
    const reblancingType = runData?.input?.rebalancing_frequency
    const inceptionDate = runData?.result?.returns?.perf_date?.[0]
      ? new Date(runData?.result?.returns?.perf_date?.[0])
      : null

    const defaultPortfolioWeights = []
    const defaultBenchmarks = []

    optimalWeightNames?.forEach((optimalSecurityName) => {
      const matchedSecurity = searchList?.find(
        (item) => item.name === optimalSecurityName,
      )

      if (matchedSecurity) {
        defaultPortfolioWeights.push({
          security: matchedSecurity,
          weight: +(optimalWeights[optimalSecurityName] * 100).toFixed(2),
        })
      }
    })

    benchmarkNames?.forEach((name) => {
      const matchedSecurity = searchList?.find((item) => item.name === name)

      if (matchedSecurity) {
        defaultBenchmarks.push(matchedSecurity)
      }
    })
    if (!single_portfolio?.id) {
      formikRef.current.setFieldValue('portfolio_weights', [
        ...defaultPortfolioWeights?.filter((it) => it.weight > 0.0),
        defaultPortfolioWeight,
      ])
    }
    formikRef.current.setFieldValue('benchmarks', defaultBenchmarks)
    formikRef.current.setFieldValue('rebalancing_type', reblancingType)
    formikRef.current.setFieldValue('inception_date', inceptionDate)
  }, [runData, searchList, formikRef, single_portfolio])

  const submitPortfolio = async (data) => {
    setSaving(true)
    const portfolio = formatPortfolio(data)
    try {
      queryClient.removeQueries({
        predicate: (query) => {
          return query.queryKey.some((key) => typeof key === 'string' && key.includes('Portfolio'))
        }
      })

      const response = await updatePortfolio(portfolio)
      response.data.type = 'Portfolio'
      const { calculated_statistics } = response.data || {}
      const { stats_date } = calculated_statistics || {}
      if (calculated_statistics) {
        calculated_statistics.stats_date = dt.dbToJs(stats_date)
      }
      fundDispatch({
        payload: { ...response.data, updated: true },
        type: UPDATE_PORTFOLIO_AND_LIST,
      })
      setSaving(false)
      const mpEvent = {
        eventName: 'Save Portfolio',
      }

      if (runData?.portfolio_optimiser_run_id) {
        mpEvent.properties = {
          'Optimiser Run ID': runData?.portfolio_optimiser_run_id,
          'Optimiser Run Name': runData?.name,
        }
      }
      mpTrack(mpEvent)

      router.push(`/asset-detail/${response.data.id}/portfolio=true`)
    } catch (error) {
      setSaving(false)
    }
  }

  const formatPortfolio = (portfolio) => {
    const {
      benchmarks,
      cost,
      hurdle,
      inception_date,
      management_fee,
      performance_fee,
      portfolio_weights,
      rebalancing_type,
      ...other
    } = portfolio
    const weights = portfolio_weights.map((o) => o.weight)
    const total_weight = weights.reduce((sum, entry) => sum + entry)
    const formatted_weights = []

    for (let i = 0; i < portfolio_weights.length; i++) {
      const entry = portfolio_weights[i]
      const { security, weight: original_weight } = entry
      if (!security) continue
      if (!original_weight) {
        toast.error('All funds must have valid weights')
        return
      }
      const weight = Number((original_weight / total_weight).toFixed(4))
      formatted_weights.push({ security: security.id, weight })
    }
    const payload = {
      ...other,
      benchmarks: benchmarks.map((entry) => entry.id),
      cost: cost / 100 || 0,
      hurdle: hurdle / 100 || 0,
      inception_date: dt.jsToDb(inception_date),
      management_fee: management_fee / 100 || 0,
      performance_fee: performance_fee / 100 || 0,
      portfolio_weights: formatted_weights,
      rebalancing_type: rebalancing_type || 'None',
    }

    return payload
  }

  return (
    <div className="portfolio-builder-container">
      <Formik
        initialValues={{
          ...single_portfolio,
          cost: single_portfolio.cost * 100,
          hurdle: single_portfolio.hurdle * 100,
          inception_date: dt.dbToJs(single_portfolio.inception_date),
          management_fee: single_portfolio.management_fee * 100,
          performance_fee: single_portfolio.performance_fee * 100,
        }}
        innerRef={formikRef}
        // enableReinitialize
        onSubmit={submitPortfolio}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={validationSchemas[activeStep]}
      >
        {(formProps) => (
          <Stepper
            activeStep={activeStep}
            fullAccess={fullAccess}
            partialAccess={partialAccess}
            runId={runData?.portfolio_optimiser_run_id}
            runName={runData?.name}
            saving={saving}
            searchList={searchList}
            setActiveStep={setActiveStep}
            submitPortfolio={submitPortfolio}
            {...formProps}
          />
        )}
      </Formik>
    </div>
  )
}
