import { useMutation } from "@tanstack/react-query"

import { updateStoriesNotifications } from "../services"


export type UpdateDocsNotificationStatus = 'read' | 'downloaded' | 'unread'

const useUpdateDocsNotification = () => {
  const { isLoading: isUpdatingDocsNotification, mutateAsync, ...rest } = useMutation(async ({ payload, status }: any) => updateStoriesNotifications(status, payload))

  const updateNotificationStatus = async (id: number | number[] | 'all', status: UpdateDocsNotificationStatus) => {
    await mutateAsync({
      payload: {
        stories: id === 'all' ? 'all' : (Array.isArray(id) ? id : [id])
      },
      status
    })
  }

  const setNotificationToReadStatus = async (id: number | number[], read: boolean = true) => await updateNotificationStatus(id, read ? 'read' : 'unread')
  const setNotificationToDownloaded = async (id: number | number[]) => await updateNotificationStatus(id, 'downloaded')
  const setAllNotificationToReadStatus = async (read: boolean) => await updateNotificationStatus('all', read ? 'read' : 'unread')

  return {
    isUpdatingDocsNotification,
    setAllNotificationToReadStatus,
    setNotificationToDownloaded,
    setNotificationToReadStatus
  }
}

export default useUpdateDocsNotification
