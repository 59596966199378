import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

import { fetchedAllSecurities } from "./sharedActions"
import { RootState } from "./store"

export const marketIndexAdapter = createEntityAdapter()
const initialState = marketIndexAdapter.getInitialState()

export const marketIndexSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchedAllSecurities, (state, action: any) => {
      marketIndexAdapter.upsertMany(state, action.payload.marketIndices)
    })
  },
  initialState,
  name: 'marketIndices',
  reducers: {

  }
})

export const {
  selectAll: selectAllMarketIndices,
  selectById: selectMarketIndexById,
  selectEntities: selectMarketIndexEntities,
  selectIds: selectMarketIndexIds,
  selectTotal: selectTotalMarketIndices,
} = marketIndexAdapter.getSelectors((state: RootState) => state.marketIndices)

const marketIndicesReducer = marketIndexSlice.reducer
export default marketIndicesReducer