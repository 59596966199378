import { createSlice } from "@reduxjs/toolkit"

import { fetchedAllSecurities } from "./sharedActions"

interface SecuritySharedState {
  strategies: string[]
  searchList: Array<any>
  aaapClasses: string[]
  selectedFunds: number[]
  scrennerFilters: {
    filter: string
    sortKey: string
    sortOrder: 'asc' | 'desc'
  }
}

const initialState: SecuritySharedState = {
  aaapClasses: [],
  scrennerFilters: {
    filter: '',
    sortKey: '',
    sortOrder: 'asc'
  },
  searchList: [],
  selectedFunds: [],
  strategies: []
}

export const securitySharedSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchedAllSecurities, (state, action: any) => {
      const { aaapClasses, searchList, strategies } = action.payload
      state.strategies = strategies
      state.aaapClasses = aaapClasses
      state.searchList = searchList
    })
  },
  initialState,
  name: 'securityShared',
  reducers: {
    selectedFundIdsChanged: (state, action) => {
      state.selectedFunds = action.payload
    }
  }
})

export const { selectedFundIdsChanged } = securitySharedSlice.actions

const securitySharedReducer = securitySharedSlice.reducer
export default securitySharedReducer