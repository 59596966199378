import React from "react";
import moment from "moment";
import { formatCorrelation } from "../../utils";
import { formatNumber } from "@telerik/kendo-intl";

export default class customCell extends React.Component {
  getCellFormat = (field, val) => {
    const { dataItem } = this.props;
    switch (field) {
      case "Rates":
        return formatNumber(val, "n2");
      case "value_date":
      case "position_date":
      case "start_date":
      case "end_date":
      case "Start Date":
      case "End Date":
        return moment(val).format("DD MMM YYYY");
      case "Ann Sharpe":
      case "Sharpe":
      case "G/L":
      case "Beta":
      case "Correlation":
      case "t-stat":
      case "p value":
      case "Statistics":
      case "Coefficient":
      case "Sortino":
        return formatNumber(val, "n2");
      case "MVaR":
      case "MCVaR":
      case "IVaR":
      case "ICVaR":
      case "Rank":
      case "Year End Rank":
      case "TuW":
        return formatNumber(val, "n0");

      default:
        if (dataItem) {
          let { index } = dataItem;
          let { Metric } = dataItem;
          let idx = Metric ? Metric : index;
          switch (idx) {
            case "Correlation":
            case "Beta":
            case "Skew":
            case "Kurtosis":
            case "Kurt":
            case "Sharpe":
            case "Sortino":
            case "Omega":
            case "Gain/Loss Dev":
            case "Win Ratio":
            case "95% TuW":
            case "Actual TuW":
            case "R-Squared":
            case "P-val of F":
            case "Gain/Loss Dev	":
              return formatNumber(val, "n2");
            default:
              return formatNumber(val, "p2");
          }
        } else {
          return formatNumber(val, "p2");
        }
    }
  };

  render() {
    const {
      // correlation,
      field,
      dataItem,
      table_name,
      correlation,
      negative_red,
    } = this.props;

    const val = dataItem[field];
    let formatted_val;

    switch (table_name) {
      case "Annualised Sortino Ratio":
      case "Annualised Sharpe Ratio":
      case "Annualised Sharpe Ratio (SA)":
      case "Historic Correlations":
      case "Simple Correlation":
      case "Up Correlation":
      case "Down Correlation":
      case "Extreme Correlation":
      case "Matrix":
      case "Rates Exposure":
      case "Sensitivity":
        formatted_val = formatNumber(val, "n2");
        break;
      case "var":
      case "returns_var":
        formatted_val = formatNumber(val * 10000, "n2");
        break;
      default:
        formatted_val = this.getCellFormat(field, val);
    }

    let style = {};

    if (correlation) {
      const { backgroundColor } = formatCorrelation(val);
      style["backgroundColor"] = backgroundColor;
    }
    let red_clx = "";
    if (negative_red && val < 0) {
      red_clx = "negative-red";
    }

    if (val > 0 || val < 0) {
      return (
        <td className={`${red_clx}`} style={style}>
          {formatted_val}
        </td>
      );
    } else if (val === 0) {
      return <td>{formatted_val}</td>;
    } else if (val === "nan") {
      return <td></td>;
    } else {
      return <td>{formatted_val}</td>;
    }
  }
}
