import mixpanel from 'mixpanel-browser'
import { useEffect, createContext, useContext } from 'react'
import { IS_DEV_ENV, IS_PROD_ENV } from '../constants/env'
import { AuthContext } from './auth'

export const MixpanelContext = createContext(mixpanel)

export const MixpanelProvider = ({ children }) => {
  const { profile, isAuthorized } = useContext(AuthContext)
  useEffect(() => {
    if (isAuthorized && profile?.email) {
      mixpanel.identify(profile.email)
      mixpanel.people.set_once({
        $email: profile.email,
        $name: `${profile.first_name} ${profile.last_name}`,
        ID: profile.id,
        Country: profile.country,
        Email: profile.email,
        'First Name': profile.first_name,
        'Last Name': profile.last_name,
        'Job Title': profile.job_title,
        Telephone: profile.telephone,
        'Signed NDA': !!profile?.company?.has_signed_nda,
        Groups: profile.groups,
        'Research Access': profile.has_access_to_research,
      })
      mixpanel.people.set({
        $email: profile.email,
        $name: `${profile.first_name} ${profile.last_name}`,
        Company:
          typeof profile.company === 'string'
            ? profile.company
            : profile.company?.name,
      })
    }
  }, [profile, isAuthorized])

  useEffect(() => {
    mixpanel.time_event('Time On Website')

    return () => {
      mixpanel.track('Time On Website')
    }
  }, [])
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: !IS_PROD_ENV,
    ignore_dnt: !IS_PROD_ENV,
    opt_out_tracking_by_default: false || IS_DEV_ENV,
  })

  return (
    <MixpanelContext.Provider value={mixpanel}>
      {children}
    </MixpanelContext.Provider>
  )
}
