export const defaultAssetValues = {
  blackLittermanViews: null,
  group: null,
  lowerBound: 0,
  name: null,
  suggestedValue: '',
  upperBound: 100,
}

export const groupConstraintsInputName = 'groupConstraints'
export const benchmarkConstraintsInputName = 'benchmarkConstraints'
export const cardinalityConstraintsInputName = 'cardinalityConstraints'

export const defaultGroupValues = {
  groupName: '',
  lowerBound: '',
  upperBound: '',
}

export const defaultBenchmarkValues = {
  alphaLowerBound: '',
  alphaUpperBound: '',
  benchmark: '',
  betaLowerBound: '',
  betaUpperBound: '',
}

export const defaultCardinalityValues = {
  includes: [],
  lowerBound: '',
  maxCombinations: null,
  upperBound: '',
}

export const defaultBasicValues = {
  affineBenchmarks: true,
  name: '',
  nearZero: null,
  objectiveFunction: 'sharpe-ratio',
  performanceEndDate: null,
  performanceStartDate: null,
  quadRisk: null,
  rebalancingFrequency: 'None',
  riskFreeRate: null,
  tags: [],
  visibilityType: 'Internal Private',
}

export const defaultOptimiserFormValues = {
  assets: [defaultAssetValues],
  [benchmarkConstraintsInputName]: [defaultBenchmarkValues],
  [cardinalityConstraintsInputName]: defaultCardinalityValues,
  enableBenchmarkConstraints: false,
  enableBlackLittermanView: false,
  enableCardinalityConstraints: false,
  enableGroupConstraints: false,
  [groupConstraintsInputName]: [defaultGroupValues],
  ...defaultBasicValues,
}
