import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'

const RouteLink = ({ children, disabled, ...props }) => (
  <Link {...props} className={clsx("custom-route-link", { 'disabled-route-link': disabled })}>
    {children}
  </Link>
)

export default RouteLink
