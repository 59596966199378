import React, { useState } from "react";
import { ReturnsProcessor, dateTimeManager as dt } from "../../utils";
import { MonthlyDatePicker, KendoPerformanceUpdate } from "../../components";
import { TextInput } from "../../components/forms";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { getNetFromGross } from "../../services";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  management_fee: Yup.number().required().label("Management Fee"),
  hurdle: Yup.number().required().label("Hurdle"),
  performance_fee: Yup.number().required().label("Performance Fee"),
});

const Exporter = ({ excelData }) => {
  const disabled = excelData.length === 0;
  const _exporter = React.createRef();

  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  return (
    <div className="excel-export">
      <Button
        variant="outlined"
        color="primary"
        disabled={disabled}
        onClick={exportExcel}
      >
        Export to Excel
      </Button>
      <ExcelExport
        data={excelData}
        fileName="Gross and Net Performance.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn
          field="perf_date"
          title="Date"
          locked={true}
          width={200}
        />
        <ExcelExportColumn
          field="gross_performance"
          title="Gross Performance"
          width={350}
        />
        <ExcelExportColumn
          field="net_performance"
          title="Net Performance"
          width={350}
        />
      </ExcelExport>
    </div>
  );
};

const ConverterForm = ({ onSubmit, excelData, grossPerformance }) => {
  const disabled = grossPerformance.length === 0;
  return (
    <Formik
      initialValues={{
        management_fee: null,
        performance_fee: null,
        hurdle: null,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => (
        <Form className="converter-form">
          <TextInput
            type="number"
            spinners={false}
            format="p2"
            name="management_fee"
            placeholder="Management Fee"
          />
          <TextInput
            type="number"
            spinners={false}
            format="p2"
            name="performance_fee"
            placeholder="Performance Fee"
          />
          <TextInput
            type="number"
            spinners={false}
            format="p2"
            name="hurdle"
            placeholder="Hurdle"
          />
          <div className="form-button">
            <Button
              disabled={disabled}
              color="primary"
              variant="contained"
              type="submit"
            >
              Convert
            </Button>
          </div>
          <Exporter excelData={excelData} />
        </Form>
      )}
    </Formik>
  );
};

export default function GrossToNetConverter() {
  const [resetDate, setResetDate] = useState(null);
  const [grossPerformance, setGrossPerformance] = useState([]);
  const [netPerformance, setNetPerformance] = useState([]);
  const [mergedData, setMergedData] = useState([]);

  const changeStartDate = ({ target }) => {
    const inceptionDate = target.value;
    setResetDate(inceptionDate);
    const newData = ReturnsProcessor.resetInceptionDate(
      [],
      new Date(),
      inceptionDate
    );
    setGrossPerformance(newData);
  };
  const convertReturns = async (data) => {
    let performance = grossPerformance.map((o) => ({
      perf_date: o.perf_date,
      perf_return: o.perf_return,
    }));
    const payload = {
      performance,
      ...data,
    };
    try {
      const response = await getNetFromGross(payload);
      let { data } = response;
      let merged = [];
      for (let i = 0; i < data.length; i++) {
        const entry = data[i];
        const grossData = grossPerformance.find(
          (o) => o.perf_date === entry.perf_date
        );
        merged.push({
          perf_date: entry.perf_date,
          gross_performance: grossData.perf_return,
          net_performance: entry.perf_return,
        });
      }
      setMergedData(merged);
      for (let i = 0; i < data.length; i++) {
        data[i].perf_date = dt.dbToMonthYear(data[i].perf_date);
      }
      setNetPerformance(data);
    } catch (error) {}
  };
  return (
    <div className="gross-to-net-route">
      <div className="selectors-container">
        <Typography variant="h6">Start date</Typography>
        <MonthlyDatePicker value={resetDate} onChange={changeStartDate} />
      </div>
      <div className="output-container">
        <div className="table-container">
          <Typography variant="h6" align="center">
            Gross Performance
          </Typography>
          <KendoPerformanceUpdate
            data={grossPerformance}
            indexCol="date"
            numberCol="perf_return"
            setData={setGrossPerformance}
          />
        </div>
        <ConverterForm
          grossPerformance={grossPerformance}
          excelData={mergedData}
          onSubmit={convertReturns}
        />
        <div className="table-container">
          <Typography variant="h6" align="center">
            Net Performance
          </Typography>
          <Grid data={netPerformance} style={{ height: "800px" }}>
            <Column field="perf_date" title="Date" width={120} />
            <Column field="perf_return" title="Performance" format="{0:p2}" />
          </Grid>
        </div>
      </div>
    </div>
  );
}
