import { Box, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import LoadingSpinner from '../../components/misc/LoadingSpinner'
import { useSecurities } from '../../hooks'
import { getSecurityDetails } from '../../services'
import {
  DateInput,
  DaysBeforeInput,
  IsFirstDateCheckbox,
  NumberOfPeriodsInput,
  SubscriptionFrequencySelect
} from './TradingCalendarFormInputs'

const TradingCalendarFundSelectionForm = () => {
  const { aaap_cell_list: aaapList, fof_share_class_list: fofList } = useSecurities()
  const { formState: { errors }, setValue, watch } = useFormContext()
  const watchFund = watch('fund')
  const { data: fundDetailResponse, isFetching } = useQuery(
    ['fund', watchFund?.id],
    () => getSecurityDetails({ fund_id: watchFund?.id, security_type: 'AAAP Cell' }),
    {
      enabled: !!watchFund,
    }
  )
  const options = useMemo(() => {
    return [...(aaapList || []), ...(fofList || [])]
  }, [aaapList, fofList])

  useEffect(() => {
    const fundDetail = fundDetailResponse?.data
    if (fundDetail) {
      const interval = fundDetail?.subs_freq
      const numberRegex = /\d+/
      const noticeDays = fundDetail?.subs_notice?.match(numberRegex)?.[0]
      setValue('interval', interval)
      setValue('daysBefore', noticeDays ? Number(noticeDays) : 0)
    }
  }, [fundDetailResponse])

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap={12}
    >
      <Autocomplete
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => {
          setValue('fund', newValue)
        }}
        options={options}
        renderInput={(params) => <TextField
          {...params}
          error={!!errors.fund?.message}
          helperText={errors.fund?.message}
          label="Fund"
        />}
        value={watchFund}
      />
      {
        isFetching
          ? <LoadingSpinner
            disableSpacing
            my={2}
          />
          : <>
            <Box
              display="flex"
              gridGap={8}
            >
              <SubscriptionFrequencySelect disabled />
              <DateInput />
              <DaysBeforeInput disabled />
            </Box>
            <NumberOfPeriodsInput />
            <IsFirstDateCheckbox />
          </>
      }
    </Box>
  )
}

export default TradingCalendarFundSelectionForm