export const flows_list = {
  manager_trades_col_list: [
    { field: "trade_date", title: "Trade Date" },
    { field: "cell", title: "Cell" },
    { field: "event_type", title: "Event Type" },
    { field: "shares", title: "Shares" },
    { field: "amount", title: "Amount" },
  ],
  investor_terms_col_list: [
    { field: "security", title: "Security" },
    { field: "frequency", title: "Frequency" },
    { field: "sub_notice", title: "Sub Notice" },
    { field: "red_notice", title: "Red Notice" },
    { field: "gate", title: "Gate" },
    { field: "soft_lock", title: "Soft Lock" },
    { field: "cell_deadline", title: "Cell Deadline" },
    { field: "cell_days_remaining", title: "Cell Days Remaining" },
    { field: "manager_deadline", title: "Manager Deadline" },
    { field: "manager_days_remaining", title: "Manager Days Remaining" },
  ],
  investor_trades_col_list: [
    { field: "security", title: "Security" },
    { field: "op_date", title: "Op. Date" },
    { field: "eff_date", title: "Eff. Date" },
    { field: "investor_acc", title: "Investor Account" },
    { field: "event_type", title: "Event Type" },
    { field: "currency", title: "Currency" },
    { field: "shares", title: "Shares" },
    { field: "amount", title: "Amount" },
    { field: "days_remaining", title: "Days Remaining" },
  ],
  manager_terms_col_list: [
    { field: "security", title: "Security" },
    { field: "sub_notice", title: "Sub Notice" },
    { field: "days_to_sub", title: "Days to Sub" },
    { field: "red_period", title: "Red Period" },
    { field: "days_to_red", title: "Days to Red" },
    { field: "gate", title: "Gate" },
    { field: "soft_lock", title: "Soft-Lock" },
    { field: "mgmt_fee", title: "Mgmt Fee" },
    { field: "incentive_fee", title: "Incentive Fee" },
  ],
};

export const terms_button_list = [
  { id: "investor_terms", title: "Cell Terms" },
  { id: "manager_terms", title: "Manager Terms" },
];

export const trades_button_list = [
  { id: "investor_trades", title: "Investor Trades" },
  { id: "manager_trades", title: "Manager Trades" },
];

export const single_manager_cell_terms = {
  security: "AAAP MLM",
  sub_notice: "10 days",
  days_to_sub: "5 days",
  red_period: "30 days",
  days_to_red: "7 days",
  gate: "25% Fund Level",
  soft_lock: false,
  mgmt_fee: "1.5%",
  incentive_fee: "0.5%",
};

export let manager_terms = [];
for (let i = 0; i < 150; i++) {
  manager_terms.push(single_manager_cell_terms);
}

export const single_manager_trades = {
  trade_date: "09/06/2019",
  cell: "AAAP MLS",
  event_type: "BUY SHARES",
  shares: 150000,
  amount: 1500000,
};

export let manager_trades = [];
for (let i = 0; i < 150; i++) {
  manager_trades.push(single_manager_trades);
}

export const investor_terms = [
  {
    security: "AAAP BAE",
    frequency: "Quarterly",
    sub_notice: "5 Business Days",
    red_notice: "95 Days",
    gate: "Quarterly 25%",
    soft_lock: "true",
    cell_deadline: "24/06/2020",
    cell_days_remaining: "5",
    manager_deadline: "31/06/2020",
    manager_days_remaining: "11",
  },
  {
    security: "AAAP BHM",
    frequency: "Monthly",
    sub_notice: "5 Business Days",
    red_notice: "90 Days",
    gate: "10% fund gate",
    soft_lock: "true",
    cell_deadline: "24/06/2020",
    cell_days_remaining: "5",
    manager_deadline: "31/06/2020",
    manager_days_remaining: "11",
  },
  {
    security: "AAAP BAE",
    frequency: "Monthly",
    sub_notice: "6 Business Days",
    red_notice: "Monthly",
    gate: "Quarterly 20%",
    soft_lock: "false",
    cell_deadline: "24/06/2020",
    cell_days_remaining: "5",
    manager_deadline: "31/06/2020",
    manager_days_remaining: "11",
  },
  {
    security: "AAAP BWF",
    frequency: "Monthly",
    sub_notice: "5 Business Days",
    red_notice: "Monthly",
    gate: "Quarterly 20%",
    soft_lock: "false",
    cell_deadline: "24/06/2020",
    cell_days_remaining: "5",
    manager_deadline: "31/06/2020",
    manager_days_remaining: "11",
  },
  {
    security: "AAAP CVX",
    frequency: "Monthly",
    sub_notice: "5 Business Days",
    red_notice: "65 Days",
    gate: "Quarterly 25%",
    soft_lock: "false",
    cell_deadline: "24/06/2020",
    cell_days_remaining: "0",
    manager_deadline: "31/06/2020",
    manager_days_remaining: "3",
  },
  {
    security: "AAAP ECF",
    frequency: "Monthly",
    sub_notice: "6 Business Days",
    red_notice: "Monthly",
    gate: "Quarterly 20%",
    soft_lock: "false",
    cell_deadline: "30/06/2020",
    cell_days_remaining: "8",
    manager_deadline: "31/06/2020",
    manager_days_remaining: "9",
  },
  {
    security: "AAAP FHE",
    frequency: "Monthly",
    sub_notice: "5 Business Days",
    red_notice: "35 Days",
    gate: "Quarterly 20%",
    soft_lock: "false",
    cell_deadline: "30/06/2020",
    cell_days_remaining: "8",
    manager_deadline: "31/06/2020",
    manager_days_remaining: "9",
  },
  {
    security: "AAAP FSO",
    frequency: "Monthly",
    sub_notice: "5 Business Days",
    red_notice: "65 Days",
    gate: "Quarterly 20%",
    soft_lock: "false",
    cell_deadline: "30/06/2020",
    cell_days_remaining: "8",
    manager_deadline: "31/06/2020",
    manager_days_remaining: "9",
  },
  {
    security: "AAAP GVA",
    frequency: "Monthly",
    sub_notice: "6 Business Days",
    red_notice: "Monthly",
    gate: "Quarterly 20%",
    soft_lock: "false",
    cell_deadline: "22/07/2020",
    cell_days_remaining: "33",
    manager_deadline: "31/08/2020",
    manager_days_remaining: "42",
  },
];

// export let investor_cell_terms = [];
// for (let i = 0; i < 150; i++) {
//   investor_cell_terms.push(single_investor_terms);
// }

export const investor_trades = [
  {
    security: "AAAP KAD - Class X",
    op_date: "29/04/2019",
    eff_date: "01/07/2019",
    investor_acc: "Julius Baer",
    event_type: "SELL SHARES",
    currency: "USD",
    shares: 100000,
    amount: 4500000,
    days_remaining: "90",
  },
  {
    security: "AAAP KAD - Class X",
    op_date: "29/04/2019",
    eff_date: "01/07/2019",
    investor_acc: "Julius Baer",
    event_type: "SELL SHARES",
    currency: "USD",
    shares: 190000,
    amount: 2989000,
    days_remaining: "90",
  },
  {
    security: "AAAP KAD - Class X",
    op_date: "30/04/2019",
    eff_date: "01/07/2019",
    investor_acc: "Julius Baer",
    event_type: "SELL SHARES",
    currency: "USD",
    shares: 190000,
    amount: 2989000,
    days_remaining: "90",
  },
  {
    security: "AAAP MLM - Class X",
    op_date: "29/04/2019",
    eff_date: "01/07/2019",
    investor_acc: "Julius Baer",
    event_type: "BUY SHARES",
    currency: "USD",
    shares: 10000,
    amount: 9890000,
    days_remaining: "90",
  },
  {
    security: "AAAP MLM - Class X",
    op_date: "29/04/2019",
    eff_date: "01/07/2019",
    investor_acc: "Julius Baer",
    event_type: "BUY SHARES",
    currency: "USD",
    shares: 20000,
    amount: 9800500,
    days_remaining: "90",
  },
  {
    security: "AAAP MLM - Class X",
    op_date: "29/04/2019",
    eff_date: "01/07/2019",
    investor_acc: "Bank of Singapore",
    event_type: "SELL SHARES",
    currency: "USD",
    shares: 5000,
    amount: 50670,
    days_remaining: "90",
  },
  {
    security: "AAAP MLM - Class B",
    op_date: "29/04/2019",
    eff_date: "01/07/2019",
    investor_acc: "Bank of Singapore",
    event_type: "BUY SHARES",
    currency: "USD",
    shares: 55000,
    amount: 187670,
    days_remaining: "90",
  },
  {
    security: "AAAP BPF - Class X",
    op_date: "29/04/2019",
    eff_date: "01/07/2019",
    investor_acc: "Bank of Singapore",
    event_type: "SELL SHARES",
    currency: "USD",
    shares: 7000,
    amount: 87670,
    days_remaining: "90",
  },
];
