import React, { Component } from "react";
import { groupBy } from "@progress/kendo-data-query";
// import PropTypes from 'prop-types';
import {
  // StockChart,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartLegend,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import Typography from "@material-ui/core/Typography";

export default class ScatterPlot extends Component {
  labelContent = (e) => {
    return e.dataItem[this.props.category_name];
  };

  render() {
    const {
      data,
      title,
      x_field,
      y_field,
      x_field_name,
      y_field_name,
      group_field,
    } = this.props;

    const group = group_field ? [{ field: group_field }] : [];

    const seriesLabels = {
      visible: true,
      // padding: 3,
      // font: 'bold 16px Arial, sans-serif'
    };
    const series = groupBy(data, group);

    return (
      <div className="line-ts-chart-container">
        <div className="title-container">
          <Typography align="center" variant="h6">
            {title}
          </Typography>
        </div>
        <Chart pannable={false} zoomable={false} transitions={false}>
          <ChartLegend visible="true" position="bottom" />
          <ChartTooltip format="Exp: {0:p2} Attribution: {1:p2}" />
          {group_field ? (
            <ChartSeries>
              {series.map((item, id) => (
                <ChartSeriesItem
                  type="scatter"
                  key={id}
                  data={item.items}
                  xField={x_field}
                  yField={y_field}
                  labels={seriesLabels}
                >
                  <ChartSeriesLabels
                    color="#000000"
                    background={"none"}
                    content={this.labelContent}
                  />
                </ChartSeriesItem>
              ))}
            </ChartSeries>
          ) : (
            <ChartSeries>
              <ChartSeriesItem
                type="scatter"
                data={data}
                xField={x_field}
                yField={y_field}
                labels={seriesLabels}
              >
                <ChartSeriesLabels
                  color="#000000"
                  background={"none"}
                  content={this.labelContent}
                />
              </ChartSeriesItem>
            </ChartSeries>
          )}
          <ChartXAxis>
            <ChartXAxisItem
              axisCrossingValue={-1000}
              title={{ text: x_field_name }}
              labels={{ format: "{0:p2}" }}
            />
          </ChartXAxis>
          <ChartYAxis>
            <ChartYAxisItem
              axisCrossingValue={-1000}
              title={{ text: y_field_name }}
              labels={{ format: "{0:p2}" }}
            />
          </ChartYAxis>
        </Chart>
      </div>
    );
  }
}
