import React, { useEffect, useMemo, useState } from 'react'

import { StockChartTimeSeries } from '../../components'
import ChartContainer from './layout/ChartContainer'
import { usePortfolioOptimiser } from './RefactoredPortfolioOptimiserProvider'

const PortOptReturnsLineChart = () => {
  const { hasFailedError, runData } = usePortfolioOptimiser()
  const [showChart, setShowChart] = useState(false)
  const returnsData = runData?.result?.returns
  const lineChartData = useMemo(() => {
    if (!returnsData) {
      return []
    }

    const { perfDate, ...restData } = returnsData

    return perfDate?.map((date, idx) => ({
      perf_date: date,
      ...Object.keys(restData).reduce((acc, key) => {
        acc[key] = restData[key][idx]
        return acc
      }, {}),
    }))
  }, [returnsData])

  useEffect(() => {
    let timer
    if (lineChartData && !hasFailedError) {
      timer = setTimeout(() => {
        setShowChart(true)
      }, 500)
    }
    return () => clearTimeout(timer)
  }, [lineChartData, hasFailedError])


  return lineChartData?.length > 0 && !hasFailedError ? (
    <ChartContainer
      height="500px"
      pr={4}
      width="100%"
    >
      {
        showChart && (
          <StockChartTimeSeries
            category_field="perf_date"
            data={lineChartData || []}
            format="{0:c}"
            minimum
            title="Hypothetical growth of $1000"
          />
        )
      }
    </ChartContainer>
  ) : null
}

export default PortOptReturnsLineChart
