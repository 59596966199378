import {
  DELETE_PORTFOLIO,
  PRIVATE_EQUITY_FUNDS_FETCHED,
  UPDATE_AAAP_CELL_LIST,
  UPDATE_FOF_SHARE_CLASSES,
  UPDATE_HEDGE_FUND_LIST,
  UPDATE_MARKET_INDEX_LIST,
  UPDATE_PORTFOLIO_LIST,
  UPDATE_PORTFOLIO_AND_LIST,
  UPDATE_SELECTED_SECURITY_LIST,
  UPDATE_HEDGE_FUNDS_WITH_STATS,
  UPDATE_WF_PORTFOLIO_LIST,
  USER_LOGOUT,
  status,
} from '../constants'
import { AAM_PRIVATE_MARKET_NAME } from '../constants/service.constants'

import { db_strategies_june_2024 } from '../data/funds'

export const initialState = {
  aaap_classes: [],
  aaap_cell_list: [],
  aaap_cell_list_map: {},
  hasLoadedPeSearchList: false,
  hedge_fund_list: [],
  hedge_fund_list_status: null,
  market_index_list: [],
  portfolio_list: [],
  search_list: [],
  search_list_map: {},
  grouped_search_list_map: {},
  selected_securities: [],
  strategy_list: [],
  webfolio_list: [],
  loadingStates: {
    aaap_cell_list: true,
    market_index_list: true,
    hedge_fund_list: true,
    portfolio_list: true,
    webfolio_list: true,
    fof_share_class_list: true,
  },
  isLoadingSecurities: true,
}

function updateSearchList(state, newItems, itemType) {
  const processedItems = (
    itemType == 'Portfolio'
      ? newItems.filter((s) => s.portfolio_type != 'Webfolio')
      : newItems
  ).map((item) => ({
    ...item,
    type: itemType == 'Fund' ? item?.primary_vendor_name : itemType,
  }))

  const processedItemsMap = processedItems.reduce((acc, item) => {
    acc[item.id] = item
    return acc
  }, {})

  const search_list = [
    ...state.search_list.filter((item) => !processedItemsMap[item.id]),
    ...processedItems,
  ]

  const search_list_map = { ...state.search_list_map }
  search_list.forEach((item) => {
    search_list_map[item.id] = item
  })

  return { search_list, search_list_map }
}

const additionalStrategies = [
  ...db_strategies_june_2024,
  ...[
    'Crypto',
    'Buyout',
    'Growth Equity',
    'Venture',
    'Secondary',
    'Real Estate',
    'Infrastructure',
    'Long/Short Credit',
    'Private Credit',
  ],
]

function updateState(state, payload, listKey, type) {
  const { search_list, search_list_map } = updateSearchList(
    state,
    payload[listKey],
    type,
  )

  const strategy_list = [
    ...new Set([
      ...search_list.map((o) => o.strategy).filter((o) => !!o),
      ...additionalStrategies,
    ]),
  ].sort()

  const newLoadingStates = {
    ...state.loadingStates,
    [listKey]: false,
  }

  const grouped_search_list_map = Object.keys(search_list_map).reduce(
    (grouped, key) => {
      const item = search_list_map[key]
      const type = item.type
      if (!grouped[type]) {
        grouped[type] = []
      }
      grouped[type].push(item)
      return grouped
    },
    {},
  )

  const newState = {
    ...state,
    [listKey]: payload[listKey],
    strategy_list,
    search_list,
    search_list_map,
    grouped_search_list_map,
    loadingStates: newLoadingStates,
    isLoadingSecurities: !Object.values(newLoadingStates).every((o) => !o),
  }

  return newState
}

export function funds(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SELECTED_SECURITY_LIST: {
      if (Array.isArray(action.payload)) {
        return { ...state, selected_securities: action.payload }
      }
      const selected_securities = [...state.selected_securities]
      const { id } = action.payload
      const idx = selected_securities.findIndex((o) => o.id === id)
      if (idx === -1) {
        selected_securities.push(action.payload)
      } else {
        selected_securities.splice(idx, 1)
      }
      return { ...state, selected_securities }
    }
    case UPDATE_AAAP_CELL_LIST: {
      const aaap_cell_list_map = {}
      action.payload.aaap_cell_list.forEach((item) => {
        aaap_cell_list_map[item.id] = item
      })

      const newState = updateState(
        state,
        action.payload,
        'aaap_cell_list',
        'AAAP Cell',
      )

      return {
        ...newState,
        aaap_classes: action.payload.aaap_classes,
        aaap_cell_list_map,
      }
    }
    case UPDATE_MARKET_INDEX_LIST: {
      return updateState(
        state,
        action.payload,
        'market_index_list',
        'Market Index',
      )
    }
    case UPDATE_HEDGE_FUND_LIST: {
      return updateState(state, action.payload, 'hedge_fund_list', 'Fund')
    }
    case UPDATE_HEDGE_FUNDS_WITH_STATS:
      const newState = updateState(
        state,
        { hedge_fund_list: action.payload },
        'hedge_fund_list',
        'Fund',
      )

      return {
        ...newState,
        hedge_fund_list_status: status.success,
      }
    case UPDATE_PORTFOLIO_LIST: {
      return updateState(state, action.payload, 'portfolio_list', 'Portfolio')
    }
    case UPDATE_PORTFOLIO_AND_LIST: {
      const { id } = action.payload
      const updated_portfolio_list = [
        action.payload,
        ...state.portfolio_list.filter((o) => o.id !== id),
      ]

      const newState = updateState(
        state,
        { portfolio_list: updated_portfolio_list },
        'portfolio_list',
        'Portfolio',
      )

      return {
        ...newState,
        selected_securities: [],
      }
    }
    case UPDATE_WF_PORTFOLIO_LIST: {
      return updateState(state, action.payload, 'webfolio_list', 'FoF')
    }
    case UPDATE_FOF_SHARE_CLASSES: {
      return updateState(
        state,
        action.payload,
        'fof_share_class_list',
        'FoF Share Class',
      )
    }
    case DELETE_PORTFOLIO: {
      const { id } = action.payload
      const portfolio_list = state.portfolio_list.filter((o) => o.id !== id)
      const search_list = state.search_list.filter((o) => o.id !== id)
      const search_list_map = { ...state.search_list_map }
      delete search_list_map[id]
      return { ...state, portfolio_list, search_list, search_list_map }
    }
    case PRIVATE_EQUITY_FUNDS_FETCHED: {
      const peFunds = action.payload
      const peFundsSearchList = peFunds.map(({ id, name }) => ({
        id,
        name,
        type: AAM_PRIVATE_MARKET_NAME,
      }))
      const search_list = [...state.search_list, ...peFundsSearchList]

      const peFundsMap = {}
      peFundsSearchList.forEach((item) => {
        peFundsMap[item.id] = item
      })

      return {
        ...state,
        hasLoadedPeSearchList: true,
        search_list,
        search_list_map: {
          ...state.search_list_map,
          ...peFundsMap,
        },
      }
    }
    case USER_LOGOUT: {
      return { ...initialState }
    }
    default:
      return state
  }
}
