import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

const useMixpanelTimeOnComponent = ({
  eventName,
  properties,
}) => {
  useEffect(() => {
    mixpanel.time_event(eventName);

    return () => {
      mixpanel.track(eventName, properties);
    };
  }, [properties]);
};

export default useMixpanelTimeOnComponent;
