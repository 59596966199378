import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

import { fetchedAllSecurities } from "./sharedActions"
import { RootState } from "./store"

export const webfoliosAdapter = createEntityAdapter()
const initialState = webfoliosAdapter.getInitialState()


export const webfoliosSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchedAllSecurities, (state, action: any) => {
      webfoliosAdapter.upsertMany(state, action.payload.webfolios)
    })
  },
  initialState,
  name: 'webfolios',
  reducers: {

  }
})

export const {
  selectAll: selectAllWebfolios,
  selectById: selectWebfolioById,
  selectEntities: selectWebfolioEntities,
  selectIds: selectWebfolioIds,
  selectTotal: selectTotalWebfolios,
} = webfoliosAdapter.getSelectors((state: RootState) => state.webfolios)

const webfoliosReducer = webfoliosSlice.reducer
export default webfoliosReducer