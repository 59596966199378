import React, { useState, useEffect } from "react";
import ReportSnippets from "../../utils/reportInputSnippets";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { status as st } from "../../constants";
import { watermarkDocument, getCompanies } from "../../services";
import { buildFileFromResponse } from "../../utils";

const WaitingScreen = () => {
  return (
    <div className="loading-progress">
      <Typography align="center">Watermarking...</Typography>
      <LinearProgress />
    </div>
  );
};

const Inputs = ({
  watermarking_inputs,
  updateWatermarkingInputs,
  company,
  watermarking_status,
  getWatermarked,
  loading,
  ...props
}) => {
  const { watermarks, watermark_docs } = watermarking_inputs;
  const disabled = !!(
    watermarks.length === 0 ||
    watermark_docs.length === 0 ||
    loading
  );
  const reportSnippets = new ReportSnippets({
    ...props,
    data: watermarking_inputs,
    updateInputs: updateWatermarkingInputs,
    submitFunction: getWatermarked,
  });

  return (
    <div className="report-setup">
      {reportSnippets.dropdownMultiSelector("watermarks", "Clients", company, {
        dataItemKey: "id",
        textField: "name",
      })}
      {reportSnippets.dropZone("watermark_docs", "Document to watermark", {
        multi: true,
      })}
      {reportSnippets.submitButton(
        loading ? "Watermarking. Please wait" : "Watermark",
        { disabled }
      )}
    </div>
  );
};

export default function Watermarking() {
  const [inputs, setInputs] = useState({
    watermark_docs: [],
    watermarks: [],
  });
  const [watermakingStatus, setWatermakingStatus] = useState(null);
  const [companies, setCompanies] = useState([]);

  const updateWatermakingInputs = ({ value, name }) => {
    setInputs((i) => ({ ...i, [name]: value }));
  };

  const getWatermark = async () => {
    setWatermakingStatus(st.request);
    const { watermarks, watermark_docs } = inputs;
    const payload = new FormData();
    for (let i = 0; i < watermark_docs.length; i++) {
      const element = watermark_docs[i];
      payload.append("watermark_docs[]", element);
    }

    payload.append("watermarks[]", JSON.stringify(watermarks.map((o) => o.id)));
    try {
      const response = await watermarkDocument(payload);
      buildFileFromResponse(response);
    } catch (error) {}
    setWatermakingStatus(null);
  };

  const fetchCompanies = async () => {
    try {
      const response = await getCompanies();
      setCompanies(response.data);
    } catch (e) {}
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const loading = watermakingStatus === st.request;
  return (
    <div className="watermarking-route">
      <Inputs
        watermarking_inputs={inputs}
        updateWatermarkingInputs={updateWatermakingInputs}
        company={companies}
        loading={loading}
        getWatermarked={getWatermark}
      />
      {loading && <WaitingScreen />}
    </div>
  );
}
