import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const SkeletonTableLoader = () => (
  <Box>
    <Table>
      <TableHead>
        <TableRow>
          {Array.from({ length: 13 }).map((_, index) => (
            <TableCell key={index}>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from({ length: 5 }).map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {Array.from({ length: 13 }).map((_, colIndex) => (
              <TableCell key={colIndex}>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
)

export default SkeletonTableLoader
