import React, { Component } from "react";
import {
  flows_list,
  trades_button_list,
  investor_trades,
  manager_trades,
} from "../../data/flows";
import { statefulTableGenerator } from "../../components";
import {
  Grid as KGrid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const SuperGrid = statefulTableGenerator(KGrid);

export class tradesTab extends Component {
  state = {
    current_view: "investor_trades",
  };

  aggregates = [
    { field: "amount", aggregate: "sum" },
    { field: "shares", aggregate: "sum" },
  ];

  changeView = (new_view) => {
    this.setState({ current_view: new_view });
  };

  generateColumn = (unique_id, title, field) => {
    return <Column key={unique_id} title={title} field={field} />;
  };

  cellRender(tdElement, cellProps) {
    const { rowType, field } = cellProps || {};

    if (rowType !== "groupFooter") {
      return tdElement;
    }

    if (["shares", "amount"].indexOf(field) !== -1) {
      const { dataItem } = cellProps || {};
      const { aggregates } = dataItem || {};
      const { [field]: value } = aggregates || {};
      const { sum } = value || {};
      return <td>{sum}</td>;
    }
    return <td />;
  }

  renderColumns = () => {
    const { current_view } = this.state;
    let field_list;
    switch (current_view) {
      case "investor_trades":
        field_list = [...flows_list.investor_trades_col_list];
        break;
      case "manager_trades":
        field_list = [...flows_list.manager_trades_col_list];
        break;
      default:
        field_list = [];
        break;
    }
    return field_list.map((entry, idx) => {
      return this.generateColumn(entry, entry.title, entry.field, idx);
    });
  };

  generateButtonGroup = () => {
    const { current_view } = this.state;
    return trades_button_list.map((btn, idx) => {
      const color = current_view === btn.id ? "primary" : "default";
      return (
        <Button
          onClick={() => {
            this.changeView(btn.id);
          }}
          color={color}
          key={idx}
        >
          {btn.title}
        </Button>
      );
    });
  };

  getData = () => {
    const { current_view } = this.state;
    switch (current_view) {
      case "investor_trades":
        return investor_trades;
      case "manager_trades":
        return manager_trades;
      default:
        return [];
    }
  };

  render() {
    const data = this.getData();
    const state_vars = {
      filter: undefined,
      group: [
        {
          field: "event_type",
          aggregates: this.aggregates,
        },
      ],
      skip: 0,
      sort: undefined,
      take: undefined,
    };
    return (
      <React.Fragment>
        <div className="investor-flows-container">
          <SuperGrid
            className="investor-flows-table"
            style={{ height: "75vh" }}
            data={data || []}
            pageable={false}
            filterable={false}
            scrollable="scrollable"
            groupable={{ enabled: true, footer: "visible" }}
            initial_state={state_vars}
            aggregateFields={this.aggregates}
            cellRender={this.cellRender}
          >
            <GridToolbar>
              <ButtonGroup
                className="view-buttons"
                variant="contained"
                color="default"
                align="center"
              >
                {this.generateButtonGroup()}
              </ButtonGroup>
            </GridToolbar>
            {this.renderColumns()}
          </SuperGrid>
        </div>
      </React.Fragment>
    );
  }
}

export default tradesTab;
