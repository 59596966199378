import React, { useState } from 'react';

const useFactSheetsPage = () => {
  const [activeBookletId, setActiveBookletId] = useState(null);
  const [openFactSheetsUpdateDialog, setOpenFactSheetsUpdateDialog] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);

  const selectActiveBooklet = (id) => setActiveBookletId(id);
  const clearActiveBooklet = () => setActiveBookletId(null);

  return {
    activeBookletId,
    openFactSheetsUpdateDialog,
    openConfirmDeleteDialog,
    openDownloadDialog,
    setOpenFactSheetsUpdateDialog,
    setOpenConfirmDeleteDialog,
    setOpenDownloadDialog,
    selectActiveBooklet,
    clearActiveBooklet,
  };
};

export default useFactSheetsPage;
