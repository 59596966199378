import React, { useMemo } from 'react'

const useParamsQuery = () => React.useMemo(() => new URLSearchParams(window.location.search), [window.location.search])

export const useParamsQueryObject = () => {
  const params = useParamsQuery()
  return useMemo(() => Object.fromEntries(params.entries()), [params])
}

export default useParamsQuery
