/* eslint-disable react/display-name */
import {
  ActionIcon,
  Anchor,
  Box,
  Center,
  Checkbox,
  Text
} from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { useQueryClient } from '@tanstack/react-query'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import DataTable from '../../components/tables/DataTable'
import useReactTableManager from '../../hooks/useReactTableManager'
import { useAppSelector } from '../../hooks/useRedux'
import useUpdatePeerGroup, { useUpdatePeerGroupNotification } from '../../hooks/useUpdatePeerGroup'

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor('select', {
    cell: ({ row }) => (
      <Checkbox
        {...{
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
    ),
    enableSorting: false,
    header: ({ table }) => (
      <Checkbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    id: 'select',
    size: 50
  }),
  columnHelper.accessor('fundName', {
    cell: info => {
      return (<Anchor
        component={Link}
        size="sm"
        to={`/asset-detail/${info.row.original.fundId}`}
      >{info.getValue()}</Anchor>)
    },
    header: 'Name',
    id: 'fundName'
  }),
  columnHelper.accessor('addedAt', {
    cell: info => moment(info.getValue()).format('YYYY-MM-DD'),
    header: 'Added At',
    id: 'addedAt',
  }),
]

const PeerGroupFundsTable = () => {
  const queryClient = useQueryClient()
  const { isLoading, updatePeerGroupInfo } = useUpdatePeerGroup()
  const { showNotification } = useUpdatePeerGroupNotification()

  const selectedPeerGroup: PeerGroup = useAppSelector(state => state.peerGroups.selectedGroupId && state.peerGroups.entities[state.peerGroups.selectedGroupId])
  const aaapEntities = useAppSelector(state => state.aaap.entities)

  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({})
  const tableData = useMemo(() => {
    if (!selectedPeerGroup) return []
    return selectedPeerGroup.funds.map(fund => ({ ...aaapEntities[fund.fundId], ...fund }))
  }, [selectedPeerGroup, aaapEntities])
  const hasSelectedFunds = Object.keys(rowSelection).length > 0
  const displayColumns = useMemo(() => {
    if (!selectedPeerGroup?.canEdit) {
      return columns.filter(column => column.id !== 'select')
    }

    return columns
  }, [columns, selectedPeerGroup])

  useEffect(() => {
    if (selectedPeerGroup) {
      setRowSelection({})
    }
  }, [selectedPeerGroup])

  const { table } = useReactTableManager({
    columns: displayColumns,
    data: tableData,
    enableRowSelection: true,
    getRowId: row => row.fundId,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  })

  const clickDeleteButtonHandler = async () => {
    try {
      const updatedFunds = selectedPeerGroup.funds.filter(fund => !rowSelection[fund.fundId])
      const numberOfFundsRemoved = selectedPeerGroup.funds.length - updatedFunds.length
      const updatedGroup = {
        ...selectedPeerGroup,
        funds: updatedFunds
      }
      await updatePeerGroupInfo(updatedGroup)
      showNotification({ groupName: selectedPeerGroup.name, message: `${numberOfFundsRemoved} fund(s) has been removed from the group.` })
    } catch (err) { }
  }

  return tableData?.length > 0 ? (
    <Box>
      <ActionIcon
        disabled={!hasSelectedFunds}
        loading={isLoading}
        ml={4}
        onClick={clickDeleteButtonHandler}
        opacity={hasSelectedFunds ? 1 : 0}
        variant="transparent"
      >
        <IconTrash />
      </ActionIcon>
      <DataTable table={table} />
    </Box>
  ) : (
    <Center mt="md">
      <Text>No funds in this group</Text>
    </Center>
  )
}

export default PeerGroupFundsTable
