import {
  Box,
  Button,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from '@material-ui/core'
import CreateRoundedIcon from '@material-ui/icons/CreateRounded'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

const BulkColumnEditor = ({
  arrayName,
  placeholder,
  targetPropName
}) => {
  const { setValue, watch } = useFormContext()
  const watchedFieldArray = watch(arrayName)
  const [anchorEl, setAnchorEl] = useState(null)
  const [textFieldValue, setTextFieldValue] = useState('')
  const open = Boolean(anchorEl)
  const total = watchedFieldArray.reduce((acc, it) => {
    return acc + parseFloat(it[targetPropName] || 0)
  }, 0)

  const clickIconButtonHandler = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const dimissPopoverHandler = () => {
    setAnchorEl(null)
  }

  const updateTextFieldHandler = (event) => {
    setTextFieldValue(event.target.value)
  }

  const clickUpdateButtonHandler = () => {
    dimissPopoverHandler()
    watchedFieldArray.forEach((it, idx) => {
      setValue(`${arrayName}.${idx}.${targetPropName}`, textFieldValue)
    })
    setTextFieldValue('')
  }

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="flex-end"
        width="100%"
      >
        <Tooltip
          arrow
          placement="top"
          title={`Bulk Update ${placeholder}`}
        >
          <IconButton
            aria-describedby="simple-popover"
            color="primary"
            onClick={clickIconButtonHandler}
            size="small"
          >
            <CreateRoundedIcon
              color="secondary"
              fontSize="small"
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'center',
        }}
        id="simple-popover"
        onClose={dimissPopoverHandler}
        open={open}
        transformOrigin={{
          horizontal: 350,
          vertical: 'center',
        }}
      >
        <Box p={2}>
          <TextField
            onChange={updateTextFieldHandler}
            placeholder={placeholder}
            value={textFieldValue}
          />
          <Button
            color="primary"
            onClick={clickUpdateButtonHandler}
            variant="contained"
          >
            Update
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default BulkColumnEditor
