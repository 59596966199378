import React, { Component } from "react";
import { LineTimeSeries } from "../../components/charts";
import { dummy_returns_timeseries } from "../../data/trackingError";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { tracking_error_timelines } from "../../data/trackingError";
import { GridToolbar } from "@progress/kendo-react-grid";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import Typography from "@material-ui/core/Typography";

export default class trackingErrorChart extends Component {
  state = {
    current_view: null,
  };

  changeView = (new_view) => {
    this.setState({ current_view: new_view });
  };

  generateButtonGroup = () => {
    const current_view = this.state;
    return tracking_error_timelines.map((btn, idx) => {
      const color = current_view === btn.id ? "primary" : "default";
      return (
        <Button
          onClick={() => {
            this.changeView(btn.id);
          }}
          color={color}
          key={idx}
        >
          {btn.title}
        </Button>
      );
    });
  };

  getData = () => {
    // do filtering here
    let filtered = [...dummy_returns_timeseries];
    const { current_view } = this.state;
    if (current_view !== null && current_view <= filtered.length) {
      filtered = filtered.slice(Math.max(filtered.length - current_view, 1));
    }
    return filtered;
  };

  render() {
    const filtered_data = this.getData();
    return (
      <React.Fragment>
        <div className="tracking-error-page">
          <GridToolbar>
            <ButtonGroup
              className="tracking-error-buttons"
              variant="contained"
              color="default"
            >
              {this.generateButtonGroup()}
            </ButtonGroup>
          </GridToolbar>
          <div className="tracking-error-chart">
            {/* <Card> */}
            {/* <CardContent> */}
            <LineTimeSeries
              data={filtered_data || []}
              title="Tracking Error"
              minimum
              format="{0:c}"
              category_field="perf_date"
            />
            {/* </CardContent> */}
            {/* </Card> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
