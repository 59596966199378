import { useMemo } from "react";
import { useSecurities } from "./useSecurities"

/**
 * Defines the order of fund classes for prioritization.
 * Lower numbers indicate higher priority.
 */
export const classOrder: { [key: string]: number } = { X: 0, H: 2, B: 1 };

export const useParsedAAAPMap = () => {
  const { aaap_cell_list: aaapFunds } = useSecurities()
  return useMemo(() => {
    const aaapMap = new Map<string, any>()

    if (aaapFunds) {
      aaapFunds.forEach((fund) => {
        // Split the fund name into the full name and class information
        const [fullName, classInfo] = fund.name.split(' - ');

        // Remove ' Fund' suffix from the name for consistency
        const name = fullName.replace(/ Fund$/, '');

        // Extract the class name (assuming it's always the second word in classInfo)
        const className = classInfo.split(' ')[1];

        // Update the map only if the fund doesn't exist or has a higher priority class
        if (!aaapMap.has(name) || classOrder[className] < classOrder[aaapMap.get(name)!.class]) {
          aaapMap.set(name, { id: fund.id, class: className });
        }
      })
    }

    return aaapMap
  }, [aaapFunds])
}

const processInsightBody = (postBody: string, aaapMap: Map<string, any>): string => {
  const regex = /\{\{((?:[^{}]|<[^>]*>)*)\}\}/g;

  return postBody.replace(regex, (match, content) => {
    const cleanFundName = content.replace(/<[^>]*>/g, '').trim();
    const fund = aaapMap.get(cleanFundName);

    if (fund) {
      return `<a href="/asset-detail/${fund.id}" target="_blank">${content}</a>`;
    }

    return match;
  });
};

export const useProcessedInsightBody = (insight: string) => {
  const aaapMap = useParsedAAAPMap()
  return useMemo(() => insight ? processInsightBody(insight, aaapMap) : '', [insight, aaapMap])
}