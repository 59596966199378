const removeNullProperties = (obj) => {
  if (typeof obj !== 'object') {
    return obj;
  }

  const cleanObject = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      cleanObject[key] = value;
    }
  });

  return cleanObject;
};

export default removeNullProperties;
