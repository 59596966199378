import {
  Box,
  Button,
  Flex,
  Group,
  Pill,
  Text
} from '@mantine/core'
import React, { useMemo } from 'react'

import camelCaseToTitleCase from '../../utils/camelCaseToTitleCaase'


interface FilterViewerProps {
  filters?: Record<string, string | string[] | undefined>
  onClickDeleteFilter?: (key: string) => void
  onClickClear?: () => void
}

const FilterViewer: React.FC<FilterViewerProps> = ({
  filters,
  onClickClear,
  onClickDeleteFilter
}) => {
  const filterArray = useMemo(() => {
    if (!filters) return []

    return Object.entries(filters || {})
      .filter(([key, value]) => (typeof value === 'string' && value) || (Array.isArray(value) && value.length > 0))
      .map(([key, value]) => ({
        key,
        label: `${camelCaseToTitleCase(key)}: ${typeof value === 'string' ? value : value?.join(', ')}`
      }))
  }, [filters])

  const clickDeleteIconButtonHandler = (key: string) => () => {
    onClickDeleteFilter?.(key)
  }

  return filterArray.length > 0 ? (
    <Box>
      <Flex
        gap="sm"
        mb="sm"
      >
        <Text
          mt={4}
          size="sm"
        >Viewing Filters: </Text>
        <Group gap="xs">
          {
            filterArray?.map(({ key, label }, idx) => (
              <Pill
                key={idx}
                onRemove={clickDeleteIconButtonHandler(key)}
                withRemoveButton
              >{label}</Pill>
            ))
          }
        </Group>
      </Flex>
      {
        onClickClear &&
        <Button onClick={onClickClear}>Reset</Button>
      }
    </Box>
  ) : null
}

export default FilterViewer
