import { useMutation } from '@tanstack/react-query';
import { get } from '../services/api';
import { buildFileFromResponse } from '../utils';

const useDownloadPDF = () => {
  const {
    mutateAsync: getPdf,
    isLoading: isDownloadingPdf,
  } = useMutation(
    (url) => get(url, null, { responseType: 'blob' }),
    {
      onSuccess: (response) => {
        buildFileFromResponse(response);
      },
    },
  );

  return { handleDownload: getPdf, isDownloadingPdf };
};

export default useDownloadPDF;
