import { Box, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import ListSubheader from '@material-ui/core/ListSubheader'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import Fuse from 'fuse.js'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { VariableSizeList } from 'react-window'

const fuseOptions = {
  includeMatches: true,
  keys: ['name'],
  threshold: 0.45,
}

const smartSearch = ({ filterKey, fuse, options, searchText }) => {
  if (!searchText) {
    return options
  }

  const searchResults = fuse
    .search({
      $and: searchText
        ?.trim()
        ?.split(' ')
        .map((str) => ({ [filterKey]: str })),
    })
    .map((result) => result.item)

  return searchResults?.sort((a, b) => a.type.localeCompare(b.type))
}
const LISTBOX_PADDING = 8
function renderRow(props) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      top: style.top + LISTBOX_PADDING,
      whiteSpace: 'nowrap',
    },
  })
}
const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div
    ref={ref}
    {...props}
    {...outerProps}
  />
})

function useResetCache(data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = 36

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48 // Category title height
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          height={getHeight() + 2 * LISTBOX_PADDING}
          innerElementType="ul"
          itemCount={itemCount}
          itemData={itemData}
          itemSize={(index) => getChildSize(itemData[index])}
          outerElementType={OuterElementType}
          overscanCount={5}
          ref={gridRef}
          style={{ overflowX: 'hidden' }}
          width="100%"
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const AutoCompleteSelect = ({
  dataKey,
  error,
  filterKey,
  groupBy,
  helperText,
  inputName,
  isStringArr,
  label,
  multiple,
  options,
  ...props
}) => {
  const { control } = useFormContext()
  const fuse = useMemo(() => {
    const myIndex = Fuse.createIndex(fuseOptions.keys, options)
    return new Fuse(options, fuseOptions, myIndex)
  }, [options])

  const filterFundOptions = (options, { inputValue }) =>
    smartSearch({
      filterKey,
      fuse,
      options,
      searchText: inputValue,
    })

  if (multiple) {
    return (
      <Controller
        control={control}
        name={inputName}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            defaultValue={value}
            getOptionLabel={(option) => option[filterKey]}
            id="tags-standard"
            multiple
            onChange={(e, data) => {
              onChange(data)
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error}
                helperText={helperText}
                label={label}
                variant="standard"
              />
            )}
          />
        )}
      />
    )
  }

  if (isStringArr) {
    return (
      <Controller
        control={control}
        name={inputName}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            ListboxComponent={ListboxComponent}
            defaultValue={value}
            disableListWrap
            onChange={(e, data) => {
              onChange(data)
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error}
                helperText={helperText}
                label={label}
              />
            )}
            renderOption={(option, { inputValue }) => {
              const matches = match(option, inputValue)
              const parts = parse(option, matches)

              return (
                <Typography noWrap>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Typography>
              )
            }}
            {...props}
          />
        )}
      />
    )
  }

  const renderGroup = (params) => [
    <Box
      key={params.key}
      ml={2}
      style={{ color: 'gray', height: '30px' }}
    >
      <Typography
        style={{ fontWeight: 'bold' }}
        variant="body1"
      >
        {params.group}
      </Typography>
    </Box>,
    params.children,
  ]

  return (
    <Controller
      control={control}
      name={inputName}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          ListboxComponent={ListboxComponent}
          defaultValue={value}
          disableListWrap
          filterOptions={filterFundOptions}
          getOptionLabel={(option) => option[filterKey]}
          groupBy={(opt) => opt.type}
          onChange={(e, data) => {
            onChange(data)
          }}
          options={options}
          renderGroup={renderGroup}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              helperText={helperText}
              label={label}
            />
          )}
          renderOption={(option, { inputValue }) => {
            const matches = match(option[filterKey], inputValue)
            const parts = parse(option[filterKey], matches)

            return (
              <Typography noWrap>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
              </Typography>
            )
          }}
          {...props}
        />
      )}
    />
  )
}

export default AutoCompleteSelect
