export const empty_user = {
  id: null,
  first_name: "",
  last_name: "",
  email: "",
  groups: [],
  company: null,
  country: null,
  is_active: true
};
