import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { useQuery } from '@tanstack/react-query'
import FormData from 'form-data'
import { Form, Formik } from 'formik'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

import {
  Combobox,
  DatePicker,
  RichTextForm,
  SecurityList,
  SingleFileDrop,
  TextInput,
} from '../../components'
import { useSecurities } from '../../hooks'
import router from '../../resources/history'
import { getDocumentType, getMailingList, getSingleDocument, updateDocument } from '../../services'
import { dateTimeManager } from '../../utils'
import { ImageGrid } from '../imageRepository'

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

const NewStory = ({ location }) => {
  const { search_list } = useSecurities()
  const { search } = location || {}
  const [editorMode, setEditorMode] = useState('rms document')
  const [repositoryOpen, setRepositoryOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const extended = editorMode === 'aaap story'
  const docPlaceholder = extended ? 'Title' : 'Document Name'
  const [initialValues, setInitialValues] = useState({
    as_of_date: dateTimeManager.previousMonthEnd(),
    attachment: null,
    attachment_original_name: null,
    body: null,
    cover_image: null,
    description: '',
    email: '',
    id: null,
    name: '',
    priority: '',
    reading_time: '',
    security: '',
    story_type: 'Letters and Risk reports',
  })

  const { data: emailList } = useQuery(
    ['mailing_list'],
    async () => {
      const response = await getMailingList()
      return response.data
    },
    { staleTime: Infinity },
  )

  const { data: documentTypes } = useQuery(
    ['documentTypes'],
    async () => {
      const response = await getDocumentType()
      return response.data
    },
    {
      select: (data) => data.map((o) => o.name),
      staleTime: Infinity
    },
  )

  useEffect(() => {
    const fetchSingleStory = async (story_id, isDuplicate) => {
      try {
        const response = await getSingleDocument(story_id)
        let {
          as_of_date,
          body,
          description,
          id,
          name,
          reading_time,
          security,
          story_type,
        } = response.data
        const fund = search_list.find((o) => o.id === security)
        setInitialValues((values) => ({
          ...values,
          as_of_date: dateTimeManager.dbToJs(as_of_date),
          body,
          description,
          id: isDuplicate ? null : id,
          name,
          reading_time,
          security: fund,
          story_type,
        }))
      } catch (error) { }
    }

    const parsed_params = qs.parse(search.replace('?', ''))
    const {
      duplicate,
      security: security_id,
      story_id,
      story_type: story_type_id,
    } = parsed_params || {}
    const isDuplicate = duplicate === 'true'
    if (story_id) {
      fetchSingleStory(story_id, isDuplicate)
    }
    if (security_id) {
      const fund = search_list.find((o) => o.id === Number(security_id))
      setInitialValues((values) => ({
        ...values,
        security: fund,
      }))
    }
    if (story_type_id) {
      setInitialValues((values) => ({
        ...values,
        story_type: story_type_id,
      }))
    }
  }, [search, search_list])

  const submitDocs = async (documentData) => {
    setUploading(true)
    const {
      as_of_date,
      attachment,
      cover_image,
      email: mailto,
      security: security_data,
      ...rest
    } = documentData
    const { id: security } = security_data || {}
    const { email } = mailto || {}

    const payload = {
      ...rest,
      as_of_date: dateTimeManager.jsToDateTimeDb(as_of_date),
      email,
      security,
    }

    var form_data = new FormData()

    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const element = payload[key]
        if (element) {
          form_data.append(key, element)
        }
      }
    }
    if (!security) {
      form_data.append('security', '')
    }

    if (attachment) {
      form_data.append('attachment', attachment)
      form_data.append('attachment_original_name', attachment.name)
    }
    if (cover_image) {
      form_data.append('cover_image', cover_image)
    }
    try {
      await updateDocument(form_data)
      router.push('/document-management')
    } catch (error) { }
    setUploading(false)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Document Name'),
  })

  return (
    <div className="new-story-container">
      <div className="editor-container">
        <div className="title-row" />
        <ButtonGroup
          className="mode-button"
          color="primary"
          fullWidth
        >
          {['rms document', 'aaap story'].map((btn) => (
            <Button
              key={btn}
              onClick={() => {
                setEditorMode(btn)
              }}
              variant={editorMode === btn ? 'contained' : 'outlined'}
            >
              {btn}
            </Button>
          ))}
        </ButtonGroup>
        <Formik
          enableReinitialize
          initialValues={{
            ...initialValues,
          }}
          onSubmit={submitDocs}
          validateOnBlur={true}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <StyledForm>
              <TextInput
                className="single-editor"
                name="name"
                placeholder={docPlaceholder}
              />

              {extended && (
                <TextInput
                  className="single-editor"
                  name="description"
                  placeholder="Description"
                />
              )}
              <Combobox
                className="kendo-virtual-combobox single-editor"
                items={documentTypes}
                name="story_type"
                placeholder="Document Type"
              />
              <SecurityList
                className="kendo-virtual-combobox global-search single-editor"
                dataItemKey="id"
                items={search_list}
                name="security"
                noLink={true}
                placeholder="Fund"
                searchFields={[
                  'name',
                  'isin',
                  'underlying_name',
                  'principal_name',
                ]}
                textField="name"
              />
              <DatePicker
                className="single-editor"
                name="as_of_date"
                style={{ width: '100%' }}
                showTodayButton
              />
              {extended && (
                <TextInput
                  className="single-editor"
                  name="reading_time"
                  placeholder="Reading Time"
                  type="number"
                />
              )}
              {extended && (
                <TextInput
                  className="single-editor"
                  name="priority"
                  placeholder="Priority"
                  type="number"
                />
              )}

              <SingleFileDrop
                className="single-editor"
                fieldOverride={['name', 'attachment_original_name']}
                label={"Drag'n'drop the document here. Or click to select"}
                name="attachment"
              />
              {extended && (
                <SingleFileDrop
                  className="single-editor"
                  label={"Drag'n'drop the cover image here. Or click to select"}
                  name="cover_image"
                />
              )}
              {extended && (
                <Button
                  className="image-repo-button single-editor"
                  color="primary"
                  onClick={() => setRepositoryOpen(true)}
                  variant="outlined"
                >
                  Image Library
                </Button>
              )}
              <RichTextForm
                className="single-editor"
                height={250}
                name="body"
              />
              <Combobox
                className="kendo-virtual-combobox single-editor"
                dataItemKey="email"
                items={emailList}
                name="email"
                placeholder="Choose email if you wish to notify somebody on submission"
                textField="name"
              />

              <Button
                color="primary"
                disabled={uploading}
                onClick={handleSubmit}
                variant="contained"
              >
                {uploading ? 'Uploading...' : 'Upload'}
              </Button>
            </StyledForm>
          )}
        </Formik>
      </div>
      <ImageGrid
        handleClose={() => {
          setRepositoryOpen(false)
        }}
        open={repositoryOpen}
      />
    </div>
  )
}

export default NewStory
