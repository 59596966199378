import { Card } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const StyledTrackRecordImg = styled.div`
    display: flex;
    justify-content: center;

`

const TrackRecordTab = (fundData) => {
    const { track_record: trackRecordData } = fundData
    return (
        <Card>
            <StyledTrackRecordImg>
                <img
                    alt="graph"
                    src={trackRecordData}
                    width="100%"
                />
            </StyledTrackRecordImg>
        </Card>
    )
}

export default TrackRecordTab
