import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Groups from "./groups";
import Companies from "./companies";

export default function Layout() {
  const [currentView, setCurrentView] = useState("companies");

  let Manager;

  switch (currentView) {
    case "groups":
      Manager = Groups;
      break;
    case "companies":
      Manager = Companies;
      break;
    default:
      break;
  }

  return (
    <div className="group-container">
      <div className="tab-menu">
        <Tabs
          value={currentView}
          onChange={(e, current_view) => setCurrentView(current_view)}
        >
          <Tab label="Companies" value="companies" />
          <Tab label="Groups" value="groups" />
        </Tabs>
      </div>
      <Manager />
    </div>
  );
}
