import styled from "styled-components";

export const Zone = styled.div`
  flex: 1;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  /* margin-top: 20px; */
  .dropzone {
    width: 100%;
    height: 100%;
    text-align: center;
  }
`;
