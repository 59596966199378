import styled from 'styled-components';
import { Form } from 'formik';
import Button from '@material-ui/core/Button';
import AuthBg from '../../styles/assets/images/auth_bg.png';

export const AuthPageContainer = styled.div`
  height: 100vh;
  background: ${({ theme }) => theme.palette.aamui.sapphire};
  position: relative;

  &:after {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
    background: ${({ theme }) => theme.palette.aamui.sapphire};
  }
  
  ${({ theme }) => theme.breakpoints.up('desktop')} {
    display: flex;
    flex-direction: row;
  }
`;

export const BannerContainer = styled.div`
  align-items:center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 77%;
  background-image: url(${AuthBg});
  display: none;
  position: relative;
  width: 100%;
  z-index: 10;
  max-width: 576px;

  h1 {
    font-size: 46px;
    color: white;
    max-width: 528px;
    font-weight: lighter;
    margin-left: 72px;
  }

  ${({ theme }) => theme.breakpoints.up('desktop')} {
    display: flex;
  }
`;

export const RootContainer = styled.div`
  height: 100vh;
  background: #0A2839;

  &:after {
    content: '';
    background: #3069AB;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ view }) => (view === 'default' && 506) || (view === 'password_reset' && 436) || 0}px;
    z-index: 0;
  }

  &:before {
    content: '';
    position: fixed;
    background: #3069ab;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

export const LoadingScreenContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.aamui.sapphire};
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const Container = styled.div`
  margin: 0 auto;
  height: 100%;
  position: relative;
  z-index: 10;
`;

export const FormContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 48px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  z-index: 10;
  overflow-y: scroll;
  height: 100%;

  & > * {
    z-index: 10;
  }

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    align-items: unset;
    padding: 0 156px;
  }
  
  ${({ theme }) => theme.breakpoints.up('desktop')} {
    align-items: unset;
    padding: 0 120px;

    & > * {
      max-width: 334px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
  position: relative;
  width: 100%;

  & > *,
  .MuiInputBase-root {
    width: 100%;
  }

  .item:not(:first-child) {
    margin-top: 24px;
  }

  & .MuiFormControlLabel-label {
    line-height: unset !important;
  }

  & .MuiButton-textPrimary {
    color: #5E94C7;
    text-decoration: underline;
    text-transform: none;
    padding: 0;
    margin: 24px 0 0;
  }

  & .MuiIconButton-root,
  & .MuiIconButton-root:disabled {
    color: #B8D6EB;
  }

  & .Mui-checked {
    color: #B8D6EB !important;
  }

  & .back-to-login-btn {
    display: flex;
    justify-content: flex-start;
    color: white;
    font-size: 16px;
  }
`;

export const InputFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    margin-bottom: 24px !important;
  }
`;

export const RequestAccessTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 16px;
  color: white;
  margin-top: 24px;

  & .MuiButton-textPrimary {
    margin: 0;
    color: white;
    font-size: 16px;
  }

  div {
    font-weight: lighter;
  }
`;

export const LogoWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.aamui.iceBlue};
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 32px;
  padding-bottom: 24px;
`;

export const Logo = styled.img`
`;

export const Header = styled.h1`
  font-size: 32px;
  color: white;
  font-weight: 300;
  margin: 0 0 24px;
`;

export const Link = styled.a`
  color: ${({ isNew }) => (isNew ? '#82B2D9' : 'black')};
`;

export const CheckboxLabel = styled.label`
  color: white;
  font-size: 12px;
  
  a {
    font-weight: bold;
    color: white;
  }
  
  span {
    font-weight: lighter !important;
    margin-right: 4px;
  }
`;

export const SubmitButton = styled(Button).attrs((props) => ({
  color: 'primary',
  variant: 'contained',
}))`
  padding: 0.5rem;
`;

export const NewSubmitButton = styled(Button).attrs((props) => ({
  color: 'primary',
  variant: 'contained',
}))`
  background: white !important;
  border: 0;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: ${({ theme }) => theme.palette.aamui.sapphire} !important;
  font-weight: bold;
  font-size: 16px;
  height: 48px;
  margin-top: 24px !important;
  text-transform: none !important;
  width: 100%;

  & span {
    text-transform: uppercase !important;
  }
  
  &:disabled {
    background: ${({ theme }) => theme.palette.aamui.iceBlue} !important;
    color: white !important;
    opacity: 0.5;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.aamui.oceanNavy} !important;
    color: white !important;
  }
`;

export const LoginButtonsWrapper = styled.div`
  color: white;
  border-top: 1px solid ${({ theme }) => theme.palette.aamui.iceBlue};
  padding-top: 24px;

  .forgot-pwd-btn {
    font-size: 16px;
    color: white;
    margin-top: 48px;
  }
`;

export const ResetPasswordInfoText = styled.div`
  color: white;
  font-size: 16px;
  margin-top: 24px;
  font-weight: light;
`;
