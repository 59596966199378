import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useSecurities } from '../../hooks'
import { useParamsQueryObject } from '../../hooks/useParamsQuery'
import { deleteDocument, emailDocument, getDocuments, getDocumentType } from '../../services'
import { dateTimeManager as dt } from '../../utils'
import Grid from './documentGrid'

const pageSize = 20

export default function GridWrapper({
  fixedFilters,
  fixedSecurity = '',
  height = '77.5vh',
  isPrivateEquity,
  showSelectedSecurity = true,
}) {
  const [filters, setFilters] = useState({
    as_of_date: null,
    ordering: null,
    page: 1,
    security: null,
    story_type: null,
  })
  const { search_list_map: searchListMap } = useSecurities()
  const { date, fund, type } = useParamsQueryObject()
  const [skip, setSkip] = useState(0)
  const [storyType, setStoryType] = useState('')
  const [security, setSecurity] = useState('')
  const [storyDate, setStoryDate] = useState(null)
  const [selectedList, setSelectedList] = useState('')

  const { data: documentTypes, isLoading: loadingDocTypes } = useQuery(
    ['documentTypes'],
    async () => {
      const res = await getDocumentType()
      return res.data
    },
    { staleTime: Infinity },
  )

  const updateFilter = (key, value, transformFn = (v) => v) => {
    setFilters((i) => {
      const updatedFilters = { ...i, page: 1 }

      const transformedValue = transformFn(value)
      if (transformedValue != null) {
        updatedFilters[key] = transformedValue
      } else {
        delete updatedFilters[key]
      }

      return updatedFilters
    })

    setSkip(0)
  }

  useEffect(() => {
    if ((fund && type) && documentTypes?.length > 0 && searchListMap) {
      const docType = documentTypes.find((doc) => doc.name === type)

      setFilters(prev => ({
        ...prev,
        as_of_date: date ? dt.jsToDateTimeDb(date) : null,
        security: +fund,
        story_type: docType.id,
      }))

      setSecurity(searchListMap[fund])
      setStoryType(docType)
      setStoryDate(new Date(date))
    }
  }, [fund, type, documentTypes, searchListMap, date])

  const filterSecurities = ({ target }) => {
    const security = target.value
    setSecurity(security)
    updateFilter('security', security?.id)
  }

  const filterStoryType = ({ target }) => {
    const storyType = target.value
    setStoryType(storyType)
    updateFilter('story_type', storyType?.id)
  }

  const filterStoryDate = ({ target }) => {
    const story_date = target.value
    setStoryDate(story_date)
    updateFilter('as_of_date', story_date, dt.jsToDateTimeDb)
  }

  const {
    data: documents,
    isLoading: loadingDocs,
    refetch,
  } = useQuery(
    ['documents', filters, fixedFilters],
    async () => {
      const response = await getDocuments({
        ...filters,
        ...fixedFilters,
      })
      for (let i = 0; i < response.data.results.length; i++) {
        let entry = response.data.results[i]
        entry['_as_of_date'] = dt.dbToJs(entry['as_of_date'])
        entry['_created_at'] = dt.dbToJs(entry['created_at'])
        entry['created_info'] = `${dt.dbToLongFormat(entry.created_at)} by ${entry.user_name
          }`
      }
      return response.data
    },
    { enabled: !!filters }, // The query will not automatically run
  )

  const handleDelete = async (dataItem) => {
    const { id } = dataItem

    await deleteDocument({ id })
    refetch()
  }

  const handleNotify = async (dataItem) => {
    const payload = {
      id: dataItem.id,
      mailing_list: selectedList.email,
    }
    try {
      await emailDocument(payload)
    } catch (e) {
      toast.error('Something went wrong')
    }
  }

  const handlePageChange = ({ page }) => {
    const { skip } = page
    const newPage = page.skip / pageSize + 1
    setFilters((i) => ({ ...i, page: newPage }))
    setSkip(skip)
  }

  const loading = loadingDocs || loadingDocTypes

  return (
    <Grid
      documentTypes={documentTypes}
      documents={documents}
      filterSecurities={filterSecurities}
      filterStoryDate={filterStoryDate}
      filterStoryType={filterStoryType}
      filters={filters}
      handleDelete={handleDelete}
      handleNotify={handleNotify}
      handlePageChange={handlePageChange}
      height={height}
      isPrivateEquity={isPrivateEquity}
      loading={loading}
      pageSize={pageSize}
      security={fixedSecurity || security}
      selectedList={selectedList}
      setFilters={setFilters}
      setSelectedList={setSelectedList}
      showSelectedSecurity={showSelectedSecurity}
      skip={skip}
      storyDate={storyDate}
      storyType={storyType}
    />
  )
}
