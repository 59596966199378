import React from 'react'
import FaqsBlock, { EmptyFaqMessage } from './FaqsBlock'
import {
  BlogType,
  FundTypeGlobalFaqHeaderMap,
  SecurityTypeToBlogSlugMap,
} from '../../utils/fundFaqUtils'
import FaqEditButton from './FaqEditButton'
import useCreateHsBlogPost from '../../hooks/useCreateHsBlogPost'
import LoadingSpinner from '../../components/misc/LoadingSpinner'
import useFaqData from '../../hooks/useFaqData'
import useFetchFaqs from '../../hooks/useFetchFaqs'
import { SecurityType } from '../../models/SecurityTypes'
import {
  FaqsActionButtonsContainer,
  FaqsContainer,
  FaqsHeader,
} from './FaqsTabLayout'

interface PeFaqTabProps {
  id: number
  name: string
  underlying_fund: any
}

const PeFaqTab: React.FC<PeFaqTabProps> = ({ id, name, underlying_fund }) => {
  const { createHsBlogPost, isCreatingHsBlogPost } = useCreateHsBlogPost()

  const underlyingFundId =
    underlying_fund?.id !== id ? underlying_fund?.id : null

  const [hsPeFaqsResults, { isFetching: isFetchingPeFaqs, refetch }] =
    useFetchFaqs(BlogType.PE_FUND_FAQS, id, !!id)
  const [hsGlobalFaqsResults, { isFetcinh: isFetchingGlobalFaqs }] =
    useFetchFaqs(
      BlogType.GLOBAL_FUND_FAQS,
      SecurityTypeToBlogSlugMap[SecurityType.PRIVATE_EQUITY],
      true,
    )
  const [
    hsUnderlyingFundFaqsResults,
    {
      isFetching: isFetchingUnderlyingFundFaqs,
      refetch: refetchUnderlyingFundFaqs,
    },
  ] = useFetchFaqs(BlogType.PE_FUND_FAQS, underlyingFundId, !!underlyingFundId)

  const [peFaqs, fundFaqsHsBlogPostId] = useFaqData(hsPeFaqsResults)
  const [globalFaqs, globalFaqsHsBlogPostId] = useFaqData(hsGlobalFaqsResults)
  const [underlyingFundFaqs, underlyingFundFaqsHsBlogPostId] = useFaqData(
    hsUnderlyingFundFaqsResults,
  )
  const isFetchingFaqs =
    isFetchingPeFaqs || isFetchingGlobalFaqs || isFetchingUnderlyingFundFaqs
  const disableButtons = isFetchingFaqs || isCreatingHsBlogPost
  const isZeroFaqs =
    !isFetchingFaqs &&
    !peFaqs?.length &&
    !globalFaqs?.length &&
    !underlyingFundFaqs?.length

  const clickCreateBlogPostButtonHandler =
    (
      fundId: number | string,
      fundName: string,
      isCreatingUnderlyingFund: boolean,
    ) =>
    async () => {
      const newBlogPostId = await createHsBlogPost({
        baseSlug: BlogType.PE_FUND_FAQS,
        fundName: fundName,
        contentGroupId: process.env.REACT_APP_HS_PE_FUND_FAQS_CONTENT_GROUP_ID,
        slugChildId: fundId,
      })

      if (newBlogPostId) {
        isCreatingUnderlyingFund ? refetchUnderlyingFundFaqs() : refetch()
      }
    }

  return (
    <FaqsContainer>
      <FaqsActionButtonsContainer>
        <FaqEditButton
          blogPostId={fundFaqsHsBlogPostId}
          disabled={disableButtons}
          faqsResults={hsPeFaqsResults}
          fundName={name}
          onClickCreate={clickCreateBlogPostButtonHandler(id, name, false)}
        />
        {!!underlyingFundId && (
          <FaqEditButton
            blogPostId={underlyingFundFaqsHsBlogPostId}
            disabled={disableButtons}
            faqsResults={hsUnderlyingFundFaqsResults}
            fundName={underlying_fund?.name}
            onClickCreate={clickCreateBlogPostButtonHandler(
              underlyingFundId,
              underlying_fund?.name,
              true,
            )}
          />
        )}
        <FaqEditButton
          blogPostId={globalFaqsHsBlogPostId}
          disabled={disableButtons}
          faqsResults={hsGlobalFaqsResults}
          fundName={FundTypeGlobalFaqHeaderMap[SecurityType.PRIVATE_EQUITY]}
        />
      </FaqsActionButtonsContainer>
      {isZeroFaqs ? (
        <EmptyFaqMessage />
      ) : (
        <>
          <FaqsHeader />
          <FaqsBlock loading={isFetchingFaqs} faqs={peFaqs} header={name} />
          <FaqsBlock
            loading={isFetchingUnderlyingFundFaqs}
            faqs={underlyingFundFaqs}
            header={name}
          />
          <FaqsBlock
            loading={isFetchingGlobalFaqs}
            faqs={globalFaqs}
            header={FundTypeGlobalFaqHeaderMap[SecurityType.PRIVATE_EQUITY]}
          />
        </>
      )}
    </FaqsContainer>
  )
}

export default PeFaqTab
