import { InputBase, Pill } from '@mantine/core'
import React, { useMemo } from 'react'

import { IRowSelectionProps } from '../../components/models/IRowSelectionProps'
import { useAppSelector } from '../../hooks/useRedux'

export const useSelectedFunds = (rowSelection: IRowSelectionProps['rowSelection'], open: boolean) => {
  return useMemo(() => {
    if (open) {
      return Object.entries(rowSelection).filter(([key, value]) => value).map(([key, value]) => +key)
    }
    return []
  }, [open, rowSelection])
}

interface FnudSelectionInputBoxProps {
  selectedFunds: number[]
  setRowSelection: IRowSelectionProps['setRowSelection']
}

const FundSelectionInputBox = ({
  selectedFunds,
  setRowSelection,
}: FnudSelectionInputBoxProps) => {
  const cachedFundsEntites = useAppSelector(state => state.peerGroups.cachedFunds)

  const clickRemoveFundHandler = (fundId: number) => () => {
    setRowSelection(prevRowSelection => ({
      ...prevRowSelection,
      [fundId]: false
    }))
  }

  return (
    <InputBase
      component="div"
      multiline
    >
      <Pill.Group>
        {
          selectedFunds?.map(fundId => (
            <Pill
              key={fundId}
              onRemove={clickRemoveFundHandler(fundId)}
              size="md"
              withRemoveButton
            >
              {cachedFundsEntites[fundId]?.name}
            </Pill>
          ))
        }
      </Pill.Group>
    </InputBase>
  )
}

export default FundSelectionInputBox