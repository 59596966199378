import React, { createContext, useState } from "react"


const state = {
    funds: {}
}

const PrivateEquityContext = createContext();

const PrivateEquityProvider = (props) => {
    const [privateEquityFunds, setPrivateEquityFunds] = useState(state)

    return (
        <PrivateEquityContext.Provider
            value={{ privateEquityFunds, setPrivateEquityFunds}}
        >
            {props.children}
        </PrivateEquityContext.Provider>
    )


}

export {PrivateEquityContext, PrivateEquityProvider}
