import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putBooklet } from '../services';
import useFactSheets from './useFactSheets';

const useUpdateBooklet = (onSuccess) => {
  const queryClient = useQueryClient();
  const { isLoadingBooklet } = useFactSheets();
  const {
    mutate: updateFactSheets,
    isLoading,
  } = useMutation(
    putBooklet,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['booklets'] });

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      },
    },
  );
  return { updateFactSheets, isUpdatingBooklet: isLoadingBooklet || isLoading };
};

export default useUpdateBooklet;
