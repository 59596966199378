function toSnakeCase(str) {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase()
}

function isPlainObject(obj) {
  return typeof obj === 'object' && obj !== null && obj.constructor === Object
}

function convertObjectKeysToSnakeCase(obj) {
  if (obj instanceof Date || obj instanceof RegExp || obj instanceof Error) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertObjectKeysToSnakeCase(item))
  }

  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce((result, key) => {
      const snakeCaseKey = toSnakeCase(key)
      result[snakeCaseKey] = convertObjectKeysToSnakeCase(obj[key])
      return result
    }, {})
  }

  return obj
}

export default convertObjectKeysToSnakeCase
