import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Card from "@material-ui/core/Card";
import {
  KendoDropDown,
  ColumnTimeSeries,
  CategoricalColumnChart,
  LineTimeSeries,
  Scatter,
} from "../../components";
import { exposure_analysis_lists } from "../../data/analysis";
import { buildYearList } from "../../utils";
import { Formik, Form } from "formik";
import { SubmitListener, DropdownForm, CheckBox } from "../../components/forms";

const baseUnits = { M: "months", Q: "quarters", Y: "years" };
const availablePeriods = Object.keys(baseUnits);
const chartGroups = [
  "Exposures",
  "Attribution",
  "Average Exposure",
  "Total Attribution",
  "RoC",
];
const specialCharts = ["CONCENTRATION", "NUMBER OF POSITIONS"];

const scalableCharts = ["long", "short", "gross"];

const TopLevelCategories = ({ top_level, top_level_date }) => {
  const { exposure, attribution } = top_level || {};
  const { exposure: exposureDates, attribution: attribDates } =
    top_level_date || {};
  const { all: totalAttribution = [] } = attribution || {};
  const { all: averageExposure = [] } = exposure || {};
  return (
    <>
      {averageExposure.length > 0 ||
        (totalAttribution.length > 0 && (
          <Grid item xs={12}>
            <div className="top-level-cat-title">
              <Typography variant="h6"> Top-Level Totals: </Typography>
            </div>
          </Grid>
        ))}
      {averageExposure.length > 0 && (
        <Grid item xs={12} xl={6}>
          <Card>
            <CategoricalColumnChart
              data={averageExposure}
              title={`Average Exposure ${exposureDates}`}
              category_field={null}
              stack={false}
            />
          </Card>
        </Grid>
      )}
      {totalAttribution.length > 0 && (
        <Grid item xs={12} xl={6}>
          <Card>
            <CategoricalColumnChart
              data={totalAttribution}
              title={`Total Attribution ${attribDates}`}
              category_field={null}
              stack={false}
            />
          </Card>
        </Grid>
      )}
    </>
  );
};

const TopLevelTs = ({ top_level }) => {
  const [periodicity, setPeriodicity] = useState("M");
  const { exposure, attribution } = top_level || {};
  const { [periodicity]: exposureData = [] } = exposure || {};
  const { [periodicity]: attributionData = [] } = attribution || {};

  return (
    <>
      {exposureData.length > 0 ||
        (attributionData.length > 0 && (
          <Grid item xs={12}>
            <div className="top-level-ts-title">
              <Typography variant="h6"> Top-Level Timeseries: </Typography>
              <KendoDropDown
                value={periodicity}
                items={availablePeriods}
                onChange={({ target }) => setPeriodicity(target.value)}
                className="periodicity-select"
              />
            </div>
          </Grid>
        ))}

      {exposureData.length > 0 && (
        <Grid item xs={12} xl={6}>
          <Card>
            <ColumnTimeSeries
              data={exposureData}
              units={baseUnits[periodicity]}
              scaled={false}
              title={`Top Level Exposure`}
              showTotal={false}
            />
          </Card>
        </Grid>
      )}
      {attributionData.length > 0 && (
        <Grid item xs={12} xl={6}>
          <Card>
            <ColumnTimeSeries
              data={attributionData}
              units={baseUnits[periodicity]}
              scaled={false}
              title={`Top Level Attribution`}
              showTotal={true}
            />
          </Card>
        </Grid>
      )}
    </>
  );
};

const ControlBar = ({
  initialValues,
  handleChange,
  classifications = [],
  yearList = [],
}) => {
  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      onSubmit={handleChange}
    >
      {(formik) => {
        const { values } = formik;
        const timeSeries = ["Exposures", "Attribution"];
        const { classification, chart_type } = values || {};
        const allDisabled = specialCharts.indexOf(classification) !== -1;
        const tsRelatedDisabled = !!(
          timeSeries.indexOf(chart_type) === -1 || allDisabled
        );
        return (
          <>
            <SubmitListener formik={formik} />
            <Form>
              <AppBar position="static" color="default">
                <Toolbar className="exposure-selector">
                  <CheckBox
                    label="Scale Exposures"
                    name="exposures_scaled"
                    disabled={tsRelatedDisabled}
                  />
                  <DropdownForm
                    items={classifications}
                    name="classification"
                    defaultItem="Classification"
                  />
                  <DropdownForm
                    items={chartGroups}
                    name="chart_type"
                    disabled={allDisabled}
                  />
                  <DropdownForm
                    items={availablePeriods}
                    name="aggregation"
                    disabled={tsRelatedDisabled}
                  />
                  <DropdownForm
                    items={yearList}
                    name="start_year"
                    defaultItem="Start Year"
                    disabled={tsRelatedDisabled}
                  />
                  <DropdownForm
                    items={yearList}
                    name="end_year"
                    defaultItem="End Year"
                    disabled={tsRelatedDisabled}
                  />
                </Toolbar>
              </AppBar>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const extractNormalExposure = (report, id) => {
  try {
    const { M: dataset } = report.normal_exposures[id].gross;
    return dataset;
  } catch (error) {
    return [];
  }
};

const TimeSeriesChartSet = ({ ts_type, filters, getData }) => {
  const { aggregation, exposures_scaled, start_year, end_year } = filters || {};
  if (!aggregation) {
    return;
  }
  const { time_series_charts } = exposure_analysis_lists;
  const units = baseUnits[aggregation];
  return (
    <>
      {time_series_charts[ts_type].map((name, idx) => {
        const appendNet = !!(name.id === "net");
        const scalable = scalableCharts.indexOf(name.id) !== -1;
        return (
          <Grid item xs={12} xl={6} key={idx}>
            <Card>
              <ColumnTimeSeries
                categoryField="index"
                data={getData(name.id)}
                units={units}
                scaled={scalable && exposures_scaled}
                start_year={start_year}
                end_year={end_year}
                title={name.name}
                reversed_axis={name.id === "short"}
                appendNet={appendNet}
              />
            </Card>
          </Grid>
        );
      })}
    </>
  );
};

const CategoryChartSet = ({ ts_type, getData, getTitles }) => {
  const { time_series_charts } = exposure_analysis_lists;
  return (
    <React.Fragment>
      {time_series_charts[ts_type].map((chart, idx) => {
        const dates = getTitles(chart.id);
        return (
          <Grid item xs={12} xl={6} key={idx}>
            <Card>
              <CategoricalColumnChart
                data={getData(chart.id)}
                title={`${chart.name} ${dates}`}
                category_field={null}
                stack={false}
                reversed_axis={chart.id === "short"}
              />
            </Card>
          </Grid>
        );
      })}
    </React.Fragment>
  );
};

const RocChartGroup = ({ getData, getTitles }) => {
  const { time_series_charts } = exposure_analysis_lists;

  return (
    <React.Fragment>
      {time_series_charts.roc.map((chart, idx) => {
        const dates = getTitles(chart.id);
        return (
          <Grid item xs={12} xl={6} key={idx}>
            <Card>
              <CategoricalColumnChart
                data={getData(chart.id)}
                title={`${chart.name} ${dates}`}
                category_field={null}
                stack={false}
                reversed_axis={chart.id === "short"}
              />
            </Card>
          </Grid>
        );
      })}
      {time_series_charts.roc_scatter.map((chart, idx) => {
        const dates = getTitles(chart.id);
        return (
          <Grid item xs={12} xl={6} key={idx}>
            <Card>
              <Scatter
                title={`${chart.name} ${dates}`}
                data={getData(chart.id)}
                category_name="sub_classification"
                x_field="exposure"
                y_field="contribution"
                x_field_name="Average Gross Exposure"
                y_field_name="Attribution"
              />
            </Card>
          </Grid>
        );
      })}
    </React.Fragment>
  );
};

const SpecialChart = ({ getData, classification }) => {
  const format = classification === "NUMBER OF POSITIONS" ? "{0:n}" : "{0:p2}";
  const title =
    classification === "CONCENTRATION"
      ? "Concentration, % of NAV"
      : "Number of Positions";
  return (
    <Grid item xs={12} xl={6}>
      <Card>
        <LineTimeSeries
          data={getData()}
          title={title}
          category_field="index"
          format={format}
          standardPalette
        />
      </Card>
    </Grid>
  );
};

const ChartGroup = (props) => {
  const { chart_type, classification } = props.filters;
  if (!chart_type || !classification) {
    return <div />;
  }

  if (specialCharts.indexOf(classification) !== -1) {
    return <SpecialChart {...props} classification={classification} />;
  }

  switch (chart_type) {
    case "Exposures":
      return <TimeSeriesChartSet ts_type="exposure" {...props} />;
    case "Attribution":
      return <TimeSeriesChartSet ts_type="attribution" {...props} />;
    case "Average Exposure":
      return <CategoryChartSet ts_type="exposure" {...props} />;
    case "Total Attribution":
      return <CategoryChartSet ts_type="attribution" {...props} />;
    case "RoC":
      return <RocChartGroup {...props} />;
    default:
      return <div />;
  }
};

const ClassificationCharts = ({ exposures_report }) => {
  const [initialValues, setInitialValues] = useState({
    exposures_scaled: false,
    classification: null,
    chart_type: "Exposures",
    aggregation: "M",
    start_year: null,
    end_year: null,
  });
  const { classifications } = exposures_report || {};

  const yearList = buildYearList(
    extractNormalExposure(exposures_report, initialValues.classification),
  );
  const updateAppBar = (data) => {
    setInitialValues(data);
  };

  const getData = (variable_type) => {
    const { aggregation, chart_type, classification } = initialValues;

    if (specialCharts.indexOf(classification) !== -1) {
      return exposures_report[classification] || [];
    }

    try {
      if (!chart_type || !classification) {
        return [];
      } else if (chart_type === "Exposures" || chart_type === "Attribution") {
        if (!aggregation) {
          return [];
        }
        return exposures_report.normal_exposures[classification][variable_type][
          aggregation
        ];
      } else if (chart_type === "RoC") {
        return exposures_report.roc[classification][variable_type];
      } else if (
        chart_type === "Average Exposure" ||
        chart_type === "Total Attribution"
      ) {
        return exposures_report.since_inception[classification][variable_type];
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  const getTitles = (variable_type) => {
    const { chart_type, classification } = initialValues;
    try {
      if (!chart_type || !classification) {
        return {};
      } else if (chart_type === "RoC") {
        return exposures_report.roc_date[classification];
      } else if (
        chart_type === "Average Exposure" ||
        chart_type === "Total Attribution"
      ) {
        return exposures_report.since_inception_dates[classification][
          variable_type
        ];
      } else {
        return {};
      }
    } catch (e) {
      return {};
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <ControlBar
            initialValues={initialValues}
            handleChange={updateAppBar}
            classifications={classifications}
            yearList={yearList}
          />
        </Card>
      </Grid>
      <ChartGroup
        getData={getData}
        getTitles={getTitles}
        filters={initialValues}
      />
    </>
  );
};

export default function ChartReport({ exposure_analysis }) {
  const { report: exposures_report } = exposure_analysis || {};
  const { top_level, top_level_date } = exposures_report || {};

  return (
    <div className="exposure-chart-report">
      <Grid container spacing={2} justifyContent="center">
        <TopLevelTs top_level={top_level} />
        <TopLevelCategories
          top_level={top_level}
          top_level_date={top_level_date}
        />
        <ClassificationCharts exposures_report={exposures_report} />
      </Grid>
    </div>
  );
}
