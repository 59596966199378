import { Card, CardContent } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import {
    StyledCardTableCell,
    StyledCardTableCellType
} from './PrivateEquityCard/PrivateEquityCard.styled'

const StyledTableRow = styled.tr`
    height: 30px;
`

const StyledTeamTableCell = styled(StyledCardTableCell)`
    padding-left: 3.8rem;
    padding-bottom: 1rem;
`

const TeamTab = (fundData = {}) => {
    const { leadership_team: teamData } = fundData
    const renderTeamTable = (team) => {
        return Object.values(team).map((teamMember) => {
            return (
                <StyledTableRow key={teamMember.id}>
                    <StyledCardTableCellType>{teamMember.name}, {teamMember.job_title}</StyledCardTableCellType>
                    <StyledTeamTableCell>
                        {teamMember.summary}
                    </StyledTeamTableCell>
                </StyledTableRow>
            )
        })
    }
    return (
        <Card>
            <CardContent>
                <table>
                    <tbody>
                        {renderTeamTable(teamData)}
                    </tbody>
                </table>
            </CardContent>
        </Card>
    )

}

export default TeamTab
