import React from 'react'
import {
  Grid as KGrid,
  GridColumn as Column,
} from '@progress/kendo-react-grid'
import router from '../../resources/history'
import { statefulTableGenerator } from '../../components'
import { useSecurities } from '../../hooks'
import { useMixpanel } from '../../hooks/useMixpanel'
import { sortOn } from '../../utils'

const SuperGrid = statefulTableGenerator(KGrid)

export default function FundsOfFunds() {
  const { mpTrack } = useMixpanel()
  const { fof_share_class_list } = useSecurities()

  const getAssetDetails = (e) => {
    const { dataItem } = e
    const { id, name } = dataItem
    mpTrack({
      eventName: 'View Fund Detail',
      properties: {
        From: location.pathname,
        'Link Item': 'Funds of Funds table row',
        Name: name,
        ID: id,
      },
    })
    router.push(`/asset-detail/${id}/`)
  }

  const generateName = (props) => {
    const { dataItem } = props
    return (
      <td
        className="portfolio-name-row"
        onClick={() => getAssetDetails(props)}
      >
        <div className="portfolio-name">{dataItem.name}</div>
      </td>
    )
  }

  let data = fof_share_class_list !== undefined ? fof_share_class_list : []
  if (data.length > 0) {
    const antarcticaCoreFunds = []
    const antarcticaOtherFunds = []
    const antarcticaSortedCoreFunds = []
    const alphabeticalOrder = ['A', 'B', 'C', 'D']
    data.forEach((element) => {
      if (element.name.includes('Antarctica Core Alpha Fund - Cl')) {
        antarcticaCoreFunds.push(element)
      } else antarcticaOtherFunds.push(element)
    })
    alphabeticalOrder.forEach((letter) => {
      antarcticaCoreFunds.forEach((fund) => {
        if (fund.name.endsWith(letter)) {
          antarcticaSortedCoreFunds.push(fund)
        }
      })
    })
    data = antarcticaSortedCoreFunds.concat(antarcticaOtherFunds)
    data = sortOn(data, 'name')
  }

  return (
    <div className="portfolios-container">
      <SuperGrid
        className="portfolios-table"
        data={data}
        filterable={false}
        pageable={false}
        scrollable="scrollable"
        style={{ height: '75vh', marginTop: '20px' }}
      >
        <Column
          cell={generateName}
          title="Name"
          width="400px"
        />
        <Column
          field="calculated_statistics.stats_date"
          format="{0:dd MMM yyyy}"
          title="Date"
          width="200px"
        />
        <Column
          field="calculated_statistics.last_month_ret"
          format="{0:p2}"
          title="MTD"
        />
        <Column
          field="calculated_statistics.ytd"
          format="{0:p2}"
          title="YTD"
        />
        <Column
          field="calculated_statistics.ann_ret"
          format="{0:p2}"
          title="Ann Return"
        />
        <Column
          field="calculated_statistics.ann_vol"
          format="{0:p2}"
          title="Ann Vol"
        />
        <Column
          field="calculated_statistics.sharpe"
          format="{0:n2}"
          title="Sharpe"
        />
      </SuperGrid>
    </div>
  )
}
