/* eslint-disable react/jsx-props-no-spreading */
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { useMixpanel } from '../../hooks/useMixpanel'
import { aamui } from '../../styles/AppTheme'
import useSerialiseTradingCalendarFormData from './hooks/useSerialiseTradingCalendarFormData'
import {
  SubscriptionFrequency,
  TradingCalendarDayType,
  TradingCalendarFormMode,
  TradingCalenderInputFormUIData
} from './models'
import TradingCalendarByCustomDateForm from './TradingCalendarByCustomDateForm'
import TradingCalendarByReportPeriodForm from './TradingCalendarByReportPeriodForm'
import { CountryInvolvedSelect } from './TradingCalendarFormInputs'
import TradingCalendarFundSelectionForm from './TradingCalendarFundSelectionForm'
import { useTradingCalendar } from './TradingCalendarProvider'

const requiredField = <T extends ZodType<any>>(schema: T): T => {
  return schema.refine(value => value !== null && value !== undefined && value !== '', {
    message: 'Required field',
  }) as unknown as T
}


const formModeComponentMap: Record<string, React.ReactElement> = {
  [TradingCalendarFormMode.Regular]: <TradingCalendarByReportPeriodForm />,
  [TradingCalendarFormMode.Custom]: <TradingCalendarByCustomDateForm />,
  [TradingCalendarFormMode.Fund]: <TradingCalendarFundSelectionForm />
}


const currentYear = (new Date()).getFullYear()

const subFreqNumIntervalsMap: Record<SubscriptionFrequency, number> = {
  [SubscriptionFrequency.Monthly]: 12,
  [SubscriptionFrequency.Annually]: 1,
  [SubscriptionFrequency.Quarterly]: 4,
  [SubscriptionFrequency.SemiAnnually]: 2
}


const defaultFormValues: TradingCalenderInputFormUIData = {
  countries: [],
  dayType: TradingCalendarDayType.BUSINESS_DAYS,
  daysBefore: 0,
  fund: '',
  interval: SubscriptionFrequency.Monthly,
  isFirstDate: false,
  mode: TradingCalendarFormMode.Fund,
  numIntervals: subFreqNumIntervalsMap[SubscriptionFrequency.Monthly],
  startDate: undefined,
  year: currentYear
}

const isRegularMode = (data) => data.mode === TradingCalendarFormMode.Regular
const isCustomMode = (data) => data.mode === TradingCalendarFormMode.Custom
const isFundMode = (data) => data.mode === TradingCalendarFormMode.Fund


const schema = z.object({
  countries: requiredField(z.any().array().min(1, 'Please select at least one country')),
  dayType: requiredField(z.enum(Object.values(TradingCalendarDayType))),
  daysBefore: requiredField(z.coerce.number().min(0).max(30, 'Max is 30')),
  fund: z.union([z.record(z.unknown()), z.string()]).optional(),
  interval: requiredField(z.string()),
  isFirstDate: z.boolean().optional(),
  mode: requiredField(z.enum(Object.values(TradingCalendarFormMode))),
  numIntervals: requiredField(z.coerce.number().min(1)),
  startDate: z.union([z.date(), z.string()]).optional(),
  year: z.number().min(2023).max(2033).optional()
}).refine(
  (data) => isRegularMode(data) ? data.isFirstDate !== undefined : true,
  { message: 'Required', path: ['isFirstDate'] }
)
  .refine(
    (data) => isRegularMode(data) ? data.year !== undefined : true,
    { message: 'Required', path: ['year'] }
  )
  .refine(
    (data) => isCustomMode(data) ? !!data.startDate : true,
    { message: 'Required', path: ['startDate'] }
  )
  .refine(
    (data) => isCustomMode(data) ? (new Date(data.startDate))?.getDate() <= 28 : true,
    { message: 'The day must be less than or equal to 28th', path: ['startDate'] }
  )
  .refine(
    (data) => isFundMode(data) ? !!data.fund : true,
    { message: 'Please select a fund', path: ['fund'] }
  )

const TradingCalendarForm: React.FC = () => {
  const { mpTrack } = useMixpanel()
  const { serialiseTradingCalendarFormData } = useSerialiseTradingCalendarFormData()
  const {
    isLoadingTradingCalendarResult,
    setLastFormValues,
    setResultDayType,
    submitTradingCalendarInput
  } = useTradingCalendar()
  const method = useForm<TradingCalenderInputFormUIData>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema),

  })
  const {
    handleSubmit,
    setValue,
    watch
  } = method
  const watchMode = watch('mode')
  const watchDayType = watch('dayType')
  const watchSubscriptionFrequency = watch('interval')

  useEffect(() => {
    setValue('numIntervals', subFreqNumIntervalsMap[watchSubscriptionFrequency])
  }, [watchSubscriptionFrequency])


  const submitFormHandler = async (data: TradingCalenderInputFormUIData) => {
    setLastFormValues({ ...data, fund: data.fund?.name })

    if (watchMode === TradingCalendarFormMode.Fund) {
      data.mode = TradingCalendarFormMode.Regular
      delete data.fund
    }

    const serialisedData = serialiseTradingCalendarFormData(data)
    await submitTradingCalendarInput(serialisedData)
    setResultDayType(watchDayType)
    const el = document.getElementById('result-section')
    if (el) {
      window.scrollTo({
        behavior: 'smooth',
        top: el.offsetTop - 90
      })
    }
    mpTrack({
      eventName: 'Calculate Trading Calendar',
      properties: {
        ...data
      }
    })
  }

  const clickChangeModeHandler = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    setValue('mode', newValue)
  }

  const clickChangeDayTypeHandler = (event: React.MouseEvent<HTMLElement>, newValue: TradingCalendarDayType) => {
    setValue('dayType', newValue)
  }

  return (
    <Box
      bgcolor="white"
      border={`1px solid ${aamui.eggShell}`}
      maxWidth="700px"
      mx="auto"
      p={4}
    >
      <Typography
        align="center"
        component="h1"
        variant="h6"
      >
        Trading Calendar Calculator Input
      </Typography>
      <FormProvider
        {...method}
      >
        <form onSubmit={handleSubmit(submitFormHandler)}>
          <Box
            display="flex"
            flexDirection="column"
            gridGap={16}
            pt={2}
          >
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              gridGap={8}
              justifyContent="center"
            >
              <ToggleButtonGroup
                exclusive
                onChange={clickChangeModeHandler}
                value={watchMode}
              >
                <ToggleButton value={TradingCalendarFormMode.Fund}>By Fund</ToggleButton>
                <ToggleButton value={TradingCalendarFormMode.Regular}>By reporting period</ToggleButton>
                <ToggleButton value={TradingCalendarFormMode.Custom}>By Custom Date</ToggleButton>
              </ToggleButtonGroup>
              <ToggleButtonGroup
                exclusive
                onChange={clickChangeDayTypeHandler}
                value={watchDayType}
              >
                <ToggleButton value={TradingCalendarDayType.BUSINESS_DAYS}>Subscription</ToggleButton>
                <ToggleButton value={TradingCalendarDayType.CALENDAR_DAYS}>Redemption</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <CountryInvolvedSelect />
            {watchMode && formModeComponentMap[watchMode]}

            <Button
              color="primary"
              disabled={isLoadingTradingCalendarResult}
              fullWidth
              type="submit"
              variant="contained"
            >
              Calculate
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}

export default TradingCalendarForm
