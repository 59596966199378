import { createContext, useState } from "react";

export const FactSheetsContext = createContext()

export const FactSheetsProvider = ({
  children
}) => {
  const [selectedBookletId, setSelectedBookletId] = useState()
  const [selectedFunds, setSelectedFunds] = useState({})

  return (
    <FactSheetsContext.Provider
      value={{
        selectedBookletId,
        setSelectedBookletId,
        selectedFunds,
        setSelectedFunds
      }}
    >
      {children}
    </FactSheetsContext.Provider>
  )
}