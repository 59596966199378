import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit"

import { Fund } from "../components/models/IFund"
import { RootState } from "./store"

export interface PeerGroup {
  id: number
  name: string
  visibilityType: 'user-private' | 'company-internal'
  createdAt: Date
  updatedAt: Date
  funds: {
    fundId: number,
    addedAt?: Date
  }[]
  tags: string[]
  user?: number
  company?: string
  canEdit: boolean
  username?: string
}


const allVisibleFields = [
  { label: 'Name', value: 'name' },
  { label: 'Visibility', value: 'visibilityType' },
  { label: 'Created At', value: 'createdAt' },
  { label: 'Updated At', value: 'updatedAt' },
  { label: 'Funds', value: 'funds' },
  { label: 'Tags', value: 'tags' },
  { label: 'Focus', value: 'notes' },
  { label: 'Typical Exposure Ranges', value: 'typicalExposureRanges' },
  { label: 'Analyst', value: 'analyst' },
  { label: 'Management Fee', value: 'managementFee' },
  { label: 'Performance Fee', value: 'performanceFee' },
]

type FilterType = string | number | null
export enum ScreenerView {
  All = 'All',
  Overview = 'Overview',
  Performance = 'Performance',
  KeyStatistics = 'Key Statistics',
  Returns = 'Returns',
  WinLossAnalysis = 'Win/Loss Analysis',
  MonthlyPerformance = 'Monthly Performance',
  AnnualPerformance = 'Annual Performance',
  RiskMetrics = 'Risk Metrics',
  DataQuality = 'Data Quality',
}

interface PeerGroupsState extends EntityState<PeerGroup, number> {
  filter: 'all' | 'strategy' | 'geoFocus'
  createPeerGroupFormOpened: boolean
  addToPeerGroupFormOpened: boolean
  addFundsFormOpened: boolean
  visibleFields: { label: string, value: string }[]
  selectedGroupId?: number | null
  selectedFunds?: number[]
  fundScreenerFilters: {
    ordering: FilterType
    page: FilterType
    pageSize: FilterType
    filterOn: Record<string, string | string[] | null> | null
    peerGroup: string | undefined
  },
  cachedFunds: Record<number, Fund>
  filterOpened: boolean
  fundScreenerRowSelection: Record<number, boolean>
  fundPeerGroupMap: Record<number, PeerGroup[]>
  fundScreenerColVisibility: Record<string, boolean>
  fundScreenerSelectedPeerGroup?: string
  screenerView: ScreenerView
}

export const peerGroupsAdapter = createEntityAdapter<PeerGroup>()


const initialState: PeerGroupsState = {
  ...peerGroupsAdapter.getInitialState(),
  addFundsFormOpened: false,
  addToPeerGroupFormOpened: false,
  cachedFunds: {},
  createPeerGroupFormOpened: false,
  filter: 'all',
  filterOpened: false,
  fundPeerGroupMap: {},
  fundScreenerColVisibility: {},
  fundScreenerFilters: {
    filterOn: {},
    ordering: '-name',
    page: 1,
    pageSize: 20,
    peerGroup: undefined
  },
  fundScreenerRowSelection: {},
  fundScreenerSelectedPeerGroup: undefined,
  selectedFunds: [],
  selectedGroupId: null,
  visibleFields: allVisibleFields,
  screenerView: ScreenerView.Overview
}


export const peerGroupsSlice = createSlice({
  initialState,
  name: 'peerGroups',
  reducers: {
    addFundFormToggled: (state, action: { payload: { open: boolean, selectedGroupId?: number } }) => {
      state.addFundsFormOpened = action.payload.open
      state.selectedGroupId = state.addFundsFormOpened ? action.payload.selectedGroupId : null
    },
    addToPeerGroupFormToggled: (state, action: { payload: { open: boolean, selectedFunds?: number[] } }) => {
      state.addToPeerGroupFormOpened = action.payload.open
      state.selectedFunds = state.addToPeerGroupFormOpened ? action.payload.selectedFunds : []
    },
    clearFundScreenerRowSelection: (state) => {
      state.fundScreenerRowSelection = {}
    },
    filterChanged: (state, action) => {
      state.filter = action.payload
    },
    filterCleared: (state) => {
      state.fundScreenerFilters.filterOn = {}
      state.fundScreenerFilters.page = 1
    },
    filterRemoved: (state, action: { payload: { key: string } }) => {
      if (state.fundScreenerFilters.filterOn) {
        state.fundScreenerFilters.filterOn[action.payload.key] = null
      }
      state.fundScreenerFilters.page = 1
    },
    filteredByPeerGroupChanged: (state, action: PayloadAction<string | undefined>) => {
      state.fundScreenerFilters.peerGroup = action.payload === 'null' ? '' : action.payload
    },
    fundFetched: (state, action) => {
      action.payload.forEach((fund: Fund) => {
        if (!state.cachedFunds[fund.id]) {
          state.cachedFunds[fund.id] = fund
        }
      })
    },
    fundScreenerFiltersChanged: (state, action: { payload: { filterKey: keyof PeerGroupsState['fundScreenerFilters'], value: FilterType } }) => {
      const { filterKey, value } = action.payload

      state.fundScreenerFilters[filterKey] = value as any
    },
    fundScreenerSelectedPeerGroupChanged: (state, action: PayloadAction<string>) => {
      state.fundScreenerSelectedPeerGroup = action.payload
    },
    fundScreenerToggled: (state) => {
      state.filterOpened = !state.filterOpened
    },
    peerGroupDeleted: (state) => {
      state.selectedGroupId = null
    },
    peerGroupDetailDimissed: (state) => {
      state.selectedGroupId = null
    },
    peerGroupFundScreenerColVisibilityToggled: (state, action: PayloadAction<Record<string, boolean> | undefined>) => {
      if (action.payload === undefined) {
        state.fundScreenerColVisibility = {}
      } else {
        state.fundScreenerColVisibility = {
          ...state.fundScreenerColVisibility,
          ...action.payload
        }
      }
    },
    peerGroupsFetched: (state, action) => {
      const peerGroups = action.payload
      peerGroupsAdapter.removeAll(state)
      peerGroupsAdapter.upsertMany(state, peerGroups)

      const fundPeerGroupMap: Record<number, PeerGroup[]> = {}

      peerGroups.forEach((grp: PeerGroup) => {
        grp.funds.forEach(fund => {
          if (!fundPeerGroupMap[fund.fundId]) {
            fundPeerGroupMap[fund.fundId] = []
          }
          fundPeerGroupMap[fund.fundId].push({ ...grp })
        })
      })

      state.fundPeerGroupMap = fundPeerGroupMap
    },
    peerGroupsFormToggled: (state, action: { payload: { open: boolean, selectedFunds?: number[] } }) => {
      state.createPeerGroupFormOpened = action.payload.open
      state.selectedFunds = state.createPeerGroupFormOpened ? action.payload.selectedFunds : []
    },
    rowSelectionChanged: (state, action: { payload: Record<number, boolean> }) => {
      state.fundScreenerRowSelection = {
        ...state.fundScreenerRowSelection,
        ...action.payload
      }
    },
    selectFilterChanged: (state, action: { payload: { key: string, value: string | string[] } }) => {
      if (state.fundScreenerFilters.filterOn) {
        state.fundScreenerFilters.filterOn[action.payload.key] = action.payload.value
      }
      state.fundScreenerFilters.page = 1
    },
    selectedPeerGroupChanged: (state, action) => {
      state.selectedGroupId = action.payload
    },
    screenerViewSelected: (state, action: PayloadAction<{ view: ScreenerView, colVisibility: string[] }>) => {
      const { view, colVisibility } = action.payload
      state.screenerView = view

      state.fundScreenerColVisibility = {
        ...Object.fromEntries(colVisibility.map(item => [item, false]))
      }
    }
  }
})

export const {
  selectAll: selectAllPeerGroups,
  selectById: selecPeerGroupById,
  selectEntities: selecPeerGroupEntities,
  selectIds: selecPeerGroupIds,
  selectTotal: selectTotalPeerGroups,
} = peerGroupsAdapter.getSelectors((state: RootState) => state.peerGroups)

export const {
  addFundFormToggled,
  addToPeerGroupFormToggled,
  clearFundScreenerRowSelection,
  filterChanged,
  filterCleared,
  filterRemoved,
  filteredByPeerGroupChanged,
  fundFetched,
  fundScreenerFiltersChanged,
  fundScreenerSelectedPeerGroupChanged,
  fundScreenerToggled,
  peerGroupDeleted,
  peerGroupDetailDimissed,
  peerGroupFundScreenerColVisibilityToggled,
  peerGroupsFetched,
  peerGroupsFormToggled,
  rowSelectionChanged,
  selectFilterChanged,
  selectedPeerGroupChanged,
  screenerViewSelected
} = peerGroupsSlice.actions


const peerGroupsReducer = peerGroupsSlice.reducer
export default peerGroupsReducer