import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { PerformanceTable } from "../../components";

export default class performanceTable extends Component {
  render() {
    const { internal_analysis, narrow } = this.props;
    const { report } = internal_analysis || {};
    const { table = [] } = report || {};
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12} lg={narrow || 8}>
            <PerformanceTable
              data={table}
              is_aaap={false}
              flat
              index_col="index"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
