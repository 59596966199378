import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { getTotalExpenseRatio } from "../../services";
import { dateTimeManager as dt } from "../../utils";
import moment from "moment";
import { KendoDropDown } from "../../components";
import { SmartGrid } from "../../components";

const toFormat = (rawDate) => ({
  id: dt.jsToDb(rawDate),
  name: dt.dbToMonthYear(rawDate),
});

const generateQuartersToDate = (startDate) => {
  const start = moment(startDate);
  const end = moment();
  let rangeBegin = start.endOf("M").startOf("D");

  let dateRange = [toFormat(rangeBegin)];
  while (rangeBegin < end) {
    rangeBegin = rangeBegin.add(3, "M");
    dateRange.push(toFormat(rangeBegin));
  }
  return dateRange;
};

export default function TotalExpenseRatio() {
  const availableDates = generateQuartersToDate("2020-03-31");
  const [selectedDate, setSelectedDate] = useState(availableDates[0]);
  const [terData, setTerData] = useState([]);

  const fetchTerData = async (end_date) => {
    setTerData([]);
    let start_date = moment(end_date).add(-2, "M");
    start_date = dt.jsToDb(start_date);
    const payload = { start_date, end_date };
    try {
      let response = await getTotalExpenseRatio(payload);
      let { data } = response;
      for (let i = 0; i < data.length; i++) {
        let element = data[i];
        element.value_date = dt.dbToMonthYear(element.value_date);
      }
      const total_ter = data.map((o) => o.ter).reduce((x, y) => x + y);
      const total_ter_ex_mgm = data
        .map((o) => o.ter_ex_management)
        .reduce((x, y) => x + y);
      data.push({
        value_date: "Total",
        ter: total_ter,
        ter_ex_management: total_ter_ex_mgm,
      });
      setTerData(data);
    } catch (error) {}
  };

  useEffect(() => {
    const { id: endDate } = selectedDate;
    fetchTerData(endDate);
  }, [selectedDate]);

  return (
    <div className="ter-route">
      <div className="title">
        <Typography align="center" variant="h6">
          TER Summary
        </Typography>
      </div>
      <div className="selector">
        <KendoDropDown
          items={availableDates}
          value={selectedDate}
          textField="name"
          dataItemKey="id"
          onChange={({ target }) => setSelectedDate(target.value)}
        />
      </div>
      <div className="ter-table">
        <SmartGrid data={terData}>
          <Column field="value_date" title="Date" />
          <Column
            field="general_operating_expense"
            title="General"
            format="{0:c0}"
          />
          <Column field="custody_fee" title="Custody" format="{0:c0}" />
          <Column field="admin_fee" title="Admin" format="{0:c0}" />
          <Column field="management_fee" title="Management" format="{0:c0}" />
          <Column field="aum" title="AUM" format="{0:c0}" />
          <Column field="total_fee" title="Total" format="{0:c0}" />
          <Column
            field="total_fee_ex_management"
            title="Total (Ex Mgmt)"
            format="{0:c0}"
          />
          <Column field="ter" title="TER" format="{0:p4}" />
          <Column
            field="ter_ex_management"
            title="TER (Ex Mgmt)"
            format="{0:p4}"
          />
        </SmartGrid>
      </div>
    </div>
  );
}
