import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import { newImplmentationAnalyticsReportOptions } from '../../constants/analytics-reports.constants'
import Correlation from './correlation'
import Decompositions from './decompositions'
import Downside from './downside'
import FactorsAnalysis from './factorsAnalysis'
import FraudDetectionReport from './FraudDetectionReport'
import PeerReport from './peerReport'
import PerformanceTable from './performance'
import Regression from './regression'
import InputSelector, { newImplementFormRegistry } from './reportSelectors'
import RoC from './roc'
import StatsReport from './statsReports'
import VarReport from './varReport'

const reportTypeComponentMap = {
  [newImplmentationAnalyticsReportOptions.fraudAnalysis]: (
    <FraudDetectionReport />
  ),
}

const WaitingScreen = () => {
  return (
    <div className="loading-progress">
      <Typography variant="h6" align="center">
        Loading hedge fund list
      </Typography>
      <LinearProgress />
    </div>
  )
}

const ReportHeading = ({ loaded, internal_analysis }) => {
  if (!loaded) {
    return <div />
  }
  let heading = ''
  const { report } = internal_analysis || {}
  const { end_month = '', fund_name = '', start_month = '' } = report || {}
  if (fund_name && start_month && end_month) {
    heading = `${fund_name} ${start_month} to ${end_month}`
  }
  return (
    <React.Fragment>
      <Typography variant="h6" align="center">
        {heading}
      </Typography>
    </React.Fragment>
  )
}

const Report = ({ internal_analysis, ...otherProps }) => {
  const { report_type } = internal_analysis
  const allProps = { internal_analysis, ...otherProps }
  switch (report_type) {
    case 'Statistical Analysis':
      return <StatsReport {...allProps} />
    case 'Peer Group Analysis':
      return <PeerReport {...allProps} />
    case 'Correlation Analysis':
      return <Correlation {...allProps} />
    case 'Regression Analysis':
      return <Regression {...allProps} />
    case 'Downside Analysis':
      return <Downside {...allProps} />
    case 'VaR':
      return <VarReport {...allProps} />
    case 'Volatility Decomposition':
      return <Decompositions {...allProps} />
    case 'RoC':
      return <RoC {...allProps} />
    case 'Performance Table':
      return <PerformanceTable {...allProps} />
    case 'Factor Analysis':
      return <FactorsAnalysis {...allProps} />
    case newImplmentationAnalyticsReportOptions.fraudAnalysis:
      ; <FraudDetectionReport />
    default:
      break
  }
}

export default function ReportContainer({
  work_ready,
  loading,
  loaded,
  reportLoaded,
  ...otherProps
}) {
  const {
    analysis_inputs: { report_type },
  } = otherProps
  const isNewImplementationReport = !!newImplementFormRegistry[report_type]
  return (
    <div className="statistics-container">
      {!work_ready && <WaitingScreen />}
      {work_ready && (
        <>
          <InputSelector {...{ ...otherProps, loading }} />
        </>
      )}
      {loading && (
        <div className="loading-progress">
          <LinearProgress />
        </div>
      )}
      <div className="report-heading">
        <ReportHeading loaded={loaded} {...otherProps} />
      </div>
      {loaded && (
        <div className="analysis">
          <Report {...otherProps} />
        </div>
      )}
      {isNewImplementationReport &&
        reportLoaded &&
        reportTypeComponentMap[report_type]}
    </div>
  )
}
