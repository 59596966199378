export const extractSeriesNames = (data) => {
  let series = []
  const non_categories = [
    "perf_date",
    "value_date",
    "val_date",
    "date",
    "_date_",
    "_date",
    "Month",
    "index",
    "_date_value_date",
    "_date_perf_date",
    "_date_Month",
    "_date_index",
    "Metric",
    "security_name",
    "monitor_display_name",
    "id",
    "group",
    "security",
    "original_index",
    "disabledClick"
  ]
  if (data) {
    try {
      let entry = Object.keys(data[0])
      for (let i = 0; i < entry.length; i++) {
        if (non_categories.indexOf(entry[i]) === -1) {
          series.splice(i, 0, entry[i])
        }
      }
    } catch (e) { }
  }
  return series
}

export const seriesRemoveUnsafeSymbols = (data) => {
  let newData = []
  for (let i = 0; i < data.length; i++) {
    const element = data[i]
    let newElement = {}
    for (const key in element) {
      if (element.hasOwnProperty(key)) {
        const value = element[key]
        const newKey = key.replaceAll(".", "")
        newElement[newKey] = value
      }
    }
    newData.push(newElement)
  }
  return newData
}

export const stripDots = (rawData) => {
  let sanitized = []
  for (let i = 0; i < rawData.length; i++) {
    let cleanElement = {}
    const element = rawData[i]
    for (const key in element) {
      cleanElement[key.replaceAll(".", ",")] = element[key]
    }
    sanitized.push(cleanElement)
  }
  return sanitized
}

export const appendTotal = (rawData, validNames, varName) => {
  for (let i = 0; i < rawData.length; i++) {
    let element = rawData[i]
    let total = 0
    for (const key in element) {
      if (validNames.indexOf(key) !== -1) {
        total = total + Number(element[key])
      }
    }
    element[varName] = total
  }
  return rawData
}
