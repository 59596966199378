import React from "react"
import PropTypes from "prop-types"
import { Typography } from "@material-ui/core"
import { colors } from "../../styles"

const styles = {
  error: {
    color: colors.error,
    display: 'block',
    marginLeft: 16
  },
}

function ErrorMessage({ error }) {
  if (!error) return null

  if (typeof error !== 'string') {
    console.warn('error is not a string', error)
    return null
  }

  return (
    <Typography
      style={styles.error}
      variant="caption"
    >
      {error}
    </Typography>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.string,
}

export default ErrorMessage
