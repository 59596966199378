import { Box, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import React from 'react'
import SlideUpDialogTransition from './SlideUpDialogTransition'

const ActionConfirmationDialog = ({
  open,
  setOpen,
  onClickConfirm,
  onClickCancel,
  children,
  confirmButtonText,
  disabled,
  disableConfirm,
  onExited
}) => {
  const closeDialogHandler = () => {
    if (disabled) {
      return
    }

    setOpen(false)
  }

  const clickCancelButtonHandler = async () => {
    if (onClickCancel) {
      await onClickCancel()
    }
    closeDialogHandler()
  }

  const clickConfirmButtonHandler = async () => {
    await onClickConfirm()
    closeDialogHandler()
  }

  return (
    <Dialog
      open={open}
      onClose={closeDialogHandler}
      maxWidth="sm"
      fullWidth
      TransitionComponent={SlideUpDialogTransition}
      TransitionProps={{
        onExited
      }}
    >
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Box pr={2} display="flex" gridGap={12}>
          <Button
            onClick={clickConfirmButtonHandler}
            color="primary"
            variant="contained"
            disabled={disabled || disableConfirm}
          >
            {confirmButtonText || 'Confirm'}
          </Button>
          <Button
            onClick={clickCancelButtonHandler}
            color="primary"
            variant="outlined"
            disabled={disabled}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ActionConfirmationDialog