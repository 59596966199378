import { Button } from '@material-ui/core'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import useCreateHsBlogPost from '../../hooks/useCreateHsBlogPost'
import { BlogType } from '../../utils/fundFaqUtils'


interface FundSummaryInfoEditButtonProps {
  queryKey: readonly [string, string | undefined],
  fund: any
}

const FundSummaryInfoEditButton: React.FC<FundSummaryInfoEditButtonProps> = ({
  queryKey,
  fund
}) => {
  const { createHsBlogPost, isCreatingHsBlogPost } = useCreateHsBlogPost()
  const queryClient = useQueryClient()

  const data: any = queryClient.getQueryData(queryKey)

  const blogPostId = data?.[0]?.id
  const hasCreatedSummary = !!blogPostId

  const handleCreateSummary = async () => {
    await createHsBlogPost({
      baseSlug: BlogType.FUND_SUMMARY,
      contentGroupId: process.env.REACT_APP_HS_FUND_SUMMARY_CONTENT_GROUP_ID,
      postName: `Fund Summary - ${fund.name}`,
      metaDescription: 'Fund Summary',
      slugChildId: fund.id
    })

    queryClient.invalidateQueries(queryKey)
  }

  const editButtonProps = {
    href: `https://app.hubspot.com/blog/${process.env.REACT_APP_REQUEST_ACCESS_HS_PORTALID}/editor/${blogPostId}/content`,
  }

  const createButtonProps = {
    onClick: handleCreateSummary,
    disabled: isCreatingHsBlogPost,
  }

  return (
    <Button
      color="primary"
      target="_blank"
      variant="contained"
      disabled={isCreatingHsBlogPost}
      {...(hasCreatedSummary ? editButtonProps : createButtonProps)}
    >
      {hasCreatedSummary ? 'Edit Summary on HubSpot' : 'Create Summary on HubSpot'}
    </Button>
  )
}

export default FundSummaryInfoEditButton