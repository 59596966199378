import {
  Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography,
} from '@material-ui/core'
import moment from 'moment'
import React, { useContext, useMemo, useState } from 'react'
import RouteLink from '../../components/misc/RouteLink'
import { FactSheetsContext } from '../../contexts/fact-sheets.context'
import useFactSheets from '../../hooks/useFactSheets'

const FactsheetFundTable = ({
  disabled,
}) => {
  const { bookletMap } = useFactSheets()
  const { selectedBookletId, selectedFunds, setSelectedFunds } = useContext(FactSheetsContext)
  const [sortColumn, setSortColumn] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const selectedFactSheets = bookletMap?.[selectedBookletId]
  const sortedFunds = useMemo(() => {
    if (selectedFactSheets?.funds?.length > 0) {
      const sorted = [...selectedFactSheets?.funds]
      sorted.sort((a, b) => {
        if (sortColumn === 'name') {
          return sortDirection === 'asc'
            ? a?.name?.localeCompare(b?.name)
            : b?.name?.localeCompare(a?.name)
        } if (sortColumn === 'added_at') {
          return sortDirection === 'asc'
            ? new Date(a.added_at) - new Date(b.added_at)
            : new Date(b.added_at) - new Date(a.added_at)
        }
        return 0
      })
      return sorted
    }
    return []
  }, [selectedFactSheets, sortColumn, sortDirection, bookletMap])

  const clickFundCheckboxHandler = (fundId) => (event) => {
    setSelectedFunds({
      ...selectedFunds,
      [fundId]: event.target.checked,
    })
  }

  const clickSortLabelHandler = (column) => () => {
    const isAsc = sortColumn === column && sortDirection === 'asc'
    const direction = isAsc ? 'desc' : 'asc'
    setSortColumn(column)
    setSortDirection(direction)
  }

  return (
    <Table bgcolor="white">
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'name'}
              direction={sortDirection}
              onClick={clickSortLabelHandler('name')}
            >
              Fund Name
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'added_at'}
              direction={sortDirection}
              onClick={clickSortLabelHandler('added_at')}
            >
              Added Date
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          sortedFunds?.map((fund, idx) => (
            <TableRow key={idx}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={!!selectedFunds[fund.id]}
                  disabled={disabled}
                  onChange={clickFundCheckboxHandler(fund.id)}
                />
              </TableCell>
              <TableCell>
                <RouteLink
                  disabled={disabled}
                  to={`/asset-detail/${fund.id}`}
                >
                  <Typography
                    color="secondary"
                    variant="body2"
                  >
                    {fund?.name}
                  </Typography>
                  <Typography variant="caption">
                    {fund?.underlying_name}
                  </Typography>
                </RouteLink>
              </TableCell>
              <TableCell>
                {moment(fund.added_at).format('DD MMM YYYY')}
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  )
}

export default FactsheetFundTable
