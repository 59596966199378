import React, { Component } from "react";
import { statefulTableGenerator } from "../../components/tables";
import {
  Grid as KGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { performance_col_list } from "../../data/funds";
import { formatNumber } from "@telerik/kendo-intl";
import { DISABLE_PERFORMANCE_QUALITY_INDICATOR } from "../../constants/env";

const TableGrid = statefulTableGenerator(KGrid);

export class performanceTable extends Component {
  generateMultilineName = (props) => {
    const { dataItem, colSpan, columnIndex, field } = props;
    let { performance, quality = "underlying-adjusted" } =
      dataItem[field] || {};

    if (!DISABLE_PERFORMANCE_QUALITY_INDICATOR) {
      if (quality === null || quality === "") {
        quality = "underlying-adjusted";
      } else {
        quality = quality.toLowerCase();
      }
    } else {
      quality = ''
    }
    let formatted = formatNumber(performance, "p2");
    const asterisk = quality === "estimate" ? "*" : "";
    return (
      <td
        colSpan={colSpan}
        className={quality.toLowerCase()}
        role="gridcell"
        aria-colindex={columnIndex + 1}
        aria-selected={false}
      >
        {formatted}
        {asterisk}
      </td>
    );
  };

  render() {
    const { data, is_aaap, flat, index_col = "year" } = this.props;
    return (
      <React.Fragment>
        <div className="performance-table-container">
          <TableGrid
            className="performance-table"
            rowHeight={30}
            data={data || []}
            filterable={false}
            sortable={false}
            pageable={false}
            scrollable="none"
            rowRender={this.rowRender}
          >
            <Column
              filterable={false}
              title="Year"
              field={index_col}
              width="80px"
            />
            {performance_col_list.map((col, idx) => {
              let field_name = is_aaap || flat ? col : `${col}.performance`;
              const cell = is_aaap ? this.generateMultilineName : null;
              return (
                <Column
                  key={idx}
                  field={field_name}
                  title={col}
                  format="{0:p2}"
                  cell={cell}
                />
              );
            })}
          </TableGrid>
          {is_aaap && !DISABLE_PERFORMANCE_QUALITY_INDICATOR && (
            <div className="performance-legend">
              <span className="underlying-adjusted">Adjusted Underlying</span>
              <span className="actual">Actual</span>
              <span className="estimate">*Estimate</span>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default performanceTable;
