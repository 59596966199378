import React from 'react'
import Selector from './reportInputs'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import RiskExposures from './riskExposureReport'

const WaitingScreen = () => {
  return (
    <div className="loading-progress">
      <Typography align="center">Loading hedge fund list</Typography>
      <LinearProgress />
    </div>
  )
}

const Report = ({ risk_analysis, ...props }) => {
  const { report_type } = risk_analysis
  const allProps = { risk_analysis, ...props }
  switch (report_type) {
    case 'Exposure Report':
      return <RiskExposures {...allProps} />
    case 'Stress Test':
      return <RiskExposures {...allProps} stress />
    default:
      break
  }
}

export default function ReportContainer({
  work_ready,
  loading,
  loaded,
  ...props
}) {
  return (
    <>
      {!work_ready && WaitingScreen()}
      {work_ready && <Selector {...props} />}
      {loading && (
        <div className="loading-progress">
          <LinearProgress />
        </div>
      )}
      {loaded && (
        <div className="analysis">
          <Report {...props} />
        </div>
      )}
    </>
  )
}
