import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid"
import React, { useState } from "react"

import { KendoLoader } from "../../components"
import useAccessRights from "../../hooks/useAccessRights"
import ActionCell from "./actionCell"
import DeleteDialog from "./deleteDialog"
import ToolBar from "./gridToolBar"
import NotifyDialog from "./notifyDialog"

export default function DocumentGrid({
  documents,
  handleDelete,
  handleNotify,
  handlePageChange,
  height,
  isPrivateEquity,
  loading,
  pageSize,
  setFilters,
  showSelectedSecurity,
  skip,
  ...rest
}) {
  const { count: total, results: data = [] } = documents || {}
  const { hasAccessInternalClient } = useAccessRights()
  const [sort, setSort] = useState([])
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [notifyDialog, setNotifyDialog] = useState(false)
  const [item, setItem] = useState(null)

  const handleSort = ({ sort }) => {
    let ordering = sort.map((entry) => {
      let { dir, field } = entry
      if (field === "_as_of_date") {
        field = "as_of_date"
      } else if (field === "_created_at") {
        field = "created_at"
      }
      if (dir === "desc") {
        return `-${field}`
      }
      return field
    })
    setFilters((i) => ({ ...i, ordering }))
    setSort(sort)
  }
  const openDeleteDialog = (dataItem) => {
    setItem(dataItem)
    setDeleteDialog(true)
  }

  const openNotifyDialog = (dataItem) => {
    setItem(dataItem)
    setNotifyDialog(true)
  }

  const closeDialog = () => {
    setItem(null)
    setDeleteDialog(false)
    setNotifyDialog(false)
  }

  return (
    <>
      <Grid
        className="rms-grid"
        data={data}
        onPageChange={handlePageChange}
        onSortChange={handleSort}
        pageSize={pageSize}
        pageable
        skip={skip}
        sort={sort}
        sortable
        style={{ height }}
        total={total}
      >
        {hasAccessInternalClient && <GridToolbar>
          <ToolBar
            {...rest}
            isPrivateEquity={isPrivateEquity}
            showSelectedSecurity={showSelectedSecurity}
          />
        </GridToolbar>}
        <Column
          field="name"
          sortable={false}
          title={"Name"}
        />
        {showSelectedSecurity && (
          <Column
            field="security_name"
            sortable={false}
            title={"Fund"}
          />
        )}
        <Column
          field="_as_of_date"
          format={"{0:dd MMM yy}"}
          title={"Date"}
          width={120}
        />
        <Column
          field="story_type"
          sortable={false}
          title={"Type"}
          width={170}
        />
        <Column
          field="_created_at"
          format={"{0:dd MMM yy}"}
          title={"Created on"}
          width={120}
        />
        {hasAccessInternalClient &&
          <Column
            field="user_name"
            sortable={false}
            title={"Created by"}
          // width={170}
          />}
        <Column
          cell={(props) => (
            <ActionCell
              isPrivateEquity={isPrivateEquity}
              openDeleteDialog={openDeleteDialog}
              openNotifyDialog={openNotifyDialog}
              {...props}
            />
          )}
          field="attachment"
          title="Actions"
        />
      </Grid>
      {loading && <KendoLoader />}
      <DeleteDialog
        handleDelete={() => {
          closeDialog()
          handleDelete(item)
        }}
        item={item}
        onClose={closeDialog}
        open={deleteDialog}
      />
      <NotifyDialog
        handleNotify={() => {
          closeDialog()
          handleNotify(item)
        }}
        onClose={closeDialog}
        open={notifyDialog}
        {...rest}
      />
    </>
  )
}
