import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core'
import React, { useEffect } from 'react'

import useOptimiserConstants from './hooks/useOptimiserConstants'
import useOptimiserTags from './hooks/useOptimiserTags'
import PortOptTagsInput from './PortOptTagsInput'
import { usePortfolioOptimiser } from './RefactoredPortfolioOptimiserProvider'

const PortOptRerunConfirmDialog = () => {
  const {
    openRerunDialog,
    reRunFormData,
    setOpenRerunDialog,
    submitForm,
  } = usePortfolioOptimiser()
  const { visibilityMenuItems } = useOptimiserConstants()
  const {
    addTag,
    inputTags,
    removeTag,
    resetTags,
  } = useOptimiserTags(reRunFormData?.tags)

  const clickCancelButtonHandler = () => {
    setOpenRerunDialog(false)
  }

  useEffect(() => {
    if (reRunFormData) {
      resetTags(reRunFormData.tags)
    }
  }, [reRunFormData])

  const submitFormHandler = (event) => {
    event.preventDefault()

    const name = event.target.name.value?.trim()
    const visibilityType = event.target.visibilityType.value

    setOpenRerunDialog(false)
    submitForm({
      ...reRunFormData,
      name,
      tags: inputTags,
      visibilityType,
    })
  }

  return (
    <Dialog open={openRerunDialog}>
      <DialogContent>
        <form
          id="rerun-form"
          onSubmit={submitFormHandler}
        >
          <Box
            display="flex"
            flexDirection="column"
            gridGap={20}
            width="400px"
          >
            <TextField
              autoComplete="off"
              defaultValue={reRunFormData?.name}
              fullWidth
              label="Name"
              name="name"
              required
            />
            <TextField
              defaultValue={reRunFormData?.visibilityType}
              fullWidth
              label="Visibility Type"
              name="visibilityType"
              select
            >
              {visibilityMenuItems}
            </TextField>
            <PortOptTagsInput
              layout="column"
              onClickAdd={addTag}
              onClickDelete={removeTag}
              tags={inputTags}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={clickCancelButtonHandler}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          form="rerun-form"
          type="submit"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PortOptRerunConfirmDialog
