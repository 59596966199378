import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Chart,
  ChartSeriesDefaults,
  ChartValueAxis,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartAxisDefaults,
} from "@progress/kendo-react-charts";
import { formatNumber } from "@telerik/kendo-intl";
import { filterBy } from "@progress/kendo-data-query";
import { extractSeriesNames, stripDots, appendTotal } from "../../utils";
import palette from "google-palette";
import styled from "styled-components";
import moment from "moment";

const SharedTooltipContainer = styled.div`
  .category-container {
    display: flex;
    justify-content: center;
    span {
      font-weight: bold;
    }
  }
  .legend-row {
    margin: 8px 0;
    }
  }
  .total-row {
    span {
      font-weight: bold;
    }
  }
`;

const SharedTooltip = (props) => {
  const { categoryText, points, totalFormat, showTotal } = props;
  const [month, date, year] = categoryText.split("/");
  const parsedDate = new Date(year, month, date);
  const formattedDate = moment(parsedDate).add(-1, "month").format("MMM YY");
  const filteredPoints = points.filter(
    (point) => point.series.name !== "Total Net"
  );
  const values = filteredPoints.map((o) => o.value);
  const total = values.reduce((a, b) => a + b);
  const totalFormatted = formatNumber(total, totalFormat);
  return (
    <SharedTooltipContainer>
      <div className="category-container">
        <span>{formattedDate}</span>
      </div>
      {points.map((point, idx) => (
        <div className="legend-row" key={idx}>
          <svg height="14" width="20">
            <line
              x1="0"
              y1="10"
              x2="15"
              y2="10"
              style={{ stroke: point.series.color, strokeWidth: 4 }}
            />
          </svg>
          {point.series.name} : {point.formattedValue}
        </div>
      ))}
      {showTotal && (
        <div className="total-row">
          <span>Total: {totalFormatted}</span>
        </div>
      )}
    </SharedTooltipContainer>
  );
};

export default class columnTimeSeries extends Component {
  getFilteredData = () => {
    const {
      data,
      start_year,
      end_year,
      categoryField = "value_date",
    } = this.props;
    let filters = [];
    if (start_year) {
      filters.splice(0, 0, {
        field: categoryField,
        operator: "gte",
        value: new Date(`${start_year}-01-01`),
      });
    }

    if (end_year) {
      filters.splice(0, 0, {
        field: categoryField,
        operator: "lte",
        value: new Date(`${end_year}-12-31`),
      });
    }

    const filter = {
      logic: "and",
      filters: filters,
    };

    return filterBy(this.convertValueDateToDate(data), filter);
  };

  calculateLabelSteps = () => {
    const { units } = this.props;
    const data = this.getFilteredData();
    if (units === "years") {
      return 1;
    } else {
      if (data.length > 12) {
        let potential_step = Math.floor(data.length / 6);
        for (let i = 0; i < 12; i++) {
          if ((potential_step + i) % 6 === 0) {
            return potential_step + i;
          }
        }
      } else if (data.length <= 12) {
        let potential_step = Math.floor(data.length / 12);
        for (let i = 0; i < 6; i++) {
          if ((potential_step + i) % 6 === 0) {
            return potential_step;
          }
        }
      } else {
        return 1;
      }
    }
  };

  convertValueDateToDate = (data) => {
    const { categoryField = "value_date" } = this.props;
    for (let i = 0; i < data.length; i++) {
      const point = data[i];
      point[categoryField] = new Date(point[categoryField]);
      data[i] = point;
    }
    return data;
  };

  determineStackType = () => {
    const { scaled } = this.props;
    return scaled ? { type: "100%" } : true;
  };

  generateChartSeriesFromSeriesList = (series, current_palette, data) => {
    const { categoryField = "value_date" } = this.props;
    const stack = this.determineStackType();
    try {
      return series.map((ser, id) => (
        <ChartSeriesItem
          key={id}
          data={data}
          stack={stack}
          name={ser}
          field={ser}
          color={`#${current_palette[id].replace("#", "")}`}
          categoryField={categoryField}
        />
      ));
    } catch (e) {
      return [];
    }
  };

  removeNonStackedSeries = (series) => {
    const full_attribution = series.indexOf("Long Attribution") >= 0;
    if (series.indexOf("TOTAL") >= 0) {
      series.splice(series.indexOf("TOTAL"), 1);
    }

    if (full_attribution && series.indexOf("Total Attribution") >= 0) {
      series.splice(series.indexOf("Total Attribution"), 1);
    }

    if (series.indexOf("Net") >= 0) {
      series.splice(series.indexOf("Net"), 1);
    }
    if (series.indexOf("Gross") >= 0) {
      series.splice(series.indexOf("Gross"), 1);
    }

    return series;
  };

  getMinMax = () => {
    let { reversed_axis, scaled } = this.props;
    if (scaled && !reversed_axis) {
      return [0, 1];
    } else if (scaled && reversed_axis) return [-1, 0];
    else {
      return [null, null];
    }
  };

  render() {
    const unsafeData = this.getFilteredData();
    let data = stripDots(unsafeData);
    const label_step = this.calculateLabelSteps();
    let {
      render_as,
      title,
      reversed_axis,
      units,
      categoryField = "value_date",
      showTotal = true,
      appendNet = false,
    } = this.props;

    const rendering_mode = render_as ? render_as : "canvas";
    let unit_step = 1;
    if (units === "quarters") {
      units = "months";
      unit_step = 3;
    }
    const minmax = this.getMinMax();

    const original_series = extractSeriesNames(data);
    const crossing = reversed_axis ? 1000 : -1000;
    let series = this.removeNonStackedSeries(original_series);

    let current_palette;
    const ser_length = original_series.length;
    // if (ser_length <= antarctica_palette.length) {
    //   current_palette = antarctica_palette;
    // } else if (ser_length > antarctica_palette.length && ser_length < 65) {
    //   current_palette = palette("mpn65", original_series.length);
    // } else {
    //   current_palette = palette("tol-dv", original_series.length);
    // }
    current_palette =
      original_series.length < 65
        ? palette("mpn65", ser_length)
        : palette("tol-dv", ser_length);
    const chartSeriesList = this.generateChartSeriesFromSeriesList(
      series,
      current_palette,
      data
    );

    if (appendNet) {
      data = appendTotal(data, series, "net");
    }

    if (extractSeriesNames(data).indexOf("Gross") >= 0) {
      chartSeriesList.splice(
        chartSeriesList.length,
        0,
        <ChartSeriesItem
          key={chartSeriesList.length}
          data={data}
          type="line"
          name={"Gross"}
          field={"Gross"}
          color={"#6E7B8B"}
          categoryField={categoryField}
        />
      );
    }
    if (extractSeriesNames(data).indexOf("Net") >= 0) {
      chartSeriesList.splice(
        chartSeriesList.length,
        0,
        <ChartSeriesItem
          key={chartSeriesList.length}
          data={data}
          type="line"
          width={4}
          name={"Net"}
          field={"Net"}
          color={"#7AA9DD"}
          categoryField={categoryField}
        />
      );
    }

    if (extractSeriesNames(data).indexOf("net") >= 0) {
      chartSeriesList.splice(
        chartSeriesList.length,
        0,
        <ChartSeriesItem
          key={chartSeriesList.length}
          data={data}
          type="line"
          width={4}
          name={"Total Net"}
          field={"net"}
          color={"#7AA9DD"}
          categoryField={categoryField}
        />
      );
    }

    return (
      <div className="line-ts-chart-container">
        <div className="title-container">
          <Typography align="center" variant="h6">
            {title}
          </Typography>
        </div>
        <Chart
          renderAs={rendering_mode}
          pannable={false}
          zoomable={false}
          transitions={false}
        >
          <ChartLegend visible="true" position="bottom" />
          <ChartSeriesDefaults markers={{ visible: false }} type="column" />
          <ChartTooltip
            shared={true}
            format={`{0:p2}`}
            visible={true}
            render={(context) => (
              <SharedTooltip
                {...context}
                totalFormat="p2"
                showTotal={showTotal}
              />
            )}
          />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              baseUnitStep={unit_step}
              labels={{ step: label_step }}
              baseUnit={units}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              reverse={reversed_axis}
              majorGridLines={{ step: 1 }}
              labels={{ format: `{0:p2}` }}
              axisCrossingValue={crossing}
              min={minmax[0]}
              max={minmax[1]}
            />
          </ChartValueAxis>
          <ChartSeries>{chartSeriesList}</ChartSeries>
          <ChartAxisDefaults majorGridLines={{ visible: false }} />
        </Chart>
      </div>
    );
  }
}
