import { Box, Typography } from '@material-ui/core'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useContext } from 'react'

import ActionConfirmationDialog from '../../components/misc/ActionConfirmationDialog'
import LoadingSpinner from '../../components/misc/LoadingSpinner'
import { FactSheetsContext } from '../../contexts/fact-sheets.context'
import useDownloadPDF from '../../hooks/useDownloadPDF'
import useFactSheets from '../../hooks/useFactSheets'
import useSnackbar from '../../hooks/useSnackbar'
import useUpdateBooklet from '../../hooks/useUpdateBooklet'
import { delBooklet } from '../../services'
import BookletActions from './BookletActions'
import BookletTable from './BookletTable'
import DownloadDialog from './DownloadBookletDialog'
import FactSheetsUpdateDialog from './FactSheetsUpdateDialog'
import SelectedBooklet from './SelectedBooklet'
import useFactSheetsPage from './useFactSheetsPage'

const defaultSelectFunds = {}

const FactSheetsPage = () => {
  const queryClient = useQueryClient()
  const { openSnackbar } = useSnackbar()
  const { bookletMap, booklets, isLoadingBooklet } = useFactSheets()
  const {
    selectedBookletId,
    selectedFunds,
    setSelectedBookletId,
    setSelectedFunds,
  } = useContext(FactSheetsContext)
  const {
    activeBookletId,
    clearActiveBooklet,
    openConfirmDeleteDialog,
    openDownloadDialog,
    openFactSheetsUpdateDialog,
    selectActiveBooklet,
    setOpenConfirmDeleteDialog,
    setOpenDownloadDialog,
    setOpenFactSheetsUpdateDialog,
  } = useFactSheetsPage()
  const { handleDownload, isDownloadingPdf } = useDownloadPDF()
  const { isUpdatingBooklet, updateFactSheets } = useUpdateBooklet(() => {
    setSelectedFunds(defaultSelectFunds)
    openSnackbar('Funds deleted')
  })
  const { isLoading: isDeletingBookletFund, mutateAsync: deleteBooklet } =
    useMutation(delBooklet, {
      onSuccess: () => {
        setSelectedFunds(defaultSelectFunds)
        queryClient.invalidateQueries({ queryKey: ['booklets'] })
        openSnackbar('Booklet deleted')
      },
    })
  const selectedBooklet = bookletMap?.[selectedBookletId]
  const disableActions =
    isUpdatingBooklet || isDeletingBookletFund || isDownloadingPdf

  const clickBookletTableRowHandler = (list) => () => {
    setSelectedBookletId(list.id)
    setSelectedFunds(defaultSelectFunds)
  }

  const clickDeleteBookletIconButtonHandler = (list) => (event) => {
    event.stopPropagation()
    selectActiveBooklet(list.id)
    setOpenConfirmDeleteDialog(true)
  }

  const openBookletDialoigHandler = (mode) => () => {
    setOpenFactSheetsUpdateDialog(mode)
  }

  const clickGenerateFactSheetButtonHandler = (bookletId) => (event) => {
    event.stopPropagation()
    selectActiveBooklet(bookletId)
    setOpenDownloadDialog(true)
  }

  const clickConfirmDeleteBookletButtonHandler = async () => {
    await deleteBooklet(activeBookletId)
    clearActiveBooklet()
  }

  return (
    <>
      {isLoadingBooklet ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box display="flex" gridGap={32} margin="0 auto" maxWidth="1400px">
            <Box flex="1 1 0">
              <BookletActions
                booklets={booklets}
                disableActions={disableActions}
                openBookletDialoigHandler={openBookletDialoigHandler}
              />
              <BookletTable
                activeBookletId={activeBookletId}
                booklets={booklets}
                clickBookletTableRowHandler={clickBookletTableRowHandler}
                clickDeleteBookletIconButtonHandler={
                  clickDeleteBookletIconButtonHandler
                }
                clickGenerateFactSheetButtonHandler={
                  clickGenerateFactSheetButtonHandler
                }
                disableActions={disableActions}
                isDownloadingPdf={isDownloadingPdf}
                selectedBooklet={selectedBooklet}
              />
            </Box>
            <Box flex="1 1 0">
              <SelectedBooklet
                disableActions={disableActions}
                openBookletDialoigHandler={openBookletDialoigHandler}
                selectedBooklet={selectedBooklet}
                selectedFunds={selectedFunds}
                updateFactSheets={updateFactSheets}
              />
            </Box>
          </Box>
        </>
      )}
      <FactSheetsUpdateDialog
        open={openFactSheetsUpdateDialog}
        selectedBooklet={selectedBooklet}
        setOpen={setOpenFactSheetsUpdateDialog}
      />
      <DownloadDialog
        activeBookletId={activeBookletId}
        bookletMap={bookletMap}
        handleDownload={handleDownload}
        openDownloadDialog={openDownloadDialog}
        setOpenDownloadDialog={setOpenDownloadDialog}
      />
      <ActionConfirmationDialog
        onClickConfirm={clickConfirmDeleteBookletButtonHandler}
        open={openConfirmDeleteDialog}
        setOpen={setOpenConfirmDeleteDialog}
      >
        <Typography>Are you sure you want to remove this booklet?</Typography>
      </ActionConfirmationDialog>
    </>
  )
}

export default FactSheetsPage
