import React from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const MuiAutocomplete = ({
  label,
  defaultValue,
  ...props
}) => (
  <Autocomplete
    {...props}
    disablePortal
    defaultValue={defaultValue}
    renderInput={(params) => <TextField {...params} placeholder={label} />}
  />
)

export default MuiAutocomplete