import React, { useState } from "react";
import RiskReport from "./reportContainer";
import { getRiskReport } from "../../services";
import { dateTimeManager } from "../../utils";
import { useSecurities } from "../../hooks";
import { useMixpanel } from "../../hooks/useMixpanel";
import moment from "moment";

export default function ReportWrapper() {
  const { mpTrack } = useMixpanel()
  const { webfolio_list: webfolioList } = useSecurities();
  const [analysisInputs, setAnalysisInputs] = useState({
    report_type: null,
    portfolio: null,
    portfolio_date: new Date(),
  });
  const [analysis, setAnalysis] = useState({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showInputs, setShowInputs] = useState(true);

  const updateSelector = ({ value, name }) => {
    setLoaded(false);
    setAnalysisInputs((i) => ({ ...i, [name]: value }));
  };

  const onGenerateReport = async () => {
    setLoading(true);
    setShowInputs(false);
    const { portfolio, portfolio_date, report_type } = analysisInputs;
    const { id } = portfolio || {};
    const inputs = {
      portfolio: id,
      report_type,
      portfolio_date: dateTimeManager.jsToDbStart(portfolio_date),
    };
    try {
      const response = await getRiskReport(inputs);
      setAnalysis(response.data);
      setLoaded(true);
      mpTrack({
        eventName: 'Generate Internal Report',
        properties: {
          Type: 'Risk Reports',
          'Report Type': 'Risk Analysis',
          'Portfolio Date': moment(portfolio_date).format('YYYY-MM-DD'),
        }
      })
    } catch (error) {
      setShowInputs(true);
    }
    setLoading(false);
  };

  return (
    <div className="risk-analysis-route">
      <RiskReport
        webfolio_list={webfolioList}
        work_ready={webfolioList.length !== 0}
        risk_analysis_inputs={analysisInputs}
        updateSelector={updateSelector}
        onGenerateReport={onGenerateReport}
        risk_analysis={analysis}
        loading={loading}
        loaded={loaded}
        showInputs={showInputs}
        setShowInputs={setShowInputs}
      />
    </div>
  );
}
