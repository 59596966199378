import LinearProgress from '@material-ui/core/LinearProgress'
import { useQueries } from '@tanstack/react-query'
import mixpanel from 'mixpanel-browser'
import qs from 'qs'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UPDATE_PORTFOLIO_AND_LIST } from '../../constants'
import { ASSET_DETAILS_OPTIMISED_QUERIES_ENABLED } from '../../constants/env'
import { FundsContext } from '../../contexts'
import { useSecurities } from '../../hooks'
import useAccessRights from '../../hooks/useAccessRights'
import {
  removeContactDetails,
  updateContactDetails,
  updateHedgeFund,
} from '../../services'
import { getSecurityDetails } from '../../services/helpers'
import { AssetDetailContainer, BasicDetails, KeyStats } from '../assetDetail'

const cleanedData = (data) =>
  data &&
  Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== null) {
      acc[key] = value
    }
    return acc
  }, {})

const queryConfigs = ASSET_DETAILS_OPTIMISED_QUERIES_ENABLED
  ? [
    {
      key: 'assetWithReturnsTableAndCalculatedStats',
      returnsFields: 'returns_table,calculated_statistics',
    },
    {
      key: 'assetWithReturnsTimeseriese',
      returnsFields: 'returns_timeseries',
    },
    {
      key: 'assetWithSummaryStats',
      returnsFields: 'summary_stats_table',
    },
    {
      key: 'assetWithReturnsWithBenchmarkTimeseriese',
      returnsFields: 'returns_with_benchmark_timeseries',
    },
    {
      key: 'assetWithAll',
      returnsFields: null,
    },
  ]
  : [
    {
      key: 'assetWithAll',
      returnsFields: null,
    },
  ]

const portfoliQueryConfigs = [
  ...queryConfigs,
  ...(ASSET_DETAILS_OPTIMISED_QUERIES_ENABLED
    ? [
      {
        key: 'assetWithPortfolioWeights',
        returnsFields: 'portfolio_weights',
      },
    ]
    : []),
]

const getQueries = ({ securityType }) => {
  switch (securityType) {
    case 'FoF Share Class':
    case 'Portfolio':
    case 'FoF':
      return portfoliQueryConfigs
    default:
      return queryConfigs
  }
}

export default function AssetDetail({
  location,
  match,
  platform_name,
  ...props
}) {
  const history = useHistory()

  const { search } = location
  const { params } = match
  const { fund_id } = params

  const parsed_params = qs.parse(search.replace('?', ''))
  const disableFetch = parsed_params.no_fetch === 'true'
  const portfolio = parsed_params.portfolio === 'true'

  const [isRefreshing, setIsRefreshing] = useState(false)
  const [securityDetails, setSecurityDetails] = useState()

  const fundName = securityDetails?.name

  const { hasAccessInternalClient } = useAccessRights()

  const { search_list_map, grouped_search_list_map } = useSecurities()

  const security = useMemo(
    () => search_list_map[Number(fund_id)],
    [search_list_map, fund_id],
  )

  const securityType = useMemo(() => {
    let type
    if (portfolio) {
      type = 'Portfolio'
    } else {
      type = security?.type
    }
    return type
  }, [fund_id, search_list_map, portfolio])

  const securityList = grouped_search_list_map[securityType]

  const queries = useMemo(() => getQueries({ securityType }, [securityType]))

  const queryResults = useQueries({
    queries: queries?.map(({ key, returnsFields }) => ({
      queryKey: ['asset', securityType, fund_id, key],
      queryFn: async ({ signal }) => {
        try {
          const data = await getSecurityDetails(
            {
              fund_id,
              security_type: securityType,
              params: { 'selected-fields': returnsFields },
              props: { signal },
            },
            securityList,
          )

          // workaround to avoid infinite loop when use effect dependency list includes queryResults
          setSecurityDetails((prev) => ({
            ...prev,
            ...cleanedData(data),
          }))
          return data
        } catch (err) {
          return null;
        }
      },
      staleTime: Infinity,
      enabled:
        !!fund_id &&
        !!securityType &&
        securityList?.length > 0 &&
        !disableFetch,
    })),
  })

  const loading =
    isRefreshing ||
    queryResults?.some(
      (query) => query.isLoading || query.isFetching || query.isRefetching,
    )
  const error = queryResults?.some((query) => query.isError)

  const isCached = queryResults?.every(
    (query) => query.isSuccess && query.isFetched,
  )

  const { dispatch: fundDispatch } = useContext(FundsContext)

  useEffect(() => {
    if (security) {
      setIsRefreshing(true)
      const mergedData = queryResults
        ?.filter((qr) => qr.isSuccess)
        .reduce((acc, { data }) => {
          if (data) {
            return { ...acc, ...cleanedData(data) }
          }
          return acc
        }, {})

      if (security.updated) {
        queryResults.forEach((qr) => {
          qr.refetch()
        })
      }
      // currently support portfolio updates only - see reducers/funds.js
      if (security.updated) {
        fundDispatch({
          payload: { ...security, updated: false },
          type: UPDATE_PORTFOLIO_AND_LIST,
        })
      }

      setSecurityDetails(cleanedData({ ...security, ...mergedData }))
      setIsRefreshing(false)
    }
  }, [fund_id, security, isCached])

  useEffect(() => {
    if (error) {
      history.replace('/404')
      return
    }

    if (securityDetails?.name) {
      const title = `${securityDetails.name} | ${platform_name}`
      window.document.title = title
    }
  }, [securityDetails, error])

  const updateSecurityDetails = ({ name, value }) => {
    setSecurityDetails((i) => ({ ...i, [name]: value }))
  }
  const postSecurityDetails = async (payload) => {
    try {
      await updateHedgeFund(payload)
    } catch (error) { }
  }

  const updateContacts = async (payload) => {
    try {
      const response = await updateContactDetails(payload)
      const { data } = response
      let newContacts = [
        data,
        ...securityDetails.contacts.filter((o) => o.id !== data.id),
      ]
      setSecurityDetails((i) => ({ ...i, contacts: newContacts }))
    } catch (error) { }
  }

  const removeContact = async (payload) => {
    try {
      await removeContactDetails(payload)
      let newContacts = securityDetails.contacts.filter(
        (o) => o.id !== payload.id,
      )
      setSecurityDetails((i) => ({ ...i, contacts: newContacts }))
    } catch (error) { }
  }

  useEffect(() => {
    const { id, name, status, strategy, underlying_name } =
      securityDetails || {}
    if (id && name) {
      mixpanel.time_event(`Time On Fund - ${name}`)
      mixpanel.track(`View Fund - ${name}`, {
        Id: id,
        Name: name,
        ...(status ? { Status: status } : {}),
        ...(strategy ? { Strategy: strategy } : {}),
        ...(underlying_name ? { 'Underlying Name': underlying_name } : {}),
        'View Type': 'Fund Page',
      })
    }
  }, [securityDetails?.id, securityDetails?.name])

  useEffect(() => {
    return () => {
      if (fundName) {
        mixpanel.track(`Time On Fund - ${fundName}`, { Fund: fundName })
      }
    }
  }, [fundName])

  return (
    <div className="asset-detail-page">
      {securityDetails && (
        <div className="page-container">
          <BasicDetails
            {...props}
            loading={loading}
            access_internal={hasAccessInternalClient}
            search_list_map={search_list_map}
            security_details={securityDetails}
            security_type={securityType}
          />
          <KeyStats
            {...props}
            loading={loading}
            access_internal={hasAccessInternalClient}
            security_details={securityDetails}
            security_type={securityType}
          />
          {loading && <LinearProgress />}
          <AssetDetailContainer
            {...props}
            loading={loading}
            access_internal={hasAccessInternalClient}
            changeDetails={updateSecurityDetails}
            queryParams={parsed_params}
            removeContact={removeContact}
            security_details={securityDetails}
            security_type={securityType}
            updateContacts={updateContacts}
            updateHedgeFund={postSecurityDetails}
          />
        </div>
      )}
    </div>
  )
}
