import React from "react";
import { GaReport, GaOverview } from "../userAnalytics";

export default function UserActivity() {
  return (
    <div className="user-activity-route">
      <GaReport>
        <GaOverview />
      </GaReport>
    </div>
  );
}
