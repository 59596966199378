import React, { Component } from "react";
import { LineTimeSeries } from "../../components/charts";
import { dummy_returns_timeseries } from "../../data/trackingError";

export default class totalExpenseChart extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <h1>Total Expense Chart</h1>
          <LineTimeSeries
            data={dummy_returns_timeseries || []}
            title="Total Expense"
            minimum
            format="{0:c}"
            category_field="perf_date"
          />
        </div>
      </React.Fragment>
    );
  }
}
