import { useMutation, useQueryClient } from '@tanstack/react-query'
import { postBooklet } from '../services'
import useFactSheets from './useFactSheets'

const useCreateBooklet = (onSuccess) => {
  const queryClient = useQueryClient()
  const { isLoadingBooklet } = useFactSheets()
  const { mutate: createBooklet, isLoading } = useMutation(
    postBooklet,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['booklets'] })

        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      },
    },
  )

  return { createBooklet, isCreatingBooklet: isLoading || isLoadingBooklet }
}

export default useCreateBooklet
