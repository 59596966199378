import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IS_PROD_ENV } from '../../constants/env'
import {
  HEDGE_FUND_PATH,
  PRIVATE_MARKETS_PATH,
} from '../../constants/routes.constants'
import {
  AAM_HEDGE_FUND_NAME,
  AAM_PRIVATE_MARKET_NAME,
} from '../../constants/service.constants'
import { useProfile } from '../../hooks'
import useAccessRights from '../../hooks/useAccessRights'
import { useMixpanel } from '../../hooks/useMixpanel'
import { useParams, useHistory } from 'react-router-dom'
import useParamsQuery from '../../hooks/useParamsQuery'

const routeTypes = {
  hedgeFund: AAM_HEDGE_FUND_NAME,
  privateMarkets: AAM_PRIVATE_MARKET_NAME,
}

const LandingPane = () => {
  const history = useHistory()
  const params = useParamsQuery()
  const { mpTrack } = useMixpanel()
  const profile = useProfile()
  const { hasPrivateEquityAccess, hasHedgeFundsAccess } = useAccessRights()
  const [requestingRoute, setRequestingRoute] = useState()
  const storedNextLocationUrl = params.get('next')
  const mutation = useMutation(
    (reqBody) => {
      return axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_REQUEST_ACCESS_HS_PORTALID}/${process.env.REACT_APP_REQUEST_ACCESS_HS_FORMID}`,
        reqBody,
      )
    },
    {
      onSuccess: () => {
        mpTrack({
          eventName: `Sent Request Access to ${requestingRoute}`,
          properties: {
            'Click Type': 'Landing Page Route Selection',
          },
        })
      },
    },
  )

  useEffect(() => {
    if (storedNextLocationUrl) {
      history.replace(storedNextLocationUrl)
    }
  }, [storedNextLocationUrl])

  const clickRequestAccessHandler = () => {
    const reqAcsPayload = {
      submittedAt: Date.now(),
      fields: Object.entries({
        firstname: profile?.first_name,
        lastname: profile?.last_name,
        client_email: profile?.email,
        title: profile?.title,
        company: profile?.company?.name,
        phone: profile?.telephone,
        jobtitle: profile?.job_title,
        country: profile?.country,
        request_solution: requestingRoute,
        source: `${IS_PROD_ENV ? 'PRODUCTION' : 'DEVELOPMENT'} - HUB`,
      }).map(([name, value]) => ({
        objectTypeId: '0-1',
        name,
        value,
      })),
    }

    mutation.mutate(reqAcsPayload)
  }

  const clickRouteCardHandler = (type) => () => {
    if (
      (type === routeTypes.privateMarkets && !hasPrivateEquityAccess) ||
      (type === routeTypes.hedgeFund && !hasHedgeFundsAccess)
    ) {
      setRequestingRoute(type)
      mpTrack({
        eventName: `Open Request Access Dialog - ${type}`,
        properties: {
          'Click Type': 'Landing Page Route Selection',
        },
      })
      return
    }

    mpTrack({
      eventName: `Click ${type} Card on Landing Page`,
      properties: {
        'Click Type': 'Landing Page Route Selection',
      },
    })
  }

  const closeReqAcsDialogHandler = () => {
    if (mutation.isLoading) {
      return
    }

    setRequestingRoute(null)
  }

  return (
    <>
      <div className="landing-page-wrapper">
        <Typography variant="h5" className="greeting-msg" component="h1">
          Welcome to the Antarctica Hub, {profile?.first_name}
        </Typography>
        <div className="buttons-wrapper">
          <Link
            className={clsx('link-button', {
              'link-button--disabled': !hasHedgeFundsAccess,
            })}
            to={hasHedgeFundsAccess ? HEDGE_FUND_PATH : '/'}
            onClick={clickRouteCardHandler(routeTypes.hedgeFund)}
          >
            <img
              src={
                hasHedgeFundsAccess
                  ? '/antarctica_logo.svg'
                  : '/antarctica_outline_logo.svg'
              }
              alt="private equity logo"
            />
            <span>{AAM_HEDGE_FUND_NAME}</span>
          </Link>
          <Link
            className={clsx('link-button', {
              'link-button--disabled': !hasPrivateEquityAccess,
            })}
            to={hasPrivateEquityAccess ? PRIVATE_MARKETS_PATH : '/'}
            onClick={clickRouteCardHandler(routeTypes.privateMarkets)}
          >
            <img
              src={
                hasPrivateEquityAccess
                  ? '/antarctica_logo.svg'
                  : '/antarctica_outline_logo.svg'
              }
              alt="private equity logo"
            />
            <span>{AAM_PRIVATE_MARKET_NAME}</span>
          </Link>
        </div>
      </div>
      <Dialog open={!!requestingRoute} onClose={closeReqAcsDialogHandler}>
        <DialogContent>
          {mutation.isSuccess ? (
            <Typography variant="h6">
              Thank you. A member of our team will respond to you shortly.
            </Typography>
          ) : (
            <Typography variant="h6">
              Please request access here and our IR Team will be in touch with
              you shortly.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {mutation.isSuccess ? (
            <Button
              variant="contained"
              color="primary"
              onClick={closeReqAcsDialogHandler}
            >
              <Typography>Close</Typography>
            </Button>
          ) : (
            <>
              <Button
                onClick={closeReqAcsDialogHandler}
                variant="text"
                color="primary"
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={clickRequestAccessHandler}
                disabled={mutation.isLoading}
              >
                <Typography>Request Access</Typography>
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LandingPane
