import { Button, Group, Modal, Select, Stack } from '@mantine/core'
import React, { useMemo } from 'react'

import { IRowSelectionProps } from '../../components/models/IRowSelectionProps'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import useUpdatePeerGroup, { useUpdatePeerGroupNotification } from '../../hooks/useUpdatePeerGroup'
import {
  addToPeerGroupFormToggled,
  selecPeerGroupEntities,
  selectAllPeerGroups
} from '../../redux/peerGroupsSlice'
import FundSelectionInputBox, { useSelectedFunds } from './FundSelectionInputBox'

interface AddToPeerGroupFormProps extends IRowSelectionProps { }

const AddToPeerGroupForm: React.FC<AddToPeerGroupFormProps> = ({
  rowSelection,
  setRowSelection
}) => {
  const dispatch = useAppDispatch()
  const open = useAppSelector(state => state.peerGroups.addToPeerGroupFormOpened)
  const peerGroupEntites = useAppSelector(selecPeerGroupEntities)
  const allPeerGroups = useAppSelector(selectAllPeerGroups)

  const { isLoading, updatePeerGroupInfo } = useUpdatePeerGroup()
  const { showNotification } = useUpdatePeerGroupNotification()
  const selectedFunds = useSelectedFunds(rowSelection, open)

  const [selectedPeerGroup, setSelectedPeerGroup] = React.useState<string | null>(null)

  const groupOptions = useMemo(() => {
    let options = []

    if (open && allPeerGroups?.length > 0) {
      options = allPeerGroups.filter(group => group.canEdit).map(group => ({
        label: group.name,
        value: String(group.id)
      }))
    }
    return options
  }, [allPeerGroups, open])

  const closeModal = () => {
    dispatch(addToPeerGroupFormToggled({ open: false }))
    setSelectedPeerGroup(null)
  }
  const changeSelectedPeerGroup = (value: string | null) => setSelectedPeerGroup(value)

  const clickAddButtonHandler = async () => {
    try {
      const selectedGroup = peerGroupEntites[+selectedPeerGroup]
      const newFunds = Array.from(new Set([...(selectedGroup?.funds || []), ...(selectedFunds || [])?.map(fundId => ({ fundId }))]))

      const updatedGroup = {
        ...selectedGroup,
        funds: newFunds
      }
      await updatePeerGroupInfo(updatedGroup)
      closeModal()
      showNotification({ groupName: selectedGroup.name, message: `${selectedFunds.length} fund(s) has been added to the group.` })
    } catch (err) { }
  }

  return (
    <Modal
      centered
      onClose={closeModal}
      opened={open}
      size="lg"
      title="Add to Peer Group"
    >
      <Stack gap="md">
        <Select
          data={groupOptions}
          onChange={changeSelectedPeerGroup}
          placeholder="Select a Peer Group"
          value={selectedPeerGroup}
        />
        <FundSelectionInputBox
          selectedFunds={selectedFunds}
          setRowSelection={setRowSelection}
        />
      </Stack>
      <Group
        justify="flex-end"
        mt="xl"
      >
        <Button
          onClick={closeModal}
          variant="outline"
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedPeerGroup || selectedFunds.length < 1}
          loading={isLoading}
          onClick={clickAddButtonHandler}
        >
          Add
        </Button>
      </Group>
    </Modal >
  )
}

export default AddToPeerGroupForm
