import React, { Component } from "react";
import PropTypes from "prop-types";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

const page_size = 10;

class VirtualSecuritySelector extends Component {
  state = {
    skip: 0,
    take: page_size,
    filter: {
      logic: "and",
      filters: [],
    },
  };

  onFilterChange = (event) => {
    this.setState({ filter: event.filter, skip: 0, take: page_size });
  };

  pageChange = (event) => {
    const skip = event.page.skip;
    const take = event.page.take;

    this.setState({
      take,
      skip,
    });
  };

  render() {
    const {
      items = [],
      placeholder = "",
      value = "",
      onChange,
      name = "_" + Math.random().toString(36).substr(2, 9),
      multi = false,
    } = this.props;
    const Component = multi ? MultiSelect : ComboBox;
    const { skip, filter, take } = this.state;
    const filtered_data = filterBy(items, filter);
    const total = filtered_data.length;
    return (
      <Component
        className="kendo-virtual-combobox"
        data={filtered_data?.slice(skip, skip + take)}
        placeholder={placeholder}
        onFilterChange={this.onFilterChange}
        filterable={true}
        virtual={{
          total: total,
          pageSize: page_size,
          skip: skip,
        }}
        onPageChange={this.pageChange}
        onChange={onChange}
        popupSettings={{
          height: "250px",
        }}
        style={{ width: "100%" }}
        value={value}
        name={name}
        {...this.props}
      />
    );
  }
}

VirtualSecuritySelector.propTypes = {
  allowCustom: PropTypes.bool,
  items: PropTypes.array,
  disabled: PropTypes.bool,
  text_field: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  // placeholder: PropTypes.string.isRequired,
  // text_field: PropTypes.string,
  // value: PropTypes.one,
};

export default VirtualSecuritySelector;
