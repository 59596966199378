import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const SkeletonTextLoader = ({
  my = 4,
  rows = 3,
  className = '',
  style = {},
}) => (
  <Box my={my} className={className} style={style}>
    <Skeleton variant="text" width="60%" height={30} />
    {rows > 1 && <Skeleton variant="text" width="80%" />}
    {rows > 2 && <Skeleton variant="text" width="75%" />}
  </Box>
)

export default SkeletonTextLoader
