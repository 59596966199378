import React from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import EditForm from "./editForm";
import { UserActivity, GaReport } from "../userAnalytics";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserForm({ selectedUser, onClose, ...props }) {
  const { id, email } = selectedUser || {};
  const title = id ? email : "New User";
  // const title = "New User";
  const open = !!selectedUser;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className="user-form"
    >
      <AppBar className="app-bar">
        <Toolbar className="tool-bar">
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography className="toolbar-element" variant="h6">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container className="user-container">
        <Grid item xs={3}>
          <div>
            <Typography align="center" variant="h6">
              User Data
            </Typography>
            <EditForm selectedUser={selectedUser} {...props} />
            {/* <UserForm {...this.props} /> */}
          </div>
        </Grid>
        <Grid item xs={9}>
          <Typography align="center" variant="h6">
            User Activity
          </Typography>
          <GaReport>
            <UserActivity id={id} />
          </GaReport>
        </Grid>
      </Grid>
    </Dialog>
  );
}
