import {
  Box,
  Button, Dialog, DialogActions, DialogContent, Typography,
} from '@material-ui/core'
import React from 'react'
import PortOptErrorResultPane from './PortOptErrorResultPane'

const PortOptErrorDialog = ({
  open,
  setOpen,
}) => {
  const closeDialogHandler = () => {
    setOpen(false)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
    >
      <DialogContent>
        <Box mb={2}>
          <Typography variant="subtitle1">An unexpected error has occurred. Please copy this message and share it with the IT team. They will investigate the issue and follow up with you promptly.</Typography>
        </Box>
        <PortOptErrorResultPane />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={closeDialogHandler}
          variant="contained"
        >
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PortOptErrorDialog
