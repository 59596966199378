import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch } from "../hooks/useRedux";

export const uiSlice = createSlice({
  initialState: {
    openFundLaunchPopUp: false,
  },
  name: 'ui',
  reducers: {
    fundLaunchPopUpToggled: (state, action: PayloadAction<boolean>) => {
      state.openFundLaunchPopUp = action.payload
    },
  }
})

export const { fundLaunchPopUpToggled } = uiSlice.actions
const uiReducer = uiSlice.reducer

export default uiReducer

