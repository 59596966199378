import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'

import { PRIVATE_EQUITY_FUNDS_FETCHED } from '../constants'
import { fundStatusDisplayOrder } from '../containers/dashboard/PrivateEquityCard/PrivateEquityCard'
import { FundsContext } from '../contexts'
import { getPrivateEquityData } from '../services'
import useAccessRights from './useAccessRights'

const LEADERSHIP = 'leadership'
const SECTORS = 'sectors'
const DOCS = 'docs'
const MEDIA = 'media'
const SHARES = 'shares'

const privateEquitySectionsMap = {
  docs: DOCS,
  leadership: LEADERSHIP,
  media: MEDIA,
  sectors: SECTORS,
  shares: SHARES,
}

const formatPrivateEquityData = (data, type) => {
  const formattedData = {}
  const typeMap = {
    // docs: data.docs,
    leadership: data.leadership_team,
    media: data.media,
    sectors: data.sectors,
    shares: data.share_classes,
  }

  typeMap[type]?.forEach((item) => {
    formattedData[item.id] = {
      ...item,
    }
  })
  return formattedData
}

export const normaliseData = (data) => {
  const normalisedData = {}
  if (data && data.length) {
    data.forEach((item) => {
      const leadershipData = formatPrivateEquityData(
        item,
        privateEquitySectionsMap[LEADERSHIP],
      )
      const shareClassData = formatPrivateEquityData(
        item,
        privateEquitySectionsMap[SHARES],
      )
      const sectorsData = formatPrivateEquityData(
        item,
        privateEquitySectionsMap[SECTORS],
      )
      const mediaData = formatPrivateEquityData(
        item,
        privateEquitySectionsMap[MEDIA],
      )

      normalisedData[item.id] = {
        ...item,
        // docs: documentData,
        leadership_team: leadershipData,
        media: mediaData,
        sectors: sectorsData,
        share_classes: shareClassData,
      }
    })
  }
  return normalisedData
}

export const usePrivateEquityFunds = (isRoot) => {
  const queryClient = useQueryClient()
  const { hasPrivateEquityAccess } = useAccessRights()
  const { dispatch, hasLoadedPeSearchList } = useContext(FundsContext)

  const query = useQuery(
    ['private equity'],
    async () => {
      const res = await getPrivateEquityData()
      const normalisedData = res.data
      const mapped = Object.values(normalisedData)
        ?.sort((a, b) => {
          return (
            fundStatusDisplayOrder.indexOf(a.fund_status) -
              fundStatusDisplayOrder.indexOf(b.fund_status) ||
            a.name.localeCompare(b.name)
          )
        })
        ?.map(({ share_class = {}, ...restProps }) => {
          const { id, ...rest } = share_class || {}
          return { ...restProps, ...rest, share_class }
        })

      return mapped.map((it) => ({
        ...it,
        class_name:
          it.underlying_fund && !it.share_class ? 'APF' : it.class_name,
      }))
    },
    {
      enabled: !!hasPrivateEquityAccess,
      staleTime: Infinity,
      onSuccess: (data) => {
        const geographyOpts = new Set(
          data
            ?.map((it) => it.geography)
            .filter((it) => it !== undefined && it !== null),
        )
        const fundTypeOpts = new Set(
          data
            ?.map((it) => it.fund_type)
            .filter((it) => it !== undefined && it !== null),
        )
        const industryFocusOpts = new Set(
          data
            ?.map((it) => it.industry_focus)
            .filter((it) => it !== undefined && it !== null),
        )
        const shareClassOpts = new Set(
          data
            ?.map((it) => it.class_name)
            .filter((it) => it !== undefined && it !== null),
        )
        queryClient.setQueryData(
          ['private equity', 'filter options', 'geography'],
          Array.from(geographyOpts),
        )
        queryClient.setQueryData(
          ['private equity', 'filter options', 'fundType'],
          Array.from(fundTypeOpts),
        )
        queryClient.setQueryData(
          ['private equity', 'filter options', 'industry focus'],
          Array.from(industryFocusOpts),
        )
        queryClient.setQueryData(
          ['private equity', 'filter options', 'share class'],
          Array.from(shareClassOpts),
        )

        if (!hasLoadedPeSearchList && isRoot) {
          dispatch({ payload: data, type: PRIVATE_EQUITY_FUNDS_FETCHED })
        }
      },
      staleTime: Infinity,
    },
  )
  return query
}
