/* eslint-disable max-len */
import React, { useMemo, useState } from 'react'
import {
  Box, MenuItem, TextField, Typography,
} from '@material-ui/core'
import { useQuery } from '@tanstack/react-query'
import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import ActionConfirmationDialog from '../../components/misc/ActionConfirmationDialog'
import { getAvailableFundsOfBooklet } from '../../services'
import { useMixpanel } from '../../hooks/useMixpanel'
import { useSecurities } from '../../hooks'
import { aamui } from '../../styles/AppTheme'

const DownloadDialog = ({
  openDownloadDialog,
  setOpenDownloadDialog,
  bookletMap,
  activeBookletId,
  handleDownload,
}) => {
  const { mpTrack } = useMixpanel()
  const { aaap_cell_list_map } = useSecurities()
  const [selectedDownloadDate, setSelectedDownloadDate] = useState('')
  const bookletDateOptions = useMemo(() => bookletMap?.[activeBookletId]?.available_dates?.sort((a, b) => new Date(b) - new Date(a)), [bookletMap, activeBookletId])
  const { data: unavailableDownloadFundIds, isFetched, isFetching: isLoadingAvailFunds } = useQuery(
    ['Download Factsheets', activeBookletId, selectedDownloadDate],
    () => getAvailableFundsOfBooklet({ date: selectedDownloadDate, bookletId: activeBookletId }),
    {
      enabled: !!selectedDownloadDate && !!activeBookletId && openDownloadDialog,
      select: (res) => res?.data?.factsheets?.filter(({ available }) => !available)?.map(({ fund_id }) => fund_id),
    },
  )

  const changeDownloadDateHandler = async (event) => {
    setSelectedDownloadDate(event.target.value)
  }

  const onExitedHandler = () => {
    setSelectedDownloadDate('')
  }

  const clickConfirmDownloadButtonHandler = async () => {
    const mpEventName = 'Download Booklet'
    mixpanel.time_event(mpEventName)
    setOpenDownloadDialog(false)
    await handleDownload(`/api/v1/booklets/${activeBookletId}/booklet?as-of-date=${selectedDownloadDate}/`)
    mpTrack({
      eventName: mpEventName,
      properties: {
        'As of Date': selectedDownloadDate,
        'Booklet ID': activeBookletId,
        'Fund IDs': bookletMap[activeBookletId]?.funds.map(({ fund_id }) => fund_id),
        'Fund Names': bookletMap[activeBookletId]?.funds.map(({ fund_id }) => aaap_cell_list_map[fund_id]?.name),
      },
    })
  }

  return (
    <ActionConfirmationDialog
      confirmButtonText={unavailableDownloadFundIds?.length > 0 ? 'Continue to Download' : 'Download'}
      disableConfirm={!isFetched || unavailableDownloadFundIds?.length === bookletMap?.[activeBookletId]?.funds?.length}
      disabled={isLoadingAvailFunds}
      onClickConfirm={clickConfirmDownloadButtonHandler}
      onExited={onExitedHandler}
      open={openDownloadDialog}
      setOpen={setOpenDownloadDialog}
    >
      <Box mb={2}>
        <Typography>
          Download Fact Sheets -
          {' '}
          {bookletMap?.[activeBookletId]?.name}
        </Typography>
      </Box>
      <TextField
        disabled={isLoadingAvailFunds}
        fullWidth
        onChange={changeDownloadDateHandler}
        select
        SelectProps={{
          displayEmpty: true,
        }}
        value={selectedDownloadDate}
      >
        <MenuItem
          disabled
          value=""
        >
          Select a date
        </MenuItem>
        {bookletDateOptions?.map((date) => (
          <MenuItem value={date}>{moment(date).format('MMMM YYYY')}</MenuItem>
        ))}
      </TextField>
      {unavailableDownloadFundIds?.length > 0 && (
        <Box
          bgcolor={aamui.redAccent}
          color="white"
          mt={2}
          p={3}
        >
          <Typography>
            <strong>
              The fact sheets for the following fund(s) are currently not available for the selected date.
            </strong>
          </Typography>
          <ul>
            {unavailableDownloadFundIds?.map((id) => (
              <li key={id}>
                <Typography variant="body2">{aaap_cell_list_map[id]?.name}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </ActionConfirmationDialog>
  )
}

export default DownloadDialog
