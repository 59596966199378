import React, { Component } from "react";
import { PlatformAumContainer } from "./../platformAum";

export default class platformAum extends Component {
  render() {
    return (
      <div className="platform-aum-page">
        <PlatformAumContainer />
      </div>
    );
  }
}
