import { Box } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import React from "react"
import { Link } from "react-router-dom"

import { GlobalSearch as SecuritySelector, KendoFlatSelector as Selector } from "../../components"
import { useSecurities } from "../../hooks"
import useAccessRights from "../../hooks/useAccessRights"
import MuiDatePicker from "../../components/forms/MuiDatePicker"

export default function Toolbar({
  documentTypes,
  filterSecurities,
  filterStoryDate,
  filterStoryType,
  isPrivateEquity,
  security,
  showSelectedSecurity,
  storyDate,
  storyType,
}) {
  const { hasAccessInternalClient } = useAccessRights()
  const { search_list } = useSecurities()
  const { id: security_id } = security || {}
  const { name: story_type_id } = storyType || {}
  const security_param = security_id ? `security=${security_id}&` : ``
  const story_type_param = story_type_id ? `story_type=${story_type_id}&` : ``
  const new_story_link = `/new-story?${security_param}${story_type_param}`
  if (isPrivateEquity) {
    documentTypes = documentTypes.filter((documentType) =>
      documentType.name.includes("APF Private"),
    )
  }

  return (
    <div className="toolbar">
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          alignItems="center"
          display="flex"
        >
          {showSelectedSecurity && (
            <SecuritySelector
              dataItemKey="id"
              items={search_list}
              name="security"
              noLink={true}
              onChange={filterSecurities}
              placeholder="Fund"
              searchFields={[
                "name",
                "isin",
                "underlying_name",
                "principal_name",
              ]}
              textField="name"
              value={security}
            />
          )}
          <Box ml={1}>
            <Selector
              dataItemKey="id"
              items={documentTypes}
              name="story_type"
              onChange={filterStoryType}
              placeholder="Type"
              style={{ width: 250 }}
              textField="name"
              value={storyType}
            />
          </Box>
          <Box
            ml={1}
            mr={1}
            width="100%"
          >
            <MuiDatePicker
              format="dd MMM yyyy"
              name="story_date"
              onChange={(newDate) => filterStoryDate({ target: { value: newDate } })}
              value={storyDate}
              disableToolbar={false}
              style={{ width: '100%', margin: 0 }}
              placeholder="dd MMM yyyy"
            />
          </Box>
        </Box>
        <Box
          alignItems="center"
          display="flex"
        >
          {hasAccessInternalClient && (
            <Button
              className="add-new-button"
              color="primary"
              component={Link}
              to={new_story_link}
              variant="contained"
            >
              Add new
            </Button>
          )}
        </Box>
      </Box>
    </div>
  )
}
