import React, { useEffect, useState } from "react";
import { dateTimeManager as dt, gaParser } from "../../utils";
// import Selectors from "./dateSelectors";
import PageViews from "./pageViewReport";
import { gaUserReport } from "../../services";

export default function UserActivity({ id }) {
  const [pageViewData, setPageViewData] = useState([]);

  const fetchUser = async ({ id, startDate, endDate }) => {
    try {
      const response = await gaUserReport({ id, startDate, endDate });
      const { sessions = [] } = response.result || {};
      const result = gaParser.parseUserSessions(sessions);
      setPageViewData(result);
    } catch (error) {}
  };

  // const selectorValues = {
  //   startDate: dt.defaultAaapDate(),
  //   endDate: dt.today(),
  // };
  // const queryReport = ({ startDate, endDate }) => {
  //   fetchUser({ id, startDate, endDate });
  // };

  useEffect(() => {
    const payload = {
      startDate: dt.jsToDateTimeDb(dt.fiveMonthsAgo()),
      endDate: dt.jsToDateTimeDb(dt.today()),
      id,
    };
    fetchUser(payload);
  }, [id]);
  return (
    <div className="activity-report">
      {/* <Selectors initialValues={selectorValues} queryReport={queryReport} /> */}
      <PageViews views={pageViewData} />
    </div>
  );
}
