import { sortBy } from "lodash"
import { useContext, useEffect } from "react"
import { useDispatch } from "react-redux"

import {
  UPDATE_AAAP_CELL_LIST,
  UPDATE_FOF_SHARE_CLASSES,
  UPDATE_HEDGE_FUND_LIST,
  UPDATE_MARKET_INDEX_LIST,
  UPDATE_PORTFOLIO_LIST,
  UPDATE_WF_PORTFOLIO_LIST,
} from '../constants'
import { FundsContext } from "../contexts"
import { usePrivateEquityFunds } from "../hooks"
import {
  getAaapCells,
  getFofShareClasses,
  getHedgeFundList,
  getMarketIndexList,
  getPortfolioList,
  getWfPortfolioList,
} from '../services'
import { dateTimeManager } from '../utils'
import { useScreener } from "../hooks/useScreener"
import { usePerformanceOverview } from "../hooks/usePerformanceOverview"

const processAaapCells = (payload, key) => {
  let aaap_cells = []
  let aaap_classes = []
  const class_pattern = /Class [A-Z]/

  payload.forEach((cell) => {
    const { classifications, name, underlying_securities = [] } = cell
    underlying_securities.forEach((underlying) => {
      if (underlying.map_type.name === 'AAAP Underlying Fund') {
        cell['underlying_name'] = underlying.original_security.name
      }
    })
    let regex_class
    try {
      regex_class = class_pattern.exec(name)[0]
    } catch (error) {
      return
    }
    cell['class_name'] = regex_class
    aaap_cells.push({ ...cell, ...classifications })
    if (aaap_classes.indexOf(regex_class) === -1) {
      aaap_classes.push(regex_class)
    }
  })

  return {
    aaap_classes,
    [key]: sortBy(aaap_cells, 'name'),
  }
}

const processPayload = (payload, key) => {
  let security_list = payload
  if (!Array.isArray(security_list)) {
    return payload // Return unmodified payload if not an array
  }

  security_list.forEach((security) => {
    let { calculated_statistics } = security || {}
    const { stats_date } = calculated_statistics || {}
    if (calculated_statistics && stats_date) {
      calculated_statistics['stats_date'] = dateTimeManager.dbToJs(stats_date)
    }
  })

  let search_list = []
  const names = {
    aaap_cell_list: 'AAAP Cell',
    fof_share_class_list: 'FoF Share Class',
    hedge_fund_list: 'Fund',
    market_index_list: 'Market Index',
    portfolio_list: 'Portfolio',
    webfolio_list: 'FoF',
  }

  const type = names[key]
  security_list.forEach((element) => {
    const { primary_vendor_name } = element
    const elementType = type === 'Fund' ? primary_vendor_name : type
    search_list.push({ ...element, type: elementType })
  })

  const result = {
    [key]: payload,
    search_list: search_list,
  }
  return result
}

export default function SecurityHolder() {
  const { dispatch: globalDispatch } = useContext(FundsContext)
  usePrivateEquityFunds(true)

  usePerformanceOverview({ asOfYear: 2024, currentView: 'AAAP' })
  usePerformanceOverview({ asOfYear: 2024, currentView: 'NAV' })
  usePerformanceOverview({ asOfYear: 2024, currentView: 'FoF' })

  useScreener({ fund_data_available: false, screenerMode: 'funds' })

  useEffect(() => {
    const getAllSecurities = async () => {
      const tasks = [
        {
          actionType: UPDATE_AAAP_CELL_LIST,
          fetcher: getAaapCells,
          key: 'aaap_cell_list',
          processor: processAaapCells,
        },
        {
          actionType: UPDATE_MARKET_INDEX_LIST,
          fetcher: getMarketIndexList,
          key: 'market_index_list',
          processor: processPayload,
        },
        {
          actionType: UPDATE_HEDGE_FUND_LIST,
          fetcher: getHedgeFundList,
          key: 'hedge_fund_list',
          processor: processPayload,
          sorter: sortBy,
        },
        {
          actionType: UPDATE_PORTFOLIO_LIST,
          fetcher: getPortfolioList,
          key: 'portfolio_list',
          processor: processPayload,
        },
        {
          actionType: UPDATE_WF_PORTFOLIO_LIST,
          fetcher: getWfPortfolioList,
          key: 'webfolio_list',
          processor: processPayload,
        },
        {
          actionType: UPDATE_FOF_SHARE_CLASSES,
          fetcher: getFofShareClasses,
          key: 'fof_share_class_list',
          processor: processPayload,
        },
      ]

      tasks.forEach((task) => {
        task
          .fetcher()
          .then((result) => {
            let data = result.data
            if (task.sorter) {
              data = task.sorter(data, 'name')
            }
            if (task.processor) {
              data = task.processor(data, task.key)
            }
            globalDispatch({
              payload: data,
              type: task.actionType,
            })
          })
          .catch((error) => {
            console.log(`Error fetching ${task.key}:`, error)
          })
      })

      // let search_list = []
      // const keys = [
      //   'market_index_list',
      //   'hedge_fund_list',
      //   'portfolio_list',
      //   'aaap_cell_list',
      //   'webfolio_list',
      //   // 'fof_share_class_list',
      // ]
      // const names = [
      //   'Market Index',
      //   'Fund',
      //   'Portfolio',
      //   'AAAP Cell',
      //   'FoF',
      //   // 'FoF Classes',
      // ]
      // for (let i = 0; i < keys.length; i++) {
      //   const single_list = payload[keys[i]]
      //   for (let j = 0; j < single_list.length; j++) {
      //     const element = single_list[j]
      //     const { primary_vendor_name } = element
      //     const type = names[i] === 'Fund' ? primary_vendor_name : names[i]
      //     search_list.push({ ...element, type })
      //   }
      // }

      // const additionalStrategies = [
      //   'Crypto',
      //   'Buyout',
      //   'Growth Equity',
      //   'Venture',
      //   'Secondary',
      //   'Real Estate',
      //   'Infrastructure',
      //   'Long/Short Credit',
      //   'Private Credit',
      // ] //these are hard coded to allow access to strategies that are not assigned to a fund

      // const strategy_list = [
      //   ...new Set([
      //     ...search_list.map((o) => o.strategy).filter((o) => !!o),
      //     ...additionalStrategies,
      //   ]),
      // ].sort()

      // const output = {
      //   ...payload,
      //   aaap_classes,
      //   search_list,
      //   strategy_list,
      // }
      // dispatch({ payload: output, type: GET_ALL_SECURITIES })
      // console.log('search_list', search_list)
      // reduxDispatch(fetchedAllSecurities(convertSnakeToCamelCase({
      //   aaap: aaap_cell_list.data,
      //   aaapClasses: aaap_classes,
      //   fofShareClasses: fof_share_class_list.data,
      //   hedgeFunds: hedge_fund_list.data,
      //   marketIndices: market_index_list.data,
      //   portfolios: portfolio_list.data,
      //   searchList: search_list,
      //   strategies: strategy_list,
      //   webfolios: webfolio_list.data
      // })))

    }
    getAllSecurities()
  }, [globalDispatch])

  return null
}
