const buildYearList = (current_dataset) => {
  try {
    let start_year = new Date(current_dataset[0].index).getFullYear();
    let end_year = new Date(
      current_dataset[current_dataset.length - 1].index
    ).getFullYear();

    let exposure_available_years = [];
    for (let i = start_year; i <= end_year; i++) {
      exposure_available_years.push(i);
    }
    return exposure_available_years;
  } catch (e) {
    return [];
  }
};

export default buildYearList;
