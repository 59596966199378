enum SecurityType {
  AAAP_CELL = 'AAAP Cell',
  PORTFOLIO = 'Portfolio',
  MARKET_INDEX = 'Market Index',
  INTERNAL_FOF = 'FoF',
  FOF = 'FoF Share Class',
  WEBFOLIO = 'Webfolio',
  PRIVATE_EQUITY = 'Private Equity',
}

const isSecurityType = (
  targetType: SecurityType,
  checkingType: string,
): boolean => {
  return checkingType === targetType
}

export { SecurityType, isSecurityType }
