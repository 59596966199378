import { Checkbox, FormControlLabel, MenuItem, TextField } from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import AutoCompleteSelect from '../../components/forms/AutoCompleteSelect'
import { useConstants } from '../../contexts'

interface DisabledInputProps {
  disabled?: boolean;
}

export const CountryInvolvedSelect = () => {
  const constants = useConstants()
  const { formState: { errors }, register } = useFormContext()
  const autoCompleteProps: any = {
    error: !!errors?.countries?.message,
    filterKey: "label",
    fullWidth: true,
    helperText: errors?.countries?.message,
    inputName: "countries",
    label: "Country",
    multiple: true,
    options: constants?.TRADING_CALENDAR_COUNTRY_INFORMATION || [],
    ...register('countries')
  }

  return (
    <AutoCompleteSelect
      {...autoCompleteProps}
    />
  )
}

export const SubscriptionFrequencySelect: React.FC<DisabledInputProps> = ({
  disabled
}) => {
  const constants = useConstants()
  const { formState: { errors }, register, watch } = useFormContext()
  const watchSubscriptionFrequency = watch('interval')
  return (
    <TextField
      fullWidth
      label="Interval"
      select
      {...register('interval')}
      disabled={disabled}
      error={!!errors?.interval?.message}
      helperText={errors?.interval?.message}
      style={{ textTransform: 'capitalize' }}
      value={watchSubscriptionFrequency}
    >
      {constants?.TRADING_CALENDAR_SUBSCRIPTION_FREQUENCIES?.map((it: string) => (
        <MenuItem
          key={it}
          style={{ textTransform: 'capitalize' }}
          value={it}
        >
          {it}
        </MenuItem>
      ))}
    </TextField>
  )
}

interface DateInputProps {
  isStartDate?: boolean;
}
const currentYear = (new Date()).getFullYear()
export const DateInput: React.FC<DateInputProps & DisabledInputProps> = ({
  disabled,
  isStartDate
}) => {
  const { formState: { errors }, register, setValue, watch } = useFormContext()
  const watchStartDate = watch('startDate')

  const changeDickPickerValueHandler = (event: any) => {
    setValue('startDate', event.target.value)
  }

  return (
    <>
      {
        isStartDate
          ? (
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              error={errors?.startDate?.message}
              fullWidth
              helperText={errors?.startDate?.message}
              id="date"
              label="Start Date"
              onChange={changeDickPickerValueHandler}
              type="date"
              value={watchStartDate}
            />
          )
          :
          (
            <TextField
              fullWidth
              label="Year"
              {...register('year', { setValueAs: (value: any) => Number(value) })}
              defaultValue={currentYear}
              disabled={disabled}
              error={!!errors?.year?.message}
              helperText={errors?.year?.message}
              type="number"
            />
          )
      }
    </>
  )
}

export const DaysBeforeInput: React.FC<DisabledInputProps> = ({ disabled }) => {
  const { formState: { errors }, register } = useFormContext()
  return (
    <TextField
      fullWidth
      label="Days Before"
      {...register('daysBefore')}
      defaultValue={0}
      disabled={disabled}
      error={!!errors?.daysBefore?.message}
      helperText={errors?.daysBefore?.message}
      type="number"
    />
  )
}

export const NumberOfPeriodsInput = () => {
  const { formState: { errors }, register } = useFormContext()
  return (
    <TextField
      fullWidth
      label="Number of Periods"
      {...register('numIntervals')}
      error={!!errors?.numIntervals?.message}
      helperText={errors?.numIntervals?.message}
      type="number"
    />
  )
}

export const IsFirstDateCheckbox = () => {
  const { register, watch } = useFormContext()
  const watchIsFirstDate = watch('isFirstDate')
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...register('isFirstDate')}
          checked={watchIsFirstDate}
          color="primary"
        />
      }
      label="Is First Date"
    />
  )
}