import { Box, Chip, TextField, Typography } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import FieldEditControl from '../../components/forms/FieldEditControl'
import LabelValueDisplay from '../../components/misc/LabelValueDisplay'
import StatusIndicator from '../../components/misc/StatusIndicator'
import useOptimiserConstants from './hooks/useOptimiserConstants'
import useOptimiserTags from './hooks/useOptimiserTags'
import useUpdatePortfolioOptimiserRun from './hooks/useUpdatePortfolioOptimiserRun'
import ChipContainer from './layout/ChipContainer'
import {
  EMPTY_DISPLAY,
  getConvergedIcon,
  RUN_STATUS_COLOR_MAP,
  VISIBILITY_ICON_MAP,
} from './optimiser.constants'
import PortOptErrorDialog from './PortOptErrorDialog'
import PortOptTagsInput from './PortOptTagsInput'
import { usePortfolioOptimiser } from './RefactoredPortfolioOptimiserProvider'

const PortOptRunHeader = () => {
  const { visibilityMenuItems } = useOptimiserConstants()
  const {
    isNewRunPage,
    isUpdatingRun,
    runData,
  } = usePortfolioOptimiser()
  const { updateRun } = useUpdatePortfolioOptimiserRun()
  const {
    canEdit,
    finishedAt,
    input,
    name,
    portfolioOptimiserRunId,
    result,
    startedAt,
    status,
    tags,
    visibilityType
  } = runData || {}
  const defaultName = name || input?.name || ''
  const defaultVisibility = visibilityType
  const defaultTags = tags || []
  const {
    addTag,
    inputTags,
    removeTag,
    resetTags,
  } = useOptimiserTags(defaultTags)
  const [inputName, setInputName] = useState()
  const [inputVisibility, setInputVisibility] = useState()
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const isFailedStatus = status === 'FAILED'

  const changeNameValueInputHandler = (event) => {
    setInputName(event.target.value)
  }

  const changeSelectedVisibilityHandler = (event) => {
    setInputVisibility(event.target.value)
  }

  const clickCancelHandler = () => {
    setInputName(defaultName)
    setInputVisibility(defaultVisibility)
    resetTags()
  }

  useEffect(() => {
    if (runData) {
      setInputName(runData.name || runData.input?.name || '')
      setInputVisibility(runData.visibilityType)
    }
  }, [runData])

  const clickSaveButtonHandler = () => {
    const formattedInputName = inputName.trim()

    updateRun({
      payload: {
        name: formattedInputName,
        tags: inputTags,
        visibility_type: inputVisibility,
      },
      runId: portfolioOptimiserRunId,
    })
  }

  const clickErrorLabelHandler = () => {
    if (isFailedStatus) {
      setOpenErrorDialog(true)
    }
  }

  const formatDate = (date) => moment(date)?.format('DD/MM/YYYY - hh:mm:ss')

  return (
    <>
      {
        isNewRunPage
          ? (<Typography variant="h6">New Run</Typography>)
          : (
            <>
              <FieldEditControl
                disabled={isUpdatingRun}
                displayValue={
                  (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gridGap={8}
                    >
                      <Box
                        alignItems="center"
                        display="flex"
                        gridGap={8}
                      >
                        <Typography variant="h6">
                          {defaultName || 'untitled'}
                        </Typography>
                        {VISIBILITY_ICON_MAP[visibilityType]}
                      </Box>
                      <ChipContainer ml={canEdit ? -4 : 0}>
                        {tags?.map((tag, idx) => (
                          <Chip
                            color="primary"
                            key={idx}
                            label={tag}
                            size="small"
                          />
                        ))}
                      </ChipContainer>
                    </Box>
                  )
                }
                hideEditButton={!canEdit}
                onClickCancel={clickCancelHandler}
                onClickSave={clickSaveButtonHandler}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap={16}
                >
                  <Box
                    display="flex"
                    gridGap={8}
                  >
                    <Box width="300px">
                      <TextField
                        disabled={isUpdatingRun}
                        fullWidth
                        label="Name"
                        onChange={changeNameValueInputHandler}
                        value={inputName}
                      />
                    </Box>
                    <Box width="200px">
                      <TextField
                        fullWidth
                        label="Visibility Type"
                        onChange={changeSelectedVisibilityHandler}
                        select
                        value={inputVisibility}
                      >
                        {
                          visibilityMenuItems
                        }
                      </TextField>
                    </Box>
                  </Box>
                  <PortOptTagsInput
                    layout="column"
                    onClickAdd={addTag}
                    onClickDelete={removeTag}
                    tags={inputTags}
                  />
                </Box>
              </FieldEditControl>
              <Box
                display="flex"
                gridGap={48}
                mt={1}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap={8}
                >
                  <LabelValueDisplay
                    label={
                      <Box
                        display="flex"
                        gridGap={2}
                      >
                        Status
                        {
                          isFailedStatus
                          && <Box fontSize={14}><ErrorOutlineIcon fontSize="inherit" /></Box>
                        }
                      </Box>
                    }
                    onClick={clickErrorLabelHandler}
                    style={{ cursor: isFailedStatus ? 'pointer' : 'auto' }}
                    value={(
                      <Box
                        alignItems="center"
                        display="flex"

                      >
                        <StatusIndicator color={RUN_STATUS_COLOR_MAP[status]} />
                        <Box
                          component="span"
                          lineHeight={isFailedStatus ? '0' : 'auto'}
                        >
                          {status}
                        </Box>
                      </Box>
                    )}
                  />
                  <LabelValueDisplay
                    label="Converged"
                    value={status === 'RUNNING' ? EMPTY_DISPLAY : (getConvergedIcon(result?.converged))}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap={8}
                >
                  <LabelValueDisplay
                    label="Start At"
                    value={formatDate(startedAt)}
                  />
                  <LabelValueDisplay
                    label="Finished At"
                    value={finishedAt ? formatDate(finishedAt) : EMPTY_DISPLAY}
                  />
                </Box>
              </Box>
            </>
          )
      }
      <PortOptErrorDialog
        open={openErrorDialog}
        setOpen={setOpenErrorDialog}
      />
    </>
  )
}

export default PortOptRunHeader
