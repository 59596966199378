import { Tab, Tabs } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import AamServiceSelect from '../../components/misc/AamServiceSelect'
import { TabContentContainer } from '../../components/misc/tab-content-container'
import useAccessRights from '../../hooks/useAccessRights'
import { AaapSelectTab, AnalyticsTab, OperationsTab } from '../dashboard'

const HedgeFundsHomePage = () => {
  const history = useHistory()
  const {
    hasAccessOps,
    hasAccessAnalytics,
    hasHedgeFundsAccess
  } = useAccessRights()
  const [currentView, setCurrentView] = useState('aaap_select')
  const { hash, pathname } = useLocation()
  const hasInternalAcs = hasAccessAnalytics || hasAccessOps

  useEffect(() => {
    if (hash) {
      setCurrentView(hash.slice(1))
    } else {
      setCurrentView('aaap_select')
    }
  }, [hash])

  const changeView = (e, new_view) => {
    history.push(`/hedge-funds/#${new_view}`)
  };

  const renderView = useMemo(() => {
    if (!hasInternalAcs) {
      return (<AaapSelectTab />)
    }

    switch (currentView) {
      case "aaap_select":
        return (
          <TabContentContainer tabName="AAAP Select" pageName="Home Page">
            <AaapSelectTab />
          </TabContentContainer>
        )
      case "analytics":
        return (
          <TabContentContainer tabName="Analytics" pageName="Home Page">
            <AnalyticsTab />
          </TabContentContainer>
        )
      case "operations":
        return (
          <TabContentContainer tabName="Operations" pageName="Home Page">
            <OperationsTab />
          </TabContentContainer>
        )
      default:
        return null;
    }
  }, [currentView, hasInternalAcs])

  return (
    <>
      <AamServiceSelect />
      {
        hasInternalAcs && (
          <div className="dashboard-tabs">
            <Tabs
              indicatorColor="primary"
              variant="fullWidth"
              value={currentView}
              onChange={changeView}
            >
              {
                hasHedgeFundsAccess && <Tab label="AAAP Select" value="aaap_select" />
              }
              {
                hasAccessAnalytics && <Tab label="Analytics" value="analytics" />
              }
              {
                hasAccessOps && <Tab label="Operations" value="operations" />
              }
            </Tabs>
          </div>
        )
      }
      {renderView}
    </>
  )
}

export default HedgeFundsHomePage