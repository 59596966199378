import React from 'react'

import TradingCalendarForm from './TradingCalendarForm'
import TradingCalendarProvider from './TradingCalendarProvider'
import TradingCalendarResult from './TradingCalendarResult'

const TradingCalendarPage: React.FC = () => (
  <TradingCalendarProvider>
    <TradingCalendarForm />
    <TradingCalendarResult />
  </TradingCalendarProvider>
)

export default TradingCalendarPage
