import { emailNDARequest } from "../../services";

// TODO: This is temporary hot fix to notify exceptions related to AAAP documents - must be refactored

export const notifyExceptionRaised = ({ error, context, to }) => {
  emailNDARequest({
    data: {
      ...context,
      meta: `Exception raised while user trying to download a documnet from AAAP. Exception stack tract: [${error}]`,
    },
    id: context.id,
    is_nda: "error",
    mailing_list: to || "tech@antarcticaam.com",
  });
};
