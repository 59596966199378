/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import { TradingCalendarNoticeDate, TradingCalendarSkippedDateReason } from './models'
import {
  Box,
  Collapse,
  TableCell,
  TableRow,
  Tooltip
} from '@material-ui/core'
import moment from 'moment'
import StatusIndicator from '../../components/misc/StatusIndicator'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import clsx from 'clsx'

interface TradingCalendarTableRowProps {
  data: TradingCalendarNoticeDate;
  index: number;
  open: boolean;
  toggleOpen: (index: number) => void;
  countryCodeNameMap: Record<string, string>;
}

const TradingCalendarTableRow: React.FC<TradingCalendarTableRowProps> = React.memo(({
  data: {
    noticeDate,
    reasons,
    targetDate
  },
  index,
  open,
  toggleOpen,
  countryCodeNameMap
}) => {
  const aggregatedSkippedDates = useMemo(() => {
    const aggregatedData: Record<string, TradingCalendarSkippedDateReason[]> = {}

    reasons.forEach((item: TradingCalendarSkippedDateReason) => {
      const formattedDate = new Date(item.date).toDateString()

      if (!aggregatedData[formattedDate]) {
        aggregatedData[formattedDate] = []
      }

      aggregatedData[formattedDate].push(item)
    })

    return Object.entries(aggregatedData)
      .map(([holidayDate, holidayReasons]) => ({ holidayDate, holidayReasons }))
      .sort((a, b) => new Date(b.holidayDate).getTime() - new Date(a.holidayDate).getTime())
  }, [reasons])
  const hasReasons = reasons?.length > 0

  const clickRowHandler = () => {
    if (!hasReasons) return

    toggleOpen(index)
  }

  return (
    <TableRow
      onClick={clickRowHandler}
      style={{ cursor: hasReasons ? 'pointer' : 'auto' }}
    >
      <TableCell>{moment(targetDate).format('DD/MM/YYYY')}</TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <StatusIndicator color={hasReasons ? '#f5a623' : '#50e3c2'} />
          {moment(noticeDate).format('DD/MM/YYYY')}
        </Box>
      </TableCell>
      <TableCell style={{ width: '60%' }}>
        {
          hasReasons && (
            <>
              <Box
                display="flex"
                justifyContent="flex-end"
              >
                {
                  (open) ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
              </Box>

              <Collapse
                in={open}
                unmountOnExit
              >
                <Box
                  className="trading-calendar-reasons-table"
                  component="table"
                  width="100%"
                >
                  <tbody>
                    <Box component="tr">
                      <Box component="th">Date</Box>
                      <Box component="th">Holiday</Box>
                      <Box
                        component="th"
                        width="22%"
                      >Country</Box>
                    </Box>
                    {
                      hasReasons
                        ? aggregatedSkippedDates?.map(({ holidayDate, holidayReasons }, holidayIdx) => (
                          <Box
                            className={clsx({ 'multiple-reasons-date-row': holidayReasons.length > 1 })}
                            component="tr"
                            key={holidayIdx}
                          >
                            <Box
                              component="td"
                              width="30%"
                            >
                              {moment(holidayDate).format('DD/MM/YYYY')} {holidayReasons.length > 1 && <strong>(2)</strong>}
                            </Box>
                            <td
                              colSpan="2"
                            >
                              {
                                holidayReasons.map(({
                                  holidayName,
                                  holidayDescription,
                                  country
                                }, reasonIdx) => (
                                  <Box
                                    alignItems="flex-start"
                                    display="flex"
                                    gridGap={8}
                                    key={reasonIdx}
                                    pr={2}
                                  >
                                    <Box
                                      alignItems="center"
                                      display="flex"
                                      gridGap={8}
                                      justifyContent="space-between"
                                      width="70%"
                                    >
                                      {holidayName}
                                      {holidayDescription ? <Tooltip title={holidayDescription}><InfoRoundedIcon
                                        color="secondary"
                                        fontSize="small"
                                      /></Tooltip> : null}
                                    </Box>
                                    <Box
                                      pl={1.5}
                                      width="30%"
                                      style={{ textTransform: 'capitalize' }}
                                    >
                                      {countryCodeNameMap[country] || country}
                                    </Box>
                                  </Box>
                                ))
                              }
                            </td>
                          </Box>
                        ))
                        : null
                    }
                  </tbody>
                </Box>
              </Collapse>
            </>
          )
        }
      </TableCell>
    </TableRow>
  )
})

export default TradingCalendarTableRow
