import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItem, TextField } from '@material-ui/core';
import { useField } from 'formik';
import ErrorMessage from './ErrorMessage';

const NewSelect = ({ name, items, ...props }) => {
  const [field, meta] = useField(name)

  return (
    <>
      <TextField
        variant="outlined"
        SelectProps={{
          displayEmpty: true,
          IconComponent: ExpandMoreIcon
        }}
        select
        {...field}
      >
        <MenuItem value="" disabled>{props.placeholder}</MenuItem>
        {
          items && items.map((it) => (
            <MenuItem key={it} value={it}>{it}</MenuItem>
          ))
        }
      </TextField>
      {meta.touched && meta.error && <ErrorMessage error={meta.error} />}
    </>
  )
}

export default NewSelect
