import { ActionIcon, Button, Checkbox, Flex, Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Table as TanstackTable } from "@tanstack/table-core"
import React from 'react'

import camelCaseToTitleCase from '../../utils/camelCaseToTitleCaase'
import { IconColumns, IconTableOptions } from '@tabler/icons-react'

interface DataTableColumnsSelectorProps<T> {
  table: TanstackTable<T>
  type?: 'icon' | 'button'
}


function DataTableColumnsSelector<T>({
  table,
  type = 'button'
}: DataTableColumnsSelectorProps<T>) {
  const [opened, { close, open }] = useDisclosure()
  return (
    <>
      {
        type === 'icon' && (
          <ActionIcon
            onClick={open}
            variant="transparent"
          >
            <IconTableOptions stroke={1.5} />
          </ActionIcon>
        )
      }
      {
        type === 'button' && (
          <Button onClick={open}>
            Edit Columns
          </Button>
        )
      }
      <Modal
        centered
        onClose={close}
        opened={opened}
        size="xl"
        title="Edit Columns"
      >
        <Flex
          columnGap="md"
          direction="column"
          mah="500px"
          rowGap="sm"
          wrap="wrap"
        >
          {
            table?.getAllLeafColumns()?.map(column => (
              <Checkbox
                checked={column.getIsVisible()}
                key={column.id}
                label={typeof column.columnDef?.header !== 'string' ? camelCaseToTitleCase(column.id) : (column.columnDef?.header || camelCaseToTitleCase(column.id))}
                onChange={column.getToggleVisibilityHandler()}
              />
            ))
          }
          <Button onClick={() => table.resetColumnVisibility()}>SELECT All</Button>
          <Button onClick={() => table.toggleAllColumnsVisible(false)}>DESELECT All</Button>
        </Flex>
      </Modal>
    </>
  )
}

export default DataTableColumnsSelector