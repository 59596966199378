import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

import Skeleton from '@material-ui/lab/Skeleton'
import SkelatonTextLoader from '../../components/skeletons/SkelatonTextLoader'
import { aamui } from '../../styles/AppTheme'
import { fundStatusMap } from '../dashboard/PrivateEquityCard/PrivateEquityCard'

interface PrivateMarketFundPreviewCardProps {
  name: string
  target_sector: string
  geography: string
  logo: string
  id: string
  fund_type: string
  target_size: string
  nameLinkMap: Record<string, string>
  fund_status: string
  isLoading: boolean
}

const PrivateMarketFundPreviewCard: React.FC<PrivateMarketFundPreviewCardProps> =
  ({ fund_status, fund_type, geography, id, logo, name, isLoading }) => {
    return (
      <Box
        bgcolor={aamui.sapphire}
        component={isLoading ? null : Link}
        display="flex"
        height="150px"
        padding={1.5}
        style={{ textDecoration: 'none' }}
        to={`/private-markets/funds/${id}`}
      >
        {isLoading ? (
          <Box
            style={{
              height: 125,
              minWidth: 125,
              objectFit: 'contain',
              width: 125,
            }}
          >
            <Skeleton
              variant="rect"
              width="100%"
              height={125}
              style={{ background: 'white' }}
            />
          </Box>
        ) : (
          <Box
            alt="logo"
            bgcolor="white"
            component="img"
            height="125px"
            src={logo}
            style={{
              height: 125,
              minWidth: 125,
              objectFit: 'contain',
              width: 125,
            }}
            width="125px"
          />
        )}
        <Box
          color="white"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          ml={2}
          py={1}
          width="100%"
        >
          <Typography>
            {isLoading ? (
              <SkelatonTextLoader rows={1} my={0} />
            ) : (
              <strong>{name}</strong>
            )}
          </Typography>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            {isLoading ? (
              <SkelatonTextLoader rows={2} my={0} style={{ width: '60%' }} />
            ) : (
              <>
                <Box display="flex" flexDirection="column" gridGap={4}>
                  <Typography>{fund_type}</Typography>
                  <Typography>{geography}</Typography>
                </Box>
              </>
            )}
            {isLoading ? (
              <Skeleton
                variant="rect"
                width={120}
                style={{ background: 'white' }}
                height={40}
              />
            ) : (
              <Box
                alignItems="center"
                bgcolor="white"
                color={aamui.sapphire}
                display="flex"
                height="40px"
                justifyContent="center"
                width="120px"
              >
                <strong>{fundStatusMap[fund_status]}</strong>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

export default PrivateMarketFundPreviewCard
