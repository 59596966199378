import { Box } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import MuiAutocomplete from '../../components/forms/MuiAutocomplete'
import LoadingSpinner from '../../components/misc/LoadingSpinner'
import { usePrivateEquityFunds } from '../../hooks'
import useParamsQuery from '../../hooks/useParamsQuery'
import removeNullProperties from '../../utils/removeNullProperties'
import PeFundsFilterToggle from './PeFundsFilterToggle'
import PrivateEquityCard from './PrivateEquityCard/PrivateEquityCard'

const AntarcticaPrivate = () => {
  const queryParams = useParamsQuery()
  const history = useHistory()
  const client = useQueryClient()
  const { data, isLoading } = usePrivateEquityFunds()
  const geographyOpts = client.getQueryData(['private equity', 'filter options', 'geography'])
  const fundTypeOpts = client.getQueryData(['private equity', 'filter options', 'fundType'])
  const industryFocusOpts = client.getQueryData(['private equity', 'filter options', 'industry focus'])
  const shareClassOpts = client.getQueryData(['private equity', 'filter options', 'share class'])
  const selectFilters = useMemo(() => {
    const options = [
      {
        label: 'Fund Type',
        options: fundTypeOpts,
        property: 'fund_type',
      },
      {
        label: 'Geography',
        options: geographyOpts,
        property: 'geography',
      },
      {
        label: 'Industry Focus',
        options: industryFocusOpts,
        property: 'industry_focus',
      },
    ]

    if (shareClassOpts?.length > 0) {
      options.push({
        label: 'Share Class',
        options: shareClassOpts,
        property: 'class_name',
      })
    }

    return options
  }, [shareClassOpts, fundTypeOpts, geographyOpts, industryFocusOpts])

  const [filter, setFilter] = useState({
    class_name: undefined,
    fund_status: '',
    fund_type: null,
    geography: null,
    industry_focus: null,
    name: ''
  })

  const filteredData = useMemo(() => {
    if (!Object.values(filter).find((it) => it !== null && it !== '')) {
      return data
    }

    const {
      name,
      ...selectFilters
    } = filter

    return data?.filter((it) => {
      for (const key in selectFilters) {
        if (selectFilters[key] !== null && selectFilters[key] !== '' && it[key] !== selectFilters[key]) {
          return false
        }
      }

      if (filter.name !== '' && !it.name.toLowerCase().includes(name)) {
        return false
      }

      return true
    })?.sort((a, b) => b.id - a.id)
  }, [data, filter])

  useEffect(() => {
    const newFilter = {
      ...filter,
      ...Object.fromEntries(queryParams),
    }

    if (shareClassOpts?.length > 0 && newFilter['class_name'] === undefined) {
      newFilter['class_name'] = shareClassOpts.includes('APF') ? 'APF' : shareClassOpts[0]
    }

    setFilter(newFilter)
  }, [queryParams, shareClassOpts])

  const changeFilterHandler = (property) => (value) => {
    history.push({
      hash: '#antarctica_private',
      pathname: '/private-markets/funds',
      search: `?${(new URLSearchParams(removeNullProperties({
        ...filter,
        [property]: value || '',
      }))).toString()}`,
    })
  }

  const changeSelectFilterHandler = (property) => (event, value) => changeFilterHandler(property)(value)
  const debounced = useDebouncedCallback(
    (value) => {
      setFilter(prev => ({
        ...prev,
        name: value
      }))
    },
    400,
  )

  const changeNameFilterHandler = (event) => {
    debounced(event.target.value)
  }

  return (
    <>
      <Box
        display="flex"
        gridGap={16}
        maxWidth="1350px"
        mb={2}
        width="100%"
      >
        <PeFundsFilterToggle
          disabledAllOption={filter.fund_status === ''}
          onChange={changeFilterHandler('fund_status')}
          value={filter.fund_status}
        />
        <Box width="100%">
          <TextField
            fullWidth
            onChange={changeNameFilterHandler}
            placeholder="Name"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        gridGap={8}
        mb={4}
      >
        {
          !isLoading && selectFilters?.map(({ label, options, property }) => (
            <Box
              key={property}
              width="240px"
            >
              <MuiAutocomplete
                getOptionLabel={(option) => option}
                label={label}
                onChange={changeSelectFilterHandler(property)}
                options={options}
                value={filter[property] || null}
              />
            </Box>
          ))
        }

      </Box>
      <Box
        alignItems="stretch"
        display="flex"
        flexWrap="wrap"
      >
        {
          filteredData?.map((fund) => (
            <PrivateEquityCard
              fund={fund}
              key={fund.id}
            />
          ))
        }
        {isLoading && <LoadingSpinner />}
      </Box>
    </>
  )
}

export default AntarcticaPrivate
