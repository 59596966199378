import { Checkbox } from "@mantine/core"
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  Typography
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import ReactIsCapsLockActive from "@matsun/reactiscapslockactive"
import { IconEye, IconEyeOff, IconMail } from "@tabler/icons-react"
import { Formik } from "formik"
import React, { useContext, useState } from "react"
import * as Yup from "yup"

import { TextInput as Input } from "../../components/forms"
import Error from "../../components/forms/ErrorMessage"
import { AuthContext } from "../../contexts"
import { getMe, login } from "../../services"
import LocalStorageService from "../../utils/localStorageService"
import { passwordValidation } from "./../../components/forms/validation"
import ErrorSnackbar from "./ErrorSnackbar"
import {
  CheckboxLabel,
  Header,
  Link,
  LoginButtonsWrapper,
  NewSubmitButton,
  RequestAccessTextWrapper,
  StyledForm
} from "./styles"

const validationSchema = Yup.object().shape({
  email: Yup
    .string()
    .required('Please enter your email address')
    .email('Please enter a valid email address')
    .label("Email"),
  password: passwordValidation,
})

export default function LoginForm({ setView }) {
  const localStorageService = LocalStorageService.getService()
  const {
    authenticating,
    setAuthenticating,
    setIsAuthorized,
    setIsLoggedIn,
    setProfile,
  } = useContext(AuthContext)
  const [showPassword, setShowPassword] = useState(false)
  const [openErrSnackbar, setOpenErrSnackbar] = useState(false)
  const [agreeTnc, setAgreeTnc] = useState(false)

  const clickUserAgreementCheckboxHandler = (event) => {
    setAgreeTnc(event.target.checked)
  }

  const authroize = async (data, { setErrors }) => {
    localStorageService.clearToken()
    setAuthenticating(true)
    const { email, password } = data || {}
    try {
      const response = await login({ email: email?.toLowerCase(), password })
      const { access, refresh, token } = response.data || {}
      /* NOTE: Retain backwards compatibility with old single sliding token auth until backend is ready */
      localStorage.setItem("accessToken", access || token)
      localStorage.setItem("refreshToken", refresh || token)
    } catch (error) {
      setOpenErrSnackbar(true)
      localStorageService.clearToken()
      setAuthenticating(false)
      return
    }
    try {
      const meResponse = await getMe()
      setIsLoggedIn(true)
      setIsAuthorized(true)
      setProfile(meResponse.data)
      setAuthenticating(false)
    } catch (error) {
      setIsLoggedIn(true)
      setAuthenticating(false)
    }
  }

  const clickRequestAccessHandler = () => {
    window.location.href = process.env.REACT_APP_ANTARCTICAAM_WEBSITE_URL + '/request-access'
  }

  return (
    <Formik
      initialValues={{
        disaclaimer: false,
        email: "",
        password: "",
      }}
      onSubmit={authroize}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <StyledForm>
          <ErrorSnackbar
            open={openErrSnackbar}
            setOpen={setOpenErrSnackbar}
          >
            <Typography component="p">
              These credentials do not match our records. If you are a new user, please <a href={`${process.env.REACT_APP_ANTARCTICAAM_WEBSITE_URL}/request-access`}>request access</a> to proceed
            </Typography>
          </ErrorSnackbar>
          <Header>
            Access the
            <br />
            <strong>Antarctica Hub</strong>
          </Header>
          <FormControl>
            <Input
              disableUnderline
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    className="login-icon-button"
                    disabled
                  >
                    <IconMail />
                  </IconButton>
                </InputAdornment>
              }
              isNew
              name="email"
              placeholder="Email"   // Placeholder text
              required
              style={{ textTransform: "lowercase" }}
              value={values}
              onChange={(e) => {
                const lowercaseEmail = e.target.value.toLowerCase()
                console.log('lowercaseEmail', lowercaseEmail)
                setFieldValue('email', lowercaseEmail)
              }}
            />
          </FormControl>
          <ReactIsCapsLockActive>
            {(active) => (
              <>
                <Input
                  disableUnderline
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {
                          showPassword ? <IconEyeOff /> : <IconEye />
                        }
                      </IconButton>
                    </InputAdornment>
                  }
                  isNew
                  name="password"
                  placeholder="Password"
                  required
                  type={showPassword ? "text" : "password"}
                />
                {active && <Error error="Caps Lock is on" />}
              </>
            )}
          </ReactIsCapsLockActive>
          <LoginButtonsWrapper>
            <Checkbox
              checked={agreeTnc}
              color="rgb(184, 214, 235)"
              label={
                <CheckboxLabel>
                  <span>
                    Click to accept our
                  </span>
                  <Link
                    href="https://radar-prod-static-files.s3.eu-west-2.amazonaws.com/static/Antarctica+Hub+Disclaimer+and+Terms+of+Use.pdf"
                    isNew
                    target="_blank"
                  >
                    Terms of Use
                  </Link>
                  &nbsp;&&nbsp;
                  <Link
                    href={`${process.env.REACT_APP_ANTARCTICAAM_WEBSITE_URL}/privacy-policy`}
                    isNew
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </CheckboxLabel>
              }
              name="disaclaimer"
              onChange={clickUserAgreementCheckboxHandler}
              size="sm"
            />
            <NewSubmitButton
              disableElevation
              disabled={!agreeTnc || authenticating}
              type="submit"
            >
              {
                authenticating
                  ? <CircularProgress
                    color="inherit"
                    size={24}
                  />
                  : "Login"
              }
            </NewSubmitButton>
            <Button
              className="forgot-pwd-btn"
              color="primary"
              onClick={() => setView("password_reset")}
              variant="text"
            >
              Forgot my password
            </Button>
            <RequestAccessTextWrapper>
              <div>
                Don't have an account?
              </div>
              <Button
                color="primary"
                onClick={clickRequestAccessHandler}
                variant="text"
              >
                Request access here
              </Button>
            </RequestAccessTextWrapper>
          </LoginButtonsWrapper>

        </StyledForm>
      )}
    </Formik>
  )
}
