import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import LoadingSpinner from '../../components/misc/LoadingSpinner'
import { useSecurities } from '../../hooks'
import {
  benchmarkConstraintsInputName,
  cardinalityConstraintsInputName,
  defaultOptimiserFormValues,
  groupConstraintsInputName,
} from './form-default-values'
import PortOptAssetsInput, { portOptAssetSchema } from './PortOptAssetsInput'
import PortOptBasicInput, { portOptBasicInputSchema } from './PortOptBasicInput'
import PortOptBenchmarkInput, { portOptBenchmarkSchema } from './PortOptBenchmarkInput'
import PortOptCardinalityInput, { portOptCardinalitySchema } from './PortOptCardinalityInput'
import PortOptGroupConstraintsInput, {
  portOptGroupConstraintsSchema,
} from './PortOptGroupConstraintsInput'
import PortOptRerunConfirmDialog from './PortOptRerunConfirmDialog'
import PortOptRunHeader from './PortOptRunHeader'
import { usePortfolioOptimiser } from './RefactoredPortfolioOptimiserProvider'

const schema = yup.object().shape({
  assets: yup.array().of(portOptAssetSchema),
  [benchmarkConstraintsInputName]: yup.array().when('enableBenchmarkConstraints', {
    is: true,
    otherwise: () => yup.array(),
    then: () => yup.array().of(portOptBenchmarkSchema),
  }),
  [cardinalityConstraintsInputName]: yup.object().when('enableCardinalityConstraints', {
    is: true,
    otherwise: null,
    then: () => portOptCardinalitySchema,
  }),
  enableBenchmarkConstraints: yup.boolean(),
  enableBlackLittermanView: yup.boolean(),
  enableCardinalityConstraints: yup.boolean(),
  enableGroupConstraints: yup.boolean(),
  [groupConstraintsInputName]: yup.array().when('enableGroupConstraints', {
    is: true,
    otherwise: () => yup.array(),
    then: () => yup.array().of(portOptGroupConstraintsSchema),
  }),
}).concat(portOptBasicInputSchema)

const PortfolioOptimiserForm = () => {
  const {
    isNewRunPage,
    isPolling,
    isRunCompleted,
    isRunRunning,
    isSubmittingOrFetchingRun,
    runData,
    setOpenRerunDialog,
    setReRunFormData,
    submitForm,
  } = usePortfolioOptimiser()
  const { isLoadingSecurities } = useSecurities()

  const methods = useForm({
    defaultValues: defaultOptimiserFormValues,
    resolver: yupResolver(schema),
    shouldFocusError: true
  })

  useEffect(() => {
    if (runData && !isNewRunPage) {
      methods.reset(runData.input)
    }
  }, [runData, isNewRunPage])

  const submitFormHandler = (formData) => {
    if (!isNewRunPage) {
      setReRunFormData(formData)
      setOpenRerunDialog(true)
      return
    }

    submitForm(formData)
  }

  const clickShowResultButtonHandler = () => {
    const el = document.getElementById('result-section')
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <FormProvider
        {...methods}
      >
        <form
          onSubmit={methods.handleSubmit(submitFormHandler)}
        >
          {
            (isSubmittingOrFetchingRun || (!isNewRunPage && !runData) || isRunRunning || isLoadingSecurities)
              ? (
                <LoadingSpinner
                  messageComponent={
                    (isRunRunning || isPolling)
                    && (
                      <Typography
                        align="center"
                        component="p"
                      >
                        Please be advised that this task may require some time to complete.
                        {' '}
                        <br />
                        You are encouraged to continue with other activities or close this page as necessary.
                        {' '}
                        <br />
                        Upon completion, you will receive a notification email with the result link.
                      </Typography>
                    )
                  }
                />
              )
              : (
                <>
                  <Box
                    alignItems="flex-start"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>
                      <PortOptRunHeader />
                    </Box>
                    {
                      isRunCompleted && (
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          gridGap={8}
                          justifyContent="flex-end"
                        >
                          <Button
                            color="primary"
                            component={Link}
                            to={`/screener/portfolio-builder?runId=${runData.portfolioOptimiserRunId}`}
                            variant="contained"
                          >
                            Save as new Portfolio
                          </Button>
                          <Button
                            color="primary"
                            onClick={clickShowResultButtonHandler}
                            variant="contained"
                          >
                            Result
                          </Button>
                        </Box>
                      )
                    }
                  </Box>
                  <PortOptBasicInput />
                  <PortOptAssetsInput />
                  <PortOptGroupConstraintsInput />
                  <PortOptBenchmarkInput />
                  <PortOptCardinalityInput />
                  <Button
                    color="primary"
                    disabled={isSubmittingOrFetchingRun}
                    type="submit"
                    variant="contained"
                  >
                    {
                      isSubmittingOrFetchingRun
                        ? <CircularProgress size={24} />
                        : (isNewRunPage ? 'Run Optimiser' : 'Re-run Optimiser')
                    }
                  </Button>
                </>
              )
          }

        </form>
        <PortOptRerunConfirmDialog />
      </FormProvider>
    </>
  )
}

export default PortfolioOptimiserForm
