export function camelToSnakeCase(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1_$2')
    .replace(/([a-z])(\d)/g, '$1_$2')
    .toLowerCase()
}
export function convertCamelToSnakeCase(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(element => convertCamelToSnakeCase(element))
  }

  return Object.keys(obj).reduce((acc, current) => {
    const snakeCaseKey = camelToSnakeCase(current)
    const value = obj[current]

    acc[snakeCaseKey] = convertCamelToSnakeCase(value)
    return acc
  }, {} as Record<string, any>)
}