import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import IDocumentNotification from "../models/IDocumentNotification"
import { docsNotificationsFetched } from "../redux/docsNotificationSlice"
import { getStoriesNotifications } from "../services"
import { convertCamelToSnakeCase } from "../utils/convertCamelToSnakeCase"
import { convertSnakeToCamelCase } from "../utils/convertSnakeToCamelCase"
import { useAppDispatch } from "./useRedux"

export interface UseDocumentNotificationsParams {
  orderBy?: string | null
  read?: boolean | null
  sinceLastLogin?: boolean | null
  ignored?: boolean | null
  page?: number
  storyType?: string | null
}

export const defaultDocsNotiPageSize = 25
export const defaultDocsNotificationParams = {
  ignored: null,
  orderBy: '-as_of_date,id,security_id',
  page: 1,
  pageSize: defaultDocsNotiPageSize,
  read: null,
  sinceLastLogin: null,
  storyType: '-AAAP Factsheet'
}


const useFetchDocsNotifications = (params: UseDocumentNotificationsParams = undefined) => {
  const dispatch = useAppDispatch()
  const paramsWithDefaults = { ...defaultDocsNotificationParams, ...params }
  const {
    data,
    isLoading,
    refetch
  } = useQuery(['notifications', paramsWithDefaults],
    () => getStoriesNotifications(convertCamelToSnakeCase(paramsWithDefaults)),
    {
      enabled: !!params,
      select: res => convertSnakeToCamelCase(res.data)
    }
  )

  useEffect(() => {
    if (data) {
      dispatch(docsNotificationsFetched({ notificationCount: data?.count, notifications: data.results as IDocumentNotification[] }))
    }
  }, [data])

  return {
    isLoading,
    refetch
  }
}

export default useFetchDocsNotifications
