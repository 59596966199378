import { Box, Tooltip, Typography } from '@material-ui/core'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'

import formatDate from '../../../utils/formatDate'

const PortOptResultSection = ({
  children,
  date,
  disableMaxWidth,
  header,
  runData,
}) => {
  return (
    <Box
      component="section"
      maxWidth={disableMaxWidth ? 'unset' : '800px'}
      mx="auto"
      my={8}
    >
      {
        header && (
          <Box
            display="flex"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h6">{header}</Typography>
            <Box
              alignItems="center"
              display="flex"
              gridGap={8}
            >
              <CalendarTodayOutlinedIcon fontSize="small" />
              {date}
              {
                (runData?.input?.performanceStartDate || runData?.input?.performanceEndDate) && (
                  <Tooltip
                    title={
                      (
                        <Box>
                          {
                            runData?.input?.performanceStartDate && (
                              <div>
                                Requested Start Date: {formatDate(runData?.input?.performanceStartDate)}
                              </div>
                            )
                          }
                          {
                            runData?.input?.performanceEndDate && (
                              <div>
                                Requested End Date: {formatDate(runData?.input?.performanceEndDate)}
                              </div>
                            )
                          }
                        </Box>
                      )
                    }
                  >
                    <InfoOutlinedIcon style={{ color: 'gray' }} />
                  </Tooltip>
                )
              }

            </Box>
          </Box>
        )
      }
      {children}
    </Box>
  )
}

export default PortOptResultSection
