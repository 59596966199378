import Typography from '@material-ui/core/Typography'

import { exposure_analysis_lists } from '../../data/analysis'
import { dateTimeManager as dt } from '../../utils'
import ReportSnippets from '../../utils/reportInputSnippets'

export default function ReportInputs({
  updateSelector,
  exposure_analysis_input,
  getAnalysis,
  loading,
  security_details,
  ...props
}) {
  const { classifications = [], quarterly_months = [] } = security_details || {}
  const generateReport = () => {
    let payload
    const {
      category,
      end_date,
      fund,
      report_date,
      report_type,
      start_date,
      table,
      table_mode,
    } = exposure_analysis_input
    if (!fund) {
      return
    }
    let core_data = {
      end_date: dt.jsToDb(end_date),
      end_date: dt.jsToDb(end_date),
      fund: fund.id,
      report_type,
      start_date: dt.jsToDb(start_date),
    }
    if (report_type === 'tables') {
      if (!report_date || !table || !table_mode || !category) {
        return
      }
      payload = {
        ...core_data,
        category: category.id,
        report_date: report_date.id,
        table: table.id,
        table_mode: table_mode.id,
      }
    } else {
      payload = {
        ...core_data,
      }
    }
    getAnalysis(payload)
  }

  const { report_type, table } = exposure_analysis_input || {}
  const { id: table_name } = table || {}
  const reportSnippets = new ReportSnippets({
    ...props,
    data: exposure_analysis_input,
    submitFunction: generateReport,
    submitFunction: generateReport,
    updateInputs: updateSelector,
  })
  const is_charts = report_type === 'charts'
  const is_tables = report_type === 'tables'
  const not_quarterly = table_name !== 'quarterly_change_table'

  return (
    <div className="selectors">
      <Typography
        align="center"
        variant="h6"
      >
        Report Set-Up
      </Typography>
      {reportSnippets.globalSearch('fund', 'Fund')}
      {reportSnippets.buttonGroupSelector(
        'report_type',
        exposure_analysis_lists.report_types,
      )}
      {is_charts && reportSnippets.starEndSelector('start_date', 'end_date')}
      {is_tables &&
        reportSnippets.dropdownSelector(
          'report_date',
          'Report Date',
          quarterly_months || [],
          {
            dataItemKey: 'id',
            defaultItem: { id: null, name: 'Report date...' },
            textField: 'name',
          },
        )}
      {is_tables &&
        reportSnippets.dropdownSelector(
          'category',
          'Classification',
          classifications || [],
          {
            dataItemKey: 'id',
            defaultItem: { id: null, name: 'Category...' },
            textField: 'name',
          },
        )}
      {is_tables &&
        reportSnippets.dropdownSelector(
          'table',
          'Table',
          exposure_analysis_lists.table_name,
          {
            dataItemKey: 'id',
            textField: 'name',
          },
        )}
      {!!(is_tables && not_quarterly) &&
        reportSnippets.dropdownSelector(
          'table_mode',
          'Table Mode',
          exposure_analysis_lists.table_mode,
          {
            dataItemKey: 'id',
            textField: 'name',
          },
        )}
      {reportSnippets.submitButton('Generate Report', {
        disabled:
          loading ||
          !exposure_analysis_input?.fund ||
          (is_tables &&
            (!exposure_analysis_input?.report_date?.id ||
              !exposure_analysis_input?.category?.id)),
      })}
    </div>
  )
}

// export class reportInputs extends Component {

//   updateStateSelector = ({ value, name }) => {
//     const { updateInputs, getDetails } = this.props;
//     updateInputs({ value, name });
//     if (name === "fund" && value) {
//       const { id: fund_id, type: security_type } = value || {};
//       getDetails({ fund_id, security_type });
//     }
//   };

//   render() {

//     return (

//     );
//   }
// }
