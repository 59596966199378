import { Box, Typography } from '@material-ui/core'
import React from 'react'

const NotFoundPage = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
      <Typography variant="h1">
        404
      </Typography>
      <Typography variant="h5">
        Page not found
      </Typography>
    </Box>
  )
}

export default NotFoundPage
