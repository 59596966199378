import Typography from '@material-ui/core/Typography'
import AttachmentIcon from '@material-ui/icons/AttachFile'
import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import React, { useContext, useEffect } from 'react'
import { useAlert } from 'react-alert'

import InsightPreviewCard from '../../components/misc/InsightPreviewCard'
import LoadingSpinner from '../../components/misc/LoadingSpinner'
import { FundsContext } from '../../contexts'
import useInsights from '../../hooks/useInsights'
import { useMixpanel } from '../../hooks/useMixpanel'
import useParamsQuery from '../../hooks/useParamsQuery'
import { downloadWatermarked } from '../../services'
import { buildFileFromResponse } from '../../utils'
import { useProcessedInsightBody } from '../../hooks/useProcessedInsightArticle'

export default function InsightPage({ match }) {
  const { mpTrack } = useMixpanel()
  const queryParams = useParamsQuery()
  const isLegacyInsight = !!queryParams?.get('o')
  const { params } = match || {}
  const { story_id } = params || {}
  const {
    insight: story,
    isLoadingHsInsights,
    isLoadingSingleLegacyInsight,
    recentInsights,
  } = useInsights({
    insightId: story_id,
    isLegacy: isLegacyInsight,
  })
  const alert = useAlert()
  const { setStoryData } = useContext(FundsContext)
  const processedInsightBody = useProcessedInsightBody(story?.postBody)

  const download = async () => {
    const downloadAlert = alert.show('Downloading the attachment...')
    try {
      const response = await downloadWatermarked({ id: story_id })
      buildFileFromResponse(response)
    } catch (error) { }
    downloadAlert.close()
  }

  useEffect(() => {
    if (story) {
      setStoryData({
        id: story_id,
        name: story.page_title,
      })
    }
  }, [story, story_id])

  useEffect(() => {
    if (story?.name) {
      mixpanel.time_event(`View Document - ${story.name}`)
    }
    return () => {
      if (story?.name) {
        mpTrack({
          eventName: `View Document - ${story.name}`,
          properties: {
            Author: story.user_name,
            'Created Date': story.as_of_date,
            Header: story.name,
            ID: story.id,
            'Story Type': story.story_type,
            'View Type': 'Insights Page',
          },
        })
      }
    }
  }, [story, mpTrack])

  return (isLoadingHsInsights || (isLegacyInsight && isLoadingSingleLegacyInsight))
    ? <LoadingSpinner />
    : (
      <div>
        <div className="insights-content">
          <div className="insights-content-inner-wrapper">
            <Typography
              className="insights-content-header"
              component="h1"
              variant="h3"
            >
              {story?.name}
            </Typography>
            <Typography
              className="insights-content-publish-date"
              component="time"
              variant="body1"
            >
              {moment(story?.publishDate)?.format('MMM Do YYYY')}
            </Typography>
            <img src={story?.featuredImage} />
            <div
              dangerouslySetInnerHTML={{ __html: processedInsightBody }}
            />
            {story?.attachment && (
              <div className="article-attachment">
                <Typography
                  onClick={download}
                  variant="body1"
                >
                  <AttachmentIcon />
                  {' '}
                  Download attachment
                </Typography>
              </div>
            )}
          </div>
        </div>
        {
          (story?.story_type === 'AAAP News' || story?.contentGroupId) && recentInsights?.length > 0 && (
            <>
              <Typography
                className="recent-insights-header"
                component="h6"
                variant="h4"
              >
                Recent Insights
              </Typography>
              <div className="recent-insights-wrapper">
                {
                  recentInsights?.map((insight) => (
                    <InsightPreviewCard
                      {...insight}
                      key={insight.id}
                    />
                  ))
                }
              </div>
            </>
          )
        }

      </div>
    )
}
