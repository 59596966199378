import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { contact_list } from "../../data/funds";

export default class contactEditDialog extends Component {
  static propTypes = {
    current_contact: PropTypes.object,
    edit_form_open: PropTypes.bool,
    cancelEdit: PropTypes.func,
  };

  generateTextFields = () => {
    const { current_contact, updateDetails } = this.props || {};

    return contact_list.map((entry, idx) => {
      const { field, title } = entry;
      const { [field]: value } = current_contact || {};

      return (
        <TextField
          key={idx}
          margin="dense"
          name={field}
          label={title}
          value={value}
          onChange={updateDetails}
          fullWidth
        />
      );
    });
  };

  render() {
    const {
      edit_form_open: open,
      cancelEdit,
      saveEdit,
      removeContact,
      current_contact,
    } = this.props;
    const { id, contact_name } = current_contact || {};
    return (
      <Dialog open={open}>
        <DialogTitle>{contact_name || "New Contact"}</DialogTitle>
        <DialogContent>{this.generateTextFields()}</DialogContent>
        <DialogActions>
          {id && (
            <Button onClick={removeContact} color="primary">
              Delete
            </Button>
          )}

          <Button onClick={cancelEdit} color="primary">
            Cancel
          </Button>
          <Button onClick={saveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
