import { useCallback, useEffect, useState } from "react"

type OpenStateMap = { [key: number]: boolean }

interface UseExpandCollapseProps {
  numberOfElements: number
}

interface UseExpandCollapseReturn {
  toggleRow: (rowIndex: number) => void;
  toggleExpandAll: () => void;
  isExpand: OpenStateMap;
  expandAll: boolean;
  reset: (expand?: boolean) => void;
}

const useExpandCollapse = ({
  numberOfElements = 0
}: UseExpandCollapseProps): UseExpandCollapseReturn => {
  const [rowOpenStateMap, setRowOpenStateMap] = useState<OpenStateMap>({})
  const [expandAll, setExpandAll] = useState(false)

  useEffect(() => {
    const openStateMap: OpenStateMap = {}
    for (let itemIndex = 0; itemIndex < numberOfElements; itemIndex++) {
      openStateMap[+itemIndex] = false
    }
    setRowOpenStateMap(openStateMap)
  }, [numberOfElements])

  useEffect(() => {
    setRowOpenStateMap(prev => {
      const newObj = Object.fromEntries(
        Object.entries(prev).map(([key, _]) => [key, expandAll])
      )
      return newObj
    })
  }, [expandAll])

  const toggleRow = useCallback((rowIndex: number) => setRowOpenStateMap(prev => ({ ...prev, [rowIndex]: !prev[rowIndex] })), [])

  const toggleExpandAll = () => setExpandAll(!expandAll)

  const reset = (expand: boolean = false) => setExpandAll(expand)

  return {
    toggleRow,
    toggleExpandAll,
    isExpand: rowOpenStateMap,
    expandAll,
    reset
  }
}

export default useExpandCollapse
