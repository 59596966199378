import { Box } from '@material-ui/core'
import React from 'react'
import { aamui } from '../../../styles/AppTheme'

const ChartContainer = ({
  children,
  ...boxProps
}) => (
  <Box
    bgcolor="white"
    border={`1px solid ${aamui.iceBlue}`}
    py={2}
    {...boxProps}
  >
    {children}
  </Box>
)

export default ChartContainer
