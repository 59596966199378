import React, { Component } from "react";
import { GenericTable } from "../../components";
import Grid from "@material-ui/core/Grid";
import { LineTimeSeries } from "../../components";
import { titleCase } from "../../utils";

export default class roc extends Component {
  getTable = (name, width = 200) => {
    const { internal_analysis } = this.props;
    const { report } = internal_analysis || {};
    return (
      <GenericTable
        table_name={name}
        data={report[name]}
        index_width={`${width}px`}
        equal_width
        negative_red
        // index_auto
      />
    );
  };

  getChartFormat = (field) => {
    const decimals = ["Beta", "Correlation"];
    let format = "{0:p2}";
    decimals.forEach((element) => {
      if (field.includes(element)) {
        format = "{0:n2}";
        return format;
      }
    });
    return format;
  };

  render() {
    const { getTable } = this;
    const simple_tables = [
      "Annual Returns",
      "Unlevered Returns",
      "Annual Over/Under Performance",
      "Portfolio statistics",
      "Unlevered Long",
      "Unlevered Short",
    ];
    const { internal_analysis, narrow } = this.props;
    const { report } = internal_analysis || {};
    const {
      chart_keys = [],
      charts = {},
      rolling_performance = {},
    } = report || {};
    const rolling_performance_names = ["long", "short"];

    return (
      <Grid container spacing={1} justifyContent="center">
        {simple_tables.map((table, id) => (
          <Grid key={id} item sm={12} lg={narrow || 8} xl={narrow || 6}>
            {getTable(table, 250)}
          </Grid>
        ))}
        {rolling_performance_names.map((name, id) => (
          <Grid key={id} item sm={12} xl={narrow || 6}>
            <LineTimeSeries
              data={rolling_performance[name]}
              category_field="index"
              format="{0:p2}"
              title={`Rolling 12M Unlevered ${titleCase(
                name
              )} vs Market Return`}
              standardPalette
            />
          </Grid>
        ))}
        <Grid item sm={12} lg={narrow || 8}>
          {getTable("Annual Alpha", 150)}
        </Grid>
        <Grid item xs={12} />
        {chart_keys.map((chart, id) => (
          <Grid key={id} item sm={12} lg={narrow || 8} xl={narrow || 4}>
            <LineTimeSeries
              data={charts[chart]}
              category_field="Month"
              format={this.getChartFormat(chart)}
              title={chart}
            />
          </Grid>
        ))}
        <Grid item xs={12} lg={narrow || 8}>
          <LineTimeSeries
            title="Cumulative Performance (Net Adjusted)"
            data={report["Cumulative Performance"]}
            minimum={true}
            format={"{0:c}"}
            category_field="index"
            standardPalette
          />
        </Grid>
      </Grid>
    );
  }
}
