
import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Pill,
  Select,
  Stack,
  TagsInput,
  Text,
  TextInput,
  Title
} from '@mantine/core'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { IconEdit } from '@tabler/icons-react'
import { useQueryClient } from '@tanstack/react-query'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { ConstantsContext } from '../../contexts'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import useUpdatePeerGroup, { useUpdatePeerGroupNotification } from '../../hooks/useUpdatePeerGroup'
import { PeerGroup, peerGroupDeleted } from '../../redux/peerGroupsSlice'
import { deletePeerGroup } from '../../services/peer-groups'
import kebabToTitleCase from '../../utils/kebabToTitleCase'

const PeerGroupInfoEdit = () => {
  const queryClient = useQueryClient()
  const constants = useContext(ConstantsContext)
  const dispatch = useAppDispatch()
  const selectedPeerGroup: PeerGroup = useAppSelector(state => state.peerGroups.selectedGroupId && state.peerGroups.entities[state.peerGroups.selectedGroupId])
  const { isLoading, updatePeerGroupInfo } = useUpdatePeerGroup()
  const { showNotification } = useUpdatePeerGroupNotification()

  const [isEditing, setIsEditing] = useState(false)
  const [localPeerGroup, setLocalPeerGroup] = useState<PeerGroup>(selectedPeerGroup)

  const canEdit = selectedPeerGroup?.canEdit

  const visiblityOptions = useMemo(() => constants?.PEER_GROUP_VISIBILITY_TYPE_ENUM.map(visibilityType => ({
    label: kebabToTitleCase(visibilityType),
    value: visibilityType,
  })) || [], [constants])

  useEffect(() => {
    setLocalPeerGroup(selectedPeerGroup)
    setIsEditing(false)
  }, [selectedPeerGroup])



  const changeGroupNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => setLocalPeerGroup((prev) => ({ ...prev, name: event.target.value }))
  const changeTagsHandler = (tags: string[]) => setLocalPeerGroup((prev) => ({ ...prev, tags }))
  const resetLocalPeerGroup = () => setLocalPeerGroup(selectedPeerGroup)

  const toggleEditing = () => {
    setIsEditing(!isEditing)
    if (isEditing) resetLocalPeerGroup()
  }

  const clickSaveButtonHandler = async () => {
    try {
      await updatePeerGroupInfo(localPeerGroup)
      setIsEditing(false)
      showNotification({ groupName: localPeerGroup.name, message: '' })
    } catch (err) {

    }
  }

  const changeVisibilityHandler = async (value: PeerGroup['visibilityType']) => {
    try {
      const updated: PeerGroup = {
        ...selectedPeerGroup,
        visibilityType: value
      }
      setLocalPeerGroup(updated)
      await updatePeerGroupInfo(updated)
      showNotification({ groupName: updated.name, message: `Visibility changed to ${value}` })
    } catch (err) {
      setLocalPeerGroup(selectedPeerGroup)
    }
  }

  const clickDeleteButtonHandler = async () => {
    try {
      modals.openConfirmModal({
        cancelProps: { variant: 'outline' },
        centered: true,
        children: (
          <Text size="sm">
            Deleting <strong>{selectedPeerGroup.name}</strong> will permanently remove it and it will no longer be available. This cannot be undone.
          </Text>
        ),
        labels: { cancel: 'Cancel', confirm: 'Delete', },
        onConfirm: async () => {
          await deletePeerGroup(selectedPeerGroup.id)
          notifications.show({ message: '', title: `Deleted ${selectedPeerGroup.name}` })
          dispatch(peerGroupDeleted())
          queryClient.invalidateQueries(['peer-groups'])
        },
        overlayProps: {
          backgroundOpacity: 0.85,
        },
        title: <Text style={{ color: 'var(--mantine-color-redAccent)' }}>Delete this Peer Group?</Text>
      })
    } catch (err) { }
  }
  return (
    <>
      <Group justify="space-between">
        <Flex
          align="flex-start"
          justify="space-between"
        >
          <Flex
            align="flex-start"
            gap={8}
          >
            <ActionIcon
              arial-label="Edit"
              disabled={!canEdit}
              onClick={toggleEditing}
              style={{ opacity: canEdit ? 1 : 0 }}
              variant="transparent"
            >
              <IconEdit stroke={1.5} />
            </ActionIcon>
            <Stack>
              {
                isEditing ? (
                  <TextInput
                    onChange={changeGroupNameHandler}
                    value={localPeerGroup?.name}
                  />
                ) : (
                  <Title
                    maw={220}
                    order={3}
                    style={{ overflowWrap: 'break-word' }}
                  >
                    {localPeerGroup?.name}
                  </Title>
                )
              }
            </Stack>
          </Flex>
          {
            canEdit &&
            <Flex justify="flex-end">
              <Select
                data={visiblityOptions}
                onChange={changeVisibilityHandler}
                placeholder="Visibility"
                value={localPeerGroup?.visibilityType}
              />
              <Button onClick={clickDeleteButtonHandler}>Delete</Button>
            </Flex>
          }
        </Flex>
      </Group>
      {
        isEditing
          ? (
            <Stack ml={36}>
              <TagsInput
                onChange={changeTagsHandler}
                placeholder="Tags Input"
                value={localPeerGroup?.tags}
              />
              <Group>
                <Button
                  onClick={toggleEditing}
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button
                  loading={isLoading}
                  onClick={clickSaveButtonHandler}
                >
                  Save
                </Button>
              </Group>
            </Stack>
          )
          : localPeerGroup?.tags?.length > 0 && (
            <Flex
              display="flex"
              gap="xs"
              ml={36}
              w="auto"
            >
              {
                localPeerGroup?.tags?.map((tag, idx) => (
                  <Pill
                    key={idx}
                    size="md"
                  >
                    {tag}
                  </Pill>
                ))
              }
            </Flex>
          )

      }
    </>
  )
}

export default PeerGroupInfoEdit