import { Button, Combobox, Group, useCombobox } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

import CountLabel from '../misc/CountLabel'

export type SelectFilterChangedEvent = string | string[] | undefined

interface BaseSelectFilterProps {
  options: { label: string; value: string }[];
  onChange: (value: SelectFilterChangedEvent) => void;
}

interface SingleSelectFilterProps extends BaseSelectFilterProps {
  multiSelect?: false;
  value?: string;
}

interface MultiSelectFilterProps extends BaseSelectFilterProps {
  multiSelect: true;
  value?: string[];
}

type SelectFilterProps = SingleSelectFilterProps | MultiSelectFilterProps;


const SelectFilter: React.FC<React.PropsWithChildren<SelectFilterProps>> = React.memo(({
  children,
  multiSelect = false,
  onChange,
  options,
  value,
}) => {
  const [search, setSearch] = useState('')
  const [timer, setTimer] = useState<NodeJS.Timeout>()
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.focusTarget()
    },
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex('active')

      const timer = setTimeout(() => {
        combobox.focusSearchInput()
      }, 200)

      setTimer(timer)
    },
  })

  const changeSearchValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.currentTarget.value)
  const clickComboxTargetHandler = () => combobox.toggleDropdown()
  const hasSelectedValue = multiSelect ? value?.length : !!value

  useEffect(() => {
    return () => clearTimeout(timer)
  }, [timer])

  const optionSubmitHandler = (selectedValue: string) => {
    if (onChange) {
      if (multiSelect) {
        const arrayValues = Array.isArray(value) ? value.slice() : []
        const newValue = arrayValues.includes(selectedValue) ? arrayValues.filter((v) => v !== selectedValue) : [...arrayValues, selectedValue]
        onChange(newValue)
      } else {
        onChange(value === selectedValue ? undefined : selectedValue)
        combobox.closeDropdown()
      }
    }
  }

  const filteredOptions = options?.filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()))

  return (
    <Combobox
      arrowOffset={16}
      arrowSize={12}
      onOptionSubmit={optionSubmitHandler}
      position="bottom-start"
      store={combobox}
      width={250}
      withArrow
    >
      <Combobox.Target withAriaAttributes={false}>
        <Button
          onClick={clickComboxTargetHandler}
          style={{ color: hasSelectedValue ? 'var(--mantine-color-sapphire)' : undefined }}
          variant="transparent"
        >
          {children} {multiSelect && <CountLabel count={value?.length || 0} />}
        </Button>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Search
          onChange={changeSearchValueHandler}
          placeholder="Search..."
          value={search}
        />
        <Combobox.Options
          style={{ height: 300, overflowY: 'auto' }}
        >
          {filteredOptions && filteredOptions.length > 0 ? (
            <Virtuoso
              data={filteredOptions}
              itemContent={(index, item) => {
                const isActive = multiSelect ? value?.includes(item.value) : value === item.value
                return (
                  <Combobox.Option
                    active={isActive}
                    key={item.value}
                    onClick={() => optionSubmitHandler(item.value)}
                    style={{ background: isActive ? 'var(--mantine-color-eggShell)' : undefined }}
                    value={item.value}
                  >
                    <Group
                      gap="sm"
                      justify="space-between"
                    >
                      {item.label}
                    </Group>
                  </Combobox.Option>
                )
              }}
              style={{ height: '100%' }}
            />
          ) : <Combobox.Empty>Nothing found...</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
})

export default SelectFilter
