import React, { Component } from "react";
import MonthlyCalendar from "./monthlyCalendar";
import { dateTimeManager as dtUtil } from "../../utils";
import MuiDatePicker from "../forms/MuiDatePicker";

export default class monthlyDatePicker extends Component {
  onChange = (e) => {
    const { onChange } = this.props;
    const { value, name } = e.target;
    let target = {
      ...e.target,
      value: dtUtil.toMonthEnd(value),
      name,
    };
    let cloned = { ...e, target };
    onChange(cloned);
  };

  render() {
    return (
      <MuiDatePicker
        {...this.props}
        calendar={MonthlyCalendar}
        format={"MMM yy"}
        onChange={(newDate) => this.onChange({ target: { value: newDate, name: this.props.name } })}
        placeholder="MMM yy"
      />
    );
  }
}
