import { Box } from '@material-ui/core'
import { DatePicker, DatePickerHandle } from "@progress/kendo-react-dateinputs"
import React from 'react'

interface KendoDatePickerProps extends DatePickerHandle {
  helperText?: string
}

const KendoDatePicker: React.FC<KendoDatePickerProps> = ({
  helperText,
  ...props
}) => (
  <Box width="100%">
    <DatePicker
      {...props}
      className="kendo-datepicker"
      format="dd/MM/yyyy"
    />
    {
      helperText && (
        <Box
          color="gray"
          component="div"
          fontSize="12px"
          mt={0.5}
        >{helperText}</Box>
      )
    }
  </Box>
)

export default KendoDatePicker
