import { Box, Combobox, Group, InputBase, Text, useCombobox } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useCallback, useMemo, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import { AAM_PRIVATE_MARKET_NAME } from "../../constants/service.constants";

const FIXED_ORDER_HEADINGS = [
  'Webfolio',
]

const ORDERED_HEADINGS = [
  'FoF', 'FoF Share Class', 'User', 'Bloomberg', 'Eurekahedge',
  'AAAP Cell', 'Market Index', 'Fund', 'Portfolio', 'Backfill', AAM_PRIVATE_MARKET_NAME,
].sort((a, b) => a.localeCompare(b));

const HEADINGS = [...FIXED_ORDER_HEADINGS, ...ORDERED_HEADINGS];

const smartSearch = (pattern, list, fields) => {
  if (!pattern.trim()) return [];
  const searchTerms = pattern.toLowerCase().split(' ').filter(term => term !== '');
  return list.filter(item =>
    fields.some(field =>
      searchTerms.every(term => item[field]?.toLowerCase().includes(term))
    )
  );
};

const GlobalFundSearch = ({
  allowCustom = false,
  disabled = false,
  isToolbar = false,
  items = [],
  multi = false,
  name = '',
  noLink = false,
  placeholder = 'Search...',
  searchFields = ['name'],
  value = '',
  ...props
}) => {
  const history = useHistory();
  const [scrollIndex, setScrollIndex] = useState(0);
  const [filterValue, setFilterValue] = useState('');
  const inputRef = useRef(null);
  const virtuosoRef = useRef(null);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      if (virtuosoRef.current) {
        virtuosoRef.current.scrollToIndex({
          index: 0,
          align: 'start',
          behavior: 'auto'
        });
      }
    }
  });

  const filteredData = useMemo(() =>
    smartSearch(filterValue, items, searchFields), [filterValue, items, searchFields]
  );

  const groupedData = useMemo(() => {
    const grouped = HEADINGS.map(heading => ({
      heading,
      items: filteredData.filter(item => item.type === heading)
    })).filter(group => group.items.length > 0);

    return grouped.flatMap(group => [
      { isHeading: true, name: group.heading },
      ...group.items
    ]);
  }, [filteredData]);

  const handleSelect = useCallback((selectedValue) => {
    const selectedItem = filteredData.find(item => item.name === selectedValue);
    if (!selectedItem) return;

    if (!noLink) {
      if (selectedItem.type === AAM_PRIVATE_MARKET_NAME) {
        history.push(`/private-markets/funds/${selectedItem.id}`);
      } else {
        history.push(`/asset-detail/${selectedItem.id}/`);
      }
    }

    setFilterValue('');
    combobox.closeDropdown();

    if (inputRef.current) {
      inputRef.current.blur();
    }
  }, [filteredData, noLink, history, combobox]);

  const options = groupedData.map((item, index) => (
    <Combobox.Option value={item.name} key={item.id || `heading-${index}`} disabled={item.isHeading} py={4} px={8}>
      {item.isHeading ? (
        <Text fw={700} fz="sm" c="black" bg="white">
          {item.name}
        </Text>
      ) : (
        <Group>
          <Text color="black">{item.name}</Text>
        </Group>
      )}
    </Combobox.Option>
  ));

  return (
    <div className="global-fund-search">
      <Combobox
        store={combobox}
        onOptionSubmit={handleSelect}
        withinPortal={false}
        {...props}
      >
        <Combobox.Target>
          <InputBase
            ref={inputRef}
            placeholder={placeholder}
            value={filterValue}
            onChange={(event) => {
              const newValue = event.currentTarget.value;
              setFilterValue(newValue);

              if (virtuosoRef.current) {
                virtuosoRef.current.scrollToIndex({
                  index: 0,
                  align: 'start',
                  behavior: 'auto'
                });
              }

              if (newValue.trim()) {
                combobox.openDropdown();
              } else {
                combobox.closeDropdown();
              }
            }}
            onClick={() => {
              if (filterValue.trim()) {
                combobox.openDropdown();
              }
            }}
            onFocus={() => {
              if (filterValue.trim()) {
                combobox.openDropdown();
              }
            }}
            rightSection={<IconSearch size="1rem" />}
            rightSectionPointerEvents="none"
            disabled={disabled}
            className={isToolbar ? 'search-input' : 'custom-select-input'}
          />
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options h={options?.length > 0 ? '300px' : '30px'}>
            {
              options?.length > 0 ? (
                <Virtuoso
                  style={{ height: '300px' }}
                  ref={virtuosoRef}
                  totalCount={options.length}
                  itemContent={(index) => options[index]}
                  overscan={200}
                  increaseViewportBy={300}
                  rangeChanged={(range) => {
                    setScrollIndex(range.startIndex);
                  }}
                  initialTopMostItemIndex={scrollIndex}
                />
              ) : <Text style={{ textAlign: 'center' }} c="gray" mx="auto">No Data Found</Text>
            }
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
};

export default GlobalFundSearch;