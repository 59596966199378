import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

import { fetchedAllSecurities } from "./sharedActions"
import { RootState } from "./store"

export const portfoliosAdapter = createEntityAdapter()
const initialState = portfoliosAdapter.getInitialState()


export const portfoliosSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchedAllSecurities, (state, action: any) => {
      portfoliosAdapter.upsertMany(state, action.payload.portfolios)
    })
  },
  initialState,
  name: 'portfolios',
  reducers: {

  }
})

export const {
  selectAll: selectAllPortfolios,
  selectById: selectPortfolioById,
  selectEntities: selectPortfolioEntities,
  selectIds: selectPortfolioIds,
  selectTotal: selectTotalPortfolios,
} = portfoliosAdapter.getSelectors((state: RootState) => state.portfolios)

const portfoliosReducer = portfoliosSlice.reducer
export default portfoliosReducer