// LocalStorageService.js
const LocalStorageService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setUnconfirmedToken(token) {
    localStorage.setItem("unconfirmed", token);
  }
  function _getUnconfirmedToken() {
    return localStorage.getItem("unconfirmed");
  }

  function _setConfirmedToken(token) {
    localStorage.setItem("confirmed", token);
  }
  function _getConfirmedToken() {
    return localStorage.getItem("confirmed");
  }
  function _clearToken() {
    localStorage.removeItem("unconfirmed");
    localStorage.removeItem("confirmed");
  }
  return {
    getService: _getService,
    setUnconfirmedToken: _setUnconfirmedToken,
    getUnconfirmedToken: _getUnconfirmedToken,
    setConfirmedToken: _setConfirmedToken,
    getConfirmedToken: _getConfirmedToken,
    clearToken: _clearToken,
  };
})();
export default LocalStorageService;
