import { ActionIcon, Menu } from '@mantine/core'
import { IconDots } from '@tabler/icons-react'
import React from 'react'

import { useMixpanel } from '../../hooks/useMixpanel'
import { useAppDispatch } from '../../hooks/useRedux'
import useUpdateDocsNotification from '../../hooks/useUpdateDocsNotification'
import {
  allNotificationsReadStatusToggled,
  selectedDocsNotiDismissed,
  useSelectedDocsNotifications,
  useSelectionMode
} from '../../redux/docsNotificationSlice'

interface DocsNotificationReadToggleMenuButtonProps {
  refetch: () => void
}

const DocsNotificationReadToggleMenuButton: React.FC<DocsNotificationReadToggleMenuButtonProps> = ({
  refetch
}) => {
  const { mpTrack } = useMixpanel()
  const dispatch = useAppDispatch()
  const { setAllNotificationToReadStatus, setNotificationToReadStatus } = useUpdateDocsNotification()
  const selectedDocsNotifications = useSelectedDocsNotifications()
  const { selectAll } = useSelectionMode()

  const clickMenuItemHandler = (isRead: boolean) => async () => {
    if (!selectAll) {
      const selectedIds = Object.keys(selectedDocsNotifications).filter(it => selectedDocsNotifications[+it]).map(it => +it)
      await setNotificationToReadStatus(selectedIds, isRead)
      mpTrack({
        eventName: 'Document Notifications Read Status Changed', properties: {
          NotificationIds: selectedIds,
          Read: isRead
        }
      })
    } else {
      dispatch(allNotificationsReadStatusToggled())
      await setAllNotificationToReadStatus(isRead)
      mpTrack({
        eventName: 'Document Notifications Read Status Changed', properties: {
          NotificationIds: 'All',
          Read: isRead
        }
      })
    }
    dispatch(selectedDocsNotiDismissed())
    refetch()
  }

  return (
    <Menu withArrow>
      <Menu.Target>
        <ActionIcon
          size="sm"
          variant="transparent"
        >
          <IconDots />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={clickMenuItemHandler(true)}>
          Mark as Read
        </Menu.Item>
        <Menu.Item onClick={clickMenuItemHandler(false)}>
          Mark as Unread
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export default DocsNotificationReadToggleMenuButton