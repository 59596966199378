import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { process } from '@progress/kendo-data-query'
import { useQuery } from '@tanstack/react-query'
import { formatNumber } from '@telerik/kendo-intl'
import { useMemo } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'

import DashboardBlock from '../../components/misc/DashboardBlock'
import PlainTable from '../../components/tables/plainTable'
import { BASE_ROUTE_TYPES } from '../../constants/routes.constants'
import { useSecurities } from '../../hooks'
import { getAlternativesOverview } from '../../services'
import { dateTimeManager } from '../../utils'
import HighlightsCard, { HighlightType } from './HighlightsCard'
import InsightsPane from './InsightsPane'

export default function AaapSelectTabNew() {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
  const {
    aaap_cell_list,
    loadingStates,
    portfolio_list,
    search_list: searchList,
    search_list_map,
  } = useSecurities()
  const { data: alternativesData, isLoading: isLoadingKeyBenchmarks } =
    useQuery(
      ['key benchmarks'],
      async () => {
        const res = await getAlternativesOverview()
        return res.data
      },
      { staleTime: Infinity },
    )

  const keyBenchmarkData = useMemo(() => {
    if (alternativesData && search_list_map) {
      return alternativesData.map((it) => ({
        ...it,
        disabledClick: !search_list_map[it.security_id],
      }))
    }
    return []
  }, [alternativesData, search_list_map])
  console.log('keyBenchmarkData', keyBenchmarkData)

  const getTopCells = () => {
    let filterValue = new Date(new Date().getFullYear(), 0, 1)

    // Apply the filter
    let filteredData
    const classesOrder = ['Class X', 'Class B', 'Class H']

    for (const className of classesOrder) {
      filteredData = applyFilter(filterValue, className)

      if (filteredData.length > 0) {
        break
      }
    }

    // If the filtered data is an empty array, adjust the filter value
    if (!filteredData || filteredData.length === 0) {
      filterValue = new Date(filterValue.setMonth(filterValue.getMonth() - 1))

      // Apply the filter again with the new filter value
      filteredData = applyFilter(filterValue)
    }

    // The function to apply the filter
    function applyFilter(filterValue, className = 'Class X') {
      const filter = process(aaap_cell_list, {
        filter: {
          filters: [
            { field: 'class_name', operator: 'eq', value: className },
            {
              field: 'calculated_statistics.stats_date',
              operator: 'gte',
              value: filterValue.toISOString().split('T')[0], // Ensure the date is in YYYY-MM-DD format
            },
          ],
          logic: 'and',
        },
        sort: [{ dir: 'desc', field: 'calculated_statistics.ytd' }],
      })
      const { data: filtered } = filter

      const flat_cells = filtered.slice(0, 9)

      return mapSecurityList(flat_cells, 'short')
    }
    return filteredData
  }

  const mapSecurityList = (sec_list, stats_type) => {
    try {
      switch (stats_type) {
        case 'short':
          return sec_list.map((o) => ({
            Name: o.name,
            // eslint-disable-next-line sort-keys/sort-keys-fix
            Date: dateTimeManager.dbToFormat(
              o?.calculated_statistics?.stats_date,
            ),
            MTD: formatNumber(o.calculated_statistics?.last_month_ret, 'p2'),
            YTD: formatNumber(o.calculated_statistics?.ytd, 'p2'),
            link: `/asset-detail/${o.id}/`,
          }))
        case 'long':
          const longResult = sec_list.map((o) => ({
            Name: o.name,
            Status: o.status,
            Summary: o.notes,
            link: `/asset-detail/${o.id}/`,
          }))

          return longResult
        default:
          return []
      }
    } catch (e) {
      console.error('Error mapping security list:', e)
      return []
    }
  }

  const getPriorityCells = () => {
    const filter = process(aaap_cell_list, {
      filter: {
        filters: [{ field: 'priority', operator: 'neq', value: null }],
        logic: 'and',
      },
      sort: [{ dir: 'desc', field: 'priority' }],
    })
    const { data: filtered } = filter
    const flat_cells = filtered.slice(0, 10)
    return mapSecurityList(flat_cells, 'long')
  }

  const mapBenchmarks = (data) => {
    return data.map((o) => ({
      Index: o.security,
      // eslint-disable-next-line sort-keys/sort-keys-fix
      Date: dateTimeManager.dbToFormat(o.Date),
      MTD: formatNumber(o.MTD, 'p2'),
      YTD: formatNumber(o.YTD, 'p2'),
      link: `/asset-detail/${o.security_id}/`,
      disabledClick: o.disabledClick,
    }))
  }

  const top_performers = getTopCells()

  const fofList = searchList
    .filter((o) => o.type === 'FoF Share Class' && !!o.calculated_statistics)
    .slice(0, 3)

  const fof_performance = mapSecurityList(fofList, 'short')

  const portfolioNumber = fof_performance.length > 0 ? 3 : 9

  const my_portfolios = mapSecurityList(
    searchList
      .filter((o) => o.type === 'Portfolio' && !!o.calculated_statistics)
      .slice(0, portfolioNumber),
    'short',
  )

  console.log('top_performers'.top_performers)
  return (
    <>
      <Grid
        className="dash-cards greeting"
        container
        direction={isTablet ? 'column-reverse' : 'row'}
        spacing={2}
      >
        <Grid
          item
          lg={8}
          xl={8}
          xs={12}
        >
          <Grid
            container
            direction="row"
            spacing={2}
          >
            <Grid
              className="top-performers"
              item
              lg={6}
              xl={6}
              xs={12}
            >
              <DashboardBlock
                header="AAAP Top Performance"
                viewAllLink="/screener"
              >
                <PlainTable
                  data={
                    loadingStates.aaap_cell_list
                      ? Array(10).fill({ Date: '', MTD: '', Name: '', YTD: '' })
                      : top_performers
                  }
                  isLoading={loadingStates.aaap_cell_list}
                  tableName="AAAP Top Performers"
                />
              </DashboardBlock>
            </Grid>
            <Grid
              className="hf-insights-wrapper"
              item
              lg={6}
              xl={6}
              xs={12}
            >
              <InsightsPane type={BASE_ROUTE_TYPES.HEDGE_FUND} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={4}
          xl={4}
          xs={12}
        >
          <HighlightsCard type={HighlightType.HEDGE_FUND} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
      >
        <Grid
          className="index-overview"
          item
          lg={4}
          md={7}
          xl={4}
          xs={12}
        >
          <DashboardBlock header="Key Benchmarks">
            <PlainTable
              data={
                isLoadingKeyBenchmarks
                  ? Array(10).fill({ Date: '', MTD: '', Name: '', YTD: '' })
                  : mapBenchmarks(keyBenchmarkData)
              }
              isLoading={isLoadingKeyBenchmarks}
            />
          </DashboardBlock>
        </Grid>
        <Grid
          className="portfolio-overview"
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <Card className="dashboard-card dash-cards">
            <CardContent>
              <>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">Antarctica FoFs</Typography>
                  <Button
                    className="view-all-button"
                    color="primary"
                    component={Link}
                    endIcon={<ChevronRightIcon />}
                    to="/antarctica-fofs"
                  >
                    View All
                  </Button>
                </Box>
                <PlainTable
                  data={
                    loadingStates.fof_share_class_list
                      ? Array(3).fill({ Date: '', MTD: '', Name: '', YTD: '' })
                      : fof_performance
                  }
                  isLoading={loadingStates.fof_share_class_list}
                />
              </>
              <>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  mt={2}
                >
                  <Typography variant="h6">My Portfolios</Typography>
                  <Button
                    className="view-all-button"
                    color="primary"
                    component={Link}
                    endIcon={<ChevronRightIcon />}
                    to="/my-portfolios"
                  >
                    View All
                  </Button>
                </Box>
                <PlainTable
                  data={
                    loadingStates.portfolio_list
                      ? Array(3).fill({ Date: '', MTD: '', Name: '', YTD: '' })
                      : my_portfolios || []
                  }
                  isLoading={loadingStates.portfolio_list}
                />
              </>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
