import moment from "moment";

export const parseTimeSeries = (report = {}, dateTime = true) => {
  const raw = report.data.rows;
  const headers = report.columnHeader.metricHeader;
  const { metricHeaderEntries = [] } = headers || {};
  const metricNames = metricHeaderEntries.map((o) => o.name);
  let reshaped = [];
  for (let i = 0; i < raw.length; i++) {
    const element = raw[i];
    const { dimensions, metrics = [] } = element;
    const { values = [] } = metrics[0];
    let dataPoint = {};
    if (dateTime === true) {
      dataPoint["index"] = moment(dimensions[0]).toDate();
    } else {
      dataPoint["index"] = dimensions[0];
    }
    for (let j = 0; j < values.length; j++) {
      dataPoint[metricNames[j]] = Number(values[j]);
    }
    reshaped.push(dataPoint);
  }
  return reshaped;
};

const parsePageView = (activity) => {
  let flatActivity = {};
  const { pageview, activityTime } = activity || {};
  const { pagePath, pageTitle } = pageview || {};
  flatActivity["time"] = moment(activityTime).toDate();
  flatActivity["path"] = pagePath;
  flatActivity["title"] = pageTitle;
  return flatActivity;
};

export const parseUserSessions = (report) => {
  let output = [];
  for (let i = 0; i < report.length; i++) {
    const entry = report[i];
    const { activities = [] } = entry;
    let session = {};
    session["Date"] = moment(entry.sessionDate).toDate();
    session["device"] = entry["deviceCategory"];
    session["platform"] = entry["platform"];
    session["activities"] = activities.map((o) => parsePageView(o));
    output.push(session);
  }
  return output;
};
