import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TableOptions } from '@tanstack/table-core'
import { useMemo, useState } from 'react'

interface UseReactTableManagerParams<T> extends Omit<TableOptions<T>, 'getCoreRowModel' | 'getSortedRowModel' | 'getFilteredRowModel'> {
  enableColumnsVisibility?: boolean
}

function useReactTableManager<T>(options: UseReactTableManagerParams<T>) {
  const [columnVisibility, setColumnVisibility] = useState({})

  const reactTableParams = useMemo(() => {
    const defaultRtParams = {
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
    }

    const { enableColumnsVisibility, ...rtOptions } = options

    if (enableColumnsVisibility) {
      if (!rtOptions.state) {
        rtOptions.state = {}
      }

      rtOptions.state.columnVisibility = columnVisibility
      rtOptions.onColumnVisibilityChange = setColumnVisibility
    }

    return {
      ...rtOptions,
      ...defaultRtParams
    }
  }, [options, columnVisibility])

  const table = useReactTable(reactTableParams)

  return {
    columnVisibility,
    setColumnVisibility,
    table
  }
}

export default useReactTableManager
