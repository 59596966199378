export const accessRightKeyMap = {
  hasAccessUserManagement: 'custom_user_management',
  hasAccessPortfolioBuilder: 'custom_full_portfolio_builder',
  hasAccessInternalClient: 'custom_internal_client_access',
  hasAccessAnalytics: 'custom_access_analytics',
  hasAccessDataManagement: 'custom_access_data_manager',
  hasAccessOps: 'custom_access_ops',
  hasAccessIr: 'custom_access_ir',
  hasAccessTestPages: 'custom_access_test_pages',
  hasPrivateEquityAccess: 'custom_access_private_equity',
};
