import { del, get, post } from './api'

export const getAaapCells = (payload) =>
  get('/api/v1/aaap-securities/', payload)

export const getFofShareClasses = (payload) =>
  get('/api/v1/fof-share-classes/', payload)

export const getSecurityDetails = (payload) => {
  const { security_type, fund_id, params, props } = payload

  const endpoints = {
    'AAAP Cell': `/api/v1/aaap-securities/${fund_id}/`,
    'Market Index': `/api/v1/market-index/${fund_id}/`,
    FoF: `/api/v1/webfolio-portfolio/${fund_id}/`,
    Portfolio: `/api/v1/portfolio/${fund_id}/`,
    'FoF Share Class': `/api/v1/fof-share-classes/${fund_id}/`,
    default: `/api/v1/hedge-fund/${fund_id}/`,
  }

  const url = endpoints[security_type] || endpoints['default']

  return get(url, params, props)
}

export const getHedgeFundList = (payload) => get('/api/v1/hedge-fund/', payload)

export const getMarketIndexList = (payload) =>
  get('/api/v1/market-index/', payload)

export const updateContactDetails = (payload) =>
  post('/api/v1/hedge-fund-contact/', payload)

export const removeContactDetails = (payload) =>
  del('/api/v1/hedge-fund-contact/', payload)

export const updateHedgeFund = (payload) => post(`/api/v1/hedge-fund/`, payload)

export const generateFundFactSheet = (id) =>
  get(`/api/v1/hedge-fund/${id}/factsheet/`, null, { responseType: 'blob' })

export const generateAaapFactSheet = (id) =>
  get(`/api/v1/aaap-securities/${id}/factsheet/`, null, {
    responseType: 'blob',
  })

export const getScreenerFilterOptions = () => get(`/api/v1/hedge-fund/options/`)
export const getPeerGroupsOptionsMap = () => get(`/api/v1/hedge-fund/options/map`)
export const getFactSheetAvailability = (fundId) => get(`/api/v1/aaap-securities/${fundId}/factsheet-availability/`)
export const getFofFactSheetAvailability = (fundId) => get(`/api/v1/fof-share-classes/${fundId}/factsheet-availability/`)
export const getFofQuarterFactSheetAvailability = (fundId) => get(`/api/v1/fof-share-classes/${fundId}/quarterly-review-availability/`)