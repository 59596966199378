import { Typography } from "@material-ui/core"
import qs from "qs"
import React, { useContext, useState } from "react"

import { AuthContext } from "../../contexts"
import EmergencyTokenForm from "./emergencyTokenForm"
import LoginForm from "./loginForm"
import OtpForm from "./otpForm"
import RequestAccessForm from "./requestAccess"
import ResetPasswordForm from "./resetPasswordForm"
import { AuthPageContainer, BannerContainer, FormContainer, Logo, LogoWrapper } from "./styles"
import AuthPageWrapper from "../../components/layout/AuthPageWrapper"

const Login = ({ location }) => {
  const { search } = location
  const params = qs.parse(search.replace("?", ""))
  const { reset_email: resetEmail } = params || {}
  const intialView = resetEmail ? "password_reset" : "default"
  const { authenticating, isAuthorized, isLoggedIn } = useContext(AuthContext)
  const [view, setView] = useState(intialView)
  const renderView = () => {
    switch (view) {
      case "default":
        return (
          <>
            {!isLoggedIn && <LoginForm setView={setView} />}
            {isLoggedIn && !isAuthorized && !authenticating && (
              <OtpForm setView={setView} />
            )}
          </>
        )
      case "password_reset":
        return (
          <>
            <ResetPasswordForm
              resetEmail={resetEmail}
              setView={setView}
            />
          </>
        )
      case "request_access":
        return (
          <>
            <RequestAccessForm setView={setView} />
          </>
        )
      case "emergency_token":
        return (
          <>
            <EmergencyTokenForm setView={setView} />
          </>
        )

      default:
        return <div />
    }
  }

  return (
    <AuthPageWrapper>
      {renderView()}
    </AuthPageWrapper>
  )
}

export default Login
