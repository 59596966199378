/* eslint-disable import/no-mutable-exports */
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'
// import blue from "@material-ui/core/colors/blue";

export const aamui = {
  eggShell: '#82b2d9',
  highlightBlue: 'rgba(184, 214, 235, 0.2)',
  iceBlue: '#b8d6eb',
  oceanNavy: '#0a2839',
  redAccent: '#FF4D00',
  sapphire: '#3069ab',
  secondaryText: 'gray',
  skyBlue: '#5e94c7',
  stormBlue: '#427db8',
}

let theme = createTheme({
  aamui,
  breakpoints: {
    values: {
      desktop: 1200,
      lg: 1200,
      mobile: 0,
      sm: 600,
      tablet: 600,
      xl: 1200,
      xs: 0,
    },
  },
  overrides: {
    MuiAccordion: {
      expanded: {
        content: {
          borderBottom: 'none'
        }
      },
      root: {
        '&$expanded': {
          margin: 0
        },
        '&:last-child': {
          borderBottom: '1px solid #5E94C7',
          marginBottom: 0,
        },
        border: '1px solid #5E94C7',
        borderBottom: 'none',
        boxShadow: 'none',
        marginBottom: -1,
        width: '100%'
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: aamui.sapphire,
        borderRadius: 0,
        color: 'white',
        height: 48,
      },
      outlinedPrimary: {
        backgroundColor: 'white',
        borderColor: aamui.sapphire,
        borderRadius: 0,
        color: aamui.sapphire,
        height: 48,
        height: 48,
      },
      textPrimary: {
        borderRadius: 0,
        color: aamui.sapphire,
        height: 48,
      },
    },
    MuiButtonGroup: {
      root: {
        '& .Mui-disabled': {
          background: 'lightgray !important',
        },
        '& .MuiButton-containedPrimary': {
          background: `${aamui.sapphire} !important`,
          borderRadius: 0,
          color: 'white !important',
        },
        '& .MuiButtonBase-root': {
          background: 'none',
          border: `1px solid ${aamui.sapphire} !important`,
          borderRadius: 0,
          color: aamui.sapphire,
          fontWeight: 600,
          padding: '10px 20px',
        },
      },
    },
    MuiCard: {
      root: {
        border: '1px solid #B8D6EB',
        borderRadius: 0,
        boxShadow: 'none',
      },
    },
    MuiChip: {
      colorPrimary: {
        backgroundColor: aamui.sapphire,
      },
    },
    MuiCircularProgress: {
      circle: {
        borderRadius: 0,
        color: aamui.sapphire,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 0,
        padding: 24,
      },
    },
    MuiDialogActions: {
      root: {
        '& button': {
          textTransform: 'none !important',
        },
        marginTop: 24,
      },
    },
    MuiFormControl: {
      root: {
        '& .Mui-disabled': {
          backgroundColor: '#f3f3f3',
        },
      },
    },
    MuiFormLabel: {
      filled: {
        color: aamui.eggShell,
        transform: 'translate(0, -8px) scale(0.75) !important',
      },
      root: {
        '&$focused': {
          color: aamui.eggShell,
          transform: 'translate(0, -8px) scale(0.75)',
        },
        color: aamui.eggShell,
        fontSize: '16px',
        left: '16px !important',
        pointerEvents: 'none',
        top: '8px !important',
        zIndex: 100,
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginRight: 8,
      }
    },
    MuiInputBase: {
      multiline: {
        height: 'unset',
        minHeight: '48px',
      },
      root: {
        '& ::placeholder': {
          fontWeight: 'normal',
          opacity: '1 !important',
        },
        background: 'white',
        border: '1px solid #5E94C7',
        color: '#82B2D9 !important',
        fontWeight: 'bold',
        minHeight: '48px',
        paddingLeft: '16px !important',
      },
    },
    MuiInputLabel: {
      shrink: {
        color: aamui.eggShell,
        transform: 'translate(0, -8px) scale(0.75)',
      },
    },
    MuiMenuItem: {
      root: {
        color: '#82B2D9',
      },
    },
    MuiOutlinedInput: {
      '&$focused': {
        borderColor: '#5E94C7',
      },
      notchedOutline: {
        border: 'none !important',
      },
      root: {
        borderRadius: 0,
      },
    },
    MuiPaper: {
      root: {
        borderRadius: '0 !important',
      },
    },
    MuiSelect: {
      outlined: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#5E94C7',
        },
        borderColor: '#5E94C7',
      },
      root: {
        '&:focus': {
          backgroundColor: 'white !important',
        },
        borderColor: '#5E94C7',
        color: '#82B2D9',
        fontWeight: 600,
        minHeight: '48px',
        paddingLeft: 0,
      },
    },
    MuiTab: {
      root: {
        color: 'white !important',
        opacity: '1 !important',
        paddingLeft: '16px',
        paddingRight: '16px',
        textTransform: 'uppercase',
        zIndex: 10,
      },
    },
    MuiTableCell: {
      head: {
      },
      root: {
        borderBottom: 'none',
      },
      stickyHeader: {
        backgroundColor: aamui.iceBlue,
        color: aamui.oceanNavy,
      },
    },
    MuiTableHead: {
      root: {
        background: aamui.iceBlue,
      },
    },
    MuiTableRow: {
      root: {
        '&:hover': {
          backgroundColor: `${aamui.iceBlue} !important`,
        },
        '&:nth-of-type(even)': {
          backgroundColor: 'rgba(184, 214, 235, 0.2)',
        },
        border: 'none !important',
        height: 34,
        textDecoration: 'none',
      },
    },
    MuiTabs: {
      indicator: {
        background: aamui.oceanNavy,
        height: '100%',
        zIndex: 0,
      },
      root: {
        '& .Mui-selected': {
          color: 'white !important',
          transition: '225ms ease',
        },
        backgroundColor: aamui.sapphire,
        display: 'inline-flex',
        marginBottom: '12px',
        marginTop: '8px',
      },
    },
    MuiTooltip: {
      arrow: {
        color: aamui.sapphire,
      },
      tooltip: {
        backgroundColor: aamui.sapphire,
        borderRadius: 0,
        fontSize: 14,
        padding: 12,
      },
    },
  },
  palette: {
    aamui,
    primary: {
      contrastText: '#ffffff',
      dark: '#00273b',
      light: '#577b94',
      main: '#0e4466',
    },
    secondary: {
      contrastText: '#ffffff',
      dark: '#00335a',
      light: '#5389b7',
      main: '#1b5c87',
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButtonGroup: {
      disableElevation: true,
      disableRipple: true,
    },
    MuiTextField: {
      disableUnderline: true,
    },
  },
  typography: {
    fontFamily: ['Gotham A', 'Gotham B', 'sans-serif'],
  },
})

theme = responsiveFontSizes(theme)

export default theme
