import React from "react";
import { Calendar } from "@progress/kendo-react-dateinputs";

export default class MonthlyCalendar extends React.Component {
  render() {
    const { onChange, value } = this.props;
    return (
      <Calendar
        bottomView="year"
        topView="decade"
        value={value}
        onChange={onChange}
      />
    );
  }
}
