import { Box } from '@material-ui/core'
import DashboardBlock from '../../components/misc/DashboardBlock'
import InsightPreviewCard from '../../components/misc/InsightPreviewCard'
import SkelatonTextLoader from '../../components/skeletons/SkelatonTextLoader'
import { BASE_ROUTE_TYPES } from '../../constants/routes.constants'
import useInsights from '../../hooks/useInsights'
import { useRouteType } from '../../hooks/useIsPeRoutes'

const InsightsPane = ({ type }) => {
  const routeType = useRouteType()
  const {
    error,
    isLoadingLegacyInsights,
    isLoadingHsInsights,
    sortedInsights,
  } = useInsights({ type, maxSlice: 3 })
  const isLoadingStory = isLoadingLegacyInsights || isLoadingHsInsights
  const viewMoreLink =
    type === BASE_ROUTE_TYPES.PRIVATE_MARKET
      ? '/private-markets/insights'
      : '/insights'

  return (
    <DashboardBlock
      header="Insights"
      viewAllLink={viewMoreLink}
      viewAllMpPayload={{
        eventName: 'Click View All',
        properties: {
          'Route Type': routeType,
          'Block Name': 'Private Market Insights Pane',
        },
      }}
    >
      {error || isLoadingStory ? (
        <Box display="flex" flexDirection="column" py={2}>
          {Array(3)
            .fill(null)
            .map((_, index) => (
              <SkelatonTextLoader
                className="insights-card-link insights-card-link-homepage"
                key={index}
                my={2}
                rows={3}
              />
            ))}
        </Box>
      ) : (
        <>
          {sortedInsights?.map((story, idx) => (
            <InsightPreviewCard
              {...story}
              isLegacy={!!story.as_of_date}
              key={idx}
              listView
            />
          ))}
        </>
      )}
    </DashboardBlock>
  )
}

export default InsightsPane
