import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"

import { factsheetNotificationFetched } from "../redux/docsNotificationSlice"
import { getFactsheetAvailability } from "../services"
import { convertSnakeToCamelCase } from "../utils/convertSnakeToCamelCase"
import { useAppDispatch, useAppSelector } from "./useRedux"

const useFetchFactSheetNotifications = () => {
  const dispatch = useAppDispatch()
  const factsheetNotification = useAppSelector((state) => state.docsNotification.factSheetNotification)
  const { data } = useQuery(
    ['notifications', 'latest', 'factsheets'],
    () => getFactsheetAvailability(),
    { select: res => convertSnakeToCamelCase(res?.data?.[0]) }
  )

  useEffect(() => {
    if (data) {
      console.log('data', data)
      dispatch(factsheetNotificationFetched(data))
    }
  }, [data])

  return factsheetNotification
}

export default useFetchFactSheetNotifications