import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit"

import { defaultDocsNotiPageSize } from "../hooks/useFetchDocsNotifications"
import { useAppSelector } from "../hooks/useRedux"
import IDocumentNotification from "../models/IDocumentNotification"

export const docsNotificationAdapter = createEntityAdapter<IDocumentNotification>()

export enum DocsNotiSelectionMode {
  SELECT_ALL = 'SELECT_ALL',
  SELECT_CURRENT_PAGE = 'SELECT_CURRENT_PAGE',
  SELECT_PARTIAL_CURRENT_PAGE = 'SELECT_PARTIAL_CURRENT_PAGE',
  SELECT_NONE = 'SELECT_NONE'
}

interface DocsNotificationState extends EntityState<IDocumentNotification, number> {
  selectedDocsNotifications: Record<number, boolean>
  notificationCount: number
  selectionMode?: DocsNotiSelectionMode | null
  togglingAllNotifications?: boolean
  factSheetNotification?: {
    latestDate: Date
    accessGroup: string
  }
}

const initialState: DocsNotificationState = {
  factSheetNotification: undefined,
  notificationCount: 0,
  selectedDocsNotifications: {},
  selectionMode: DocsNotiSelectionMode.SELECT_NONE,
  togglingAllNotifications: false,
  ...docsNotificationAdapter.getInitialState()
}

export const docsNotificationSlice = createSlice({
  initialState,
  name: 'docsNotification',
  reducers: {
    allNotificationsReadStatusToggled: (state) => {
      state.togglingAllNotifications = true
    },
    docsNotiSelectReset: (state) => {
      state.selectedDocsNotifications = {}
      state.selectionMode = DocsNotiSelectionMode.SELECT_NONE
    },
    docsNotificationPageChanged: (state) => {
      state.selectedDocsNotifications = {}
      state.selectionMode = DocsNotiSelectionMode.SELECT_NONE
    },
    docsNotificationSelectToggled: (state, action) => {
      const { id } = action.payload
      state.selectedDocsNotifications[id] = !state.selectedDocsNotifications[id]

      const selectedCount = Object.values(state.selectedDocsNotifications).filter(it => it).length
      console.log('selectedCountselectedCount', selectedCount)
      if (selectedCount) {
        state.selectionMode = selectedCount === defaultDocsNotiPageSize ? DocsNotiSelectionMode.SELECT_CURRENT_PAGE : DocsNotiSelectionMode.SELECT_PARTIAL_CURRENT_PAGE
      } else {
        state.selectionMode = DocsNotiSelectionMode.SELECT_NONE
      }
    },
    docsNotificationsFetched: (state, action) => {
      const { notificationCount, notifications } = action.payload
      docsNotificationAdapter.setAll(state, notifications)
      state.notificationCount = notificationCount
    },
    factSheetNotificationDismissed: (state) => {
      state.factSheetNotification = undefined
    },
    factsheetNotificationFetched: (state, action: PayloadAction<IDocumentNotification>) => {
      state.factSheetNotification = action.payload
    },
    notificationDocsDownloaded: (state, action: PayloadAction<number>) => {
      docsNotificationAdapter.updateOne(state, { changes: { read: true }, id: action.payload })
    },
    selectAllNotificationsToggled: (state) => {
      if (state.selectionMode === DocsNotiSelectionMode.SELECT_ALL) {
        state.selectionMode = DocsNotiSelectionMode.SELECT_NONE

        state.ids.forEach((id) => {
          state.selectedDocsNotifications[id] = false
        })
      } else {
        state.selectionMode = DocsNotiSelectionMode.SELECT_ALL
      }
    },
    selectCurrentPageDocsNotiToggled: (state) => {
      if (state.selectionMode !== DocsNotiSelectionMode.SELECT_NONE) {
        state.selectionMode = DocsNotiSelectionMode.SELECT_NONE
        state.ids.forEach((id) => {
          state.selectedDocsNotifications[id] = false
        })
      } else {
        state.selectionMode = DocsNotiSelectionMode.SELECT_CURRENT_PAGE
        state.ids.forEach((id) => {
          state.selectedDocsNotifications[id] = true
        })
      }
    },
    selectedDocsNotiDismissed: (state) => {
      state.selectionMode = DocsNotiSelectionMode.SELECT_NONE
      state.selectedDocsNotifications = {}
      state.togglingAllNotifications = false
    }
  }
})

export const {
  selectAll: selectCurrentPageDocsNotifications,
  selectById: selectDocsNotificationById,
  selectEntities: selectDocsNotificationEntities,
  selectIds: selectDocsNotificationIds
} = docsNotificationAdapter.getSelectors((state: any) => state.docsNotification)

export const {
  allNotificationsReadStatusToggled,
  docsNotiSelectReset,
  docsNotificationPageChanged,
  docsNotificationSelectToggled,
  docsNotificationsFetched,
  factSheetNotificationDismissed,
  factsheetNotificationFetched,
  notificationDocsDownloaded,
  selectAllNotificationsToggled,
  selectCurrentPageDocsNotiToggled,
  selectedDocsNotiDismissed
} = docsNotificationSlice.actions

const docsNotificationReducer = docsNotificationSlice.reducer

export const useDocsNotiIds = () => useAppSelector(selectDocsNotificationIds)
export const useDocsNotiEntities = () => useAppSelector(selectDocsNotificationEntities)
export const useDocsNotiCount = () => useAppSelector((state) => state.docsNotification.notificationCount)
export const useSelectedDocsNotifications = () => useAppSelector((state) => state.docsNotification.selectedDocsNotifications)
export const useSelectionMode = () => {
  const seletctionMode = useAppSelector((state) => state.docsNotification.selectionMode)
  return ({
    intermediateCurrentPage: seletctionMode === DocsNotiSelectionMode.SELECT_PARTIAL_CURRENT_PAGE,
    selectAll: seletctionMode === DocsNotiSelectionMode.SELECT_ALL,
    selectCurrentPage: seletctionMode === DocsNotiSelectionMode.SELECT_CURRENT_PAGE,
    selectNone: seletctionMode === DocsNotiSelectionMode.SELECT_NONE
  })
}

export default docsNotificationReducer