import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useHistory, useLocation } from "react-router-dom";
import { titleCase } from "../../utils";
import { usePrivateEquityFunds, useSecurities, } from "../../hooks";
import { FundsContext } from "../../contexts";
import useIsPeRoutes from "../../hooks/useIsPeRoutes";
import { HEDGE_FUND_PATH, PRIVATE_MARKETS_PATH } from "../../constants/routes.constants";
import { AAM_HEDGE_FUND_NAME, AAM_PRIVATE_MARKET_NAME } from "../../constants/service.constants";

export default function PageHeader() {
  const location = useLocation();
  const { pathname } = location;
  const { search_list } = useSecurities();
  const { storyData } = useContext(FundsContext);
  const { data: privateEquityFunds, isLoading } = usePrivateEquityFunds()
  const isPeRoutes = useIsPeRoutes()
  const rootPath = isPeRoutes ? PRIVATE_MARKETS_PATH : HEDGE_FUND_PATH

  const getPath = (pathName) => {
    let pathNames = [isPeRoutes ? AAM_PRIVATE_MARKET_NAME : AAM_HEDGE_FUND_NAME]
    const paths = pathName.split("/").filter((path) => path);
    const hasTwoPaths = paths.length >= 2;
    pathNames = [...pathNames, ...paths]
    return {
      pathNames,
      hasTwoPaths
    }
  }

  const getPathParams = (paths) => {
    let pathParams = []
    let parentPath
    let fundName
    let securityType
    if (paths[1] === "asset-detail") {
      const fund = search_list.find(fundItem => fundItem.id === Number(paths[2]))

      if (fund) {
        fundName = fund.name
        securityType = fund.type
        parentPath = 'screener'
        if (securityType === 'Portfolio') {
          parentPath = 'my-portfolios'
        }
        pathParams = [paths[0], parentPath, fundName]
      }

    } else if (paths[1] === "private-markets" && !isLoading) {
      const privateEquityFundName = privateEquityFunds.find(it => String(it.id) === paths[3])?.name
      parentPath = 'private-markets'
      pathParams = [paths[0], { path: parentPath + '/' + paths[2], pathName: paths[2] }, privateEquityFundName]
    } else if (paths[1] === "insights") {
      if (storyData && Object.keys(storyData).length > 1) {
        const storyName = storyData.name
        pathParams = [paths[0], "insights", storyName]

      }
    } else if (paths[1] === 'portfolio-optimiser') {
      pathParams = [paths[0], 'portfolio-optimiser']
    }
    return pathParams
  }

  const handleTwoPaths = (paths, hasTwoPaths) => {
    let pathParams
    if (hasTwoPaths) {
      pathParams = getPathParams(paths)
    } else {
      if (paths.length < 2) {
        pathParams = [paths[0]]
      } else {
        pathParams = [paths[0], titleCase(paths[1])]
      }
    }
    return pathParams
  }

  const pathObj = getPath(pathname)
  const pathParams = handleTwoPaths(pathObj.pathNames, pathObj.hasTwoPaths)
  const parentPath = pathParams[0]
  let nestedPath
  let itemPath
  let formattedPath
  if (pathParams.length > 1) {
    nestedPath = typeof pathParams[1] === 'string' ? pathParams[1] : pathParams[1].path
    formattedPath = titleCase((typeof pathParams[1] === 'string' ? pathParams[1] : pathParams[1].pathName).replace("-", " "))
    itemPath = pathParams[2]
  }

  return (
    <div className="page-header">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={rootPath}>
          <Typography className="breadcrumb-link" variant="h6">
            {parentPath}
          </Typography>
        </Link>
        {nestedPath ?
          <Link to={`/${nestedPath}`}>
            <Typography variant="h6">
              {formattedPath}
            </Typography>
          </Link> : ''}
        {itemPath ? <Typography variant="h6">
          {itemPath}
        </Typography> : ''}
      </Breadcrumbs>
    </div>
  );
}
