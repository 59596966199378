import React, { useState, useEffect } from "react";
import Grid from "./groupGrid";
import Manager from "./groupManager";
import { sortBy } from "lodash";
import { getGroups, updateGroups } from "../../services";
import { Box } from "@material-ui/core";

export default function Groups() {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupList, setGroupList] = useState([]);

  const getGroupList = async () => {
    try {
      const response = await getGroups();
      const sortedGroups = sortBy(response.data, "name");
      setGroupList(sortedGroups);
    } catch (error) { }
  };

  useEffect(() => {
    getGroupList();
  }, []);

  return (
    <Box display="flex">
      <div className="tables-container">
        <Grid
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          groupList={groupList}
          setGroupList={setGroupList}
          updateGroups={updateGroups}
        />
      </div>
      <div className="group-manager">
        <Manager
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          groupList={groupList}
          setGroupList={setGroupList}
        />
      </div>
    </Box>
  );
}
