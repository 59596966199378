// hooks/useDeletePeerGroupFunds.ts

import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import useUpdatePeerGroup, { useUpdatePeerGroupNotification } from '../../hooks/useUpdatePeerGroup'
import { useAppSelector } from '../../hooks/useRedux'
import { PeerGroup } from '../../redux/peerGroupsSlice'

export const useDeletePeerGroupFunds = (isFundScrenner: boolean = false) => {
  const [isLoading, setIsLoading] = useState(false)
  const { updatePeerGroupInfo } = useUpdatePeerGroup()
  const { showNotification } = useUpdatePeerGroupNotification()
  const selectedViewPeerGroup = useAppSelector(state => state.peerGroups.selectedGroupId && state.peerGroups.entities[state.peerGroups.selectedGroupId])
  const selectedScreenerPeerGroup = useAppSelector(state => state.peerGroups.fundScreenerFilters.peerGroup && state.peerGroups.entities[state.peerGroups.fundScreenerFilters.peerGroup])
  const selectedPeerGroup = isFundScrenner ? selectedScreenerPeerGroup : selectedViewPeerGroup

  const deleteFunds = async (rowSelection: Record<string, boolean> | number[]) => {
    if (!selectedPeerGroup) return

    setIsLoading(true)
    try {
      let selectedFunds: Record<string, boolean> = {}
      if (Array.isArray(rowSelection)) {
        selectedFunds = rowSelection.reduce((acc, fundId) => {
          acc[fundId] = true
          return acc
        }, {})
      } else {
        selectedFunds = rowSelection
      }

      const updatedFunds = selectedPeerGroup.funds.filter(fund => !selectedFunds[fund.fundId])
      const numberOfFundsRemoved = selectedPeerGroup.funds.length - updatedFunds.length
      const updatedGroup = {
        ...selectedPeerGroup,
        funds: updatedFunds
      }
      await updatePeerGroupInfo(updatedGroup)
      showNotification({
        groupName: selectedPeerGroup.name,
        message: `${numberOfFundsRemoved} fund(s) has been removed from the group.`
      })
    } catch (err) {
      // Handle error (you might want to show an error notification here)
    } finally {
      setIsLoading(false)
    }
  }

  return { deleteFunds, isDeleting: isLoading }
}