import { Box } from '@material-ui/core'
import React, { useEffect } from 'react'

import { useMixpanel } from '../../hooks/useMixpanel'

export const TabContentContainer = ({
  children,
  pageName,
  properties,
  tabName,
  tabsType,
}) => {
  const { mpTrack } = useMixpanel()

  useEffect(() => {
    if (pageName && tabName) {
      mpTrack({
        eventName: `View ${tabName} Tab on ${pageName}`,
        properties: {
          'Tab Name': tabName,
          'View Type': `Tab on ${tabsType || pageName}`,
          ...properties
        }
      })
    }
  }, [mpTrack, pageName, tabName])

  return (
    <Box mt={2}>
      {children}
    </Box>
  )
}