import { del, get, post } from "./api"

export const getInternalReport = (payload) => {
  const { responseType = "json", ...rest_payload } = payload
  return post("/api/v1/internal-report/", rest_payload, "", { responseType })
}

export const getDocuments = (payload) => get("/api/v1/stories/", payload)
export const getAaapDocuments = (payload) => {
  const { security } = payload
  return get(`/api/v1/aaap-documents/${security}/docs/`, payload)
}
export const getSingleDocument = (id) => get(`/api/v1/stories/${id}/`)

export const downloadWatermarked = (payload) => {
  const { id, ...rest } = payload
  return get(`/api/v1/news/${id}/watermarked/`, rest, { responseType: "blob" })
}

export const downloadAaapDocument = (payload) => {
  const { id, ...rest } = payload
  return get(`/api/v1/aaap-documents/${id}/download/`, rest, {
    responseType: "blob",
  })
}

export const exportFundInfoDocs = (payload) => post('/api/v1/aaap-documents/export_fund_info/', payload, undefined, { responseType: "blob" })

export const emailDocument = (payload) => {
  const { id, ...rest } = payload
  return get(`/api/v1/stories/${id}/notify/`, rest)
}

export const emailNDARequest = (payload) => {
  const { id, ...rest } = payload
  return post("/api/v1/stories/notify_nda/", rest)
}

export const getDocumentType = (payload) =>
  get("/api/v1/story-types/", payload)
export const updateDocument = (payload) => post("/api/v1/stories/", payload)
export const deleteDocument = (payload) => del("/api/v1/stories/", payload)
export const getNotificationStoryTypes = () => get("/api/v1/stories/notifications/story-types/")

export const getNews = () => get("/api/v1/news/")
export const getExposureAnalysis = (payload) =>
  post("/api/v1/exposure-report/", payload)

export const getExternalReport = (payload) =>
  post("/api/v1/external-report/", payload)

export const getRiskReport = (payload) => post("/api/v1/risk-report/", payload)

export const getPerformanceOverview = (payload) =>
  get("/api/v1/performance-overview/", payload)
export const getMarketOverview = (payload) =>
  get("/api/v1/market-overview/", payload)
export const getMarketDataSeries = (payload) =>
  get(`/api/v1/market-data/${payload.id}/`)

export const getAlternativesOverview = (payload) =>
  get("/api/v1/alternatives-overview/", payload)

export const getPortfolioAnalysis = (payload) => {
  const { id, ...rest } = payload
  return get(`/api/v1/portfolio/${id}/analysis/`, rest)
}
