import React, { useState, useEffect } from "react";
import { statefulTableGenerator, MonthlyDatePicker } from "../../components";
import {
  Grid as KGrid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { getAumHistory, getAumHistoryExcel } from "../../services";
import { formatNumber } from "@telerik/kendo-intl";
import { dateTimeManager as dt } from "../../utils";
import Button from "@material-ui/core/Button";
import { buildFileFromResponse } from "../../utils";
import moment from "moment";

const SuperGrid = statefulTableGenerator(KGrid);

export default function PlatformAumContainer() {
  const [expandedFields, setExpandedFields] = useState([]);
  const [aumHistory, setAumHistory] = useState([]);
  const [aumDate, setAumDate] = useState(dt.previousMonthEnd());

  const generateExcel = async () => {
    try {
      const asOfDate = dt.jsToDb(aumDate);
      const response = await getAumHistoryExcel({ date: asOfDate });
      buildFileFromResponse(response);
    } catch (error) {}
  };

  useEffect(() => {
    const fetchAum = async () => {
      const asOfDate = dt.jsToDb(aumDate);
      try {
        const response = await getAumHistory({ date: asOfDate });
        const non_aaap = ["Fund of Funds", "Advisory"];
        let { data } = response;
        const class_pattern = /Class [A-Z].+/;
        let mapped_data = data.map((o) => {
          const { security_name, security_type } = o || {};
          let category =
            non_aaap.indexOf(security_type) === -1 ? "AAAP" : "FoF";
          let regex_class = class_pattern.exec(security_name);
          let class_name = regex_class ? regex_class[0] : null;
          const shown_name = class_name ? class_name : security_name;
          return {
            ...o,
            security_name: shown_name,
            security_type,
            category,
            expanded: false,
          };
        });
        setAumHistory(mapped_data);
      } catch (error) {}
    };
    fetchAum();
  }, [aumDate]);

  const formattedNames = {
    security_name: "Fund",
    final_nav: `Closing NAV (${moment(aumDate).format("DD MMM")})`,
    subs: "Subs",
    reds: "Reds",
    next_open_nav: `Opening NAV (${moment(aumDate)
      .add(1, "d")
      .format("DD MMM")})`,
    final_cross_investment: "CI (Closing)",
    next_open_cross_investment: "CI (Opening)",
  };
  const colNames = Object.keys(formattedNames);

  const agg_fields = [
    "final_nav",
    "subs",
    "reds",
    "next_open_nav",
    "final_cross_investment",
    "next_open_cross_investment",
  ];
  let aggregates = agg_fields.map((field) => ({ field, aggregate: "sum" }));
  let field_length = agg_fields.length;
  const state_vars = {
    filter: undefined,
    group: [
      { field: "category", aggregates },
      { field: "security_type", aggregates },
    ],
    skip: 0,
    sort: undefined,
    take: undefined,
  };

  const cellRender = (td, cellProps) => {
    const { rowType, field } = cellProps || {};

    if (rowType !== "groupHeader") {
      return td;
    }
    if (agg_fields.indexOf(field) !== -1) {
      const { dataItem } = cellProps || {};
      const { aggregates } = dataItem || {};
      const { [field]: value } = aggregates || {};
      const { sum } = value || {};
      const formatted_sum = formatNumber(sum, "n0");
      return <td>{formatted_sum}</td>;
    } else if (td && td.props.children) {
      const { colSpan } = td.props;
      let children = <span>{td.props.children.props.children}</span>;

      return React.cloneElement(
        td,
        { ...td.props, colSpan: colSpan - field_length },
        children
      );
    } else {
      return td;
    }
  };

  const handleExpand = ({ dataItem, target, value, ...rest }) => {
    const { props } = target || {};
    const { expandField } = props || {};
    setExpandedFields([...expandedFields, { dataItem, expandField, value }]);
  };

  return (
    <div className="aum-grid-container">
      <SuperGrid
        data={aumHistory}
        filterable={false}
        // groupable={{ enabled: true, footer: "visible" }}
        scrollable="scrollable"
        sortable={false}
        style={{ height: "75vh" }}
        initial_state={state_vars}
        aggregateFields={aggregates}
        expandedFields={expandedFields}
        cellRender={cellRender}
        expandField="expanded"
        onExpandChange={handleExpand}
        className="aum-grid"
        collapseAll
      >
        <GridToolbar>
          <MonthlyDatePicker
            value={aumDate}
            onChange={(e) => {
              setAumDate(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={generateExcel}
            style={{ marginLeft: "16px" }}
          >
            Export
          </Button>
        </GridToolbar>
        {colNames.map((field, idx) => (
          <Column
            key={idx}
            field={field}
            title={formattedNames[field]}
            format="{0:n0}"
            width={idx === 0 ? "350px" : "none"}
          />
        ))}
      </SuperGrid>
    </div>
  );
}
