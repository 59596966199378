import useAccessRights from '../../hooks/useAccessRights'
import PerformanceTable from './performanceOverviewTable'

export default function PerformanceOverview({ ...props }) {
  const { hasAccessInternalClient } = useAccessRights()
  return (
    <div>
      <PerformanceTable
        {...props}
        hasAccessInternalClient={hasAccessInternalClient}
        mode="performance"
      />
    </div>
  )
}
