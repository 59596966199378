import React from "react";
import { DocumentGrid } from "../researchManagement";

export default function ResearchManagement() {
  return (
    <div className="research-management">
      <DocumentGrid />
    </div>
  );
}
