import { Box, Typography } from '@material-ui/core'
import React from 'react'

const LabelValueDisplay = ({
  label,
  value,
  ...rest
}) => (
  <Box {...rest}>
    <Typography variant="caption">
      {label}
    </Typography>
    <Typography variant="subtitle2">
      {value}
    </Typography>
  </Box>
)

export default LabelValueDisplay