import React from 'react'
import {
  CountryInvolvedSelect,
  DateInput,
  DaysBeforeInput,
  NumberOfPeriodsInput,
  SubscriptionFrequencySelect
} from './TradingCalendarFormInputs'
import { Box } from '@material-ui/core'

const TradingCalendarByCustomDateForm = () => {
  return (
    <>
      <Box
        display="flex"
        gridGap={8}
      >
        <SubscriptionFrequencySelect />
        <DateInput isStartDate />
        <DaysBeforeInput />
      </Box>
      <NumberOfPeriodsInput />
    </>
  )
}

export default TradingCalendarByCustomDateForm
