import React from "react";
import ReportSnippets from "../../utils/reportInputSnippets";
import {
  exposure_map_categories,
  original_exposure_categories,
} from "../../data/dataManagement";

export default function ExposureMapInput({
  exposure_key_map_inputs,
  original_classifications,
  mapped_classifications,
  updateInputs,
  getExposures,
  ...props
}) {
  const reportSnippets = new ReportSnippets({
    ...props,
    data: exposure_key_map_inputs,
    updateInputs: updateInputs,
    submitFunction: getExposures,
  });

  return (
    <div className="security-setup-container">
      {reportSnippets.globalSearch("security", "Fund")}
      {reportSnippets.buttonGroupSelector(
        "category",
        original_exposure_categories
      )}
      {reportSnippets.buttonGroupSelector(
        "target_category",
        exposure_map_categories
      )}
      {reportSnippets.dropdownSelector(
        "original_classification",
        "Classification",
        original_classifications,
        {
          dataItemKey: "classification",
          textField: "classification",
          defaultItem: { classification: "Original classification..." },
        }
      )}
      {reportSnippets.dropdownSelector(
        "mapped_classification",
        "Mapped Classification",
        mapped_classifications,
        { defaultItem: "Original classification..." }
      )}
      {reportSnippets.submitButton("Get map")}
    </div>
  );
}
