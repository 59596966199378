import { useQuery } from '@tanstack/react-query'
import { useEffect } from "react"

import { peerGroupsFetched, selectAllPeerGroups } from "../redux/peerGroupsSlice"
import { getPeerGroups } from "../services/peer-groups"
import { convertSnakeToCamelCase } from "../utils/convertSnakeToCamelCase"
import { useAppDispatch, useAppSelector } from "./useRedux"

export const useFetchPeerGroups = (enableFetched: boolean = false) => {
  const peerGroups = useAppSelector(selectAllPeerGroups)
  const fundPeerGroupMap = useAppSelector(state => state.peerGroups.fundPeerGroupMap)
  const dispatch = useAppDispatch()
  const {
    data,
    error,
    isLoading
  } = useQuery(
    ['peer-groups'],
    () => getPeerGroups(),
    { enabled: enableFetched, select: (res) => res?.data }
  )

  useEffect(() => {
    if (data) {
      dispatch(peerGroupsFetched(convertSnakeToCamelCase(data)))
    }
  }, [data])

  return {
    fundPeerGroupMap,
    isLoadingPeerGroups: isLoading,
    peerGroups
  }
}