import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import { aamui } from '../../styles/AppTheme'

export const EMPTY_DISPLAY = '-'
export const RUN_STATUS_COLOR_MAP = {
  CANCELLED: 'lightgrey',
  COMPLETE: '#50e3c2',
  CREATED: 'lightgrey',
  FAILED: 'red',
  RUNNING: '#f5a623'
}
export const RUN_STATUS_OPTIONS = Object.keys(RUN_STATUS_COLOR_MAP)
export const VISIBILITY_ICON_MAP = {
  'Internal Private': <VisibilityOffOutlinedIcon
    fontSize="small"
    htmlColor="gray"
  />,
  'Internal Public': <VisibilityOutlinedIcon
    fontSize="small"
    htmlColor={aamui.sapphire}
  />,
}

export const getConvergedIcon = (converged) => {
  if (converged === undefined) {
    return (
      <ErrorOutlineOutlinedIcon
        fontSize="small"
        htmlColor={RUN_STATUS_COLOR_MAP.FAILED}
      />
    )
  }

  return converged ? (
    <CheckCircleOutlineOutlinedIcon
      fontSize="small"
      htmlColor={RUN_STATUS_COLOR_MAP.COMPLETE}
    />
  ) : (
    <CancelOutlinedIcon
      fontSize="small"
      htmlColor="gray"
    />
  )
}
