import { Box, BoxProps, Button } from '@material-ui/core'
import React from 'react'
import { toast } from 'react-toastify'

import { useMixpanel } from '../../hooks/useMixpanel'

interface DownloadButtonProps extends BoxProps {
  fileName?: string
  fetchFunction?: () => Promise<Response>
  fileType?: string
}

const DownloadButton: React.FC<React.PropsWithChildren<DownloadButtonProps>> =
  ({
    children,
    fetchFunction,
    fileName = 'download.csv',
    fileType = 'text/csv;charset=utf-8;',
    ...boxProps
  }) => {
    const { mpTrack } = useMixpanel()
    const [loading, setLoading] = React.useState(false)
    const handleDownload = async () => {
      try {
        if (!fetchFunction) return
        setLoading(true)

        const downloadData = await fetchFunction()

        const blob = new Blob([downloadData], { type: fileType })

        const link = document.createElement('a')
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
        mpTrack({ eventName: 'Downloaded file', properties: { fileName } })
      } catch (error) {
        toast.error('Failed to download file')
      } finally {
        setLoading(false)
      }
    }

    return (
      <Box {...boxProps}>
        <Button
          color="primary"
          disabled={loading}
          onClick={handleDownload}
          variant="contained"
        >
          {children}
        </Button>
      </Box>
    )
  }

export default DownloadButton
