import { useQuery } from "@tanstack/react-query"

import { getPeerGroupFunds } from "../services/peer-groups"
import { convertSnakeToCamelCase } from "../utils/convertSnakeToCamelCase"

const usePeerGroupFunds = (ordering: string = '-name', page: number | null = null, pagesize: number | null = null, filters: Record<string, any> = {}) => {
  return useQuery(
    ['peerGroupFundInfo', ordering, page, pagesize, filters],
    () => getPeerGroupFunds({ ordering, page, pagesize, ...filters }),
    {
      select: (response) => convertSnakeToCamelCase(response)
    }
  )
}

export default usePeerGroupFunds
