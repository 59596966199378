import { PRIVATE_MARKET_BASE_PATH } from '../constants/routes.constants'
import { AAM_HEDGE_FUND_NAME, AAM_PRIVATE_MARKET_NAME } from '../constants/service.constants'
import { AntarcticaPrivate } from '../containers/dashboard'
import LandingPane from '../containers/dashboard/LandingPane'
import DocumentNotificationsPage from '../containers/DocumentNotifications/DocumentNotificationsPage'
import FactSheetsPage from '../containers/fact-sheets/FactSheetsPage'
import FactSheetUserGuide from '../containers/fact-sheets/FactSheetUserGuidePage'
import HedgeFundsHomePage from '../containers/HedgeFundsHomePage/HedgeFundsHomePage'
import PeerGroupPage from '../containers/PeerGroups/PeerGroupPage'
import PortfolioOptimiserListPage from '../containers/portfolioOptimiser/PortfolioOptimiserListPage'
import PortfolioOptimiserPage from '../containers/portfolioOptimiser/PortfolioOptimiserPage'
import PrivateMarketHomePage from '../containers/PrivateMarketHomePage/PrivateMarketHomePage'
import {
  AssetDetail,
  AssetDetailPrivateEquity,
  CashManagement,
  DataManagement,
  Diagnostics,
  EvaluationsOverview,
  ExposureAnalysis,
  Flows,
  FundsOfFunds,
  InsightPage,
  InvestorRelations,
  ManagerHoldings,
  Monitors,
  NewStory,
  PerformanceOverview,
  PlatformAum,
  PortfolioAnalysis,
  PortfolioBuilder,
  Portfolios,
  QuickAnalysis,
  ResearchManagement,
  RiskReport,
  Sandbox,
  Screener,
  Statistics,
  Stories,
  SubsRedsCalendar,
  TotalExpense,
  TotalExpenseRatio,
  TrackingError,
  UserActivity,
  UserGroups,
  UserProfile,
  Users,
} from '../containers/routes'
import TradingCalendarPage from '../containers/tradingCalendar/TradingCalendarPage'

const privateEquityRoutes = [
  {
    component: PrivateMarketHomePage,
    exact: true,
    path: PRIVATE_MARKET_BASE_PATH,
    title: AAM_PRIVATE_MARKET_NAME,
  },
  {
    component: Stories,
    exact: true,
    navPath: true,
    path: `${PRIVATE_MARKET_BASE_PATH}/insights`,
    title: 'Insights',
  },
  {
    component: AntarcticaPrivate,
    exact: true,
    path: `${PRIVATE_MARKET_BASE_PATH}/funds`,
  },
  {
    component: AssetDetailPrivateEquity,
    navPath: true,
    path: `${PRIVATE_MARKET_BASE_PATH}/funds/:fund_id`,
    title: 'Asset Detail Private Equity',
  },
  {
    component: DocumentNotificationsPage,
    exact: true,
    path: `${PRIVATE_MARKET_BASE_PATH}/document-notifications`,
    title: 'Document Notifications'
  }
]

const aaap_routes = [
  {
    component: LandingPane,
    exact: true,
    path: '/',
  },
  {
    component: HedgeFundsHomePage,
    exact: true,
    path: '/hedge-funds',
    title: AAM_HEDGE_FUND_NAME,
  },
  {
    component: Screener,
    exact: true,
    navPath: true,
    path: '/screener',
    title: 'Fund Screener',
  },
  {
    component: QuickAnalysis,
    navPath: true,
    path: '/quick-analysis',
    title: 'Quick Analysis',
  },
  {
    component: PortfolioBuilder,
    navPath: true,
    path: '/screener/portfolio-builder',
    title: 'Portfolio Builder',
  },
  {
    component: FactSheetUserGuide,
    navPath: true,
    path: '/fact-sheets/user-guide',
    title: 'Fact Sheets User Guide',
  },
  {
    component: FactSheetsPage,
    navPath: true,
    path: '/fact-sheets',
    title: 'Fact Sheets',
  },
  {
    component: InsightPage,
    exact: true,
    navPath: true,
    path: '/insights/:story_id',
    title: 'Insights',
  },
  {
    component: Stories,
    navPath: true,
    path: '/insights',
    title: 'Insights',
  },
  {
    component: Portfolios,
    navPath: true,
    path: '/my-portfolios',
    title: 'My Portfolio',
  },
  {
    component: FundsOfFunds,
    navPath: true,
    path: '/antarctica-fofs',
    title: 'Antarctica FoFs',
  },
  {
    component: AssetDetail,
    navPath: true,
    path: '/asset-detail/:fund_id',
    title: 'Asset Detail',
  },
  {
    component: UserProfile,
    navPath: true,
    path: '/me',
    title: 'Settings',
  },
  {
    component: PerformanceOverview,
    exact: true,
    path: '/performance-overview',
    title: 'Performance Overview',
  },
  {
    component: DocumentNotificationsPage,
    exact: true,
    path: '/document-notifications',
    title: 'Document Notifications'
  }
]

const user_management_routes = [
  {
    component: Users,
    navPath: true,
    path: '/users',
    title: 'User Management',
  },
  {
    component: UserGroups,
    navPath: true,
    path: '/groups',
    title: 'Groups & Lists',
  },
  {
    component: UserActivity,
    navPath: true,
    path: '/activity',
    title: 'Activity Analytics',
  },
  {
    component: Users,
    navPath: true,
    path: '/private-markets/users',
    title: 'User Management',
  },
  {
    component: UserGroups,
    navPath: true,
    path: '/private-markets/groups',
    title: 'Groups & Lists',
  },
  {
    component: UserActivity,
    navPath: true,
    path: '/private-markets/activity',
    title: 'Activity Analytics',
  },
]

const analytics_routes = [
  {
    component: PeerGroupPage,
    exact: true,
    navPath: true,
    path: '/peer-groups',
    title: 'Peer Groups'
  },
  {
    component: Statistics,
    navPath: true,
    path: '/statistics',
    title: 'Statistical Analysis',
  },
  {
    component: ResearchManagement,
    navPath: true,
    path: '/document-management',
    title: 'Document Management',
  },
  {
    component: RiskReport,
    navPath: true,
    path: '/risks',
    title: 'Risks',
  },
  {
    component: Monitors,
    navPath: true,
    path: '/index-monitors',
    title: 'Index Monitors',
  },
  {
    component: ExposureAnalysis,
    navPath: true,
    path: '/exposure-analysis',
    title: 'Exposure Analysis',
  },
  {
    component: PortfolioAnalysis,
    navPath: true,
    path: '/portfolio-analysis',
    title: 'Portfolio Analysis',
  },
  {
    component: ResearchManagement,
    navPath: true,
    path: '/private-markets/document-management',
    title: 'Document Management',
  },
  {
    component: PortfolioOptimiserPage,
    exact: true,
    navPath: true,
    path: '/portfolio-optimiser/new',
    title: 'Portfolio Optimiser',
  },
  {
    component: PortfolioOptimiserPage,
    exact: true,
    navPath: true,
    path: '/portfolio-optimiser/runs/:runId',
    title: 'Portfolio Optimiser',
  },
  {
    component: PortfolioOptimiserPage,
    navPath: true,
    path: '/portfolio-optimiser/:runId',
    title: 'Portfolio Optimiser',
  },
  {
    component: PortfolioOptimiserListPage,
    navPath: true,
    path: '/portfolio-optimiser',
    title: 'Portfolio Optimiser',
  },
]

const data_management_routes = [
  {
    component: NewStory,
    exact: true,
    navPath: true,
    path: '/new-story/:story',
    title: 'New Story',
  },
  {
    component: NewStory,
    navPath: true,
    path: '/new-story/',
    title: 'New Story',
  },
  {
    component: DataManagement,
    navPath: true,
    path: '/data-management',
    title: 'Data Management',
  },
  {
    component: Sandbox,
    navPath: true,
    path: '/dev-sandbox',
    title: 'Sandbox',
  },
]

const ops_routes = [
  {
    component: Flows,
    navPath: true,
    path: '/operating-flows',
    title: 'Operating Flows',
  },
  {
    component: CashManagement,
    navPath: true,
    path: '/cash-management',
    title: 'Cash Management',
  },
  {
    component: Diagnostics,
    navPath: true,
    path: '/platform-diagnostic',
    title: 'Platform Diagnostics',
  },
  {
    component: TotalExpense,
    navPath: true,
    path: '/total-expense/:cell_id',
    title: 'Total Expense Chart',
  },
  {
    component: TrackingError,
    navPath: true,
    path: '/tracking-error/:cell_id',
    title: 'Tracking Error Chart',
  },
  {
    component: PlatformAum,
    navPath: true,
    path: '/aum',
    title: 'Platform AUM',
  },
  {
    component: ManagerHoldings,
    navPath: true,
    path: '/manager-holdings',
    title: 'Manager Holdings',
  },
  {
    component: TotalExpenseRatio,
    navPath: true,
    path: '/total-expense-ratio',
    title: 'Total Expense Ratio',
  },
  {
    component: SubsRedsCalendar,
    navPath: true,
    path: '/subs-reds-calendar',
    title: 'Subs & Reds Calendar',
  },
  {
    component: EvaluationsOverview,
    navPath: true,
    path: '/evaluations',
    title: 'Evaluations Overview',
  },
  {
    component: TradingCalendarPage,
    navPath: true,
    path: '/trading-calendar',
    title: 'Trading Calendar'
  }
]

const ir_routes = [
  {
    component: InvestorRelations,
    navPath: true,
    path: '/investor-relations',
    title: 'Investor Relations',
  },
]

export {
  aaap_routes,
  user_management_routes,
  analytics_routes,
  data_management_routes,
  ops_routes,
  ir_routes,
  privateEquityRoutes,
}
