import React from "react";
import {
  FactorBarChart,
  FactorRangeChart,
  LineTimeSeries,
  GenericTable,
} from "../../components";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";

const Container = styled.div``;

const ChartContainer = styled(Paper)`
  max-width: 1280px;
  margin: 40px auto;
`;

export default function FactorsAnalysis({ internal_analysis }) {
  const { report } = internal_analysis || {};
  const {
    factor_exposure,
    contrib_to_risk,
    contrib_to_return,
    cum_growth,
    summary_stats,
  } = report || {};

  return (
    <Container>
      <ChartContainer>
        <GenericTable
          data={summary_stats}
          index_col="index"
          table_name="Key Metrics"
        />
      </ChartContainer>
      <ChartContainer>
        <FactorBarChart data={factor_exposure} title="Factor Exposure" />
      </ChartContainer>
      <ChartContainer>
        <FactorRangeChart
          data={contrib_to_risk}
          title="Factor Contribution To Risk"
          max={1}
        />
      </ChartContainer>
      <ChartContainer>
        <FactorRangeChart
          data={contrib_to_return}
          title="Factor Contribution To Return"
        />
      </ChartContainer>
      <ChartContainer>
        <LineTimeSeries
          title="Fund vs Factors"
          data={cum_growth}
          minimum={true}
          format={"{0:c}"}
          category_field="index"
        />
      </ChartContainer>
    </Container>
  );
}
