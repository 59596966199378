import React, { useState, useEffect } from "react";
import { getSubsRedsCalendar } from "../../services";
import { SmartGrid, KendoDropDown } from "../../components";
import { GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { dateTimeManager as dt } from "../../utils";
import { orderBy } from "@progress/kendo-data-query";

export default function SubsRedsCalendar() {
  const [tradingCalendar, setTradingCalendar] = useState([]);
  const [portfoliosList, setPortfoliosList] = useState([]);
  const [currentPortfolio, setCurrentPortfolio] = useState("");
  const fetchCalendar = async () => {
    try {
      const response = await getSubsRedsCalendar();
      let { data } = response;
      const datesFields = [
        "as_of_date",
        "next_red_date",
        "first_cash_flow",
        "second_cash_flow",
        "red_deadline",
        "next_sub_date",
        "sub_deadline",
      ];

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < datesFields.length; j++) {
          data[i][datesFields[j]] = dt.dbToJs(data[i][datesFields[j]]);
          // const fund_name = data[i]["security"];
          // data[i]["series_name"] = data[i]["series_name"].replace(
          //   fund_name,
          //   ""
          // );
        }
      }

      const portfolios = [...new Set(data.map((o) => o.portfolio))].sort();
      setTradingCalendar(response.data);
      setPortfoliosList(portfolios);
      setCurrentPortfolio("Antarctica Alpha Access Portfolio SPC Ltd");
    } catch (error) {}
  };
  useEffect(() => {
    fetchCalendar();
  }, []);

  const data = orderBy(
    tradingCalendar.filter((o) => o.portfolio === currentPortfolio),
    [{ field: "series_name", dir: "asc" }]
  );

  return (
    <div className="trading-calendar-route">
      <SmartGrid
        style={{ height: 780 }}
        sortable
        scrollable="scrollable"
        data={data}
      >
        <GridToolbar>
          <div className="toolbar">
            <KendoDropDown
              value={currentPortfolio}
              items={portfoliosList}
              onChange={({ target }) => setCurrentPortfolio(target.value)}
            />
          </div>
        </GridToolbar>
        {/* <Column field="security" title="Fund" width={270} locked /> */}
        <Column field="series_name" title="Asset" width={250} locked />
        <Column
          field="as_of_date"
          title="As Of"
          format={"{0:dd MMM yyyy}"}
          width={140}
        />
        <Column
          field="investment_value"
          title="Invested Value as at Run Date"
          format={"{0:n0}"}
          width={120}
        />
        <Column
          field="next_red_date"
          title="Next Available Redemption Date"
          format={"{0:dd MMM yyyy}"}
          width={140}
        />
        <Column
          field="days_till_red"
          title="Days till Redemption Date"
          width={120}
        />
        <Column
          field="first_cash_flow"
          title="First Cash Flow by"
          format={"{0:dd MMM yyyy}"}
          width={140}
        />
        <Column
          field="second_cash_flow"
          title="Second Cash Flow by"
          format={"{0:dd MMM yyyy}"}
          width={140}
        />
        <Column field="red_notice_period" title="Notice Period" width={140} />
        <Column field="gate" title="Gate" width={180} />
        <Column
          field="red_deadline"
          title="Redemption Notice Deadline"
          format={"{0:dd MMM yyyy}"}
          width={140}
        />
        <Column
          field="days_till_red_deadline"
          title="Days till Redemption Notice Deadline"
          width={140}
        />
        <Column
          field="max_redeemable"
          format={"{0:n0}"}
          title="Max Redeemable Amount"
          width={140}
        />
        <Column
          field="percent_of_holdings"
          title="% of Holding"
          format={"{0:p0}"}
          width={140}
        />
        <Column field="penalty" title="Penalty" width={140} format={"{0:n0}"} />
        <Column
          field="next_sub_date"
          title="Next Available Subscription Date"
          format={"{0:dd MMM yyyy}"}
          width={140}
        />
        <Column
          field="days_till_sub"
          title="Days till Subscription Date"
          width={140}
        />
        <Column
          field="sub_notice_period"
          title="Subscription Notice Period"
          width={140}
        />
        <Column
          field="sub_deadline"
          title="Subscription Notice Deadline"
          format={"{0:dd MMM yyyy}"}
          width={140}
        />
        <Column
          field="days_till_sub_deadline"
          title="Days till Subscription Notice Deadline"
          width={140}
        />
      </SmartGrid>
    </div>
  );
}
