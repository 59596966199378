import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Group,
  Modal,
  Stack,
  TagsInput,
  TextInput
} from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { peerGroupsFormToggled, selectedPeerGroupChanged } from '../../redux/peerGroupsSlice'
import { createPeerGroup } from '../../services/peer-groups'
import FundSelectionInputBox, { useSelectedFunds } from './FundSelectionInputBox'
import { IRowSelectionProps } from '../../components/models/IRowSelectionProps'

const schema = yup.object().shape({
  name: yup.string().required('Required').min(5, 'Name must be at least 5 characters'),
  tags: yup.array().of(yup.string()),
})

interface PeerGroupsFormProps extends IRowSelectionProps {
}

const PeerGroupsForm: React.FC<PeerGroupsFormProps> = ({
  rowSelection,
  setRowSelection
}) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { isLoading, mutateAsync: createPeerGroups } = useMutation((data) => createPeerGroup(data))

  const open = useAppSelector(state => state.peerGroups.createPeerGroupFormOpened)
  const selectedFunds = useSelectedFunds(rowSelection, open)

  const dispatch = useAppDispatch()
  const { formState: { errors }, handleSubmit, register, reset, setValue, watch } = useForm({
    defaultValues: {
      name: '',
      tags: []
    },
    resolver: yupResolver(schema)
  })
  const watchTags = watch('tags')

  const closeModal = () => dispatch(peerGroupsFormToggled({ open: false }))

  const clickCreatedNotification = (id: number) => () => {
    dispatch(selectedPeerGroupChanged(id))
    history.push('/peer-groups')
    notifications.clean()
  }

  const submitFormHandler = async (data) => {
    try {
      const res = await createPeerGroups({
        ...data,
        funds: selectedFunds?.map(fundId => ({ fund_id: fundId }))
      })
      queryClient.invalidateQueries(['peer-groups'])
      const createdPeerGroupId = res.data.id
      notifications.show({
        message: <Box
          onClick={clickCreatedNotification(createdPeerGroupId)}
          style={{ cursor: 'pointer' }}
        >
          Click to View
        </Box>,
        title: `Created ${res.data.name}`,
      })
      closeModal()
      reset()
    } catch (err) {
      console.log('error', err)
    }
  }

  const tagValueChangeHandler = (value) => {
    setValue('tags', value)
  }

  return (
    <Modal
      centered
      onClose={closeModal}
      opened={open}
      size="lg"
      title="Create Peer Group"
    >
      <form
        id="peer-group-form"
        onSubmit={handleSubmit(submitFormHandler)}
      >
        <Stack>
          <TextInput
            {...register('name')}
            autoComplete="off"
            error={errors.name?.message}
            placeholder="Name"
          />
          <TagsInput
            data={watchTags}
            onChange={tagValueChangeHandler}
            placeholder="Tags"
          />
          <FundSelectionInputBox
            selectedFunds={selectedFunds}
            setRowSelection={setRowSelection}
          />
        </Stack>
        <Group
          justify="flex-end"
          mt="xl"
        >
          <Button
            onClick={closeModal}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            disabled={selectedFunds.length < 1}
            form="peer-group-form"
            loading={isLoading}
            type="submit"
          >
            Create
          </Button>
        </Group>
      </form>
    </Modal>
  )

}

export default PeerGroupsForm
