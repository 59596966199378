import React, { Component } from "react";
import { GlobalSearch } from "../selectors";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined"
import update from "immutability-helper";

export default class portfolioFundSelector extends Component {
  updateFundSelector = (e, idx) => {
    const { weights, handleChange } = this.props;
    const last_fund = weights.length - 1;
    let element = weights[idx];
    const { security } = element || {};
    const new_security = e.target.value;
    const new_element = { security: new_security, weight: element.weight };
    let new_weights = update(weights, { $splice: [[idx, 1, new_element]] });
    if (!security && idx === last_fund && new_security) {
      new_weights.push({ security: "", weight: null });
    }
    handleChange(new_weights);
  };

  updateWeight = (e, idx) => {
    const { weights, handleChange } = this.props;
    const { value } = e.target;
    const weight = value ? Number(value) / 100 : value;
    let element = weights[idx];
    const { security } = element || {};
    const new_element = { security, weight };
    let new_weights = update(weights, { $splice: [[idx, 1, new_element]] });
    handleChange(new_weights);
  };

  deleteFund = (idx) => {
    const { weights, handleChange } = this.props;
    let new_weights = update(weights, { $splice: [[idx, 1]] });
    let last_idx = new_weights.length - 1;
    const { security } = new_weights[last_idx] || {};
    if (last_idx === -1) {
      new_weights.push({ security: "", weight: null });
    } else if (security) {
      new_weights.push({ security: "", weight: null });
    }

    handleChange(new_weights);
  };

  render() {
    const { items, weights, no_weight } = this.props;
    return (
      <div className="portfolio-fund-list">
        {weights.map((entry, idx) => {
          const { security, weight } = entry;
          const percent_values =
            typeof weight === "number" ? weight * 100 : weight;
          return (
            <div className="weight-row" key={idx}>
              <GlobalSearch
                items={items}
                placeholder="Fund"
                name="fund"
                value={security}
                textField="name"
                dataItemKey="id"
                noLink={true}
                onChange={(e) => {
                  this.updateFundSelector(e, idx);
                }}
                searchFields={[
                  "name",
                  "isin",
                  "underlying_name",
                  "principal_name",
                ]}
              />
              <NumericTextBox
                // className="fund-weight"
                placeholder="Weight"
                disabled={no_weight}
                // variant="outlined"
                size="small"
                type="number"
                name="weight"
                value={percent_values}
                onChange={(e) => {
                  this.updateWeight(e, idx);
                }}
                InputProps={{
                  endAdornment: <span>%</span>,
                }}
              />
              <IconButton
                onClick={() => {
                  this.deleteFund(idx);
                }}
                variant="outlined"
                className="icon-button"
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </div>
          );
        })}
      </div>
    );
  }
}
