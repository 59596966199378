import { Grid } from '@material-ui/core'
import React from 'react'

import AamServiceSelect from '../../components/misc/AamServiceSelect'
import { BASE_ROUTE_TYPES } from '../../constants/routes.constants'
import HighlightsCard, { HighlightType } from '../dashboard/HighlightsCard'
import InsightsPane from '../dashboard/InsightsPane'
import PrivateMarketFundsOverviewTable from './PrivateMarketFundsOverviewTable'

const PrivateMarketHomePage = () => (
  <>
    <AamServiceSelect />
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        lg={4}
        xl={4}
        xs={12}
      >
        <PrivateMarketFundsOverviewTable />
      </Grid>
      <Grid
        item
        lg={4}
        xl={4}
        xs={12}
      >
        <InsightsPane type={BASE_ROUTE_TYPES.PRIVATE_MARKET} />
      </Grid>
      <Grid
        item
        lg={4}
        xl={4}
        xs={12}
      >
        <HighlightsCard type={HighlightType.PRIVATE_EQUITY} />
      </Grid>
    </Grid>
  </>
)

export default PrivateMarketHomePage
