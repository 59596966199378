import { Card } from '@material-ui/core'
import { CategoricalColumnChart, ClassificationTable } from '../../components'
import SkeletonExposuresChartLoader from '../../components/skeletons/SkelatonExposuresChartLoader'
import { dateTimeManager } from '../../utils'

const ExposureChart = ({ latest_underlying_exposure_date, exposure_data }) => {
  const as_of = dateTimeManager.dbToLongFormat(latest_underlying_exposure_date)
  const { exposures, format, risk_name } = exposure_data || {}

  return (
    <CategoricalColumnChart
      category_field={null}
      data={exposures}
      format={format}
      stack={false}
      title={`Risk Exposure (${risk_name}) as of ${as_of}`}
      use_antarctica_colors
    />
  )
}

const getCleanExposures = (security_details) => {
  const { underlying_exposures = [] } = security_details || {}
  if (underlying_exposures.length === 0) {
    return {}
  }
  const row = underlying_exposures[0]
  const { classification } = row || {}
  let risk_name, format, value_name, multiplier
  if (classification === 'IsoCurrency') {
    value_name = 'dv01_value'
    risk_name = 'DV01'
    format = '{0:n2}'
    multiplier = 10000
  } else {
    value_name = 'net_value'
    risk_name = classification
    format = '{0:p2}'
    multiplier = 1
  }
  let exposures = {}
  for (let i = 0; i < underlying_exposures.length; i++) {
    const element = underlying_exposures[i]
    const { sub_classification } = element || {}
    exposures[sub_classification] = element[value_name] * multiplier
  }
  return { exposures, format, risk_name }
}

const Exposures = ({ security_details, loading }) => {
  const exposure_data = getCleanExposures(security_details)
  const {
    classifications,
    underlying_exposures,
    latest_underlying_exposure_date,
  } = security_details || {}

  return (
    <Card>
      <div className="asset-classification-chart-container">
        {classifications && <ClassificationTable data={classifications} />}
        {loading ? (
          <div
            style={{
              flex: 7,
              padding: '10px 0',
              paddingTop: '20px',
              paddingLeft: '5px',
            }}
          >
            <SkeletonExposuresChartLoader />
          </div>
        ) : (
          underlying_exposures && (
            <ExposureChart
              latest_underlying_exposure_date={latest_underlying_exposure_date}
              exposure_data={exposure_data}
            />
          )
        )}
      </div>
    </Card>
  )
}

export default Exposures
