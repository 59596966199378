import React from "react";
import PropTypes from "prop-types";
import ErrorMessage from "./ErrorMessage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useFormikContext } from "formik";

export default function SwitchForm({ name, label }) {
  const { errors, values, setFieldValue } = useFormikContext();
  const value = values[name] || false;
  const handleChecking = ({ target }) => {
    setFieldValue(name, target.checked);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            // onChange={(e) => {
            //   this.handleChecking(e, id);
            // }}
            onChange={handleChecking}
            color="primary"
          />
        }
        label={label}
      />
      <ErrorMessage error={errors[name]} />
    </>
  );
}

SwitchForm.propTypes = {
  name: PropTypes.string,
};
