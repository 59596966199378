import React, { Component } from "react";
import { FlowsContainer } from "../flows";

export default class flows extends Component {
  render() {
    return (
      <div className="flows-page">
        <FlowsContainer {...this.state} />
      </div>
    );
  }
}
