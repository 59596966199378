import React, { useEffect, useContext } from "react";
import { checkSignedIn, renderButton } from "../../services/google";
import { AuthContext } from "../../contexts";

export default function UserActivity() {
  const { gaAuth: isSignedIn, setGaAuth: setIsSignedIn } = useContext(
    AuthContext
  );

  // const [isSignedIn, setIsSignedIn] = useState(false);

  const updateSignin = (signedIn) => {
    setIsSignedIn(signedIn);
    if (!signedIn) {
      renderButton();
    }
  };

  const init = () => {
    checkSignedIn()
      .then((signedIn) => {
        updateSignin(signedIn);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    window.gapi.load("auth2", init);
  });

  return (
    <div className="user-activity-auth">
      {!isSignedIn && (
        <div className="auth-button">
          <div id="signin-button"></div>
        </div>
      )}
    </div>
  );
}
