import { get, post } from "./api";
export const getMappedValueKeys = (payload) =>
  get("/api/v1/value-key-options/", payload);

export const getExistingExposureMap = (payload) =>
  get("/api/v1/value-map/", payload);

export const updateExistingExposureMap = (payload) =>
  post("/api/v1/value-map/", payload);

export const getPerformance = (payload) => get("/api/v1/performance/", payload);
export const updatePerformance = (payload) =>
  post("/api/v1/performance/", payload);

export const updateRelatedSecurities = (payload) =>
  post("/api/v1/hedge-fund/", payload, "related/");

export const getExposures = (payload) =>
  get(`/api/v1/exposures/${payload.id}/`, payload, { responseType: "blob" });

export const updateExposures = (payload) => post("/api/v1/exposures/", payload);
export const bulkInsertDocuments = (payload) =>
  post("/api/v1/stories/bulk/", payload);

export const getLatestQuotes = (payload) => get("/api/v1/wf-quotes/", payload);
export const pushQuoteToWf = (payload) => post("/api/v1/wf-quotes/", payload);

export const getNetFromGross = (payload) =>
  post("/api/v1/gross-to-net-converter/", payload);
