function stringToRGBColorCode(inputString, opacity = 1) {
  // Convert string to hash code
  let hashCode = 0;
  for (let i = 0; i < inputString.length; i++) {
    hashCode = inputString.charCodeAt(i) + ((hashCode << 5) - hashCode);
  }

  // Generate RGB components
  const r = (hashCode & 0xFF0000) >> 16;
  const g = (hashCode & 0x00FF00) >> 8;
  const b = hashCode & 0x0000FF;

  // Return RGB color code
  return `rgb(${r}, ${g}, ${b}, ${opacity})`;
}

export default stringToRGBColorCode;
