import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { FieldArray, useFormikContext } from 'formik'
import React from 'react'

import {
  DatePicker,
  DropdownForm,
  SecurityList,
  SwitchForm,
  TextInput,
} from '../../components'
import MonthlyCalendar from '../../components/misc/monthlyCalendar'

const portfolioTypes = [
  'AAAP Portfolio',
  'AAAP Model Portfolio',
  'Internal Private Portfolio',
  'Internal Public Portfolio',
]
export const rebalancingTypes = [
  { label: 'None', value: 'None' },
  { label: 'M - Monthly', value: 'M' },
  { label: 'Q - Quarterly', value: 'Q' },
  { label: 'A - Annually', value: 'A' },
]

export const kendoRebalcingTypes = ['None', 'M', 'Q', 'A']

export const Description = ({ fullAccess }) => {
  return (
    <Box
      className="description-container"
      display="flex"
      flexDirection="column"
      gridGap={8}
    >
      <TextInput name="name" placeholder="Portfolio name *" required />
      <TextInput
        name="description"
        placeholder="Portfolio description *"
        required
      />
      {fullAccess && (
        <DropdownForm
          items={portfolioTypes}
          name="portfolio_type"
          placeholder="Portfolio type"
        />
      )}
    </Box>
  )
}

export const FundAllocator = ({ searchList, ...props }) => {
  const { setFieldValue, values } = useFormikContext()
  const { portfolio_weights } = values
  const total = portfolio_weights.length
  const weights = portfolio_weights.map((o) => o.weight)
  let total_weight = weights.reduce((sum, entry) => sum + entry)
  total_weight = Math.round((total_weight + Number.EPSILON) * 100) / 100
  const equalAllocation = () => {
    let updated_weights = []
    let target_weight =
      (1 / portfolio_weights.filter((o) => !!o.security).length) * 100
    for (let i = 0; i < portfolio_weights.length; i++) {
      let entry = portfolio_weights[i]
      if (!entry.security) {
        updated_weights.push({ ...entry })
        continue
      }
      updated_weights.push({ ...entry, weight: target_weight })
    }
    setFieldValue('portfolio_weights', updated_weights)
  }

  const scaleToHundred = () => {
    if (!total_weight) return
    let updated_weights = []

    for (let i = 0; i < portfolio_weights.length; i++) {
      const entry = portfolio_weights[i]
      if (!entry.security || !entry.weight) {
        updated_weights.push({ ...entry })
        continue
      }
      const target_weight = (entry.weight / total_weight) * 100
      updated_weights.push({ ...entry, weight: target_weight })
    }
    setFieldValue('portfolio_weights', updated_weights)
  }
  return (
    <>
      <FieldArray
        name="portfolio_weights"
        render={(arrayHelpers) => (
          <Box
            className="array-container"
            display="flex"
            flexDirection="column"
            gridGap={8}
            mb={2}
          >
            {portfolio_weights.map((entry, index) => {
              return (
                <Box
                  alignItems="flex-start"
                  className="weight-row"
                  gridGap={8}
                  key={index}
                >
                  <SecurityList
                    className="kendo-virtual-combobox global-search"
                    dataItemKey="id"
                    handleChange={() => {
                      if (index + 1 === total) {
                        arrayHelpers.push({ security: '', weight: null })
                      }
                    }}
                    items={searchList}
                    name={`portfolio_weights.${index}.security`}
                    noLink={true}
                    placeholder="Fund"
                    searchFields={[
                      'name',
                      'isin',
                      'underlying_name',
                      'principal_name',
                    ]}
                    textField="name"
                    wrapperProps={{
                      flex: '8 0 0',
                    }}
                  />
                  <TextInput
                    name={`portfolio_weights.${index}.weight`}
                    placeholder="Weight"
                    spinners={false}
                    type="number"
                    wrapperProps={{
                      flex: '3 0 0',
                    }}
                  />
                  <Box flex="1 0 0" mt={1.5}>
                    <IconButton
                      className="icon-button"
                      color="secondary"
                      disabled={index + 1 === total}
                      onClick={() => {
                        if (index + 1 !== total) {
                          arrayHelpers.remove(index)
                        }
                      }}
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </Box>
                </Box>
              )
            })}
          </Box>
        )}
      />
      <div className="weight-row total">
        <ButtonGroup variant="contained">
          <Button onClick={equalAllocation}>Equal Allocation</Button>
          <Button onClick={scaleToHundred}>Scale to 100%</Button>
        </ButtonGroup>
        <Typography>Total Weight: {total_weight}%</Typography>
      </div>
    </>
  )
}

export const Benchmarks = ({ searchList }) => {
  return (
    <>
      <SecurityList
        className="kendo-virtual-combobox global-search"
        dataItemKey="id"
        items={searchList}
        multi
        name={`benchmarks`}
        noLink={true}
        placeholder="Benchmarks"
        searchFields={['name', 'isin', 'underlying_name', 'principal_name']}
        textField="name"
      />
      <Typography variant="caption">
        if no benchmarks are selected, the standard set will be used
      </Typography>
    </>
  )
}

export const Fees = () => {
  return (
    <>
      <div className="fees-row">
        <TextInput
          label="Perf Fee, %"
          name="performance_fee"
          spinners={false}
          type="number"
        />
        <TextInput
          label="Mgmt Fee, %"
          name="management_fee"
          spinners={false}
          type="number"
        />
        <TextInput label="Cost, %" name="cost" spinners={false} type="number" />
        <TextInput
          label="Hurdle, %"
          name="hurdle"
          spinners={false}
          type="number"
        />
      </div>
      <div className="meta-row">
        <DropdownForm
          className="kendo-virtual-combobox rebalancing-dropdown"
          defaultItem="Rebalancing Type"
          items={kendoRebalcingTypes}
          name="rebalancing_type"
        />
        <Typography variant="caption">Inception Date: </Typography>
        <DatePicker
          calendar={MonthlyCalendar}
          className="inception-picker"
          format="MMM yyyy"
          name="inception_date"
        />
      </div>
      <SwitchForm label="Prorated" name="prorated" />
    </>
  )
}
