import moment from 'moment'
import { extractSeriesNames } from './extractSeriesNames'

function filterWithRange(value, range) {
  const { start_date, end_date } = range
  const val_date = moment(value.perf_date)
  if (start_date && end_date) {
    return val_date >= start_date && val_date <= end_date
  } else if (start_date && !end_date) {
    return val_date >= start_date
  } else if (!start_date && end_date) {
    return val_date <= end_date
  } else {
    return true
  }
}

export const cumulativePerf = (raw_perf_obj) => {
  const { raw_perf, start, end } = raw_perf_obj
  let start_date, end_date
  const series_names = extractSeriesNames(raw_perf)

  if (start) {
    start_date = moment(start)
  }
  if (end) {
    end_date = moment(end)
  }

  const range = { start_date, end_date }
  const active_raw_perf = raw_perf.filter((v) => filterWithRange(v, range))
  if (!active_raw_perf | (active_raw_perf.length === 0)) {
    return raw_perf
  }

  const first_date = moment(active_raw_perf[0].perf_date)
    .add(-1, 'month')
    .endOf('month')
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss')

  let calculated_output = {
    perf_date: [first_date],
  }
  for (let i = 0; i < series_names.length; i++) {
    const name = series_names[i]
    calculated_output[name] = [1000]
  }

  for (let i = 0; i < active_raw_perf.length; i++) {
    const element = active_raw_perf[i]
    calculated_output.perf_date.push(element.perf_date)

    for (let j = 0; j < series_names.length; j++) {
      const name = series_names[j]
      const current = calculated_output[name][i]
      const new_value = current * (1 + element[name])
      calculated_output[name].push(new_value)
    }
  }

  let formatted_output = []
  for (let i = 0; i < calculated_output.perf_date.length; i++) {
    let perf_object = {
      perf_date: calculated_output.perf_date[i],
    }

    for (let j = 0; j < series_names.length; j++) {
      const name = series_names[j]
      perf_object[name] = calculated_output[name][i]
    }
    formatted_output.push(perf_object)
  }

  return formatted_output
}
