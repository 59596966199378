import React, { useEffect, useState } from "react";
import { statefulTableGenerator } from "../../components/tables";
import { kendoTextFilter } from "../../components/filters";
import { Grid as KGrid, GridColumn } from "@progress/kendo-react-grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import UserForm from "./userForm";
// import { cloneDeep } from "lodash";
// import { empty_user } from "../../data";
// import passwordGenerator from "../../utils/idGenerator";
import { KendoLoader } from "../../components/misc";
import { getUsers } from "../../services";
import { dateTimeManager } from "../../utils";

const StatefulGrid = statefulTableGenerator(KGrid);
const TextFilter = kendoTextFilter();

export default function UserList() {
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleRowClick = ({ dataItem }) => {
    setSelectedUser(dataItem);
  };

  const handleClose = () => {
    setSelectedUser(null);
  };

  const processUser = (input) => {
    let user = { ...input };
    const { last_login, first_name = "", last_name = "", groups = [] } =
      user || {};
    user.full_name = `${first_name} ${last_name}`;
    user.group_string = groups.join(", ");
    user.last_login = dateTimeManager.dbToJs(last_login);
    return user;
  };

  const getUsersList = async () => {
    const response = await getUsers();
    setUserList(response.data);
  };

  useEffect(() => {
    getUsersList();
  }, []);

  let parsedList = userList.map((o) => processUser(o));

  const user_updating = false;

  return (
    <div className="filter-pane">
      <div className="user-toolbar">
        <Button variant="contained" onClick={() => setSelectedUser({})}>
          <AddIcon />
          Add new user
        </Button>
      </div>
      <StatefulGrid
        style={{ height: "800px" }}
        className="user-table"
        data={parsedList}
        pageable={false}
        filterable={true}
        onRowClick={handleRowClick}
        scrollable="scrollable"
      >
        <GridColumn title="ID" field="id" filterCell={TextFilter} width={120} />
        <GridColumn title="Name" field="full_name" filterCell={TextFilter} />
        <GridColumn title="Company" field="company" filterCell={TextFilter} />
        <GridColumn title="E-mail" field="email" filterCell={TextFilter} />
        <GridColumn title="Country" field="country" filterCell={TextFilter} />
        <GridColumn
          title="Access"
          field="group_string"
          filterCell={TextFilter}
        />
        <GridColumn
          title="Last Login"
          field="last_login"
          format="{0:dd-MMM-yyyy HH:mm}"
          filterable={false}
        />
        <GridColumn
          field="is_active"
          title="Active"
          width="80px"
          cell={(props) => (
            <td>
              <input
                disabled
                type="checkbox"
                checked={props.dataItem[props.field]}
              />
            </td>
          )}
          filterable={false}
        />
      </StatefulGrid>
      {user_updating && <KendoLoader />}
      {selectedUser && (
        <UserForm
          selectedUser={selectedUser}
          onClose={handleClose}
          setUserList={setUserList}
          userList={userList}
          setSelectedUser={setSelectedUser}
          //   onClose={this.onClose}
          //   enterEdit={this.enterEdit}
          //   cancelEdit={this.cancelEdit}
          //   onEdit={this.onEdit}/
          //   onSave={this.onSave}
          //   {...this.state}
        />
      )}
    </div>
  );
}
