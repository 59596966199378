import React, { useState, useEffect } from "react";
import { gaDashReport } from "../../services";
import { dateTimeManager as dt, gaParser } from "../../utils";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { LineTimeSeries, SmartGrid } from "../../components";
import Selelectors from "./dateSelectors";

export default function Overview() {
  const [data, setData] = useState({
    userSessions: [],
    activeUsers: [],
    pageViews: [],
    countries: [],
  });
  const [loading, setLoading] = useState(false);

  const selectorValues = {
    startDate: dt.defaultAaapDate(),
    endDate: dt.today(),
  };

  const queryReport = async (payload) => {
    setLoading(true);
    const response = await gaDashReport(payload);
    const { reports = [] } = response.result || {};
    const userSessions = gaParser.parseTimeSeries(reports[0]);
    const weeklyUsers = gaParser.parseTimeSeries(reports[1]);
    const monthlyUsers = gaParser.parseTimeSeries(reports[2]);
    let activeUsers = [];
    for (let i = 0; i < weeklyUsers.length; i++) {
      const weekly = weeklyUsers[i];
      const monthly = monthlyUsers[i];
      activeUsers.push({ ...weekly, ...monthly });
    }

    const pageViews = gaParser.parseTimeSeries(reports[3], false);
    const countries = gaParser.parseTimeSeries(reports[4], false);
    setData({ userSessions, activeUsers, pageViews, countries });
    setLoading(false);
  };

  useEffect(() => {
    const payload = {
      startDate: dt.jsToDateTimeDb(dt.defaultAaapDate()),
      endDate: dt.jsToDateTimeDb(dt.today()),
    };
    queryReport(payload);
  }, []);

  return (
    <div className="activity-dashboard">
      <Typography align="center" variant="h5">
        User Activity Dashboard
      </Typography>
      <Selelectors initialValues={selectorValues} queryReport={queryReport} />
      {loading && (
        <div>
          <LinearProgress />
        </div>
      )}
      {!loading && (
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <LineTimeSeries
              data={data.userSessions}
              base_unit="days"
              category_field="index"
              format="{0:n}"
              title="Users/Sessions summary"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <LineTimeSeries
              data={data.activeUsers}
              base_unit="days"
              category_field="index"
              format="{0:n}"
              title="Active Users"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SmartGrid
              data={data.pageViews}
              sortable
              scrollable="scrollable"
              style={{ height: 500 }}
              initialState={{
                sort: [{ field: "Page Views", dir: "desc" }],
              }}
            >
              <Column field="index" title="Page" width={400} />
              <Column field="Page Views" format="{0:n0}" />
              <Column field="Avg Time (s)" format="{0:n0}" />
            </SmartGrid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <SmartGrid
              data={data.countries}
              sortable
              scrollable="scrollable"
              style={{ height: 500 }}
              initialState={{
                sort: [{ field: "Sessions", dir: "desc" }],
              }}
            >
              <Column field="index" title="Country" width={400} />
              <Column field="Users" format="{0:n0}" />
              <Column field="Sessions" format="{0:n0}" />
            </SmartGrid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
