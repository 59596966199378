export const analysis_lists = {
  reg_type: [
    { id: "ols", name: "OLS", title: "OLS" },
    { id: "wls", name: "WLS", title: "WLS" },
  ],
  downside_type: [
    { id: "parametric", name: "Parametric", title: "Parametric" },
    { id: "factor", name: "Factor-Based", title: "Factor-Based" },
    { id: "analytical", name: "Analytical AR(1)", title: "Analytical AR(1)" },
  ],
  var_type: [
    { id: "fund_summary", name: "Fund Summary", title: "Fund Summary" },
    {
      id: "portfolio_summary_historical",
      name: "Historical",
      title: "Historical",
    },
    {
      id: "portfolio_summary_t_copula",
      name: "Hist (t-copula)",
      title: "Hist (t-copula)",
    },
  ],
  decomposition_type: [
    { id: "sd", name: "Volatility", title: "Volatility" },
    { id: "var", name: "VaR", title: "VaR" },
    { id: "sim_var", name: "Monte-Carlo VaR", title: "Monte-Carlo VaR" },
  ],
};

export const exposure_analysis_lists = {
  report_types: [
    { id: "charts", title: "Charts" },
    { id: "tables", title: "Tables" },
    { id: "aum", title: "AUM" },
  ],
  table_mode: [
    { id: "monthly", name: "Monthly" },
    { id: "quarterly", name: "Quarterly" },
    { id: "last_quarter", name: "Latest Quarter" },
  ],
  table_name: [
    { id: "quarterly_change_table", name: "Quarterly Change" },
    { id: "exposure", name: "Exposure" },
    { id: "attribution", name: "Attribution" },
    { id: "roc", name: "RoC" },
  ],
  chart_groups: [
    { name: "Exposures", id: "EXPOSURES" },
    { name: "Attribution", id: "ATTRIBUTION" },
    { name: "Average Exposure", id: "AVERAGE EXPOSURE" },
    { name: "Total Attribution", id: "TOTAL ATTRIBUTION" },
    { name: "RoC", id: "ROC" },
  ],
  time_series_charts: {
    exposure: [
      { name: "Long Exposure", id: "long" },
      { name: "Short Exposure", id: "short" },
      { name: "Gross Exposure", id: "gross" },
      { name: "Net Exposure", id: "net" },
    ],
    attribution: [
      { name: "Long Attribution", id: "long attribution" },
      { name: "Short Attribution", id: "short attribution" },
      { name: "Total Attribution", id: "total attribution" },
    ],
    roc: [
      { id: "gross_roc", name: "RoC (Gross)" },
      { id: "net_roc", name: "RoC (Net)" },
    ],
    roc_scatter: [
      { id: "gross_scatter", name: "RoC (Gross)" },
      { id: "net_scatter", name: "RoC (Net)" },
    ],
    roc_table: [{ id: "data", name: "RoC" }],
  },
};

export const client_available_benchmarks = [
  "MSCI World",
  "MSCI EU",
  "MSCI EM",
  "S&P 500",
  "NASDAQ Composite",
  "FTSE 100",
  "Dax",
  "Nifty 50",
  "Nikkei",
];
