import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FileCopy";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined"
import Dropzone from "react-dropzone";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getImageList, uploadPicture, deletePicture } from "../../services";
import { fetcher } from "../../utils/fetcher";

export const ImageGrid = ({ handleClose, open }) => {
  const [imageList, setImageList] = useState([]);
  const [previousLink, setPreviousLink] = useState(null);
  const [nextLink, setNextLink] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const handleImageResponse = ({ data }) => {
    const { next, previous, results } = data || {};
    setNextLink(next);
    setPreviousLink(previous);
    setImageList(results);
  };

  useEffect(() => {
    const getImages = async () => {
      try {
        const response = await getImageList({ page_size: 9 });
        handleImageResponse(response);
      } catch (error) { }
    };
    getImages();
  }, []);

  const getPage = async (link) => {
    try {
      const response = await fetcher.get(link);
      handleImageResponse(response);
    } catch (error) { }
  };

  const uploadImage = async (files) => {
    const asset = files[0];
    const name = asset.name;
    let payload = new FormData();
    payload.append("asset", asset);
    payload.append("name", name);
    try {
      const response = await uploadPicture(payload);
      const updatedList = [response.data, ...imageList];
      setImageList(updatedList);
    } catch (error) { }
  };

  const deleteImage = async (id) => {
    const originalImages = [...imageList];
    let updatedList = imageList.filter((o) => o.id !== id);
    setImageList(updatedList);
    try {
      await deletePicture({ id });
    } catch (error) {
      setImageList(originalImages);
    }
  };

  const titleButton = () => {
    if (editMode) {
      return (
        <Button
          onClick={() => setEditMode(false)}
          className="title-button"
          color="primary"
        >
          Done
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => setEditMode(true)}
          className="title-button"
          color="primary"
        >
          Edit
        </Button>
      );
    }
  };

  const closeForm = () => {
    setEditMode(false);
    handleClose();
  };

  return (
    <Dialog
      onClose={closeForm}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      className="image-repo-dialog"
    >
      <DialogTitle
        disableTypography
        onClose={closeForm}
        className="dialog-title"
      >
        <Typography variant="h6"> Image Repository </Typography>
        {titleButton()}
      </DialogTitle>
      <DialogContent dividers>
        <Dropzone onDrop={uploadImage}>
          {({ getRootProps, getInputProps }) => (
            <section className="container single-editor">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                Drag and drop picture here
                {/* {name ? <p>{name}</p> : <p>{title}</p>} */}
              </div>
            </section>
          )}
        </Dropzone>
        <GridList cellHeight={160} cols={3}>
          {imageList.map((img) => (
            <GridListTile key={img.id} cols={1}>
              <img src={img.asset} alt={img.id} />
              <GridListTileBar
                title={img.name}
                actionIcon={
                  editMode ? (
                    <IconButton onClick={() => deleteImage(img.id)}>
                      <DeleteOutlinedIcon style={{ color: "red" }} />
                    </IconButton>
                  ) : (
                    <CopyToClipboard text={img.asset}>
                      <IconButton>
                        <CopyIcon style={{ color: "white" }} />
                      </IconButton>
                    </CopyToClipboard>
                  )
                }
              />
            </GridListTile>
          ))}
        </GridList>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button
          disabled={!previousLink}
          onClick={() => getPage(previousLink)}
          color="primary"
        >
          {`<`} Previous
        </Button>
        <Button
          disabled={!nextLink}
          onClick={() => getPage(nextLink)}
          color="primary"
        >
          Next {`>`}{" "}
        </Button>
        <Button onClick={closeForm} color="secondary" className="close-button">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
