import React from 'react'
import ReportSnippets from '../../utils/reportInputSnippets'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const report_types = ['Exposure Report', 'Stress Test']

export default function ReportInputs({
  risk_analysis_inputs,
  webfolio_list,
  updateSelector,
  onGenerateReport,
  showInputs,
  setShowInputs,
  loading,
  ...props
}) {
  const { portfolio } = risk_analysis_inputs || {}
  const not_ready = !!(!portfolio || !portfolio.id)
  const reportSnippets = new ReportSnippets({
    ...props,
    data: risk_analysis_inputs,
    updateInputs: updateSelector,
    submitFunction: onGenerateReport,
  })
  return (
    <div className="selectors">
      <Typography variant="h6" align="center">
        Report Set-Up
        {!showInputs && (
          <IconButton
            onClick={() => {
              setShowInputs(true)
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        )}
      </Typography>
      <Collapse in={showInputs}>
        {reportSnippets.dropdownSelector(
          'portfolio',
          'Portfolio',
          webfolio_list,
          {
            dataItemKey: 'id',
            textField: 'name',
            defaultItem: { id: null, name: 'Select portfolio...' },
          },
        )}
        {reportSnippets.dropdownSelector(
          'report_type',
          'Report Type',
          report_types,
          { defaultItem: 'Select report...' },
        )}
        {reportSnippets.monthlyCalendarSelector('portfolio_date')}
        {reportSnippets.submitButton('Generate Risk Report', {
          disabled:
            not_ready ||
            loading ||
            !risk_analysis_inputs?.portfolio ||
            !risk_analysis_inputs?.report_type,
        })}
      </Collapse>
    </div>
  )
}
