export const exposure_map_categories = [
  { id: "MAPPED", title: "Normal" },
  { id: "RISK", title: "Risk" },
];

export const original_exposure_categories = [
  { id: "UNMAPPED", title: "Normal" },
  { id: "SOURCE", title: "Source" },
  { id: "RAW", title: "Raw" },
];
