import { Button, Group, Pill, Stack } from '@mantine/core'
import { useQuery } from '@tanstack/react-query'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import SelectFilter, { SelectFilterChangedEvent } from '../../components/filters/SelectFilter'
import { useSecurities } from '../../hooks'
import { useMixpanel } from '../../hooks/useMixpanel'
import { useParamsQueryObject } from '../../hooks/useParamsQuery'
import { getNotificationStoryTypes } from '../../services'
import camelCaseToTitleCase from '../../utils/camelCaseToTitleCaase'
import { createOptions } from '../../utils/createOptionsFromList'

const uiNameParamKeyMap: Record<string, string> = {
  documentType: 'storyType',
  fund: 'security'
}

const defaultPage = 1

const DocumentNotificationFilter: React.FC = () => {
  const { mpTrack } = useMixpanel()
  const history = useHistory()

  const { search_list: searchList, search_list_map: searchListMap } = useSecurities()
  const { data: storyTypes } = useQuery(['docs-notification-story-types'], () => getNotificationStoryTypes(), { select: (res) => res.data })

  const storyTypeOptions = useMemo(() => createOptions(storyTypes, 'name', 'name', true), [storyTypes])
  const securityOptions = useMemo(() => createOptions(searchList?.filter(it => it.type !== 'Market Index'), 'name', 'id', true).sort((a, b) => a.label.localeCompare(b.label)), [searchList])
  const urlParams = useParamsQueryObject()

  const chipsData = useMemo(() => {
    const documentType = urlParams?.storyType?.split(',')
    const fund = urlParams?.security?.split(',')

    return [
      ...(documentType?.map(it => ({ displayValue: it, key: 'documentType', value: it })) || []),
      ...(fund?.map(it => ({ displayValue: searchListMap[it]?.name, key: 'fund', value: it })) || [])
    ]
  }, [urlParams?.storyType, urlParams?.security, searchListMap])

  const selectFilterValues = useMemo(() => ({
    documentType: urlParams?.storyType?.split(','),
    fund: urlParams?.security?.split(',')
  }), [urlParams?.storyType, urlParams?.security])

  const changeFilterValueHandler = (key: string) => (selectedValue: SelectFilterChangedEvent) => {
    const newParams: any = { ...urlParams, page: defaultPage }

    if ((selectedValue as string[])?.length > 0) {
      newParams[key] = selectedValue
    } else {
      delete newParams[key]
    }

    history.push(`/document-notifications?${new URLSearchParams(newParams).toString()}`)

    mpTrack({
      eventName: 'Document Notification Filter Changed',
      properties: {
        ...newParams
      }
    })
  }

  const clickRemovePill = (key: string, value: string) => () => {
    const newValue = urlParams[uiNameParamKeyMap[key]].split(',').filter(it => it !== value)
    changeFilterValueHandler(uiNameParamKeyMap[key])(newValue)
  }

  const removeAllFilters = () => {
    const newParams = {
      page: defaultPage
    }

    if (urlParams.view) {
      newParams.view = urlParams.view
    }

    history.push(`/document-notifications?${new URLSearchParams(newParams).toString()}`)
  }

  return (
    <Stack gap="xs">
      <Group>
        <SelectFilter
          multiSelect
          onChange={changeFilterValueHandler('security')}
          options={securityOptions}
          value={selectFilterValues.fund}
        >
          Fund
        </SelectFilter>
        <SelectFilter
          multiSelect
          onChange={changeFilterValueHandler('storyType')}
          options={storyTypeOptions}
          value={selectFilterValues.documentType}
        >
          Document Type
        </SelectFilter>
        {
          (selectFilterValues.documentType?.length > 0 || selectFilterValues.fund?.length > 0) &&
          <Button
            onClick={removeAllFilters}
            size="sm"
          >
            Clear All
          </Button>
        }
      </Group>
      <Group gap="xs">
        {
          chipsData?.map((chip, idx) => (
            <Pill
              key={idx}
              onRemove={clickRemovePill(chip.key, chip.value)}
              withRemoveButton
            >
              <strong>{camelCaseToTitleCase(chip.key)}:</strong> {chip.displayValue}
            </Pill>
          ))
        }
      </Group>
    </Stack>
  )
}

export default DocumentNotificationFilter