import { get } from "./api";

export const getAumHistory = (payload) => get("/api/v1/aum-history/", payload);
export const getAumHistoryExcel = (payload) =>
  get("/api/v1/aum-history/raw/", payload, { responseType: "blob" });
export const getManagerHoldings = (payload) =>
  get("/api/v1/manager-holdings/", payload);
export const getTotalExpenseRatio = (payload) =>
  get("/api/v1/total-expense-ratio/", payload);

export const getSubsRedsCalendar = (payload) =>
  get("/api/v1/subs-reds-calendar/", payload);

export const getEvaluations = (payload) => get("/api/v1/evaluations/", payload);
export const getEvaluationDates = () => get("/api/v1/evaluations/dates/");
export const getManagerHoldingsExcel = (payload) =>
  get("/api/v1/manager-holdings/export/", payload, { responseType: "blob" });
