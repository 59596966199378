import { Box, LinearProgress } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import {
  GridColumn as Column,
  GridToolbar,
  Grid as KGrid,
} from '@progress/kendo-react-grid'
import { useContext, useEffect, useMemo, useState } from 'react'

import { TableCell } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import FundStatusIndicator from '../../components/misc/FundStatusIndicator'
import SkeletonTableLoader from '../../components/skeletons/SkelatonPerformanceTableLoader'
import { statefulTableGenerator } from '../../components/tables'
import { UPDATE_SELECTED_SECURITY_LIST } from '../../constants'
import { FundsContext, PortfolioContext } from '../../contexts'
import { screener_fields } from '../../data/funds'
import { useSecurities } from '../../hooks'
import useAccessRights from '../../hooks/useAccessRights'
import { useMixpanel } from '../../hooks/useMixpanel'
import { useScreener } from '../../hooks/useScreener'
import router from '../../resources/history'
import { decidedSelectedClass, determineFormat } from '../../utils'
import FilterBar from './filterBar'
import ScreenerToolbar from './screenerToolbar'

const SuperGrid = statefulTableGenerator(KGrid)

export default function ScreenerTable() {
  const { mpTrack } = useMixpanel()
  const [currentView, setCurrentView] = useState('performance')
  const [screenerMode, setScreenerMode] = useState('aaap')
  const [nameFilter, setNameFilter] = useState('')
  const [filterBarOpen, setFilterBarOpen] = useState(true)
  const [cellFilters, setCellFilters] = useState({
    class_name: null,
    credit_correlation: null,
    directionality: null,
    equity_correlation: null,
    leverage: null,
    strategy: null,
    volatility: null,
  })

  const {
    aaap_cell_list,
    aaap_classes,
    hedge_fund_list,
    hedge_fund_list_status,
    selected_securities,
    strategy_list,
    loadingStates,
  } = useSecurities()

  const statusOptions = useMemo(() => {
    let options = new Set()

    if (aaap_cell_list) {
      aaap_cell_list.forEach((item) => {
        options.add(item.status)
      })
    }

    if (hedge_fund_list) {
      hedge_fund_list.forEach((item) => {
        options.add(item.status)
      })
    }

    return Array.from(options)
      .filter((item) => !!item)
      .sort((a, b) => a.localeCompare(b))
  }, [aaap_cell_list, hedge_fund_list])

  useEffect(() => {
    if (aaap_classes && aaap_classes.length > 0) {
      const selectedClass = decidedSelectedClass(aaap_classes)
      setCellFilters((i) => ({ ...i, class_name: selectedClass }))
    }
  }, [aaap_classes])

  const { setPortfolio } = useContext(PortfolioContext)

  const { hasAccessInternalClient } = useAccessRights()

  const name_column_width = '280px'
  const truncate_at = 35

  const getAssetDetails = (e) => {
    const { dataItem } = e
    const { id, name } = dataItem
    mpTrack({
      eventName: `View Fund Detail - ${name}`,
      properties: {
        From: location.pathname,
        ID: id,
        'Link Item': 'Fund Screener table row',
        Name: name,
      },
    })
    router.push(`/asset-detail/${id}/`)
  }

  const truncateName = (name) => {
    if (!name || name.length < truncate_at) return name
    const new_name = name.slice(0, truncate_at)
    return `${new_name}...`
  }

  const generateMultilineName = (props) => {
    const { dataItem } = props
    return (
      <td className="cell-name" onClick={() => getAssetDetails(props)}>
        <div className="aaap-name">
          <FundStatusIndicator status={dataItem['status']} />
          {dataItem['name']}
        </div>
        <Box className="underlying-name" ml={2}>
          {truncateName(dataItem['underlying_name'])}
        </Box>
      </td>
    )
  }

  const getColumnList = () => {
    switch (currentView) {
      case 'performance':
        return screener_fields.performance
      case 'risk':
        return screener_fields.risk
      default:
        return []
    }
  }

  const { dispatch } = useContext(FundsContext)

  const addFundToSelected = (e, payload) => {
    dispatch({ payload, type: UPDATE_SELECTED_SECURITY_LIST })
  }

  const generateCheckBoxField = (props) => {
    const selected_ids = selected_securities.map((security) => security.id)
    const checked = selected_ids.indexOf(props.dataItem.id) !== -1
    return (
      <td>
        <Checkbox
          checked={checked}
          color="primary"
          onChange={(e) => {
            addFundToSelected(e, props.dataItem)
          }}
          size="small"
        />
      </td>
    )
  }

  const filterNames = (item_list) => {
    const lower_name = nameFilter.toLocaleLowerCase()
    if (!nameFilter | (nameFilter.length < 3)) {
      return item_list
    } else {
      return item_list.filter((o) => {
        let {
          isin = '',
          name = '',
          principal_name = '',
          underlying_name = '',
        } = o
        isin = isin === null ? '' : isin
        principal_name = principal_name === null ? '' : principal_name
        const found =
          name.toLocaleLowerCase().includes(lower_name) |
          underlying_name.toLocaleLowerCase().includes(lower_name) |
          principal_name.toLocaleLowerCase().includes(lower_name) |
          (isin.toLocaleLowerCase() === lower_name)
        return found
      })
    }
  }
  const filterCells = (cellList) => {
    let filtered = [...cellList]
    for (const filterName in cellFilters) {
      if (Object.hasOwnProperty.call(cellFilters, filterName)) {
        const filterValue = cellFilters[filterName]
        if (filterValue) {
          filtered = filtered.filter((o) => o[filterName] === filterValue)
        }
      }
    }
    return filtered
  }

  const clearSelectedSecurities = () => {
    dispatch({ payload: [], type: UPDATE_SELECTED_SECURITY_LIST })
  }

  const fund_data_available = useMemo(
    () => hedge_fund_list_status === 'success',
    [hedge_fund_list_status],
  )

  const { isLoading: isLoadingStats } = useScreener({
    fund_data_available,
    screenerMode,
  })

  const changeScreenerMode = async (screener_mode) => {
    const fund_data_available = hedge_fund_list_status === 'success'
    setCellFilters({
      ...cellFilters,
      class_name: screener_mode === 'funds' ? null : 'Class X',
    })
    setScreenerMode(screener_mode)
  }

  const item_list = useMemo(() => {
    const non_empty_hf_list = hedge_fund_list.filter(
      (o) => !!o.calculated_statistics,
    )
    const statsAvailable = non_empty_hf_list.length > 0
    switch (screenerMode) {
      case 'aaap':
        return aaap_cell_list
      case 'funds':
        return statsAvailable ? non_empty_hf_list : hedge_fund_list
      default:
        return aaap_cell_list
    }
  }, [aaap_cell_list, hedge_fund_list, screenerMode])

  const strategyOptions = useMemo(() => {
    let options = new Set(item_list?.map((o) => o.strategy))

    return Array.from(options)
      .filter((item) => !!item)
      .sort((a, b) => a.localeCompare(b))
  }, [item_list])

  const data = filterNames(item_list)
  const filteredData = filterCells(data)
  const total = filteredData.length
  const columnList = getColumnList()
  const isLoading = loadingStates.aaap_cell_list
  return (
    <div className="screener-container">
      {isLoading ? (
        <SkeletonTableLoader />
      ) : (
        <SuperGrid
          className="screener-table"
          data={filteredData}
          filterable={false}
          pageSize={16}
          rowHeight={40}
          scrollable="virtual"
          selectedField="selected"
          style={{
            height: '600px',
          }}
          total={total}
        >
          <GridToolbar>
            <ScreenerToolbar
              changeScreenerMode={changeScreenerMode}
              clearSelectedSecurities={clearSelectedSecurities}
              currentView={currentView}
              filterBarOpen={filterBarOpen}
              full_access={hasAccessInternalClient}
              nameFilter={nameFilter}
              screenerMode={screenerMode}
              selected_securities={selected_securities}
              setCurrentView={setCurrentView}
              setFilterBarOpen={setFilterBarOpen}
              setNameFilter={setNameFilter}
              setPortfolio={setPortfolio}
            />
            {filterBarOpen && (
              <FilterBar
                aaap_classes={aaap_classes}
                cellFilters={cellFilters}
                setCellFilters={setCellFilters}
                statusOptions={statusOptions}
                strategy_list={strategyOptions}
              />
            )}
            {isLoading || isLoadingStats ? (
              <div
                style={{
                  width: '100%',
                  paddingLeft: '0px',
                  paddingRight: '8px',
                  margin: 0,
                }}
              >
                <LinearProgress
                  style={{
                    width: '100%',
                    margin: 0,
                    padding: 0,
                  }}
                />
              </div>
            ) : null}
          </GridToolbar>
          <Column
            cell={generateCheckBoxField}
            filterable={false}
            title=""
            width="48px"
          />
          <Column
            cell={generateMultilineName}
            field="name"
            title="Fund"
            width={name_column_width}
          />

          {columnList.map((col, idx) => {
            const format = determineFormat(col.field)
            return (
              <Column
                {...col}
                cell={
                  isLoadingStats && screenerMode === 'funds'
                    ? () => (
                        <TableCell key={idx}>
                          <Skeleton
                            variant="text"
                            width="60%"
                            height={30}
                            style={{ margin: '0 auto' }}
                          />
                        </TableCell>
                      )
                    : col.cell
                }
                format={format}
                key={idx}
              />
            )
          })}
        </SuperGrid>
      )}
    </div>
  )
}
