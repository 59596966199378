import { Box, Typography } from '@material-ui/core'
import React from 'react'

export const FaqsHeader = () => (
  <Box>
    <Typography variant="h6">FAQs</Typography>
  </Box>
)

export const FaqsContainer: React.FC<React.PropsWithChildren> = ({
  children
}) => (
  <Box
    alignItems="flex-start"
    display="flex"
    flexDirection="column"
    gridGap={16}
  >
    {children}
  </Box>
)

export const FaqsActionButtonsContainer: React.FC<React.PropsWithChildren> = ({
  children
}) => (
  <Box
    display="flex"
    gridGap={16}
    mb={2}
    flexWrap="wrap"
  >
    {children}
  </Box>
)