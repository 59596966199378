import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useQuery } from '@tanstack/react-query'
import { KendoFlatSelector as Selector } from '../../components'
import { getMailingList } from '../../services'

export default function NotifyDialog({
  open,
  onClose,
  handleNotify,
  setSelectedList,
  selectedList,
}) {
  const { data: mailingLists } = useQuery(
    ['mailing_list'],
    async () => {
      const response = await getMailingList()
      return response.data
    },
    { staleTime: Infinity },
  )

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Notify</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To send notification about this entry, please select the mailing list
          in the selector below.
        </DialogContentText>
        <Selector
          items={mailingLists}
          value={selectedList}
          onChange={({ target }) => {
            setSelectedList(target.value)
          }}
          dataItemKey="email"
          textField="name"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleNotify} color="primary">
          Notify
        </Button>
      </DialogActions>
    </Dialog>
  )
}
