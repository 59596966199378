import React, { Component } from "react";
import { CategoricalColumnChart } from "../../components";
import Grid from "@material-ui/core/Grid";

export default class decompositions extends Component {
  render() {
    const { internal_analysis, narrow } = this.props;
    const { report } = internal_analysis || {};
    const { funds, portfolio } = report || {};
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12} lg={narrow || 10} xl={narrow || 8}>
            <CategoricalColumnChart
              data={funds}
              title={"Funds"}
              category_field="index"
              stack={true}
              series_type="bar"
              category_crossing={-999}

              // index_auto
            />
          </Grid>
        </Grid>
        {portfolio && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} lg={narrow || 10} xl={narrow || 8}>
              <CategoricalColumnChart
                data={portfolio}
                title={"Portfolio"}
                category_field="index"
                stack={true}

                // index_auto
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}
