import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getBooklet } from '../services';
import { useSecurities } from './useSecurities';

const useFactSheets = () => {
  const { aaap_cell_list_map, isLoadingSecurities } = useSecurities();
  const { data: booklets, isLoading: isLoadingBooklet } = useQuery(
    ['booklets'],
    getBooklet,
    {
      select: (res) => res.data?.map((list) => ({
        ...list,
        funds: list?.funds?.map((it) => (aaap_cell_list_map[it.fund_id] ? ({ ...it, ...aaap_cell_list_map[it.fund_id] }) : it)),
      })),
    },
  );

  return useMemo(() => ({
    booklets,
    isLoadingBooklet: isLoadingSecurities || isLoadingBooklet,
    bookletMap: booklets?.reduce((obj, it) => Object.assign(obj, { [it.id]: it }), {}),
  }), [booklets, isLoadingBooklet, isLoadingSecurities]);
};

export default useFactSheets;
