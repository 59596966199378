import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

export default class footer extends Component {
  render() {
    const year = new Date().getFullYear()
    return (
      <footer className="footer-container">
        <div className="text-wrapper">
          <Typography variant="subtitle2" align="center">
            All Rights Reserved Antarctica Asset Management © {year}
          </Typography>
          <Box>
            <a href="https://radar-prod-static-files.s3.eu-west-2.amazonaws.com/static/Antarctica+Hub+Disclaimer+and+Terms+of+Use.pdf">
              Terms of use
            </a>
            <Box ml={2} pl={2} borderLeft="1px solid white" component="a" href={`${process.env.REACT_APP_ANTARCTICAAM_WEBSITE_URL}/privacy-policy`} target="_blank">
              PRIVACY POLICY
            </Box>
          </Box>
        </div>
      </footer>
    );
  }
}
