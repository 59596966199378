import React, { useMemo } from 'react'
import {
  Box, Button, IconButton, Typography,
} from '@material-ui/core'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import { useSecurities } from '../../hooks'
import { useMixpanel } from '../../hooks/useMixpanel'
import FactsheetFundTable from './FactsheetFundTable'
import { factSheetsUpdateMode } from './FactSheetsUpdateDialog'

const SelectedBooklet = ({
  selectedBooklet,
  disableActions,
  openBookletDialoigHandler,
  selectedFunds,
  updateFactSheets,
}) => {
  const { aaap_cell_list_map } = useSecurities()
  const { mpTrack } = useMixpanel()

  const clickDeleteFundsButtonHandler = () => {
    mpTrack({
      eventName: 'Removed Funds from Booklet',
      properties: {
        'Removed Fund IDs': Object.keys(selectedFunds).map((fundId) => fundId),
        'Removed Fund Names': Object.keys(selectedFunds).map((fundId) => aaap_cell_list_map[fundId]?.name),
        'Booklet Name': selectedBooklet.name,
        'Booklet ID': selectedBooklet.id,
      },
    })
    updateFactSheets({
      ...selectedBooklet,
      funds: selectedBooklet.funds.filter((it) => !selectedFunds[it.id]).map(({ id }) => ({ id, fund_id: id })),
    })
  }

  const selectedFundsCount = useMemo(() => Object.values(selectedFunds).filter((it) => it).length, [selectedFunds])

  return (
    <>
      {selectedBooklet?.length > 0 && !selectedBooklet && (
        <Box
          bgcolor="white"
          border="1px solid lightgray"
          mt={8}
          py={12}
        >
          <Typography align="center">Please select a booklet</Typography>
        </Box>
      )}
      {selectedBooklet?.funds && (
        <>
          <Box
            alignItems="center"
            display="flex"
            gridGap={12}
            justifyContent="space-between"
            mb={2}
          >
            <Box
              alignItems="center"
              display="flex"
              gridGap={4}
            >
              <Typography variant="h6">{selectedBooklet?.name}</Typography>
              <IconButton onClick={openBookletDialoigHandler(factSheetsUpdateMode.updateName)}>
                <EditRoundedIcon />
              </IconButton>
            </Box>
            <Box
              display="flex"
              gridGap={12}
            >
              <Button
                color="primary"
                disabled={disableActions}
                onClick={openBookletDialoigHandler(factSheetsUpdateMode.addFunds)}
                variant="contained"
              >
                Add fund
              </Button>
              <Button
                color="primary"
                disabled={disableActions || selectedFundsCount < 1}
                onClick={clickDeleteFundsButtonHandler}
                variant="contained"
              >
                Remove Funds
              </Button>
            </Box>
          </Box>
          <FactsheetFundTable disabled={disableActions} />
          {(!selectedBooklet?.funds || selectedBooklet?.funds?.length < 1) && (
            <Box mt={2}>
              <Typography align="center">No funds in this booklet</Typography>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default SelectedBooklet
