import Button from '@material-ui/core/Button'
import {
  GridColumn as Column,
  GridToolbar,
  Grid as KGrid,
} from '@progress/kendo-react-grid'
import { Input } from '@progress/kendo-react-inputs'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { KendoDropDown, statefulTableGenerator } from '../../components'
import SkeletonTableLoader from '../../components/skeletons/SkelatonPerformanceTableLoader'
import { useSecurities } from '../../hooks'
import useAccessRights from '../../hooks/useAccessRights'
import { useMixpanel } from '../../hooks/useMixpanel'
import router from '../../resources/history'

const SuperGrid = statefulTableGenerator(KGrid)

const defaultPortfolioType = 'Portfolio type...'

export default function Portfolios() {
  const { mpTrack } = useMixpanel()
  const { portfolio_list, loadingStates } = useSecurities()
  const [nameFilter, setNameFilter] = useState('')
  const [typeFilter, setTypeFilter] = useState(null)
  const { hasAccessAnalytics, hasAccessInternalClient } = useAccessRights()
  const portfolioTypes = useMemo(() => {
    const types = ['AAAP Portfolio', 'AAAP Model Portfolio']
    if (hasAccessAnalytics || hasAccessInternalClient) {
      types.push('Internal Private Portfolio')
    }
    if (hasAccessInternalClient) {
      types.push('Internal Public Portfolio')
    }
    return types
  }, [hasAccessAnalytics, hasAccessInternalClient])
  const filteredData = useMemo(() => {
    const filteredByType = portfolio_list.filter(
      ({ portfolio_type }) => portfolio_type !== 'Webfolio',
    )
    const filteredByName = nameFilter
      ? filteredByType.filter(({ name }) =>
          name.toLowerCase().includes(nameFilter.toLowerCase()),
        )
      : filteredByType
    const finalFiltered =
      typeFilter && typeFilter !== defaultPortfolioType
        ? filteredByName.filter(
            ({ portfolio_type }) => portfolio_type === typeFilter,
          )
        : filteredByName
    return finalFiltered
  }, [portfolio_list, nameFilter, typeFilter])

  const updateFilters = ({ target }) => {
    const { name, value } = target
    if (name === 'nameFilter') {
      setNameFilter(value)
    } else if (name === 'typeFilter') {
      setTypeFilter(value)
    }
  }

  const getAssetDetails = (id, name) => {
    mpTrack({
      eventName: `View Fund Detail - ${name}`,
      properties: {
        From: window.location.pathname,
        ID: id,
        'Link Item': 'My Portfolio table row',
        Name: name,
      },
    })
    router.push(`/asset-detail/${id}/`)
  }

  const generateMultilineName = ({ dataItem }) => {
    const { description = 'No description available', name = '' } = dataItem
    const truncate_at = 60
    const truncated_desc =
      description?.length <= truncate_at
        ? description
        : `${description?.substring(0, truncate_at)}...`
    const truncated_name =
      name?.length <= truncate_at - 8
        ? name
        : `${name?.substring(0, truncate_at - 8)}...`

    return (
      <td
        className="portfolio-name-row"
        onClick={() => getAssetDetails(dataItem.id, name)}
      >
        <div className="portfolio-name">{truncated_name}</div>
        <div className="portfolio-desc">{truncated_desc}</div>
      </td>
    )
  }

  return (
    <div className="portfolios-container">
      {loadingStates.portfolio_list ? (
        <SkeletonTableLoader />
      ) : (
        <SuperGrid
          className="portfolios-table"
          data={filteredData}
          filterable={false}
          pageable={false}
          scrollable="scrollable"
          style={{ height: '75vh' }}
        >
          <GridToolbar>
            <div className="toolbar">
              <Input
                className="name-filter"
                name="nameFilter"
                onChange={updateFilters}
                placeholder="Portfolio name..."
                value={nameFilter}
              />
              <KendoDropDown
                className="type-filter"
                defaultItem={defaultPortfolioType}
                items={portfolioTypes}
                name="typeFilter"
                onChange={updateFilters}
                value={typeFilter}
              />
              <Button
                className="new-portfolio-button"
                color="primary"
                component={Link}
                to="/screener/portfolio-builder"
                variant="contained"
              >
                New Portfolio
              </Button>
            </div>
          </GridToolbar>
          <Column
            cell={generateMultilineName}
            title="Portfolio"
            width="400px"
          />
          <Column
            field="calculated_statistics.stats_date"
            format={'{0:dd MMM yyyy}'}
            title="Date"
            width="200px"
          />
          <Column field="portfolio_type" title="Type" width="200px" />
          <Column
            field="calculated_statistics.last_month_ret"
            format={'{0:p2}'}
            title="MTD"
          />
          <Column
            field="calculated_statistics.ytd"
            format={'{0:p2}'}
            title="YTD"
          />
          <Column
            field="calculated_statistics.ann_ret"
            format={'{0:p2}'}
            title="Ann Return"
          />
          <Column
            field="calculated_statistics.ann_vol"
            format={'{0:p2}'}
            title="Ann Vol"
          />
          <Column
            field="calculated_statistics.sharpe"
            format={'{0:n2}'}
            title="Sharpe"
          />
        </SuperGrid>
      )}
    </div>
  )
}
