import { Box, Card, Grid, Typography } from '@material-ui/core'
import React from 'react'

const PortOptInputLayout = ({ children, header, toggleComponent }) => (
  <Box component="section" my={3}>
    <Card>
      <Box display="flex" flexDirection="column" gridGap={24} p={3}>
        {header && (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">{header}</Typography>
            {toggleComponent}
          </Box>
        )}
        {children}
      </Box>
    </Card>
  </Box>
)

export const PortOptGridContainer = ({ children }) => (
  <Grid container spacing={1}>
    {children}
  </Grid>
)

export const PortOptGridRow = ({ children, ...gridProps }) => (
  <Grid {...gridProps} container item spacing={1} xs={12}>
    {children}
  </Grid>
)

export default PortOptInputLayout
