import React, { useState, useEffect } from "react";
import ExposureInput from "./exposureMapInput";
import { KendoFlatSelector } from "../../components";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { cloneDeep } from "lodash";
import CopyIcon from "@material-ui/icons/FileCopy";
import {
  getMappedValueKeys,
  getExistingExposureMap,
  updateExistingExposureMap,
} from "../../services";
import { getSecurityDetails } from "../../services/helpers";
import { status as st } from "../../constants";
import { useSecurities } from "../../hooks";

const SOURCE_MAP = {
  1: "Webfolio",
  2: "Eurekahedge",
  3: "Bloomberg",
  4: "Allocator",
  5: "User",
  7: "Backfill",
  9: "BofA",
  11: "Webfolio (FoF Performance)",
  12: "Automated Routines (No Additional Data)",
  14: "Bloomberg (Yields)",
};

const MappingTable = ({
  exposure_key_map,
  mapped_keys,
  saved,
  saving,
  target_category,
  updateInputs,
  cloneRow,
  saveMap,
}) => {
  const allow_custom = !(target_category === "RISK");
  return (
    <div className="exposure-map-route">
      {exposure_key_map.length > 0 && (
        <div className="exposure-map">
          {exposure_key_map.map((row, idx) => {
            const { original_value, mapped_value, weight } = row || {};
            return (
              <div className="row" key={idx}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "12px",
                    paddingTop: "30px",
                    width: "100px",
                    maxWidth: "100px",
                    minWidth: "100px",
                    overflow: "hidden",
                  }}
                >
                  <span>
                    {SOURCE_MAP[original_value.primary_vendor_id] || "Error"}
                  </span>
                </div>

                <KendoFlatSelector
                  value={original_value}
                  dataItemKey="sub_classification"
                  textField="sub_classification"
                  onChange={() => {}}
                  label="Original Classification"
                  name="original_value"
                  disabled={true}
                  items={[]}
                />
                <KendoFlatSelector
                  items={mapped_keys}
                  value={mapped_value || ""}
                  dataItemKey="sub_classification"
                  textField="sub_classification"
                  onChange={(e) => {
                    updateInputs(idx, e);
                  }}
                  label="Mapped Classification"
                  name="mapped_value"
                  allowCustom={allow_custom}
                />

                <NumericTextBox
                  value={weight}
                  label="Weight"
                  name="weight"
                  onChange={(e) => {
                    updateInputs(idx, e);
                  }}
                />
                <IconButton
                  onClick={() => {
                    cloneRow(idx);
                  }}
                >
                  <CopyIcon />
                </IconButton>
              </div>
            );
          })}
          <div className="row submit">
            <Button
              onClick={saveMap}
              fullWidth
              variant="contained"
              color="primary"
              disabled={!!(saved || saving)}
            >
              {saved ? "Saved" : "Save Map"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default function ExposureMap() {
  const [keyMap, setKeyMap] = useState([]);
  const [inputs, setInputs] = useState({
    original_classification: null,
    mapped_classification: null,
    category: "UNMAPPED",
    security: "",
    target_category: "RISK",
  });
  const [originalClassifications, setOriginalClassifications] = useState([]);
  const [valueKeyList, setValueKeyList] = useState([]);
  const { search_list: searchList } = useSecurities();

  const [mapStatus, setMapStatus] = useState(null);

  const getDetails = async (payload) => {
    const details = await getSecurityDetails(payload, searchList);
    const { meta_lists } = details || {};
    const { classifications = [] } = meta_lists || {};
    setOriginalClassifications(classifications);
  };

  const fetchValueKeys = async () => {
    try {
      const response = await getMappedValueKeys();
      setValueKeyList(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchValueKeys();
  }, []);

  const mappedClassifications = [
    ...new Set(
      valueKeyList
        .filter((o) => o.category === inputs.target_category)
        .map((o) => o.classification),
    ),
  ];

  const mappedKeys = valueKeyList.filter(
    (o) =>
      o.classification === inputs.mapped_classification &&
      o.category === inputs.target_category,
  );

  const updateInputs = ({ value, name }) => {
    setInputs((i) => ({ ...i, [name]: value }));
    if (name === "security" && value) {
      const { id: fund_id, type: security_type } = value || {};
      getDetails({ fund_id, security_type });
    }
  };

  const getExposures = async () => {
    const {
      original_classification,
      mapped_classification,
      security,
      category,
    } = inputs || {};
    if (
      !original_classification ||
      !mapped_classification ||
      !security ||
      !category
    ) {
      return;
    }
    const payload = {
      original_classification: original_classification.classification,
      mapped_classification,
      security: security.id,
      category,
    };
    try {
      const response = await getExistingExposureMap(payload);
      setKeyMap(response.data);
    } catch (error) {}
  };

  const cloneRow = (idx) => {
    let updated_inputs = cloneDeep(keyMap);
    const row = updated_inputs[idx];
    updated_inputs.splice(idx, 0, cloneDeep(row));
    setKeyMap(updated_inputs);
  };

  const updateMap = (i, { target }) => {
    setMapStatus(null);
    let updated_inputs = cloneDeep(keyMap);
    let { value, name } = target || {};
    if (name === "mapped_value" && value && !value.classification) {
      const {
        mapped_classification: classification,
        target_category: category,
      } = inputs || {};
      value.sub_classification = value.sub_classification.toUpperCase();
      value = { ...value, category, classification, primary_vendor_id: 5 };
    }
    updated_inputs[i][name] = value;
    setKeyMap(updated_inputs);
  };

  const saveMap = async () => {
    setMapStatus(st.request);
    let clean_exposure_map = [];
    for (let i = 0; i < keyMap.length; i++) {
      const entry = keyMap[i];
      const { mapped_value } = entry;
      if (mapped_value) {
        clean_exposure_map.push(entry);
      }
    }
    const {
      category,
      original_classification,
      mapped_classification,
      security,
    } = inputs || {};

    const payload = {
      security: security.id,
      original_classification: original_classification.classification,
      mapped_classification,
      category,
      data: clean_exposure_map,
    };
    try {
      const response = await updateExistingExposureMap(payload);
      setKeyMap(response.data);
      setMapStatus(st.success);
    } catch (error) {
      setMapStatus(st.failure);
    }
  };

  return (
    <div>
      <ExposureInput
        exposure_key_map_inputs={inputs}
        original_classifications={originalClassifications}
        mapped_classifications={mappedClassifications}
        updateInputs={updateInputs}
        getExposures={getExposures}
        search_list={searchList}
      />
      <MappingTable
        exposure_key_map={keyMap}
        mapped_keys={mappedKeys}
        saved={mapStatus === st.success}
        saving={mapStatus === st.request}
        target_category={inputs.target_category}
        updateInputs={updateMap}
        cloneRow={cloneRow}
        saveMap={saveMap}
      />
    </div>
  );
}
