import { Box, Button } from '@material-ui/core'
import React from 'react'
import { useStatisticsReport } from '../../contexts/statistics-report.context'

const ReportFormBuilder = ({ formData, submitButtonText }) => {
  const { reportSnippets, submitNewImplementationForm } = useStatisticsReport()
  return reportSnippets && formData ? (
    <form onSubmit={submitNewImplementationForm}>
      {formData.map(({ type, helperText, ...rest }, idx) => (
        <Box key={idx} position="relative" mb={helperText ? 5 : 0}>
          {reportSnippets[type](...Object.values(rest))}
          {helperText && (
            <Box position="absolute" bottom="-24px" color="gray">
              {helperText}
            </Box>
          )}
        </Box>
      ))}
      <Button color="primary" variant="contained" fullWidth type="submit">
        {submitButtonText || 'Generate Report'}
      </Button>
    </form>
  ) : null
}

export default ReportFormBuilder
