export const tsLineChartData = {
  columns: [
    1504137600000,
    1506729600000,
    1509408000000,
    1512000000000,
    1514678400000,
    1517356800000,
    1519776000000,
    1522454400000,
    1525046400000,
    1527724800000,
    1530316800000,
    1532995200000,
    1535673600000,
    1538265600000,
    1540944000000,
    1543536000000,
    1546214400000,
    1548892800000,
    1551312000000,
    1553990400000,
    1556582400000,
    1559260800000,
    1561852800000,
    1564531200000,
    1567209600000,
    1569801600000,
    1572480000000,
    1575072000000,
    1577750400000,
    1580428800000,
    1582934400000,
    1585612800000,
    1588204800000,
    1590883200000,
    1593475200000,
    1596153600000,
    1598832000000,
    1601424000000,
    1604102400000,
    1606694400000,
    1609372800000,
  ],
  index: ["mean", "errorband"],
  data: [
    [
      -0.0269856835,
      0.0398150884,
      -0.0135694778,
      0.0316778896,
      0.1117685527,
      0.1490983106,
      0.2022612034,
      0.1499580597,
      0.1650713332,
      0.0767817827,
      0.0504632154,
      0.026207025,
      -0.0379704354,
      -0.0372144943,
      -0.038948635,
      -0.1030859135,
      -0.1392139298,
      -0.1018920844,
      0.0143218564,
      -0.0891930447,
      0.0572454093,
      0.0760680716,
      0.1927011401,
      0.1705079755,
      0.1328086408,
      0.2660166115,
      0.1873550089,
      0.314011294,
      0.3176247588,
      0.2649394592,
      0.2190404492,
      0.2128366911,
      0.1650960184,
      0.1249291995,
      0.0931825209,
      0.0836809542,
      0.0221245487,
      -0.0658673855,
      -0.0112738843,
      0.3666052533,
      0.380226272,
    ],
    [
      [-0.1136474263, 0.0596760594],
      [-0.0777381025, 0.1573682793],
      [-0.1272210439, 0.1000820882],
      [-0.0970585593, 0.1604143385],
      [0.0139530134, 0.2095840921],
      [0.0590491886, 0.2391474327],
      [0.1178037611, 0.2867186456],
      [0.0849153002, 0.2150008193],
      [0.1069910006, 0.2231516658],
      [0.028128512, 0.1254350534],
      [-0.0082855479, 0.1092119787],
      [-0.0337553736, 0.0861694237],
      [-0.0887649588, 0.012824088],
      [-0.0886776254, 0.0142486368],
      [-0.0902993867, 0.0124021167],
      [-0.1892690177, -0.0169028094],
      [-0.2355004552, -0.0429274044],
      [-0.2120094516, 0.0082252828],
      [-0.1365943507, 0.1652380636],
      [-0.2472177269, 0.0688316375],
      [-0.1613976842, 0.2758885029],
      [-0.1358680213, 0.2880041645],
      [-0.0013675739, 0.3867698542],
      [-0.0240205326, 0.3650364835],
      [-0.0517259727, 0.3173432543],
      [0.1107897911, 0.4212434319],
      [0.0726034855, 0.3021065322],
      [0.2612684452, 0.3667541427],
      [0.2652599144, 0.3699896032],
      [0.1835564015, 0.346322517],
      [0.1418076654, 0.2962732329],
      [0.1359571466, 0.2897162355],
      [0.1081948558, 0.2219971809],
      [0.0652001979, 0.184658201],
      [0.0377563862, 0.1486086556],
      [0.0218765627, 0.1454853457],
      [-0.0494795722, 0.0937286695],
      [-0.1438601171, 0.0121253461],
      [-0.1337443346, 0.111196566],
      [0.0234066686, 0.709803838],
      [0.0382249974, 0.7222275467],
    ],
  ],
};
export const meanSummary = {
  index: ["mean", "errorband"],
  columns: [
    "TIG Zebedee Core Fund",
    "MSCI Europe Net TR Local Index",
    "MSCI Europe Financials Net TR Local Index",
    "MSCI Europe Materials A Net TR Local Index",
    "HFRI EH Total Index",
  ],
  data: [
    [
      0.11952492381955815,
      0.04745195219080389,
      -0.01377491832259492,
      0.0875719346676822,
      0.0849312626086236,
    ],
    [
      [0.0763954323654617, 0.1626544152736546],
      [-0.0051449601697625, 0.10004886455137028],
      [-0.09619996044164633, 0.06865012379645649],
      [0.026519640743219955, 0.14862422859214447],
      [0.04705120702255166, 0.12281131819469554],
    ],
  ],
};
