import React, { useState } from "react"

import {
  ButtonsWrapper,
  CardLink,
  HeaderWrapper,
  StyledCard,
  StyledCardBody,
  StyledCardFooter,
  StyledCardFund,
  StyledCardFundName,
  StyledCardMetaRow,
  StyledCardMetaSector,
  StyledCardMetaType,
  StyledCardRow,
  StyledCardStatus,
  StyledCardTable,
  StyledCardTableCell,
  StyledCardTableCellType,
  StyledData,
  StyledDataHeading,
  StyledDataValue,
  StyledFundLogo,
  StyledHeader,
  StyledLink,
} from "./PrivateEquityCard.styled"

export const formatNumberToCurrency = (number, currency = "USD") => {
  return number === 0
    ? "TBD"
    : new Intl.NumberFormat("en-US", {
      currency: currency,
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
      notation: "compact",
      style: "currency",
      unitDisplay: "short",
    }).format(number)
}

export const setFundStatus = (fundStatus) => {
  if (fundStatus === "OPN") {
    return { bgColor: "#B8D6EB", color: "#0A2839", width: "20%" }
  } else if (fundStatus === "CMS") {
    return { bgColor: "#fec000", color: "#000000", width: "30%" }
  } else return { bgColor: "#0A2839", color: "#ffffff", width: "20%" } //TODO: needs changing for closed
}

export const peFundStatusKey = {
  CLD: 'CLD',
  CMS: 'CMS',
  OPN: 'OPN',
}

export const fundStatusMap = {
  [peFundStatusKey.CLD]: "CLOSED",
  [peFundStatusKey.CMS]: "COMING SOON",
  [peFundStatusKey.OPN]: "OPEN",
}

export const fundStatusDisplayOrder = Object.keys(fundStatusMap)

const PrivateEquityCard = ({ fund }) => {
  const [isBlurred, setIsBlurred] = useState(false)

  const fundInvestmentMap = {
    COR: "CORE",
    SAT: "SATELLITE",
  }

  const currency = fund?.terms_legal?.currency

  const targetSize = fund.target_size
  const minInvestment = formatNumberToCurrency(fund.min_investment, currency)

  return (
    <CardLink to={`/private-markets/funds/${fund.id}`}>
      <StyledCard>
        <StyledHeader>
          <HeaderWrapper>
            <StyledFundLogo
              alt="fund-logo"
              isBlurred={isBlurred}
              src={fund.logo}
            />
            <StyledCardStatus isOpen={setFundStatus(fund.fund_status)}>
              {fundStatusMap[fund.fund_status]}
            </StyledCardStatus>
          </HeaderWrapper>
          <StyledCardMetaRow>
            <StyledCardFund>
              <StyledCardFundName isBlurred={isBlurred}>
                {fund.name}
              </StyledCardFundName>
            </StyledCardFund>
          </StyledCardMetaRow>
        </StyledHeader>
        <ButtonsWrapper>
          <StyledCardMetaType>
            {fundInvestmentMap[fund.investment_category]}
          </StyledCardMetaType>
          <StyledCardMetaSector>{fund.target_sector}</StyledCardMetaSector>
        </ButtonsWrapper>
        <StyledCardBody>
          <StyledCardRow>
            <StyledData>
              <StyledDataHeading>TARGET SIZE</StyledDataHeading>
              <StyledDataValue>{targetSize}</StyledDataValue>
            </StyledData>
            <StyledData>
              <StyledDataHeading>FUND LIFE</StyledDataHeading>
              <StyledDataValue>{fund.fund_life}</StyledDataValue>
            </StyledData>
            <StyledData>
              <StyledDataHeading>MIN INVESTMENT</StyledDataHeading>
              <StyledDataValue>{minInvestment}</StyledDataValue>
            </StyledData>
          </StyledCardRow>
        </StyledCardBody>
        <StyledCardTable>
          <tbody>
            <tr>
              <StyledCardTableCellType>Fund Type</StyledCardTableCellType>
              <StyledCardTableCell>{fund.fund_type}</StyledCardTableCell>
            </tr>
            <tr>
              <StyledCardTableCellType>Geography</StyledCardTableCellType>
              <StyledCardTableCell>{fund.geography}</StyledCardTableCell>
            </tr>
            <tr>
              <StyledCardTableCellType>Industry Focus</StyledCardTableCellType>
              <StyledCardTableCell>{fund.industry_focus}</StyledCardTableCell>
            </tr>
            <tr>
              <StyledCardTableCellType>Summary</StyledCardTableCellType>
              <StyledCardTableCell>{fund.summary}</StyledCardTableCell>
            </tr>
          </tbody>
        </StyledCardTable>
        <StyledCardFooter>
          {!isBlurred ? (
            <StyledLink to={`/private-markets/funds/${fund.id}`}>
              VIEW DETAILS
            </StyledLink>
          ) : (
            <div>Coming Soon</div>
          )}
        </StyledCardFooter>
      </StyledCard>
    </CardLink>
  )
}

export default PrivateEquityCard
