import React, { Component } from "react";
import { GenericTable } from "../../components";
import { exposure_analysis_lists } from "../../data/analysis";
import Grid from "@material-ui/core/Grid";
import {
  Grid as KGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";

export default class tabledReport extends Component {
  renderSimpleTable = (report_type) => {
    const { time_series_charts } = exposure_analysis_lists;

    const { exposure_analysis } = this.props;
    const { report } = exposure_analysis || {};
    return (
      <React.Fragment>
        {time_series_charts[report_type].map((entry, idx) => {
          return (
            <Grid key={idx} item>
              <GenericTable
                data={report[entry.id]}
                table_name={entry.name}
                total_row
                total_col
                highlight_name
                blue_heading
              />
            </Grid>
          );
        })}
      </React.Fragment>
    );
  };

  renderQuarterlyTable = () => {
    const { exposure_analysis } = this.props;
    const { report } = exposure_analysis || {};
    const { data, names } = report;
    return (
      <Grid item>
        <div className="generic-table-container">
          <KGrid data={data} className="generic-table total-row blue-heading">
            <Column title=" " field={"index"} width="250px" />
            <Column title={names.previous_date_str}>
              <Column field="previous_long" title="Long" format={"{0:p2}"} />
              <Column field="previous_short" title="Short" format={"{0:p2}"} />
              <Column field="previous_gross" title="Gross" format={"{0:p2}"} />
              <Column field="previous_net" title="Net" format={"{0:p2}"} />
            </Column>
            <Column title={names.current_dat_str}>
              <Column field="current_long" title="Long" format={"{0:p2}"} />
              <Column field="current_short" title="Short" format={"{0:p2}"} />
              <Column field="current_gross" title="Gross" format={"{0:p2}"} />
              <Column field="current_net" title="Net" format={"{0:p2}"} />
            </Column>
            <Column
              title={names.quarter_net_change_str}
              field={"net_change"}
              format={"{0:p2}"}
            />
            <Column
              title={names.quarter_gross_change_str}
              field={"gross_change"}
              format={"{0:p2}"}
            />
          </KGrid>
        </div>
      </Grid>
    );
  };

  renderReport = () => {
    const { exposure_analysis_input } = this.props;
    const { table } = exposure_analysis_input || {};
    const { id: table_name } = table || {};
    switch (table_name) {
      case "quarterly_change_table":
        return this.renderQuarterlyTable();
      case "roc":
        return this.renderSimpleTable("roc_table");
      case "attribution":
      case "exposure":
        return this.renderSimpleTable(table_name);
      default:
        return <div />;
    }
  };

  render() {
    return (
      <div className="exposure-table-report">
        <Grid container direction="column" spacing={2}>
          {this.renderReport()}
        </Grid>
      </div>
    );
  }
}
