import { notifications } from '@mantine/notifications'
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { PeerGroup } from '../redux/peerGroupsSlice'
import { updatePeerGroup } from "../services/peer-groups"
import { convertCamelToSnakeCase } from "../utils/convertCamelToSnakeCase"

const useUpdatePeerGroup = () => {
  const queryClient = useQueryClient()
  const { isLoading, mutateAsync: updatePeerGroupInfo } = useMutation(
    async (data: PeerGroup) => updatePeerGroup(convertCamelToSnakeCase(data)),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['peer-groups'])
      },
    }
  )

  return { isLoading, updatePeerGroupInfo }
}

export const useUpdatePeerGroupNotification = () => {
  const showNotification = ({ groupName, message }: { message: string; groupName: string }) => {
    notifications.show({ message, title: `${groupName} updated successfully` })
  }

  return { showNotification }
}

export default useUpdatePeerGroup
