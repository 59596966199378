export const decidedSelectedClass = (classList) => {
  if (!classList) {
    return null;
  } else if (classList.indexOf("Class X") !== -1) {
    return "Class X";
  } else if (classList.indexOf("Class B") !== -1) {
    return "Class B";
  } else if (classList.indexOf("Class H") !== -1) {
    return "Class H";
  } else {
    return null;
  }
};
