import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import FormTextField from '../../components/forms/FormTextField'
import {
  positivePercentage,
  requiredString,
  validPositiveLowerBound,
  validUpperBound,
  withRequired,
} from '../../utils/yup-utils'
import { defaultGroupValues, groupConstraintsInputName } from './form-default-values'
import OptimiserPercentageSumLabel from './layout/OptimiserPercentageSumLabel'
import { getArrayValueFromSchema } from './optimiser-validations'
import PortOptInputLayout, { PortOptGridContainer, PortOptGridRow } from './PortOptInputLayout'

export const portOptGroupConstraintsSchema = yup.object().shape({
  groupName: requiredString().test(
    'group-match',
    'Must match a group name from assets',
    function (value) {
      const schemaValue = this.from.find((it) => !!it.value[groupConstraintsInputName])?.value
      const assetGroupNames = schemaValue?.assets?.map((asset) => asset.group)
      return assetGroupNames?.includes(value)
    },
  ),
  lowerBound: withRequired(validPositiveLowerBound().concat(positivePercentage()).test('sum-of-lowerBound', 'The sum of all lower bounds cannot exceed 100%', function () {
    const grpConstraints = getArrayValueFromSchema(this.from, groupConstraintsInputName)
    const sum = grpConstraints.reduce((acc, grp) => acc + parseFloat(grp.lowerBound || 0), 0)
    return sum <= 100
  })),
  upperBound: validUpperBound(undefined, positivePercentage().test('sum-of-upperBound', 'The sum of all upper bounds should be at least 100%', function () {
    const grpConstraints = getArrayValueFromSchema(this.from, groupConstraintsInputName)
    const sum = grpConstraints.reduce((acc, grp) => acc + parseFloat(grp.upperBound || 0), 0)
    return sum >= 100
  })),
})

const PortOptGroupConstraintsInput = () => {
  const {
    control, formState: { errors }, register, watch,
  } = useFormContext()
  const groupContraintsArray = useFieldArray({ control, name: groupConstraintsInputName })
  const enableGroupConstraints = watch('enableGroupConstraints', false)

  const clickAddButtonHandler = () => {
    groupContraintsArray.append(defaultGroupValues)
  }

  const clickRemoveButtonHandler = (index) => () => {
    groupContraintsArray.remove(index)
  }

  return (
    <PortOptInputLayout
      header="Group Constraint Options"
      toggleComponent={(
        <FormControlLabel
          control={(
            <Switch
              checked={enableGroupConstraints}
              name="enableGroupConstraints"
              {...register('enableGroupConstraints')}
            />
          )}
        />
      )}
    >
      {
        enableGroupConstraints && (
          <>
            <PortOptGridContainer>
              {
                groupContraintsArray.fields.map((it, idx) => (
                  <PortOptGridRow
                    key={it.id}
                  >
                    <Grid
                      item
                      xs={2}
                    >
                      <FormTextField
                        errorMessage={errors?.[groupConstraintsInputName]?.[idx]?.groupName?.message}
                        label="Group"
                        name={`${groupConstraintsInputName}.${idx}.groupName`}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                    >
                      <FormTextField
                        endAdornment="%"
                        errorMessage={errors?.[groupConstraintsInputName]?.[idx]?.lowerBound?.message}
                        label="Weight Lower Bound"
                        name={`${groupConstraintsInputName}.${idx}.lowerBound`}
                        type="number"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                    >
                      <FormTextField
                        endAdornment="%"
                        errorMessage={errors?.[groupConstraintsInputName]?.[idx]?.upperBound?.message}
                        label="Weight Upper Bound"
                        name={`${groupConstraintsInputName}.${idx}.upperBound`}
                        type="number"
                      />
                    </Grid>
                    <Grid item>
                      <Box pt={1.5}>
                        {
                          groupContraintsArray.fields.length > 1 && (
                            <IconButton onClick={clickRemoveButtonHandler(idx)}>
                              <DeleteIcon />
                            </IconButton>
                          )
                        }
                      </Box>
                    </Grid>
                  </PortOptGridRow>
                ))
              }
              <PortOptGridRow>
                <Grid
                  item
                  xs={2}
                />
                <Grid
                  item
                  xs={2}
                >
                  <OptimiserPercentageSumLabel
                    arrayName={groupConstraintsInputName}
                    targetKey="lowerBound"
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                >
                  <OptimiserPercentageSumLabel
                    arrayName={groupConstraintsInputName}
                    targetKey="upperBound"
                  />
                </Grid>
              </PortOptGridRow>
            </PortOptGridContainer>
            <Box mt={2}>
              <Button
                color="primary"
                onClick={clickAddButtonHandler}
                variant="contained"
              >
                Add Additional Groups
              </Button>
            </Box>
          </>
        )
      }
    </PortOptInputLayout>
  )
}

export default PortOptGroupConstraintsInput
