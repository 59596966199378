import React, { Component } from "react";
import { TotalExpenseChart } from "../totalExpense";

export default class totalExpense extends Component {
  render() {
    return (
      <div>
        <TotalExpenseChart {...this.state} />
      </div>
    );
  }
}
