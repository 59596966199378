import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import moment from 'moment'
import qs from 'qs'
import { useHistory } from 'react-router-dom'
import { GenericTable } from '../../components'

const field_mapping = new Map(
  Object.entries({
    ann_ret: 'Annualised Return',
    ann_vol: 'Annualised Vol',
    sharpe: 'Sharpe',
    ytd: 'YTD',
    three_month_ret: 'Last 3 Month',
    six_month_ret: 'Last 6 Month',
    twelve_month_ret: 'Last 12 Month',
    three_years_ret: 'Last 3Y (Annualised)',
    five_years_ret: 'Last 5Y (Annualised)',
    gain_dev: 'Gain Dev',
    loss_dev: 'Loss Dev',
    gain_to_loss_dev: 'Gain/Loss Dev',
    positive_months: 'Positive Months',
    negative_months: 'Negative Months',
    win_ratio: 'Win Ratio',
    avg_win: 'Average Win',
    avg_loss: 'Average Loss',
    largest_win: 'Largest Win',
    largest_loss: 'Largest Loss',
    max_dd: 'Max Drawdown',
    mean: 'Mean',
    std_dev: 'St Dev',
    kurt: 'Kurt',
    skew: 'Skew',
    Sortino: 'Sortino',
    Omega: 'Omega',
    MTD: 'MTD',
    'Total Return': 'Total Return',
    Correlation: 'Correlation',
    Alpha: 'Alpha',
    Beta: 'Beta',
  }),
)

const StatisticsTab = ({ security_details }) => {
  const history = useHistory()

  const openCustomReport = () => {
    const { benchmarks = [], id } = security_details
    let fund_list = benchmarks.map((o) => o.id)
    if (fund_list.length === 0) {
      fund_list = [292, 513, 60]
    }
    fund_list.splice(0, 0, id)
    const params = qs.stringify({ fund_list, report_type: 'Summary Stats' })
    history.push({
      pathname: '/quick-analysis',
      search: params,
    })
  }

  const {
    summary_stats_table,
    calculated_statistics = {},
    name: securityName,
  } = security_details || {}

  const mapped_calculated_statistics = Array.from(field_mapping.keys())
    .map((key) => ({
      index: field_mapping.get(key),
      [securityName]: calculated_statistics[key],
    }))
    .filter(Boolean)

  const { stats_table, start_date, end_date } = summary_stats_table || {}
  const start = moment(start_date).format('MMM-YY')
  const end = moment(end_date).format('MMM-YY')

  return (
    <div className="statistics-table-container">
      <div className="dates-container">
        <Button
          onClick={openCustomReport}
          variant="contained"
          color="primary"
          size="small"
        >
          Custom report
        </Button>
        <Typography variant="caption" className="stats-date">
          <CalendarIcon className="calendar-icon" />
          {start} to {end}
        </Typography>
      </div>
      <GenericTable
        style={{ height: '600px' }}
        scrollable="scrollable"
        data={stats_table || mapped_calculated_statistics || []}
      />
    </div>
  )
}

export default StatisticsTab
