const determineFormat = (field_name) => {
  const decimal_fields = [
    "calculated_statistics.sharpe",
    "calculated_statistics.sortino",
    "calculated_statistics.omega",
    "calculated_statistics.win_ratio",
    "calculated_statistics.positive_months",
    "calculated_statistics.gain_to_loss_dev",
  ]
  const single_decimal_fields = [
    "calculated_statistics.skew",
    "calculated_statistics.kurt",
  ]
  const date_fields = ["calculated_statistics.stats_date"]
  if (decimal_fields.indexOf(field_name) !== -1) return "{0:n2}"
  else if (single_decimal_fields.indexOf(field_name) !== -1) return "{0:n1}"
  else if (date_fields.indexOf(field_name) !== -1) return "{0:dd MMM yyyy}"
  else {
    return "{0:p2}"
  }
}

export default determineFormat
