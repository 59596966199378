function convertSnakeToCamel(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertSnakeToCamel(item));
  }

  const camelObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelKey = key.replace(/_([a-z])/g, (match, group1) => group1.toUpperCase());
      camelObj[camelKey] = convertSnakeToCamel(obj[key]);
    }
  }

  return camelObj;
}

export default convertSnakeToCamel;
