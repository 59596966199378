import React from "react";
import { GenericTable } from "../../components";
import Typography from "@material-ui/core/Typography";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";

export default function StatsTable({ external_analysis }) {
  const { report } = external_analysis;
  const { stats_table = [], start_date, end_date } = report || {};
  const start = moment(start_date).format("MMM-YY");
  const end = moment(end_date).format("MMM-YY");

  return (
    <div className="statistics-table-container">
      <div className="dates-container">
        <Typography className="stats-date">
          <CalendarIcon className="calendar-icon" />
          {start} to {end}
        </Typography>
      </div>
      <GenericTable
        style={{ height: "500px" }}
        scrollable="scrollable"
        data={stats_table || []}
      />
    </div>
  );
}
