import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  // StockChart,
  Chart,
  // ChartTitle,
  // ChartPaneTitle,
  ChartAxisDefaults,
  ChartSeriesDefaults,
  ChartValueAxis,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  // ChartNavigator,
  // ChartNavigatorSelect,
  // ChartNavigatorSeries,
  // ChartNavigatorSeriesItem
} from '@progress/kendo-react-charts'
import { extractSeriesNames, seriesRemoveUnsafeSymbols } from '../../utils'
import Typography from '@material-ui/core/Typography'
import { antarctica_palette } from '../../data'
import palette from 'google-palette'

export default class lineTimeSeries extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    category_field: PropTypes.string,
    minimum: PropTypes.bool,
    format: PropTypes.string,
  }

  determineMinimum = () => {
    const { data = [] } = this.props
    const series = extractSeriesNames(data)

    let def_min = 1000
    for (let i = 0; i < data.length; i++) {
      const element = data[i]
      for (let j = 0; j < series.length; j++) {
        const name = series[j]
        const value = element[name]
        if (value < def_min) {
          def_min = value
        }
      }
    }
    def_min *= 0.8

    return Math.ceil(def_min / 100) * 100
  }

  determineMaximum = () => {
    const { data = [] } = this.props
    const series = extractSeriesNames(data)

    let def_max = -Infinity
    for (let i = 0; i < data.length; i++) {
      const element = data[i]
      for (let j = 0; j < series.length; j++) {
        const name = series[j]
        const value = element[name]
        if (typeof value === 'number' && !isNaN(value) && value > def_max) {
          def_max = value
        }
      }
    }

    if (def_max === -Infinity) {
      return 0 // Return 0 if no valid numbers found
    }

    return def_max === 0 ? 0.01 : null
  }

  render() {
    const {
      data: unsafe_data,
      title = '',
      category_field = 'perf_date',
      minimum = false,
      format = '{0:p2}',
      series_type = 'line',
      base_unit = 'months',
      standardPalette,
    } = this.props
    const data = seriesRemoveUnsafeSymbols(unsafe_data)
    const label_step = data ? Math.round(data.length / 6) : 12
    const series = extractSeriesNames(data)
    const min_number = minimum ? this.determineMinimum() : null
    const max_number = this.determineMaximum()
    const stack = series_type === 'area'
    let current_palette = antarctica_palette
    if (standardPalette) {
      current_palette =
        series.length < 65
          ? palette('mpn65', series.length)
          : palette('tol-dv', series.length)
      current_palette = current_palette.map((o) => `#${o.replace('#', '')}`)
    }
    return (
      <div className="line-ts-chart-container">
        <div className="title-container">
          <Typography align="center" variant="h6">
            {title}
          </Typography>
        </div>
        <Chart pannable={false} zoomable={false}>
          <ChartLegend visible="true" position="bottom" margin={20} />
          <ChartSeriesDefaults
            markers={{ visible: false }}
            type={series_type}
          />
          <ChartTooltip format={`{1}`} visible={true} />
          <ChartTooltip shared={true} format={format} visible={true} />
          <ChartAxisDefaults
            majorGridLines={{ step: label_step / 2, visible: false }}
          />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              labels={{ step: label_step }}
              baseUnit={base_unit}
              type="date"
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              {...(min_number !== null && { min: min_number })}
              {...(max_number !== null && { max: max_number })}
              majorGridLines={{ step: 1 }}
              labels={{ format: format }}
              axisCrossingValue={-1000}
            />
          </ChartValueAxis>
          <ChartSeries>
            {series.map((ser, id) => (
              <ChartSeriesItem
                key={id}
                data={data}
                name={ser}
                field={ser}
                color={current_palette[id]}
                // style={"smooth"}
                categoryField={category_field}
                stack={stack}
              />
            ))}
          </ChartSeries>
        </Chart>
      </div>
    )
  }
}
