import React, { useMemo, useState } from "react";

import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

export default function GroupManager({
  selectedGroup,
  setSelectedGroup,
  groupList,
  setGroupList,
  updateGroups,
}) {
  const [newGroup, setNewGroup] = useState("");

  const renderGroupList = useMemo(() => (
    <List dense className="toolbar-menu">
      {groupList.map((g) => (
        <ListItem
          className={g === selectedGroup ? "selected-group" : ""}
          key={g.id}
          onClick={() => setSelectedGroup(g)}
        >
          <ListItemText primary={g.name} />
        </ListItem>
      ))}
    </List>
  ), [groupList])

  const createNewGroup = async () => {
    try {
      setNewGroup("");
      const response = await updateGroups({
        name: newGroup,
        allowed_securities: [],
        permissions: [],
      });
      const group = response.data;
      setGroupList([group, ...groupList]);
    } catch (error) { }
  };


  return (
    <div className="group-list-container groups">
      <div className="new-group-form">
        <TextField
          fullWidth
          value={newGroup}
          onChange={(e) => setNewGroup(e.target.value)}
          placeholder="New Group"
        />
        <Button onClick={createNewGroup} variant="contained" color="primary">
          Add
        </Button>
      </div>
      {renderGroupList}
    </div>
  );
}
