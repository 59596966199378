import React from 'react'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import PropTypes from 'prop-types'
// import TextField from "@material-ui/core/TextField";
import { Input as TextField } from '@progress/kendo-react-inputs'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { DatePicker } from '@progress/kendo-react-dateinputs'
import Dropzone from 'react-dropzone'
import HelpIcon from '@material-ui/icons/Help'
import {
  GlobalSearch,
  KendoFlatSelector,
  MonthlyDatePicker,
  PortfolioFundSelector,
  KendoDropDown,
  Zone,
} from '../components'
import MuiDatePicker from '../components/forms/MuiDatePicker'
import { Box } from '@mantine/core'
// import moment from "moment";
// import { DateTimeUtils } from "../../utils";

export const snippetType = {
  checkBox: 'checkBox',
  textField: 'textField',
  globalSearch: 'globalSearch',
  globalMultiSearch: 'globalMultiSearch',
  comboboxSelector: 'comboboxSelector',
  dropdownSelector: 'dropdownSelector',
  dropdownMultiSelector: 'dropdownMultiSelector',
  datePicker: 'datePicker',
  monthlyCalendarSelector: 'monthlyCalendarSelector',
  starEndSelector: 'starEndSelector',
  portfolioFundsSelector: 'portfolioFundsSelector',
  buttonGroupSelector: 'buttonGroupSelector',
  dropZone: 'dropZone',
  actionButton: 'actionButton',
  submitButton: 'submitButton',
}

export default class ReportInputSnippet {
  static propTypes = {
    data: PropTypes.object.isRequired,
    updateInputs: PropTypes.func.isRequired,
    search_list: PropTypes.array.isRequired,
    submitFunction: PropTypes.func,
  }

  constructor(props) {
    this.state = { ...props }
  }

  helperText = (title, body) => (
    <div className="selector-explanation">
      <Tooltip
        className="explanation-tooltip"
        title={
          <>
            <Typography color="inherit" variant="body1">
              {body}
            </Typography>
          </>
        }
      >
        <HelpIcon className="help-icon" />
      </Tooltip>
    </div>
  )

  handleCheckbox = ({ target }) => {
    const { updateInputs } = this.state
    const { checked, name } = target
    updateInputs({ value: checked, name })
  }

  checkBox = (name, title, props) => {
    const { data } = this.state
    return (
      <div className="single-selector">
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={data[name]}
                color="primary"
                name={name}
                onChange={this.handleCheckbox}
              />
            }
            label={<Typography color="textPrimary">{title}</Typography>}
            {...props}
          />
        </FormGroup>
      </div>
    )
  }

  textField = (name, title, props) => {
    const { data } = this.state
    return (
      <div className="single-selector">
        <TextField
          name={name}
          onChange={this.updateStateSelector}
          placeholder={title}
          style={{ width: '100%' }}
          value={data[name] || ''}
          {...props}
        />
      </div>
    )
  }

  updateStateSelector = (e) => {
    const { updateInputs } = this.state
    const { value, name } = e.target
    updateInputs({ value, name })
  }

  globalSearch = (
    name,
    title,
    props,
    overrideOptions,
    excludeSecurityTypes = [],
  ) => {
    const { search_list, data } = this.state
    const list =
      excludeSecurityTypes?.length > 0
        ? search_list?.filter((o) => !excludeSecurityTypes.includes(o.type))
        : search_list
    return (
      <div className="single-selector">
        <GlobalSearch
          dataItemKey="id"
          items={overrideOptions || list}
          name={name}
          noLink
          onChange={this.updateStateSelector}
          placeholder={title}
          searchFields={['name', 'isin', 'underlying_name', 'principal_name']}
          textField="name"
          value={data[name]}
          {...props}
        />
      </div>
    )
  }

  globalMultiSearch = (name, title, props, overrideOptions) => {
    const { search_list, data } = this.state
    return (
      <div className="single-selector">
        <GlobalSearch
          dataItemKey="id"
          items={overrideOptions || search_list}
          multi
          name={name}
          noLink
          onChange={this.updateStateSelector}
          placeholder={title}
          searchFields={['name', 'isin', 'underlying_name', 'principal_name']}
          textField="name"
          value={data[name] || []}
          {...props}
        />
      </div>
    )
  }

  comboboxSelector = (name, label, items, props) => {
    const { data } = this.state
    return (
      <div className="single-selector">
        <KendoFlatSelector
          items={items}
          name={name}
          onChange={this.updateStateSelector}
          placeholder={label}
          value={data[name]}
          // dataItemKey="id"
          // textField="name"
          {...props}
        />
      </div>
    )
  }

  dropdownSelector = (name, label, items, props) => {
    const { data } = this.state
    return (
      <div className="single-selector">
        <KendoDropDown
          items={items}
          name={name}
          onChange={this.updateStateSelector}
          placeholder={label}
          value={data[name]}
          {...props}
        />
      </div>
    )
  }

  dropdownMultiSelector = (name, label, items, props) => {
    const { data } = this.state
    return (
      <div className="single-selector">
        <KendoFlatSelector
          items={items}
          multi
          name={name}
          onChange={this.updateStateSelector}
          placeholder={label}
          value={data[name]}
          // dataItemKey="id"
          // textField="name"
          {...props}
        />
      </div>
    )
  }

  updateButtonSelector = (name, value) => {
    const { updateInputs } = this.state
    updateInputs({ value, name })
  }

  datePicker = (name, props) => {
    const { data } = this.state

    return (
      <div className="single-selector one-line">
        <MuiDatePicker
          className="date-input"
          name={name}
          onChange={(newValue) => {
            this.updateStateSelector({ target: { value: newValue, name } })
          }}
          value={data[name]}
          {...props}
          disableToolbar={false}
          placeholder={props.format || 'Date Input'}
        />
      </div>
    )
  }

  monthlyCalendarSelector = (name, title = 'As of Date') => {
    const { data } = this.state
    return (
      <div className="single-selector one-line">
        <Typography variant="caption">{title} </Typography>
        <MuiDatePicker
          onChange={(newValue) => {
            this.updateStateSelector({ target: { value: newValue, name } })
          }}
          className="date-input"
          title="Start Date"
          value={data[name]}
          format="MMM yy"
        />
      </div>
    )
  }

  starEndSelector = (start_name, end_name) => {
    const { data } = this.state

    return (
      <div className="single-selector one-line">
        <Box display="flex" style={{ alignItems: 'center' }}>
          <Typography variant="caption" style={{ textWrap: 'nowrap' }}> Start Date </Typography>
          <MuiDatePicker
            onChange={(newValue) => {
              this.updateStateSelector({ target: { value: newValue, name: start_name } })
            }}
            className="date-input"
            title="Start Date"
            value={data[start_name]}
            format="MMM yy"
          />
          <Typography variant="caption" style={{ textWrap: 'nowrap' }}> End Date </Typography>
          <MuiDatePicker
            className="date-input"
            onChange={(newValue) => {
              this.updateStateSelector({ target: { value: newValue, name: end_name } })
            }}
            title="End Date"
            value={data[end_name]}
            format="MMM yy"
          />
        </Box>
      </div>
    )
  }

  onWeightsChange = (value, name) => {
    const { updateInputs } = this.state
    const payload = { value, name }
    updateInputs(payload)
  }

  portfolioFundsSelector = (name, props) => {
    const { search_list, data } = this.state
    const weights = data[name]
    return (
      <div className="single-selector">
        <PortfolioFundSelector
          handleChange={(value) => {
            this.onWeightsChange(value, name)
          }}
          items={search_list}
          weights={weights}
          {...props}
        />
      </div>
    )
  }

  buttonGroupSelector = (name, items, props) => {
    const { data } = this.state
    const { [name]: current_value } = data
    return (
      <div className="single-selector">
        <ButtonGroup color="primary" fullWidth>
          {items.map((btn, idx) => (
            <Button
              key={idx}
              name={name}
              onClick={() => this.updateButtonSelector(name, btn.id)}
              variant={btn.id === current_value ? 'contained' : 'outlined'}
            >
              {btn.title}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    )
  }

  onDrop = (files, name, multi = false) => {
    const { updateInputs } = this.state
    const value = multi ? files : files[0]
    updateInputs({ value, name })
  }

  dropZone = (field, title, props) => {
    const { data } = this.state
    const doc = data[field]
    const { multi } = props
    let uploaded
    let render_name
    if (Array.isArray(doc)) {
      uploaded = !!(doc.length > 0)
      render_name = (
        <>
          <ul>
            {doc.map((o, idx) => (
              <li key={idx}>{o.name}</li>
            ))}
          </ul>
        </>
      )
    } else {
      const { name } = doc || {}
      uploaded = !!name
      render_name = (
        <>
          <p>{name}</p>
        </>
      )
    }
    return (
      <div className="single-selector">
        <Dropzone
          onDrop={(e) => {
            this.onDrop(e, field, multi)
          }}
          {...props}
        >
          {({ getRootProps, getInputProps }) => (
            <Zone>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {uploaded ? render_name : <p>{title}</p>}
              </div>
            </Zone>
          )}
        </Dropzone>
      </div>
    )
  }

  actionButton = (title, action, props) => (
    <div className="single-selector">
      <Button
        color="default"
        fullWidth
        onClick={action}
        variant="contained"
        {...props}
      >
        {title}
      </Button>
    </div>
  )

  submitButton = (title, props) => {
    const { submitFunction } = this.state

    return (
      <div className="generate-button">
        <Button
          color="primary"
          onClick={submitFunction}
          variant="contained"
          {...props}
        >
          {title}
        </Button>
      </div>
    )
  }
}
