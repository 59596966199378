import React, { useState, useEffect } from "react"
import {
  getManagerHoldings,
  getEvaluationDates,
  getManagerHoldingsExcel,
} from "../../services"
import {
  extractSeriesNames,
  dateTimeManager as dt,
  buildFileFromResponse,
} from "../../utils"
import { HoldingsTable, shortNames } from "../managerHoldings"

export default function ManagerHoldings() {
  const [evaluationsDates, setEvaluationsDates] = useState([])
  const [holdingsData, setHoldingsData] = useState([])
  const [fundNames, setFundNames] = useState([])
  const [selectedNames, setSelectedNames] = useState([])
  const [fundName, setFundName] = useState("")
  const [selectedMonth, setSelectedMonth] = useState(null)

  const fetchDates = async () => {
    try {
      const response = await getEvaluationDates()
      setEvaluationsDates(response.data)
      setSelectedMonth(response.data[0])
    } catch (error) { }
  }

  const fetchHoldings = async (asOfDate) => {
    try {
      setHoldingsData([])
      const response = await getManagerHoldings({
        value_date: dt.jsToDb(asOfDate),
      })
      setHoldingsData(response.data)
      const names = extractSeriesNames(response.data)

      const upperCaseShortNames = {}
      Object.keys(shortNames).forEach(key => {
        upperCaseShortNames[key.toUpperCase()] = shortNames[key]
      })
      const shortNameList = names.map((o) => upperCaseShortNames[o.toUpperCase()] || o)
      setFundNames(shortNameList)
      setSelectedNames([])
    } catch (error) { }
  }

  const generateExcel = async () => {
    try {
      const response = await getManagerHoldingsExcel({
        value_date: selectedMonth,
      })
      buildFileFromResponse(response)
    } catch (error) { }
  }

  useEffect(() => {
    if (evaluationsDates.length === 0) {
      fetchDates()
    }
    if (selectedMonth) {
      fetchHoldings(selectedMonth)
    }
  }, [evaluationsDates, selectedMonth])

  return (
    <div className="manager-holdings-route">
      <div className="holdings-table">
        <HoldingsTable
          changeFundName={setFundName}
          columns={fundNames}
          data={holdingsData}
          evaluationsDates={evaluationsDates}
          fundName={fundName}
          onExport={generateExcel}
          selectedMonth={selectedMonth}
          selectedNames={selectedNames}
          setSelectedMonth={setSelectedMonth}
          setSelectedNames={setSelectedNames}
        />
      </div>
    </div>
  )
}
