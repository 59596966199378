import { Box } from '@material-ui/core'
import React, { useMemo } from 'react'

import DashboardBlock from '../../components/misc/DashboardBlock'
import { usePrivateEquityFunds } from '../../hooks'
import { useRouteType } from '../../hooks/useIsPeRoutes'
import { peFundStatusKey } from '../dashboard/PrivateEquityCard/PrivateEquityCard'
import PrivateMarketFundPreviewCard from './PrivateMarketFundPreviewCard'

const filterStatus = [peFundStatusKey.CMS, peFundStatusKey.OPN]

const PrivateMarketFundsOverviewTable: React.FC = () => {
  const { data, isLoading } = usePrivateEquityFunds()
  const routeType = useRouteType()
  const displayData = useMemo(
    () =>
      data
        ?.filter(
          (it) =>
            it.underlying_fund &&
            !it.share_class &&
            filterStatus.includes(it.fund_status),
        )
        ?.sort((a, b) => b.id - a.id),
    [data],
  )

  return (
    <DashboardBlock
      header="Featured Funds"
      viewAllLink="/private-markets/funds"
      viewAllMpPayload={{
        eventName: 'Click View All',
        properties: {
          'Block Name': 'Private Market Funds Table',
          'Route Type': routeType,
        },
      }}
    >
      <Box display="flex" flexDirection="column" gridGap={12}>
        {(isLoading ? Array(5).fill({}) : displayData)?.map((it, idx) => (
          <PrivateMarketFundPreviewCard
            {...{ ...it, isLoading: isLoading }}
            key={idx}
          />
        ))}
      </Box>
    </DashboardBlock>
  )
}

export default PrivateMarketFundsOverviewTable
