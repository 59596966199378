import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { formatNumber, parseNumber } from '@telerik/kendo-intl'
import { useMemo, useState } from 'react'
import { Prompt } from 'react-router'
import SkelatonTextLoader from '../../components/skeletons/SkelatonTextLoader'

import { Allocation, KendoFlatSelector } from '../../components'
import { fund_detail_rows } from '../../data/funds'
import { useSecurities } from '../../hooks'
import { dateTimeManager } from '../../utils'
import FundInfoSummary from './FundInfoSummary'

const uneditableSecurityTypes = {
  aaapCell: 'AAAP Cell',
  fofClass: 'FoF Share Class',
  marketIndex: 'Market Index',
  portfolio: 'Portfolio',
}
const uneditableFieldSecurityTypesMap = {
  info: [
    uneditableSecurityTypes.marketIndex,
    uneditableSecurityTypes.portfolio,
  ],
  summary: [
    uneditableSecurityTypes.aaapCell,
    uneditableSecurityTypes.marketIndex,
    uneditableSecurityTypes.portfolio,
    uneditableSecurityTypes.fofClass,
  ],
}

export default function FundInfoTab({
  access_internal,
  changeDetails,
  fetchDetails,
  search_list,
  security_details,
  security_type,
  updateHedgeFund,
  loading,
  ...other
}) {
  const [editMode, setEditMode] = useState(false)
  const { strategy_list } = useSecurities()
  const isEditableSummary =
    !uneditableFieldSecurityTypesMap.summary.includes(security_type)
  const isEditableInfoDetails =
    !uneditableFieldSecurityTypesMap.info.includes(security_type)
  const isEditable = isEditableInfoDetails || isEditableSummary
  const isUnderlyingFund = !!security_details?.underlying_fund?.id
  const disabledFields = useMemo(() => {
    const { fund_terms } = fund_detail_rows
    const fields = {}
    fund_terms.forEach((term) => {
      if (term.disabled || (isUnderlyingFund && term.isUnderlyingFundField)) {
        fields[term.field] = true
      }
    })
    return fields
  }, [fund_detail_rows, isUnderlyingFund])

  const switchMode = () => {
    setEditMode((i) => !i)
  }

  const updateDate = (date) => {
    changeDetails({ name: 'inception_date', value: date })
  }

  const handleChange = ({ target }) => {
    const { name, value } = target
    changeDetails({ name, value })
  }

  const renderEditField = (entry, props) => {
    let { field, items } = entry
    const data = security_details[field]
    if (field === 'strategy') {
      items = strategy_list
    }
    if (items) {
      return (
        <KendoFlatSelector
          disabled={disabledFields[entry.field]}
          items={items}
          name={entry.field}
          onChange={handleChange}
          value={data}
        />
      )
    }
    if (field === 'inception_date') {
      return (
        <KeyboardDatePicker
          allowKeyboardControl
          ampm={false}
          format="dd MMM yyyy"
          fullWidth
          inputVariant="standard"
          onChange={updateDate}
          value={data}
          variant="dialog"
        />
      )
    }
    return (
      <TextField
        fullWidth
        name={entry.field}
        onChange={handleChange}
        value={data || ''}
        {...props}
        disabled={disabledFields[entry.field]}
      />
    )
  }

  const saveEdits = () => {
    const { id: fund_id } = security_details
    const { fund_terms } = fund_detail_rows
    const payload = { id: Number(fund_id) }
    const fields = [
      'summary',
      ...fund_terms.filter((o) => !o.disabled).map((o) => o.field),
    ]

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      if (security_details[field]) {
        if (field === 'inception_date') {
          payload[field] = dateTimeManager.jsToDateTimeDb(
            security_details[field],
          )
        } else {
          payload[field] = security_details[field]
        }
      }
    }
    updateHedgeFund(payload)
    switchMode()
  }

  const cancelEdits = () => {
    const { id: fund_id } = security_details
    switchMode()

    if (fetchDetails) {
      fetchDetails(fund_id, search_list)
    }
  }

  const renderControlPanel = () => (
    <div className="control-row">
      {!editMode && access_internal && (
        <Button
          className="edit-button"
          color="primary"
          onClick={switchMode}
          variant="contained"
        >
          Edit Fund Info
        </Button>
      )}

      {editMode && access_internal && (
        <>
          <Button
            className="save-button"
            color="primary"
            onClick={saveEdits}
            variant="contained"
          >
            Save changes
          </Button>
          <Button
            className="cancel-button"
            color="primary"
            onClick={cancelEdits}
            variant="outlined"
          >
            Cancel
          </Button>
        </>
      )}
    </div>
  )
  const fields = [...fund_detail_rows.fund_terms]
  const { description, summary } = security_details
  const availableData = fields.some((entry, idx) => {
    const { field, format, title } = entry
    let data = security_details[field]
    return data
  })

  return (
    <div className="asset-detail-container">
      {isEditable && renderControlPanel()}

      {description && (
        <div className="summary">
          <Card>
            <CardContent>
              <Typography variant="h6">Description</Typography>
              {loading ? (
                <SkelatonTextLoader />
              ) : (
                <Typography align="justify" variant="body2">
                  {description}
                </Typography>
              )}
            </CardContent>
          </Card>
        </div>
      )}

      <FundInfoSummary
        loading={loading}
        canEdit={editMode && isEditableSummary}
        editRenderer={renderEditField}
        fundDetails={security_details}
        legacySummary={summary}
      />

      {(
        <div className="table-container">
          <Card>
            <Table>
              <TableBody>
                {fields.map((entry, idx) => {
                  const { field, format, title } = entry
                  let data = security_details[field]

                  if (data && format) {
                    data = formatNumber(parseNumber(data), format)
                  }
                  if (data && field === 'inception_date') {
                    data = dateTimeManager.dbToLongFormat(data)
                  }
                  if (data && title === 'Portal Address') {
                    data = (
                      <a
                        href={data}
                        rel="noopener noreferrer"
                        style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        target="_blank"
                      >
                        {data}
                      </a>
                    )
                  }
                  if (data || (editMode && !entry.disabled)) {
                    return (
                      <TableRow className="fund-info-table" key={idx}>
                        <TableCell className="data-type">{title}</TableCell>
                        <TableCell>
                          {editMode ? renderEditField(entry) : data}
                        </TableCell>
                      </TableRow>
                    )
                  }
                  return null
                })}
              </TableBody>
            </Table>
          </Card>
        </div>
      )}
      <div className="allocation-chart">
        <Allocation
          {...other}
          format="p2"
          security_details={security_details}
        />
      </div>
      <Prompt
        message={() =>
          'You have unsaved changes. Are you sure you want to leave without saving?'
        }
        when={editMode}
      />
    </div>
  )
}
