import React, { useContext } from "react";
import { AuthContext } from "../../contexts";
import GaAuthButton from "./authButton";

export default function ReportContainer({ children }) {
  const { gaAuth } = useContext(AuthContext);
  return (
    <>
      <GaAuthButton />
      {gaAuth && <>{children}</>}
    </>
  );
}
