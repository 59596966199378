/* eslint-disable max-len */
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core'
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import LoadingSpinner from '../../components/misc/LoadingSpinner'
import StatusIndicator from '../../components/misc/StatusIndicator'
import TimeDurationLabel from '../../components/misc/TimeDurationLabel'
import { getPortfolioOptimiser } from '../../services'
import { aamui } from '../../styles/AppTheme'
import useOptimiserConstants from './hooks/useOptimiserConstants'
import useUpdatePortfolioOptimiserRun from './hooks/useUpdatePortfolioOptimiserRun'
import ChipContainer from './layout/ChipContainer'
import {
  EMPTY_DISPLAY,
  getConvergedIcon,
  RUN_STATUS_COLOR_MAP,
  VISIBILITY_ICON_MAP,
} from './optimiser.constants'
import PortOptListFilter, { defaultOptimiserRunFilter } from './PortOptListFilter'

const filterDataBy = (data, filter) => data.filter((item) => (!filter.status || item.status === filter.status)
  && (!filter.visibility_type || item.visibility_type === filter.visibility_type)
  && (!filter.objective_function || item.input?.objective_function === filter.objective_function)
  && (!filter.tag || item?.tags?.includes(filter.tag)))

const sortBy = (data, orderBy, order) => [...data].sort((a, b) => {
  const aValue = a[orderBy]
  const bValue = b[orderBy]

  if (orderBy === 'started_at' || orderBy === 'finished_at') {
    return order === 'asc' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue)
  } if (orderBy === 'duration') {
    const aDuration = new Date(a.finished_at) - new Date(a.started_at)
    const bDuration = new Date(b.finished_at) - new Date(b.started_at)
    return order === 'asc' ? aDuration - bDuration : bDuration - aDuration
  }

  return order === 'asc' ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue)
})

const PortfolioOptimiserListPage = () => {
  const history = useHistory()
  const [orderBy, setOrderBy] = useState('started_at')
  const [order, setOrder] = useState('desc')
  const { objFuncOptionsMap } = useOptimiserConstants()
  const { isUpdatingRun, updateRun } = useUpdatePortfolioOptimiserRun()
  const [filter, setFilter] = useState(defaultOptimiserRunFilter)
  const [filteredData, setFilteredData] = useState([])
  const [showArchived, setShowArchived] = useState(false)
  const { data, isLoading, refetch: fetchOptimiserRuns } = useQuery(
    ['historic optimiser runs', showArchived],
    () => getPortfolioOptimiser(showArchived),
    {
      enabled: false,
      select: (res) => res?.data,
    },
  )
  const tagOptions = useMemo(() => {
    const tags = data?.map((it) => it?.tags || []).flat()
    return [...new Set(tags)].sort()
  }, [data])

  useEffect(() => {
    if (!data) {
      return
    }

    const filtered = sortBy(filterDataBy(data, filter), orderBy, order)

    setFilteredData(filtered)
  }, [data, filter, order, orderBy])

  useEffect(() => {
    fetchOptimiserRuns()
  }, [showArchived])

  const clickSortLabelHandler = (property) => () => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const clickDeleteIconButtonHandler = (runId, archived) => async (event) => {
    event.stopPropagation()
    await updateRun({
      payload: {
        archived,
      },
      runId,
    })
    fetchOptimiserRuns()
  }

  const clickRowHandler = (runId) => () => {
    history.push(`/portfolio-optimiser/${runId}`)
  }

  const toggleArchivedHandler = () => {
    setShowArchived(!showArchived)
  }

  return (
    <Box>
      <Box mb={2}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
        >
          <Grid
            item
            xs={9}
          >
            <PortOptListFilter
              filter={filter}
              setFilter={setFilter}
              tagOptions={tagOptions}
            />
          </Grid>
          <Grid item>
            <Box
              display="flex"
              gridGap={8}
            >
              <Button
                color="primary"
                onClick={toggleArchivedHandler}
                variant="contained"
              >
                {showArchived ? 'Show Unarchived' : 'Show Archived'}
              </Button>
              <Button
                color="primary"
                component={Link}
                to="/portfolio-optimiser/new"
                variant="contained"
              >
                New Run
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {
        isLoading
          ? (
            <LoadingSpinner />
          )
          : (
            <Box bgcolor="white">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={order}
                        onClick={clickSortLabelHandler('name')}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Converged</TableCell>

                    <TableCell>Tags</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'started_at'}
                        direction={order}
                        onClick={clickSortLabelHandler('started_at')}
                      >
                        Start At
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'finished_at'}
                        direction={order}
                        onClick={clickSortLabelHandler('finished_at')}
                      >
                        End At
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'duration'}
                        direction={order}
                        onClick={clickSortLabelHandler('duration')}
                      >
                        Duration
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Visibility</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    filteredData?.map(({
                      archived,
                      can_edit,
                      finished_at,
                      input,
                      name,
                      portfolio_optimiser_run_id,
                      result,
                      started_at,
                      status,
                      tags,
                      visibility_type
                    }) => (
                      <TableRow
                        className="link-table-row"
                        key={portfolio_optimiser_run_id}
                        onClick={clickRowHandler(portfolio_optimiser_run_id)}
                      >
                        <TableCell size="small">
                          <Box fontWeight="bold">
                            {name || input?.name || (<i>untitled</i>)}
                          </Box>
                          <Box color={aamui.secondaryText}>
                            {objFuncOptionsMap[input.objective_function]}
                          </Box>
                        </TableCell>
                        <TableCell size="small">
                          <Box
                            alignItems="center"
                            display="flex"
                          >
                            <StatusIndicator bgcolor={RUN_STATUS_COLOR_MAP[status]} />
                            <Typography variant="caption">
                              {status}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          style={{ textTransform: 'uppercase' }}
                        >
                          {status === 'RUNNING' ? EMPTY_DISPLAY : (getConvergedIcon(result?.converged))}
                        </TableCell>

                        <TableCell size="small">
                          <ChipContainer maxWidth="500px">
                            {tags?.map((tag, idx) => (
                              <Chip
                                color={filter.tag ? (filter.tag === tag ? 'primary' : 'default') : 'primary'}
                                key={idx}
                                label={tag}
                                size="small"
                              />
                            ))}
                          </ChipContainer>
                        </TableCell>
                        <TableCell size="small">
                          <Box>
                            {moment(started_at)?.format('DD/MM/YYYY')}
                          </Box>
                          <Box
                            color={aamui.secondaryText}
                            component="time"
                          >
                            {moment(started_at)?.format('hh:mm:ss')}
                          </Box>
                        </TableCell>
                        <TableCell size="small">
                          {
                            finished_at ? (
                              <>
                                <Box>
                                  {moment(finished_at)?.format('DD/MM/YYYY')}
                                </Box>
                                <Box
                                  color={aamui.secondaryText}
                                  component="time"
                                >
                                  {moment(finished_at)?.format('hh:mm:ss')}
                                </Box>
                              </>
                            ) : EMPTY_DISPLAY
                          }
                        </TableCell>
                        <TableCell size="small">{status === 'RUNNING' ? '-' : <TimeDurationLabel seconds={(new Date(finished_at) - new Date(started_at)) / 1000} />}</TableCell>
                        <TableCell size="small">
                          <Box display="flex">
                            {VISIBILITY_ICON_MAP[visibility_type]}
                          </Box>
                        </TableCell>
                        <TableCell size="small">
                          {
                            can_edit && (
                              <Tooltip
                                enterDelay={500}
                                placement="top"
                                title={archived ? 'Unarchive' : 'Archive'}
                              >
                                <IconButton
                                  disabled={isUpdatingRun}
                                  onClick={clickDeleteIconButtonHandler(portfolio_optimiser_run_id, !archived)}
                                >
                                  {
                                    archived
                                      ? (
                                        <UnarchiveOutlinedIcon
                                          fontSize="small"
                                          style={{ color: aamui.redAccent }}
                                        />
                                      )
                                      : (
                                        <ArchiveOutlinedIcon
                                          fontSize="small"
                                          style={{ color: aamui.sapphire }}
                                        />
                                      )
                                  }
                                </IconButton>
                              </Tooltip>
                            )
                          }
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </Box>
          )
      }
    </Box>
  )
}

export default PortfolioOptimiserListPage
