import React from "react";
import Selectors from "./reportInputs";
import QuickCharts from "./quickCharts";
import StatsTable from "./statsTable";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const Reports = ({ external_analysis, loaded, ...props }) => {
  const { report_type } = external_analysis || {};
  const allProps = { external_analysis, ...props };
  if (!loaded) {
    return <div />;
  }
  switch (report_type) {
    case "Quick Chart":
      return <QuickCharts {...allProps} />;
    case "Summary Stats":
      return <StatsTable {...allProps} />;
    default:
      return <div />;
  }
};

const WaitingScreen = () => {
  return (
    <div className="loading-progress">
      <Typography align="center">Loading hedge fund list</Typography>
      <LinearProgress />
    </div>
  );
};

export default function ReportContainer({ loading, work_ready, ...props }) {
  return (
    <>
      {work_ready ? <Selectors {...props} /> : <WaitingScreen />}

      {loading && (
        <div className="loading-indicator">
          <LinearProgress />
        </div>
      )}
      <div className="comparison-container">
        {" "}
        <Reports {...props} />{" "}
      </div>
    </>
  );
}
