import React, { Component } from "react";
import { cloneDeep } from "lodash";
import statefulTableGenerator from "./statefulTable";
import {
  GridColumn as Column,
  Grid as KGrid,
} from "@progress/kendo-react-grid";
// import { NumericTextBox } from "@progress/kendo-react-inputs";
import TextField from "@material-ui/core/TextField";

const SuperGrid = statefulTableGenerator(KGrid);

export default class DataManagementTable extends Component {
  curateDataPoint = (data_point) => {
    if (data_point.includes("%")) {
      return Number(data_point.replace("%", "")) / 100;
    }
    return Number(data_point);
  };

  clearTrailingZero = (data_list) => {
    const last_idx = data_list.length - 1;
    if (!data_list[last_idx]) {
      data_list.splice(last_idx, 1);
    }
    return data_list;
  };

  handlePaste = (e, idx, field) => {
    const { clearTrailingZero, curateDataPoint, createUpdatedData } = this;
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      if (element.type !== "text/plain") {
        continue;
      }
      element.getAsString((text) => {
        const split_data = text.split("\n");
        const no_trailing_data = clearTrailingZero(split_data);
        const cleaned = no_trailing_data.map((o) => curateDataPoint(o));
        createUpdatedData(idx, cleaned, field);
      });
    }
    e.preventDefault();
  };

  createUpdatedData = (start, values, field) => {
    const { onUpdate, data } = this.props;
    let updated = cloneDeep(data);
    if (updated.length < start + values.length) {
      return;
    }
    for (let i = 0; i < values.length; i++) {
      let new_value = values[i];
      updated[start + i][field] = new_value;
    }
    onUpdate(updated);
  };

  handleChange = (e, id, field) => {
    const { onUpdate, data, percent } = this.props;
    let updated = cloneDeep(data);
    const { value } = e.target;
    let new_value;
    if (percent) {
      new_value = value ? Number(value) / 100 : value;
    } else {
      new_value = value ? Number(value) : value;
    }
    updated[id][field] = new_value;
    onUpdate(updated);
  };

  editableCell = (props) => {
    const { percent } = this.props;
    const { handlePaste, handleChange } = this;
    const { dataItem, field, dataIndex } = props;
    const id = dataIndex;
    const value = dataItem[field];
    const formatted_value =
      typeof value === "number" && percent ? +(value * 100).toFixed(2) : value;
    const adornment = percent ? "%" : "";
    return (
      <td className="cell-name">
        <TextField
          onPaste={(e) => {
            handlePaste(e, id, field);
          }}
          onChange={(e) => {
            handleChange(e, id, field);
          }}
          type="number"
          value={formatted_value || ""}
          InputProps={{
            endAdornment: <span>{adornment}</span>,
          }}
        />
      </td>
    );
  };

  render() {
    const {
      data,
      static_fields,
      editable_fields,
      height = "400px",
      ...other_props
    } = this.props;
    return (
      <div className="data-management-table-container">
        <SuperGrid
          data={data}
          filterable={false}
          sortable={false}
          pageable={false}
          className="data-management-table"
          style={{ height }}
          {...other_props}
        >
          {this.props.children}
          {static_fields.map((col, idx) => (
            <Column {...col} key={idx} />
          ))}

          {editable_fields.map((col, idx) => (
            <Column cell={this.editableCell} {...col} key={idx} />
          ))}
        </SuperGrid>
      </div>
    );
  }
}
