import React, { useState } from "react";
import PerformanceInput from "./performanceInput";
import { HotPerformance } from "../../components";
import { ReturnsProcessor } from "../../utils";
import moment from "moment";
import { Prompt } from "react-router";
import { getPerformance, updatePerformance } from "../../services";
import { useSecurities } from "../../hooks";

const PerformanceTable = ({
  data,
  inception_date,
  updateStartDate,
  resetInceptionDate,
  onDataChange,
  updateAction,
  ...props
}) => {
  return (
    <HotPerformance
      data={data}
      licenseKey="non-commercial-and-evaluation"
      editable_columns={[{ field: "perf_return", title: "Performance" }]}
      index_name="date"
      inception_date={inception_date}
      updateStartDate={updateStartDate}
      resetInceptionDate={resetInceptionDate}
      onDataChange={onDataChange}
      updateAction={updateAction}
      {...props}
    />
  );
};

export default function PerformanceUpdate() {
  const [performanceInputs, setPerformanceInputs] = useState({
    security: "",
  });
  const { search_list: searchList } = useSecurities();
  const [performanceData, setPerformanceData] = useState([]);
  const [inceptionDate, setInceptionDate] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);

  const updateInputs = ({ value, name }) => {
    setUpdated(false);
    setFetched(false);
    setPerformanceInputs((i) => ({ ...i, [name]: value }));
  };

  const fetchPerformance = async () => {
    const { security } = performanceInputs || {};
    if (!security) {
      return;
    }
    try {
      const response = await getPerformance({ security: security.id });
      const performance = ReturnsProcessor.processReturns(
        response.data,
        new Date(),
        { security: performanceInputs.security.id }
      );
      setPerformanceData(performance);
      setFetched(true);
    } catch (error) {}
  };

  const updateStartDate = ({ target }) => setInceptionDate(target.value);

  const resetInceptionDate = () => {
    if (performanceData.length === 0) {
      let dateStart = moment(inceptionDate).endOf("month").startOf("day");
      let dateEnd = moment(new Date()).endOf("month").startOf("day");
      let data = [];
      while (
        dateEnd > dateStart ||
        dateStart.format("M") === dateEnd.format("M")
      ) {
        data.push({
          perf_date: dateStart.format("YYYY-MM-DD"),
          perf_return: null,
          date: dateStart.format("MMM-YY"),
        });
        dateStart.add(1, "month").endOf("month").startOf("day");
        setPerformanceData(data);
      }
    } else {
      let data = [...performanceData];
      let dateStart = moment(inceptionDate).endOf("month").startOf("day");
      let dateEnd = moment(data[0]["perf_date"])
        .add(-1, "month")
        .endOf("month")
        .startOf("day");
      let emptyDates = [];
      while (
        dateEnd > dateStart ||
        dateStart.format("M") === dateEnd.format("M")
      ) {
        emptyDates.push({
          perf_date: dateStart.format("YYYY-MM-DD"),
          perf_return: null,
          date: dateStart.format("MMM-YY"),
        });
        dateStart.add(1, "month").endOf("month").startOf("day");
      }
      data = emptyDates.concat(data);
      setPerformanceData(data);
    }
  };

  const onPerformanceUpdate = async () => {
    setUpdating(true);
    const { id: security } = performanceInputs.security || {};
    let clean_performance = [];
    for (let i = 0; i < performanceData.length; i++) {
      const entry = performanceData[i];
      const { perf_return } = entry;
      if (perf_return || perf_return === 0) {
        clean_performance.push({ ...entry, security });
      }
    }
    try {
      const response = await updatePerformance({
        data: clean_performance,
        security,
      });
      const performance = ReturnsProcessor.processReturns(
        response.data,
        new Date(),
        { security: performanceInputs.security.id }
      );
      setPerformanceData(performance);
      setUpdated(true);
    } catch (error) {}
    setUpdating(false);
  };

  const warning = fetched && !updated;
  return (
    <div>
      <PerformanceInput
        performance_inputs={performanceInputs}
        updateInputs={updateInputs}
        getPerformance={fetchPerformance}
        search_list={searchList}
        fetched={fetched}
      />
      {fetched && (
        <PerformanceTable
          data={performanceData}
          inception_date={inceptionDate}
          updateStartDate={updateStartDate}
          resetInceptionDate={resetInceptionDate}
          onDataChange={(data) => {
            setUpdated(false);
            setPerformanceData(data);
          }}
          updateAction={onPerformanceUpdate}
          updating={updating}
          updated={updated}
          fetched={fetched}
        />
      )}
      <Prompt
        when={warning}
        message={() =>
          `You have unsaved changes. Are you sure you want to leave without saving?`
        }
      />
    </div>
  );
}
