type AnyObject = { [key: string]: any };

function snakeToCamel(str: string): string {
  return str.replace(/(_\w)/g, m => m[1].toUpperCase())
}

function isObject(obj: any): obj is AnyObject {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function'
}

export function convertSnakeToCamelCase(obj: any): any {
  if (isObject(obj)) {
    const newObj: AnyObject = {}
    for (const key in obj) {
      newObj[snakeToCamel(key)] = convertSnakeToCamelCase(obj[key])
    }
    return newObj
  } else if (Array.isArray(obj)) {
    return obj.map(item => convertSnakeToCamelCase(item))
  }
  return obj
}