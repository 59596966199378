import { IconButton } from '@material-ui/core';
import React from 'react'
import styled from "styled-components";
import CloseIcon from '@material-ui/icons/Close';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 260px;
  width: 100%;
  z-index: 100;
`

const InnerWrapper = styled.div`
  background: ${({ theme }) => theme.palette.aamui.redAccent};
  display: flex;
  align-items: center;
  padding: 18px 24px 18px 6px;
  width: 100%;
  right: 0;
  color: white;
  width: 360px;
  box-sizing: border-box;
  margin: 0 -30px;

  button {
    margin-right: 30px;
  }

  p {
    font-size: 12px;
    font-weight: lighter;
  }

  a {
    color: white;
    text-decoration: underline;
    font-weight: bolder;
    cursor: pointer;
  }
`

const ErrorSnackbar = ({
  children,
  open,
  setOpen
}) => {
  const clickDismissButtonHandler = () => {
    setOpen(false)
  }

  return open ? (
    <Wrapper>
      <InnerWrapper>
        <IconButton onClick={clickDismissButtonHandler}>
          <CloseIcon htmlColor="white" />
        </IconButton>
        <div>
          {children}
        </div>
      </InnerWrapper>
    </Wrapper>
  ) : null
}

export default ErrorSnackbar