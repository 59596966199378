import { ActionIcon, Indicator } from '@mantine/core'
import { IconBell } from '@tabler/icons-react'
import React from 'react'
import { Link } from 'react-router-dom'

import useFetchDocsNotifications from '../../hooks/useFetchDocsNotifications'
import { useAppSelector } from '../../hooks/useRedux'
import useIsPeRoutes from '../../hooks/useIsPeRoutes'

const initNotificationParams = {
  storyType: '-AAAP Factsheet'
}

const NotificationButton = () => {
  useFetchDocsNotifications(initNotificationParams)
  const notificationCount = useAppSelector(state => state.docsNotification.notificationCount)
  const isPeRoutes = useIsPeRoutes()
  const iconLink = `${isPeRoutes ? '/private-markets' : ''}/document-notifications`

  return (
    <Indicator
      color="red"
      disabled={notificationCount < 1}
      inline
      offset={7}
      position="top-end"
      size={12}
      style={{ alignSelf: 'self-end' }}
      withBorder
    >
      <ActionIcon
        component={Link}
        to={iconLink}
        variant="transparent"
      >
        <IconBell />
      </ActionIcon>
    </Indicator>
  )
}

export default NotificationButton
