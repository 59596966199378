import { Button, Checkbox, Group } from '@mantine/core'
import React from 'react'

import { useAppDispatch } from '../../hooks/useRedux'
import {
  selectAllNotificationsToggled,
  selectCurrentPageDocsNotiToggled,
  useDocsNotiCount,
  useSelectedDocsNotifications,
  useSelectionMode
} from '../../redux/docsNotificationSlice'
import DocsNotificationReadToggleMenuButton from './DocsNotificationReadToggleMenuButton'

interface DocsNotificationTableActionsProps {
  refetch: () => void
}

const DocsNotificationTableActions: React.FC<DocsNotificationTableActionsProps> = ({
  refetch
}) => {
  const dispatch = useAppDispatch()
  const allDocsCount = useDocsNotiCount()
  const {
    intermediateCurrentPage,
    selectAll,
    selectCurrentPage
  } = useSelectionMode()
  const selectedDocsNotifications = useSelectedDocsNotifications()
  const selectedCount = selectAll ? allDocsCount : Object.values(selectedDocsNotifications).filter(it => it).length

  const clickCheckBoxHandler = () => {
    dispatch(selectCurrentPageDocsNotiToggled())
  }

  const clickSelectAllButtonHandler = () => {
    dispatch(selectAllNotificationsToggled())
  }

  return (
    <Group className="notification-actions">
      <Checkbox
        checked={selectCurrentPage || selectAll}
        indeterminate={intermediateCurrentPage}
        label={selectedCount > 0 ? `Selected ${selectedCount}` : 'Select all'}
        onChange={clickCheckBoxHandler}
      />
      {
        (selectCurrentPage || selectAll) && (
          <Button
            disabled={!selectAll && !selectCurrentPage}
            h="auto"
            onClick={clickSelectAllButtonHandler}
            opacity={(selectCurrentPage || selectAll) ? 1 : 0}
            p="0"
            variant="transparent"
          >
            {
              selectAll ? 'Clear Selection' : ` Select All ${allDocsCount} notifications`
            }
          </Button>
        )
      }
      {
        selectedCount > 0 && (
          <>

            <DocsNotificationReadToggleMenuButton refetch={refetch} />
          </>
        )
      }
    </Group>
  )
}

export default DocsNotificationTableActions