import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'

const LoadingSpinner = ({
  disableSpacing,
  messageComponent,
  minHeight,
  ...boxProps
}) => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="column"
    gridGap={36}
    height="50%"
    justifyContent="center"
    minHeight={disableSpacing ? 'unset' : '400px'}
    width="100%"
    {...boxProps}
  >
    <CircularProgress size={40} />
    {
      messageComponent && (
        <Box maxWidth="800px">
          {messageComponent}
        </Box>
      )
    }
  </Box>
)

export default LoadingSpinner
