import {
  ActionIcon,
  Anchor,
  Box,
  Center,
  Combobox,
  HoverCard,
  Menu,
  Pill,
  PillsInput,
  Stack,
  Text,
  Tooltip,
  useCombobox
} from '@mantine/core'
import { IconCategory } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { GroupedVirtuoso } from 'react-virtuoso'

import { useSecurities } from '../../hooks'
import { PeerGroup } from '../../redux/peerGroupsSlice'
import { Fund } from '../models/IFund'
import { useFetchPeerGroups } from '../../hooks/usePeerGroups'

export const filterOptions = (parsedGroupsMap: Record<string, { label: string, value: string }[]>, searchValue: string) => {
  if (searchValue.trim() === '') {
    return parsedGroupsMap
  }

  const newGroupMap: Record<string, any> = {}


  Object.entries(parsedGroupsMap).forEach(([groupName, items]) => {
    const filteredItems = items.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()))

    if (filteredItems.length > 0) {
      newGroupMap[groupName] = filteredItems
    }
  })

  return newGroupMap
}

interface GlobalFundSelectorProps {
  onChange: (value: Fund[]) => void
  placeholder?: string
  showPeerGroup?: boolean
  selectedFunds: Fund[]
  disabled?: boolean
}

const GlobalFundSelector: React.FC<GlobalFundSelectorProps> = ({
  disabled,
  onChange,
  placeholder = 'Search funds',
  selectedFunds = [],
  showPeerGroup = false
}) => {
  const { fundPeerGroupMap, peerGroups } = useFetchPeerGroups(showPeerGroup)
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  })
  const { isLoadingSecurities, search_list: searchList, search_list_map: searchListMap } = useSecurities()
  const [search, setSearch] = useState('')
  const [openPeerGroupMenu, setOpenPeerGroupMenu] = useState(false)
  const selectedFundIds = useMemo(() => selectedFunds.map(fund => String(fund?.id)), [selectedFunds])

  const parsedGroupsMap = useMemo(() => {
    if (searchList && !isLoadingSecurities) {
      const groups: any = {}
      const keySet = new Set()
      searchList.forEach((it: any) => {
        if (!groups[it.type]) {
          groups[it.type] = []
        }
        if (!keySet.has(it.id)) {
          groups[it.type].push({
            label: it.name,
            value: String(it.id)
          })
        }
        keySet.add(it.id)
      })

      return groups
    }

    return []
  }, [searchList, isLoadingSecurities])

  const handleValueSelect = (val: string) => {
    const newValue = selectedFundIds?.includes(val) ? selectedFundIds.filter((v) => v !== val) : [...selectedFundIds, val]
    onChange(newValue?.map(it => searchListMap[it]))
    setSearch('')
  }

  const handleValueRemove = (val: string) => {
    const newValue = selectedFundIds.filter((v) => v !== val)
    onChange(newValue?.map(it => searchListMap[it]))
  }

  const filteredGroupOptions = filterOptions(parsedGroupsMap, search)
  const filteredOptions = Object.values(filteredGroupOptions).flat()
  const filteredGroups = Object.keys(filteredGroupOptions)
  const groupCounts = Object.values(filteredGroupOptions).map(items => items.length)

  const openDropDown = () => combobox.openDropdown()
  const closeDropDown = () => combobox.closeDropdown()

  const clickPeerGroupHandler = (group: PeerGroup) => () => {
    const funds = group.funds.map(fund => String(fund.fundId))
    const fundIdsSet = new Set([...selectedFundIds, ...funds])
    onChange([...Array.from(fundIdsSet)]?.map(it => searchListMap[it]))
  }

  const values = selectedFundIds.map((item: any) => {
    const myGroups = fundPeerGroupMap[item]?.filter(group => group.canEdit)?.map(group => group.name).join(', ')
    const otherGroups = fundPeerGroupMap[item]?.filter(group => !group.canEdit)?.map(group => group.name).join(', ')

    return (
      <HoverCard
        disabled={!showPeerGroup || (!fundPeerGroupMap[item])}
        key={item}
        withArrow
      >
        <HoverCard.Target>
          <Pill
            onRemove={() => handleValueRemove(item)}
            style={{ cursor: fundPeerGroupMap[item] ? 'pointer' : 'default' }}
            withRemoveButton
          >
            {searchListMap[item]?.name}
          </Pill>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <Stack gap="xs">
            <Text size="xs">
              Peer Groups
            </Text>
            {
              myGroups?.length > 0 && (
                <Stack gap={0}>
                  <Text size="xs"><strong>Created by You</strong></Text>
                  <Text size="xs">{myGroups}</Text>
                </Stack>
              )
            }
            {
              otherGroups?.length > 0 && (
                <Stack gap={0}>
                  <Text size="xs"><strong>Created by Others</strong></Text>
                  <Text size="xs">{otherGroups}</Text>
                </Stack>
              )
            }
          </Stack>
        </HoverCard.Dropdown>
      </HoverCard>
    )
  })

  return (
    <Box style={{ position: 'relative' }}>
      <Combobox
        disabled={disabled}
        onOptionSubmit={handleValueSelect}
        store={combobox}
      >
        <Combobox.DropdownTarget>
          <PillsInput onClick={openDropDown}>
            <Pill.Group>
              {values}
              <Combobox.EventsTarget>
                <PillsInput.Field
                  disabled={disabled}
                  onBlur={closeDropDown}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex()
                    setSearch(event.currentTarget.value)
                  }}
                  onFocus={openDropDown}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && search.length === 0) {
                      event.preventDefault()
                      handleValueRemove(selectedFunds[selectedFunds.length - 1])
                    }
                  }}
                  placeholder={placeholder}
                  value={search}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>
        <Combobox.Dropdown>
          <Combobox.Options>
            <GroupedVirtuoso
              groupContent={(groupIdx) => (
                <Text
                  bg="white"
                  color="gray"
                  key={groupIdx}
                  px="sm"
                  size="md"
                >
                  <strong>
                    {filteredGroups[groupIdx]}
                  </strong>
                </Text>

              )}
              groupCounts={groupCounts}
              itemContent={(optionIdx) => (
                <Combobox.Option
                  active={selectedFundIds.includes(filteredOptions[optionIdx].value)}
                  key={optionIdx}
                  px="sm"
                  py={4}
                  value={filteredOptions[optionIdx].value}
                >
                  <Text size="md">
                    {filteredOptions[optionIdx]?.label}
                  </Text>
                </Combobox.Option>
              )}
              style={{ height: 300 }}
            />
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      {showPeerGroup && (
        <Box style={{ left: '100%', position: 'absolute', top: '50%', transform: 'translate(20%, -50%)' }}>
          <Menu
            onChange={setOpenPeerGroupMenu}
            opened={openPeerGroupMenu}
          >
            <Menu.Target>
              <Tooltip
                label="Insert Funds from Peer Group"
                position="right"
                withArrow
              >
                <ActionIcon
                  disabled={disabled}
                  variant="transparent"
                >
                  <IconCategory />
                </ActionIcon>
              </Tooltip>
            </Menu.Target>
            <Menu.Dropdown
              mah={250}
              style={{ overflowY: 'auto' }}
            >
              {
                peerGroups.map(group => (
                  <Menu.Item
                    key={group.id}
                    onClick={clickPeerGroupHandler(group)}
                  >
                    <Text size="xs">
                      {group.name}
                    </Text>
                  </Menu.Item>
                ))
              }
              {
                peerGroups?.length === 0 && (
                  <Stack>
                    <Center p="sm">
                      <Anchor
                        component={Link}
                        size="sm"
                        to="/peer-groups?tabValue=fundScreener"
                      >
                        Add Peer Group
                      </Anchor>
                    </Center>
                  </Stack>
                )
              }
            </Menu.Dropdown>
          </Menu>
        </Box>
      )}
    </Box>
  )
}

export default GlobalFundSelector 