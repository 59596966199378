import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined'
import React from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import styled, { css } from 'styled-components'

import ActionButton from '../../components/tables/ActionButton'
import useAccessRights from "../../hooks/useAccessRights"
import { useMixpanel } from "../../hooks/useMixpanel"
import useUpdateDocsNotification from "../../hooks/useUpdateDocsNotification"
import { downloadPrivateEquityDocs } from "../../services"
import { buildFileFromResponse } from "../../utils"

const ActionButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  
  ${({ internal, theme }) => internal && css`
    button, a {
      color: ${theme.palette.aamui.iceBlue} !important;
    }
  
  `}
`

const StyledActionCell = styled.td`
  display: flex;
`


export default function ActionCell({
  dataItem,
  field,
  isPrivateEquity,
  openDeleteDialog,
  openNotifyDialog
}) {
  const { mpTrack } = useMixpanel()
  const { body, id } = dataItem || {}
  const showInfo = !!body
  const link = dataItem[field]
  const { hasAccessInternalClient } = useAccessRights()
  const { setNotificationToDownloaded, setNotificationToReadStatus } = useUpdateDocsNotification()
  const downloadPDF = async () => {
    const toastId = toast.info("We are preparing your document...", {
      autoClose: false,
      position: toast.POSITION.BOTTOM_CENTER,
    })
    const payload = {
      fileId: id
    }
    const mpProps = {
      'Document Name': dataItem?.name,
      'Document Type': dataItem?.story_type?.name,
      'Media Type': dataItem?.media_type,
      'Type': 'Download PE Document'
    }
    try {
      const response = await downloadPrivateEquityDocs(payload)
      buildFileFromResponse(response)
      await setNotificationToReadStatus(id)
      await setNotificationToDownloaded(id)

      if (isPrivateEquity) {
        mpTrack({
          eventName: `Download PE Document - ${dataItem?.name}`,
          properties: mpProps
        })
      }
    } catch (error) {
      mpTrack({
        eventName: `Fail to Download PE Document - ${dataItem?.name}`,
        properties: mpProps
      })
    } finally {
      toast.dismiss(toastId)
    }
  }

  const clickDownloadButtonHandler = async () => {
    await setNotificationToReadStatus(id)
    await setNotificationToDownloaded(id)
  }

  return (
    <StyledActionCell
      className="rms-action-cell"
    >
      <ActionButtonsWrapper>
        {link && !isPrivateEquity ? (
          <ActionButton
            color="default"
            href={link}
            onClick={clickDownloadButtonHandler}
            target="_blank"
          >
            <CloudDownloadOutlinedIcon />
          </ActionButton>
        ) : (
          <ActionButton
            color="default"
            onClick={() => downloadPDF()}
            target="_blank"
          >
            <CloudDownloadOutlinedIcon />
          </ActionButton>
        )}
        {showInfo && (
          <ActionButton
            color="default"
            component={Link}
            target="_blank"
            to={`/insights/${id}?o=t`}
          >
            <InfoOutlinedIcon />
          </ActionButton>
        )}
      </ActionButtonsWrapper>
      {hasAccessInternalClient && (
        <ActionButtonsWrapper hasAccessInternalClient>
          <ActionButton
            color="default"
            component={Link}
            key="edit"
            to={`/new-story?story_id=${id}`}
          >
            <CreateOutlinedIcon />
          </ActionButton>
          {!isPrivateEquity && (<ActionButton
            color="default"
            key="notify"
            onClick={() => {
              openNotifyDialog(dataItem)
            }}
          >
            <NotificationsNoneOutlinedIcon />
          </ActionButton>)}
          <ActionButton
            color="default"
            component={Link}
            key="copy"
            to={`/new-story?story_id=${id}&duplicate=true`}
          >
            <FileCopyOutlinedIcon />
          </ActionButton>
          <ActionButton
            color="default"
            key="delete"
            onClick={() => {
              openDeleteDialog(dataItem)
            }}
          >
            <DeleteOutlinedIcon />
          </ActionButton>
        </ActionButtonsWrapper>
      )}
    </StyledActionCell>
  )
}
