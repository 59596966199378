import React, { useState } from "react";
import { HotPerformance } from "../../components";
import ReportSnippets from "../../utils/reportInputSnippets";
import { ReturnsProcessor, dateTimeManager } from "../../utils";
import Typography from "@material-ui/core/Typography";
import { getBackfill, updateBackfill } from "../../services";
import { status as st } from "../../constants";
import { useSecurities } from "../../hooks";

const Selector = ({ data, fetched, changeBackfill, getBackfill, ...props }) => {
  const reportSnippets = new ReportSnippets({
    ...props,
    data,
    updateInputs: changeBackfill,
    submitFunction: getBackfill,
  });

  return (
    <div className="security-setup-container">
      <Typography variant="h6" align="center">
        Backfills
      </Typography>
      {reportSnippets.globalSearch(
        "original_security",
        "Select original security"
      )}
      {reportSnippets.submitButton("Get backfill")}

      {reportSnippets.textField("notes", "Backfill Description", {
        disabled: !fetched,
      })}
    </div>
  );
};

const BackfillTable = ({
  backfill,
  inception_date,
  updateStartDate,
  resetInceptionDate,
  onDataChange,
  updateAction,
  ...props
}) => {
  const { backfill: data } = backfill;

  return (
    <div>
      <HotPerformance
        {...props}
        data={data}
        licenseKey="non-commercial-and-evaluation"
        editable_columns={[{ field: "perf_return", title: "Performance" }]}
        index_name="date"
        inception_date={inception_date}
        updateStartDate={updateStartDate}
        resetInceptionDate={resetInceptionDate}
        onDataChange={onDataChange}
        updateAction={updateAction}
      />
    </div>
  );
};

export default function BackfillUpdate() {
  const [backfillData, setBackfillData] = useState({
    original_security: "",
    backfill: [],
    notes: "",
    end_date: null,
  });
  const [fetched, setFetched] = useState(false);
  const { search_list: searchList } = useSecurities();
  const [inceptionDate, setInceptionDate] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);

  const fetchBackfill = async () => {
    const { id } = backfillData.original_security || {};
    try {
      const response = await getBackfill({ id });
      let { backfill, end_date, notes } = response.data;
      backfill.forEach((o) => {
        o.date = dateTimeManager.dbToMonthYear(o.perf_date);
      });
      setBackfillData((i) => ({ ...i, backfill, end_date, notes }));
      setFetched(true);
    } catch (error) {}
  };

  const changeBackfill = ({ name, value }) => {
    setUpdateStatus(null);
    setBackfillData((i) => ({ ...i, [name]: value }));
  };

  const changeBackfillData = (value) => {
    setUpdateStatus(null);
    setBackfillData((i) => ({ ...i, backfill: value }));
  };

  const resetInceptionDate = () => {
    const { backfill: data, end_date } = backfillData;

    const new_data = ReturnsProcessor.resetInceptionDate(
      data,
      end_date,
      inceptionDate
    );
    setBackfillData((i) => ({ ...i, backfill: new_data }));
  };

  const postBackfill = async () => {
    setUpdateStatus(st.request);
    const { backfill: data, notes, original_security } = backfillData;
    if (!original_security) {
      return;
    }
    const clean_backfill = data
      .filter((o) => !!o.perf_return)
      .map((o) => ({
        perf_return: o.perf_return,
        perf_date: o.perf_date,
      }));
    const payload = {
      backfill: clean_backfill,
      id: original_security.id,
      notes,
    };
    try {
      await updateBackfill(payload);
      setUpdateStatus(st.success);
    } catch (error) {
      setUpdateStatus(st.failure);
    }
  };

  const updating = updateStatus === st.request;
  const updated = updateStatus === st.success;

  return (
    <div className="backfill-route">
      <Selector
        data={backfillData}
        fetched={fetched}
        changeBackfill={changeBackfill}
        getBackfill={fetchBackfill}
        search_list={searchList}
      />
      {fetched && (
        <BackfillTable
          backfill={backfillData}
          inception_date={inceptionDate}
          updateStartDate={({ target }) => {
            setInceptionDate(target.value);
          }}
          resetInceptionDate={resetInceptionDate}
          onDataChange={changeBackfillData}
          updateAction={postBackfill}
          updating={updating}
          updated={updated}
          fetched={fetched}
        />
      )}
    </div>
  );
}
