import React, { useState } from "react";
import ReportSnippets from "../../utils/reportInputSnippets";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { status as st } from "../../constants";
import { unlockDocument } from "../../services";
import { buildFileFromResponse } from "../../utils";

const WaitingScreen = () => {
  return (
    <div className="loading-progress">
      <Typography align="center">Unlocking...</Typography>
      <LinearProgress />
    </div>
  );
};

const Input = ({
  unlock_inputs,
  updateUnlockInputs,
  loading,
  getUnlocked,
  ...props
}) => {
  const { docs } = unlock_inputs;
  const disabled = !!(docs.length === 0 || loading);
  const reportSnippets = new ReportSnippets({
    ...props,
    data: unlock_inputs,
    updateInputs: updateUnlockInputs,
    submitFunction: getUnlocked,
  });
  return (
    <div className="report-setup">
      {reportSnippets.dropZone("docs", "Document to unlock", {
        multi: true,
      })}
      {reportSnippets.textField("password", "Password to open (if required)")}
      {reportSnippets.submitButton(
        loading ? "Unlocking. Please wait" : "Unlock",
        { disabled }
      )}
    </div>
  );
};

export default function Unlocking() {
  const [unlockStatus, setUnlockStatus] = useState(null);
  const [inputs, setInputs] = useState({
    password: "",
    docs: [],
  });
  const updateInputs = ({ name, value }) => {
    setInputs((i) => ({ ...i, [name]: value }));
  };

  const getUnlocked = async () => {
    setUnlockStatus(st.request);
    const { docs = [], password } = inputs || {};
    const payload = new FormData();
    for (let i = 0; i < docs.length; i++) {
      const element = docs[i];
      payload.append("docs[]", element);
    }
    payload.append("password", password);
    try {
      const response = await unlockDocument(payload);
      buildFileFromResponse(response);
    } catch (error) {}
    setUnlockStatus(null);
  };

  const loading = unlockStatus === st.request;
  return (
    <div className="watermarking-route">
      <Input
        unlock_inputs={inputs}
        updateUnlockInputs={updateInputs}
        loading={loading}
        getUnlocked={getUnlocked}
      />
      {loading && <WaitingScreen />}
    </div>
  );
}
