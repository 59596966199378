import Typography from '@material-ui/core/Typography'
import { process } from '@progress/kendo-data-query'
import { GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid'
import { Input } from '@progress/kendo-react-inputs'
import { Tooltip } from '@progress/kendo-react-tooltip'
import { useQuery } from '@tanstack/react-query'
import { formatNumber } from '@telerik/kendo-intl'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getSecurityDetails } from '../../services/helpers'

import {
  GenericTable,
  KendoDropDown,
  KendoFlatSelector,
  PortfolioTable,
} from '../../components'
import DownloadButton from '../../components/misc/DownloadButton'
import { dateTimeManager } from '../../utils'

const viewTypeNameMap = {
  AAAP: 'aaap-performance',
  FoF: 'analytics',
  NAV: 'aaap-navs',
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const unused = [
  'index',
  'strategy',
  'YTD',
  'MTD',
  'Date',
  'underlying_name',
  'class_name',
  'original_index',
]

const AccountList = ({ tooltipData }) => {
  const { fund_name, perf_date, underlying_name } = tooltipData

  if (perf_date) {
    return (
      <div>
        <Typography
          paragraph={false}
          style={{ padding: '24px' }}
          variant="body2"
        >
          {dateTimeManager.dbToFormat(perf_date)}
        </Typography>
      </div>
    )
  }

  if (fund_name) {
    return (
      <>
        <div>
          <Typography
            paragraph={false}
            style={{ padding: '8px 24px' }}
            variant="body2"
          >
            {fund_name}
          </Typography>
        </div>
        {underlying_name && (
          <div>
            <Typography
              paragraph={false}
              style={{ padding: '8px 24px' }}
              variant="body2"
            >
              <b>{underlying_name}</b>
            </Typography>
          </div>
        )}
      </>
    )
  }

  return <div />
}

const Performance = ({
  getYearNames,
  filteredData,
  loading,
  yearList,
  asOfYear,
  setAsOfYear,
  updateFilters,
  filters,
  portfolios,
  hide_portfolios,
  strategy_list,
  share_class_list,
  hasAccessInternalClient,
  fetchFunction,
  currentView,
  renderTitleCell,
  showTooltip,
  closeTooltip,
  tooltipData,
}) => {
  const years = getYearNames()
  const colNames = months.concat(years)

  const total = filteredData.length

  const renderCell = useCallback(
    ({ colSpan, columnIndex, dataItem, field }) => {
      const { [field]: entry } = dataItem || {}
      const { perf_date, perf_return } = entry || {}
      const formatReturn = formatNumber(perf_return, 'p2')
      return (
        <td
          aria-colindex={columnIndex + 1}
          aria-selected={false}
          colSpan={colSpan}
          onMouseEnter={(e) => showTooltip(e, { perf_date })}
          onMouseLeave={closeTooltip}
          role="gridcell"
          style={{ cursor: 'default' }}
          title={perf_return}
        >
          {formatReturn}
        </td>
      )
    },
    [showTooltip, closeTooltip],
  )

  return (
    <div className="performance-overview-table">
      <Tooltip
        anchorElement="target"
        content={() => <AccountList tooltipData={tooltipData?.tooltipData} />}
        position="right"
        open={tooltipData?.tooltipOpen}
      >
        <PortfolioTable
          custom_cols
          data={filteredData}
          index_col_props={{ cell: renderTitleCell }}
          loading={loading}
          pageSize={20}
          rowHeight={36}
          scrollable="virtual"
          sortable={true}
          style={{ maxHeight: '800px', height: 'auto' }}
          total={total}
        >
          <GridToolbar>
            <div className="toolbar">
              <KendoDropDown
                className="year-filter"
                items={yearList}
                onChange={({ target }) => setAsOfYear(target.value)}
                placeholder="Select year..."
                value={asOfYear}
              />
              {portfolios && !hide_portfolios && (
                <KendoFlatSelector
                  className="portfolio-filter"
                  fullWidth={false}
                  items={portfolios.map((o) => o.name)}
                  name="portfolio_search"
                  onChange={updateFilters}
                  placeholder="Filter by portfolio..."
                  value={filters.portfolio_search}
                />
              )}
              <Input
                className="name-filter"
                name="name_search"
                onChange={updateFilters}
                placeholder="Search by fund name..."
                value={filters.name_search}
              />
              <KendoFlatSelector
                className="strategy-filter"
                items={strategy_list}
                name="strategy_search"
                onChange={updateFilters}
                placeholder="Filter by strategy..."
                value={filters.strategy_search}
              />
              {share_class_list && (
                <KendoFlatSelector
                  className="strategy-filter"
                  items={share_class_list}
                  name="share_class_search"
                  onChange={updateFilters}
                  placeholder="Filter by share class..."
                  value={filters.share_class_search}
                />
              )}
              {hasAccessInternalClient && (
                <DownloadButton
                  fetchFunction={fetchFunction}
                  fileName={`performance-overview-${viewTypeNameMap[currentView]}-${asOfYear}.csv`}
                  ml={2}
                >
                  Export CSV
                </DownloadButton>
              )}
            </div>
          </GridToolbar>
          <Column field="Date" />
          {colNames.map((m, id) => (
            <Column
              cell={renderCell}
              field={m}
              format="{0:p2}"
              key={id}
              title={m}
            />
          ))}
        </PortfolioTable>
      </Tooltip>
    </div>
  )
}

const Navs = ({
  loading,
  filteredData,
  renderTitleCell,
  yearList,
  asOfYear,
  setAsOfYear,
  updateFilters,
  filters,
  strategy_list,
  share_class_list,
  hasAccessInternalClient,
  fetchFunction,
  currentView,
  showTooltip,
  closeTooltip,
  tooltipData,
}) => {
  const total = filteredData.length

  return (
    <div className="performance-overview-table">
      <Tooltip
        anchorElement="target"
        content={() => <AccountList tooltipData={tooltipData?.tooltipData} />}
        position="right"
        open={tooltipData?.tooltipOpen}
      >
        <GenericTable
          custom_cols
          data={filteredData}
          index_col_props={{ cell: renderTitleCell }}
          index_width="250px"
          loading={loading}
          pageSize={20}
          rowHeight={36}
          scrollable="virtual"
          sortable
          style={{ maxHeight: '800px', height: 'auto' }}
          total={total}
        >
          <GridToolbar>
            <div className="toolbar">
              <KendoDropDown
                className="year-filter"
                items={yearList}
                onChange={({ target }) => setAsOfYear(target.value)}
                placeholder="Select year..."
                value={asOfYear}
              />
              <Input
                className="name-filter"
                name="name_search"
                onChange={updateFilters}
                placeholder="Search by fund name..."
                value={filters.name_search}
              />
              <KendoFlatSelector
                className="strategy-filter"
                items={strategy_list}
                name="strategy_search"
                onChange={updateFilters}
                placeholder="Filter by strategy..."
                value={filters.strategy_search}
              />
              {share_class_list && (
                <KendoFlatSelector
                  className="strategy-filter"
                  items={share_class_list}
                  name="share_class_search"
                  onChange={updateFilters}
                  placeholder="Filter by share class..."
                  value={filters.share_class_search}
                />
              )}
              {hasAccessInternalClient && (
                <DownloadButton
                  fetchFunction={fetchFunction}
                  fileName={`performance-overview-${viewTypeNameMap[currentView]}-${asOfYear}.csv`}
                  ml={2}
                >
                  Export CSV
                </DownloadButton>
              )}
            </div>
          </GridToolbar>
          {months.map((m, id) => (
            <Column field={m} format="{0:n2}" key={id} title={m} />
          ))}
        </GenericTable>
      </Tooltip>
    </div>
  )
}

const PerformanceOverviewTable = ({
  performance_overview,
  portfolios = [],
  asOfYear,
  setAsOfYear,
  share_class_list,
  strategy_list,
  yearList,
  hide_portfolios,
  hasAccessInternalClient,
  fetchFunction,
  currentView,
  loading,
  mode,
}) => {
  const [filters, setFilters] = useState({
    name_search: '',
    portfolio_search: '',
    share_class_search: '',
    strategy_search: '',
  })

  const [tooltipData, setTooltipData] = useState({
    tooltipData: {},
    tooltipElement: null,
    tooltipOpen: false,
  })

  const [filteredData, setFilteredData] = useState(performance_overview || [])

  const updateFilters = useCallback(({ target: { name, value } }) => {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }, [])

  const getYearNames = useCallback(() => {
    const allCols = months.concat(unused)
    if (filteredData?.length === 0) {
      return []
    }
    const keys = Object.keys(filteredData?.[0] || {}).filter(
      (k) => k !== 'fund_id',
    )
    const years = keys.filter((o) => allCols.indexOf(o) === -1)
    return years.sort().reverse()
  }, [filteredData])

  const closeTooltip = useCallback((e) => {
    setTooltipData({
      tooltipData: {},
      tooltipElement: null,
      tooltipOpen: false,
    })
  }, [])

  const showTooltip = useCallback((e, tooltipData) => {
    const { target } = e
    setTooltipData({
      tooltipData,
      tooltipElement: target,
      tooltipOpen: true,
    })
  }, [])

  const renderTitleCell = useCallback(
    ({ colSpan, columnIndex, dataItem, field }) => {
      const {
        original_index,
        underlying_name,
        [field]: fund_name,
      } = dataItem || {}
      return (
        <td
          aria-colindex={columnIndex + 1}
          aria-selected={false}
          colSpan={colSpan}
          onMouseEnter={(e) =>
            showTooltip(e, { fund_name: original_index, underlying_name })
          }
          onMouseLeave={closeTooltip}
          role="gridcell"
          style={{ cursor: 'default' }}
          title={fund_name}
        >
          {fund_name}
        </td>
      )
    },
    [showTooltip, closeTooltip],
  )

  const selectedPortfolio = useMemo(
    () => portfolios?.find((o) => o.name === filters.portfolio_search),
    [filters.portfolio_search, portfolios],
  )

  const queryResult = useQuery({
    queryKey: ['portfolio_weights', selectedPortfolio?.id],
    queryFn: async ({ signal }) => {
      const data = await getSecurityDetails(
        {
          fund_id: selectedPortfolio?.id,
          security_type: 'FoF',
          params: { 'selected-fields': 'portfolio_weights' },
          props: { signal },
        },
        portfolios,
      )

      return data
    },
    staleTime: Infinity,
    enabled: !!selectedPortfolio?.id,
  })

  const selectedPortfolioData = useMemo(
    () => queryResult.data,
    [queryResult.data],
  )

  useEffect(() => {
    const applyFilters = () => {
      let prefilteredOverview = [...(performance_overview || [])]
      if (filters.portfolio_search !== '' && selectedPortfolioData) {
        const portDate = moment(new Date())
          .add('-1', 'M')
          .startOf('M')
          .format('YYYY-MM-DD')
        const { portfolio_weights = [] } = selectedPortfolioData || {}

        const filteredFunds = portfolio_weights
          ?.filter((o) => o.weight_date === portDate)
          ?.map((o) => o.security_name)
        prefilteredOverview = prefilteredOverview?.filter(
          (o) => filteredFunds?.indexOf(o.index) !== -1,
        )
      }

      const appliedFilters = []
      if (filters.name_search) {
        appliedFilters.push({
          field: 'index',
          operator: 'contains',
          value: filters.name_search,
        })
      }
      if (filters.strategy_search) {
        appliedFilters.push({
          field: 'strategy',
          operator: 'eq',
          value: filters.strategy_search,
        })
      }
      if (filters.share_class_search) {
        appliedFilters.push({
          field: 'class_name',
          operator: 'eq',
          value: filters.share_class_search,
        })
      }

      const filter = { filters: appliedFilters, logic: 'and' }
      const sort = [{ dir: 'asc', field: 'index' }]
      const processed = process(prefilteredOverview, { filter, sort })

      return processed
    }

    const processedData = applyFilters()
    setFilteredData(processedData?.data)
  }, [filters, performance_overview, selectedPortfolioData])

  return mode === 'performance' ? (
    <Performance
      getYearNames={getYearNames}
      filteredData={filteredData}
      tooltipData={tooltipData}
      loading={loading}
      yearList={yearList}
      asOfYear={asOfYear}
      setAsOfYear={setAsOfYear}
      updateFilters={updateFilters}
      filters={filters}
      portfolios={portfolios}
      hide_portfolios={hide_portfolios}
      strategy_list={strategy_list}
      share_class_list={share_class_list}
      hasAccessInternalClient={hasAccessInternalClient}
      fetchFunction={fetchFunction}
      currentView={currentView}
      renderTitleCell={renderTitleCell}
      showTooltip={showTooltip}
      closeTooltip={closeTooltip}
    />
  ) : (
    <Navs
      loading={loading}
      filteredData={filteredData}
      renderTitleCell={renderTitleCell}
      yearList={yearList}
      asOfYear={asOfYear}
      setAsOfYear={setAsOfYear}
      updateFilters={updateFilters}
      filters={filters}
      strategy_list={strategy_list}
      share_class_list={share_class_list}
      hasAccessInternalClient={hasAccessInternalClient}
      fetchFunction={fetchFunction}
      currentView={currentView}
      showTooltip={showTooltip}
      closeTooltip={closeTooltip}
      tooltipData={tooltipData}
    />
  )
}

export default PerformanceOverviewTable
