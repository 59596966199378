import React, { useState } from "react";
import * as ReactDOM from "react-dom";
import { Grid } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

class LoadingPanel extends React.Component {
  render() {
    const loadingPanel = (
      <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
      </div>
    );

    const gridContent = document && document.querySelector(".k-grid-content");
    return gridContent
      ? ReactDOM.createPortal(loadingPanel, gridContent)
      : loadingPanel;
  }
}

export default function SmartGrid({
  data,
  pageSize,
  loading,
  initialState,
  ...rest
}) {
  const [dataState, setDataState] = useState({ ...initialState });

  const processed = process(data, dataState);
  return (
    <>
      <Grid
        pageSize={pageSize}
        {...rest}
        {...dataState}
        data={processed}
        onDataStateChange={(e) => {
          setDataState(e.dataState);
        }}
      ></Grid>
      {loading && <LoadingPanel />}
    </>
  );
}
