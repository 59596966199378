import { Box, Snackbar } from "@material-ui/core";
import { createContext, useState } from "react";

export const SnackbarContext = createContext()

export const SnackbarProvider = ({
  children
}) => {
  const [open, setOpen] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState()

  const openSnackbar = (message) => {
    setSnackbarMsg(message)
    setOpen(true)
  }

  const closeSnackbarHandler = () => {
    setOpen(false)
  }

  const clearSnackbarMsgHandler = () => {
    setSnackbarMsg()
  }

  return (
    <SnackbarContext.Provider
      value={{
        openSnackbar
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open && !!snackbarMsg}
        onClose={closeSnackbarHandler}
        autoHideDuration={3000}
        message={snackbarMsg}
        TransitionProps={{
          onExited: clearSnackbarMsgHandler
        }}
      >
        <Box className="copy-snackbar-wrapper">
          {snackbarMsg}
        </Box>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

