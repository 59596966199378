import { Box } from '@material-ui/core'
import React from 'react'

import { useSecurities } from '../../hooks'
import PortOptReturnsLineChart from './PortOptReturnsLineChart'
import PortOptStats from './PortOptStats'
import PortOptTableData from './PortOptTableData'
import PortOptWeightResult from './PortOptWeightResult'
import { usePortfolioOptimiser } from './RefactoredPortfolioOptimiserProvider'

const PortfolioOptimiserResult = () => {
  const { isNewRunPage, isRunRunning, isSubmittingOrFetchingRun } = usePortfolioOptimiser()
  const { isLoadingSecurities } = useSecurities()
  const showResultSection = !isSubmittingOrFetchingRun && !isRunRunning && !isNewRunPage && !isLoadingSecurities

  return showResultSection ? (
    <Box
      component="section"
      id="result-section"
      mx="auto"
      my={8}
      style={{ scrollMarginTop: '80px' }}
    >
      <PortOptWeightResult />
      <PortOptTableData />
      <PortOptStats />
      <PortOptReturnsLineChart />
    </Box>
  ) : null
}

export default PortfolioOptimiserResult
