import React, { Component } from "react";
import { statefulTableGenerator } from "../../components/tables";
import {
  Grid as KGrid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  current_cash_button_list,
  current_cash_percentage,
  current_cash_usd,
} from "../../data/cashManagement";
import { extractSeriesNames } from "../../utils";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Tooltip } from "@progress/kendo-react-tooltip";

const SuperGrid = statefulTableGenerator(KGrid);

export class currentCashTab extends Component {
  state = {
    current_view: "percentage",
    tooltip_element: null,
    tooltip_open: false,
    tooltip_data: {},
  };

  changeView = (new_view) => {
    this.setState({ current_view: new_view });
  };

  generateColumn = (idx, title, field) => {
    return <Column key={idx} title={title} field={field} />;
  };

  generateButtonGroup = () => {
    const { current_view } = this.state;
    return current_cash_button_list.map((btn, idx) => {
      const color = current_view === btn.id ? "primary" : "default";
      return (
        <Button
          onClick={() => {
            this.changeView(btn.id);
          }}
          color={color}
          key={idx}
        >
          {btn.title}
        </Button>
      );
    });
  };

  getData = () => {
    const { current_view } = this.state;
    switch (current_view) {
      case "percentage":
        return current_cash_percentage;
      case "usd":
        return current_cash_usd;
      default:
        return [];
    }
  };

  showTooltip = (e, tooltip_data) => {
    const { target } = e;
    this.setState({
      tooltip_element: target,
      tooltip_open: true,
      tooltip_data,
    });
  };

  closeTooltip = (e) => {
    this.setState({
      tooltip_element: null,
      tooltip_open: false,
      tooltip_data: {},
    });
  };

  account_names = {
    trading: "Trading Account",
    investor_account: "Investor Account",
    holdback: "Holdback Account",
    ops_account: "Operations Account",
  };

  renderAccountList = () => {
    const { tooltip_data = {} } = this.state;
    const fields = Object.keys(tooltip_data);
    return fields.map((field, idx) => (
      <div key={idx} className="tooltip-row">
        <span className="row-name">
          <Typography paragraph={false} variant="body2">
            {`${this.account_names[field]}: `}
          </Typography>
        </span>
        &nbsp;
        <span className="row-value">
          <Typography paragraph={false} variant="body2">
            {tooltip_data[field]}
          </Typography>
        </span>
      </div>
    ));
  };

  renderTooltip = (props) => {
    return <div className="tooltip-content">{this.renderAccountList()}</div>;
  };

  renderCell = (props) => {
    const { dataItem, colSpan, columnIndex, field } = props;
    const { value, account_values = {} } = dataItem[field];
    return (
      <td
        colSpan={colSpan}
        role="gridcell"
        aria-colindex={columnIndex + 1}
        aria-selected={false}
        onMouseEnter={(e) => this.showTooltip(e, account_values)}
        onMouseLeave={this.closeTooltip}
        title={value}
      >
        {value}
      </td>
    );
  };

  render() {
    const data = this.getData();
    const col_names = extractSeriesNames(data);
    const { tooltip_element, tooltip_open } = this.state;
    return (
      <React.Fragment>
        <div className="current-cash-container">
          <Tooltip
            anchorElement="target"
            open={tooltip_open}
            targetElement={tooltip_element}
            openDelay={50}
            content={this.renderTooltip}
          >
            <SuperGrid
              className="current-cash-table"
              style={{ style: "500px" }}
              scrollable="scrollable"
              data={data || []}
              pageable={false}
              filterable={false}
            >
              <GridToolbar className="current-cash-toolbar">
                <ButtonGroup
                  className="view-buttons"
                  variant="contained"
                  color="default"
                  align="center"
                >
                  {this.generateButtonGroup()}
                </ButtonGroup>
                <TextField label="Search for a cell" className="search-bar" />
              </GridToolbar>
              <Column field="index" title="Cell" />
              {col_names.map((month, idx) => (
                <Column
                  key={idx}
                  field={month}
                  title={month}
                  cell={this.renderCell}
                />
              ))}
            </SuperGrid>
            <div id="agglomerations" className="cash-tool-tip"></div>
          </Tooltip>
        </div>
      </React.Fragment>
    );
  }
}

export default currentCashTab;
