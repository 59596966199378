import { sortBy } from "lodash"
import { MANAGER_HOLDINGS_SHORTNAME_MAP } from "../../constants/env"

function swapKeys(json) {
  var ret = {}
  for (var key in json) {
    ret[json[key]] = key
  }
  return ret
}

export const shortNames = MANAGER_HOLDINGS_SHORTNAME_MAP

export const createTotals = (data, activeNames, allNames) => {
  const nameDict = swapKeys(shortNames)
  const columns = activeNames.length ? activeNames : allNames
  const filtered = data.map(row => {
    const total = columns.reduce((acc, col) => {
      const value = row[nameDict[col]] || row[col]
      return value != null ? acc + value : acc
    }, 0)

    if (total === 0) {
      return null
    }

    const nonEmptyEntry = columns.reduce((entry, col) => {
      const value = row[nameDict[col]] || row[col]
      if (value != null && value !== 0) {
        entry[col] = value
      }
      return entry
    }, { index: row.index })

    return { ...nonEmptyEntry, Total: total }
  }).filter(entry => entry !== null)


  const sorted = sortBy(filtered, "Total").reverse()

  return sorted
}
