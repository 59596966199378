import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import AutoCompleteSelect from '../../components/forms/AutoCompleteSelect'
import FormTextField from '../../components/forms/FormTextField'
import { useSecurities } from '../../hooks'
import {
  positivePercentage,
  requiredString,
  validLowerBound,
  validPositiveLowerBound,
  validUpperBound,
} from '../../utils/yup-utils'
import {
  benchmarkConstraintsInputName,
  defaultBenchmarkValues,
} from './form-default-values'
import OptimiserPercentageSumLabel from './layout/OptimiserPercentageSumLabel'
import PortOptInputLayout, {
  PortOptGridContainer,
  PortOptGridRow,
} from './PortOptInputLayout'

export const portOptBenchmarkSchema = yup.object().shape({
  alphaLowerBound: validPositiveLowerBound().concat(positivePercentage()),
  alphaUpperBound: validUpperBound(
    'alphaLowerBound',
    positivePercentage(),
    true,
  ),
  benchmark: requiredString(),
  betaLowerBound: validLowerBound(),
  betaUpperBound: validUpperBound('betaLowerBound', null, true),
})

const PortOptBenchmarkInput = () => {
  const { market_index_list } = useSecurities()
  const {
    control,
    formState: { errors },
    register,
    watch,
  } = useFormContext()
  const benchmarkConstraintsArrays = useFieldArray({
    control,
    name: benchmarkConstraintsInputName,
  })
  const enableBenchmarkConstraints = watch('enableBenchmarkConstraints', false)

  const clickAddButtonHandler = () => {
    benchmarkConstraintsArrays.append(defaultBenchmarkValues)
  }

  const clickRemoveButtonHandler = (index) => () => {
    benchmarkConstraintsArrays.remove(index)
  }

  return (
    <PortOptInputLayout
      header="Benchmark Constraint Options"
      toggleComponent={
        <FormControlLabel
          control={
            <Switch
              checked={enableBenchmarkConstraints}
              name="enableBenchmarkContstraints"
              {...register('enableBenchmarkConstraints')}
            />
          }
        />
      }
    >
      {enableBenchmarkConstraints && (
        <>
          <PortOptGridContainer>
            {benchmarkConstraintsArrays.fields.map((it, idx) => (
              <PortOptGridRow key={it.id}>
                <Grid item xs>
                  <AutoCompleteSelect
                    dataKey="name"
                    error={
                      !!errors?.[benchmarkConstraintsInputName]?.[idx]
                        ?.benchmark?.message
                    }
                    filterKey="name"
                    helperText={
                      errors?.[benchmarkConstraintsInputName]?.[idx]?.benchmark
                        ?.message
                    }
                    inputName={`${benchmarkConstraintsInputName}.${idx}.benchmark`}
                    isStringArr
                    label="Benchmark"
                    options={market_index_list?.map((it) => it.name)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormTextField
                    errorMessage={
                      errors?.[benchmarkConstraintsInputName]?.[idx]
                        ?.betaLowerBound?.message
                    }
                    label="Beta Lower Bound"
                    name={`${benchmarkConstraintsInputName}.${idx}.betaLowerBound`}
                    type="number"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormTextField
                    errorMessage={
                      errors?.[benchmarkConstraintsInputName]?.[idx]
                        ?.betaUpperBound?.message
                    }
                    label="Beta Upper Bound"
                    name={`${benchmarkConstraintsInputName}.${idx}.betaUpperBound`}
                    type="number"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormTextField
                    endAdornment="%"
                    errorMessage={
                      errors?.[benchmarkConstraintsInputName]?.[idx]
                        ?.alphaLowerBound?.message
                    }
                    label="Alpha Lower Bound"
                    name={`${benchmarkConstraintsInputName}.${idx}.alphaLowerBound`}
                    type="number"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormTextField
                    endAdornment="%"
                    errorMessage={
                      errors?.[benchmarkConstraintsInputName]?.[idx]
                        ?.alphaUpperBound?.message
                    }
                    label="Alpha Upper Bound"
                    name={`${benchmarkConstraintsInputName}.${idx}.alphaUpperBound`}
                    type="number"
                  />
                </Grid>
                <Grid item xs style={{ flexGrow: 0 }}>
                  <Box pt={1.5}>
                    {benchmarkConstraintsArrays.fields.length > 1 && (
                      <IconButton onClick={clickRemoveButtonHandler(idx)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </PortOptGridRow>
            ))}
            <PortOptGridRow>
              <Grid item xs />
              <Grid item xs={2} />
              <Grid item xs={2} />
              <Grid item xs={2}>
                <OptimiserPercentageSumLabel
                  arrayName={benchmarkConstraintsInputName}
                  targetKey="alphaLowerBound"
                />
              </Grid>
              <Grid item xs={2}>
                <OptimiserPercentageSumLabel
                  arrayName={benchmarkConstraintsInputName}
                  targetKey="alphaUpperBound"
                />
              </Grid>
              <Grid item xs style={{ flexGrow: 0 }}>
                {benchmarkConstraintsArrays.fields.length > 1 && (
                  <div style={{ width: '48px' }}></div>
                )}
              </Grid>
            </PortOptGridRow>
          </PortOptGridContainer>
          <Box mt={2}>
            <Button
              color="primary"
              onClick={clickAddButtonHandler}
              variant="contained"
            >
              Add Additional Benchmark
            </Button>
          </Box>
        </>
      )}
    </PortOptInputLayout>
  )
}

export default PortOptBenchmarkInput
