import React from 'react'
import { Box, withStyles } from '@material-ui/core'

import chartPlaceholder from '../../styles/assets/images/chart-placeholder.png' // Adjust the path as necessary

const styles = (theme) => ({
  '@keyframes muiPulse': {
    '0%': {
      opacity: 0.6,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.6,
    },
  },
  chartSkeleton: {
    width: '100%',
    height: '300px',
    backgroundImage: `url(${chartPlaceholder})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'blur(8px)',
    borderRadius: '8px',
    animation: '$muiPulse 1.5s ease-in-out infinite',
  },
})

const SkeletonChartLoader = withStyles(styles)(({ classes }) => (
  <Box className={classes.chartSkeleton}></Box>
))

export default SkeletonChartLoader
