import { Box, Button, Card, CardContent } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import clsx from 'clsx'
import React from 'react'

import SkelatonTextLoader from '../../components/skeletons/SkelatonTextLoader'
import {
  HUBSPOT_BLOG_POST_ENDPOINT,
  HUBSPOT_PROXY_URL,
  IS_PROD_ENV,
} from '../../constants/env'
import useAccessRights from '../../hooks/useAccessRights'
import getFundOrUnderlyingId from '../../utils/getFundOrUnderlyingId'
import FundSummaryInfoEditButton from './FundSummaryInfoEditButton'

interface FundInfoSummaryProps {
  legacySummary: string
  fundDetails: any
  canEdit: boolean
  editRenderer: (field: { field: string }, options: { multiline: boolean, rows: number, rowsMax: number }) => React.ReactNode
  loading: boolean
}

const FundInfoSummary: React.FC<FundInfoSummaryProps> = ({
  canEdit,
  editRenderer,
  fundDetails,
  legacySummary,
  loading,
}) => {
  const { hasAccessInternalClient } = useAccessRights()
  const fundOrUnderlyingId = getFundOrUnderlyingId(fundDetails)

  const { data: hubSpotFundSummaryResult, isLoading } = useQuery(
    ['underlying-fund', fundOrUnderlyingId],
    async () => {
      const response = await axios.post(HUBSPOT_PROXY_URL, {
        method: 'GET',
        url: `${HUBSPOT_BLOG_POST_ENDPOINT}?slug=fund-summary/${fundOrUnderlyingId}${IS_PROD_ENV ? '&state=PUBLISHED' : ''}`,
      })
      return response?.data?.results
    },
    {
      enabled: Boolean(fundOrUnderlyingId),
      staleTime: Infinity,
    },
  )

  const { postBody: hubSpotFundSummary } = hubSpotFundSummaryResult?.[0] || {}
  const isEditLegacySummaryMode = canEdit && !hubSpotFundSummary

  const EditButton = () => (
    <FundSummaryInfoEditButton
      fund={fundDetails}
      queryKey={['underlying-fund', fundOrUnderlyingId]}
    />
  )

  const renderEditMode = () => (
    <>
      {editRenderer(
        { field: 'summary' },
        { multiline: true, rows: 3, rowsMax: 30 },
      )}
      <Box mt={2}>
        <EditButton />
      </Box>
    </>
  )

  const renderSummaryContent = () => {
    if (isLoading || loading) return <SkelatonTextLoader />

    if (hubSpotFundSummary) {
      return (
        <>
          <div dangerouslySetInnerHTML={{ __html: hubSpotFundSummary }} />
          {canEdit && hasAccessInternalClient && <EditButton />}
        </>
      )
    }

    return (
      <Typography align="justify" variant="body2">
        {legacySummary}
      </Typography>
    )
  }

  if (isEditLegacySummaryMode) return renderEditMode()
  if (!legacySummary && !hubSpotFundSummary) return null

  return (
    <div className={clsx({ 'fund-detail-hs-summary': hubSpotFundSummary, summary: !hubSpotFundSummary })}>
      <Card>
        <CardContent>
          <Typography variant="h6">Summary</Typography>
          {renderSummaryContent()}
        </CardContent>
      </Card>
    </div>
  )
}


export default FundInfoSummary
