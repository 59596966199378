import React, { Component } from "react";
import PropTypes from "prop-types";
import { GridToolbar } from "@progress/kendo-react-grid";
import { PortfolioTable, KendoDropDown } from "../../components";

export default class riskExposureReport extends Component {
  static propTypes = {
    risk_analysis: PropTypes.object,
  };

  state = {
    stress_table: "Stress Summary",
    scenario: "Lehman Crisis",
    report_type: "Weighted Summary",
    table_name: "Equity Regional Exposure",
    nested_table_name: "",
    mode: "Detailed",
  };

  changeReport = (e) => {
    const { value, name } = e.target;
    if (value) {
      if (name === "table_name") {
        const { [value]: nested_options = [] } = this.nested;
        const is_nested = nested_options.length > 0;
        const nested_table_name = is_nested ? "Top-Level" : "";
        this.setState({ [name]: value, nested_table_name });
      } else if (name === "report_type") {
        this.setState({ [name]: value, mode: "Detailed" });
      } else {
        this.setState({ [name]: value });
      }
    }
  };

  reports = [
    "Unweighted",
    "Unweighted Summary",
    "Weighted",
    "Weighted Summary",
    "Sensitivity",
  ];

  report_types_stress = ["Unweighted", "Weighted"];

  scenario_dict = {
    Unweighted: "detailed_frames",
    Weighted: "weighted_frames",
  };

  stress_tables = ["Stress Summary", "Tests"];

  tables = [
    "Equity Regional Exposure",
    "Equity Sector Exposure",
    "Credit Exposure",
    "Rates Exposure",
    "FX Exposure",
    "Commodity Exposure",
    "Greeks Exposure",
  ];

  nested = {
    "Credit Exposure": [
      "Corporate High Yield",
      "Corporate Investment Grade",
      "Mortgages",
      "Sovereign",
    ],
    "Rates Exposure": ["USD", "EUR", "JPY", "EM", "Other"],
    "Greeks Exposure": ["Delta", "Gamma", "Theta", "Vega"],
  };

  nested_names = ["Credit Exposure", "Rates Exposure", "Greeks Exposure"];

  flats = ["Sensitivity", "Unweighted Summary", "Weighted Summary"];

  modes = ["Aggregated", "Detailed"];

  scenarios = ["11 Europe sovereign debt crisis", "Lehman Crisis"];

  getTableData = (table_data) => {
    const { table_name, nested_table_name } = this.state;
    const { [table_name]: data = [] } = table_data || {};
    if (this.nested_names.indexOf(table_name) !== -1) {
      const { [nested_table_name]: nested_data = [] } = data || {};
      return nested_data;
    } else {
      return data;
    }
  };

  getData = () => {
    const { report_type, table_name, nested_table_name } = this.state;
    const { risk_analysis } = this.props;
    const { report } = risk_analysis || {};
    if (this.flats.indexOf(report_type) !== -1) {
      const { [report_type]: data = [] } = report || {};
      return data;
    } else {
      const { [report_type]: table_data = [] } = report || {};
      const { [table_name]: data = [] } = table_data || {};
      if (this.nested_names.indexOf(table_name) !== -1) {
        const { [nested_table_name]: nested_data = [] } = data || {};
        return nested_data;
      } else {
        return data;
      }
    }
  };

  getStressData = () => {
    const { risk_analysis } = this.props;
    const { report } = risk_analysis || {};
    const { stress_table, report_type, scenario } = this.state;
    if (stress_table === "Stress Summary") {
      return report[stress_table];
    } else {
      const tests = report[stress_table];
      const { [scenario]: scenario_data } = tests || {};
      const { [this.scenario_dict[report_type]]: table_data } =
        scenario_data || {};
      return this.getTableData(table_data);
    }
  };

  renderExposureSelectors = () => {
    const { report_type, table_name, nested_table_name, mode } = this.state;
    const { [table_name]: nested_options = [] } = this.nested;
    const show_table_selector = this.flats.indexOf(report_type) === -1;
    const show_nested_selector = this.nested_names.indexOf(table_name) !== -1;
    const show_aggregated =
      ["Weighted", "Weighted Summary"].indexOf(report_type) !== -1;
    return (
      <React.Fragment>
        <div className="selector">
          <KendoDropDown
            data={this.reports}
            name="report_type"
            value={report_type}
            onChange={this.changeReport}
          />
        </div>
        {show_table_selector && (
          <div className="selector">
            <KendoDropDown
              data={this.tables}
              name="table_name"
              value={table_name}
              onChange={this.changeReport}
            />
          </div>
        )}
        {show_table_selector && show_nested_selector && (
          <div className="selector">
            <KendoDropDown
              data={[...nested_options, "Top-Level"]}
              name="nested_table_name"
              value={nested_table_name}
              onChange={this.changeReport}
            />
          </div>
        )}
        {show_aggregated && (
          <div className="selector">
            <KendoDropDown
              data={this.modes}
              name="mode"
              value={mode}
              onChange={this.changeReport}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  renderStressSelectors = () => {
    const {
      report_type,
      table_name,
      scenario,
      nested_table_name,
      mode,
      stress_table,
    } = this.state;
    const { [table_name]: nested_options = [] } = this.nested;
    const is_summary = stress_table === "Stress Summary";
    const show_table_selector = this.flats.indexOf(report_type) === -1;
    const show_nested_selector = this.nested_names.indexOf(table_name) !== -1;
    const show_aggregated =
      ["Weighted", "Weighted Summary"].indexOf(report_type) !== -1;
    return (
      <React.Fragment>
        <div className="selector">
          <KendoDropDown
            data={this.stress_tables}
            name="stress_table"
            value={stress_table}
            onChange={this.changeReport}
          />
        </div>
        {!is_summary && (
          <React.Fragment>
            <div className="selector">
              <KendoDropDown
                data={this.report_types_stress}
                name="report_type"
                value={report_type}
                onChange={this.changeReport}
              />
            </div>
            <div className="selector">
              <KendoDropDown
                data={this.scenarios}
                name="scenario"
                value={scenario}
                onChange={this.changeReport}
              />
            </div>
            {show_table_selector && (
              <div className="selector">
                <KendoDropDown
                  data={this.tables}
                  name="table_name"
                  value={table_name}
                  onChange={this.changeReport}
                />
              </div>
            )}
            {show_table_selector && show_nested_selector && (
              <div className="selector">
                <KendoDropDown
                  data={[...nested_options, "Top-Level"]}
                  name="nested_table_name"
                  value={nested_table_name}
                  onChange={this.changeReport}
                />
              </div>
            )}
            {show_aggregated && (
              <div className="selector">
                <KendoDropDown
                  data={this.modes}
                  name="mode"
                  value={mode}
                  onChange={this.changeReport}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { stress } = this.props;
    const data = stress ? this.getStressData() : this.getData();
    const { table_name, mode, stress_table, report_type } = this.state;
    const is_stress_summary = !!(stress && stress_table === "Stress Summary");
    const summary =
      ["Unweighted Summary", "Weighted Summary"].indexOf(report_type) !== -1;
    const name_is_index = !!(summary || is_stress_summary);
    const aggregated = mode === "Aggregated";
    return (
      <div className="exposure-report">
        <div className="exposure-table">
          <PortfolioTable
            data={data}
            index_col={name_is_index ? "index" : "security_name"}
            aggregated={aggregated}
            table_name={table_name}
          >
            <GridToolbar className="selectors">
              <div className="toolbar">
                {stress
                  ? this.renderStressSelectors()
                  : this.renderExposureSelectors()}
              </div>
            </GridToolbar>
          </PortfolioTable>
        </div>
      </div>
    );
  }
}
