import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMixpanel } from './useMixpanel';
import useAuth from './useAuth';

const useMixpanelPageTrack = () => {
  const history = useHistory();
  const { mpTrack } = useMixpanel();
  const { isAuthorized } = useAuth();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (isAuthorized) {
        mpTrack({
          eventName: 'Enter Page',
          properties: {
            Hash: location?.hash,
            Pathname: location?.pathname,
            Search: location?.search,
            Type: 'Enter Page',
          },
        });
      }
    });
    return () => {
      unlisten();
    };
  }, [isAuthorized]);
};

export default useMixpanelPageTrack;
