import React, { useState, useEffect, useContext } from "react"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import { Input } from "@progress/kendo-react-inputs"
import QRCode from "qrcode.react"
import {
  getOtpDevice,
  getOtpEmergencyTokens,
  otpCreateDevice,
  otpConfirm,
  deleteOtpDevice,
} from "../../services"
import { AuthContext } from "../../contexts"
import { toast } from "react-toastify"

export default function Security() {
  const { logout } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [otpDevice, setOtpDevice] = useState(null)
  const [newQr, setNewQr] = useState(null)
  const [passcode, setPasscode] = useState("")
  const [emergencyTokens, setEmergencyTokens] = useState([])

  const fetchDevice = async () => {
    setLoading(true)
    try {
      const response = await getOtpDevice()
      setOtpDevice(response.data)
    } catch (error) { }
    setLoading(false)
  }

  const createQr = async () => {
    try {
      const response = await otpCreateDevice()
      setNewQr(response.data)
    } catch (error) { }
  }

  const confirmDevice = async () => {
    try {
      await otpConfirm({ passcode })
      setOtpDevice(true)
      logout()
      toast.success("You have enabled two-factor authentication successfully. Please login again to continue.")
    } catch (error) { }
  }

  const deleteDevice = async () => {
    try {
      await deleteOtpDevice()
      setOtpDevice(false)
    } catch (error) { }
  }

  const getTokens = async () => {
    const response = await getOtpEmergencyTokens()
    setEmergencyTokens(response.data)
  }

  useEffect(() => {
    fetchDevice()
  }, [])

  return (
    <div className="security">
      {otpDevice && (
        <>
          <div className="delete-device-form">
            <Typography
              align="center"
              variant="subtitle1"
            >
              You have enabled two-factor authentication. If you wish to disable
              it, please delete the linked device
            </Typography>
            <Button
              onClick={deleteDevice}
              variant="outlined"
            >
              Disable two-factor authentication
            </Button>
          </div>
          <div className="token-list">
            <Typography
              align="center"
              variant="h6"
            >
              Emergency Tokens
            </Typography>
            <Typography
              align="center"
              variant="subtitle1"
            >
              In case you lose access to your device, you can use emergency
              tokens to restore access to your account.
            </Typography>
            <Button
              onClick={getTokens}
              variant="outlined"
            >
              Request emergency tokens
            </Button>
            <div>
              <List>
                {emergencyTokens.map((token, idx) => (
                  <ListItem
                    button
                    dense
                    key={idx}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={false}
                        disableRipple
                        edge="start"
                        inputProps={{ "aria-labelledby": token }}
                        tabIndex={-1}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={token}
                      primary={token}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </>
      )}

      {!otpDevice && !loading && (
        <>
          <Typography align="center">
            You do not have any linked devices
          </Typography>
          <Button
            className="new-device-btn"
            onClick={createQr}
            variant="outlined"
          >
            Link New Device
          </Button>
          {newQr && (
            <>
              <Typography align="center">
                Use Authenticator app to scan the code below
              </Typography>
              <div className="qr-code">
                <QRCode value={newQr} />
              </div>

              <Input
                onChange={({ target }) => setPasscode(target.value)}
                placeholder="Passcode"
                style={{ width: "100%" }}
              />
              <Button
                className="new-device-btn"
                color="primary"
                onClick={confirmDevice}
                variant="contained"
              >
                Confirm device
              </Button>
            </>
          )}
        </>
      )}
    </div>
  )
}
