import React from "react";
import { StatisticalAnalysis } from "../statistics";

export default function Analysis({ security_details }) {
  return (
    <div className="asset-analysis-container">
      <StatisticalAnalysis
        formatNarrow
        fixedSecurity={security_details}
        hideFund
        noPortfolioAnalysis
      />
    </div>
  );
}
