import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useMemo, useState } from 'react'
import { FieldValues, UseFieldArrayReturn } from 'react-hook-form'

import { useSecurities } from '../../hooks'
import { useMixpanel } from '../../hooks/useMixpanel'
import { convertValueToPercentage } from '../../utils/numberFormatConvertors'
import { defaultAssetValues } from './form-default-values'
import { useQuery } from '@tanstack/react-query'
import { getPortfolioList } from '../../services'

interface PortfolioImportButtonProps {
  assetsArray: UseFieldArrayReturn<FieldValues, "assets", "id">
  watchedAssets: any[]
}

const filterLatestDateWeights = (securityWeights: any[]) => {
  if (securityWeights.length === 0) {
    return []
  }

  // Parse the dates and find the latest one
  const latestDate = securityWeights.reduce((latest, entry) => {
    const currentDate = new Date(entry.weight_date)
    return currentDate > latest ? currentDate : latest
  }, new Date(securityWeights[0].weight_date))

  // Filter the securityWeights to only include entries with the latest date
  return securityWeights.filter(entry => new Date(entry.weight_date).getTime() === latestDate.getTime())
}

const PortfolioImportButton: React.FC<PortfolioImportButtonProps> = ({
  assetsArray,
  watchedAssets = []
}) => {
  const { mpTrack } = useMixpanel()
  const { search_list_map: searchListMap, webfolio_list: webfolios } = useSecurities()
  const { data: portfolios } = useQuery(['porfolio-list'], () => getPortfolioList({ 'selected-fields': 'portfolio_weights' }), { select: (res) => res.data })
  const options = useMemo(() => [...(portfolios || []), ...webfolios], [portfolios, webfolios])
  const [value, setValue] = useState()
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)

  const openDialog = () => setOpen(true)
  const closeDialog = () => setOpen(false)
  const inputChangeHandler = (event, newInputValue) => setInputValue(newInputValue)
  const changeValueHandler = (event: any, newValue: string | null) => setValue(newValue)

  const clickConfirmButtonHandler = () => {
    const filtered = filterLatestDateWeights(value.portfolio_weights)
    const portfolioName = value.name
    const portfolioId = value.id
    let existingAssets = [...watchedAssets]

    const importedAssets = filtered.map((asset) => ({
      ...defaultAssetValues,
      name: {
        name: searchListMap[asset.security]?.name,
        type: searchListMap[asset.security]?.type
      },
      suggestedValue: convertValueToPercentage(asset.weight)
    }))

    const lastAppenedAssetIdx = watchedAssets.length - 1
    const lastAppendedAsset = watchedAssets[lastAppenedAssetIdx]

    if (lastAppendedAsset && (JSON.stringify(lastAppendedAsset) === JSON.stringify(defaultAssetValues))) {
      assetsArray.remove(lastAppenedAssetIdx)
      existingAssets = existingAssets.slice(0, lastAppenedAssetIdx)
    }

    const allAssets = [...existingAssets, ...importedAssets]

    assetsArray.remove()
    allAssets.forEach((asset) => assetsArray.append(asset))

    closeDialog()
    setValue(null)

    mpTrack({
      eventName: 'Import Portfolio to Optimiser',
      properties: {
        Portfolio: portfolioName,
        'Portfolio ID': portfolioId,
      }
    })
  }

  return (
    <>
      <Button
        color="primary"
        onClick={openDialog}
        variant="contained"
      >
        Import Portfolio
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={closeDialog}
        open={open}
      >
        <DialogTitle>Select Portfolio</DialogTitle>
        <DialogContent>
          <Autocomplete
            getOptionLabel={(option) => option.name}
            inputValue={inputValue}
            onChange={changeValueHandler}
            onInputChange={inputChangeHandler}
            options={options}
            renderInput={(params) => <TextField
              {...params}
              helperText={value?.portfolio_weights?.length === 0 && 'No securities in portfolio. Please select another.'}
              placeholder="Search for a portfolio"
              variant="outlined"
            />}
            value={value}
          />
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            gridGap={12}
            mr={2}
          >
            <Button
              color="primary"
              onClick={closeDialog}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={!value || (value?.portfolio_weights?.length < 1)}
              onClick={clickConfirmButtonHandler}
              variant="contained"
            >
              Import
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}


export default PortfolioImportButton