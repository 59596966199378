export const diagnostics_list = {
  tracking_error: [
    { field: "start_date", title: "Start Date" },
    { field: "finish_date", title: "Finish Date" },
    { field: "tracking_error", title: "Tracking Error" },
  ],
  total_expense_ratio: [
    { field: "start_date", title: "Start Date" },
    { field: "finish_date", title: "Finish Date" },
    { field: "expense_ratio", title: "Total Expense Ratio" },
  ],
};

export const total_expense_button_list = [
  { id: "unsort", title: "Unsort" },
  { id: "remove_filter", title: "Remove Filter" },
];

export const single_total_expense = {
  cell: "BHM - Class X",
  fund: "Brevan Howard Fund",
  start_date: "20/02/2020",
  finish_date: "20/04/2020",
  expense_ratio: "0.9%",
};

export let total_expense_data = [];
for (let i = 0; i < 150; i++) {
  total_expense_data.push({ ...single_total_expense, cell_id: i });
}

export const single_tracking_error = {
  fund: "Brevan Howard Fund",
  cell: "BHM - Class X",
  start_date: "14/09/2019",
  finish_date: "14/11/2019",
  tracking_error: "6.02%",
};

export let tracking_error = [];
for (let i = 0; i < 150; i++) {
  tracking_error.push({ ...single_tracking_error, cell_id: i });
}
