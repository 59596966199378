import { Button, Grid, MenuItem, TextField } from '@material-ui/core'
import React from 'react'

import StatusIndicator from '../../components/misc/StatusIndicator'
import useOptimiserConstants from './hooks/useOptimiserConstants'
import { RUN_STATUS_COLOR_MAP, RUN_STATUS_OPTIONS } from './optimiser.constants'

export const defaultOptimiserRunFilter = {
  objective_function: '',
  status: '',
  tag: '',
  visibility_type: '',
}

const PortOptListFilter = ({
  filter,
  setFilter,
  tagOptions,
}) => {
  const { objFuncOptions, visibilityMenuItems } = useOptimiserConstants()

  const changeFilterHandler = (filterKey) => (event) => {
    setFilter({
      ...filter,
      [filterKey]: event.target.value,
    })
  }

  const clickResetButton = () => {
    setFilter(defaultOptimiserRunFilter)
  }

  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        item
        xs={2}
      >
        <TextField
          SelectProps={{
            displayEmpty: true,
          }}
          fullWidth
          onChange={changeFilterHandler('status')}
          select
          value={filter.status}
        >
          <MenuItem
            style={{ color: 'lightgray' }}
            value=""
          >
            Status
          </MenuItem>
          {
            RUN_STATUS_OPTIONS?.map((it) => (
              <MenuItem
                key={it}
                value={it}
              >
                <StatusIndicator color={RUN_STATUS_COLOR_MAP[it]} />
                {it}
              </MenuItem>
            ))
          }
        </TextField>
      </Grid>
      <Grid
        item
        xs={3}
      >
        <TextField
          SelectProps={{
            displayEmpty: true,
          }}
          fullWidth
          onChange={changeFilterHandler('objective_function')}
          select
          value={filter.objective_function}
        >
          <MenuItem
            style={{ color: 'lightgray' }}
            value=""
          >
            Objective Function
          </MenuItem>
          {
            objFuncOptions?.map(({ label, value }) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            ))
          }
        </TextField>
      </Grid>
      <Grid
        item
        xs={2}
      >
        <TextField
          SelectProps={{
            displayEmpty: true,
          }}
          fullWidth
          onChange={changeFilterHandler('visibility_type')}
          select
          value={filter.visibility_type}
        >
          <MenuItem
            style={{ color: 'lightgray' }}
            value=""
          >
            Visibility
          </MenuItem>
          {visibilityMenuItems}
        </TextField>
      </Grid>
      <Grid
        item
        xs={2}
      >
        <TextField
          SelectProps={{
            displayEmpty: true,
          }}
          fullWidth
          onChange={changeFilterHandler('tag')}
          select
          value={filter.tag}
        >
          <MenuItem
            style={{ color: 'lightgray' }}
            value=""
          >
            Tag
          </MenuItem>
          {
            tagOptions?.map((tag) => (
              <MenuItem
                key={tag}
                value={tag}
              >
                {tag}
              </MenuItem>
            ))
          }
        </TextField>
      </Grid>
      <Grid
        item
        xs={2}
      >
        <Button
          color="primary"
          onClick={clickResetButton}
          variant="outlined"
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  )
}

export default PortOptListFilter
