/* eslint-disable import/prefer-default-export */
import { useState, useEffect, useCallback } from 'react';
import mixpanel from 'mixpanel-browser';
import useAuth from './useAuth';

export const useMixpanel = (props) => {
  const auth = useAuth();
  const [trackedSet, setTrackedSet] = useState(new Set());
  const timeOnPageName = props?.timeOnPageName;

  const hasTracked = (id) => trackedSet.has(id);

  useEffect(() => {
    if (mixpanel && timeOnPageName) {
      mixpanel.time_event('Time On Page');
    }

    return () => {
      if (mixpanel && timeOnPageName) {
        mixpanel.track('Time On Page', {
          page: timeOnPageName,
        });
      }
    };
  }, [timeOnPageName]);

  const mpTrack = useCallback((args, hash) => {
    const properties = {
      ...(args.properties || {}),
      'Git Sha': process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
      'Git Commit Ref': process.env.REACT_APP_VERCEL_GIT_COMMIT_REF,
      'Vercel Url': process.env.REACT_APP_VERCEL_URL,
    };

    if (auth.isAuthorized) {
      properties.distinct_id = auth.profile.email;
    }

    if (hash) {
      if (trackedSet.has(hash)) {
        return;
      }
      setTrackedSet(new Set([...trackedSet, hash]));
    }

    mixpanel.track(args.eventName, properties, args.optionsOrCallback, args.callback);
  }, [auth, trackedSet]);

  return { mpTrack, hasTracked };
};
