import React from 'react';
import { AuthPageContainer, BannerContainer, FormContainer, Logo, LogoWrapper } from '../../containers/authorization/styles';
import { Typography } from '@material-ui/core';

interface AuthPageWrapperProps extends React.PropsWithChildren { }

const AuthPageWrapper: React.FC<AuthPageWrapperProps> = ({
  children
}) => (
  <AuthPageContainer>
    <BannerContainer>
      <Typography component="h1">
        Your <strong>gateway</strong> to <br /> <strong>alternative<br />investment<br />strategies</strong>
      </Typography>
    </BannerContainer>
    <FormContainer>
      <LogoWrapper>
        <Logo src="/logo_white.svg" />
      </LogoWrapper>
      {children}
    </FormContainer>
  </AuthPageContainer>
)

export default AuthPageWrapper
