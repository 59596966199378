const mapRelatedSecurities = (related_securities, search_list, data) => {
  let benchmarks = [],
    peers = [],
    factors = [],
    primary_benchmark = null;
  for (let i = 0; i < related_securities.length; i++) {
    const element = related_securities[i];
    const { map_type, mapped_security } = element || {};
    const getSecurity = (security) =>
      search_list.find((o) => o.id === security.id);
    switch (map_type.name) {
      case "benchmark":
        benchmarks.push(getSecurity(mapped_security));
        break;
      case "peer":
        peers.push(getSecurity(mapped_security));
        break;
      case "factor":
        factors.push(getSecurity(mapped_security));
        break;
      case "primary_benchmark":
        primary_benchmark = getSecurity(mapped_security);
        break;
      default:
        break;
    }
    data.benchmarks = benchmarks.clean();
    data.peers = peers.clean();
    data.factors = factors.clean();
    data.primary_benchmark = primary_benchmark;
  }
  return data;
};

export default mapRelatedSecurities;
