import { Box, Typography } from '@material-ui/core'
import React from 'react'

import { aamui } from '../../styles/AppTheme'
import { usePortfolioOptimiser } from './RefactoredPortfolioOptimiserProvider'

const PortOptErrorResultPane = () => {
  const { runData, submitFormError } = usePortfolioOptimiser()
  const errorData = submitFormError?.response?.data || runData?.result?.error
  const isErrorWithDetails = errorData?.type && errorData?.title && errorData?.traceback

  return errorData
    ? (
      <Box
        bgcolor="black"
        border={`1px solid ${aamui.redAccent}`}
        color="white"
        p={4}
      >
        <Typography
          component="p"
          style={{ fontFamily: 'monospace' }}
        >
          {isErrorWithDetails ? (
            <>
              {errorData.type}
              <br />
              <br />
              {errorData.title}
              <br />
              <br />
              {errorData.traceback.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </>
          ) : (
            JSON.stringify(errorData)
          )}
        </Typography>
      </Box>
    )
    : null
}

export default PortOptErrorResultPane
