import { Box, Card, CardContent, Typography } from '@material-ui/core'
import React from 'react'

const PeDisclaimers = ({
  summary,
  terms_legal: termsLegal
}) => (
  <Box
    display="flex"
    flexDirection="column"
    gridGap={20}
  >
    {
      summary && (
        <Card>
          <CardContent>
            <Typography
              className="asset-detail-subheader"
              variant="h6"
            >
              Description
            </Typography>
            {summary}
          </CardContent>
        </Card>
      )
    }
    {
      termsLegal?.disclaimer && (
        <Card>
          <CardContent>
            <Typography
              className="asset-detail-subheader"
              variant="h6"
            >
              Disclaimer
            </Typography>
            {termsLegal.disclaimer}
          </CardContent>
        </Card>
      )
    }
  </Box>
)

export default PeDisclaimers
