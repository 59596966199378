import { Combobox, Group, Text, TextInput, useCombobox } from '@mantine/core'
import { CloseButton } from '@mantine/core'
import { IconChevronDown } from '@tabler/icons-react'
import React, { useMemo, useRef, useState } from 'react'
import { GroupedVirtuoso } from 'react-virtuoso'

import { useSecurities } from '../../hooks'
import { filterOptions } from './GlobalFundSelector'

interface FundSelectProps {
  value: any // fund object
  disabled?: boolean
  onChange: (value: string | null) => void
}

const FundSelect: React.FC<FundSelectProps> = ({
  disabled,
  onChange,
  value
}) => {
  const ref = useRef()
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const [search, setSearch] = useState('')
  const { isLoadingSecurities, search_list: searchList, search_list_map: searchListMap } = useSecurities()

  const parsedGroupsMap = useMemo(() => {
    if (searchList && !isLoadingSecurities) {
      const groups: any = {}
      const keySet = new Set()
      searchList.forEach((it: any) => {
        if (!groups[it.type]) {
          groups[it.type] = []
        }
        if (!keySet.has(it.id)) {
          groups[it.type].push({
            label: it.name,
            value: String(it.id)
          })
        }
        keySet.add(it.id)
      })

      return groups
    }

    return {}
  }, [searchList, isLoadingSecurities])
  const filteredGroupOptions = filterOptions(parsedGroupsMap, search)
  const filteredOptions = Object.values(filteredGroupOptions).flat()
  const filteredGroups = Object.keys(filteredGroupOptions)
  const groupCounts = Object.values(filteredGroupOptions).map(items => items.length)

  const openDropdown = () => combobox.openDropdown()
  const closeDropdown = () => combobox.closeDropdown()

  const optionSubmitHandler = (fundId: string) => {
    onChange(searchListMap[fundId])
    setSearch(searchListMap[fundId]?.name || '')
    combobox.closeDropdown()
  }

  const onBlueHandler = () => {
    combobox.closeDropdown()
    const selectedFundId = value?.id

    if (selectedFundId) {
      setSearch(searchListMap[selectedFundId]?.name || '')
    } else {
      setSearch('')
    }
  }

  const clickClearButtonHandler = () => {
    setSearch('')
    onChange(null)
  }

  const clickDropDownButton = () => {
    closeDropdown()
    ref.current.focus()
  }

  return (
    <Combobox
      onOptionSubmit={optionSubmitHandler}
      store={combobox}
    >
      <Combobox.Target>
        <TextInput
          onBlur={onBlueHandler}
          onChange={(event) => {
            setSearch(event.currentTarget.value)
            openDropdown()
          }}
          onClick={openDropdown}
          onFocus={openDropdown}
          placeholder="Fund"
          ref={ref}
          rightSection={(
            <Group
              gap={0}
              justify="flex-end"
              mr="xs"
            >
              {
                value && <CloseButton onClick={clickClearButtonHandler} />
              }
              <IconChevronDown onClick={clickDropDownButton} />
            </Group>
          )}
          rightSectionWidth="70px"
          value={search}
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          <GroupedVirtuoso
            groupContent={(groupIdx) => (
              <Text
                bg="white"
                color="gray"
                key={groupIdx}
                px="sm"
                size="md"
              >
                <strong>
                  {filteredGroups[groupIdx]}
                </strong>
              </Text>
            )}
            groupCounts={groupCounts}
            itemContent={(optionIdx) => (
              <Combobox.Option
                active={String(value?.id) === filteredOptions[optionIdx].value}
                key={optionIdx}
                px="sm"
                py={4}
                value={filteredOptions[optionIdx].value}
              >
                <Text size="md">
                  {filteredOptions[optionIdx]?.label}
                </Text>
              </Combobox.Option>
            )}
            style={{ height: 300 }}
          />
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default FundSelect
