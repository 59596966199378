import { InputAdornment, TextField } from '@material-ui/core'
import React from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'

interface FormTextFieldProps
  extends Pick<FieldValues, 'control' | 'name' | 'error' | 'type'> {
  label?: string
  errorMessage?: string
  endAdornment?: React.ReactNode
}

const FormTextField: React.FC<FormTextFieldProps> = ({
  control,
  endAdornment,
  error,
  errorMessage,
  label,
  name,
  type,
}) => {
  const { control: defaultFormControl } = useFormContext()
  return (
    <Controller
      control={defaultFormControl || control}
      name={name}
      render={({ field: { onChange, ref, value, ...restField } }) => (
        <TextField
          {...restField}
          InputProps={{
            endAdornment: endAdornment ? (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : null,
          }}
          error={!!errorMessage}
          fullWidth
          helperText={errorMessage}
          inputRef={ref}
          label={label}
          onChange={(event) => {
            const inputValue = event.target.value
            if (type === 'number' && (inputValue === '-' || inputValue === '')) {
              onChange(inputValue)
              return
            }

            onChange(
              type == 'number'
                ? parseFloat(parseFloat(inputValue).toFixed(2))
                : inputValue,
            )
          }}
          type={type}
          value={
            type === 'number' ? (value === '-' || value === '' ? value : parseFloat(parseFloat(value).toFixed(2))) : value
          }
        />
      )}
    />
  )
}

export default FormTextField
