import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Info, Security } from "../userProfile";

export default function UserProfile() {
  const [currentView, setCurrentView] = useState("main");
  const changeView = (e, view) => {
    setCurrentView(view);
  };
  return (
    <div className="user-profile">
      <Tabs
        value={currentView}
        onChange={changeView}
        aria-label="simple tabs example"
        indicatorColor="primary"
      >
        <Tab label="Profile" value="main" />
        <Tab label="Two-Factor Auth" value="security" />
      </Tabs>

      {currentView === "main" && <Info />}
      {currentView === "security" && <Security />}
    </div>
  );
}
