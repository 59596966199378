import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { toast } from 'react-toastify'

import LogoutScreen from '../components/misc/LogoutScreen'
import { hostname } from '../constants'
import { IS_DEV_ENV } from '../constants/env'
import { accessRightKeyMap } from '../constants/permissions'
import { getMe } from '../services'
import { logout as apiLogout } from '../services/auth'
import { hideLoader, showLoader } from '../utils/appLoaderControl'
import { fetcher } from '../utils/fetcher'

const defaultProfile = {
  permission_list: [],
}

const AuthContext = createContext({
  isAuthorized: false,
  profile: defaultProfile,
})

const AuthContextProvider = (props) => {
  const queryClient = useQueryClient()
  const [authenticating, setAuthenticating] = useState(false)
  const [authorizing, setAuthorizing] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [profile, setProfile] = useState(defaultProfile)
  const [joyrideOpen, setJoyrideOpen] = useState(false)
  const [gaAuth, setGaAuth] = useState(false)
  const [loggingOut, setLoggingOut] = useState(false)
  const accessRights = useMemo(() => {
    const permissionListSet = new Set(profile.permission_list)
    const acsRights = Object.entries(accessRightKeyMap).reduce(
      (obj, [key, value]) => ({
        ...obj,
        [key]: permissionListSet.has(value),
      }),
      {},
    )

    acsRights.hasPrivateEquityAccess =
      profile?.groups?.includes('Private Equity')
    acsRights.hasHedgeFundsAccess = profile?.groups?.some(
      (it) => it !== 'Private Equity',
    )
    return acsRights
  }, [profile.permission_list, profile.groups])

  const logout = useCallback(async (args) => {
    try {
      setLoggingOut(true)

      if (!args?.skipApiLogout) {
        await apiLogout()
      }
    } finally {
      setIsLoggedIn(false)
      setIsAuthorized(false)
      setProfile(defaultProfile)
      localStorage.clear()
      queryClient.clear()
      setLoggingOut(false)
    }
  }, [])

  useEffect(() => {
    fetcher.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('accessToken')
        if (token) {
          config.headers = { ...config.headers, Authorization: `JWT ${token}` }
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    fetcher.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config

        if (error.response?.status === 400) {
          toast.error('An error occurred. Please try again later.', {
            toastId: '400-error',
          })
          return Promise.reject(error)
        }

        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true

          const refreshToken = localStorage.getItem('refreshToken')
          if (!refreshToken) {
            toast.error('Authentication error. Please log in again.', {
              toastId: 'auth-error',
            })
            localStorage.clear()
            logout({ skipApiLogout: true })
            return Promise.reject(error)
          }

          try {
            const { data } = await axios.post(
              `${hostname}/auth/token/refresh/`,
              { refresh: refreshToken },
            )
            localStorage.setItem('accessToken', data.access)
            originalRequest.headers.Authorization = 'JWT ' + data.access
            return axios(originalRequest)
          } catch (refreshError) {
            toast.error('Failed to refresh token. Please log in again.', {
              toastId: 'refresh-error',
            })
            localStorage.clear()
            logout({ skipApiLogout: true })
            return Promise.reject(refreshError)
          }
        }

        return Promise.reject(error)
      },
    )
  }, [localStorage, logout])

  useEffect(() => {
    const tryAuthorize = async () => {
      try {
        showLoader()
        setAuthenticating(true)
        const meResponse = await getMe()
        setProfile(meResponse.data)
        setIsAuthorized(true)

        if (IS_DEV_ENV) {
          queryClient.setQueryData(['user profile'], meResponse.data)
        }
      } finally {
        setAuthenticating(false)
        hideLoader()
      }
    }

    if (localStorage.getItem('accessToken')) {
      tryAuthorize()
    } else {
      setAuthenticating(false)
      hideLoader()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        accessRights,
        authenticating,
        authorizing,
        gaAuth,
        isAuthorized,
        isLoggedIn,
        joyrideOpen,
        logout,
        profile,
        setAuthenticating,
        setAuthorizing,
        setGaAuth,
        setIsAuthorized,
        setIsLoggedIn,
        setJoyrideOpen,
        setProfile,
      }}
    >
      {props.children}
      {loggingOut && <LogoutScreen />}
    </AuthContext.Provider>
  )
}

export { AuthContextProvider, AuthContext }
