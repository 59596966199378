import { getSecurityDetails as getDetails } from '../funds'
import { mapRelatedSecuritiesUtil } from '../../utils'

export async function getSecurityDetails(payload, searchList) {
  const response = await getDetails(payload)
  let { data } = response
  const { underlying_fund, related_securities } = data
  if (underlying_fund) {
    data.aaap_summary = data.summary
    data.summary = underlying_fund.summary
    data.underlying_management_fee = underlying_fund.management_fee
    data.underlying_performance_fee = underlying_fund.performance_fee
    data.underlying_name = underlying_fund.name
    data.underlying_aum = underlying_fund.latest_aum
    data.underlying_principal_name = underlying_fund.principal_name
  }
  let mapped_data
  if (related_securities) {
    mapped_data = mapRelatedSecuritiesUtil(related_securities, searchList, data)
  }
  const result = mapped_data || data
  return result
}
