import React from 'react'
import { ReportInputs, ChartReport, TableReport, AumReport } from '.'
import LinearProgress from '@material-ui/core/LinearProgress'

const Report = ({ exposure_analysis, ...props }) => {
  const { report_type } = exposure_analysis || {}
  const allProps = { exposure_analysis, ...props }

  switch (report_type) {
    case 'charts':
      return <ChartReport {...allProps} />
    case 'tables':
      return <TableReport {...allProps} />
    case 'aum':
      return <AumReport {...allProps} />
    default:
      return <div />
  }
}

export default function RepotContainer({ loading, loaded, ...props }) {
  return (
    <div className="exposure-analysis-route">
      <ReportInputs {...{ ...props, loading }} />
      {loading && (
        <div className="loading-indicator">
          <LinearProgress />
        </div>
      )}

      {loaded && (
        <div className="report-body">
          <Report {...props} />
        </div>
      )}
    </div>
  )
}
