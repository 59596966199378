import { Card } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import clsx from 'clsx'
import { useMixpanel } from '../../hooks/useMixpanel'
import { useRouteType } from '../../hooks/useIsPeRoutes'

const InsightPreviewCard = ({
  isLegacy,
  listView,
  ...props
}) => {
  const routeType = useRouteType()
  const { mpTrack } = useMixpanel()
  const {
    path,
    thumbnail,
    date,
    name,
    description,
  } = isLegacy
      ? {
        path: `/insights/${props.id}?o=t`,
        thumbnail: props.cover_image,
        date: props.as_of_date,
        name: props.name,
        description: props.description,
      }
      : {
        path: `/insights/${props.id}`,
        thumbnail: props.featuredImage,
        date: props.publishDate,
        name: props.name,
        description: props?.widgets?.text?.body?.value,
      }

  const clickCardHandler = () => {
    mpTrack({
      eventName: `Click Insights Card`,
      properties: {
        Type: 'Click Insights Card',
        'Insight Name': name,
        'Publish Date': moment(date).format("MMM Do YYYY"),
        'Route Type': routeType,
      }
    })
  }

  return (
    <Link
      className={clsx('insights-card-link', { 'insights-card-link-homepage': listView })}
      to={path}
      onClick={clickCardHandler}
    >
      <Card>
        <div className="insights-piece">
          <div className="card-image">
            <img src={thumbnail} alt="" />
          </div>
          <div className="insights-content-wrapper">
            <p className="card-date">
              {moment(date).format("MMM Do YYYY")}
            </p>
            <h3 className="card-title">
              {name}
            </h3>
            <p className="card-description">{description}</p>
          </div>
        </div>
      </Card>
    </Link>
  )
}

export default InsightPreviewCard
