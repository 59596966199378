/* eslint-disable sort-keys/sort-keys-fix */
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import React, { useState } from "react"

import { BookletManagement, IrDocuments, Unlocking, Watermarking } from "../investorRelations"
import DocumentsExport from "../investorRelations/DocumentsExport"

const ViewComponents = {
  'Watermarking': Watermarking,
  'Unlocking': Unlocking,
  'Booklets Management': BookletManagement,
  'Documents': IrDocuments,
  'Documents Export': DocumentsExport
}

const tabs = Object.keys(ViewComponents)

const View = ({ current_view, ...props }) => {
  const Component = ViewComponents[current_view] || (() => <div />)
  return <Component {...props} />
}

export default function InvestorRelations() {
  const [currentView, setCurrentView] = useState(tabs[0])
  return (
    <div className="investor-relations-route">
      <Tabs
        indicatorColor="primary"
        onChange={(e, view) => setCurrentView(view)}
        value={currentView}
      >
        {
          tabs.map((tab, i) => (
            <Tab
              key={i}
              label={tab}
              value={tab}
            />
          ))
        }
      </Tabs>
      <View current_view={currentView} />
    </div>
  )
}
