import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useDebouncedCallback } from "use-debounce";

const drawerWidth = 280;

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      marginTop: "60px",
      paddingBottom: "60px",
      transition: theme.transitions.create("width", {
        easing: "linear",
        duration: 150,
      }),
      '& .MuiListItemText-root': {
        opacity: `1 !important`,
      }
    },
    drawerClose: {
      marginTop: "60px",
      paddingBottom: "60px",
      transition: theme.transitions.create("width", {
        easing: "linear",
        duration: 100,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
      '& .MuiListItemText-root': {
        opacity: `0`,
      }
    },
  })
)

const PersistentDrawer = ({
  toggleDrawer,
  open,
  children
}) => {
  const classes = useStyles()

  const openDrawerDebounce = useDebouncedCallback(
    () => toggleDrawer(true),
    250
  )

  const onMouseEnter = () => {
    openDrawerDebounce();
  }

  const onMouseLeave = () => {
    openDrawerDebounce.cancel()
    toggleDrawer(false)
  }

  return (
    <Drawer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      variant="permanent"
      className={clsx(
        classes.drawer,
        {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }
      )}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {children}
    </Drawer>
  );
}

export default PersistentDrawer
