import moment from "moment";

export function dbToJs(dateAsString) {
  if (dateAsString) {
    return moment(dateAsString).toDate();
  }
  return null;
}

export function dbToFormat(dateAsString) {
  if (dateAsString) {
    return moment(dateAsString).format("DD MMM");
  }
  return null;
}

export function dbToLongFormat(dateAsString) {
  if (dateAsString) {
    return moment(dateAsString).format("DD MMM YYYY");
  }
  return null;
}

export function dbToMonthYear(dateAsString) {
  if (dateAsString) {
    return moment(dateAsString).format("MMM-YY");
  }
  return null;
}

export function jsToDb(dateAsDate) {
  if (dateAsDate) {
    return moment(dateAsDate).endOf("month").startOf("D").format("YYYY-MM-DD");
  }
  return null;
}

export function jsToDbStart(dateAsDate) {
  if (dateAsDate) {
    return moment(dateAsDate).startOf("month").format("YYYY-MM-DD");
  }
  return null;
}

export function jsToDateTimeDb(dateAsDate) {
  if (dateAsDate) {
    return moment(dateAsDate).format("YYYY-MM-DD");
  }
  return null;
}

export function toMonthEnd(dateAsString) {
  if (dateAsString) {
    return moment(dateAsString).endOf("M").startOf("D").toDate();
  }
  return null;
}

export function previousMonthEnd() {
  return moment().add(-1, "M").endOf("M").startOf("D").toDate();
}

export function defaultEndDate() {
  return moment().endOf("M").startOf("D").toDate();
}

export function today() {
  return moment().toDate();
}

export function defaultStartDate() {
  return moment().endOf("Y").startOf("D").add(-30, "Y").toDate();
}

export function defaultAaapDate() {
  return moment().endOf("M").startOf("D").add(-2, "M").toDate();
}

export function fiveMonthsAgo() {
  return moment().endOf("M").startOf("D").add(-5, "M").toDate();
}

export function toTime(date) {
  return moment(date).format("HH:MM");
}

export function endOfLastWeek() {
  return moment()
    .endOf("W")
    .add(-1, "week")
    .add(-2, "day")
    .startOf("D")
    .toDate();
}
