import React, { Component } from "react";
import { statefulTableGenerator } from "../../components/tables";
import {
  Grid as KGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { GridDetailRow } from "@progress/kendo-react-grid";
import parse from "html-react-parser";
import { contact_list } from "../../data/funds";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import EditDialog from "./contactEditDialog";
// import update from 'immutability-helper';

const TableGrid = statefulTableGenerator(KGrid);

class ContactDetailComponent extends GridDetailRow {
  render() {
    const { dataItem } = this.props;
    const { contact_pm_bio = "" } = dataItem || {};
    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography align={"center"}>Biography</Typography>
          </Grid>
          <Grid item xs={12}>
            {parse(contact_pm_bio || "")}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export class contactsTable extends Component {
  state = {
    edit_form_open: false,
    current_contact: null,
  };
  addNewContact = () => {
    const { security_details } = this.props;
    const { id: hedge_fund } = security_details;
    let new_contact = {
      id: null,
      contact_name: "",
      contact_phone: "",
      contact_email: "",
      contact_address: "",
      contact_position: "",
      contact_is_pm: false,
      contact_pm_bio: "",
      hedge_fund,
    };
    this.setState({ current_contact: new_contact, edit_form_open: true });
  };

  onEnterEdit = (dataItem) => {
    this.setState({ current_contact: dataItem, edit_form_open: true });
  };
  cancelEdit = () => {
    this.setState({ current_contact: null, edit_form_open: false });
  };

  onRemoveContact = () => {
    const { removeContact } = this.props;
    const { current_contact } = this.state;
    removeContact(current_contact);
    this.setState({ current_contact: null, edit_form_open: false });
  };

  saveEdit = () => {
    const { current_contact } = this.state;
    const { updateContacts } = this.props;
    updateContacts(current_contact);
    this.setState({ current_contact: null, edit_form_open: false });
  };

  expandChange = (event) => {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };

  updateDetails = (e) => {
    const { value, name } = e.target;
    const { current_contact } = this.state;
    const update_contact = { ...current_contact, [name]: value };
    this.setState({ current_contact: update_contact });
  };

  render() {
    const { edit_form_open } = this.state;
    const { security_details, access_internal } = this.props;
    const { contacts } = security_details;
    return (
      <React.Fragment>
        <div className="contacts-table-container">
          {access_internal && (
            <div className="button-container">
              <Button
                onClick={this.addNewContact}
                variant="contained"
                color="primary"
              >
                Add Contact
              </Button>
            </div>
          )}
          <TableGrid
            className="contact-table"
            filterable={false}
            sortable={false}
            pageable={false}
            scrollable="none"
            detail={ContactDetailComponent}
            expandField="expanded"
            onExpandChange={this.expandChange}
            data={contacts || []}
          >
            {contact_list.map((col, idx) => (
              <Column key={idx} field={col.field} title={col.title} />
            ))}
            {access_internal && (
              <Column
                field="id"
                title={"Edit"}
                sortable={false}
                cell={(props) => (
                  <td style={{ textAlign: "center" }}>
                    <Button
                      onClick={() => {
                        this.onEnterEdit(props.dataItem);
                      }}
                      variant={"outlined"}
                      color={"secondary"}
                    >
                      Edit
                    </Button>
                  </td>
                )}
              />
            )}
          </TableGrid>
          {edit_form_open && (
            <EditDialog
              {...this.state}
              cancelEdit={this.cancelEdit}
              updateDetails={this.updateDetails}
              saveEdit={this.saveEdit}
              removeContact={this.onRemoveContact}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default contactsTable;
