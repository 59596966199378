import React, { useContext } from "react";
import { Formik } from "formik";
import { StyledForm } from "./styles";
import { SubmitButton } from "./styles";
import { TextInput as Input } from "../../components/forms";
import { getMe, otpEmergency } from "../../services";
import LocalStorageService from "../../utils/localStorageService";
import { AuthContext } from "../../contexts";
import Button from "@material-ui/core/Button";

export default function EmergencyTokenForm({ setView }) {
  const localStorageService = LocalStorageService.getService();

  const {
    authorizing,
    setAuthorizing,
    setIsAuthorized,
    setProfile,
  } = useContext(AuthContext);

  const authroize = async (data) => {
    setAuthorizing(true);
    const { emergency_code } = data || {};
    try {
      const response = await otpEmergency({ emergency_code });
      const { token } = response.data || {};
      localStorageService.setConfirmedToken(token);
    } catch (error) {
      setAuthorizing(false);
      return;
    }
    try {
      const meResponse = await getMe();
      setIsAuthorized(true);
      setProfile(meResponse.data);
      setAuthorizing(false);
    } catch (error) {
      setAuthorizing(false);
    }
  };

  return (
    <Formik
      initialValues={{
        emergency_code: "",
      }}
      onSubmit={authroize}
    >
      {() => (
        <StyledForm>
          <Input
            name="emergency_code"
            placeholder="Emergency Code"
            className="item"
          />
          <SubmitButton type="submit">
            {authorizing ? "Authenticating..." : "Confirm"}
          </SubmitButton>
          <Button
            variant="outlined"
            className="item"
            onClick={() => setView("default")}
          >
            Back to log-in
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
}
