import { useIsFetching, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import useParamsQuery from '../../../hooks/useParamsQuery'
import { getPortfolioOptimiserRun } from '../../../services'

const useOptimiserRunData = (intialFetch = false) => {
  const { runId } = useParams()
  const params = useParamsQuery()
  const queryRunId = params.get('runId')
  const queryOrParamRunId = runId || queryRunId
  const { data } = useQuery(
    ['optimiser run', queryOrParamRunId],
    () => getPortfolioOptimiserRun(queryOrParamRunId),
    {
      enabled: intialFetch && !!queryOrParamRunId,
      select: (res) => res?.data,
    },
  )
  const runDataResult = data?.result
  const hasFailedError = runDataResult && (runDataResult.status !== 200 || runDataResult.converged === false)
  const isFetchingRunData = useIsFetching(['optimiser run', queryOrParamRunId])

  return {
    hasFailedError,
    isFetchingRunData,
    runData: data,
  }
}

export default useOptimiserRunData
