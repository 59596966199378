import React from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { Chip, Group, Pill } from '@mantine/core'
import { ScreenerView, screenerViewSelected } from '../../redux/peerGroupsSlice'
import { screenerColDef } from './PeerGroupFundScreenerColsDef';

const viewColsVisibilityMap = Object.values(ScreenerView).reduce((acc, view) => {
  acc[view] = screenerColDef
    .filter(def => !def.views.includes(view) && def.id !== 'select' && view !== ScreenerView.All)
    .map(def => def.id);
  return acc;
}, {} as Record<ScreenerView, string[]>);

const PeerGroupScreenerViewSelector = () => {
  const dispatch = useAppDispatch()
  const screenerView = useAppSelector(state => state.peerGroups.screenerView)

  return (
    <Group gap={0}>
      {
        Object.entries(ScreenerView).map(([key, view]) => (
          <Pill
            key={key}
            style={{
              backgroundColor: screenerView === view ? 'var(--mantine-color-sapphire)' : 'var(--mantine-color-grey)',
              color: screenerView === view ? 'var(--mantine-color-white)' : 'var(--mantine-color-grey)',
              cursor: 'pointer'
            }}
            onClick={() => dispatch(screenerViewSelected({ view, colVisibility: viewColsVisibilityMap[view] }))}
          >
            {view}
          </Pill>
        ))
      }
    </Group>
  )
}

export default PeerGroupScreenerViewSelector
