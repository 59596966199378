import {
  Box,
  Button,
  Drawer,
  LoadingOverlay,
  Stack,
  Tabs
} from '@mantine/core'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import useParamsQuery from '../../hooks/useParamsQuery'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import {
  filteredByPeerGroupChanged,
  peerGroupDetailDimissed,
  peerGroupsFetched
} from '../../redux/peerGroupsSlice'
import { getPeerGroups } from '../../services/peer-groups'
import { convertSnakeToCamelCase } from '../../utils/convertSnakeToCamelCase'
import PeerGroupFundScreener from './PeerGroupFundScreener'
import PeerGroupFundScreenerFilter from './PeerGroupFundScreenerFilter'
import PeerGroupFundsTable from './PeerGroupFundsTable'
import PeerGroupInfoEdit from './PeerGroupInfoEdit'
import PeerGroupsTable from './PeerGroupsTable'

const PeerGroupPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const params = useParamsQuery()
  const selectedGroupId = useAppSelector(state => state.peerGroups.selectedGroupId)
  const hasSelectedPeerGroup = !!selectedGroupId
  const {
    data,
    error,
    isLoading
  } = useQuery(
    ['peer-groups'],
    () => getPeerGroups(),
    { select: (res) => res?.data }
  )
  const tabValue = params.get('tabValue')

  useEffect(() => {
    if (data) {
      dispatch(peerGroupsFetched(convertSnakeToCamelCase(data)))
    }
  }, [data])

  const clickTabButtonHandler = (value: string | null) => {
    if (value) {
      history.push(`/peer-groups?tabValue=${value}`)
    }
  }

  const dimissPeerGroupDrawer = () => dispatch(peerGroupDetailDimissed())

  const clickViewDetailHandler = () => {
    dispatch(filteredByPeerGroupChanged(String(selectedGroupId)))
    history.push('/peer-groups?tabValue=fundScreener')
  }

  return !isLoading ? (
    <Box>
      <Tabs
        onChange={clickTabButtonHandler}
        value={tabValue || 'peerGroup'}
      >
        <Tabs.List>
          <Tabs.Tab value="peerGroup">Peer Groups</Tabs.Tab>
          <Tabs.Tab value="fundScreener">Fund Screener</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="peerGroup">
          <PeerGroupsTable />
          <Drawer
            closeOnClickOutside={false}
            lockScroll={false}
            onClose={dimissPeerGroupDrawer}
            opened={hasSelectedPeerGroup && tabValue !== 'fundScreener'}
            position="right"
            shadow="lg"
            size="xl"
            withOverlay={false}
            zIndex={2000}
          >
            <Stack gap={20}>
              <PeerGroupInfoEdit />
              <Box>
                <Button onClick={clickViewDetailHandler}>View Details</Button>
              </Box>
              <PeerGroupFundsTable />
            </Stack>
          </Drawer>
        </Tabs.Panel>
        <Tabs.Panel value="fundScreener">
          <Stack gap="sm" style={{ overflow: 'hidden', height: 'calc(100vh - 196px)', margin: '0 -16px', padding: '0 16px' }}>
            <Box flex="1 0 0">
              <PeerGroupFundScreenerFilter />
            </Box>
            <Box h="100%">
              <PeerGroupFundScreener />
            </Box>
          </Stack>
        </Tabs.Panel>
      </Tabs>
    </Box>
  ) : (
    <LoadingOverlay
      overlayProps={{ backgroundOpacity: 1 }}
      visible
    />
  )
}

export default PeerGroupPage
