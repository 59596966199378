import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'

interface OptimiserPercentageSumLabelProps {
  arrayName: string
  targetKey: string
}

const OptimiserPercentageSumLabel: React.FC<OptimiserPercentageSumLabelProps> =
  ({ arrayName, targetKey }) => {
    const { watch } = useFormContext()
    const watchedFieldArray = watch(arrayName)

    const total = watchedFieldArray
      .reduce((acc, curr) => acc + parseFloat(curr[targetKey] || 0), 0)
      ?.toFixed(2)
    return (
      <Box pl={2.5}>
        <Typography style={{ color: 'gray' }} variant="body2">
          {total}%
        </Typography>
      </Box>
    )
  }

export default OptimiserPercentageSumLabel
