import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import SubRedIcon from '@material-ui/icons/Autorenew'
import BallotIcon from '@material-ui/icons/Ballot'
import TestPageIcon from '@material-ui/icons/BatteryCharging30'
// import FlowsIcon from "@material-ui/icons/ImportExport";
// import CashIcon from "@material-ui/icons/AccountBalanceWallet";
import DiagnosticIcon from '@material-ui/icons/Build'
import UserGroupIcon from '@material-ui/icons/Contacts'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import PortfolioAnalysisIcon from '@material-ui/icons/Explore'
import ScreenerIcon from '@material-ui/icons/FilterTiltShift'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import StatisticsIcon from '@material-ui/icons/Functions'
import EvaluationsIcon from '@material-ui/icons/Gavel'
import HomeIcon from '@material-ui/icons/Home'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import OperationsIcons from '@material-ui/icons/MonetizationOn'
import GroupIcon from '@material-ui/icons/People'
import UserIcon from '@material-ui/icons/PersonAdd'
import UserActivityIcon from '@material-ui/icons/PieChart'
import AnalysisIcon from '@material-ui/icons/Poll'
import DataManagementIcon from '@material-ui/icons/SettingsInputComponent'
import MonitorIcon from '@material-ui/icons/Speed'
import InvestorIcon from '@material-ui/icons/SupervisorAccount'
import ExposureIcon from '@material-ui/icons/TableChart'
import AumIcon from '@material-ui/icons/TrendingUp'
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded'
import RiskIcon from '@material-ui/icons/Warning'
// import PortfolioManagementIcon from "@material-ui/icons/AccountBalance";
import PortfolioManagementIcon from '@material-ui/icons/Work'
import { IconBell, IconBookmark, IconCategory } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { accessRightKeyMap } from '../../constants/permissions'
import { HEDGE_FUND_PATH, PRIVATE_MARKETS_PATH } from '../../constants/routes.constants'
import { useProfile } from '../../hooks'
import useIsPeRoutes from '../../hooks/useIsPeRoutes'

const styles = {
  closedSubList: { transition: 'padding 0.25s' },
  subList: { paddingLeft: 36, transition: 'padding 0.25s' },
}

const peRouteList = [
  { icon: <HomeIcon />, name: 'Home', route: PRIVATE_MARKETS_PATH },
  { icon: <IconBell />, name: 'Document Notifications', route: '/private-markets/document-notifications' },
  { icon: <MenuBookIcon />, name: 'Insights', route: '/private-markets/insights' },
  { icon: <ScreenerIcon />, name: 'Fund Screener', route: '/private-markets/funds' },
]

const hfRouteList = [
  { icon: <HomeIcon />, name: 'Home', route: HEDGE_FUND_PATH },
  { icon: <IconBell />, name: 'Document Notifications', route: '/document-notifications' },
  { icon: <MenuBookIcon />, name: 'Insights', route: '/insights' },
  {
    childrenPaths: ['/asset-detail'], icon: <ScreenerIcon />, name: 'Fund Screener', route: '/screener',
  },
  { icon: <IconBookmark />, name: 'Fact Sheets', route: '/fact-sheets' },
  {
    icon: <PortfolioManagementIcon />,
    name: 'My Portfolios',
    route: '/my-portfolios',
  },
  {
    icon: <BallotIcon />,
    name: 'Performance Overview',
    route: '/performance-overview',
  },
]

const analytics_route_list = [
  {
    children: [
      {
        icon: <IconCategory />, name: 'Peer Groups', route: '/peer-groups',
      },
      {
        icon: <StatisticsIcon />,
        name: 'Stats Analysis',
        route: '/statistics',
      },
      {
        icon: <ExposureIcon />,
        name: 'Exposure Analysis',
        route: '/exposure-analysis',
      },
      {
        icon: <PortfolioAnalysisIcon />,
        name: 'Portfolio Analysis',
        route: '/portfolio-analysis',
      },
      { icon: <RiskIcon />, name: "Risk Reports", route: "/risks" },
      {
        icon: <TrendingUpRoundedIcon />,
        name: "Portfolio Optimiser",
        route: "/portfolio-optimiser"
      }
    ],
    icon: <AnalysisIcon />,
    name: 'Analytics',
    status: 'analysis',
  },
  {
    icon: <FindInPageIcon />,
    name: 'Document Management',
    route: '/document-management',
  },
  { icon: <MonitorIcon />, name: 'Index Monitors', route: '/index-monitors' },
]

const ops_route_list = [
  {
    children: [
      { icon: <AumIcon />, name: 'AUM', route: '/aum' },
      {
        icon: <MoneyIcon />,
        name: 'Manager Holdings',
        route: '/manager-holdings',
      },
      {
        icon: <EvaluationsIcon />,
        name: 'Evaluations',
        route: '/evaluations',
      },
      {
        icon: <DateRangeIcon />,
        name: 'Trading Calendar',
        route: "/trading-calendar",
      }
    ],
    icon: <OperationsIcons />,
    name: 'Operations',
    status: 'operations',
  },
]

const user_management_route_list = [
  {
    children: [
      { icon: <UserIcon />, name: 'Users', route: '/users' },
      { icon: <GroupIcon />, name: 'Groups & Companies', route: '/groups' },
      {
        icon: <UserActivityIcon />,
        name: 'User Activity',
        route: '/activity',
      },
    ],
    icon: <UserGroupIcon />,
    name: 'User Management',
    status: 'user_management',
  },
]

const data_management_route_list = [
  {
    icon: <DataManagementIcon />,
    name: 'Data Management',
    route: '/data-management',
  },
]

const ir_route_list = [
  {
    icon: <InvestorIcon />,
    name: 'Investor Relations',
    route: '/investor-relations',
  },
]

const test_pages_route_list = [
  {
    children: [
      {
        icon: <DiagnosticIcon />,
        name: 'Total Expense Ratio',
        route: '/total-expense-ratio',
      },
      {
        icon: <SubRedIcon />,
        name: 'Subs & Reds Calendar',
        route: '/trading-calendar',
      },
    ],
    icon: <TestPageIcon />,
    name: 'Test Pages',
    status: 'test_pages',
  },
]

const authRoutesMap = {
  [accessRightKeyMap.hasAccessUserManagement]: user_management_route_list,
  [accessRightKeyMap.hasAccessAnalytics]: analytics_route_list,
  [accessRightKeyMap.hasAccessDataManagement]: data_management_route_list,
  [accessRightKeyMap.hasAccessOps]: ops_route_list,
  [accessRightKeyMap.hasAccessIr]: ir_route_list,
  [accessRightKeyMap.hasAccessTestPages]: test_pages_route_list,
}

const peRoutesMap = {
  [accessRightKeyMap.hasAccessUserManagement]: [
    {
      children: [
        { icon: <UserIcon />, name: 'Users', route: '/private-markets/users' },
        { icon: <GroupIcon />, name: 'Groups & Companies', route: '/private-markets/groups' },
        {
          icon: <UserActivityIcon />,
          name: 'User Activity',
          route: '/private-markets/activity',
        },
      ],
      icon: <UserGroupIcon />,
      name: 'User Management',
      status: 'user_management',
    },
  ],
  [accessRightKeyMap.hasAccessAnalytics]: [
    {
      icon: <FindInPageIcon />,
      name: 'Document Management',
      route: '/private-markets/document-management',
    },
  ],
}

const defaultMenuState = {
  analysis: false,
  operations: false,
  test_pages: false,
  user_management: false,
}

const MenuList = ({
  onMenuClick,
  open: drawer_open,
}) => {
  const isPeRoutes = useIsPeRoutes()
  const { permission_list } = useProfile()
  const [collapsibles, setCollapsibles] = useState(defaultMenuState)
  const authRoutes = useMemo(() => {
    if (isPeRoutes) {
      const peRoutes = [peRouteList]
      Object.entries(peRoutesMap).forEach(([key, routes]) => {
        if (permission_list.includes(key)) {
          peRoutes.push(routes)
        }
      })
      return peRoutes
    }

    const resRoutes = [hfRouteList]
    Object.entries(authRoutesMap).forEach(([key, routes]) => {
      if (permission_list.includes(key)) {
        resRoutes.push(routes)
      }
    })
    return resRoutes
  }, [permission_list, authRoutesMap, isPeRoutes])

  const toggleCollapsible = (name) => () => {
    setCollapsibles({
      ...defaultMenuState,
      [name]: !collapsibles[name],
    })
  }

  const isActive = (childrenPaths) => (match, location) => {
    const hasActiveChild = childrenPaths?.some((it) => location.pathname.includes(it))
    if (!match && !hasActiveChild) {
      return false
    }

    return true
  }

  return (
    <div>
      <List>
        {authRoutes.map((list, list_id) => (
          <React.Fragment key={list_id}>
            {list.map((route, id) => {
              const { children, status } = route
              if (children) {
                const open = collapsibles[status]
                return (
                  <React.Fragment key={id}>
                    <ListItem
                      button
                      className="navi-menu-list-item"
                      name={status}
                      onClick={toggleCollapsible(status)}
                    >
                      <ListItemIcon className="menu-icon">
                        {route.icon}
                      </ListItemIcon>
                      <ListItemText primary={route.name} />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      in={open}
                      timeout="auto"
                    >
                      <List
                        component="div"
                        disablePadding
                      >
                        {children.map((menu, menu_id) => (
                          <ListItem
                            activeClassName="active-link"
                            button
                            className="navi-menu-list-item"
                            component={NavLink}
                            isActive={isActive(route.childrenPaths)}
                            key={menu_id}
                            onClick={onMenuClick}
                            style={
                              drawer_open
                                ? styles.subList
                                : styles.closedSubList
                            }
                            to={menu.route}
                          >
                            <ListItemIcon className="menu-icon">
                              {menu.icon}
                            </ListItemIcon>
                            <ListItemText primary={menu.name} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                )
              }
              return (
                <ListItem
                  activeClassName="active-link"
                  button
                  className="navi-menu-list-item"
                  component={NavLink}
                  exact
                  isActive={isActive(route.childrenPaths)}
                  key={id}
                  onClick={onMenuClick}
                  to={route.route}
                >
                  <ListItemIcon className="menu-icon">
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText primary={route.name} />
                </ListItem>
              )
            })}
            <Divider className="nav-divider" />
          </React.Fragment>
        ))}
      </List>
    </div>
  )
}

export default MenuList
