import React from 'react'

import PortfolioOptimiserResult from './PortfolioOptimiserResult'
import PortfolioOptimiserForm from './PortfolioOptmiserForm'
import PortfolioOptimiserProvider from './RefactoredPortfolioOptimiserProvider'

const PortfolioOptimiserPage = () => (
  <PortfolioOptimiserProvider>
    <PortfolioOptimiserForm />
    <PortfolioOptimiserResult />
  </PortfolioOptimiserProvider>
)

export default PortfolioOptimiserPage
