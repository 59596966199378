import React, { useState, useEffect } from "react";
import QuickAnalysis from "./reportContainer";
import { dateTimeManager } from "../../utils";
import { getExternalReport } from "../../services";
import qs from "qs";
import { useSecurities } from "../../hooks";

export default function ReportWrapper({ location }) {
  const { search_list: searchList } = useSecurities();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState({});
  const [ignoreQueryParam, setIgnoreQueryParam] = useState(false);
  const [analysisInputs, setAnalysisInputs] = useState({
    report_type: "Quick Chart",
    fund_list: [],
    start_date: null,
    end_date: null,
  });

  const { search } = location;
  const params = qs.parse(search.replace("?", ""));
  const { fund_list, report_type } = params;

  const updateInputs = ({ value, name }) => {
    setLoaded(false);
    setAnalysisInputs((i) => ({ ...i, [name]: value }));
  };

  const collectInputs = (passedInputs) => {
    const { fund_list, start_date, end_date, report_type } = passedInputs;
    const payload = {
      report_type,
      fund_list: fund_list.map((o) => o.id),
      start_date: dateTimeManager.jsToDb(start_date),
      end_date: dateTimeManager.jsToDb(end_date),
    };

    return payload;
  };

  const onGenerateReport = async () => {
    setAnalysis({});
    setLoading(true);
    const inputs = collectInputs(analysisInputs);
    try {
      const response = await getExternalReport(inputs);
      setAnalysis(response.data);
      setLoaded(true);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    const parseInputs = async () => {
      const updatedInputs = {
        fund_list: fund_list.map((id) =>
          searchList.find((o) => o.id === Number(id))
        ),
        start_date: null,
        end_date: null,
        report_type,
      };
      setAnalysisInputs(updatedInputs);
      const inputs = collectInputs(updatedInputs);
      setAnalysis({});
      setLoading(true);
      try {
        const response = await getExternalReport(inputs);
        setAnalysis(response.data);
        setLoaded(true);
      } catch (error) {}
      setLoading(false);
    };
    if (
      !ignoreQueryParam &&
      fund_list &&
      report_type &&
      fund_list.length > 0 &&
      searchList.length > 0
    ) {
      setIgnoreQueryParam(true);
      parseInputs();
    }
  }, [ignoreQueryParam, fund_list, report_type, searchList]);

  return (
    <div className="comparison-route">
      <QuickAnalysis
        loaded={loaded}
        loading={loading}
        external_analysis={analysis}
        analysis_inputs={analysisInputs}
        search_list={searchList}
        work_ready={searchList.length !== 0}
        updateInputs={updateInputs}
        onGenerateReport={onGenerateReport}
      />
    </div>
  );
}
