import { Button } from '@material-ui/core'
import React from 'react'
import useAccessRights from '../../hooks/useAccessRights'

interface FaqEditButtonProps {
  blogPostId: string
  fundName?: string
  faqsResults: any
  disabled: boolean
  onClickCreate?: () => void
}

const FaqEditButton: React.FC<FaqEditButtonProps> = ({
  blogPostId,
  fundName,
  faqsResults,
  disabled,
  onClickCreate
}) => {
  const { hasAccessInternalClient } = useAccessRights()
  const hsBlogTemplateWidgets = faqsResults?.[0]?.widgets

  const clickCreateButtonHandler = () => {
    if (onClickCreate) {
      onClickCreate()
    }
  }

  return hasAccessInternalClient ? (
    <>
      {
        hsBlogTemplateWidgets?.faqs?.body?.field_group?.length > 0 || !!(hsBlogTemplateWidgets)
          ? (
            <Button
              color="primary"
              href={`https://app.hubspot.com/blog/${process.env.REACT_APP_REQUEST_ACCESS_HS_PORTALID}/editor/${blogPostId}/content`}
              target="_blank"
              variant="contained"
            >
              Edit FAQs - {fundName}
            </Button>
          )
          : (
            <Button
              color="primary"
              disabled={disabled}
              onClick={clickCreateButtonHandler}
              variant="contained"
            >
              Create FAQs - {fundName}
            </Button>
          )
      }
    </>
  ) : null
}

export default FaqEditButton
