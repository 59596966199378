import React, { useState, useEffect } from "react";
import { GridToolbar, GridColumn as Column } from "@progress/kendo-react-grid";
import {
  GenericTable,
  KendoDropDown,
  MonthlyDatePicker,
} from "../../components";
import { extractSeriesNames } from "../../utils/extractSeriesNames";
import moment from "moment";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { getRiskReport, getWebfolioWeights } from "../../services";

export default function ExposureMonitor() {
  const [filters, setFilters] = useState({
    selected_date: null,
    selected_portfolio: null,
  });

  const [weights, setWeights] = useState([]);
  const [availableData, setAvailableData] = useState([]);

  const updateFilters = ({ target }) => {
    const { value, name } = target;
    setFilters((i) => ({ ...i, [name]: value }));
  };

  const fetchWeights = async () => {
    try {
      const response = await getWebfolioWeights();
      setWeights(response.data);
    } catch (error) {}
  };

  const fetchReport = async () => {
    try {
      const response = await getRiskReport({ report_type: "Available Data" });
      const { report } = response.data || {};
      setAvailableData(report);
    } catch (error) {}
  };

  useEffect(() => {
    fetchWeights();
    fetchReport();
  }, []);

  const toMonthStart = (selected) =>
    moment(selected).startOf("M").format("YYYY-MM-DD");

  const getData = () => {
    const { selected_portfolio, selected_date } = filters;
    const { portfolio_id } = selected_portfolio || {};
    let activeFilters = [];
    if (portfolio_id) {
      activeFilters.push({
        field: "portfolio_id",
        operator: "eq",
        value: portfolio_id,
      });
    }
    if (selected_date) {
      activeFilters.push({
        field: "weight_date",
        operator: "eq",
        value: toMonthStart(selected_date),
      });
    }
    const filtered_weights = filterBy(weights, {
      logic: "and",
      filters: activeFilters,
    });

    const filtered_funds = [...new Set(filtered_weights.map((o) => o.name))];
    const data = availableData.filter(
      (o) => filtered_funds.indexOf(o.index) !== -1
    );
    return orderBy(data, [{ field: "index", dir: "asc" }]);
  };

  const customCell = ({ field, dataItem }) => {
    const val = dataItem[field];
    const available = val === 1;
    const color = available ? "#00b84a" : "#bf383e";
    return <td style={{ backgroundColor: color, border: "1px solid white" }} />;
  };

  const generateColumnList = () => {
    const data = getData();
    const col_names = extractSeriesNames(data);
    return col_names.map((name, idx) => (
      <Column
        key={idx}
        field={name}
        title={moment(name).format("MMM YY")}
        cell={customCell}
        width={70}
      />
    ));
  };

  const getPortfolioList = () => {
    let portfolio_list_long = weights.map((o) => ({
      portfolio_id: o.portfolio_id,
      portfolio_name: o.portfolio_name,
    }));
    let portfolio = Array.from(
      new Set(portfolio_list_long.map((s) => s.portfolio_id))
    ).map((portfolio_id) => ({
      portfolio_id,
      portfolio_name: portfolio_list_long.find(
        (o) => o.portfolio_id === portfolio_id
      ).portfolio_name,
    }));
    return portfolio;
  };

  const data = getData();
  const { selected_date, selected_portfolio } = filters;
  const portfolio_list = getPortfolioList();
  return (
    <div className="exposure-monitor">
      <GenericTable
        scrollable="scrollable"
        data={data}
        custom_cols
        lock_index
        index_width="400px"
        style={{ height: "70vh" }}
      >
        <GridToolbar>
          <div className="toolbar">
            <div className="portfolio-selector">
              <KendoDropDown
                data={portfolio_list}
                name="selected_portfolio"
                value={selected_portfolio}
                onChange={updateFilters}
                dataItemKey="portfolio_id"
                textField="portfolio_name"
                defaultItem={{
                  portfolio_id: null,
                  portfolio_name: "Select portfolio...",
                }}
              />
            </div>
            <div className="date-selector">
              <MonthlyDatePicker
                name="selected_date"
                value={selected_date}
                onChange={updateFilters}
              />
            </div>
          </div>
        </GridToolbar>
        {generateColumnList()}
      </GenericTable>
    </div>
  );
}
