import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { HUBSPOT_BLOG_POST_ENDPOINT, HUBSPOT_PROXY_URL } from "../constants/env"

export interface PopUpData {
  currentlyPublished: boolean
  topLabel: string
  title: string
  description: string
  buttons?: {
    label?: string | null
    classOrder?: string | null
    fundName?: string
  }[]
}

export const usePopUpData = () => {
  return useQuery(['pop-up-data'],
    () => axios.post(HUBSPOT_PROXY_URL, {
      url: `${HUBSPOT_BLOG_POST_ENDPOINT}/${process.env.REACT_APP_POPUP_POST_ID}`,
      method: 'GET'
    }),
    {
      select: (res: any) => {
        const widgets = res?.data?.widgets

        return ({
          topLabel: widgets?.topLabel?.body?.value,
          currentlyPublished: res?.data?.currentlyPublished,
          title: widgets?.title?.body?.value,
          description: widgets?.header?.body?.value,
          buttons: widgets?.buttons?.body?.buttons?.map((button: any) => ({
            label: button?.buttonLabel,
            classOrder: button?.classOrder,
            fundName: button?.fund_name
          }))
        } as Partial<PopUpData>)
      }
    }
  )
}

