import { Button, ButtonGroup } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React from 'react'
import { useMemo } from 'react'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { HEDGE_FUND_PATH, PRIVATE_MARKETS_PATH } from '../../constants/routes.constants'
import { AAM_HEDGE_FUND_NAME, AAM_PRIVATE_MARKET_NAME } from '../../constants/service.constants'
import useAccessRights from '../../hooks/useAccessRights'
import useIsPeRoutes from '../../hooks/useIsPeRoutes'
import { useMixpanel } from '../../hooks/useMixpanel'

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
`

const AamServiceSelect = () => {
  const history = useHistory()
  const { mpTrack } = useMixpanel()
  const isPeRoutes = useIsPeRoutes()
  const { hasPrivateEquityAccess, hasHedgeFundsAccess } = useAccessRights()
  const toggleValue = useMemo(() => isPeRoutes ? PRIVATE_MARKETS_PATH : HEDGE_FUND_PATH, [isPeRoutes])

  const clickButtonHandler = (event, value) => {
    if (!value) {
      return
    }

    mpTrack({
      eventName: `Switch service to ${value === HEDGE_FUND_PATH ? AAM_HEDGE_FUND_NAME : AAM_PRIVATE_MARKET_NAME}`,
      properties: {
        Type: 'Switch Service on Service Home Page'
      }
    })

    history.push(value)
  }

  return (
    <Wrapper>
      <ToggleButtonGroup value={toggleValue} onChange={clickButtonHandler} exclusive>
        <ToggleButton
          disabled={!hasHedgeFundsAccess}
          value={HEDGE_FUND_PATH}
        >
          {AAM_HEDGE_FUND_NAME}
        </ToggleButton>
        <ToggleButton
          disabled={!hasPrivateEquityAccess}
          value={PRIVATE_MARKETS_PATH}
        >
          {AAM_PRIVATE_MARKET_NAME}
        </ToggleButton>
      </ToggleButtonGroup>
    </Wrapper>
  )
}

export default AamServiceSelect
