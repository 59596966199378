import { useMemo } from 'react'

import useAccessRights from '../../hooks/useAccessRights'
import PerformanceTable from './performanceOverviewTable'

export default function NavsOverview({
  performance_overview,
  search_list,
  ...props
}) {
  const { hasAccessInternalClient } = useAccessRights()
  const enhanced_list = useMemo(
    () =>
      performance_overview?.map((o) => {
        const meta_data = search_list.find((f) => f.name === o.index)
        const { class_name, underlying_name } = meta_data || {}
        return { ...o, class_name, original_index: o.index, underlying_name }
      }),
    [performance_overview, search_list],
  )
  const share_class_list = useMemo(
    () =>
      [...new Set(enhanced_list?.map((o) => o.class_name))].filter((it) => it),
    [enhanced_list],
  )

  return (
    <div>
      <PerformanceTable
        {...props}
        hasAccessInternalClient={hasAccessInternalClient}
        mode="navs"
        performance_overview={enhanced_list}
        search_list={search_list}
        share_class_list={share_class_list}
      />
    </div>
  )
}
