import { Box, CircularProgress, IconButton } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Snackbar from '@material-ui/core/Snackbar'
import Tooltip from '@material-ui/core/Tooltip'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { useState } from 'react'
import styled from 'styled-components'

import SkeletonTextLoader from '../../components/skeletons/SkelatonTextLoader'
import useAccessRights from '../../hooks/useAccessRights'


const StyledListHeader = styled.div`
  display: flex;
  padding-left: 1.2rem;
`

export const StyledList = styled(List)`
  .MuiListItem-root {
    border-bottom: 1px solid ${({ theme }) => theme.palette.aamui.iceBlue};

    &:first-child {
      border-top: 1px solid ${({ theme }) => theme.palette.aamui.iceBlue};
    }

    &:hover {
      background: rgba(184, 214, 235, 0.2);
    }
  }
`

function SkeletonDocumentList() {
  return (
    <ListItem>
      <ListItemIcon>
        <img
          alt="pdf"
          my={0}
          src="/pdf_icon.svg"
          style={{ height: 50, objectFit: 'contain' }}
        />
      </ListItemIcon>
      <SkeletonTextLoader
        rows={2}
        style={{ width: '100%' }}
      />
    </ListItem>
  )
}

export const StyledDownloadIcon = styled(CloudDownloadOutlinedIcon)`
  color: ${({ theme }) => theme.palette.aamui.sapphire};
`

const DocListItem = ({ doc, onDocumentClick }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [copiedDocName, setCopiedDocName] = useState()
  const [downloading, setDownloading] = useState(false)
  const { hasAccessInternalClient } = useAccessRights()

  const clickCopyButtonHandler =
    ({ id, name }) =>
      (e) => {
        e.stopPropagation()
        setCopiedDocName(name)
        navigator.clipboard.writeText(`${window.location.href}?doc=${id}`)
        setOpenSnackbar(true)
      }

  const closeSnackbarHandler = () => {
    setOpenSnackbar(false)
  }

  const clickDownloadHandler = async () => {
    setDownloading(true)
    await onDocumentClick(doc)
    setDownloading(false)
  }

  return (
    <>
      <ListItem button onClick={clickDownloadHandler} disabled={downloading}>
        <ListItemIcon>
          <img
            alt="pdf"
            src="/pdf_icon.svg"
            style={{ objectFit: 'contain', height: 50 }}
          />
        </ListItemIcon>
        <ListItemText
          primary={doc.group || doc.name}
          secondary={doc.date || doc.as_of_date}
        />
        {hasAccessInternalClient && (
          <Box mr={1}>
            <Tooltip
              placement="top"
              arrow
              title={`${window.location.href}?doc=${doc.id}`}
            >
              <IconButton
                className="doc-copy-button"
                onClick={clickCopyButtonHandler(doc)}
              >
                <FileCopyOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {downloading ? <CircularProgress size={24} /> : <StyledDownloadIcon />}
      </ListItem>
      <Snackbar
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        autoHideDuration={3000}
        onClose={closeSnackbarHandler}
        open={openSnackbar}
      >
        <Box className="copy-snackbar-wrapper">
          Copied download link of <strong>{copiedDocName}</strong> to clipboard
        </Box>
      </Snackbar>
    </>
  )
}

export default function documentList({ documents, loading, onDocumentClick, children }) {
  return (
    <StyledList>
      {children}
      {loading ? (
        <SkeletonDocumentList />
      ) : document && documents?.length > 0 ? (
        documents?.map((doc, idx) => (
          <DocListItem
            doc={doc}
            key={idx}
            onDocumentClick={onDocumentClick}
          />
        ))
      ) : null}
    </StyledList>
  )
}
