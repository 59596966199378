export default function sortOn(arr, prop) {
  if (arr.length === 0) {
    return arr;
  }
  return arr.sort(function(a, b) {
    if (a[prop] < b[prop]) {
      return -1;
    } else if (a[prop] > b[prop]) {
      return 1;
    } else {
      return 0;
    }
  });
}
