import 'date-fns'

import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import KendoDatePicker from '../../components/forms/KendoDatePicker'
import {
  deletePropertyIfCondition,
  nullablePositiveFloat,
  nullablePositiveInteger,
  positivePercentage,
  requiredString,
  setDefaultValueIfNull,
  setPropertyIfCondition,
} from '../../utils/yup-utils'
import useOptimiserConstants from './hooks/useOptimiserConstants'
import PortOptInputLayout, { PortOptGridRow } from './PortOptInputLayout'
import PortOptTagsInput from './PortOptTagsInput'
import { usePortfolioOptimiser } from './RefactoredPortfolioOptimiserProvider'

export const portOptBasicInputSchema = yup.object().shape({
  affineBenchmarks: yup.boolean().default(true),
  name: requiredString(),
  nearZero: nullablePositiveFloat(),
  objectiveFunction: requiredString(),
  performanceEndDate: yup.date()
    .nullable()
    .test('end_date', 'End date must be later than start date', function (endDate) {
      const { performanceStartDate } = this.parent
      if (performanceStartDate && endDate) {
        return new Date(endDate) >= new Date(performanceStartDate)
      }
      return true
    }),
  quadRisk: nullablePositiveFloat(),
  riskFreeRate: positivePercentage(),
  visibilityType: requiredString(),
  solverMaxIterations: nullablePositiveInteger(),
  solverLearningRate: nullablePositiveFloat(),
}).transform((value, originalValue) => {
  deletePropertyIfCondition({
    conditionKey: 'objectiveFunction',
    conditionValue: 'sharpe-ratio',
    originalValue,
    property: 'riskFreeRate',
    value,
  })
  setPropertyIfCondition({
    conditionKey: 'objectiveFunction',
    conditionValue: 'quadratic-risk',
    defaultValue: 0.5,
    originalValue,
    property: 'quadRisk',
    value,
  })
  setDefaultValueIfNull({
    defaultValue: 0.00001, key: 'nearZero', originalValue, value,
  })
  setDefaultValueIfNull({
    defaultValue: 1, key: 'rebalancingFrequency', originalValue, value,
  })
  return value
})

const PortOptBasicInput = () => {
  const { isNewRunPage } = usePortfolioOptimiser()
  const { objFuncOptions, rebalancingFreqOptions, visibilityOptions } = useOptimiserConstants()
  const {
    control, formState: { errors }, register, setValue, watch,
  } = useFormContext()
  const tagsFieldArray = useFieldArray({ control, name: 'tags' })
  const objectiveFunction = watch('objectiveFunction')
  const visibilityType = watch('visibilityType')
  const rebalancingFrequency = watch('rebalancingFrequency')
  const performanceStartDate = watch('performanceStartDate')
  const performanceEndDate = watch('performanceEndDate')
  const tags = watch('tags')

  const changeDateValueHandler = (field) => (event) => {
    setValue(field, event.value)
  }

  const clickAddTagButtonHandler = (tagValue) => {
    tagsFieldArray.append(tagValue.trim())
  }

  const clickRemoveTagButtonHandler = (index) => () => {
    tagsFieldArray.remove(index)
  }

  return (
    <PortOptInputLayout>
      {
        isNewRunPage && (
          <PortOptGridRow>
            <Box
              display="flex"
              gridGap={8}
              pl={0.5}
            >
              <TextField
                fullWidth
                label="Name"
                style={{ minWidth: '510px' }}
                {...register('name')}
                autoComplete="off"
                error={!!errors?.name}
                helperText={errors?.name?.message}
              />

              <TextField
                fullWidth
                label="Visibility Type"
                select
                style={{ minWidth: '140px' }}
                value={visibilityType}
                {...register('visibilityType')}
                error={!!errors?.visibilityType}
                helperText={errors?.visibilityType?.message}
              >
                {
                  visibilityOptions?.map(({ label, value }) => (
                    <MenuItem
                      key={value}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
          </PortOptGridRow>
        )
      }
      <Box
        display="flex"
        gridGap={8}
      >
        <TextField
          fullWidth
          label="Objective Function"
          select
          style={{ minWidth: '220px', width: '220px' }}
          value={objectiveFunction}
          {...register('objectiveFunction')}
          error={!!errors?.objectiveFunction}
          helperText={errors?.objectiveFunction?.message}
        >
          {
            objFuncOptions?.map(({ label, value }) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            ))
          }
        </TextField>
        <TextField
          fullWidth
          label="Rebalancing Frequency"
          select
          style={{ minWidth: '220px', width: '220px' }}
          value={rebalancingFrequency}
          {...register('rebalancingFrequency')}
          error={!!errors?.rebalancingFrequency}
          helperText={errors?.rebalancingFrequency?.message}
        >
          {
            rebalancingFreqOptions?.map(({ label, value }) => (
              <MenuItem
                key={value}
                value={value}
              >{label}</MenuItem>
            ))
          }
        </TextField>
        <TextField
          inputProps={{
            step: 'any'
          }}
          label="Near Zero"
          step="any"
          style={{ width: '140px' }}
          type="number"
          {...register('nearZero')}
          error={!!errors?.nearZero}
          helperText={errors?.nearZero?.message}
        />

        <Box
          alignItems="center"
          display="flex"
          height="100%"
          ml={2}
        >
          <FormControlLabel
            control={(
              <Switch
                name="affineBenchmarks"
                {...register('affineBenchmarks')}
                defaultChecked
              />
            )}
            defaultChecked
            defaultValue
            label="Affine Benchmarks"
            labelPlacement="end"
          />
        </Box>
      </Box>
      {
        (objectiveFunction === 'sharpe-ratio' || objectiveFunction === 'quadratic-risk') && (
          <PortOptGridRow>
            {
              objectiveFunction === 'sharpe-ratio' && (
                <Grid
                  item
                  xs={3}
                >
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <Box mr={1}>
                          %
                        </Box>
                      ),
                    }}
                    label="Risk Free Rate"
                    type="number"
                    {...register('riskFreeRate')}
                    error={!!errors?.riskFreeRate?.message}
                    helperText={errors?.riskFreeRate?.message || 'If not provided, the market-free rate will be used'}
                  />
                </Grid>
              )
            }
            {
              objectiveFunction === 'quadratic-risk' && (
                <Grid
                  item
                  xs={3}
                >
                  <TextField
                    label="Quadratic Risk"
                    {...register('quadRisk')}
                    error={!!errors?.quadRisk?.message}
                    helperText={errors?.quadRisk?.message || "If not provided, the default value will be 0.5"}
                  />
                </Grid>
              )
            }
            <Grid
              item
              xs={3}
            >
              <TextField
                label="Solver Max Iterations"
                {...register('solverMaxIterations')}
                error={!!errors?.solverMaxIterations?.message}
                helperText={errors?.solverMaxIterations?.message}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={3}
            >
              <TextField
                label="Solver Learning Rate"
                {...register('solverLearningRate')}
                error={!!errors?.solverLearningRate?.message}
                helperText={errors?.solverLearningRate?.message}
                fullWidth
              />
            </Grid>
          </PortOptGridRow>
        )
      }
      <Box
        alignItems="center"
        display="flex"
        gridGap={8}
        width="510px"
      >
        <KendoDatePicker
          helperTfefeext={errors?.performanceStartDate?.message}
          onChange={changeDateValueHandler('performanceStartDate')}
          placeholder="Performance Start Date"
          value={performanceStartDate}
        />

        <KendoDatePicker
          helperText={errors?.performanceEndDate?.message}
          onChange={changeDateValueHandler('performanceEndDate')}
          placeholder="Performance End Date"
          value={performanceEndDate}
        />
      </Box>
      {
        isNewRunPage && (
          <PortOptGridRow>
            <Grid
              item
              xs={12}
            >
              <PortOptTagsInput
                onClickAdd={clickAddTagButtonHandler}
                onClickDelete={clickRemoveTagButtonHandler}
                tags={tags}
              />
            </Grid>
          </PortOptGridRow>
        )
      }
    </PortOptInputLayout>
  )
}

export default PortOptBasicInput
