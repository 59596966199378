/* eslint-disable react/display-name */
import {
  ActionIcon,
  Button,
  Divider,
  Group,
  Select,
  Stack,
} from '@mantine/core'
import { IconAdjustmentsHorizontal, IconTrash } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { createColumnHelper, SortingState } from '@tanstack/react-table'
import React, { useEffect, useMemo, useState } from 'react'

import CountLabel from '../../components/misc/CountLabel'
import { Fund } from '../../components/models/IFund'
import DataTable, { PaginationProps } from '../../components/tables/DataTable'
import DataTableColumnsSelector from '../../components/tables/DataTableColumnsSelector'
import usePeerGroupFunds from '../../hooks/usePeerGroupFunds'
import useReactTableManager from '../../hooks/useReactTableManager'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import {
  addToPeerGroupFormToggled,
  filteredByPeerGroupChanged,
  fundFetched,
  fundScreenerFiltersChanged,
  fundScreenerToggled,
  peerGroupFundScreenerColVisibilityToggled,
  peerGroupsFormToggled,
  selecPeerGroupEntities,
  selectAllPeerGroups
} from '../../redux/peerGroupsSlice'
import { getPeerGroupsOptionsMap } from '../../services'
import { camelToSnakeCase, convertCamelToSnakeCase } from '../../utils/convertCamelToSnakeCase'
import { convertSnakeToCamelCase } from '../../utils/convertSnakeToCamelCase'
import AddToPeerGroupForm from './AddToPeerGroupForm'
import PeerGroupsForm from './PeerGroupsForm'
import { useDeletePeerGroupFunds } from './useDeletePeerGroupFunds'
import PeerGroupScreenerViewSelector from './PeerGroupScreenerViewSelector'
import { screenerColDef } from './PeerGroupFundScreenerColsDef'

const columnHelper = createColumnHelper<Fund>()
const columns = screenerColDef.map(def =>
  columnHelper.accessor(def.accessor, {
    header: def.header,
    cell: def.cell,
    id: def.id,
    size: def.size,
    enableSorting: def.enableSorting
  })
);

const SEARCHABLE_FIELDS = ['name']
const INPUT_FILTER_FIELDS_MAP = {
  monthsOfData: 'calculated_statistics__months_of_data__gte',
}
const INPUT_FILTER_FIELDS = Object.keys(INPUT_FILTER_FIELDS_MAP)

const PeerGroupFundScreener = () => {
  const dispatch = useAppDispatch()
  const { deleteFunds } = useDeletePeerGroupFunds(true)

  const [sorting, setSorting] = useState<SortingState>([])
  const [rowSelection, setRowSelection] = useState({})
  const [calStatsKeys, setCalStatsKeys] = useState<string[] | undefined>()

  const allPeerGroups = useAppSelector(selectAllPeerGroups)
  const peerGroupEntity = useAppSelector(selecPeerGroupEntities)
  const { filterOn, ordering, page, pageSize, peerGroup } = useAppSelector(state => state.peerGroups.fundScreenerFilters)
  const columnVisibility = useAppSelector(state => state.peerGroups.fundScreenerColVisibility)
  const { data: queryKeysMap } = useQuery(['peer-group-funds-screener-query-keys-map'], () => getPeerGroupsOptionsMap(), { select: (res) => convertSnakeToCamelCase(res?.data) })
  const parsedFilterParams = useMemo(() => {
    const filterParams: Record<string, any> = {}

    if (queryKeysMap) {
      Object.entries(filterOn).forEach(([key, value]) => {
        if (value) {
          const paramsKey = SEARCHABLE_FIELDS.includes(key) ? 'search' : queryKeysMap[key]
          filterParams[Array.isArray(value) ? `${paramsKey}__in` : paramsKey] = value
        }
      })
    }

    if (peerGroup) {
      filterParams['id__in'] = peerGroupEntity[peerGroup]?.funds?.map((fund) => fund.fundId)
    }

    return filterParams
  }, [filterOn, queryKeysMap, peerGroup, peerGroupEntity])
  const inputFilterParams = useMemo(() => {
    const filterParams: Record<string, any> = {}

    INPUT_FILTER_FIELDS.forEach((key) => {
      if (filterOn?.[key]) {
        filterParams[INPUT_FILTER_FIELDS_MAP[key]] = filterOn[key]
      }
    })

    return filterParams
  }, [filterOn])

  const { data: fundsResponse, isLoading } = usePeerGroupFunds(ordering as string, page as number, pageSize as number, { ...parsedFilterParams, ...inputFilterParams })

  const tableData = fundsResponse?.data?.results
  const selectedRowCount = Object.values(rowSelection).filter(value => value === true).length
  const emptySelection = Object.values(rowSelection).length === 0

  const { table } = useReactTableManager({
    columns,
    data: tableData || [],
    enableRowSelection: true,
    filterFns: null,
    getRowId: (row) => row.id.toString(),
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnVisibilityChange: (newState) => {
      let changes = undefined

      if (typeof newState !== 'object') {
        changes = newState()
      }

      if (typeof newState === 'object' && Object.keys(newState).length > 0) {
        changes = newState
      }

      dispatch(peerGroupFundScreenerColVisibilityToggled(changes))
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    state: {
      columnVisibility,
      rowSelection,
      sorting
    }
  })

  const paginationProps: PaginationProps = useMemo(() => ({
    activePage: page,
    onPageChange: (pageIndex: number) => dispatch(fundScreenerFiltersChanged({ filterKey: 'page', value: pageIndex })),
    total: Math.ceil(fundsResponse?.data?.count / 20)
  }), [fundsResponse, page])

  useEffect(() => {
    if (tableData) {
      dispatch(fundFetched(tableData))
    }
  }, [tableData])

  useEffect(() => {
    if (sorting?.length > 0 && calStatsKeys) {
      const sortDetails = convertCamelToSnakeCase(sorting[0])
      const snakeCaseSortKey = camelToSnakeCase(sortDetails.id)
      const sortParam = calStatsKeys.indexOf(sortDetails.id) > -1 ? `calculated_statistics__${snakeCaseSortKey}` : snakeCaseSortKey
      dispatch(fundScreenerFiltersChanged({ filterKey: 'ordering', value: `${sortDetails?.desc ? '-' : ''}${sortParam}` }))
    }
  }, [sorting, calStatsKeys])

  useEffect(() => {
    if (!calStatsKeys && tableData) {
      const calcaultedStatisticsKeys = Object.keys(tableData?.[0]?.calculatedStatistics || {})
      setCalStatsKeys(calcaultedStatisticsKeys)
    }
  }, [calStatsKeys, tableData])

  const clickClearButtonHandler = () => setRowSelection({})
  const toggleFilter = () => dispatch(fundScreenerToggled())

  const getSelectedFunds = () => Object.entries(rowSelection)
    .filter(([key, value]) => value)
    .map(([key, value]) => +key)

  const clickCreatePeerGroupHandler = () => {
    const selectedFunds = getSelectedFunds()
    dispatch(peerGroupsFormToggled({ open: true, selectedFunds }))
  }

  const clickAddToPeerGroupHandler = () => {
    const selectedFunds = getSelectedFunds()
    dispatch(addToPeerGroupFormToggled({ open: true, selectedFunds }))
  }

  const clickDeleteFundHandler = async () => {
    const selectedFunds = getSelectedFunds()
    await deleteFunds(selectedFunds)
    clickClearButtonHandler()
  }

  return (
    <>
      <Stack gap={12} h="100%">
        <Group justify="space-between">
          <Group>
            <Group gap="xs">
              {
                peerGroup && !emptySelection && (
                  <ActionIcon
                    onClick={clickDeleteFundHandler}
                    variant="transparent"
                    color="red"
                  >
                    <IconTrash stroke={1.5} />
                  </ActionIcon>
                )
              }
              <ActionIcon
                onClick={toggleFilter}
                variant="transparent"
              >
                <IconAdjustmentsHorizontal stroke={1.5} />
              </ActionIcon>
              <DataTableColumnsSelector table={table} type="icon" />
              <Divider orientation="vertical" />
              <PeerGroupScreenerViewSelector />
            </Group>
          </Group>
          <Group>
            {
              !emptySelection && (
                <Button
                  onClick={clickClearButtonHandler}
                >
                  Deselect All <CountLabel count={selectedRowCount} />
                </Button>
              )
            }
            {
              allPeerGroups?.length > 0 && (
                <Select
                  clearable
                  data={allPeerGroups.map(group => ({ label: group.name, value: String(group.id) }))}
                  onChange={(value) => {
                    dispatch(filteredByPeerGroupChanged(String(value)))
                    clickClearButtonHandler()
                  }}
                  placeholder="Filter By Peer Group"
                  value={peerGroup}
                />
              )
            }
            <Button
              disabled={emptySelection}
              onClick={clickCreatePeerGroupHandler}
            >
              Create
            </Button>
            <Button
              disabled={emptySelection}
              onClick={clickAddToPeerGroupHandler}
            >
              Add
            </Button>
          </Group>
        </Group>
        <DataTable
          isLoading={isLoading}
          paginationProps={paginationProps}
          table={table}
          pinnedColumns={['select', 'name']}
          fullWidth
        />
      </Stack>
      <PeerGroupsForm
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
      <AddToPeerGroupForm
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </>
  )
}

export default PeerGroupFundScreener
