import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
// import PropTypes from 'prop-types';
import {
  Chart,
  ChartSeriesDefaults,
  ChartValueAxis,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartAxisDefaults,
} from '@progress/kendo-react-charts'
import { extractSeriesNames, seriesRemoveUnsafeSymbols } from '../../utils'
import { antarctica_palette } from '../../data'
import palette from 'google-palette'

export default class columnCategoriesChart extends Component {
  getObjectKeys = (data) => {
    try {
      return Object.keys(data)
    } catch (e) {
      return []
    }
  }

  calculateMinMax = (data, series) => {
    let min = Infinity
    let max = -Infinity

    data.forEach((item) => {
      series.forEach((ser) => {
        if (typeof item[ser] === 'number' && !isNaN(item[ser])) {
          min = Math.min(min, item[ser])
          max = Math.max(max, item[ser])
        }
      })
    })

    // Handle cases where no valid numbers were found
    if (min === Infinity && max === -Infinity) {
      return { min: 0, max: 0 }
    }

    min = min > 0 ? 0 : null
    max = max === 0 ? 0.01 : null

    return { min, max }
  }

  render() {
    let {
      data: unsafe_data,
      title,
      category_field = 'Month',
      stack = false,
      n_split = false,
      format = '{0:p2}',
      reversed_axis,
      series_type = 'column',
      category_crossing = 0,
      use_antarctica_colors,
      show_labels = true,
    } = this.props
    let data
    if (Array.isArray(unsafe_data)) {
      data = seriesRemoveUnsafeSymbols(unsafe_data)
    } else {
      data = unsafe_data
    }

    if (n_split && category_field) {
      data.forEach((o) => {
        o[category_field] = o[category_field].split(' ').join('\n')
      })
    }

    let series = []
    if (Array.isArray(data)) {
      series = extractSeriesNames(data)
    } else {
      series = this.getObjectKeys(data)
      data = [data]
    }

    let current_palette
    const ser_length = series.length

    current_palette =
      series.length < 65
        ? palette('mpn65', ser_length)
        : palette('tol-dv', ser_length)
    current_palette = current_palette.map((o) => `#${o.replace('#', '')}`)
    if (use_antarctica_colors) {
      current_palette = antarctica_palette
    }

    const { min, max } = this.calculateMinMax(data, series)

    return (
      <div className="line-ts-chart-container">
        <div className="title-container">
          <Typography align="center" variant="h6">
            {title}
          </Typography>
        </div>

        <Chart pannable={false} zoomable={false}>
          <ChartLegend visible="true" position="bottom" />
          <ChartSeriesDefaults
            markers={{ visible: false }}
            type={series_type}
          />
          <ChartTooltip format={format} visible={true} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem visible={show_labels} />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              reverse={reversed_axis}
              // majorGridLines={{ step: 1 }}
              labels={{ format: format }}
              axisCrossingValue={category_crossing}
              {...(min !== null && { min: min })}
              {...(max !== null && { max: max })}
            />
          </ChartValueAxis>
          <ChartAxisDefaults majorGridLines={{ visible: false }} />
          <ChartSeries>
            {series.map((ser, id) => (
              <ChartSeriesItem
                key={id}
                color={current_palette[id]}
                data={data}
                name={ser}
                field={ser}
                stack={stack}
                categoryField={category_field}
              />
            ))}
          </ChartSeries>
        </Chart>
      </div>
    )
  }
}
