import React, { useMemo } from 'react'
import ReactPlayer from 'react-player'
import Box from '@material-ui/core/Box'
import { Button } from '@material-ui/core'
import InsightPreviewCard from '../../components/misc/InsightPreviewCard'
import useInsights from '../../hooks/useInsights'
import LoadingSpinner from '../../components/misc/LoadingSpinner'

import useIsPeRoutes from '../../hooks/useIsPeRoutes'
import { BASE_ROUTE_TYPES } from '../../constants/routes.constants'

export default function Stories() {
  const isPrivateMarketRoute = useIsPeRoutes()
  const {
    hubspotInsights,
    isLoadingHsInsights,
    isFetchingHSInsights,
    isLoadingLegacyInsights,
    fetchNextPage,
    hasNextPage,
    sortedInsights,
  } = useInsights({ type: isPrivateMarketRoute ? BASE_ROUTE_TYPES.PRIVATE_MARKET : BASE_ROUTE_TYPES.HEDGE_FUND })
  return (
    <>
      {
        isLoadingHsInsights || isLoadingLegacyInsights
          ? (
            <LoadingSpinner />
          )
          : (
            <>
              <div className="insights-route">
                {
                  !hasNextPage
                    ? sortedInsights?.map((it, id) => (
                      <InsightPreviewCard
                        {...it}
                        key={id}
                        isLegacy={!!it.as_of_date}
                      />
                    ))
                    : hubspotInsights?.map((insight) => (
                      <InsightPreviewCard
                        key={insight.id}
                        {...insight}
                      />
                    ))
                }
              </div>
              {
                hasNextPage && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    mb={12}
                    mt={8}
                  >
                    <Button
                      color="primary"
                      disabled={isFetchingHSInsights}
                      onClick={fetchNextPage}
                      variant="contained"
                    >
                      Load More
                    </Button>
                  </Box>
                )
              }
            </>
          )
      }
    </>
  )
}
