import React from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { cloneDeep } from "lodash";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const Container = styled.div`
  background-color: white;
  border: none;
`;
const TitleContainer = styled.div`
  background-color: white;
`;

const Tooltip = ({ point, data }) => {
  const { category, formattedValue } = point || {};
  const row = data.find((o) => o.Factor === category);
  const p_value = row["P-Value"];
  return (
    <>
      <Typography variant="h6">{category}</Typography>
      <Typography variant="subtitle1">
        Exposure: {formattedValue} (P-Value: {p_value})
      </Typography>
    </>
  );
};

export default ({ data, title }) => {
  const getData = () => {
    let upd_data = cloneDeep(data);
    upd_data.forEach((item) => {
      item["color"] = item["Significant"] ? "#0e4466" : "#eceff1";
    });
    return upd_data;
  };

  const chartData = getData();
  return (
    <Container>
      <TitleContainer>
        <Typography align="center" variant="h6">
          {title}
        </Typography>
      </TitleContainer>
      <Chart pannable={false} zoomable={false} transitions={false}>
        <ChartTooltip
          format={`{0:n2}`}
          visible={true}
          render={(context) => <Tooltip {...context} data={chartData} />}
        />
        <ChartValueAxis>
          <ChartValueAxisItem labels={{ format: `{0:n2}` }} />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem labels={{ background: "#ffffff" }} />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem
            colorField="color"
            border={{ color: "#607d8b" }}
            categoryField={"Factor"}
            field={"Value"}
            type="bar"
            gap={0.1}
            // spacing={0.25}
            data={chartData}
          />
        </ChartSeries>
      </Chart>
    </Container>
  );
};
