import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import AutoCompleteSelect from '../../components/forms/AutoCompleteSelect'
import FormTextField from '../../components/forms/FormTextField'
import {
  nullablePositiveInteger,
  setDefaultValueIfNull,
  validPositiveLowerBound,
  validUpperBound,
  withRequired,
} from '../../utils/yup-utils'
import { cardinalityConstraintsInputName, groupConstraintsInputName } from './form-default-values'
import PortOptInputLayout, { PortOptGridContainer, PortOptGridRow } from './PortOptInputLayout'

export const portOptCardinalitySchema = yup.object().shape({
  includes: yup.array().test(
    'solutions-match',
    'Must match an asset from Weight Constraints Options',
    function (includedSolutions) {
      const schemaValue = this.from.find((it) => !!it.value.assets)?.value
      const assetNames = schemaValue?.assets?.map((asset) => asset?.name?.name)?.filter((it) => !!it)
      return includedSolutions.map(it => it?.name).every((value) => assetNames.includes(value))
    },
  ),
  lowerBound: withRequired(validPositiveLowerBound().integer().min(2)),
  maxCombinations: nullablePositiveInteger().min(1),
  upperBound: validUpperBound(undefined, nullablePositiveInteger().min(2).test(
    'max-number',
    'Must equal to or less than the number of assets',
    function (upperBound) {
      const schemaValue = this.from.find((it) => !!it.value[groupConstraintsInputName])?.value
      const assetLength = schemaValue?.assets?.length

      return assetLength >= upperBound
    },
  )),
}).transform((value, originalValue) => {
  setDefaultValueIfNull({
    defaultValue: 10000, key: 'maxCombinations', originalValue, value,
  })
  return value
})

const PortOptCardinalityInput = () => {
  const {
    formState: { errors }, register, watch,
  } = useFormContext()
  const enableCardinalityConstraints = watch('enableCardinalityConstraints', false)
  const inputedAssets = watch('assets')
  const includedSolutions = watch(`${cardinalityConstraintsInputName}.includes`)
  const assetOptions = inputedAssets?.map(({ name }) => name?.name)?.filter((it) => !!it)
  const cardinalityIncludeOptions = assetOptions?.filter((asset) => !includedSolutions?.includes(asset))

  return (
    <PortOptInputLayout
      header="Cardinality Constraints"
      toggleComponent={(
        <FormControlLabel
          control={(
            <Switch
              checked={enableCardinalityConstraints}
              name="enableCardinalityConstraints"
              {...register('enableCardinalityConstraints')}
            />
          )}
        />
      )}
    >
      {
        enableCardinalityConstraints && (
          <>
            <PortOptGridContainer>
              <PortOptGridRow>
                <Grid
                  item
                  xs={3}
                >
                  <FormTextField
                    errorMessage={errors?.[cardinalityConstraintsInputName]?.lowerBound?.message}
                    label="Cardinality Lower Bound"
                    name={`${cardinalityConstraintsInputName}.lowerBound`}
                    type="number"
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <FormTextField
                    errorMessage={errors?.[cardinalityConstraintsInputName]?.upperBound?.message}
                    label="Cardinality Upper Bound"
                    name={`${cardinalityConstraintsInputName}.upperBound`}
                    type="number"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormTextField
                    errorMessage={errors?.[cardinalityConstraintsInputName]?.maxCombinations?.message}
                    label="Maximum Number of Combinations (Default: 10000)"
                    name={`${cardinalityConstraintsInputName}.maxCombinations`}
                    type="number"
                  />
                </Grid>
              </PortOptGridRow>
              <PortOptGridRow>
                <Grid
                  item
                  xs={12}
                >
                  {
                    includedSolutions
                    && (
                      <AutoCompleteSelect
                        filterKey="name"
                        multiple
                        {...register(`${cardinalityConstraintsInputName}.includes`)}
                        error={!!errors?.[cardinalityConstraintsInputName]?.includes?.message}
                        fullWidth
                        helperText={errors?.[cardinalityConstraintsInputName]?.includes?.message}
                        inputName={`${cardinalityConstraintsInputName}.includes`}
                        label="Solution Must Include"
                        options={cardinalityIncludeOptions?.map((it) => ({ name: it }))}
                      />
                    )
                  }
                </Grid>
              </PortOptGridRow>
            </PortOptGridContainer>
          </>
        )
      }
    </PortOptInputLayout>
  )
}

export default PortOptCardinalityInput
