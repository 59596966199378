import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import clsx from 'clsx'
import GetAppIcon from '@material-ui/icons/GetApp'
import moment from 'moment'
import AsyncIconButton from '../../components/misc/AsyncIconButton'

const BookletTable = ({
  booklets,
  clickBookletTableRowHandler,
  clickGenerateFactSheetButtonHandler,
  clickDeleteBookletIconButtonHandler,
  isDownloadingPdf,
  activeBookletId,
  disableActions,
  selectedBooklet,
}) => {
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('created_at')

  const getCellValue = (row, columnId) => {
    if (columnId === 'name') {
      return row.name
    }
    if (columnId === 'created_at') {
      return row.created_at
    }
    return null
  }

  const rowComparator = (a, b) => {
    const aValue = getCellValue(a, orderBy)
    const bValue = getCellValue(b, orderBy)
    if (aValue < bValue) {
      return order === 'asc' ? -1 : 1
    }
    if (aValue > bValue) {
      return order === 'asc' ? 1 : -1
    }
    return 0
  }

  const stableSort = (array, comparator) => {
    const stabilized = array?.map((el, index) => [el, index])
    stabilized?.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilized?.map((el) => el[0])
  }

  const sortedBooklet = stableSort(booklets, rowComparator)

  const clickSortLabelHandler = (columnId) => () => {
    const isAsc = orderBy === columnId && order === 'asc'
    setOrderBy(isAsc ? 'desc' : 'asc')
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(columnId)
  }

  return (
    <>
      <Table bgcolor="white">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'name'}
                direction={orderBy === 'name' ? order : 'asc'}
                onClick={clickSortLabelHandler('name')}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>Share Class</TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'created_at'}
                direction={orderBy === 'created_at' ? order : 'asc'}
                onClick={clickSortLabelHandler('created_at')}
              >
                Created Date
              </TableSortLabel>
            </TableCell>
            <TableCell padding="none" />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedBooklet?.map((list) => (
            <TableRow
              key={list.id}
              className={clsx('clickable-table-row', {
                'active-table-row': selectedBooklet?.id === list.id,
              })}
              id={list.id}
              onClick={clickBookletTableRowHandler(list)}
            >
              <TableCell>{list.name}</TableCell>
              <TableCell>{list.share_class}</TableCell>
              <TableCell>
                {moment(list.created_at).format('DD MMM YYYY')}
              </TableCell>
              <TableCell align="right" padding="normal">
                <AsyncIconButton
                  disabled={disableActions}
                  isLoading={isDownloadingPdf && activeBookletId === list.id}
                  onClick={clickGenerateFactSheetButtonHandler(list.id)}
                >
                  <GetAppIcon />
                </AsyncIconButton>
                <IconButton
                  disabled={disableActions}
                  onClick={clickDeleteBookletIconButtonHandler(list)}
                  size="small"
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {booklets?.length < 1 && (
        <Box mt={2}>
          <Typography align="center">No booklets found</Typography>
        </Box>
      )}
    </>
  )
}

export default BookletTable
