import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import SkeletonTextLoader from '../../components/skeletons/SkelatonTextLoader'

const Disclaimers = ({ security_details, loading }) => {
  const { summary, aaap_summary, disclaimer = '' } = security_details || {}
  const disclaimer_list = disclaimer.split('\n')

  return (
    <>
      <div className="aaap-disclaimer">
        <Card>
          <CardContent>
            <Typography className="asset-detail-subheader" variant="h6">
              {aaap_summary ? 'AAAP' : ''} Description
            </Typography>
            {loading ? (
              <SkeletonTextLoader />
            ) : (
              <Typography variant="body2">{aaap_summary || summary}</Typography>
            )}
          </CardContent>
        </Card>
      </div>
      <div className="performance-disclaimer">
        <Card>
          <CardContent>
            <Typography className="asset-detail-subheader" variant="h6">
              Performance Disclaimer
            </Typography>
            {loading ? (
              <SkeletonTextLoader />
            ) : (
              disclaimer_list.map((txt, idx) => (
                <Typography key={idx} paragraph variant="body2">
                  {txt}
                </Typography>
              ))
            )}
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default Disclaimers
