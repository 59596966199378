import { Box, Button, IconButton } from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import React, { useState } from 'react'

import { aamui } from '../../styles/AppTheme'

const FieldEditControl = ({
  children,
  disabled = false,
  displayValue,
  hideEditButton = false,
  onClickCancel = () => { },
  onClickSave = () => { },
  wrapperBoxProps = {},
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const clickCancelButtonHandler = () => {
    onClickCancel()
    setIsEditing(false)
  }

  const clickSaveButtonHandler = async () => {
    await onClickSave()
    setIsEditing(false)
  }

  const clickEditIconButtonHandler = () => {
    setIsEditing(true)
  }

  return (
    <>
      {
        isEditing
          ? (
            <Box
              alignItems="flex-start"
              bgcolor={aamui.highlightBlue}
              display="flex"
              gridGap={24}
              mb={2}
              p={2}
              pt={3}
              {...wrapperBoxProps}
            >
              {children}
              <Box
                display="flex"
                gridGap={8}
                mt={2}
              >
                <Button
                  color="primary"
                  disabled={disabled}
                  onClick={clickSaveButtonHandler}
                  variant="contained"
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  disabled={disabled}
                  onClick={clickCancelButtonHandler}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )
          : (
            <Box
              alignItems="flex-start"
              display="flex"
              gridGap={4}
            >
              {
                !hideEditButton && (
                  <IconButton
                    disabled={disabled}
                    onClick={clickEditIconButtonHandler}
                    size="small"
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                )
              }
              {displayValue}
            </Box>
          )
      }
    </>
  )
}

export default FieldEditControl
