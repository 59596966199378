import { useMutation, useQueryClient } from '@tanstack/react-query'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'

import { newImplmentationAnalyticsReportOptions } from "../../constants/analytics-reports.constants"
import { StatisticsReportProvider } from "../../contexts/statistics-report.context"
import { useSecurities } from "../../hooks"
import { getInternalReport } from "../../services"
import { getSecurityDetails } from "../../services/helpers"
import { buildFileFromResponse, dateTimeManager as ds } from "../../utils"
import isStringArray from "../../utils/isStringArray"
import ReportInputSnippet from "../../utils/reportInputSnippets"
import ReportContainer from "./reportContainer"
import { newImplementFormRegistry } from "./reportSelectors"
import { useMixpanel } from '../../hooks/useMixpanel'

const defaultFraudDetectionAnalysisOptions = {
  max_kurtosis: 5.0,
  minimum_adjusted_r_squared: 0.1,
  num_bins_for_histogram: 20,
  pvalue: 0.1,
  reg_width: 3,
}

const previewFraudDetectionAnalysisOptions = Object.fromEntries(
  Object.keys(defaultFraudDetectionAnalysisOptions).map((key) => [key, null]),
)

export default function Statistics({ fixedSecurity, formatNarrow, ...props }) {
  const { mpTrack } = useMixpanel()
  const queryClient = useQueryClient()
  const {
    portfolio_list: analytics_portfolios,
    search_list,
    webfolio_list,
  } = useSecurities()
  const portfolio_list = useMemo(
    () =>
      [...analytics_portfolios, ...webfolio_list].reduce((acc, current) => {
        // Check if the accumulator already has an entry with the same 'id'
        const x = acc.find((item) => item.id === current.id)
        if (!x) {
          // If not found, add the current item to the accumulator
          return acc.concat([current])
        }
        // If found, just return the accumulator as is (ignoring the current item)
        return acc
      }, []),
    [analytics_portfolios, webfolio_list],
  )
  const alert = useAlert()
  const [analysisInputs, setAnalysisInputs] = useState({
    benchmarks: [],
    decomposition_type: 'sd',
    downside_type: 'parametric',
    end_date: ds.defaultEndDate(),
    factors: [],
    fund: '',
    fund_list: [],
    jump_mean: null,
    jump_probability: null,
    jump_vol: null,
    peer: null,
    peers: [],
    portfolio: '',
    reg_type: 'ols',
    report_type: null,

    selected_portfolio: '',
    simulation_years: null,
    start_date: ds.defaultStartDate(),
    var_type: 'fund_summary',
    weights: [{ security: '', weight: null }],

    ...previewFraudDetectionAnalysisOptions,
  })
  const [analysis, setAnalysis] = useState({})
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [showInputs, setShowInputs] = useState(true)
  const work_ready = search_list.length !== 0
  const narrow = formatNarrow ? 12 : null
  const blobs = ['Statistical Analysis (PDF)', 'Statistical Analysis (Excel)']
  const {
    data: reportData,
    isLoading: isLoadingReport,
    isSuccess: reportLoaded,
    mutateAsync: getReport,
  } = useMutation(
    async (payload) => {
      return await getInternalReport(payload)
    },
    {
      onError: () => {
        setShowInputs(true)
      },
      onSuccess: (res) => {
        queryClient.setQueryData(
          [newImplmentationAnalyticsReportOptions.fraudAnalysis],
          res?.data,
        )
        return res?.data?.data
      },
    },
  )

  useEffect(() => {
    if (fixedSecurity) {
      const { exposures_available, id, name, primary_vendor_id, type } =
        fixedSecurity
      updateSelector({
        name: 'fund',
        value: { exposures_available, id, name, primary_vendor_id, type },
      })
    }
  }, [fixedSecurity])

  const getDetails = async (payload) => {
    setLoading(true)
    const { benchmarks, factors, peers } = await getSecurityDetails(
      payload,
      search_list,
    )
    setAnalysisInputs((i) => ({ ...i, benchmarks, factors, peers }))
    setLoading(false)
  }

  const getPortfolio = async (payload) => {
    const { portfolio_id: fund_id, portfolio_type } = payload
    const security_type = search_list.find(
      (o) => o.id === Number(fund_id),
    )?.type

    setLoading(true)
    const { benchmarks, factors, peers, portfolio_weights } =
      await getSecurityDetails(
        {
          fund_id,
          security_type,
          params: { 'selected-fields': 'portfolio_weights' },
        },
        search_list,
      )
    const is_webfolio = portfolio_type === 'Webfolio'
    const port_date = moment(new Date())
      .add('-1', 'M')
      .startOf('M')
      .format('YYYY-MM-DD')
    let filtered_weights = !is_webfolio
      ? portfolio_weights
      : portfolio_weights.filter((o) => o.weight_date === port_date)
    let updated_weights = []
    for (let i = 0; i < filtered_weights.length; i++) {
      const element = filtered_weights[i]
      const { security, weight } = element
      const identified_security = search_list.find((o) => o.id === security)
      updated_weights.push({ security: identified_security, weight })
    }
    updated_weights.push({ security: '', weight: null })
    setAnalysisInputs((i) => ({ ...i, weights: updated_weights }))
    setLoading(false)
  }

  const updateSelector = (payload) => {
    setLoaded(false)
    const { name, value } = payload

    if (
      name === 'report_type' &&
      value === newImplmentationAnalyticsReportOptions.fraudAnalysis
    ) {
      const factors = search_list
        ?.filter(({ name }) => name.includes('AAM Factor - '))
        ?.map((it) => ({ ...it, name: it.name.replace('AAM Factor - ', '') }))
        ?.filter(
          ({ name }) =>
            !name.includes('Developed Markets Equity') &&
            !name.includes('ESG Equity'),
        )
      setAnalysisInputs({ ...analysisInputs, factors, [name]: value })
      return
    }

    setAnalysisInputs((i) => ({ ...i, [name]: value }))

    if (newImplementFormRegistry[analysisInputs.report_type]) {
      return
    }

    if (name === 'fund' && value) {
      const { id: fund_id, type: security_type } = value || {}
      getDetails({
        fund_id,
        security_type,
        params: { 'selected-fields': 'related_securities' },
      })
    }
    if (name === 'selected_portfolio' && value) {
      const { id: portfolio_id, portfolio_type } = value
      getPortfolio({ portfolio_id, portfolio_type })
    }
  }

  const transformInputsForReporting = () => {
    const cloned_analysis_inputs = cloneDeep(analysisInputs)
    const {
      benchmarks = [],
      decomposition_type,
      downside_type,
      end_date,
      factors = [],
      fund = {},
      peers = [],
      reg_type,
      report_type,
      start_date,
      var_type,
      weights = [],
    } = cloned_analysis_inputs

    const { id: fund_id } = fund || {}

    let fund_list = []
    for (let i = 0; i < weights.length; i++) {
      const element = weights[i]
      const { security } = element
      if (security) {
        fund_list.push(security.id)
      }
    }

    const non_null_inputs = Object.filter(
      cloned_analysis_inputs,
      ([key, value]) => !!value,
    )

    let refactored = {
      ...non_null_inputs,
      benchmarks: (benchmarks || []).map((entry) => entry.id),
      decomposition_type,
      downside_type,
      end_date: ds.jsToDb(end_date),
      factors: factors.map((entry) => entry.id),
      fund: fund_id,
      fund_list,
      peers: isStringArray(peers) ? peers : peers.map((entry) => entry.id),
      reg_type,
      report_type,
      start_date: ds.jsToDb(start_date),
      var_type,
      weights: weights.reduce((result, entry) => {
        const { security, weight } = entry
        if (security) {
          result.push({ security_id: security.id, weight })
        }
        return result
      }, []),
    }

    if (blobs.indexOf(report_type) !== -1) {
      refactored['responseType'] = 'blob'
    }

    return refactored
  }

  const onGenerateReport = async () => {
    setLoading(true)
    setShowInputs(false)
    const { report_type } = analysisInputs

    if (!report_type) {
      alert.show('Select valid analysis')
      setLoading(false)
      return
    }

    const inputs = transformInputsForReporting()
    try {
      const response = await getInternalReport(inputs)
      if (blobs.indexOf(analysisInputs.report_type) !== -1) {
        buildFileFromResponse(response)
      } else {
        setAnalysis(response.data)
        setLoaded(true)
      }

      mpTrack({
        eventName: 'Generate Internal Report',
        properties: {
          'Type': 'Statistics Report',
          'Report Type': report_type,
          'Fund ID': analysisInputs?.fund?.id,
          'Number of Benchmarks': analysisInputs?.benchmarks?.length,
          'Number of Factors': analysisInputs?.factors?.length,
          'Start Date': moment(analysisInputs?.start_date)?.format('YYYY-MM-DD'),
          'End Date': moment(analysisInputs?.end_date)?.format('YYYY-MM-DD'),
        }
      })
    } catch (error) {
      setShowInputs(true)
    }
    setLoading(false)
  }

  const submitNewImplementationForm = async (event) => {
    event.preventDefault()
    const transformedAnalysisInputData = transformInputsForReporting()
    let reqBody
    switch (analysisInputs.report_type) {
      case newImplmentationAnalyticsReportOptions.fraudAnalysis: {
        const checkAndAssignDefault = (key, values, defaultValues) =>
          values[key] ? +values[key] : defaultValues[key]
        const {
          end_date,
          factors,
          fund,
          peer,
          report_type,
          start_date,
          ...rest
        } = transformedAnalysisInputData
        reqBody = {
          end_date,
          factors,
          fund,
          max_kurtosis: checkAndAssignDefault(
            'max_kurtosis',
            rest,
            defaultFraudDetectionAnalysisOptions,
          ),
          minimum_adjusted_r_squared: checkAndAssignDefault(
            'minimum_adjusted_r_squared',
            rest,
            defaultFraudDetectionAnalysisOptions,
          ),
          num_bins_for_histogram: checkAndAssignDefault(
            'num_bins_for_histogram',
            rest,
            defaultFraudDetectionAnalysisOptions,
          ),
          peer: peer?.id,
          pvalue: checkAndAssignDefault(
            'pvalue',
            rest,
            defaultFraudDetectionAnalysisOptions,
          ),
          reg_width: checkAndAssignDefault(
            'reg_width',
            rest,
            defaultFraudDetectionAnalysisOptions,
          ),
          report_type,
          start_date,
        }
        break
      }
      default: {
        break
      }
    }
    getReport(reqBody)
  }

  const reportSnippets = new ReportInputSnippet({
    data: analysisInputs,
    search_list: search_list,
    submitFunction: onGenerateReport,
    updateInputs: updateSelector,
  })

  return reportSnippets ? (
    <StatisticsReportProvider
      value={{
        reportSnippets,
        setAnalysisInputs,
        submitNewImplementationForm,
      }}
    >
      <ReportContainer
        analysis_inputs={analysisInputs}
        internal_analysis={analysis}
        loaded={loaded}
        loading={loading || isLoadingReport}
        narrow={narrow}
        onGenerateReport={onGenerateReport}
        portfolio_list={portfolio_list}
        reportLoaded={reportLoaded}
        search_list={search_list}
        setShowInputs={setShowInputs}
        showInputs={showInputs}
        updateSelector={updateSelector}
        work_ready={work_ready}
        {...props}
      />
    </StatisticsReportProvider>
  ) : null
}
