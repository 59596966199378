import React, { Component } from "react";
import { statefulTableGenerator } from "../../components/tables";
import { diagnostics_list, tracking_error } from "../../data/diagnostics";
import {
  Grid as KGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import router from "../../resources/history";

const SuperGrid = statefulTableGenerator(KGrid);

export class trackingErrorTable extends Component {
  state = {
    current_view: "tracking_error",
  };

  generateColumn = (unique_id, title, field) => {
    return <Column key={unique_id} title={title} field={field} />;
  };

  renderColumns = () => {
    const field_list = [...diagnostics_list.tracking_error];
    return field_list.map((entry, idx) => {
      return this.generateColumn(entry, entry.title, entry.field, idx);
    });
  };

  getFundChart = (e) => {
    const { dataItem } = e;
    const { cell_id } = dataItem;
    router.push(`/tracking-error/${cell_id}`);
  };

  generateMultilineName = (props) => {
    const { dataItem } = props;
    return (
      <td className="cell-name" onClick={() => this.getFundChart(props)}>
        <div className="aaap-name">{dataItem["cell"]}</div>
        <div className="underlying-name">{dataItem["fund"]}</div>
      </td>
    );
  };

  render() {
    return (
      <React.Fragment>
        <SuperGrid
          className="tracking-error-table"
          style={{ height: "85vh" }}
          data={tracking_error || []}
          pageable={false}
          filterable={false}
          scrollable="scrollable"
        >
          <Column field="name" title="Fund" cell={this.generateMultilineName} />
          {this.renderColumns()}
        </SuperGrid>
      </React.Fragment>
    );
  }
}

export default trackingErrorTable;
