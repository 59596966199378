export const antarctica_palette = [
  "#C6E2FF",
  "#3B6AA0",
  "#7AA9DD",
  "#6E7B8B",
  "#26466D",
  "#b6c6d1",
  "#0276FD",
  "#3B4856",
  "#A2B5CD",
  "#3063A5",
  "#5993E5",
  "#6E6362",
  "#007991",
  "#6e8ea3",
  "#9eb4c1",
  "#0b3651",
  "#051b28",
  "#1B3F8B",
  "#0147FA",
  "#86a1b2",
  "#CAE1FF",
  "#4A7499",
  "#838EDE",
];

export const blue_shades_palette = [
  "#111E6C",
  "#1D2951",
  "#003152",
  "#000080",
  "#0E4D92",
  "#1034A6",
  "#0080FF",
  "#0F52BA",
  "#008ECC",
  "#6593F5",
  "#4C516D",
  "#008081",
  "#73C2DB",
  "#7285A5",
  "#4F97A3",
  "#57A0D3",
  "#4682B4",
  "#81D8D0",
  "#89CFF0",
  "#588BAE",
  "#7EF9FF",
  "#95C8D8",
  "#B0DFE5",
  "#3FE0D0",
];

export const contrasting_palette = [
  "#0e4466",
  "#C6E2FF",
  "#3B6AA0",
  "#7AA9DD",
  "#000080",
  "#81D8D0",
  "#0276FD",
  "#007991",
  "#1B3F8B",
  "#0147FA",
  "#4A7499",
  "#838EDE",
  "#3FE0D0",
];
