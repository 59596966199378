import React from "react"
import App from "./providers"
import ReactDOM from "react-dom"
import TagManager from 'react-gtm-module'
import * as serviceWorker from "./serviceWorker"
import { createRoot } from 'react-dom/client'
const tagManagerArgs = {
    gtmId: 'GTM-PG5HB5V'
}

TagManager.initialize(tagManagerArgs)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

// ReactDOM.render(
//     <App />,
//     document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
