import React, { Component } from "react";
import PropTypes from "prop-types";
import { GenericTable } from "../../components";
import Grid from "@material-ui/core/Grid";
import { LineTimeSeries, CategoricalColumnChart } from "../../components";

export default class statsReports extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  getChartFormat = (field) => {
    const decimals = ["Beta", "Correlation"];
    let format = "{0:p2}";
    decimals.forEach((element) => {
      if (field.includes(element)) {
        format = "{0:n2}";
        return format;
      }
    });
    return format;
  };

  render() {
    const { internal_analysis, narrow } = this.props;
    const { report } = internal_analysis || {};
    return (
      <Grid container spacing={1} justifyContent="center">
        {report.basic_tables.map((field, id) => (
          <Grid
            className="report-entry"
            item
            xs={12}
            lg={narrow || 10}
            xl={narrow || 6}
            key={id}
          >
            <GenericTable
              table_name={field.name}
              data={report[field.name]}
              index_width="200px"
              equal_width
              index_auto
              negative_red
            />
          </Grid>
        ))}
        <Grid item xs={12} />
        {Object.keys(report.rolling_returns_charts).map((field, id) => (
          <Grid key={id} item xs={12} lg={narrow || 10} xl={narrow || 6}>
            <LineTimeSeries
              title={field}
              data={report.rolling_returns_charts[field]}
              format="{0:p2}"
              category_field="perf_date"
              standardPalette
            />
          </Grid>
        ))}
        {report.rolling_charts.map((field, id) => (
          <Grid key={id} item xs={12} xl={narrow || 6} lg={narrow || 10}>
            <LineTimeSeries
              title={field.name}
              data={report[field.name]}
              format={field.format}
            />
          </Grid>
        ))}
        <Grid item xs={12} />
        {report.categorical_bars.map((bar_chart, idx) => {
          return (
            <Grid item xs={12} lg={narrow || 10} xl={narrow || 6} key={idx}>
              <CategoricalColumnChart
                data={report[bar_chart.name]}
                title={bar_chart.name}
              />
            </Grid>
          );
        })}
        {Object.keys(report.sorted_charts).map((field, id) => (
          <Grid key={id} item xs={12} lg={narrow || 10} xl={narrow || 6}>
            <CategoricalColumnChart
              data={report.sorted_charts[field]}
              category_field="perf_date"
              format="{0:p2}"
              title={field}
              show_labels={false}
            />
          </Grid>
        ))}
        {Object.keys(report.benchmark_up_down_charts).map((field, id) => (
          <Grid key={id} item xs={12} lg={narrow || 10} xl={narrow || 6}>
            <CategoricalColumnChart
              data={report.benchmark_up_down_charts[field]}
              category_field="index"
              format="{0:p2}"
              title={field}
            />
          </Grid>
        ))}
        <Grid item xs={12} />
        <Grid item xs={12} lg={narrow || 10} xl={narrow || 6}>
          <LineTimeSeries
            title="Cumulative Returns"
            data={report["Cumulative Returns"]}
            minimum={true}
            format={"{0:c}"}
            category_field="index"
            standardPalette
          />
        </Grid>
        <Grid item xs={12} lg={narrow || 10} xl={narrow || 6}>
          <LineTimeSeries
            data={report["Drawdown Recovery"]}
            title={"Drawdown Recovery"}
            standardPalette
          />
        </Grid>
        <Grid item xs={12} />
        {report.statskey.map((field, id) => (
          <Grid item xs={12} lg={narrow || 10} xl={narrow || 6} key={id}>
            <GenericTable
              table_name={field}
              data={report.reg_stats_dict[field]}
              index_width="200px"
              equal_width
              index_auto
              negative_red
            />
          </Grid>
        ))}
        <Grid item xs={12} />
        {Object.keys(report.reg_charts_dict).map((field, id) => (
          <Grid key={id} item xs={12} lg={narrow || 10} xl={narrow || 4}>
            <LineTimeSeries
              data={report.reg_charts_dict[field]}
              category_field="Month"
              format={this.getChartFormat(field)}
              title={field}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}
