import React, { useMemo } from 'react'

import formatDate from '../../utils/formatDate'
import PortOptResultSection from './layout/PortOptResultSection'
import PortOptStatsTable from './PortOptStatsTable'
import { usePortfolioOptimiser } from './RefactoredPortfolioOptimiserProvider'

const PortOptTableData = () => {
  const { hasFailedError, runData } = usePortfolioOptimiser()
  const result = runData?.result
  const dates = runData?.optimiserPayload?.allRets?.perfDate
  const tableData = useMemo(() => {
    if (!result) return null

    return {
      'Alpha Betas': {
        data: Object.entries(result.alphaBetas || {}).map(([label, value]) => (
          [label, `${(value.alpha * 100).toFixed(2)}%`, value.beta.toFixed(2)]
        )),
        headers: ['Index', 'Alpha', 'Beta'],
      },
      Result: {
        data: [
          ['Optimal Value', result.optimalValue?.toFixed(4)],
          ['Cumulative Returns', `${(result.cumulativeReturns * 100)?.toFixed(2)}%`],
        ],
        headers: ['Result Item', 'Value'],
      },
    }
  }, [result])

  return tableData && !hasFailedError ? (
    <>
      {
        Object.entries(tableData)?.map(([label, { data, headers }], idx) => (data?.length > 0 ? (
          <PortOptResultSection
            date={`${formatDate(dates?.[0])} - ${formatDate(dates?.[dates.length - 1])}`}
            header={label}
            key={idx}
            runData={runData}
          >
            <PortOptStatsTable
              data={data}
              headers={headers}
            />
          </PortOptResultSection>
        ) : null))
      }
    </>
  ) : null
}

export default PortOptTableData
