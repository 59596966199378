import React, { Component } from "react";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";

export default class mobileDrawer extends Component {
  static propTypes = {
    open: PropTypes.bool,
    toggleDrawer: PropTypes.func
  };

  render() {
    const { open, toggleDrawer } = this.props;
    return (
      <Drawer
        open={open}
        onClose={() => {
          toggleDrawer(false);
        }}
        className="mobile-drawer"
      >
        {this.props.children}
      </Drawer>
    );
  }
}
