import React from "react";
import ReportSnippets from "../../utils/reportInputSnippets";
import Typography from "@material-ui/core/Typography";

const reports = ["Quick Chart", "Summary Stats"];

export default function ReportInputs({
  analysis_inputs,
  updateInputs,
  onGenerateReport,
  ...props
}) {
  const reportSnippets = new ReportSnippets({
    ...props,
    data: analysis_inputs,
    updateInputs,
    submitFunction: onGenerateReport,
  });
  return (
    <div className="selector-container">
      <Typography variant="h6" align="center">
        Report Set-Up
      </Typography>
      {reportSnippets.dropdownSelector("report_type", "Report type", reports, {
        defaultItem: "Select report type...",
      })}
      {reportSnippets.globalMultiSearch("fund_list", "Funds/Benchmarks")}
      {reportSnippets.starEndSelector("start_date", "end_date")}
      {reportSnippets.submitButton("Generate report")}
    </div>
  );
}
