import Button from "@material-ui/core/Button"
import { Formik } from "formik"
import React, { useContext } from "react"

import { TextInput as Input } from "../../components/forms"
import { AuthContext } from "../../contexts"
import { getMe, otpConfirm } from "../../services"
import { StyledForm } from "./styles"
import { SubmitButton } from "./styles"

export default function OtpnForm({ setView }) {
  const {
    authorizing,
    setAuthorizing,
    setIsAuthorized,
    setProfile,
  } = useContext(AuthContext)

  const authroize = async (data) => {
    setAuthorizing(true)
    const { passcode } = data || {}
    try {
      const response = await otpConfirm({ passcode })
      const { access, refresh, token } = response.data || {}
      localStorage.setItem("accessToken", access || token)
      localStorage.setItem("refreshToken", refresh || token)

      const meResponse = await getMe(refresh)
      setIsAuthorized(true)
      setProfile(meResponse.data)
      setAuthorizing(false)
    } catch (error) {
      setIsAuthorized(false)
      setAuthorizing(false)
      return
    }
  }

  return (
    <Formik
      initialValues={{
        passcode: "",
      }}
      onSubmit={authroize}
    >
      {() => (
        <StyledForm>
          <Input
            className="item"
            name="passcode"
            placeholder="Passcode"
          />
          <SubmitButton type="submit">
            {authorizing ? "Authenticating..." : "Confirm"}
          </SubmitButton>
          <Button
            className="item"
            onClick={() => setView("default")}
            variant="outlined"
          >
            Back to log-in
          </Button>
          <Button
            className="item"
            onClick={() => setView("emergency_token")}
            variant="outlined"
          >
            Use Emergency Token
          </Button>
        </StyledForm>
      )}
    </Formik>
  )
}
