import {
  Combobox,
  Checkbox,
  createTheme,
  CSSVariablesResolver,
  DefaultMantineColor,
  Input,
  MantineColorsTuple,
  Menu,
  Pagination,
  SegmentedControl,
  Table,
  Tabs,
} from '@mantine/core'
import { Button } from '@mantine/core'

import { aamui } from './AppTheme'
import buttonClasses from './mantine-components-styles/button.module.css'
import comboboxClasses from './mantine-components-styles/combobox.module.css'
import checkboxClasses from './mantine-components-styles/checkbox.module.css'
import inputClasses from './mantine-components-styles/input.module.css'
import menuClasses from './mantine-components-styles/menu.module.css'
import paginationClasses from './mantine-components-styles/pagination.module.css'
import segmentedControlClasses from './mantine-components-styles/segmented-control.module.css'
import tableClasses from './mantine-components-styles/table.module.css'
import tabsClasses from './mantine-components-styles/tabs.module.css'

type ExtendedCustomColors =
  | 'aamui'
  | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, MantineColorsTuple>;
  }
}

const arrowSize = 10

const mantineTheme = createTheme({
  colors: {
    aamui: [aamui.oceanNavy, aamui.iceBlue, aamui.redAccent, aamui.sapphire, aamui.stormBlue, aamui.skyBlue, aamui.eggShell, aamui.highlightBlue, "#000", "#000"]
  },
  components: {
    ActionIcon: {
      defaultProps: {
        color: 'gray'
      }
    },
    Anchor: {
      styles: {
        root: {
          color: aamui.oceanNavy
          // fontSize: '14px'
        }
      }
    },
    Button: Button.extend({
      classNames: buttonClasses,
    }),
    Checkbox: Checkbox.extend({
      classNames: checkboxClasses,
      defaultProps: {
        color: 'var(--mantine-color-sapphire)',
        size: 'xs'
      },
    }),
    Chip: {
      styles: {
        label: {
          backgroundColor: aamui.sapphire,
          color: 'white',
          padding: 12
        }
      }
    },
    Combobox: Combobox.extend({
      classNames: comboboxClasses,
      defaultProps: {
        transitionProps: {
          duration: 225,
          transition: 'fade'
        }
      }
    }),
    Flex: {
      styles: {
        root: {
          width: '100%'
        }
      }
    },
    Grid: {
      styles: {
        root: {
          width: '100%'
        }
      }
    },
    HoverCard: {
      defaultProps: {
        arrowSize
      }
    },
    Input: Input.extend({
      classNames: inputClasses
    }),
    InputWrapper: {
      styles: {
        error: {
          marginLeft: '18px'
        },
        input: {
          '&::placeholder': {
            color: `${aamui.skyBlue} !important`,
          }
        }
      }
    },
    Loader: {
      defaultProps: {
        color: 'var(--mantine-color-sapphire)'
      }
    },
    Menu: Menu.extend({
      classNames: menuClasses,
      defaultProps: {
        arrowSize
      }
    }),
    // Menu: {
    //   defaultProps: {
    //     arrowSize
    //   }
    // },
    Modal: {
      styles: {
        content: {
          padding: '24px'
        },
        inner: {
          zIndex: 1401,
        },
        overlay: {
          zIndex: 1400
        }
      }
    },
    Pagination: Pagination.extend({
      classNames: paginationClasses
    }),
    Pill: {
      styles: {
        root: {
          backgroundColor: aamui.sapphire,
          color: 'white',
        }
      }
    },
    Popover: {
      defaultProps: {
        offset: 0
      },
    },
    SegmentedControl: SegmentedControl.extend({
      classNames: segmentedControlClasses,
      defaultProps: {
      }
    }),
    Select: {
      defaultProps: {
        checkIconPosition: 'right'
      },
      styles: {
        dropdown: {
          zIndex: '2500'

        }
      }
    },
    Table: Table.extend({
      classNames: tableClasses,
      defaultProps: {
        borderColor: 'var(--mantine-color-eggShell)',
        highlightOnHover: true,
        highlightOnHoverColor: 'var(--mantine-color-iceBlue)',
        striped: 'even',
        stripedColor: 'var(--mantine-color-highlightBlue)'
      },
    }),
    Tabs: Tabs.extend({
      classNames: tabsClasses
    }),
    TextInput: {
      styles: {
        input: {
          '&::placeholder': {
            color: 'green !important'
          },
        },
        section: {
          justifyContent: 'flex-end'
        }
      }
    },
    Tooltip: {
      defaultProps: {
        arrowSize
      },
      styles: {
        tooltip: {
          background: 'var(--mantine-color-sapphire)',
        }
      }
    }
  },
  defaultRadius: 0,
  fontFamily: 'Gotham A, Gotham B, sans-serif',
  other: {
    eggShell: '#82b2d9',
    highlightBlue: 'rgba(184, 214, 235, 0.2)',
    iceBlue: '#b8d6eb',
    oceanNavy: '#0a2839',
    redAccent: '#FF4D00',
    sapphire: '#3069ab',
    skyBlue: '#5e94c7',
    stormBlue: '#427db8',
  }
})

export const resolver: CSSVariablesResolver = (theme) => ({
  dark: {},
  light: {},
  variables: {
    '--mantine-color-eggShell': theme.other.eggShell,
    '--mantine-color-highlightBlue': theme.other.highlightBlue,
    '--mantine-color-iceBlue': theme.other.iceBlue,
    '--mantine-color-oceanNavy': theme.other.oceanNavy,
    '--mantine-color-placeholder': theme.other.skyBlue,
    '--mantine-color-redAccent': theme.other.redAccent,
    '--mantine-color-sapphire': theme.other.sapphire,
    '--mantine-color-skyBlue': theme.other.skyBlue,
    '--mantine-color-stormBlue': theme.other.stormBlue
  }
})

export default mantineTheme