import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

import { fetchedAllSecurities } from "./sharedActions"
import { RootState } from "./store"

export const fofShareClassesAdapter = createEntityAdapter()
const initialState = fofShareClassesAdapter.getInitialState()


export const fofShareClassesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchedAllSecurities, (state, action: any) => {
      fofShareClassesAdapter.upsertMany(state, action.payload.fofShareClasses)
    })
  },
  initialState,
  name: 'fofShareClasses',
  reducers: {

  }
})

export const {
  selectAll: selectAllFofShareClasses,
  selectById: selectFofShareClassById,
  selectEntities: selectFofShareClassEntities,
  selectIds: selectFofShareClassIds,
  selectTotal: selectTotalFofShareClasses,
} = fofShareClassesAdapter.getSelectors((state: RootState) => state.fofShareClasses)

const fofShareClassesReducer = fofShareClassesSlice.reducer
export default fofShareClassesReducer