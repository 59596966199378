import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React, { useEffect, useState } from 'react'

import { useMixpanel } from '../../hooks/useMixpanel'
import useMixpanelTimeOnComponent from '../../hooks/useMixpanelTimeOnComponent'
import useParamsQuery from '../../hooks/useParamsQuery'
import { isSecurityType, SecurityType } from '../../models/SecurityTypes'
import {
  AaapDocuments,
  Analysis,
  ContactsTable,
  Disclaimers,
  DocumentsTab,
  Exposures,
  FundInfoTab,
  PerformanceTab,
  PortfolioAnalysis,
  StatisticsTab,
  WebfolioUpdates,
} from '.'
import FaqTab from './FaqTab'

const TabContentContainer = ({ children, id, security_details, tabName }) => {
  const { mpTrack } = useMixpanel()

  useMixpanelTimeOnComponent({
    eventName: `Time on Tab of Fund - ${security_details.name}`,
    properties: { 'Tab Name': tabName },
  })

  useEffect(() => {
    if (mpTrack && security_details?.name) {
      mpTrack({
        eventName: `View ${tabName} Tab on ${security_details?.name}`,
        properties: {
          Name: security_details?.name,
          'Tab Name': tabName,
          'View Type': 'Tab on Fund Page',
        },
      })
    }
  }, [mpTrack, security_details, tabName])

  return <>{children}</>
}

const TabsContainer = ({ current_view, loading, ...props }) => {
  const tabComponents = {
    Faq: FaqTab,
    aaap_documents: AaapDocuments,
    analysis: Analysis,
    contacts: ContactsTable,
    disclaimers: Disclaimers,
    documents: DocumentsTab,
    exposures: Exposures,
    fund_info: FundInfoTab,
    performance: PerformanceTab,
    portfolio_analysis: PortfolioAnalysis,
    statistics: StatisticsTab,
    webfolio: WebfolioUpdates,
  }

  const TabComponent = tabComponents[current_view]

  return (
    TabComponent && (
      <TabContentContainer
        tabName={current_view}
        {...props}
        id={current_view}
      >
        <TabComponent
          {...props}
          loading={loading}
        />
      </TabContentContainer>
    )
  )
}

const AssetDetailContainer = ({
  loading,
  queryParams,
  security_type,
  ...props
}) => {
  const urlQuery = useParamsQuery()
  const { defaultTab = 'performance' } = queryParams || {};
  const tabFromUrl = urlQuery.get('tab');
  const docFromUrl = urlQuery.get('doc');
  const [currentView, setCurrentView] = useState(tabFromUrl || defaultTab)
  const securityId = props?.security_details?.id

  const show_contacts = ![
    'Market Index',
    'Portfolio',
    'AAAP Cell',
    'FoF Share Class',
  ].includes(security_type)

  const tabVisibility = {
    Faq: ['AAAP Cell', 'FoF Share Class', 'Webfolio'].includes(security_type),
    aaap_documents: ['AAAP Cell', 'FoF Share Class'].includes(security_type),
    analysis: !['AAAP Cell', 'Market Index', 'FoF Share Class'].includes(
      security_type,
    ),
    disclaimers: ['AAAP Cell', 'FoF Share Class'].includes(security_type),
    documents: !['AAAP Cell', 'Market Index', 'FoF Share Class'].includes(
      security_type,
    ),
    exposures: isSecurityType(SecurityType.AAAP_CELL, security_type),
    fund_info: !isSecurityType(SecurityType.INTERNAL_FOF, security_type),
    portfolio_analysis: ['Portfolio', 'FoF', 'FoF Share Class'].includes(
      security_type,
    ),
    webfolio: isSecurityType(SecurityType.WEBFOLIO, security_type),
  }

  useEffect(() => {
    if (!securityId) return

    const newView = docFromUrl ? 'aaap_documents' : tabFromUrl || defaultTab
    setCurrentView(newView)
  }, [urlQuery, securityId, defaultTab, docFromUrl, tabFromUrl])

  return (
    <div>
      <div>
        <Tabs
          indicatorColor="primary"
          onChange={(e, view) => setCurrentView(view)}
          value={currentView}
        >
          <Tab
            label="Performance"
            value="performance"
          />
          <Tab
            label="Statistics"
            value="statistics"
          />
          {tabVisibility.exposures && (
            <Tab
              label="Exposures"
              value="exposures"
            />
          )}
          {tabVisibility.portfolio_analysis && (
            <Tab
              label="Portfolio Analysis"
              value="portfolio_analysis"
            />
          )}
          <Tab
            label="Info"
            value="fund_info"
          />
          {tabVisibility.Faq && <Tab
            label="FAQ"
            value="Faq"
          />}
          {show_contacts && <Tab
            label="Contacts"
            value="contacts"
          />}
          {tabVisibility.disclaimers && (
            <Tab
              label="Disclaimers"
              value="disclaimers"
            />
          )}
          {tabVisibility.aaap_documents && (
            <Tab
              label="Documents"
              value="aaap_documents"
            />
          )}
          {tabVisibility.documents && (
            <Tab
              label="Documents"
              value="documents"
            />
          )}
          {tabVisibility.analysis && <Tab
            label="Analysis"
            value="analysis"
          />}
          {tabVisibility.webfolio && <Tab
            label="Webfolio"
            value="webfolio"
          />}
        </Tabs>
      </div>
      <div>
        {securityId && (
          <TabsContainer
            current_view={currentView}
            loading={loading}
            {...props}
            security_type={security_type}
          />
        )}
      </div>
    </div>
  )
}

export default AssetDetailContainer
