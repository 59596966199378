import React from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { cloneDeep } from "lodash";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { formatNumber } from "@telerik/kendo-intl";

const Container = styled.div`
  background-color: white;
  border: none;
`;
const TitleContainer = styled.div`
  background-color: white;
`;

const Tooltip = ({ point, data }) => {
  const { category, format } = point || {};
  const row = data.find((o) => o.Factor === category);
  const p_value = row["P-Value"];
  const value = formatNumber(row["Value"], format);
  return (
    <>
      <Typography variant="h6">{category}</Typography>
      <Typography variant="subtitle1">
        Contribution: {value} (P-Value: {p_value})
      </Typography>
    </>
  );
};

export default ({ data, title, max, format = "p2" }) => {
  const getData = () => {
    let upd_data = cloneDeep(data);
    upd_data.forEach((item) => {
      const pval = item["P-Value"];
      item["color"] = item["Value"] >= 0 ? "#ff0029" : "#377eb8";
      if (pval !== 0 && !pval) {
        item["color"] = "#545454";
      }
    });
    return upd_data;
  };

  const getSeriesMax = () => {
    const values = data.map((o) => o.End);
    return Math.max(...values);
  };

  const chartData = getData();
  const maxValue = getSeriesMax();
  return (
    <Container>
      <TitleContainer>
        <Typography align="center" variant="h6">
          {title}
        </Typography>
      </TitleContainer>
      <Chart pannable={false} zoomable={false} transitions={false}>
        <ChartTooltip
          format={format}
          visible={true}
          render={(context) => <Tooltip {...context} data={chartData} />}
        />
        <ChartValueAxis>
          <ChartValueAxisItem labels={{ format }} max={max || maxValue} />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem labels={{ background: "#ffffff" }} />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem
            colorField="color"
            categoryField={"Factor"}
            field={"Value"}
            gap={0.1}
            // spacing={0.25}
            data={chartData}
            type="rangeBar"
            fromField="Start"
            toField="End"
          />
        </ChartSeries>
      </Chart>
    </Container>
  );
};
