import React from "react";
import { LineTimeSeries } from "../../components";

export default function QuickCharts({ external_analysis }) {
  const { report } = external_analysis;
  return Object.keys(report).map((name, idx) => {
    const min = idx === 0;
    const format = idx === 0 ? "{0:c}" : "{0:p2}";
    return (
      <div key={idx} className="single-entry">
        <LineTimeSeries
          data={report[name]}
          title={name}
          category_field="perf_date"
          minimum={min}
          format={format}
        />
      </div>
    );
  });
}
