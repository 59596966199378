import React from "react";
import { useField } from "formik";
import ErrorMessage from "./ErrorMessage";
import { KendoDropDown } from "../selectors";

function DropdownForm({ name, ...props }) {
  const [field, meta] = useField(name);
  return (
    <>
      <KendoDropDown {...field} {...props} />
      {meta.touched && meta.error && <ErrorMessage error={meta.error} />}
    </>
  );
}

export default DropdownForm;
