import React, { Component } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TradesTab, TermsTab } from "./";

export class flowsContainer extends Component {
  state = {
    current_view: "trades",
  };

  changeView = (e, new_view) => {
    this.setState({ current_view: new_view });
  };

  renderTab = () => {
    const { current_view } = this.state;
    switch (current_view) {
      case "trades":
        return <TradesTab />;
      case "terms":
        return <TermsTab />;
      default:
        break;
    }
  };

  render() {
    const { current_view } = this.state;
    return (
      <React.Fragment>
        <div className="flows-page">
          <Tabs
            value={current_view}
            onChange={this.changeView}
            indicatorColor="primary"
          >
            <Tab label="Trades" value="trades" />
            <Tab label="Terms" value="terms" />
          </Tabs>
        </div>
        <div>{this.renderTab()}</div>
      </React.Fragment>
    );
  }
}

export default flowsContainer;
