import React from "react"
import { SmartGrid, KendoDropDown } from "../../components"
import { GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid"
import { Input } from "@progress/kendo-react-inputs"
import { createTotals } from "./helpers"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"

export default function HoldingsTable({
  data,
  columns,
  setSelectedNames,
  selectedNames,
  setSelectedMonth,
  selectedMonth,
  fundName,
  changeFundName,
  evaluationsDates,
  onExport,
}) {
  const dataWithTotals = createTotals(data, selectedNames, columns)
  const filteredData = dataWithTotals.filter((o) =>
    o.index.toLowerCase().includes(fundName.toLowerCase())
  )
  const handleFilterClick = (name) => {
    if (selectedNames.indexOf(name) === -1) {
      setSelectedNames((i) => [...i, name])
    } else {
      setSelectedNames((i) => i.filter((o) => o !== name))
    }
  }

  const handleClear = () => {
    setSelectedNames([])
    changeFundName("")
  }

  const activeCols = selectedNames.length === 0 ? columns : selectedNames

  return (
    <SmartGrid
      data={filteredData}
      scrollable="scrollable"
      sortable={true}
      style={{ height: "80vh", width: "100%" }}
    >
      <GridToolbar>
        <div className="toolbar">
          <div className="filters-col">
            <div className="filter-row">
              <ButtonGroup style={{ display: 'block' }}>
                {columns.map((name, idx) => {
                  const selected = selectedNames.indexOf(name) !== -1
                  return (
                    <Button
                      color={selected ? "primary" : "default"}
                      key={idx}
                      onClick={() => handleFilterClick(name)}
                      style={{ marginBottom: '4px', height: '48px' }}
                      variant={selected ? "contained" : "outlined"}
                    >
                      {name}
                    </Button>
                  )
                })}
              </ButtonGroup>
              <div className="export-button">
                <Button
                  color="primary"
                  onClick={onExport}
                  variant="contained"
                >
                  Export
                </Button>
              </div>
            </div>
            <div className="filter-row">
              <div className="pivot-selectors">
                <Input
                  onChange={({ target }) => {
                    changeFundName(target.value)
                  }}
                  placeholder="Search for fund..."
                  value={fundName}
                />
                <Button
                  color="primary"
                  disabled={selectedNames.length === 0 && !fundName}
                  onClick={handleClear}
                  size="small"
                  variant="outlined"
                >
                  Clear filters
                </Button>
              </div>
              <div className="date-selector">
                <KendoDropDown
                  defaultItem="Selected Evaluation Date..."
                  items={evaluationsDates}
                  onChange={({ target }) => setSelectedMonth(target.value)}
                  sortable
                  value={selectedMonth}
                />
              </div>
            </div>
          </div>
        </div>
      </GridToolbar>

      <Column
        field="index"
        locked
        sortable
        title="Fund"
        width="300px"
      />
      {activeCols.map((m, id) => (
        <Column
          field={m}
          format="{0:n0}"
          key={id}
          title={m}
          width="112px"
        />
      ))}
      <Column
        field="Total"
        format="{0:n0}"
        locked
        title="Total"
        width="200px"
      />
    </SmartGrid>
  )
}
