import React, { useMemo } from "react";
import { useField, useFormikContext } from "formik";
import ErrorMessage from "./ErrorMessage";
import MuiDatePicker from "./MuiDatePicker";
import isValidDate from "../../utils/isValidDate";
import { Box, Group, Tooltip } from "@mantine/core";
import { IconButton } from "@material-ui/core";
import ActionButton from "../tables/ActionButton";
import { IconRestore } from "@tabler/icons-react";

export default function DatePicker({ name, ...props }) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext()

  const handleChange = (date) => {
    setFieldValue(name, date ? (isValidDate(date) ? date.toISOString() : 'Invalid Date') : null);
  };

  return (
    <>
      <Group display="flex" align="center">
        <Group style={{ width: props.showTodayButton ? 'calc(100% - 56px)' : '100%' }}>
          <MuiDatePicker
            {...field}
            format="dd/MM/yyyy"
            disableToolbar={false}
            {...props}
            onChange={handleChange}
            placeholder={props.format}
            style={{ marginTop: '8px', width: '100%' }}
          />
        </Group>
        {
          props.showTodayButton &&
          <Tooltip label="Reset to Today">
            <ActionButton onClick={() => {
              setFieldValue(name, new Date())
            }}>
              <IconRestore />
            </ActionButton>
          </Tooltip>
        }
      </Group>
      {meta.touched && meta.error && <ErrorMessage error={meta.error} />}
    </>
  );
}

export const BulkDatePicker = ({ name, bulkArray, bulkField, ...props }) => {
  const { values, setValues } = useFormikContext();
  const value = values[name] || "";

  const bulkChange = (newDate, name) => {
    const { [bulkArray]: modArray } = values;
    let newArray = [...modArray];

    for (let i = 0; i < newArray.length; i++) {
      let element = newArray[i];
      element[bulkField] = newDate;
    }
    setValues({ ...values, [bulkArray]: newArray, [name]: newDate });
  };

  return (
    <Group style={{ width: props.showTodayButton ? 'calc(100% - 56px)' : '100%' }}>
      <MuiDatePicker
        value={value}
        onChange={(e) => {
          bulkChange(e, name);
        }}
        format="dd MMM yyyy"
        disableToolbar={false}
        {...props}
      />
      {
        props.showTodayButton &&
        <Tooltip label="Reset to Today">
          <ActionButton onClick={() => {
            bulkChange(new Date(), name);
          }}>
            <IconRestore />
          </ActionButton>
        </Tooltip>
      }
    </Group>
  )
};
