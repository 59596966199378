import { useLocation } from 'react-router-dom';
import { BASE_ROUTE_TYPES, PRIVATE_MARKET_BASE_PATH } from '../constants/routes.constants';

const useIsPeRoutes = () => {
  const location = useLocation();
  return location?.pathname?.includes(PRIVATE_MARKET_BASE_PATH);
};

export const useRouteType = () => {
  const isPeRoute = useIsPeRoutes()
  return isPeRoute ? BASE_ROUTE_TYPES.PRIVATE_MARKET : BASE_ROUTE_TYPES.HEDGE_FUND
}

export default useIsPeRoutes;
