import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import React from 'react'
import { fundStatusMap } from './PrivateEquityCard/PrivateEquityCard'

interface PeFundsFilterToggleProps {
  onChange: (value: string) => void;
  value?: string;
  disabledAllOption?: boolean;
  hideAllOption?: boolean;
  size?: 'small' | 'medium';
}

const PeFundsFilterToggle: React.FC<PeFundsFilterToggleProps> = ({
  onChange,
  value,
  disabledAllOption,
  hideAllOption,
  size = 'medium'
}) => {
  const changeFilterHandler = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    onChange(newValue)
  }

  return (
    <ToggleButtonGroup
      onChange={changeFilterHandler}
      value={value}
      exclusive
    >
      {
        !hideAllOption && (
          <ToggleButton
            disabled={disabledAllOption}
            value=""
            size={size}
          >
            All
          </ToggleButton>
        )
      }
      {Object.keys(fundStatusMap).map((it) => (
        <ToggleButton size={size} key={it} value={it}>
          {fundStatusMap[it]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default PeFundsFilterToggle
