import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined"
import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"

import { Zone } from "../../components"
import DeleteDialog from "../../containers/researchManagement/deleteDialog"
import { deleteIrDocs, getIrDocs, uploadIrDocs } from "../../services"
import { dateTimeManager as dt } from "../../utils"

const Uploader = ({ onDrop }) => {
  return (
    <Dropzone onDrop={onDrop}>
      {({ getInputProps, getRootProps }) => (
        <Zone {...getRootProps()}>
          <input {...getInputProps()} />
          Drag'n'Drop new materials here. Or click to select.
        </Zone>
      )}
    </Dropzone>
  )
}

const DocumentList = ({ data, onDelete }) => (
  <>
    <Paper>
      <Typography
        align="center"
        variant="h6"
      >
        Latest Materials
      </Typography>
      <div>
        <List className="toolbar-menu">
          {data.map((doc, id) => (
            <ListItem
              button
              key={id}
              onClick={() => {
                window.open(doc.attachment, "_blank", "noopener")
              }}
            >
              <ListItemIcon>
                <img
                  alt="pdf"
                  src="/pdf_icon.svg"
                  style={{ height: 50, objectFit: "contain" }}
                />
              </ListItemIcon>

              <ListItemText
                primary={doc.name}
                secondary={doc.as_of_date}
              />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="delete"
                  className="icon-button"
                  edge="end"
                  onClick={() => onDelete(doc)}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </Paper>
  </>
)

export default function IrDocuments() {
  const [documentList, setDocumentList] = useState([])
  const [itemToDelete, setItemToDelete] = useState(null)
  const dialogOpen = !!itemToDelete

  const handleUpload = (files) => {
    var form_data = new FormData()
    const attachment = files[0]
    const today = dt.jsToDateTimeDb(new Date())
    form_data.append("name", attachment.name)
    form_data.append("story_type", "IR Marketing Documents")
    form_data.append("as_of_date", today)
    form_data.append("attachment", attachment)
    form_data.append("attachment_original_name", attachment.name)
    form_data.append("security", "")
    uploadDocument(form_data)
  }

  const handleOpenDialog = (document) => {
    setItemToDelete(document)
  }

  const uploadDocument = async (payload) => {
    try {
      const response = await uploadIrDocs(payload)
      setDocumentList((i) => [...i, response.data])
    } catch (error) { }
  }

  const fetchDocuments = async () => {
    try {
      const response = await getIrDocs()
      setDocumentList(response.data)
    } catch (error) { }
  }

  const deleteDocuments = async (doc) => {
    const originalList = [...documentList]
    setDocumentList((i) => i.filter((o) => o.id !== doc.id))
    try {
      await deleteIrDocs(doc)
    } catch (error) {
      setDocumentList(originalList)
    }
  }

  useEffect(() => {
    fetchDocuments()
  }, [])

  return (
    <div className="ir-docs-route">
      <div className="uploader">
        <Uploader onDrop={handleUpload} />
      </div>
      <div className="document-list">
        <DocumentList
          data={documentList}
          onDelete={handleOpenDialog}
        />
      </div>
      <DeleteDialog
        handleDelete={() => {
          deleteDocuments(itemToDelete)
          setItemToDelete(null)
        }}
        item={itemToDelete}
        onClose={() => {
          setItemToDelete(null)
        }}
        open={dialogOpen}
      />
    </div>
  )
}
