import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React from "react"

import ReportSnippets from "../../utils/reportInputSnippets"

export default function ReportInputs({
  analysis_inputs,
  onGenerateReport,
  onGenerateRoC,
  setShowInputs,
  showInputs,
  updateSelector,
  webfolio_list,
  ...props
}) {
  const { portfolio } = analysis_inputs || {}
  const not_ready = !!(!portfolio || !portfolio.id)
  const reportSnippets = new ReportSnippets({
    ...props,
    data: analysis_inputs,
    submitFunction: onGenerateReport,
    updateInputs: updateSelector,
  })
  return (
    <div className="selectors">
      <Typography
        align="center"
        variant="h6"
      >
        Report Set-Up
        {!showInputs && (
          <IconButton
            onClick={() => {
              setShowInputs(true)
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        )}
      </Typography>
      <Collapse in={showInputs}>
        {reportSnippets.dropdownSelector(
          "portfolio",
          "Portfolio",
          webfolio_list,
          {
            dataItemKey: "id",
            defaultItem: { id: null, name: "Select portfolio..." },
            textField: "name",
          }
        )}
        {reportSnippets.monthlyCalendarSelector("end_date")}
        {reportSnippets.checkBox(
          "hide_unused_strategies",
          "Hide Special Sits and Side Pockets"
        )}
        {reportSnippets.submitButton("Generate PRA", { disabled: not_ready })}
        {reportSnippets.submitButton("Generate RoC Table", {
          disabled: not_ready,
          onClick: () => onGenerateRoC(),
          variant: "outlined",
        })}
      </Collapse>
    </div>
  )
}
