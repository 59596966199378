import React from "react";
import { ExposureReport } from "../exposureAnalysis";

export default function exposureAnalysis() {
  return (
    <div>
      <ExposureReport />
    </div>
  );
}
