import React, { useMemo } from 'react'
import ReportFormBuilder from '../../../components/misc/ReportFormBuilder'
import { useSecurities } from '../../../hooks'
import { snippetType } from '../../../utils/reportInputSnippets'

const FraudDetectionAnalysisForm = () => {
  const { search_list } = useSecurities()
  const fraudAnalysisOptionalFields = useMemo(() => [
    {
      type: snippetType.globalSearch,
      key: 'fund',
      name: 'Fund',
      fieldProps: {
        required: true,
      },
      overrideOptions: search_list?.filter(({ type, name }) => type === 'Webfolio' && !name.includes('AAAP'))
    },
    {
      type: snippetType.globalMultiSearch,
      key: 'factors',
      name: 'Factors',
      fieldProps: {
        required: true
      },
      overrideOptions: search_list?.filter(({ name }) => name.includes('AAM Factor - '))
        ?.map((it) => ({ ...it, name: it.name.replace("AAM Factor - ", "") }))
        ?.filter(({ name }) => !name.includes('Developed Markets Equity'))
    },
    {
      type: snippetType.globalSearch,
      key: 'peer',
      name: 'Peer',
      fieldProps: {
        required: true
      },
      overrideOptions: search_list?.filter(({ name }) => name.includes('HFRI'))
    },
    {
      type: snippetType.textField,
      key: 'pvalue',
      name: 'P-value (default: 0.1)',
      fieldProps: {
        min: 0,
        max: 1,
        type: 'number'
      },
    },
    {
      type: snippetType.textField,
      key: 'minimum_adjusted_r_squared',
      name: 'Minimum Adj-R^2 (default: 0.1)',
      fieldProps: {
        min: 0,
        max: 1,
        type: 'number'
      },
    },
    {
      type: snippetType.textField,
      key: 'max_kurtosis',
      name: 'Maximum Kurtosis  (default: 5)',
      fieldProps: {
        type: 'number'
      },
    },
    {
      type: snippetType.textField,
      key: 'num_bins_for_histogram',
      name: 'Number of histogram bins (default: 20)',
      fieldProps: {
        step: 1,
        min: 0,
        type: 'number'
      },
    },
    {
      type: snippetType.textField,
      key: 'reg_width',
      name: 'Regression bin width (default: 3)',
      fieldProps: {
        min: 1,
        step: 1,
        type: 'number'
      },
    },
    {
      type: snippetType.starEndSelector,
      startKey: 'start_date',
      endKey: 'end_date',
    }
  ], [search_list])

  return (
    <ReportFormBuilder
      formData={fraudAnalysisOptionalFields}
    />
  )
}

export default FraudDetectionAnalysisForm
