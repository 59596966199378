import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.8rem 0.6rem;
    flex-wrap: wrap;
    padding: 38px 48px;
    background: ${({ theme }) => theme.palette.aamui.sapphire};
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
`;

const StyledCard = styled.div`
    width: 504px;
    border-radius: 0;
    margin-bottom: 2rem;
    margin-right: 2rem;
    box-shadow: 0.2rem 0.1rem 1.1rem rgba(0,0,0,20%);
    background: white;
    position: relative;
    height: 100%;
`;

const ButtonsWrapper = styled.div`
    background: white;
    padding: 22px 48px;
    display: flex;
    align-items: center;
    position: relative;
    gap: 24px;
    
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: ${({ theme }) => theme.palette.aamui.sapphire};
        opacity: 0.1;
    }
`;

const StyledCardRow = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 32px;
    padding: 40px 48px;
`;

const StyledCardMetaRow = styled.div`
    margin-top: 10px;    
`;
const StyledCardBody = styled.div`
    background-color: white;
`;

const StyledData = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 10px;
    background: ${({ theme }) => theme.palette.aamui.oceanNavy};
`;

const StyledDataHeading = styled.span`
    font-weight: 400;
    color: white;
    font-size: 11px;
    margin-bottom: 8px;
`;

const StyledDataValue = styled.span`
    font-weight: 700;
    font-size: 16px;
    color: white;
`;

const StyledCardTable = styled.table`
    display: block;
    background-color: white;
    padding: 30px 0px;
    margin: 0 48px 80px;
    border-top: 1px solid ${({ theme }) => theme.palette.aamui.iceBlue};

    & td {
        padding-bottom: 16px;
    }
`;

const StyledCardTableCellType = styled.td`
    display: block;
    font-weight: bolder;
    color: ${({ theme }) => theme.palette.aamui.oceanNavy};
    width: max-content;
`;

const StyledCardTableCell = styled.td`
    padding-left: 2.9rem;
    color: ${({ theme }) => theme.palette.aamui.oceanNavy};
`;

const StyledLink = styled(Link)`
    color: ${({ theme }) => theme.palette.aamui.oceanNavy};
    text-decoration: none;
    font-size: 16px;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CardLink = styled(Link)`
    display: block;
    text-decoration: none;
    position: relative;
    margin-bottom: 24px;
`;

const StyledCardFooter = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    color: #124768;
    font-weight: 600;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    position: absolute;
    background: ${({ theme }) => theme.palette.aamui.highlightBlue};
`;

const StyledCardStatus = styled.div`
    background-color: ${(props) => props.isOpen.bgColor};
    display: flex;
    align-items: center;
    color: ${(props) => props.isOpen.color};
    justify-content: center;
    padding: 0 24px;
    height: 48px;
    font-weight: 500;
`;

const StyledCardMetaType = styled.span`
    background-color: ${({ theme }) => theme.palette.aamui.sapphire};
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;;
    font-weight: bold;
    padding: 10px 24px;
    color: white;
    text-transform: uppercase;
`;

const StyledCardMetaSector = styled(StyledCardMetaType)`
    background-color: ${({ theme }) => theme.palette.aamui.skyBlue};
`;

const StyledCardFundName = styled.h1`
    font-weight: bolder;
    font-size: 1rem;
    color: #ffffff;
    margin: 0;
    filter: ${(props) => (props.isBlurred ? 'blur(4px)' : 'none')};
`;

const StyledCardFund = styled.div`
    display: flex;
    align-items: center;
`;

const StyledFundLogo = styled.img`
    width: 100px;
    height: 100px;
    background-color: #ffffff;
    filter: ${(props) => (props.isBlurred ? 'blur(6px)' : 'none')};
    object-fit: contain;
`;

export {
    StyledHeader,
    StyledCard,
    StyledCardRow,
    StyledCardMetaRow,
    StyledCardBody,
    StyledData,
    StyledLink,
    StyledCardFooter,
    StyledCardMetaType,
    StyledCardFundName,
    StyledFundLogo,
    StyledCardFund,
    StyledCardMetaSector,
    StyledCardStatus,
    StyledCardTable,
    StyledCardTableCell,
    StyledDataHeading,
    StyledCardTableCellType,
    StyledDataValue,
    ButtonsWrapper,
    HeaderWrapper,
    CardLink,
};
