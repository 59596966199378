import {
  Box,
  Button,
  Card,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import LoadingSpinner from '../../components/misc/LoadingSpinner'
import SelectableList from '../../components/misc/SelectableList'
import { ConstantsContext } from '../../contexts'
import { exportFundInfoDocs, getDocumentType, getGroups } from '../../services'
import { buildFileFromResponse } from '../../utils'
import { convertCamelToSnakeCase } from '../../utils/convertCamelToSnakeCase'
import { convertSnakeToCamelCase } from '../../utils/convertSnakeToCamelCase'

const ListHeader = ({ count, title }) => (
  <Typography variant="h6">{`${title}${
    count > 0 ? ` (${count})` : ''
  }`}</Typography>
)

const listMaxHeight = 550

const DocumentsExport = () => {
  const constant = useContext(ConstantsContext)
  const [selectedGroups, setSelectedGroups] = useState([])
  const [selectedStoryTypes, setSelectedStoryTypes] = useState([])
  const [asOfDateStoryTypeId, setAsOfDateStoryTypeId] = useState({})
  const [isDefaultValuesSet, setIsDefaultValuesSet] = useState(false)
  const [storyDate, setStoryDate] = useState('')

  const { data: accessGroups, isLoading: loadingAcsGrp } = useQuery(
    ['access-groups'],
    () => getGroups(),
    { select: (res) => res?.data?.map(({ id, name }) => ({ id, name })) },
  )
  const { data: storyTypes, isLoading: loadingStoryTypes } = useQuery(
    ['story-types'],
    () => getDocumentType(),
    { select: (res) => res.data },
  )
  const { isLoading: isExporting, mutateAsync: exportFundInfo } = useMutation(
    (data) => exportFundInfoDocs(data),
  )
  const isLoadingDisplayData =
    loadingAcsGrp || loadingStoryTypes || !isDefaultValuesSet

  useEffect(() => {
    const defaultValues = convertSnakeToCamelCase(
      constant?.DEFAULT_FUND_INFO_EXPORT_ENDPOINT_BODY,
    )
    if (defaultValues) {
      setSelectedGroups(defaultValues.accessGroups || [])
      setSelectedStoryTypes(defaultValues.storyTypes?.map((it) => it.id) || [])
      setAsOfDateStoryTypeId(
        defaultValues.storyTypes?.reduce(
          (acc, it) => ({ ...acc, [it.id]: it.asOfDate }),
          {},
        ) || {},
      )
      setStoryDate(defaultValues.storyDate || '')
    }
    setIsDefaultValuesSet(true)
  }, [constant])

  const toggleSwitchHandler =
    (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation()
      setAsOfDateStoryTypeId({
        ...asOfDateStoryTypeId,
        [id]: event.target.checked,
      })
    }

  const changeDateHandler = (event) => {
    setStoryDate(event.target.value)
  }

  const clickExportButtonHandler = async () => {
    try {
      const res = await exportFundInfo(
        convertCamelToSnakeCase({
          accessGroups: selectedGroups,
          storyDate,
          storyTypes: selectedStoryTypes?.map((id) => ({
            asOfDate: !!asOfDateStoryTypeId[id],
            id,
          })),
        }),
      )
      buildFileFromResponse(res)
      toast.success('Documents exported successfully')
    } catch (err) {
      toast.error('Failed to export documents')
    }
  }

  return isLoadingDisplayData ? (
    <LoadingSpinner />
  ) : (
    <>
      <Box>
        <Typography>
          Before exporting, please select <strong>Access Groups</strong>, choose
          the <strong>Story Types</strong>, and set the <strong>Date</strong>.
        </Typography>
        <Box display="flex" justifyContent="space-between" my={2}>
          <Box alignItems="center" display="flex" gridGap={8}>
            <Typography variant="h6">Date </Typography>
            <TextField
              onChange={changeDateHandler}
              type="date"
              value={storyDate}
            />
          </Box>
          <Button
            color="primary"
            disabled={!storyDate || isExporting}
            onClick={clickExportButtonHandler}
            variant="contained"
          >
            Export
          </Button>
        </Box>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Card>
            <SelectableList
              ListHeader={
                <ListHeader
                  count={selectedGroups.length}
                  title="Access Groups"
                />
              }
              itemKey="id"
              itemLabel="name"
              items={accessGroups}
              maxHeight={listMaxHeight}
              selectedItems={selectedGroups}
              setSelectedItems={setSelectedGroups}
            />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <SelectableList
              ListHeader={
                <ListHeader
                  count={selectedStoryTypes.length}
                  title="Story Types"
                />
              }
              itemKey="id"
              itemLabel="name"
              items={storyTypes}
              maxHeight={listMaxHeight}
              renderSecondaryAction={(item) => (
                <Tooltip title="As of Date">
                  <Switch
                    checked={!!asOfDateStoryTypeId[item.id]}
                    onChange={toggleSwitchHandler(item.id)}
                  />
                </Tooltip>
              )}
              selectedItems={selectedStoryTypes}
              setSelectedItems={setSelectedStoryTypes}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default DocumentsExport
