import { useQuery } from '@tanstack/react-query'

import { fetchHubSpotFaqs } from '../utils/fundFaqUtils'

type UseFetchFaqsParams = [...Parameters<typeof fetchHubSpotFaqs>, boolean]

const useFetchFaqs = (...args: UseFetchFaqsParams) => {
  const [blogType, blogSlug, enabled] = args
  const { data, ...rest } = useQuery(
    ['fund-faqs', blogType, blogSlug],
    async () => fetchHubSpotFaqs(blogType, blogSlug),
    {
      staleTime: Infinity,
      enabled,
    },
  )

  return [data, rest]
}

export default useFetchFaqs
