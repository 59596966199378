
import { Box, Chip } from '@material-ui/core'
import React, { useMemo } from 'react'

import { TradingCalendarDayType, TradingCalendarFormMode } from './models'
import { useTradingCalendar } from './TradingCalendarProvider'

interface LastFormValues {
  mode: TradingCalendarFormMode;
  [key: string]: any;
}

interface ChipData {
  key: string;
  label: React.ReactNode | string;
}

interface TradingCalendarLastFormValueChipsProps {
  data: LastFormValues;
}

const keyToLabelMap: Record<string, string> = {
  countries: 'Country',
  dayType: 'Day Type',
  daysBefore: 'Days Before',
  fund: 'Fund',
  interval: 'Interval',
  isFirstDate: 'Is First Date',
  mode: 'Mode',
  numIntervals: 'Number of Periods',
  startDate: 'Start Date',
  year: 'Year'
}

const order = ['mode', 'dayType', 'countries', 'fund', 'interval', 'year', 'startDate', 'daysBefore', 'numIntervals', 'isFirstDate']

const modeToExcludedLabelMap: Record<TradingCalendarFormMode, string[]> = {
  [TradingCalendarFormMode.Custom]: ['fund', 'year', 'isFirstDate'],
  [TradingCalendarFormMode.Regular]: ['fund', 'startDate'],
  [TradingCalendarFormMode.Fund]: ['startDate']
}

const onlyValueKeys = ['dayType', 'mode']
const onlyValueKeyMap: Record<string, string> = {
  [TradingCalendarDayType.BUSINESS_DAYS]: 'Subscription',
  [TradingCalendarDayType.CALENDAR_DAYS]: 'Redemption',
  [TradingCalendarFormMode.Regular]: 'By Report Period',
  [TradingCalendarFormMode.Custom]: 'By Custom Date',
  [TradingCalendarFormMode.Fund]: 'By Fund'
}

const convertFormValuesToLabel = (key: string, value: any): React.ReactNode => {
  const label = keyToLabelMap[key]
  let valueString: string | undefined

  if (onlyValueKeys.includes(key)) {
    return (
      <strong>{onlyValueKeyMap[value]}</strong>
    )
  }

  if (key === 'countries') {
    valueString = value.map((country: { label: string }) => country.label).join(', ')
  }

  if (key === 'isFirstDate') {
    valueString = value ? 'Yes' : 'No'
  }

  return (
    <>
      <strong>{label}</strong>: {valueString || value}
    </>
  )
}

const TradingCalendarLastFormValueChips = ({ data }: TradingCalendarLastFormValueChipsProps) => {
  const { lastFormValues } = useTradingCalendar()

  const chipData = useMemo(() => {
    if (!lastFormValues) return null
    return order.filter((key) => !modeToExcludedLabelMap[lastFormValues.mode].includes(key))?.map((key) => ({
      key,
      label: convertFormValuesToLabel(key, lastFormValues[key])
    }))

  }, [lastFormValues])

  return chipData ? (
    <Box
      display="flex"
      flexWrap="wrap"
      gridGap={8}
      mb={2}
    >
      {
        chipData.map(({ key, label }) => (
          <Chip
            color="primary"
            key={key}
            label={label}
          />
        ))
      }
    </Box>
  ) : null
}

export default TradingCalendarLastFormValueChips
