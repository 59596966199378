import { CircularProgress, IconButton } from '@material-ui/core'
import React from 'react'

const AsyncIconButton = ({
  isLoading,
  children,
  disabled,
  ...rest
}) => {
  return (
    <IconButton {...rest} size="small" disabled={isLoading || disabled}>
      {isLoading ? <CircularProgress size={24} /> : children}
    </IconButton>
  )
}

export default AsyncIconButton