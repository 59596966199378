import {
  Alert,
  Box,
  Center,
  Group,
  LoadingOverlay,
  Pagination,
  SegmentedControl,
  Stack,
  Text
} from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import useFetchDocsNotifications, {
  defaultDocsNotificationParams,
  defaultDocsNotiPageSize,
  UseDocumentNotificationsParams
} from '../../hooks/useFetchDocsNotifications'
import { useParamsQueryObject } from '../../hooks/useParamsQuery'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import useUpdateDocsNotification from '../../hooks/useUpdateDocsNotification'
import {
  docsNotificationPageChanged,
  factSheetNotificationDismissed,
  selectedDocsNotiDismissed,
  useDocsNotiCount,
  useDocsNotiIds
} from '../../redux/docsNotificationSlice'
import DocsNotificationItem from './DocsNotificationItem'
import DocsNotificationTableActions from './DocsNotificationTableActions'
import DocumentNotificationFilter from './DocumentNotificationFilter'
import { useParsedAAAPMap } from '../../hooks/useProcessedInsightArticle'
import useAAAPUnderlyingIdClassMap from '../../hooks/useAAAPUnderlyingIdClassMap'
import useAPFUnderlyingFundIdClassMap from '../../hooks/useAPFUnderlyingFundIdClassMap'


const viewStatusReadParamMap: Record<string, boolean | null> = {
  'All': null,
  'Unread': false,
  // eslint-disable-next-line sort-keys/sort-keys-fix
  'Read': true
}
const viewStatuses = Object.keys(viewStatusReadParamMap)
const viewStatusOptions = viewStatuses?.map((it) => ({ label: it, value: it }))

const DocumentNotificationsPage = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [fetchParams, setFetchParams] = useState<UseDocumentNotificationsParams>()
  const aaapUnderlyingIdClassMap = useAAAPUnderlyingIdClassMap()
  const peUnderlyingIdClassMap = useAPFUnderlyingFundIdClassMap()
  const { isLoading: isLoadingNotifications, refetch } = useFetchDocsNotifications(fetchParams)
  const togglingAllNotifications = useAppSelector((state) => state.docsNotification.togglingAllNotifications)
  const notificationCount = useDocsNotiCount()
  const notificationIds = useDocsNotiIds()
  const factsheetNotification = useAppSelector((state) => state.docsNotification.factSheetNotification)
  const { setNotificationToReadStatus } = useUpdateDocsNotification()

  const urlParams = useParamsQueryObject()
  const { security, storyType, view } = urlParams
  const viewStatus = viewStatuses.includes(view) ? view : 'All'
  const isLoading = isLoadingNotifications || togglingAllNotifications

  const changeViewStatusHandler = (newViewStatus: string) => {
    setFetchParams({ ...fetchParams, page: 1 })
    const params = {
      ...urlParams,
      view: newViewStatus,
    }
    history.push(`/document-notifications?${new URLSearchParams(params).toString()}`)
  }

  useEffect(() => {
    const newStoryType = storyType?.split(',') || []
    if (!newStoryType.includes('-AAAP Factsheet')) {
      newStoryType.push('-AAAP Factsheet')
    }

    setFetchParams(prev => ({
      ...defaultDocsNotificationParams,
      ...prev,
      page: urlParams.page ? +urlParams.page : defaultDocsNotificationParams.page,
      read: viewStatus ? viewStatusReadParamMap[viewStatus] : defaultDocsNotificationParams.read,
      security__in: security || undefined,
      storyType: newStoryType.join(',') || defaultDocsNotificationParams.storyType
    }))

    dispatch(selectedDocsNotiDismissed())
  }, [viewStatus, storyType, security, urlParams])

  const changePageHandler = (newPage: number) => {
    setFetchParams({ ...fetchParams, page: newPage })
    dispatch(docsNotificationPageChanged())
  }

  const dismissFactSheetNotificationHandler = async () => {
    await setNotificationToReadStatus(factsheetNotification?.id as number)
    dispatch(factSheetNotificationDismissed())
  }

  return (
    <Stack
      className="notification-page"
      gap="xs"
      maw="1100px"
      mx="auto"
    >
      <Group>
        <SegmentedControl
          data={viewStatusOptions}
          onChange={changeViewStatusHandler}
          value={viewStatus}
          withItemsBorders={false}
        />
      </Group>
      <DocumentNotificationFilter />
      {
        isLoading
          ? <LoadingOverlay
            overlayProps={{ opacity: 0 }}
            visible={isLoading}
          />
          : (
            <>
              {
                factsheetNotification &&
                <Alert
                  icon={<IconInfoCircle />}
                  onClose={dismissFactSheetNotificationHandler}
                  style={{ textDecoration: 'none' }}
                  title={
                    <Text
                      component={Link}
                      size="sm"
                      to="/fact-sheets"
                    >
                      {`${moment(factsheetNotification.latestDate).format('MMM YYYY')} Fact Sheets Available`}
                    </Text>
                  }
                >
                </Alert>
              }
              {
                notificationIds?.length > 0
                  ? (
                    <Box
                      className="notification-list"
                      component="ol"
                      pl={0}
                    >
                      <DocsNotificationTableActions refetch={refetch} />
                      {
                        notificationIds.map((id) => (
                          <DocsNotificationItem
                            key={id}
                            notificationId={id as number}
                            refetch={refetch}
                            viewStatus={viewStatus}
                            aaapIdMap={aaapUnderlyingIdClassMap}
                            peIdMap={peUnderlyingIdClassMap}
                          />
                        ))
                      }

                    </Box>
                  )
                  : (<Center>No Notifications</Center>)
              }
              <Center>
                <Pagination
                  onChange={changePageHandler}
                  total={Math.ceil(notificationCount / defaultDocsNotiPageSize)}
                  value={fetchParams?.page}
                />
              </Center>
            </>
          )
      }
    </Stack>
  )
}

export default DocumentNotificationsPage
