import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { HUBSPOT_BLOG_POST_ENDPOINT, HUBSPOT_PROXY_URL } from "../constants/env"

type CreateHsBlogPostParams = {
  baseSlug: string
  fundName?: string
  contentGroupId: number | string
  slugChildId: number | string
  postName?: string
  metaDescription?: string
}

const useCreateHsBlogPost = () => {
  const {
    mutateAsync,
    isLoading,
    ...rest
  } = useMutation(
    async (data: any) => axios.post(
      HUBSPOT_PROXY_URL,
      {
        url: `${HUBSPOT_BLOG_POST_ENDPOINT}`,
        method: 'POST',
        data,
      }
    )
  )

  const createHsBlogPost = async ({
    baseSlug,
    fundName,
    contentGroupId,
    slugChildId,
    postName,
    metaDescription
  }: CreateHsBlogPostParams) => {
    const res = await mutateAsync({
      slug: `${baseSlug}/${slugChildId}`,
      name: postName || `Fund FAQs: ${fundName}`,
      contentGroupId,
      metaDescription: metaDescription || "Fund FAQs",
      useFeaturedImage: false
    })

    if (res?.data?.id) {
      window.open(`https://app.hubspot.com/blog/${process.env.REACT_APP_REQUEST_ACCESS_HS_PORTALID}/editor/${res.data.id}/content`)
      return res?.data?.id
    }
  }

  return {
    ...rest,
    createHsBlogPost,
    isCreatingHsBlogPost: isLoading,
  }
}

export default useCreateHsBlogPost
