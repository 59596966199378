import { useContext, createContext } from 'react';

export const StatisticsReportContext = createContext({});
export const StatisticsReportProvider = ({ children, value }) => (
  <StatisticsReportContext.Provider
    value={value}
  >
    {children}
  </StatisticsReportContext.Provider>
)
export const useStatisticsReport = () => useContext(StatisticsReportContext);
