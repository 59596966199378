import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useMemo } from 'react'
import { newImplmentationAnalyticsReportOptions } from '../../constants/analytics-reports.constants'

const FraudDetectionReport = () => {
  const queryClient = useQueryClient()
  const reportData = queryClient.getQueryData([newImplmentationAnalyticsReportOptions.fraudAnalysis])
  const testResults = reportData?.report?.fraud_detection_test_results
  const testWarnings = reportData?.report?.warnings
  const testSummary = useMemo(() => {
    if (!testResults) {
      return null
    }

    return [
      { label: 'Tests Passed', value: testResults.filter(({ result }) => result).length },
      { label: 'Tests Failed', value: testResults.filter(({ result }) => !result).length },
      { label: 'Total', value: testResults.length }
    ]
  }, [testResults])

  return (
    <>
      {
        testWarnings?.length > 0 && (
          <Box component="ul" my={2}>
            {
              testWarnings.map((warning, idx) => (
                <Box component="li" key={idx}>{warning}</Box>
              ))
            }
          </Box>
        )
      }
      <Box bgcolor="white" maxWidth="500px" mx="auto" my={4}>
        <Table
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Test</TableCell>
              <TableCell>Passed/Failed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              testResults?.map(({ friendly_name, result }, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    {friendly_name}
                  </TableCell>
                  <TableCell>
                    <Box color={result ? 'inherit' : 'red'}>
                      {result ? 'Pass' : 'Fail'}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            }
            <TableRow />
          </TableBody>
        </Table>
        <Box mt={2}>
          <Table
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>Summary</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                testSummary?.map(({ label, value }, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{label}</TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box bgcolor="white" maxWidth="1000px" mx="auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Test</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Method</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              testResults?.map(({ friendly_name, description, method }, idx) => (
                <TableRow key={idx}>
                  <TableCell>{friendly_name}</TableCell>
                  <TableCell>{description}</TableCell>
                  <TableCell>{method}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Box>
    </>
  )
}

export default FraudDetectionReport
