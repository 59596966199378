import React, { useState, useEffect } from "react";
import ReportSnippets from "../../utils/reportInputSnippets";
import { useAlert } from "react-alert";
import {
  getMappedValueKeys,
  getExposures,
  updateExposures,
} from "../../services";
import Typography from "@material-ui/core/Typography";
import { status as st } from "../../constants";
import { buildFileFromResponse } from "../../utils";
import { useSecurities } from "../../hooks";

const ExposureSetUp = ({
  exposure_inputs,
  mapped_classifications,
  exposure_update_status,
  updateInputs,
  saveExposures,
  getDataExcel,
  ...props
}) => {
  const { security, classification, book } = exposure_inputs || {};
  const get_allowed = !!security;
  const post_allowed = !!(
    security &&
    classification &&
    book &&
    !exposure_update_status
  );
  const reportSnippets = new ReportSnippets({
    ...props,
    data: exposure_inputs,
    updateInputs: updateInputs,
    submitFunction: saveExposures,
  });

  const getSubmitButtonText = () => {
    switch (exposure_update_status) {
      case st.success:
        return "Data uploaded";
      case st.request:
        return "Uploading...";
      case st.failure:
        return "Something went wrong";
      default:
        return "Update exposures";
    }
  };

  return (
    <div className="security-setup-container">
      <Typography variant="h6" align="center">
        Exposures Upload{" "}
      </Typography>
      {reportSnippets.globalSearch("security", "Hedge Fund")}
      {reportSnippets.actionButton("Get data", getDataExcel, {
        disabled: !get_allowed,
      })}
      {reportSnippets.dropdownSelector(
        "classification",
        "Classification",
        mapped_classifications,
        { defaultItem: "Classification" }
      )}
      {reportSnippets.dropZone("book", "Excel File", {
        accept: [".xls", ".xlsx"],
      })}
      {reportSnippets.submitButton(getSubmitButtonText(), {
        disabled: !post_allowed,
      })}
    </div>
  );
};

export default function ExposureUpdate() {
  const [valueKeyList, setValueKeyList] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [exposureInputs, setExposureInputs] = useState({
    security: "",
    classification: null,
    book: null,
  });

  const alert = useAlert();

  const { search_list: searchList } = useSecurities();

  const fetchValueKeys = async () => {
    try {
      const response = await getMappedValueKeys();
      setValueKeyList(response.data);
    } catch (error) {}
  };

  const updateInputs = ({ value, name }) => {
    setUpdateStatus(null);
    setExposureInputs((i) => ({ ...i, [name]: value }));
  };

  const fetchExposures = async () => {
    const { security } = exposureInputs || {};

    const { id } = security || {};
    alert.show("Generating Excel...");
    try {
      const response = await getExposures({ id });
      buildFileFromResponse(response);
    } catch (error) {}
  };

  const saveExposures = async () => {
    setUpdateStatus(st.request);
    const { security, classification, book } = exposureInputs || {};
    const data = new FormData();
    data.append("security", security.id);
    data.append("classification", classification);
    data.append("book", book);
    try {
      await updateExposures(data);
      setUpdateStatus(st.success);
    } catch (error) {
      setUpdateStatus(st.failure);
    }
  };

  useEffect(() => {
    fetchValueKeys();
  }, []);

  const mappedClassifications = [
    "TOP-LEVEL",
    ...new Set(
      valueKeyList
        .filter((o) => o.category === "MAPPED")
        .map((o) => o.classification)
    ),
  ];

  return (
    <div>
      <ExposureSetUp
        exposure_inputs={exposureInputs}
        search_list={searchList}
        mapped_classifications={mappedClassifications}
        exposure_update_status={updateStatus}
        updateInputs={updateInputs}
        saveExposures={saveExposures}
        getDataExcel={fetchExposures}
      />
    </div>
  );
}
