import React from "react";
import PropTypes from "prop-types";
import ErrorMessage from "./ErrorMessage";
import MuiPhoneNumber from "material-ui-phone-number";
import { useFormikContext } from "formik";
import { TextField } from "@material-ui/core";
import styled from 'styled-components'

const Wrapper = styled.div`
  & .MuiInputBase-root {
    border: 1px solid #5E94C7;
  }
`;

function PhoneForm({ name, ...props }) {
  const { handleChange, errors, values } = useFormikContext();
  const value = values[name] || "";

  return (
    <Wrapper>
      <MuiPhoneNumber
        fullWidth
        name={name}
        margin="none"
        defaultCountry="us"
        value={value || ""}
        onChange={handleChange(name)}
        {...props}
      />
      <ErrorMessage error={errors[name]} />
    </Wrapper>
  );
}

PhoneForm.propTypes = {
  name: PropTypes.string,
};

export default PhoneForm;
