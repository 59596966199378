import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { UPDATE_HEDGE_FUNDS_WITH_STATS } from '../constants'
import { FundsContext } from '../contexts'
import { getHedgeFundList } from '../services'
import { dateTimeManager } from '../utils'
import useAccessRights from './useAccessRights'
import { useSecurities } from './useSecurities'

export const useScreener = ({ fund_data_available, screenerMode }) => {
  const { isLoadingSecurities } = useSecurities()
  const { hasAccessInternalClient } = useAccessRights()

  const enabled =
    !isLoadingSecurities &&
    hasAccessInternalClient &&
    screenerMode === 'funds' &&
    !fund_data_available

  const { dispatch } = useContext(FundsContext)

  const { data, isLoading, ...rest } = useQuery(
    ['hedgeFundList', screenerMode],
    async () => {
      if (screenerMode === 'funds') {
        const response = await getHedgeFundList({ mode: 'with_stats' })
        return response.data.map((fund) => {
          const { calculated_statistics } = fund || {}
          if (calculated_statistics) {
            const { stats_date } = calculated_statistics
            return {
              ...fund,
              calculated_statistics: {
                ...calculated_statistics,
                stats_date: dateTimeManager.dbToJs(stats_date),
              },
            }
          }
          return fund
        })
      }
      return null
    },
    {
      enabled: enabled,
      onSuccess: (data) => {
        if (data) {
          dispatch({
            payload: data,
            type: UPDATE_HEDGE_FUNDS_WITH_STATS,
          })
        }
      },
    },
  )

  return { data, isLoading: enabled && isLoading }
}
