import { Box, Button, Grid, IconButton, TextField } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import React, { useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import AutoCompleteSelect from '../../components/forms/AutoCompleteSelect'
import FormTextField from '../../components/forms/FormTextField'
import { useSecurities } from '../../hooks'
import {
  nullableNumber,
  positivePercentage,
  validPositiveLowerBound,
  validUpperBound,
  withRequired,
} from '../../utils/yup-utils'
import {
  defaultAssetValues,
  groupConstraintsInputName,
} from './form-default-values'
import BulkColumnEditor from './layout/BulkColumnEditor'
import OptimiserPercentageSumLabel from './layout/OptimiserPercentageSumLabel'
import { getArrayValueFromSchema } from './optimiser-validations'
import PortfolioImportButton from './PortfolioImportButton'
import PortOptAssetsSuggestedValueScaleButton from './PortOptAssetsSuggestedValueScaleButton'
import PortOptInputLayout, {
  PortOptGridContainer,
  PortOptGridRow,
} from './PortOptInputLayout'

function hasValidSuggestedValue(assets) {
  return assets?.some(
    (asset) => asset?.suggestedValue !== null && asset?.suggestedValue !== '',
  )
}

export const portOptAssetSchema = yup.object().shape({
  blackLittermanViews: positivePercentage(),
  group: yup
    .string()
    .nullable()
    .test('docheck', 'Required', function (value) {
      return !(
        this.options.from.some((it) => it?.value?.enableGroupConstraints) &&
        !value
      )
    })
    .test('no-group-match', 'Please defined this group name', function (value) {
      const schemaValue = this.from.find((it) => !!it.value.assets)?.value
      const definedAssetNames = schemaValue?.[groupConstraintsInputName]
        ?.map((group) => group.groupName)
        ?.filter((it) => !!it)

      if (!schemaValue.enableGroupConstraints) {
        return true
      }

      return definedAssetNames?.includes(value?.trim())
    }),
  lowerBound: withRequired(
    validPositiveLowerBound()
      .concat(positivePercentage())
      .test(
        'sum-of-lowerBound',
        'The sum of all lower bounds cannot exceed 100%',
        function () {
          const assets = getArrayValueFromSchema(this.from, 'assets')
          const sum = assets.reduce(
            (acc, asset) => acc + parseFloat(asset.lowerBound || 0),
            0,
          )
          return sum <= 100
        },
      ),
  ),
  name: withRequired(yup.object()).test(
    'unique-name',
    'Duplicated asset',
    function (value) {
      const assets = getArrayValueFromSchema(this.from, 'assets')
      return assets.filter((it) => it.name?.name === value?.name).length === 1
    },
  ),
  suggestedValue: nullableNumber()
    .min(0, 'Must be zero or positive number')
    .test(
      'mandatory-if-one-valid',
      'All suggested values are mandatory if one is provided',
      function (value) {
        const assets = getArrayValueFromSchema(this.from, 'assets')

        // If there's at least one valid suggestedValue, all become mandatory
        if (hasValidSuggestedValue(assets)) {
          return value !== null && value !== ''
        }

        // If no valid suggestedValues are provided, the validation passes
        return true
      },
    )
    .test(
      'smaller-or-euqal-to-one',
      'The sum of all suggested values be equal to 100.00%',
      function (value) {
        const assets = getArrayValueFromSchema(this.from, 'assets')

        if (!hasValidSuggestedValue(assets)) {
          return true // If there are no suggested values, pass validation
        }

        const sum = assets?.reduce((acc, it) => {
          const parsedValue = parseFloat(it.suggestedValue)
          return acc + (isNaN(parsedValue) ? 0 : parsedValue) // Use `isNaN` to check for valid numbers
        }, 0)

        // Define the acceptable range for the sum
        const lowerBound = 99.995444444
        const upperBound = 100.004444444

        // Check if the sum is within the specified range
        return sum >= lowerBound && sum <= upperBound
      },
    ),
  upperBound: validUpperBound(
    undefined,
    positivePercentage().test(
      'sum-of-upperBound',
      'The sum of all upper bounds must be at least 100%',
      function () {
        const assets = getArrayValueFromSchema(this.from, 'assets') // Assuming 'assets' is available in the context
        const sum = assets.reduce(
          (acc, asset) => acc + parseFloat(asset.upperBound || 0),
          0,
        )
        return sum >= 100
      },
    ),
  ),
})

const ColumnEditorWrapper = ({ children }) => (
  <Grid item justifyContent="flex-end" xs>
    <Box display="flex" justifyContent="flex-end" width="100%">
      {children}
    </Box>
  </Grid>
)

const PortOptAssetsInput = () => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext()
  const assetsArray = useFieldArray({ control, name: 'assets' })
  const { search_list } = useSecurities()
  const enableGroupConstraints = watch('enableGroupConstraints')
  const watchedAssets = watch('assets')
  const assetOptions = useMemo(
    () =>
      search_list
        ?.map((it) => ({ name: it.name, type: it.type }))
        ?.filter(({ name }) =>
          watchedAssets?.every((asset) => asset?.name?.name !== name),
        )
        ?.sort((a, b) => a.type.localeCompare(b.type)),
    [search_list, watchedAssets],
  )

  const clickAddButtonHandler = () => {
    assetsArray.append(defaultAssetValues)
  }

  const clickRemoveButtonHandler = (index) => () => {
    assetsArray.remove(index)
  }

  const clickEqualiseSuggestedValueHandler = () => {
    watchedAssets.forEach((it, idx) => {
      const value = 100 / watchedAssets.length
      setValue(
        `assets.${idx}.suggestedValue`,
        value % 1 === 0 ? value : value.toFixed(2),
      )
    })
  }

  return (
    <PortOptInputLayout header="Weight Constraint Options">
      <PortOptGridContainer>
        <PortOptGridRow>
          <Grid item xs={6}>
            <div style={{ paddingLeft: '16px' }}>Assets</div>
          </Grid>
          <Grid item xs></Grid>
          <Grid item xs>
            <div style={{ textAlign: 'center' }}>Weights</div>
          </Grid>
          <Grid item xs></Grid>
          {enableGroupConstraints && (
            <Grid item xs>
              <div>Groups</div>
            </Grid>
          )}
          <Grid item xs style={{ flexGrow: 0 }}>
            {assetsArray.fields.length > 1 && (
              <div style={{ width: '48px' }}></div>
            )}
          </Grid>
        </PortOptGridRow>

        <PortOptGridRow>
          <Grid item xs={6} />
          <ColumnEditorWrapper>
            <BulkColumnEditor
              arrayName="assets"
              placeholder="Weight Lower Bound"
              targetPropName="lowerBound"
            />
          </ColumnEditorWrapper>
          <ColumnEditorWrapper>
            <BulkColumnEditor
              arrayName="assets"
              placeholder="Weight Upper Bound"
              targetPropName="upperBound"
            />
          </ColumnEditorWrapper>
          <ColumnEditorWrapper>
            <BulkColumnEditor
              arrayName="assets"
              placeholder="Suggested Value"
              targetPropName="suggestedValue"
            />
          </ColumnEditorWrapper>
          {enableGroupConstraints && <Grid item xs></Grid>}
          <Grid item xs style={{ flexGrow: 0 }}>
            {assetsArray.fields.length > 1 && (
              <div style={{ width: '48px' }}></div>
            )}
          </Grid>
        </PortOptGridRow>

        {assetsArray.fields.map((it, idx) => (
          <PortOptGridRow key={it.id}>
            <Grid item xs={6}>
              <AutoCompleteSelect
                dataKey="name"
                error={!!errors?.assets?.[idx]?.name}
                filterKey="name"
                groupBy={(option) => option?.type?.trim()?.toUpperCase()}
                helperText={errors?.assets?.[idx]?.name?.message}
                inputName={`assets.${idx}.name`}
                label="Asset"
                options={assetOptions}
              />
            </Grid>
            <Grid item xs>
              <FormTextField
                endAdornment="%"
                errorMessage={errors?.assets?.[idx]?.lowerBound?.message}
                label="Lower Bound"
                name={`assets.${idx}.lowerBound`}
                type="number"
              />
            </Grid>
            <Grid item xs>
              <FormTextField
                endAdornment="%"
                errorMessage={errors?.assets?.[idx]?.upperBound?.message}
                label="Upper Bound"
                name={`assets.${idx}.upperBound`}
                type="number"
              />
            </Grid>
            <Grid item xs>
              <FormTextField
                endAdornment="%"
                errorMessage={errors?.assets?.[idx]?.suggestedValue?.message}
                label="Suggested"
                name={`assets.${idx}.suggestedValue`}
                type="number"
              />
            </Grid>
            {enableGroupConstraints && (
              <Grid item xs>
                <TextField
                  fullWidth
                  label="Group"
                  {...register(`assets.${idx}.group`)}
                  error={!!errors?.assets?.[idx]?.group}
                  helperText={errors?.assets?.[idx]?.group?.message}
                />
              </Grid>
            )}

            <Grid item xs style={{ flexGrow: 0 }}>
              <Box pt={1.5}>
                {assetsArray.fields.length > 1 && (
                  <IconButton onClick={clickRemoveButtonHandler(idx)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
          </PortOptGridRow>
        ))}
        <PortOptGridRow>
          <Grid item xs={6} />
          <Grid item xs>
            <OptimiserPercentageSumLabel
              arrayName="assets"
              targetKey="lowerBound"
            />
          </Grid>
          <Grid item xs>
            <OptimiserPercentageSumLabel
              arrayName="assets"
              targetKey="upperBound"
            />
          </Grid>
          <Grid item xs>
            <OptimiserPercentageSumLabel
              arrayName="assets"
              targetKey="suggestedValue"
            />
          </Grid>
          {enableGroupConstraints && <Grid item xs></Grid>}
          <Grid item xs style={{ flexGrow: 0 }}>
            {assetsArray.fields.length > 1 && (
              <div style={{ width: '48px' }}></div>
            )}
          </Grid>
        </PortOptGridRow>

        <PortOptGridRow>
          <Grid item xs={6}>
            <Box display="flex" gridGap={12}>
              <Button
                color="primary"
                onClick={clickAddButtonHandler}
                variant="contained"
              >
                Add Additional Assets
              </Button>
              <PortfolioImportButton
                assetsArray={assetsArray}
                watchedAssets={watchedAssets}
              />
            </Box>
          </Grid>

          <Grid item xs></Grid>
          <Grid item xs></Grid>

          <Grid item xs>
            <Box display="flex" flexDirection="column" gridGap={12}>
              <Button
                color="primary"
                onClick={clickEqualiseSuggestedValueHandler}
                variant="contained"
              >
                Equalise
              </Button>
              <PortOptAssetsSuggestedValueScaleButton
                assetsArray={assetsArray}
                watchedAssets={watchedAssets}
              />
            </Box>
          </Grid>
          {enableGroupConstraints && <Grid item xs></Grid>}
          <Grid item xs style={{ flexGrow: 0 }}>
            {assetsArray.fields.length > 1 && (
              <div style={{ width: '48px' }}></div>
            )}
          </Grid>
        </PortOptGridRow>
      </PortOptGridContainer>
    </PortOptInputLayout>
  )
}

export default PortOptAssetsInput
