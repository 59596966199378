import { useMemo } from 'react';
import { useSecurities } from './useSecurities';
import { classOrder } from './useProcessedInsightArticle';
import { usePrivateEquityFunds } from './usePrivateEquityData';

const useAPFUnderlyingFundIdClassMap = () => {
  const { data: peFunds } = usePrivateEquityFunds()
  return useMemo(() => {
    const map: { [key: number]: number[] } = {};

    peFunds?.forEach((fund) => {
      const underlyingFundId = fund?.underlying_fund?.id

      if (underlyingFundId) {
        if (!map[underlyingFundId]) {
          map[underlyingFundId] = [];
        }

        map[underlyingFundId] = fund.id
      }
    });

    return map;
  }, [peFunds]);
};

export default useAPFUnderlyingFundIdClassMap;