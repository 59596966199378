import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

import { fetchedAllSecurities } from "./sharedActions"
import { RootState } from "./store"

export const hedgeFundsAdapter = createEntityAdapter()
const initialState = hedgeFundsAdapter.getInitialState()

export const hedgeFundsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchedAllSecurities, (state, action: any) => {
      hedgeFundsAdapter.upsertMany(state, action.payload.hedgeFunds)
    })
  },
  initialState,
  name: 'hedgeFunds',
  reducers: {

  }
})

export const {
  selectAll: selectAllHedgeFunds,
  selectById: selectHedgeFundById,
  selectEntities: selectHedgeFundEntities,
  selectIds: selectHedgeFundIds,
  selectTotal: selectTotalHedgeFunds,
} = hedgeFundsAdapter.getSelectors((state: RootState) => state.hedgeFunds)

const hedgeFundsReducer = hedgeFundsSlice.reducer
export default hedgeFundsReducer