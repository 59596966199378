import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import SkeletonTextLoader from '../../components/skeletons/SkelatonTextLoader'

interface Faq {
  question: string
  answer: string
}

interface FaqsBlockProps {
  faqs: Faq[]
  header?: string
  loading?: boolean
}

export const EmptyFaqMessage: React.FC = () => (
  <Card style={{ width: '100%' }}>
    <Box mt={2} p={4} textAlign="center" width="100%">
      <Typography>There is no FAQs for this fund.</Typography>
    </Box>
  </Card>
)

const FaqsBlock: React.FC<FaqsBlockProps> = ({ faqs, header, loading }) => {
  return loading ? (
    <Box mb={2} style={{ width: '100%' }}>
      {header && <Typography variant="h6">{header}</Typography>}
      <Box mt={1}>
        {Array(3)
          .fill(null)
          ?.map((_, idx: number) => (
            <Accordion key={idx}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <SkeletonTextLoader my={0} rows={1} style={{ width: '100%' }} />
              </AccordionSummary>
              <AccordionDetails>
                <SkeletonTextLoader my={0} rows={2} style={{ width: '100%' }} />
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
    </Box>
  ) : faqs?.length > 0 ? (
    <Box mb={2} style={{ width: '100%' }}>
      {header && <Typography variant="h6">{header}</Typography>}
      <Box mt={1}>
        {faqs?.map(({ answer, question }: Faq, idx: number) => (
          <Accordion key={idx}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">{question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div dangerouslySetInnerHTML={{ __html: answer }} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  ) : null
}

export default FaqsBlock
