import React, { Component } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { IndexMonitor } from "../monitors";
import qs from "qs";

export class monitors extends Component {
  componentDidMount() {
    const { search } = this.props.location;
    const query = qs.parse(search.replace("?", ""));
    const { view } = query || {};
    if (view) {
      this.setState({ current_view: view });
    }
  }

  state = {
    current_view: "index_monitor",
  };

  changeView = (e, new_view) => {
    this.setState({ current_view: new_view });
  };

  renderTab = () => {
    const { current_view } = this.state;
    switch (current_view) {
      case "index_monitor":
        return <IndexMonitor />;
      default:
        break;
    }
  };
  render() {
    const { current_view } = this.state;
    return (
      <div className="monitor-route">
        <Tabs
          indicatorColor="primary"
          value={current_view}
          onChange={this.changeView}
        >
          <Tab label="Index Monitor" value="index_monitor" />
        </Tabs>

        <div>{this.renderTab()}</div>
      </div>
    );
  }
}

export default monitors;
