import React, { Component } from 'react'
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from '@progress/kendo-react-charts'
import Typography from '@material-ui/core/Typography'
import { formatNumber } from '@telerik/kendo-intl'
import { cloneDeep } from 'lodash'
import { antarctica_palette } from '../../data/palette'

export default class allocation extends Component {
  renderTooltip = (context) => {
    const { format } = this.props
    const { category, value } = context.point || context
    const formatted_value = formatNumber(value, format)
    return (
      <div style={{ color: 'white' }}>
        {category}: {formatted_value}
      </div>
    )
  }

  applySeriesColors = (series) => {
    if (!series) {
      return []
    }
    const updated_series = cloneDeep(series)
    return updated_series.map((entry, idx) => {
      entry.color = antarctica_palette[idx]
      return entry
    })
  }

  renderPieNames = (e) => {
    const { category, value } = e
    const formatted = formatNumber(value, 'p1')
    return `${category}\n${formatted}`
  }

  render() {
    const { security_details, format } = this.props
    const { portfolio_weights } = security_details
    // Find the most recent weight_date
    const mostRecentDate = portfolio_weights?.reduce(
      (latestDate, currentObj) => {
        const currentDate = new Date(currentObj.weight_date)
        return !latestDate || currentDate > latestDate
          ? currentDate
          : latestDate
      },
      null,
    )

    // Filter items with the most recent weight_date
    const mostRecentWeights = portfolio_weights?.filter(
      (item) =>
        new Date(item.weight_date).getTime() === mostRecentDate.getTime(),
    )
    const colored_weights = this.applySeriesColors(mostRecentWeights)

    if (mostRecentWeights) {
      return (
        <div className="portfolio-weights-chart">
          <div className="chart-container">
            <Typography align="center" variant="h6">
              Portfolio Allocation
            </Typography>
            <Chart title="Portfolio Allocation">
              {/* <ChartTooltip render={this.renderTooltip} /> */}

              <ChartLegend visible={false} />
              <ChartSeries>
                <ChartSeriesItem
                  categoryField="security_name"
                  data={colored_weights}
                  field="weight"
                  labels={{
                    visible: true,
                    format: `{0:${format}}`,
                    content: this.renderPieNames,
                  }}
                  type="pie"
                />
              </ChartSeries>
            </Chart>
          </div>
        </div>
      )
    }
    return null
  }
}
