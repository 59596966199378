import convertObjectKeysToSnakeCase from '../utils/convertObjectKeysToSnake'
import objectToQueryString from '../utils/objectToQueryString'
import { del, get, post } from './api'

export const getCompanies = () => get('/auth/companies/')
export const deleteCompany = (payload) => del('/auth/companies/', payload)
export const updateCompanies = (payload) => post('/auth/companies/', payload)

export const getCountries = () => get('/auth/countries/')

export const getGroups = () => get('/auth/access-groups/')
export const updateGroups = (payload) => post('/auth/access-groups/', payload)

export const getPermissions = () => get('/auth/permissions/')

export const getMailingList = (payload) => get('/api/v1/mailing-list/', payload)
export const updateMailingList = (payload) => post('/api/v1/mailing-list/', payload)

export const watermarkDocument = (payload) => post('/api/v1/watermark/files/', payload, '', { responseType: 'blob' })

export const unlockDocument = (payload) => post('/api/v1/watermark/decrypt/', payload, '', { responseType: 'blob' })

export const requestAccess = (payload) => post('/auth/request-access/', payload)

export const getImageList = (payload) => get('/api/v1/image-repository/', payload)

export const uploadPicture = (payload) => post('/api/v1/image-repository/', payload)

export const deletePicture = (payload) => del('/api/v1/image-repository/', payload)

export const getDocumentList = (payload) => get('/auth/documents/', payload)

export const deleteUserDocument = (payload) => del('/auth/documents/', payload)

export const uploadDocuments = (payload) => post('/auth/documents/bulk/', payload)

export const getMyDocumentsList = (payload) => get('/auth/my-documents/', payload)

export const downloadMyDocument = (id) => get(`/auth/my-documents/${id}/download/`, {}, { responseType: 'blob' })

export const getAvailableBooklets = (payload) => get('/api/v1/available-booklets/', payload)
export const addToAvailableBooklets = (payload) => post('/api/v1/available-booklets/', payload)
export const deleteAvailableBooklets = (payload) => del('/api/v1/available-booklets/', payload)
export const downloadWatermarkedBooklet = (payload) => get('/api/v1/available-booklets/download/', payload, {
  responseType: 'blob',
})
export const downloadUnwatermarkedBooklet = (payload) => get('/api/v1/available-booklets/clean/', payload, {
  responseType: 'blob',
})

export const getIrDocs = (payload) => get('/api/v1/ir-docs/', payload)
export const uploadIrDocs = (payload) => post('/api/v1/ir-docs/', payload)
export const deleteIrDocs = (payload) => del('/api/v1/ir-docs/', payload)

export const downloadPrivateEquityDocs = (payload) => get('/api/v1/private-equity-docs/download/', payload, {
  responseType: 'blob',
})

export const getPrivateEquityData = () => get('/api/v1/private-equity/')
export const getAppConstants = () => get('/api/v1/constants/')
export const getPrivateEquityDocsById = (id) => get(`/api/v1/private-equity-documents/${id}/docs/`)
export const downloadPeDocsByDocId = (id) => get(`/api/v1/private-equity-documents/${id}/download/`, {}, { responseType: 'blob' })
export const getStoriesNotifications = (params) => get(`/api/v1/stories/notifications/?${objectToQueryString(params)}`)
export const updateStoriesNotifications = (status, payload) => post(`/api/v1/stories/notifications/${status}/`, payload)
export const getTradingCalendarCalculationResult = (params) => get('/api/v1/trading-calendar/', convertObjectKeysToSnakeCase(params))
export const getFactsheetAvailability = () => get('/api/v1/stories/notifications/factsheet-availability/?detailed=false')
export const managePopupStatus = (payload) => post('/auth/user/popup-tracker/', payload)