import { Button } from '@material-ui/core'
import React from 'react'
import { FieldValues, UseFieldArrayReturn } from 'react-hook-form'

interface PortOptAssetsSuggestedValueScaleButtonProps {
  assetsArray: UseFieldArrayReturn<FieldValues, 'assets', 'id'>
  watchedAssets: any[]
}

const PortOptAssetsSuggestedValueScaleButton: React.FC<PortOptAssetsSuggestedValueScaleButtonProps> =
  ({ assetsArray, watchedAssets = [] }) => {
    const clickScaleSuggestedValueHandler = () => {
      const sum = watchedAssets.reduce(
        (acc, asset) => acc + +asset.suggestedValue,
        0,
      )

      watchedAssets.forEach((asset, index) => {
        const suggestedValue = asset.suggestedValue
        if (suggestedValue) {
          assetsArray.update(index, {
            ...asset,
            suggestedValue: (suggestedValue / sum) * 100,
          })
        }
      })
    }

    return (
      <Button
        color="primary"
        onClick={clickScaleSuggestedValueHandler}
        variant="contained"
      >
        Scale to 100%
      </Button>
    )
  }

export default PortOptAssetsSuggestedValueScaleButton
