import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

import { fetchedAllSecurities } from "./sharedActions"
import { RootState } from "./store"

export const aaapAdapter = createEntityAdapter()
const initialState = aaapAdapter.getInitialState()


export const aaapSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchedAllSecurities, (state, action: any) => {
      aaapAdapter.upsertMany(state, action.payload.aaap)
    })
  },
  initialState,
  name: 'aaap',
  reducers: {

  }
})

export const {
  selectAll: selectAllAaaps,
  selectById: selectAaapById,
  selectEntities: selectAaapEntities,
  selectIds: selectAaapIds,
  selectTotal: selectTotalAaaps,
} = aaapAdapter.getSelectors((state: RootState) => state.aaap)

const aaapReducer = aaapSlice.reducer
export default aaapReducer