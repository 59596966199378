import React, { Component } from "react";
import { statefulTableGenerator } from "../../components/tables";
import {
  Grid as KGrid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  flows_list,
  terms_button_list,
  manager_terms,
  investor_terms,
} from "../../data/flows";

const SuperGrid = statefulTableGenerator(KGrid);

export default class termsTab extends Component {
  state = {
    current_view: "manager_terms",
  };

  changeView = (new_view) => {
    this.setState({ current_view: new_view });
  };

  generateColumn = (idx, title, field) => {
    return <Column key={idx} title={title} field={field} />;
  };

  renderColumns = () => {
    const { current_view } = this.state;
    let field_list;
    switch (current_view) {
      case "manager_terms":
        field_list = [...flows_list.manager_terms_col_list];
        break;
      case "investor_terms":
        field_list = [...flows_list.investor_terms_col_list];
        break;
      default:
        field_list = [];
        break;
    }
    return field_list.map((entry, idx) => {
      return this.generateColumn(
        entry,
        entry.title,
        entry.field,
        idx,
        entry.cell
      );
    });
  };

  generateButtonGroup = () => {
    const { current_view } = this.state;
    return terms_button_list.map((btn, idx) => {
      const color = current_view === btn.id ? "primary" : "default";
      return (
        <Button
          onClick={() => {
            this.changeView(btn.id);
          }}
          color={color}
          key={idx}
        >
          {btn.title}
        </Button>
      );
    });
  };

  getData = () => {
    const { current_view } = this.state;
    switch (current_view) {
      case "manager_terms":
        return manager_terms;
      case "investor_terms":
        return investor_terms;
      default:
        return [];
    }
  };

  render() {
    const data = this.getData();
    return (
      <React.Fragment>
        <div className="manager-flows-container">
          <SuperGrid
            className="manager-flows-table"
            style={{ height: "700px" }}
            data={data || []}
            pageable={false}
            filterable={false}
            scrollable="scrollable"
          >
            <GridToolbar>
              <ButtonGroup
                className="view-buttons"
                variant="contained"
                color="default"
                align="center"
              >
                {this.generateButtonGroup()}
              </ButtonGroup>
            </GridToolbar>
            {this.renderColumns()}
          </SuperGrid>
        </div>
      </React.Fragment>
    );
  }
}
