export default function format_correlation(value) {
  if (value <= -0.95) {
    return { backgroundColor: "#68BB80" };
  }

  if (-0.95 < value && value <= -0.9) {
    return { backgroundColor: "#6AC17C" };
  }

  if (-0.9 < value && value <= -0.8) {
    return { backgroundColor: "#79C67D" };
  }

  if (-0.8 < value && value <= -0.7) {
    return { backgroundColor: "#8DCB7C" };
  }

  if (-0.7 < value && value <= -0.6) {
    return { backgroundColor: "#9BD176" };
  }

  if (-0.6 < value && value <= -0.5) {
    return { backgroundColor: "#ADD37E" };
  }

  if (-0.5 < value && value <= -0.4) {
    return { backgroundColor: "#BFD683" };
  }

  if (-0.4 < value && value <= -0.3) {
    return { backgroundColor: "#CFDB80" };
  }

  if (-0.3 < value && value <= -0.2) {
    return { backgroundColor: "#DDE27C" };
  }

  if (-0.2 < value && value <= -0.1) {
    return { backgroundColor: "#EEE87F" };
  }

  if (-0.1 < value && value < 0) {
    return { backgroundColor: "#FEEB84" };
  }

  if (value === 0) {
    return { backgroundColor: "#FEEB84" };
  }

  if (0 < value && value <= 0.1) {
    return { backgroundColor: "#FDDD83" };
  }

  if (0.1 < value && value <= 0.2) {
    return { backgroundColor: "#EEE87F" };
  }

  if (0.2 < value && value <= 0.3) {
    return { backgroundColor: "#FFD387" };
  }

  if (0.3 < value && value <= 0.4) {
    return { backgroundColor: "#F7B176" };
  }

  if (0.4 < value && value <= 0.5) {
    return { backgroundColor: "#F8A677" };
  }

  if (0.5 < value && value <= 0.6) {
    return { backgroundColor: "#FC9972" };
  }

  if (0.6 < value && value <= 0.7) {
    return { backgroundColor: "#FA8C73" };
  }
  if (0.7 < value && value <= 0.8) {
    return { backgroundColor: "#F77D70" };
  }

  if (0.8 < value && value <= 0.9) {
    return { backgroundColor: "#F46F6C" };
  }
  if (0.9 < value) {
    return { backgroundColor: "#F56B69" };
  } else {
    return {};
  }
}
