import React from "react";
import { FormContainer, DatePicker } from "../../components/forms";
import { dateTimeManager as dt } from "../../utils";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Form } from "formik";

export default function Selectors({ initialValues, queryReport }) {
  const onSubmit = ({ startDate, endDate }) => {
    const payload = {
      startDate: dt.jsToDateTimeDb(startDate),
      endDate: dt.jsToDateTimeDb(endDate),
    };
    queryReport(payload);
  };

  return (
    <div className="selectors">
      <FormContainer initialValues={initialValues} onSubmit={onSubmit}>
        <Form className="selector-form">
          <div className="item">
            <Typography>Start Date: </Typography>
            <DatePicker name="startDate" />
          </div>
          <div className="item">
            <Typography>End Date: </Typography>
            <DatePicker name="endDate" />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
          >
            Refresh
          </Button>
          <div className="item"></div>
        </Form>
      </FormContainer>
    </div>
  );
}
