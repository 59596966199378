import { Box, Card, CardContent } from "@material-ui/core"
import React from "react"
import styled from "styled-components"

import {
  StyledCardTableCell,
  StyledCardTableCellType,
} from "./PrivateEquityCard/PrivateEquityCard.styled"
import TrackRecordTab from "./TrackRecordTab"

const StyledOverviewTableCell = styled(StyledCardTableCell)`
  padding-left: 6rem;
  padding-bottom: 1rem;
`

const StyledOverviewTableListSingle = styled.ul`
  padding-left: 0;
  margin: 0;
  list-style: none;
`

const OverviewTab = (fundData = {}) => {
  const { track_record: trackRecordData } = fundData
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap={12}
    >
      <Card>
        <CardContent>
          <tbody>
            <tr>
              <StyledCardTableCellType>Overview</StyledCardTableCellType>
              <StyledOverviewTableCell>{fundData.overview}</StyledOverviewTableCell>
            </tr>
            <tr>
              <StyledCardTableCellType>Geography</StyledCardTableCellType>
              <StyledOverviewTableCell>
                {fundData.geography}
              </StyledOverviewTableCell>
            </tr>
            <tr>
              <StyledCardTableCellType>Sectors</StyledCardTableCellType>
              <StyledOverviewTableCell>
                {Object.values(fundData?.sectors || {})?.map((sector) => (
                  <StyledOverviewTableListSingle>
                    <li key={sector.id}>{sector.name}</li>
                  </StyledOverviewTableListSingle>
                ))}
              </StyledOverviewTableCell>
            </tr>
          </tbody>
        </CardContent>
      </Card>
      <TrackRecordTab {...fundData} />
    </Box>
  )
}

export default OverviewTab
