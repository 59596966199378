import { Box, Button } from '@material-ui/core'
import React from 'react'
import { factSheetsUpdateMode } from './FactSheetsUpdateDialog'
import { useMixpanel } from '../../hooks/useMixpanel'

const BookletActions = ({ disableActions, openBookletDialoigHandler, booklets }) => {
  const { mpTrack } = useMixpanel()

  const clickUserGuideHanmdler = () => {
    mpTrack({
      eventName: 'Clicked User Guide',
      properties: {
        'Booklet Count': booklets?.length || 0,
      },
    })
  }

  return (
    <Box
      display="flex"
      gridGap={12}
      justifyContent="flex-start"
      mb={2}
    >
      <Button
        color="primary"
        disabled={disableActions}
        onClick={openBookletDialoigHandler(factSheetsUpdateMode.newList)}
        variant="contained"
      >
        Create a booklet
      </Button>
      <Button
        className="user-guide-button"
        color="primary"
        href="/fact-sheets/user-guide"
        onClick={clickUserGuideHanmdler}
        target="_blank"
        variant="contained"
      >
        User Guide
      </Button>
    </Box>
  )
}

export default BookletActions
