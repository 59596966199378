import React, { Component } from "react";
import { GenericTable } from "../../components";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

export default class downside extends Component {
  render() {
    const { internal_analysis, narrow } = this.props;
    const { report } = internal_analysis || {};
    const { summary, description, fund_name } = report || {};
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12} lg={narrow || 6}>
            <GenericTable
              table_name="Model"
              data={summary}
              index_col="index"
              index_width={`${250}px`}
              equal_width
              // index_auto
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {fund_name} Downside Assessment
            </Typography>
          </Grid>
          <Grid item xs={12} lg={narrow || 6}>
            <div
              className="downside-description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
