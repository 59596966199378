import { useMutation } from '@tanstack/react-query'

import { updatePortfolioOptimiserRun } from '../../../services'
import { usePortfolioOptimiser } from '../RefactoredPortfolioOptimiserProvider'

const useUpdatePortfolioOptimiserRun = () => {
  const { setRunDataResponse } = usePortfolioOptimiser()

  const {
    isLoading: isUpdatingRun,
    mutateAsync: updateRun,
  } = useMutation(
    ({ payload, runId }) => updatePortfolioOptimiserRun(runId, payload),
    {
      onSuccess: (res) => {
        if (setRunDataResponse) {
          setRunDataResponse({ data: res })
        }
      },
    },
  )

  return {
    isUpdatingRun,
    updateRun,
  }
}

export default useUpdatePortfolioOptimiserRun
