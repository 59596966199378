import React, { useState } from "react";
// import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { TextInput } from "../../components/forms";
import { setNewPassword } from "../../services";
import { toast } from "react-toastify";
import { passwordValidation } from "./../../components/forms/validation";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  current_password: passwordValidation,
  new_password: passwordValidation,
  re_new_password: Yup.string().oneOf(
    [Yup.ref("new_password"), null],
    "Passwords must match",
  ),
});

export default function Info() {
  const [success, setSuccess] = useState(false);
  const updatePassword = async (data, { resetForm }) => {
    setSuccess(true);
    try {
      await setNewPassword(data);
      resetForm();
    } catch (e) {
      toast.error(
        "Something went wrong. Please refresh the page and try again",
      );
      setSuccess(false);
    }
  };

  return (
    <div className="user-info">
      <Typography variant="h6" align="center">
        Password update
      </Typography>
      <Formik
        initialValues={{
          current_password: "",
          new_password: "",
          re_new_password: "",
        }}
        validateOnChange={true}
        validationSchema={validationSchema}
        onSubmit={updatePassword}
      >
        {() => (
          <Form>
            <TextInput
              name="current_password"
              placeholder="Current Password"
              type="password"
            />
            <TextInput
              name="new_password"
              placeholder="New Password"
              type="password"
            />
            <TextInput
              name="re_new_password"
              placeholder="Repeat Password"
              type="password"
            />
            <Button
              disabled={success}
              variant="contained"
              color="primary"
              type="submit"
            >
              {success ? "Password updated" : "Update Password"}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
