/* eslint-disable react/display-name */
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React, { useEffect, useMemo } from 'react'

import { useConstants } from '../../contexts'
import useExpandCollapse from '../../hooks/useExpandCollapse'
import { TradingCalendarDayType } from './models'
import TradingCalendarLastFormValueChips from './TradingCalendarLastFormValueChips'
import { useTradingCalendar } from './TradingCalendarProvider'
import TradingCalendarTableRow from './TradingCalendarTableRow'


const TradingCalendarResult = () => {
  const constants = useConstants()
  const {
    isLoadingTradingCalendarResult,
    lastFormValues,
    resultDayType,
    tradingCalendarResult
  } = useTradingCalendar()
  const {
    expandAll,
    isExpand,
    reset,
    toggleExpandAll,
    toggleRow
  } = useExpandCollapse({
    numberOfElements: tradingCalendarResult?.length
  })
  const countryCodeNameMap = useMemo(() => {
    const tradingCalendarCountryInfo = constants?.TRADING_CALENDAR_COUNTRY_INFORMATION
    if (!tradingCalendarCountryInfo) return null

    const codeNameMap: Record<string, string> = {}

    tradingCalendarCountryInfo.forEach(({ label, value }: { label: string, value: string }) => {
      codeNameMap[value] = label
    })

    return codeNameMap
  }, [constants])

  useEffect(() => {
    if (tradingCalendarResult) {
      reset()
    }
  }, [tradingCalendarResult])

  return !isLoadingTradingCalendarResult && tradingCalendarResult?.length > 0
    ? (
      <Box
        id="result-section"
        maxWidth="1000px"
        mt={4}
        mx="auto"
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h6">
            Business Days Calendar
          </Typography>
          <Button
            color="primary"
            onClick={toggleExpandAll}
            variant="contained"
          >
            {expandAll ? 'Collapse All' : 'Expand All'}
          </Button>
        </Box>
        <TradingCalendarLastFormValueChips data={lastFormValues} />
        <Box bgcolor="white">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="200px">
                  <Box
                    alignItems="center"
                    display="flex"
                    gridGap={4}
                  >
                    <span>
                      Target Date
                    </span>
                    {
                      resultDayType === TradingCalendarDayType.BUSINESS_DAYS && (
                        <Tooltip title="Closest Business Date to Target Date">
                          <InfoOutlinedIcon fontSize="small" />
                        </Tooltip>
                      )
                    }
                  </Box>
                </TableCell>
                <TableCell>Notice Date</TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: '70%'
                  }}
                >
                  Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                tradingCalendarResult?.map((result, rowIdx) => (
                  <TradingCalendarTableRow
                    countryCodeNameMap={countryCodeNameMap}
                    data={result}
                    index={rowIdx}
                    key={rowIdx}
                    open={result?.reasons?.length > 0 && isExpand?.[rowIdx]}
                    toggleOpen={toggleRow}
                  />
                ))
              }
            </TableBody>
          </Table>
        </Box>
      </Box>
    )
    : null
}

export default TradingCalendarResult
