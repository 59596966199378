import React, { Component } from "react";
import { GenericTable } from "../../components";
import Grid from "@material-ui/core/Grid";
import { RegressionChart } from "../../components";

export default class regression extends Component {
  getTable = (name, width = 200) => {
    const { internal_analysis } = this.props;
    const { report } = internal_analysis || {};
    return (
      <GenericTable
        table_name={name}
        data={report[name]}
        index_width={`${width}px`}
        equal_width
        // index_auto
      />
    );
  };

  render() {
    const { getTable } = this;
    const tables = ["Regression Output", "Alpha", "Statistics"];
    const { internal_analysis, narrow } = this.props;
    const { report } = internal_analysis || {};
    const { Scatter } = report || {};
    return (
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} lg={narrow || 10} xl={narrow || 8}>
          {getTable("Funds")}
        </Grid>
        {tables.map((table, id) => (
          <Grid item xs={12} lg={narrow || 10} xl={narrow || 8} key={id}>
            {getTable(table)}
          </Grid>
        ))}
        {Scatter && (
          <Grid item xs={12} lg={narrow || 10} xl={narrow || 8}>
            <RegressionChart data={report["Scatter"]} />
          </Grid>
        )}
      </Grid>
    );
  }
}
