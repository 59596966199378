import React, { Component } from "react";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import { MonthlyDatePicker } from "../../components";
import Button from "@material-ui/core/Button";

const CustomPercentEditor = Handsontable.editors.TextEditor.prototype.extend();

CustomPercentEditor.prototype.setValue = function (value) {
  this.TEXTAREA = document.createElement("input");
  this.TEXTAREA.value = parseFloat(value) * 100;
  this.TEXTAREA.className = "handsontableInput";
  this.textareaStyle = this.TEXTAREA.style;
  this.textareaStyle.width = 0;
  this.textareaStyle.height = 0;
  Handsontable.dom.empty(this.TEXTAREA_PARENT);
  this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
};

export default class hotPerformance extends Component {
  createColumns = () => {
    const { editable_columns, index_name } = this.props;
    const index_col = [{ data: index_name, editor: false, readOnly: true }];

    const column_list = editable_columns.map((name) => ({
      data: name.field,
      type: "numeric",
      numericFormat: {
        pattern: "0.00%",
        culture: "en-US",
      },
      editor: CustomPercentEditor,
    }));
    return index_col.concat(column_list);
  };
  renderControlPanel = () => {
    const { inception_date, updateStartDate, resetInceptionDate } = this.props;
    const { fetched } = this.props;
    const inception_invalid = !inception_date;
    return (
      <div className="control-panel">
        <MonthlyDatePicker
          value={inception_date}
          onChange={updateStartDate}
          disalbed={inception_invalid}
        />
        <Button
          onClick={resetInceptionDate}
          variant="contained"
          color="default"
          className="reset-button"
          disabled={!fetched}
        >
          Reset start date
        </Button>
      </div>
    );
  };

  handleHotChange = (changes, source) => {
    const { onDataChange, data } = this.props;
    let data_slice = data.slice();

    if (source === "edit") {
      let change = changes[0];
      let id = change[0];
      let field = change[1];
      let new_val = change[3];
      new_val = this.trimAndReplace(new_val, change[2]);

      data_slice[id][field] = new_val;
      onDataChange(data_slice);
    }
  };

  trimAndReplace = (new_val, default_val) => {
    if (typeof new_val === "number") {
      return new_val / 100;
    } else if (new_val === "" || new_val === null) {
      return null;
    } else if (new_val.indexOf("%") > 0) {
      new_val = new_val.replace(/\s/g, "");
      new_val = new_val.replace(/\n/g, "");
      new_val = new_val.replace("%", "");
      new_val = parseFloat(new_val) / 100;
      return new_val;
    } else {
      return default_val;
    }
  };

  beforePaste = (data, coords) => {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        data[i][j] = this.trimAndReplace(data[i][j], 0);
      }
    }
  };

  render() {
    const {
      editable_columns,
      index_name,
      updateAction,
      fetched,
      updating,
      updated,
      ...props
    } = this.props;
    const columns = this.createColumns();
    const headers = ["Date"].concat(editable_columns.map((o) => o.title));
    let update_button_text = "Update Performance";
    if (updating) {
      update_button_text = "Updating";
    } else if (updated) {
      update_button_text = "Updated";
    }
    return (
      <div className="hot-performance">
        {this.renderControlPanel()}
        <div className="table-container">
          <HotTable
            className="hot-performance-table"
            columns={columns}
            colHeaders={headers}
            height="700"
            stretchH="all"
            afterChange={(change, source) =>
              this.handleHotChange(change, source)
            }
            beforePaste={(data, coord) => this.beforePaste(data, coord)}
            {...props}
          />
        </div>
        <div className="submit-container">
          <Button
            className="update-button"
            variant="contained"
            color="primary"
            disabled={!fetched || updating || updated}
            onClick={updateAction}
          >
            {update_button_text}
          </Button>
        </div>
      </div>
    );
  }
}
