import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  TextInput as TextForm,
  DropdownForm,
  SwitchForm,
  Combobox,
} from "../../components";
import Button from "@material-ui/core/Button";
import {
  getCountries,
  getCompanies,
  getGroups,
  updateUser,
  registerUser,
} from "../../services";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import passwordGenerator from "../../utils/idGenerator";
import { sortBy } from "lodash";

const styles = {
  submitButon: {
    marginTop: "24px",
  },
  backButton: {
    marginTop: "36px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  successScreen: {
    display: "flex",
    flexDirection: "column",
  },
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  title: Yup.string().label("Title").required(),
  first_name: Yup.string().required().label("First Name"),
  last_name: Yup.string().required().label("Last Name"),
  company: Yup.string().label("Company").required().nullable(),
  country: Yup.string().label("Country").required().nullable(),
  address: Yup.string().label("Address").nullable(),
  telephone: Yup.string().label("Telephone").nullable(),
  job_title: Yup.string().label("Job Title").nullable(),
  is_active: Yup.bool().label("Active"),
  has_signed_nda: Yup.bool().label("Signed NDA"),
});

const titles = ["Mr", "Mrs", "Dr"];

export default function EditForm({
  selectedUser,
  setUserList,
  userList,
  setSelectedUser,
}) {
  const alert = useAlert();
  const [companies, setCompanies] = useState([]);
  const [groups, setGroups] = useState([]);
  const [countries, setCountries] = useState([]);
  const [userUpdating, setUserUpdating] = useState(false);

  useEffect(() => {
    getLists();
  }, []);

  const saveEdits = async (data, formControl) => {
    let response, user;
    setUserUpdating(true);
    try {
      const { id, ...payload } = data || {};
      let newUserList = [...userList];
      if (id) {
        response = await updateUser(data);
        user = response.data;
        const index = newUserList.indexOf(user);
        newUserList[index] = user;
      } else {
        response = await registerUser({
          ...payload,
          password: passwordGenerator(12),
        });
        user = response.data;
        newUserList.push(user);
      }
      setSelectedUser(user);
      setUserList(newUserList);
      setUserUpdating(false);
    } catch (error) {
      alert.error("You request could not be sent");
      setUserUpdating(false);
    }
  };

  const getLists = async () => {
    Promise.all([getCountries(), getCompanies(), getGroups()]).then(function ([
      countries,
      companies,
      groups,
    ]) {
      setCountries(countries.data.map((o) => o.name));
      setCompanies(companies.data.map((o) => o.name));
      setGroups(sortBy(groups.data.map((o) => o.name)));
    });
  };

  const { id, ...userProps } = selectedUser;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: id,
        email: "",
        title: null,
        first_name: "",
        last_name: "",
        company: null,
        address: "",
        telephone: "",
        job_title: "",
        groups: [],
        is_active: true,
        has_signed_nda: false,
        country: null,
        ...userProps,
      }}
      onSubmit={saveEdits}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      style={styles.form}
    >
      {({ handleSubmit }) => (
        <Form className="access-form">
          {!id && <TextForm name="email" placeholder="Email" />}
          <TextForm name="first_name" placeholder="First Name" />
          <TextForm name="last_name" placeholder="Last Name" />
          <DropdownForm defaultItem="Title" name="title" placeholder="Title" items={titles} />
          <DropdownForm
            name="company"
            placeholder="Company"
            items={companies}
            defaultItem={"Select company..."}
          />
          <DropdownForm
            name="country"
            placeholder="Country"
            items={countries}
            defaultItem={"Select country..."}
          />
          <TextForm name="address" placeholder="Address" />
          <TextForm name="telephone" placeholder="Phone" />
          <TextForm name="job_title" placeholder="Job Title" />
          {id && (
            <Combobox name="groups" placeholder="Groups" items={groups} multi />
          )}
          <SwitchForm name="is_active" label="Active" />
          <SwitchForm name="has_signed_nda" label="Signed NDA" />
          <SwitchForm
            name="has_access_to_research"
            label="Access to Research"
          />
          <Button
            fullWidth
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            style={styles.submitButon}
            disabled={userUpdating}
          >
            {userUpdating ? "Saving..." : "Save"}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
