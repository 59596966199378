import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  TextInput as TextForm,
  PhoneForm,
} from "../../components";
import Button from "@material-ui/core/Button";
import { getCountries, requestAccess } from "../../services";
import * as Yup from "yup";
import { IconButton, InputAdornment, Typography } from "@material-ui/core";
import { useAlert } from "react-alert";
import { InputFieldsWrapper, NewSubmitButton, RequestAccessTextWrapper, StyledForm } from "./styles";
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';
import NewSelect from "../../components/forms/NewSelect";

const styles = {
  submitButton: {
    marginTop: "24px",
  },
  backButton: {
    marginTop: "36px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  successScreen: {
    display: "flex",
    flexDirection: "column",
  },
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  title: Yup.string().label("Title").required(),
  first_name: Yup.string().required().label("First Name"),
  last_name: Yup.string().required().label("Last Name"),
  company_name: Yup.string().label("Company").required(),
  country: Yup.string().label("Country").required(),
  address: Yup.string().label("Address"),
  telephone: Yup.string().label("Telephone").required(),
  job_title: Yup.string().label("Job Title"),
});

const titles = ["Mr", "Mrs", "Ms", "Dr"];

export default function RequestAccess({ setView }) {
  const alert = useAlert();

  const submitRequest = async (data, formControl) => {
    setAccessRequested(true);
    try {
      const response = await requestAccess(data);
      setAccessRequested(response.status === 200);
    } catch (error) {
      alert.error("You request could not be sent");
      setAccessRequested(false);
    }
  };

  const getCountryList = async () => {
    try {
      const response = await getCountries();
      setCountries(response.data.map((o) => o.name));
    } catch (error) { }
  };

  const [countries, setCountries] = useState([]);
  const [accessRequested, setAccessRequested] = useState(false);

  useEffect(() => {
    getCountryList();
  }, []);

  if (accessRequested) {
    return (
      <div style={styles.successScreen}>
        <Typography variant="body1" align="center">
          Thank you for your interest. We will get back to you soon.
        </Typography>
        <Button
          onClick={() => setView("default")}
          style={styles.backButton}
          variant="outlined"
          color="primary"
        >
          Back to login
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          title: "",
          first_name: "",
          last_name: "",
          company_name: "",
          address: "",
          telephone: "",
          job_title: "",
          country: "",
        }}
        onSubmit={submitRequest}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        style={styles.form}
      >
        {({ handleSubmit }) => (
          <StyledForm className="access-form">
            <InputFieldsWrapper>

              <TextForm
                name="email"
                placeholder="Email"
                disableUnderline
                isNew
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton disabled>
                      <EmailIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <TextForm
                name="first_name"
                placeholder="First Name"
                disableUnderline
                isNew
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton disabled>
                      <PersonIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <TextForm name="last_name" placeholder="Last Name"
                disableUnderline
                isNew
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton disabled>
                      <PersonIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <NewSelect name="title" placeholder="Title" items={titles} />
              <TextForm name="company_name" placeholder="Company"
                disableUnderline
                isNew
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton disabled>
                      <BusinessIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <NewSelect name="country" placeholder="Country" items={countries} />
              <TextForm name="address" placeholder="Address" multiline isNew />
              <PhoneForm name="telephone" />
              <TextForm name="job_title" placeholder="Job Title"
                disableUnderline
                isNew
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton disabled>
                      <WorkIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </InputFieldsWrapper>
            <NewSubmitButton
              fullWidth
              onClick={handleSubmit}
            >
              Request access
            </NewSubmitButton>
            <RequestAccessTextWrapper>
              <div>
                Already have an account?
              </div>
              <Button
                fullWidth
                onClick={() => setView("default")}
                variant="text"
                color="primary"
              >
                Back to login
              </Button>
            </RequestAccessTextWrapper>
          </StyledForm>
        )}
      </Formik>
    </div>
  );
}
