import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core'
import React from 'react'

interface SelectableListProps {
  selectedItems: number[] | string[]
  setSelectedItems: (selectedItems: number[] | string[]) => void
  items: any[] | undefined | null
  itemKey: string
  itemLabel: string
  maxHeight?: number
  ListHeader?: React.ReactNode
  renderSecondaryAction?: (item: any) => React.ReactNode
}

const SelectableList: React.FC<SelectableListProps> = ({
  ListHeader,
  itemKey,
  itemLabel,
  items,
  maxHeight,
  renderSecondaryAction,
  selectedItems,
  setSelectedItems
}) => {

  const changeSelectedItemsHandler = (id: number | string) => () => {
    const currentIndex = selectedItems.indexOf(id)
    const newChecked = [...selectedItems]

    if (currentIndex === -1) {
      newChecked.push(id)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setSelectedItems(newChecked)
  }

  const clickSelectAllHandler = () => {
    if (items) {
      setSelectedItems(items?.map((it) => it.id))
    }
  }

  const clickClearHandler = () => {
    setSelectedItems([])
  }

  return (
    <Box>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={1}
        p={2}
      >
        {ListHeader}
        <Box
          display="flex"
          gridGap={12}
          justifyContent="flex-end"
        >
          <Button
            color="primary"
            onClick={clickSelectAllHandler}
            variant="contained"
          >Select All</Button>
          <Button
            color="primary"
            onClick={clickClearHandler}
            variant="contained"
          >Clear</Button>
        </Box>
      </Box>
      <Box
        bgcolor="white"
        style={{
          maxHeight: maxHeight || 'unset',
          overflowY: 'auto'
        }}
      >
        <List >
          {items?.map((item) => (
            <ListItem
              button
              dense
              key={item[itemKey]}
              onClick={changeSelectedItemsHandler(item[itemKey])}
              role={undefined}
            >
              <ListItemIcon>
                <Checkbox
                  checked={selectedItems.indexOf(item[itemKey]) !== -1}
                  edge="start"
                  size="small"
                  tabIndex={-1}
                />
              </ListItemIcon>
              <ListItemText primary={item[itemLabel]} />
              {
                renderSecondaryAction && (
                  <ListItemSecondaryAction>
                    {renderSecondaryAction(item)}
                  </ListItemSecondaryAction>
                )
              }
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  )
}

export default React.memo(SelectableList)
