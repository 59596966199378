import { Box, Tooltip } from '@material-ui/core'
import React from 'react'

export const fundStatusStylesMap: { [key: string]: string } = {
  "Open": '#50e3c2',
  // eslint-disable-next-line sort-keys/sort-keys-fix
  "Closed": 'red',
  "Launch Pending": "grey",
  "Limited Capacity": "#FFBF00",
}

interface FundStatusIndicatorProps {
  status: string
}

const FundStatusIndicator: React.FC<FundStatusIndicatorProps> = ({
  status
}) => fundStatusStylesMap[status] ? (
  <Tooltip
    hidden={!fundStatusStylesMap[status]}
    placement="top"
    title={status}
  >
    <Box
      display="inline-block"
      mr={1}
      style={{
        backgroundColor: fundStatusStylesMap[status] || 'transparent',
        borderRadius: '50%',
        height: ' 8px',
        width: '8px',
      }}
    />
  </Tooltip>
) : null

export default FundStatusIndicator
