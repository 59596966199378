import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  StockChart,
  ChartSeries,
  ChartSeriesItem,
  ChartNavigator,
  ChartNavigatorSelect,
  ChartNavigatorSeries,
  ChartNavigatorSeriesItem,
  ChartAxisDefaults,
  ChartSeriesDefaults,
  ChartValueAxis,
  ChartTooltip,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
} from '@progress/kendo-react-charts'
import { extractSeriesNames, seriesRemoveUnsafeSymbols } from '../../utils'
import Typography from '@material-ui/core/Typography'
import { cumulativePerformanceCalculator } from '../../utils'
import { antarctica_palette } from '../../data'
import moment from 'moment'

const LineTimeSeries = ({
  data: unsafe_data,
  title = '',
  category_field = 'perf_date',
  minimum = false,
  format = '{0:p2}',
}) => {
  const [filter, setFilter] = useState({ start: '', end: '' })

  const determineMinimum = useCallback(() => {
    const filtered_cumulative = cumulativePerformanceCalculator({
      raw_perf: unsafe_data,
      ...filter,
    })
    const series = extractSeriesNames(unsafe_data)

    let def_min = 1000
    for (let i = 0; i < filtered_cumulative.length; i++) {
      const element = filtered_cumulative[i]
      for (let j = 0; j < series.length; j++) {
        const name = series[j]
        const value = element[name]
        if (value < def_min) {
          def_min = value
        }
      }
    }
    def_min *= 0.8

    return Math.ceil(def_min / 100) * 100
  }, [unsafe_data, filter])

  const onNavigatorChange = useCallback((event) => {
    setFilter({ start: event.from, end: event.to })
  }, [])

  const renderTooltip = useCallback((props) => {
    const { categoryText, colspan, points } = props
    const date = moment(categoryText, 'M/DD/YYYY').format('MMM YYYY')
    return (
      <table>
        <thead>
          <tr>
            <th colSpan={colspan}>{date}</th>
          </tr>
        </thead>
        <tbody>
          {points.map((element, id) => {
            const { formattedValue, series } = element || {}
            const { name, color } = series || {}
            return (
              <tr key={id}>
                <td>
                  <span
                    className="k-chart-shared-tooltip-marker"
                    style={{ backgroundColor: color }}
                  ></span>
                </td>
                <td>{name}</td>
                <td>{formattedValue}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }, [])

  const data = useMemo(
    () => seriesRemoveUnsafeSymbols(unsafe_data),
    [unsafe_data],
  )
  const series = useMemo(() => extractSeriesNames(data), [data])

  const min_number = useMemo(
    () => (minimum ? determineMinimum() : null),
    [minimum, determineMinimum],
  )

  const filtered_cumulative = useMemo(
    () =>
      cumulativePerformanceCalculator({
        raw_perf: data,
        ...filter,
      }),
    [data, filter],
  )

  const full_cumulative = useMemo(
    () =>
      cumulativePerformanceCalculator({
        raw_perf: data,
      }),
    [data],
  )

  const label_step = useMemo(
    () => (data ? Math.round(filtered_cumulative.length / 6) : 12),
    [data, filtered_cumulative],
  )

  return (
    <div className="line-ts-chart-container">
      <div className="title-container">
        <Typography align="center" variant="h6">
          {title}
        </Typography>
      </div>
      <StockChart
        key={series} // Use the key prop to force remount
        onNavigatorFilter={onNavigatorChange}
        partialRedraw={true}
        zoomable={false}
        pannable={false}
      >
        <ChartLegend visible="true" position="bottom" margin={20} />
        <ChartSeriesDefaults markers={{ visible: false }} type="line" />
        <ChartTooltip
          render={renderTooltip}
          shared={true}
          format={format}
          visible={true}
        />
        <ChartAxisDefaults
          majorGridLines={{ step: label_step / 2, visible: false }}
        />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            labels={{ step: label_step }}
            baseUnit="months"
            type="date"
          />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem
            min={min_number}
            majorGridLines={{ step: 1 }}
            labels={{ format: format }}
            axisCrossingValue={-1000}
          />
        </ChartValueAxis>
        <ChartSeries>
          {series.map((ser, id) => (
            <ChartSeriesItem
              key={id}
              data={filtered_cumulative}
              name={ser}
              field={ser}
              categoryField={category_field}
              color={antarctica_palette[id]}
            />
          ))}
        </ChartSeries>
        <ChartNavigator hint={{ visible: false }}>
          <ChartNavigatorSelect mousewheel={false} from={''} to={''} />
          <ChartNavigatorSeries>
            <ChartNavigatorSeriesItem
              data={full_cumulative}
              type="area"
              field={series[0]}
              categoryField={category_field}
              color={antarctica_palette[1]}
              tooltip={{ visible: true }}
            />
          </ChartNavigatorSeries>
        </ChartNavigator>
      </StockChart>
    </div>
  )
}

LineTimeSeries.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  category_field: PropTypes.string,
  minimum: PropTypes.bool,
  format: PropTypes.string,
}

export default LineTimeSeries
