import React, { useState, useEffect } from "react";
import { pushQuoteToWf, getLatestQuotes } from "../../services";
import { dateTimeManager as dt } from "../../utils";
import { SmartGrid } from "../../components/tables";
import { DatePicker, TextInput } from "../../components/forms";
import {
  GridColumn as Column,
  GridToolbar as ToolBar,
} from "@progress/kendo-react-grid";
import { Formik, Form } from "formik";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const ConfirmationDialog = ({ open, onCancel, onSubmit, data }) => {
  let contextValid = true;
  let { perf_date, perf_return, fundName } = data || {};
  if ((!perf_return || perf_return === null) & (perf_return !== 0)) {
    perf_return = 0;
    contextValid = false;
  }
  if (!perf_date) {
    contextValid = false;
  }
  const formattedRet = `${(perf_return * 100).toFixed(2)}%`;
  const formattedDate = dt.jsToDateTimeDb(perf_date);
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent>
        {contextValid ? (
          <DialogContentText>
            You are about to post <b>{formattedRet}</b> as of{" "}
            <b>{formattedDate}</b> for <b>{fundName}</b>. This action can only
            be reverted directly in Webfolio. Please confirm.
          </DialogContentText>
        ) : (
          <DialogContentText>
            The form you are about to post is invalid. Please check that
            performance and date fields are not empty
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!contextValid}
          onClick={onSubmit}
          color="primary"
          autoFocus
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UpdateForm = ({ onSubmit, loading, fundName }) => {
  const buttonText = loading ? "Loading..." : "Update";
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <Formik
      initialValues={{
        perf_date: dt.endOfLastWeek(),
        perf_return: null,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, validateForm }) => (
        <Form className="update-form">
          <DatePicker name="perf_date" />
          <TextInput
            width="150px"
            type="number"
            name="perf_return"
            placeholder="Performance, %"
            spinners={false}
            format="p2"
          />
          <Button
            disabled={loading}
            size="small"
            disableElevation
            variant="contained"
            color="primary"
            onClick={() => setDialogOpen(true)}
          >
            {buttonText}
          </Button>
          <ConfirmationDialog
            open={dialogOpen}
            onCancel={() => setDialogOpen(false)}
            onSubmit={(e) => {
              if (
                (values.perf_return || values.perf_return === 0) &&
                values.perf_return !== null
              ) {
                setDialogOpen(false);
                handleSubmit(e);
              }
            }}
            data={{ ...values, fundName }}
          />
        </Form>
      )}
    </Formik>
  );
};

const ExistingQuotesGrid = ({ data, ...props }) => {
  return (
    <SmartGrid
      data={data}
      style={{ height: "70vh", width: "100%" }}
      scrollable="scrollable"
      sortable={true}
    >
      <ToolBar>
        <div className="toolbar">
          <UpdateForm {...props} />
        </div>
      </ToolBar>
      <Column field="perf_date" title="Date" format="{0: dd MMM yyyy}" />
      <Column field="perf_return" title="Return" format="{0:p2}" />
    </SmartGrid>
  );
};

export default function WebfolioUpdates({ security_details }) {
  const { id, name: fundName } = security_details;
  const [existingQuotes, setExistingQuotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchExistingQuotes = async (fund_id) => {
    setLoading(true);
    if (!fund_id) {
      return;
    }
    try {
      const response = await getLatestQuotes({ fund_id });
      let { data } = response;
      for (let i = 0; i < data.length; i++) {
        let entry = data[i];
        entry.perf_date = dt.dbToJs(entry.perf_date);
        entry.perf_return = Number(entry.perf_return);
      }
      setExistingQuotes(data);
    } catch (error) { }
    setLoading(false);
  };

  const updateWebfolio = async (payload) => {
    try {
      await pushQuoteToWf(payload);
    } catch (error) { }
  };

  const handleUpdate = ({ perf_date, perf_return }, { resetForm }) => {
    setLoading(true);
    const payload = {
      perf_date: dt.jsToDateTimeDb(perf_date),
      perf_return: Number(perf_return.toFixed(4)),
      fund_id: id,
    };
    updateWebfolio(payload);
    fetchExistingQuotes(id);
    resetForm();
    setLoading(false);
  };

  useEffect(() => {
    fetchExistingQuotes(id);
  }, [id]);

  return (
    <div className="webfolio-update-route">
      <div className="grid-container">
        <Typography align="center" variant="h6">
          Latest Performance (past 3 months)
        </Typography>
        <ExistingQuotesGrid
          data={existingQuotes}
          onSubmit={handleUpdate}
          loading={loading}
          fundName={fundName}
        />
      </div>
    </div>
  );
}
