export const dummy_returns_timeseries = [
  {
    perf_date: "2009-05-31T00:00:00",
    fund_return: "0.0106",
    benchmark: "0.109038523633293",
  },
  {
    perf_date: "2009-06-30T00:00:00",
    fund_return: "0.0056",
    benchmark: "0.00612018767776758",
  },
  {
    perf_date: "2009-07-31T00:00:00",
    fund_return: "0.0158",
    benchmark: "0.0837093511747315",
  },
  {
    perf_date: "2009-08-31T00:00:00",
    fund_return: "0.0113",
    benchmark: "0.0391002632208661",
  },
  {
    perf_date: "2009-09-30T00:00:00",
    fund_return: "0.0111",
    benchmark: "0.0381171702284453",
  },
  {
    perf_date: "2009-10-31T00:00:00",
    fund_return: "0.0067",
    benchmark: "0.0184652788869367",
  },
  {
    perf_date: "2009-11-30T00:00:00",
    fund_return: "0.0019",
    benchmark: "0.0387282244139688",
  },
  {
    perf_date: "2009-12-31T00:00:00",
    fund_return: "0.0143",
    benchmark: "0.0169363190920879",
  },
  {
    perf_date: "2010-01-31T00:00:00",
    fund_return: "0.0197",
    benchmark: "0.0418752727926263",
  },
  {
    perf_date: "2010-02-28T00:00:00",
    fund_return: "0.0215",
    benchmark: "0.0111752727926263",
  },
  {
    perf_date: "2010-03-31T00:00:00",
    fund_return: "0.0567",
    benchmark: "0.0465752727926263",
  },
  {
    perf_date: "2010-04-30T00:00:00",
    fund_return: "0.1567",
    benchmark: "0.09876752727926263",
  },
  {
    perf_date: "2010-05-31T00:00:00",
    fund_return: "0.0045",
    benchmark: "0.0018752727926263",
  },
  {
    perf_date: "2010-06-30T00:00:00",
    fund_return: "0.0095",
    benchmark: "0.0008752727926263",
  },
  {
    perf_date: "2010-07-31T00:00:00",
    fund_return: "0.1234",
    benchmark: "0.099952727926263",
  },
  {
    perf_date: "2010-08-31T00:00:00",
    fund_return: "0.0267",
    benchmark: "0.0118752727926263",
  },
  {
    perf_date: "2010-09-30T00:00:00",
    fund_return: "0.0189",
    benchmark: "0.0499752727926263",
  },
  {
    perf_date: "2010-10-31T00:00:00",
    fund_return: "0.1397",
    benchmark: "0.1518752727926263",
  },
  {
    perf_date: "2010-11-30T00:00:00",
    fund_return: "0.1097",
    benchmark: "0.0918752727926263",
  },
  {
    perf_date: "2010-12-31T00:00:00",
    fund_return: "0.3197",
    benchmark: "0.3418752727926263",
  },
  {
    perf_date: "2011-01-31T00:00:00",
    fund_return: "0.0097",
    benchmark: "0.0018752727926263",
  },
  {
    perf_date: "2011-02-28T00:00:00",
    fund_return: "0.0112",
    benchmark: "0.0318752727926263",
  },
  {
    perf_date: "2011-03-31T00:00:00",
    fund_return: "0.0255",
    benchmark: "0.0418752727926263",
  },
  {
    perf_date: "2011-04-30T00:00:00",
    fund_return: "0.0307",
    benchmark: "0.0400752727926263",
  },
  {
    perf_date: "2011-05-31T00:00:00",
    fund_return: "0.0357",
    benchmark: "0.0300752727926263",
  },
  {
    perf_date: "2011-06-30T00:00:00",
    fund_return: "0.0617",
    benchmark: "0.0598752727926263",
  },
  {
    perf_date: "2011-07-31T00:00:00",
    fund_return: "0.0700",
    benchmark: "0.0434752727926263",
  },
  {
    perf_date: "2011-08-31T00:00:00",
    fund_return: "0.0997",
    benchmark: "0.0018752727926263",
  },
  {
    perf_date: "2011-09-30T00:00:00",
    fund_return: "0.0772",
    benchmark: "0.0098752727926263",
  },
  {
    perf_date: "2011-10-31T00:00:00",
    fund_return: "0.0099",
    benchmark: "0.1018752727926263",
  },
  {
    perf_date: "2011-11-30T00:00:00",
    fund_return: "0.0102",
    benchmark: "0.0008752727926263",
  },
  {
    perf_date: "2011-12-31T00:00:00",
    fund_return: "0.0597",
    benchmark: "0.0058752727926263",
  },
  {
    perf_date: "2012-01-31T00:00:00",
    fund_return: "0.0450",
    benchmark: "0.0418752727926263",
  },
  {
    perf_date: "2012-02-29T00:00:00",
    fund_return: "0.0108416667",
    benchmark: "0.0466036473821207",
  },
  {
    perf_date: "2012-03-31T00:00:00",
    fund_return: "0.0204416667",
    benchmark: "0.0102331526426018",
  },
  {
    perf_date: "2012-04-30T00:00:00",
    fund_return: "0.0060416667",
    benchmark: "0.0137346514127179",
  },
  {
    perf_date: "2012-05-31T00:00:00",
    fund_return: "0.0252583333",
    benchmark: "0.0899156871382313",
  },
  {
    perf_date: "2012-06-30T00:00:00",
    fund_return: "0.0000583333",
    benchmark: "0.0493189769369247",
  },
  {
    perf_date: "2012-07-31T00:00:00",
    fund_return: "0.0140416667",
    benchmark: "0.0120172854692",
  },
  {
    perf_date: "2012-08-31T00:00:00",
    fund_return: "0.0222416667",
    benchmark: "0.0229015568900581",
  },
  {
    perf_date: "2012-09-30T00:00:00",
    fund_return: "-0.0074583333",
    benchmark: "0.0252421416342898",
  },
  {
    perf_date: "2012-10-31T00:00:00",
    fund_return: "0.0097416667",
    benchmark: "-0.00760960731986282",
  },
  {
    perf_date: "2012-11-30T00:00:00",
    fund_return: "0.0275416667",
    benchmark: "0.0107336037863421",
  },
  {
    perf_date: "2012-12-31T00:00:00",
    fund_return: "0.0134416667",
    benchmark: "0.0174915810838547",
  },
  {
    perf_date: "2013-01-31T00:00:00",
    fund_return: "0.0140416667",
    benchmark: "0.0500336197235711",
  },
  {
    perf_date: "2013-02-28T00:00:00",
    fund_return: "0.0142416667",
    benchmark: "-0.000206336670295348",
  },
  {
    perf_date: "2013-03-31T00:00:00",
    fund_return: "0.0125416667",
    benchmark: "0.0208727707482315",
  },
  {
    perf_date: "2013-04-30T00:00:00",
    fund_return: "0.0018416667",
    benchmark: "0.0290203623536958",
  },
  {
    perf_date: "2013-05-31T00:00:00",
    fund_return: "0.0082416667",
    benchmark: "-0.00285203300499959",
  },
  {
    perf_date: "2013-06-30T00:00:00",
    fund_return: "-0.0085583333",
    benchmark: "-0.0260746095262683",
  },
  {
    perf_date: "2013-07-31T00:00:00",
    fund_return: "0.0089416667",
    benchmark: "0.0518712287677445",
  },
];

export const tracking_error_timelines = [
  { id: null, title: "Since Inception" },
  { id: 36, title: "Last 3 Years" },
  { id: 60, title: "Last 5 Years" },
  { id: 120, title: "Last 10 Years" },
];
