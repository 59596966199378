import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropDownList } from "@progress/kendo-react-dropdowns";

class DropdownSelector extends Component {
  render() {
    const {
      items = [],
      placeholder = "",
      value = "",
      onChange,
      classes,
    } = this.props;

    return (
      <DropDownList
        className={"kendo-virtual-combobox " + classes}
        data={items}
        placeholder={placeholder}
        onChange={onChange}
        popupSettings={{
          height: "250px",
        }}
        style={{ width: "100%" }}
        value={value}
        {...this.props}
      />
    );
  }
}

DropdownSelector.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  // placeholder: PropTypes.string.isRequired,
  // text_field: PropTypes.string,
  // value: PropTypes.one,
};

export default DropdownSelector;
