import { useQuery } from '@tanstack/react-query'
import { getPerformanceOverview } from '../services'
import useAccessRights from './useAccessRights'
import { useSecurities } from './useSecurities'

export const usePerformanceOverview = ({ asOfYear, currentView }) => {
  const { hasAccessInternalClient } = useAccessRights()
  const { isLoadingSecurities } = useSecurities()

  const { data, isLoading } = useQuery(
    [
      'performanceOverview',
      { end_date: `${asOfYear}-12-31`, mode: currentView },
    ],
    async ({ queryKey }) => {
      const [_key, { end_date, mode }] = queryKey
      const response = await getPerformanceOverview({ end_date, mode })
      return response.data
    },
    {
      enabled:
        !isLoadingSecurities &&
        !!asOfYear &&
        !!currentView &&
        (currentView === 'FoF' ? hasAccessInternalClient : true),
    },
  )
  return { data, isLoading }
}
