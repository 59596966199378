import { Box } from "@material-ui/core"
import React from "react"

import FundStatusIndicator from "../../components/misc/FundStatusIndicator"
import { KendoDropDown } from "../../components/selectors"

const defaultOps = ["Low", "Medium", "High"]
export const fundStatusStylesMap = {
  "Closed": 'gray',
  "OTHERS": '#ffbf00',
  "Open": '#50e3c2'
}

export default function FilterBar({
  aaap_classes,
  cellFilters,
  setCellFilters,
  statusOptions,
  strategy_list
}) {
  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <Box
        alignItems="flex-start"
        display="flex"
      >
        <Box>
          <FundStatusIndicator status={li.props.children?.[0]} />
        </Box>
        {li.props.children}
      </Box>
    )
    return React.cloneElement(li, li.props, itemChildren)
  }

  const filters = [
    { defaultItem: "Share class", items: aaap_classes, name: "class_name" },
    { defaultItem: "Strategy", items: strategy_list, name: "strategy" },
    { defaultItem: 'Status', itemRender, items: statusOptions || [], name: 'status' },
    {
      defaultItem: "Credit Correlation",
      items: defaultOps,
      name: "credit_correlation",
    },
    {
      defaultItem: "Equity Correlation",
      items: defaultOps,
      name: "equity_correlation",
    },
    {
      defaultItem: "Directionality",
      items: defaultOps,
      name: "directionality",
    },
    { defaultItem: "Leverage", items: defaultOps, name: "leverage" },
    { defaultItem: "Volatility", items: defaultOps, name: "volatility" },
  ]
  return (
    <div className="filter-toolbar">
      {filters.map((filter, idx) => (
        <div
          className={`filter-container ${filter.className}`}
          key={idx}
        >
          <KendoDropDown
            onChange={({ target }) =>
              setCellFilters((i) => ({
                ...i,
                [filter.name]:
                  target.value === filter.defaultItem ? null : target.value,
              }))
            }
            value={cellFilters[filter.name]}
            {...filter}
            classes={cellFilters[filter.name] ? "filter-active" : ""}
          />
        </div>
      ))}
    </div>
  )
}
