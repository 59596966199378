import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import React, { useEffect, useMemo, useState } from 'react'
import { Bar, Pie } from 'react-chartjs-2'

import formatDate from '../../utils/formatDate'
import stringToRGBColorCode from '../../utils/stringToRGBColorCode'
import ChartContainer from './layout/ChartContainer'
import PortOptResultSection from './layout/PortOptResultSection'
import { preferredOrder } from './PortOptStats'
import PortOptStatsTable from './PortOptStatsTable'
import { usePortfolioOptimiser } from './RefactoredPortfolioOptimiserProvider'


const useStyles = makeStyles((theme) => ({
  accordion: {
    '&$expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
    border: 'none',
    boxShadow: 'none',
  },
  expanded: {},
}))

ChartJS.register(ArcElement, BarElement, CategoryScale, Title, Tooltip, Legend, LinearScale)

const PIE_INACTIVE_BG_OPACITY = 0.2
const PIE_ACTIVE_BG_OPACITY = 1

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      labels: {
        font: {
          family: '"Gotham A", "Gotham B", sans-serif',
        },
      },
    },
    tooltip: {
      callbacks: {
        label: ({ label, raw }) => `${label}: ${raw}%`,
      },
    },
  },
}

const barChartOptions = Object.assign({}, options, {
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: (props) => {
          return `${props.dataset.label}: ${props.raw.toFixed(2)}%`
        }
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      title: {
        display: true,
        text: 'Weight (%)',
      }
    },
  }
})

const AssetTableCell = ({ label, opacity = PIE_INACTIVE_BG_OPACITY }) => (
  <Box
    alignItems="center"
    display="flex"
    gridGap={12}
  >
    <div>
      <Box
        bgcolor={stringToRGBColorCode(label)}
        component="div"
        height="20px"
        style={{ opacity }}
        width="20px"
      />
    </div>
    <span>{label}</span>
  </Box>
)

function getOrderedKeys(obj) {
  const allKeys = Object.keys(obj)

  const orderedKeys = preferredOrder.filter(key => allKeys.includes(key))

  allKeys.forEach(key => {
    if (!preferredOrder.includes(key)) {
      orderedKeys.push(key)
    }
  })

  return orderedKeys
}

const PortOptWeightResult = () => {
  const { hasFailedError, runData } = usePortfolioOptimiser()
  const result = runData?.result
  const dates = runData?.optimiserPayload?.allRets?.perfDate
  const classes = useStyles()
  const [barChartData, setBarChartData] = useState()
  const [pieChartData, setPieChartData] = useState()
  const [stackedTableData, setStackedTableData] = useState()
  const [selectedResultSet, setSelectedResultSet] = useState()
  const [tableData, setTableData] = useState()
  const resultSetOptions = useMemo(() => result?.weights ? getOrderedKeys(result.weights) : [], [result])
  const optWeightkeyValArr = useMemo(() => (selectedResultSet && result?.weights?.[selectedResultSet] ? Object.entries(result.weights[selectedResultSet]) : null), [result, selectedResultSet])

  useEffect(() => {
    if (resultSetOptions?.length > 0) {
      setSelectedResultSet(resultSetOptions[0])
    }
  }, [resultSetOptions])

  useEffect(() => {
    if (!optWeightkeyValArr) {
      return
    }
    const weightValues = Object.values(result?.weights || {})
    const uniqueFundNames = Array.from(new Set(weightValues.flatMap(Object.keys)))

    const datasets = uniqueFundNames?.map(label => ({
      backgroundColor: stringToRGBColorCode(label, PIE_INACTIVE_BG_OPACITY),
      borderColor: stringToRGBColorCode(label, PIE_ACTIVE_BG_OPACITY),
      borderWidth: 1,
      data: resultSetOptions.map(weightType => (result?.weights[weightType][label] || 0) * 100),
      label,
    }))

    setBarChartData({
      datasets,
      labels: resultSetOptions,
    })

    const tableData = uniqueFundNames.map(fundName => {
      const assetTableCell = (
        <AssetTableCell
          label={fundName}
        />
      )

      const percentageWeights = resultSetOptions.map((weightType) => {
        const weightPercent = (result.weights[weightType][fundName] || 0) * 100
        return `${weightPercent.toFixed(2)}%`

      })

      return [assetTableCell, ...percentageWeights]
    })

    setStackedTableData(tableData.sort((a, b) => {
      const percentageA = parseFloat(a[1])
      const percentageB = parseFloat(b[1])
      return percentageB - percentageA
    }))

    setPieChartData({
      datasets: [
        {
          backgroundColor: optWeightkeyValArr?.map(([label]) => stringToRGBColorCode(label, PIE_INACTIVE_BG_OPACITY)),
          borderColor: optWeightkeyValArr?.map(([label]) => stringToRGBColorCode(label, PIE_ACTIVE_BG_OPACITY)),
          borderWidth: 1,
          data: optWeightkeyValArr?.map(([label, value]) => (value * 100).toFixed(2)),
        },
      ],
      labels: [...optWeightkeyValArr?.map(([label]) => label), ...optWeightkeyValArr?.map(([label]) => label)],
    })

    setTableData(
      optWeightkeyValArr.map(([label, value]) => (
        [
          (<AssetTableCell label={label} />),
          `${(value * 100).toFixed(2)}%`,
        ]
      )).sort((a, b) => {
        const percentageA = parseFloat(a[1])
        const percentageB = parseFloat(b[1])
        return percentageB - percentageA
      })
    )

  }, [optWeightkeyValArr, result, resultSetOptions])

  const hoverTableRowHandler = (isHover) => (index) => {
    const hoverItemLabel = optWeightkeyValArr[index][0]

    setPieChartData((prev) => {
      const updatedColor = stringToRGBColorCode(hoverItemLabel, isHover ? PIE_ACTIVE_BG_OPACITY : PIE_INACTIVE_BG_OPACITY)
      const backgroundColorData = [...prev.datasets[0].backgroundColor]
      backgroundColorData[index] = updatedColor

      return {
        ...prev,
        datasets: [
          {
            ...prev.datasets[0],
            backgroundColor: backgroundColorData,
          },
        ],
      }
    })

    setTableData((prev) => {
      const data = [...prev]
      data[index] = [<AssetTableCell
        label={hoverItemLabel}
        opacity={isHover ? PIE_ACTIVE_BG_OPACITY : PIE_INACTIVE_BG_OPACITY}
      />, data[index][1]]

      return data
    })
  }

  const haoveStackedTableRowHandler = (isHover) => (index) => {
    setBarChartData((prev) => {
      const updatedColor = stringToRGBColorCode(stackedTableData[index][0]?.props?.label, isHover ? PIE_ACTIVE_BG_OPACITY : PIE_INACTIVE_BG_OPACITY)
      const matchedDataIdx = prev.datasets.findIndex((dataset) => dataset.label === stackedTableData[index][0]?.props?.label)
      const newDataSets = [...prev.datasets]
      newDataSets[matchedDataIdx] = {
        ...newDataSets[matchedDataIdx],
        backgroundColor: updatedColor
      }
      return {
        ...prev,
        datasets: newDataSets
      }
    })

    setStackedTableData((prev) => {
      const data = [...prev]

      data[index][0] = <AssetTableCell
        label={stackedTableData[index][0]?.props?.label}
        opacity={isHover ? PIE_ACTIVE_BG_OPACITY : PIE_INACTIVE_BG_OPACITY}
      />

      return data
    })
  }

  const changeSelectedResSetHandler = (event, newValue) => setSelectedResultSet(newValue)

  return result && !hasFailedError ? (
    <PortOptResultSection
      date={`${formatDate(dates?.[0])} - ${formatDate(dates?.[dates.length - 1])}`}
      header="Weights"
      runData={runData}
    >
      {
        barChartData && (
          <Accordion
            className={classes.accordion}
            defaultExpanded
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Stacked Bar Chart Comparison</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                display="flex"
                flexDirection="column"
                gridGap={12}
                width="100%"
              >
                <ChartContainer
                  height="350px"
                  width="100%"
                >
                  <Bar
                    data={barChartData}
                    options={barChartOptions}
                  />
                </ChartContainer>
                {
                  stackedTableData && (
                    <Box>
                      <PortOptStatsTable
                        data={stackedTableData}
                        headers={['Fund', ...resultSetOptions]}
                        onMouseEnterRow={haoveStackedTableRowHandler(true)}
                        onMouseLeaveRow={haoveStackedTableRowHandler(false)}
                      />
                    </Box>
                  )
                }
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      }
      <Accordion
        className={classes.accordion}
        defaultExpanded
        style={{ borderBottom: 'none' }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Asset Allocation Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            flexDirection="column"
            gridGap={12}
            width="100%"
          >
            <Tabs
              indicatorColor='primary'
              onChange={changeSelectedResSetHandler}
              value={selectedResultSet}
              variant="fullWidth"
            >
              {resultSetOptions?.map((option, idx) => (
                <Tab
                  key={idx}
                  label={option}
                  value={option}
                />
              ))}
            </Tabs>
            {
              pieChartData && (
                <ChartContainer
                  height="350px"
                  width="100%"
                >
                  <Pie
                    data={pieChartData}
                    options={options}
                  />
                </ChartContainer>
              )
            }
            {
              tableData && (
                <Box>
                  <PortOptStatsTable
                    data={tableData}
                    headers={['Fund', 'Weight']}
                    onMouseEnterRow={hoverTableRowHandler(true)}
                    onMouseLeaveRow={hoverTableRowHandler(false)}
                  />
                </Box>
              )
            }
          </Box>
        </AccordionDetails>
      </Accordion>
    </PortOptResultSection>
  ) : null
}

export default PortOptWeightResult
