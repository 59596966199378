import { Card, TableCell, TableRow } from "@material-ui/core"
import numeral from "numeral"
import React from "react"
import styled from "styled-components"

import { formatNumberToCurrency } from "./PrivateEquityCard/PrivateEquityCard"
import { StyledCardTableCell } from "./PrivateEquityCard/PrivateEquityCard.styled"

const StyledTableHeader = styled.th`
  text-align: left;
  padding: 0.7rem 0.6rem;
  color: ${({ theme }) => theme.palette.aamui.oceanNavy};
`

const StyledHeaderedTable = styled.table`
  background: white;
  border-collapse: collapse;
  margin-top: 12px;
  width: 100%;

  th, td {
    border: 1px solid ${({ theme }) => theme.palette.aamui.eggShell};
  }

  tr:first-child {
    background: ${({ theme }) => theme.palette.aamui.iceBlue};
  }

  tr:nth-child(2n) {
    background: ${({ theme }) => theme.palette.aamui.highlightBlue};
  }
`

const StyledTableCellType = styled.td`
  font-weight: 600;
  padding: 0.7rem 0.6rem;
`

const StyledTableCell = styled(StyledCardTableCell)`
  padding: 0.7rem 0.6rem;
`

const StyledTableDisclaimer = styled.div`
  font-style: italic;
  color: #144869;
  padding: 0.4rem 0.8rem;
`

const LegalTab = (fundData = {}) => {
  const { terms_legal: legalData } = fundData
  const shareClassesData = fundData?.share_class ? [fundData.share_class] : []
  const MIN_SUBSCRIPTION = "minimum_subs"
  const SUBSCRIPTION_FEE = "subscription_fee"
  const PERFORMANCE_FEE = "performance_fee"
  const MANAGEMENT_FEE = "management_fee"
  const SUBS_DEADLINE = "subs_deadline"
  const CAPITAL_CALL_DEADLINE = "capital_call_deadline"
  const CAPITAL_CALL = "capital_call"
  const ISIN = "isin"

  const renderInvestmentTableRow = (legalData) => {
    const tableCellTypeMap = {
      auditor: "Auditor",
      currency: "Currency",
      fund_admin: "Fund Administrator",
      investment_manager: "Investment Manager",
      investment_period: "Investment Period",
      investment_structure: "Investment Structure",
      term: "Term",
      underlying_fund: "Underlying Fund",
      underlying_fund_carried_interest: "Underlying Fund Carried Interest",
      underlying_fund_management_fee: "Underlying Fund Management Fee (p.a.)",
      vehicle_name: "Vehicle Name",
    }

    return Object.entries(legalData).map((data, index) => {
      const key = data[0]
      const value = data[1]

      if (key !== "id" && key !== "disclaimer") {
        return (
          <TableRow
            className="fund-info-table"
            key={index}
          >
            <TableCell className="data-type">
              {tableCellTypeMap[key]}
            </TableCell>
            <TableCell>{value}</TableCell>
          </TableRow>
        )
      }
      return null
    })
  }

  const renderShareClassTableHeaders = (shareClassesData) => {
    return Object.values(shareClassesData).map((share, i) => {
      return <StyledTableHeader key={i}>{share.class_name}</StyledTableHeader>
    })
  }

  const renderShareClassTableHeader = (shareClassesData) => {
    const tableHeaders = renderShareClassTableHeaders(shareClassesData)

    return (
      <tr>
        <StyledTableHeader>Share Class</StyledTableHeader>
        {tableHeaders}
      </tr>
    )
  }

  const renderCapitalCall = (shareData) => {
    if (
      shareData.class_immediate_capital_upperbound !== null &&
      shareData.class_immediate_capital_upperbound !== 0
    ) {
      return `${numeral(shareData.class_immediate_capital_lowerbound).format(
        "0.00",
      )} - ${numeral(shareData.class_immediate_capital_upperbound).format(
        "0.00",
      )}%`
    } else {
      if (shareData.class_immediate_capital_lowerbound === 0) {
        return "-"
      } else
        return `${numeral(shareData.class_immediate_capital_lowerbound).format(
          "0.00",
        )}%`
    }
  }

  const formatFeeData = (feeData) => {
    const suffix = "%"
    const value = numeral(feeData).format("0.00") + suffix
    return value
  }

  const formatDateData = (dateData) => {
    let value = ""
    if (dateData === null) {
      value = "-"
    } else {
      const date = new Date(dateData)
      value = Intl.DateTimeFormat().format(date)
    }
    return value
  }

  const currency = legalData?.currency
  const formatSubData = (subData) => formatNumberToCurrency(subData, currency)

  const formatCellData = (data, cellType) => {
    let cellData

    const formatMap = {
      [CAPITAL_CALL]: data,
      [CAPITAL_CALL_DEADLINE]: formatDateData,
      [ISIN]: data,
      [MANAGEMENT_FEE]: formatFeeData,
      [MIN_SUBSCRIPTION]: formatSubData,
      [PERFORMANCE_FEE]: formatFeeData,
      [SUBSCRIPTION_FEE]: formatFeeData,
      [SUBS_DEADLINE]: formatDateData,
    }

    if (data === 0 || data === undefined) {
      cellData = "-"
    } else {
      const formatFunction = formatMap[cellType]
      if (typeof formatFunction === "function") {
        cellData = formatFunction(data)
      } else {
        cellData = formatFunction
      }
    }

    return cellData
  }

  const renderShareClassTableCell = (shareClassesData, cellType) => {
    return Object.values(shareClassesData).map((share, i) => {
      const cellTypeMap = {
        [CAPITAL_CALL]: renderCapitalCall(share),
        [CAPITAL_CALL_DEADLINE]: share.capital_call_deadline,
        [ISIN]: share.isin,
        [MIN_SUBSCRIPTION]: share.class_min_subscription,
        [PERFORMANCE_FEE]: share.class_performance_fee,
        [SUBSCRIPTION_FEE]: share.class_subs_fee,
        [SUBS_DEADLINE]: share.class_subs_deadline,
        management_fee: share.class_management_fee,
      }
      const value = formatCellData(cellTypeMap[cellType], cellType)
      return <TableCell key={i}>{value}</TableCell>
    })
  }

  const renderShareClassTableRow = (shareClassesData, cellType) => {
    const tableRowMap = {
      [CAPITAL_CALL]: "Immediate Capital Call",
      [CAPITAL_CALL_DEADLINE]: "Capital Call Deadline",
      [ISIN]: "ISIN",
      [MANAGEMENT_FEE]: "Management Fee (p.a.)",
      [MIN_SUBSCRIPTION]: "Minimum Subscription",
      [PERFORMANCE_FEE]: "Performance Fee",
      [SUBSCRIPTION_FEE]: "Commitment Fee (Optional)",
      [SUBS_DEADLINE]: "Subscription Document Deadline"
    }

    const rowCells = renderShareClassTableCell(shareClassesData, cellType)

    return (
      <TableRow className="fund-info-table">
        <TableCell className="data-type">{tableRowMap[cellType]}</TableCell>
        {rowCells}
      </TableRow>
    )
  }

  const renderLegalDisclaimer = (disclaimer) => {
    if (disclaimer !== "") {
      return (
        <Card>
          <StyledTableDisclaimer>* {disclaimer}</StyledTableDisclaimer>
        </Card>
      )
    }
  }

  const renderShareClassTable = (shareClassesData) => {
    const tableHeader = renderShareClassTableHeader(shareClassesData)
    const minSubcriptionRow = renderShareClassTableRow(
      shareClassesData,
      MIN_SUBSCRIPTION,
    )
    const subcriptionFeeRow = renderShareClassTableRow(
      shareClassesData,
      SUBSCRIPTION_FEE,
    )
    const managmentFeeRow = renderShareClassTableRow(
      shareClassesData,
      MANAGEMENT_FEE,
    )
    const performanceFeeRow = renderShareClassTableRow(
      shareClassesData,
      PERFORMANCE_FEE,
    )
    const capitalCallRow = renderShareClassTableRow(
      shareClassesData,
      CAPITAL_CALL,
    )
    const capitalCallDeadline = renderShareClassTableRow(
      shareClassesData,
      CAPITAL_CALL_DEADLINE,
    )
    const subsDeadlineRow = renderShareClassTableRow(
      shareClassesData,
      SUBS_DEADLINE,
    )
    const isinRow = renderShareClassTableRow(
      shareClassesData,
      ISIN,
    )

    return (
      <>
        {minSubcriptionRow}
        {subcriptionFeeRow}
        {managmentFeeRow}
        {performanceFeeRow}
        {capitalCallRow}
        {subsDeadlineRow}
        {capitalCallDeadline}
        {isinRow}
      </>
    )
  }

  return shareClassesData ? (
    <div className="asset-detail-container">
      <div className="table-container">
        <Card>
          <table>
            <tbody>
              {renderInvestmentTableRow(legalData)}
              {shareClassesData?.length > 0 && renderShareClassTable(shareClassesData)}
            </tbody>
          </table>
        </Card>
        {renderLegalDisclaimer(legalData.disclaimer)}
      </div>
    </div>
  ) : null
}

export default LegalTab
