import { Typography } from '@material-ui/core'
import React from 'react'

import useCreateHsBlogPost from '../../hooks/useCreateHsBlogPost'
import useFaqData from '../../hooks/useFaqData'
import useFetchFaqs from '../../hooks/useFetchFaqs'
import { SecurityType } from '../../models/SecurityTypes'
import extractFundIds from '../../utils/extractFundIds'
import {
  BlogType,
  FundTypeGlobalFaqHeaderMap,
  SecurityTypeToBlogSlugMap,
  SecurityTypeWithFaqs,
} from '../../utils/fundFaqUtils'
import FaqEditButton from './FaqEditButton'
import FaqsBlock, { EmptyFaqMessage } from './FaqsBlock'
import { FaqsActionButtonsContainer, FaqsContainer } from './FaqsTabLayout'

interface FaqTabProps {
  security_details: any
  security_type: SecurityType
}

const FaqTab: React.FC<FaqTabProps> = ({ security_details, security_type }) => {
  const { createHsBlogPost, isCreatingHsBlogPost } = useCreateHsBlogPost()
  const { fundId, underlyingFundId } = extractFundIds(security_details)
  const isUnderlyingFund = !underlyingFundId
  const shouldFetchFaqs = SecurityTypeWithFaqs.has(security_type)

  const [hsGlobalFaqsResults, { isFetcinh: isFetchingGlobalFaqs }] =
    useFetchFaqs(
      BlogType.GLOBAL_FUND_FAQS,
      SecurityTypeToBlogSlugMap[security_type],
      !!(shouldFetchFaqs && (fundId || underlyingFundId)),
    )
  const [
    fundFaqsResults,
    { isFetching: isFetchingFundFaqs, refetch: refetchFundFaqs },
  ] = useFetchFaqs(BlogType.FUND_FAQS, fundId, !!(shouldFetchFaqs && fundId))
  const [
    underlyingFundFaqsResults,
    {
      isFetching: isFetchingUnderlyingFundFaqs,
      refetch: refetchUnderlyingFundFaqs,
    },
  ] = useFetchFaqs(
    BlogType.FUND_FAQS,
    underlyingFundId,
    !!(shouldFetchFaqs && underlyingFundId),
  )

  const [globalFaqs, globalFaqsHsBlogPostId] = useFaqData(hsGlobalFaqsResults)
  const [fundFaqs, fundFaqsHsBlogPostId] = useFaqData(fundFaqsResults)
  const [underlyingFundFaqs, underlyingFundFaqsHsBlogPostId] = useFaqData(
    underlyingFundFaqsResults,
  )

  const isFetchingFaqs =
    isFetchingFundFaqs || isFetchingUnderlyingFundFaqs || isFetchingGlobalFaqs
  const disableButtons = isCreatingHsBlogPost || isFetchingFaqs
  const isZeroFaqs =
    !isFetchingFaqs &&
    !fundFaqs?.length &&
    !underlyingFundFaqs?.length &&
    !globalFaqs?.length

  const clickCreateBlogPostButtonHandler =
    (
      fundId: number | string,
      fundName: string,
      isCreatingUnderlyingFund: boolean,
    ) =>
    async () => {
      const newBlogPostId = await createHsBlogPost({
        baseSlug: BlogType.FUND_FAQS,
        contentGroupId: process.env.REACT_APP_HS_FUND_FAQS_CONTENT_GROUP_ID,
        fundName,
        slugChildId: fundId,
      })

      if (newBlogPostId) {
        isCreatingUnderlyingFund
          ? refetchUnderlyingFundFaqs()
          : refetchFundFaqs()
      }
    }

  return (
    <FaqsContainer>
      <FaqsActionButtonsContainer>
        <FaqEditButton
          blogPostId={fundFaqsHsBlogPostId}
          disabled={disableButtons}
          faqsResults={fundFaqsResults}
          fundName={security_details?.name}
          onClickCreate={clickCreateBlogPostButtonHandler(
            fundId,
            security_details?.name,
            false,
          )}
        />
        {!isUnderlyingFund && (
          <FaqEditButton
            blogPostId={underlyingFundFaqsHsBlogPostId}
            disabled={disableButtons}
            faqsResults={underlyingFundFaqsResults}
            fundName={security_details?.underlying_fund?.name}
            onClickCreate={clickCreateBlogPostButtonHandler(
              underlyingFundId,
              security_details?.underlying_fund?.name,
              true,
            )}
          />
        )}
        <FaqEditButton
          blogPostId={globalFaqsHsBlogPostId}
          disabled={disableButtons}
          faqsResults={hsGlobalFaqsResults}
          fundName={FundTypeGlobalFaqHeaderMap[security_type]}
        />
      </FaqsActionButtonsContainer>
      {isZeroFaqs ? (
        <EmptyFaqMessage />
      ) : (
        <>
          <Typography variant="h6">FAQs</Typography>
          <FaqsBlock
            loading={isFetchingFaqs}
            faqs={fundFaqs}
            header={security_details?.name}
          />
          <FaqsBlock
            loading={isFetchingUnderlyingFundFaqs}
            faqs={underlyingFundFaqs}
            header={security_details?.underlying_fund?.name}
          />
          <FaqsBlock
            loading={isFetchingGlobalFaqs}
            faqs={globalFaqs}
            header={FundTypeGlobalFaqHeaderMap[security_type]}
          />
        </>
      )}
    </FaqsContainer>
  )
}

export default FaqTab
