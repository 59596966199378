import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { dateTimeManager as dt } from "../../utils";

export default function PageViewReport({ views }) {
  return (
    <div className="page-views">
      {views.map((o, idx) => (
        <Accordion key={idx}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{dt.dbToLongFormat(o.Date)}</Typography>
            <Typography>Device: {o.device}</Typography>
            <Typography>Platform: {o.platform}</Typography>
            <Typography>Views: {o.activities.length}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {o.activities.map((a, aIdx) => (
                <ListItem key={aIdx}>
                  <ListItemText
                    primary={a.title}
                    secondary={`${dt.toTime(a.time)}`}
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
