import React from "react";
import Typography from "@material-ui/core/Typography";
import { UserList } from "../users";
import { permissions } from "../../constants";
import { useProfile } from "../../hooks";
import useAccessRights from "../../hooks/useAccessRights";

export default function Users() {
  const profile = useProfile();
  const { hasAccessUserManagement } = useAccessRights()

  if (!hasAccessUserManagement) {
    return (
      <div>
        <Typography>Not Allowed</Typography>
      </div>
    );
  }
  return <UserList />;
}
