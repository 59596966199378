import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { NewSubmitButton, StyledForm, Header, ResetPasswordInfoText } from "./styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { TextInput as Input } from "../../components/forms";
import { requestNewPassword } from "../../services";
import EmailIcon from '@material-ui/icons/Email';
import { IconButton, InputAdornment } from "@material-ui/core";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

export default function ResetPasswordForm({ setView, resetEmail }) {
  const [passwordRequested, setPasswordRequested] = useState(false);
  const resetPassword = async (data) => {
    try {
      setPasswordRequested(true);
      await requestNewPassword({
        ...data,
        email: data?.email?.toLowerCase(),
      });
    } catch {
      setPasswordRequested(false);
    }
  };

  useEffect(() => {
    if (resetEmail) {
      resetPassword({ email: resetEmail });
    }
    return () => { };
  }, [resetEmail]);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      onSubmit={resetPassword}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values }) => (
        <StyledForm>
          <Header>
            <strong>Reset</strong> your
            <br />
            Password
          </Header>
          {!passwordRequested ? (
            <>
              <Input
                name="email"
                placeholder="Email"
                disableUnderline
                className="reset-password-input"
                isNew
                value={values.email}
                onChange={(e) => {
                  const lowerCaseEmail = e.target.value.toLowerCase();
                  setFieldValue("email", lowerCaseEmail);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton disabled>
                      <EmailIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <NewSubmitButton disableElevation disabled={passwordRequested} type="submit">
                Reset Password
              </NewSubmitButton>
            </>
          ) : (
            <Typography>
              You will receive email with a link to set a new password
            </Typography>
          )}
          <ResetPasswordInfoText>
            Please check your inbox for the link to reset your password.
          </ResetPasswordInfoText>
          <Button
            variant="text"
            color="primary"
            className="back-to-login-btn"
            onClick={() => setView("default")}
          >
            Back to login
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
}
