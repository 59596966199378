import React from 'react'
import { Form } from 'formik'
import {
  Description, FundAllocator, Benchmarks, Fees,
} from './forms'

const Content = ({ activeStep: step, ...props }) => {
  switch (step) {
    case 0:
      return <Description {...props} />
    case 1:
      return <FundAllocator {...props} />
    case 2:
      return <Benchmarks {...props} />
    default:
      return <Fees {...props} />
  }
}

export default function BuilderForm({ submitPortfolio, ...props }) {
  return (
    <Form>
      <Content {...props} />
    </Form>
  )
}
