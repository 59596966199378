/* eslint-disable unused-imports/no-unused-imports */
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import { useQuery } from '@tanstack/react-query'
import React, { useMemo } from 'react'
import { toast } from 'react-toastify'

import LoadingSpinner from '../../components/misc/LoadingSpinner'
import { Document } from '../../hooks/useFetchAaapDocs'
import { useMixpanel } from '../../hooks/useMixpanel'
import { downloadPeDocsByDocId, getPrivateEquityDocsById } from '../../services'
import { buildFileFromResponse } from '../../utils'
import FundDocumentListBlock from '../assetDetail/FundDocumentListBlock'

interface PrivateEquityDocumentTabProps {
  id: number
  name: string
}

const docTypeHeaderMap: Record<string, string> = {
  'APF Legal': 'Legal Documents',
  'APF Marketing': 'Presentation',
  'APF Reporting': 'Reporting',
  'APF Research': 'Research Documents',
}

const PrivateEquityDocumentTab: React.FC<PrivateEquityDocumentTabProps> = ({
  id,
  name
}) => {
  const { mpTrack } = useMixpanel()
  const { data, isLoading } = useQuery(['private-equity-document', id], () => getPrivateEquityDocsById(id), { select: res => res.data })

  const groupedDocs = useMemo(() => {
    if (data) {
      const groupedData = data.reduce((acc: Record<string, Document[]>, doc: Document) => {
        const key = doc.story_type
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(doc)
        return acc
      }, {})
      return groupedData
    }

    return []
  }, [data])

  const clickDownloadDocsHandler = async (doc: Document) => {
    const mpEventPayload = {
      eventName: 'Download PE Fund Document',
      properties: {
        "Document Name": doc.name,
        "Security ID": id,
        "Security Name": name,
        "Type": doc.story_type,

      }
    }
    try {
      const res = await downloadPeDocsByDocId(doc.id)
      buildFileFromResponse(res)
      toast.success('Document downloaded')
      mpTrack({ ...mpEventPayload, properties: { ...mpEventPayload.properties, "Status": "Success" } })
    } catch (err) {
      toast.error('Failed to download document')
      mpTrack({ ...mpEventPayload, properties: { ...mpEventPayload.properties, "Status": "Failed" } })
    }
  }

  return isLoading ? <LoadingSpinner /> : (
    <Card>
      <CardContent>
        {
          groupedDocs && Object.keys(docTypeHeaderMap).map((name) => groupedDocs[name] && (
            <FundDocumentListBlock
              documents={groupedDocs[name]}
              header={docTypeHeaderMap[name]}
              key={name}
              onDocumentClick={clickDownloadDocsHandler}
            />

          ))
        }
        {data?.length === 0 && (
          <Box textAlign="center">
            <Typography>There is no Document for this fund</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

export default PrivateEquityDocumentTab