import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import { cloneDeep } from "lodash";
import TextField from "@material-ui/core/TextField";

class MultiBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlight_left: [],
      highlight_right: [],
      search_left: "",
      search_right: "",
    };
  }

  handleChangeMultiple = (e, side) => {
    const { options } = e.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      [side]: value,
    });
  };

  addToSelectedList = () => {
    const { selected_list, update_list_func, options } = this.props;
    if (options.length === 0) {
      return;
    }
    const { highlight_left } = this.state;
    let new_selected_list = [];
    for (let i = 0; i < highlight_left.length; i++) {
      const highlighted = highlight_left[i];
      const element = options.find((o) => o === highlighted);
      selected_list.push(element);
    }
    const merged_list = new_selected_list.concat(selected_list);
    update_list_func(merged_list);
  };

  removeFromSelectList = () => {
    const { highlight_right } = this.state;
    const { selected_list, update_list_func } = this.props;
    let updated_selected_list = cloneDeep(selected_list);
    for (let i = 0; i < highlight_right.length; i++) {
      const highlighted = highlight_right[i];
      const element = updated_selected_list.find((o) => o === highlighted);
      const element_index = updated_selected_list.indexOf(element);
      updated_selected_list.splice(element_index, 1);
    }
    update_list_func(updated_selected_list);
  };

  handleSearch = (e, side) => {
    this.setState({ [side]: e.target.value });
  };

  handleTranfers = () => {
    const { options } = this.props;
    const { selected_list } = this.props;
    let unselected_options = cloneDeep(options);
    for (let i = 0; i < selected_list.length; i++) {
      const element = unselected_options.find((o) => o === selected_list[i]);
      const element_index = unselected_options.indexOf(element);
      if (element_index >= 0) {
        unselected_options.splice(element_index, 1);
      }
    }
    return unselected_options;
  };

  handleFilter = (unselected_options, selected_options) => {
    const { search_left, search_right } = this.state;
    const c_selected_list = cloneDeep(selected_options);
    const c_unselected_options = cloneDeep(unselected_options);
    let upd_selected_list = [],
      upd_unselected_options = [];
    for (let i = 0; i < c_unselected_options.length; i++) {
      const element = c_unselected_options[i];
      if (element.indexOf(search_left) >= 0) {
        upd_unselected_options.push(element);
      }
    }
    for (let i = 0; i < c_selected_list.length; i++) {
      const element = c_selected_list[i];
      if (element.indexOf(search_right) >= 0) {
        upd_selected_list.push(element);
      }
    }
    return [upd_unselected_options, upd_selected_list];
  };

  render() {
    const { highlight_left, highlight_right, search_left, search_right } =
      this.state;
    const { selected_list, height, label } = this.props;
    const min_height = height ? height : 20;
    let unselected_options = this.handleTranfers();
    let selected_options = cloneDeep(selected_list);
    [unselected_options, selected_options] = this.handleFilter(
      unselected_options,
      selected_options
    );

    return (
      <Fragment>
        <Grid
          container
          spacing={0}
          style={{ padding: "2rem", overflow: "hidden" }}
        >
          <Grid item container spacing={4} alignItems={"center"}>
            <Grid item xs={6}>
              <TextField
                placeholder={label}
                fullWidth
                value={search_left}
                onChange={(e) => {
                  this.handleSearch(e, "search_left");
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                placeholder={label}
                fullWidth
                value={search_right}
                onChange={(e) => {
                  this.handleSearch(e, "search_right");
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                multiple
                style={{ width: "100%", display: "block" }}
                native
                onChange={(e) => this.handleChangeMultiple(e, "highlight_left")}
                value={highlight_left}
                inputProps={{
                  style: { minHeight: `${min_height}rem` },
                }}
              >
                {unselected_options.map((option, id) => (
                  <option key={id} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                multiple
                style={{ width: "100%", display: "block" }}
                native
                value={highlight_right}
                onChange={(e) =>
                  this.handleChangeMultiple(e, "highlight_right")
                }
                inputProps={{
                  id: "select-multiple-native",
                  style: { minHeight: `${min_height}rem` },
                }}
              >
                {selected_options.map((option, id) => (
                  <option key={id} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={4}
              justifyContent={"space-around"}
            >
              <Grid item>
                <Button
                  onClick={this.addToSelectedList}
                  color={"primary"}
                  variant="outlined"
                >
                  Add
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={this.removeFromSelectList}
                  color={"primary"}
                  variant="outlined"
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default MultiBox;
