import React, { Component } from "react";
import { GenericTable } from "../../components";
import Grid from "@material-ui/core/Grid";
import { Scatter } from "../../components";

export default class peerReport extends Component {
  getTable = (name, width = 200, other_props) => {
    const { internal_analysis } = this.props;
    const { report } = internal_analysis || {};
    return (
      <GenericTable
        table_name={name}
        data={report[name]}
        index_width={`${width}px`}
        equal_width
        negative_red
        {...other_props}
        // index_auto
      />
    );
  };

  render() {
    const { getTable } = this;
    const simple_tables = [
      "Annualised Returns",
      "Latest Returns",
      "Statistics",
      "Drawdowns",
    ];
    const { internal_analysis, narrow } = this.props;
    const { report } = internal_analysis || {};
    return (
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} lg={narrow || 10} xl={narrow || 8}>
          {getTable("Funds", 400)}
        </Grid>
        {simple_tables.map((table, id) => (
          <Grid key={id} item xs={12} lg={narrow || 10} xl={narrow || 6}>
            {getTable(table, 250)}
          </Grid>
        ))}
        <Grid item xs={12} lg={narrow || 10} xl={narrow || 8}>
          {getTable("Historic Correlations", 250, {
            correlation: true,
            negative_red: false,
          })}
        </Grid>
        <Grid item xs={12} lg={narrow || 10} xl={narrow || 8}>
          <Scatter
            title="Risk-Return Profile"
            data={report["Risk-Return profile"]}
            category_name="index"
            x_field="Volatility"
            y_field="Return"
            x_field_name="Volatility"
            y_field_name="Return"
            group_field="Type"
          />
        </Grid>
        <Grid item xs={12} xl={narrow || 8} lg={narrow || 10}>
          <GenericTable
            style={{ maxHeight: "800px" }}
            table_name={"Matrix"}
            data={report["Matrix"]}
            index_width="250px"
            // equal_width
            scrollable="scrollable"
            correlation
          />
        </Grid>
        {report.benchmark_names.map((name, id) => (
          <Grid item xs={12} lg={narrow || 10} xl={narrow || 6} key={id}>
            <GenericTable
              table_name={name}
              data={report.benchmark_stats[name]}
              index_width="200px"
              equal_width
              negative_red
              // index_auto
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}
