import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import React from 'react'

const PortOptStatsTable = ({
  data,
  headers,
  onMouseEnterRow,
  onMouseLeaveRow,
}) => {
  const handleMouseEvents = (index) => (event) => {
    if (event.type === 'mouseenter' && onMouseEnterRow) {
      onMouseEnterRow(index)
    }
    if (event.type === 'mouseleave' && onMouseLeaveRow) {
      onMouseLeaveRow(index)
    }
  }

  return (
    <Box
      bgcolor="white"
      mx="auto"
    >
      <TableContainer style={{ maxHeight: '500px' }}>
        <Table
          size="small"
          stickyHeader
        >
          {
            headers && (
              <TableHead>
                <TableRow>
                  {headers?.map((header, idx) => (
                    <TableCell
                      align={idx > 0 ? 'right' : 'left'}
                      key={idx}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )
          }
          <TableBody>
            {
              data?.map((values, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  onMouseEnter={handleMouseEvents(rowIndex)}
                  onMouseLeave={handleMouseEvents(rowIndex)}
                >
                  {
                    values?.map((value, cellIndex) => (
                      <TableCell
                        align={cellIndex > 0 ? 'right' : 'left'}
                        key={cellIndex}
                      >
                        {value}
                      </TableCell>
                    ))
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PortOptStatsTable
