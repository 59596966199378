// eslint-disable-next-line no-extend-native
Array.prototype.clean = function () {
  var newArray = [];
  for (var index = 0; index < this.length; index++) {
    if (this[index]) {
      newArray.push(this[index]);
    }
  }
  return newArray;
};

Object.filter = function (obj, filterFunc) {
  const result = Object.fromEntries(Object.entries(obj).filter(filterFunc));
  return result;
};
