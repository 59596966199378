import React from 'react'
import moment from 'moment'

const TimeDurationLabel = ({ seconds }) => {
  const duration = moment.duration(seconds, 'seconds')

  let formattedTime

  if (duration.asDays() >= 1) {
    formattedTime = `${Math.floor(duration.asDays())}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`
  } else if (duration.asHours() >= 1) {
    formattedTime = `${Math.floor(duration.asHours())}h ${duration.minutes()}m ${duration.seconds()}s`
  } else if (duration.asMinutes() >= 1) {
    formattedTime = `${Math.floor(duration.asMinutes())}m ${duration.seconds()}s`
  } else {
    formattedTime = `${duration.seconds()}s`
  }

  return <time>{formattedTime}</time>
}

export default TimeDurationLabel
