function camelCaseToTitleCase(camelCaseString: string): string {
  return camelCaseString
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
    .trim()
}

export default camelCaseToTitleCase
