import { Box, Button, Chip, TextField } from '@material-ui/core'
import React, { useState } from 'react'

import ChipContainer from './layout/ChipContainer'

const PortOptTagsInput = ({
  layout = 'row',
  onClickAdd,
  onClickDelete,
  tags,
}) => {
  const [inputTagValue, setInputTagValue] = useState('')

  const changeTagInputValueHandler = (event) => {
    setInputTagValue(event.target.value)
  }

  const clickAddButtonHandler = () => {
    if (inputTagValue.trim() === '') {
      return
    }

    if (onClickAdd) {
      onClickAdd(inputTagValue)
    }

    setInputTagValue('')
  }

  return (
    <Box
      display="flex"
      flexDirection={layout}
      gridGap={8}
    >
      <Box display="flex">
        <TextField
          onChange={changeTagInputValueHandler}
          placeholder="Tags"
          style={{ width: '515px' }}
          value={inputTagValue}
        />
        <Button
          color="primary"
          onClick={clickAddButtonHandler}
          variant="contained"
        >
          Add
        </Button>
      </Box>
      <ChipContainer
        alignItems="center"
        maxWidth="700px"
      >
        {
          tags?.map((tag, idx) => (
            <Chip
              key={idx}
              label={tag}
              onDelete={onClickDelete(idx)}
              size="small"
            />
          ))
        }
      </ChipContainer>
    </Box>
  )
}

export default PortOptTagsInput
