import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { StockChartTimeSeries } from '../../components/charts'
import SkelatonChartLoader from '../../components/skeletons/SkelatonChartLoader'
import SkeletonTableLoader from '../../components/skeletons/SkelatonPerformanceTableLoader'
import SkelatonTextLoader from '../../components/skeletons/SkelatonTextLoader'
import { PerformanceTable } from '../../components/tables'

const PerformanceTab = ({ security_details, security_type, loading }) => {
  const { monthly_commentary } = security_details
  const {
    returns_table,
    returns_timeseries,
    returns_with_benchmark_timeseries,
  } = security_details || {}

  const is_aaap = security_type === 'AAAP Cell'
  const returns_available = returns_table && returns_table.length > 0

  return (
    <div className="performance-tab">
      {returns_table ? (
        <div className="perf-table">
          <PerformanceTable
            data={returns_table}
            is_aaap={is_aaap}
          />
        </div>
      ) : (
        <div className="perf-table">
          <Card>
            <CardContent>
              <SkeletonTableLoader />
            </CardContent>
          </Card>
        </div>
      )}

      {!loading || monthly_commentary ? (
        monthly_commentary && (
          <div className="commentary-content">
            <Card>
              <CardContent>
                <Typography variant="h6">Monthly Commentary</Typography>
                <Typography
                  align="justify"
                  variant="body2"
                >
                  {monthly_commentary}
                </Typography>
              </CardContent>
            </Card>
          </div>
        )
      ) : loading ? (
        <div className="commentary-content">
          <Card>
            <CardContent>
              <SkelatonTextLoader />
            </CardContent>
          </Card>
        </div>
      ) : null}

      {returns_with_benchmark_timeseries || returns_timeseries ? (
        <div className="cumulative-growth">
          <Card>
            <CardContent>
              <StockChartTimeSeries
                data={returns_with_benchmark_timeseries || returns_timeseries}
                title="Hypothetical growth of $1000"
                minimum
                format="{0:c}"
                category_field="perf_date"
              />
            </CardContent>
          </Card>
        </div>
      ) : (
        <div className="cumulative-growth">
          <Card>
            <CardContent>
              <SkelatonChartLoader />
            </CardContent>
          </Card>
        </div>
      )}

      <div className="pagebreak"></div>
    </div>
  )
}

export default PerformanceTab
