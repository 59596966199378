import React, { useEffect, useRef } from 'react'
import { isEqual } from 'lodash'

export const SubmitListener = (props) => {
  const { formik } = props
  const lastValuesRef = useRef(formik.values)

  useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValuesRef.current, formik.values)
    const valuesEqualInitialValues = isEqual(
      formik.values,
      formik.initialValues,
    )

    if (!valuesEqualLastValues) {
      lastValuesRef.current = formik.values

      if (!valuesEqualInitialValues) {
        formik.submitForm()
      }
    }
  }, [formik.values, formik.initialValues, formik.submitForm])

  return null
}
