import React, { createContext, useState } from 'react'
import { cloneDeep } from 'lodash'

export const defaultPortfolioWeight = { security: '', weight: null }

const defaultState = {
  id: null,
  name: '',
  portfolio_type: 'AAAP Portfolio',
  rebalancing_type: null,
  inception_date: null,
  prorated: false,
  description: '',
  cost: null,
  performance_fee: null,
  management_fee: null,
  hurdle: null,
  portfolio_weights: [defaultPortfolioWeight],
  benchmarks: [],
}

const PortfolioContext = createContext()

const PortfolioContextProvider = (props) => {
  const [portfolio, setPortfolio] = useState(cloneDeep(defaultState))
  const clonePortfolio = (payload) => {
    setPortfolio(payload)
  }
  const resetPortfolio = () => {
    setPortfolio(cloneDeep(defaultState))
  }

  return (
    <PortfolioContext.Provider
      value={{
        single_portfolio: portfolio,
        clonePortfolio,
        resetPortfolio,
        setPortfolio,
      }}
    >
      {props.children}
    </PortfolioContext.Provider>
  )
}

export { PortfolioContext, PortfolioContextProvider }
