export const PRIVATE_EQUITY_FUNDS_FETCHED = 'PRIVATE_EQUITY_FUNDS_FETCHED'

export const USER_LOGOUT = 'USER_LOGOUT'

export const UPDATE_SELECTED_SECURITY_LIST = 'UPDATE_SELECTED_SECURITY_LIST'
export const UPDATE_PORTFOLIO_LIST = 'UPDATE_PORTFOLIO_LIST'
export const UPDATE_PORTFOLIO_AND_LIST = 'UPDATE_PORTFOLIO_AND_LIST'
export const UPDATE_AAAP_CELL_LIST = 'UPDATE_AAAP_CELL_LIST'
export const UPDATE_MARKET_INDEX_LIST = 'UPDATE_MARKET_INDEX_LIST'
export const UPDATE_HEDGE_FUND_LIST = 'UPDATE_HEDGE_FUND_LIST'
export const UPDATE_HEDGE_FUNDS_WITH_STATS = 'UPDATE_HEDGE_FUNDS_WITH_STATS'
export const UPDATE_WF_PORTFOLIO_LIST = 'UPDATE_WF_PORTFOLIO_LIST'
export const UPDATE_FOF_SHARE_CLASSES = 'UPDATE_FOF_SHARE_CLASSES'
