import React, { useState, useEffect } from 'react'
import { MaterialTransferList, SwitchContol } from '../../components'
import Typography from '@material-ui/core/Typography'
import { sortBy } from 'lodash'

import {
  getPermissions,
  getAaapCells,
  updateGroups,
  getHedgeFundList,
  getWfPortfolioList,
  getFofShareClasses,
  getPrivateEquityData,
} from '../../services'

export default function GroupManager({
  selectedGroup,
  setSelectedGroup,
  groupList,
  setGroupList,
}) {
  const [availablePermissions, setPermissions] = useState([])
  const [availableSecurities, setSecurities] = useState([])
  const [availableFunds, setAvailableFunds] = useState([])
  const [isUnderlying, setIsUnderlying] = useState(false)
  const {
    name = 'No Group Selected',
    allowed_securities = [],
    permissions = [],
  } = selectedGroup || {}

  const getSelectors = async () => {
    const [perms, secs, funds, ports, fofs, pe] = await Promise.all([
      getPermissions(),
      getAaapCells({ mode: 'maintenance' }),
      getHedgeFundList(),
      getWfPortfolioList(),
      getFofShareClasses({ mode: 'all' }),
      getPrivateEquityData(),
    ])

    const peShareClasses = pe.data.filter((o) => o.underlying_fund !== null)
    const peUndelyingFunds = pe.data.filter((o) => o.underlying_fund === null)

    const valid_providers = ['Webfolio', 'Webfolio (FoF Performance)']
    const valid_funds = funds.data.filter(
      (o) => valid_providers.indexOf(o.primary_vendor_name) !== -1,
    )
    setPermissions(sortBy(perms.data, 'name').map((o) => o.name))
    setSecurities([
      ...sortBy(secs.data, 'name').map((o) => o.name),
      ...sortBy(peShareClasses, 'name').map((o) => o.name),
    ])
    setAvailableFunds([
      ...fofs.data.map((o) => o.name),
      ...ports.data.map((o) => o.name),
      ...sortBy(valid_funds).map((o) => o.name),
      ...sortBy(peUndelyingFunds, 'name').map((o) => o.name),
    ])
  }
  const updateSelectedGroup = async (list, name) => {
    try {
      const updSelectedGroup = { ...selectedGroup, [name]: list }
      const response = await updateGroups(updSelectedGroup)
      const group = response.data
      setSelectedGroup(group)
      let newGroupList = [...groupList]
      const idx = newGroupList.findIndex((o) => o.id === group.id)
      newGroupList.splice(idx, 1, group)
      setGroupList(newGroupList)
    } catch (error) {}
  }

  useEffect(() => {
    getSelectors()
  }, [])

  const fund_list = isUnderlying ? availableFunds : availableSecurities

  return (
    <div className="group-manager">
      <Typography align="center">{name}</Typography>
      {selectedGroup && (
        <>
          <div className="manage-container switch-control">
            <SwitchContol
              checked={isUnderlying}
              handleChange={(e) => setIsUnderlying(e.target.value)}
              label="Show Underlying"
              color="primary"
            />
          </div>
          <div className="manage-container">
            <MaterialTransferList
              options={fund_list}
              selected_list={sortBy(allowed_securities)}
              update_list_func={(e) => {
                updateSelectedGroup(e, 'allowed_securities')
              }}
              label="Search Securities"
            />
          </div>
          <div className="manage-container">
            <MaterialTransferList
              options={availablePermissions}
              selected_list={permissions}
              update_list_func={(e) => {
                updateSelectedGroup(e, 'permissions')
              }}
              label="Search Permissions"
            />
          </div>
        </>
      )}
    </div>
  )
}
