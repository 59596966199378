import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { Box, Card } from "@material-ui/core";

const clean_names = {
  credit_correlation: "Credit Correlation",
  directionality: "Directionality",
  equity_correlation: "Equity Correlation",
  leverage: "Leverage",
  volatility: "Volatility",
};

export default class classificationTable extends Component {
  render() {
    const { data = {} } = this.props;
    return (
      <Box p={1}>
        <Card>
          <div className="classification-table">
            <Typography variant="h6" align="center">
              Fund Classification
            </Typography>
            <Table>
              <TableBody>
                {Object.keys(clean_names).map((name, id) => (
                  <TableRow key={id}>
                    <TableCell>{clean_names[name]}</TableCell>
                    <TableCell>{data[name]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Card>
      </Box>
    );
  }
}
