import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import ErrorIcon from '@material-ui/icons/Error'
import {
  Input,
  NumericTextBox,
  MaskedTextBox,
} from "@progress/kendo-react-inputs"
import MuiInput from '@material-ui/core/Input'

import { useField, useFormikContext } from "formik"
import ErrorMessage from "./ErrorMessage"
import { Box, IconButton, InputAdornment } from "@material-ui/core"
import { aamui } from "../../styles/AppTheme"

const StyledTextField = styled(Input)`
  background: ${(props) => props.background || "white"};
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => props.inputMargin || "auto"};
`

const StyledNumericField = styled(NumericTextBox)`
  background: ${(props) => props.background || "white"};
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => props.inputMargin || "auto"};
  font-size: 16px;
  display: flex;
  align-items: center;

  & input {
    height: 49px;
    border: 1px solid ${aamui.sapphire};
    color: ${aamui.sapphire};
    padding-left: 12px;
  }

  & span {
    border: none !important;
  }
`

const StyledMaskedTextBox = styled(MaskedTextBox)`
  background: ${(props) => props.background || "white"};
  width: ${(props) => props.width || "100%"};
  margin: ${(props) => props.inputMargin || "auto"};
`

const MuiInputWrapper = styled.div`
  position: relative;
  padding-bottom: 32px;

  ${(({ error }) => error
    && css`
      .Mui-error {
        border: 2px solid #FF4D00 !important;
      }

      .MuiIconButton-label {
        color: #FF4D00;
      }
    `
  )}
`

const RequiredAsterisk = styled.span`
  color: red;
  font-size: 16px;
  left: 6px;
  top: 13px;
  position: absolute;
  z-index: 100;
`

const MuiErrorMessage = styled.div`
  position: absolute;
  left: 16px;
  top: calc(100% - 26px);
  font-size: 12px;
  color: #bbd6eb;
`

const StyledMuiInput = ({
  error,
  className,
  endAdornment,
  ...props
}) => (
  <MuiInputWrapper
    className={className}
    error={!!error}
    required={props.required}
  >
    {props.required && <RequiredAsterisk>*</RequiredAsterisk>}
    <MuiInput
      {...props}
      endAdornment={
        error
          ? (
            <InputAdornment position="end">
              <IconButton disabled>
                <ErrorIcon />
              </IconButton>
            </InputAdornment>
          )
          : endAdornment
      }
      error={!!error}
    />
    {
      error &&
      <MuiErrorMessage>
        {error}
      </MuiErrorMessage>
    }
  </MuiInputWrapper>
)

export default ({ name, type, isNew, wrapperProps, ...props }) => {
  let Input
  switch (type) {
    case "text":
      Input = isNew ? StyledMuiInput : StyledTextField
      break
    case "number":
      Input = StyledNumericField
      break
    case "masked":
      Input = StyledMaskedTextBox
      break
    default:
      Input = isNew ? StyledMuiInput : StyledTextField
      break
  }
  const [field, meta] = useField({ name, ...props })
  return (
    <Box {...wrapperProps}>
      <Input
        {...props}
        {...field}
        error={meta.error}
        type={type}
      />
      {!isNew && meta.touched && meta.error && <ErrorMessage error={meta.error} />}
    </Box>
  )
}

export const BulkTextField = ({ name, bulkArray, bulkField, ...props }) => {
  const { values, setValues } = useFormikContext()
  const value = values[name] || ""

  const bulkChange = (e, name) => {
    const { value: newValue } = e.target
    const { [bulkArray]: modArray } = values
    let newArray = [...modArray]

    for (let i = 0; i < newArray.length; i++) {
      let element = newArray[i]
      element[bulkField] = newValue
    }
    setValues({ ...values, [bulkArray]: newArray, [name]: newValue })
  }

  return (
    <StyledTextField
      name={name}
      onChange={(e) => {
        bulkChange(e, name)
      }}
      value={value || ""}
      {...props}
    />
  )
}
