import React from "react";

import { RichText } from "../editors";
import { useField } from "formik";
import ErrorMessage from "./ErrorMessage";

export default ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers || {};
  return (
    <>
      <RichText {...field} {...props} onChange={(value) => setValue(value)} />
      {meta.touched && meta.error && <ErrorMessage error={meta.error} />}
    </>
  );
};
