import React, { Component } from "react";
import { GroupContainer } from "../groupsCompanies";

export default class userGroups extends Component {
  render() {
    return (
      <div>
        <GroupContainer />
      </div>
    );
  }
}
