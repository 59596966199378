import { Box, Typography } from '@material-ui/core'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import React from 'react'

import LoadingSpinner from '../../components/misc/LoadingSpinner'
import { HUBSPOT_PROXY_URL } from '../../constants/env'
import { getHubspotInsightsUrl } from '../../hooks/useInsights'

const FactSheetUserGuide = () => {
  const { data, isLoading } = useQuery(['factsheets', 'user guide'], () => axios.post(
    HUBSPOT_PROXY_URL,
    {
      method: 'GET',
      url: getHubspotInsightsUrl({ childrenPath: `/${process.env.REACT_APP_USER_GUIDE_HS_POST_ID}` }),
    },
  ))

  return (
    <Box
      className="insights-content"
      maxWidth="995px"
      mx="auto"
    >
      <div className="insights-content-inner-wrapper">
        <Box mb={2}>
          <Typography
            className="insights-content-header"
            variant="h3"
          >
            User Guide
          </Typography>
        </Box>
        {
          isLoading
            ? <LoadingSpinner />
            : (
              <div
                dangerouslySetInnerHTML={{ __html: data?.data?.postBody }}
              />
            )
        }
      </div>
    </Box>
  )
}

export default FactSheetUserGuide
