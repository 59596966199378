import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useQuery } from '@tanstack/react-query'
import { formatNumber } from '@telerik/kendo-intl'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import ActionButton from '../../components/tables/ActionButton'
import PlainTable from '../../components/tables/plainTable'
import { client_available_benchmarks } from '../../data/analysis'
import { useSecurities } from '../../hooks'
import {
  getDocuments,
  getMarketOverview,
  getPerformanceOverview,
} from '../../services'
import { dateTimeManager } from '../../utils'

export default function AnalyticsTab() {
  const { search_list: searchList } = useSecurities()
  const { data: marketOverview, isLoading: isLoadingMarkOverview } = useQuery(
    ['analytics', 'market overview'],
    async () => {
      const res = await getMarketOverview()
      const { summary: data, stats_date: date } = res.data

      const marketData = data.filter(
        (o) =>
          client_available_benchmarks.indexOf(o.monitor_display_name) !== -1,
      )
      const marketDate = dateTimeManager.dbToFormat(date)

      const benchmarks = marketData?.map((o) => ({
        Index: o.monitor_display_name,
        '1DCh': formatNumber(o['1DCh'], 'p2'),
        WTD: formatNumber(o.WTD, 'p2'),
        MTD: formatNumber(o.MTD, 'p2'),
        QTD: formatNumber(o.QTD, 'p2'),
        YTD: formatNumber(o.YTD, 'p2'),
      }))

      return {
        benchmarks: benchmarks || [],
        marketDate,
      }
    },
    {
      staleTime: Infinity,
    },
  )
  const { data: documents, isLoading: isLoadingDocs } = useQuery(
    ['analytics', 'latest review'],
    async () => {
      const response = await getDocuments({
        story_type__in: '1,4,6,13,26',
        ordering: '-as_of_date',
      })
      const docs = response.data.results
      return docs?.map((o) => {
        const {
          security_name: nullable_sec_name,
          as_of_date,
          body,
          attachment,
          story_type,
          id,
        } = o
        const security_name = nullable_sec_name || 'No fund'
        let button_list = []
        if (body) {
          button_list.push(
            <ActionButton
              color="default"
              component={Link}
              key="body"
              size="small"
              to={`/insights/${id}?o=t`}
            >
              <InfoOutlinedIcon style={{ fontSize: 20 }} />
            </ActionButton>,
          )
        }
        if (attachment) {
          button_list.push(
            <ActionButton
              color="default"
              href={attachment}
              key="attachment"
              size="small"
              target="_blank"
            >
              <CloudDownloadOutlinedIcon style={{ fontSize: 20 }} />
            </ActionButton>,
          )
        }
        const actions = (
          <TableCell className="btn-cell" key={id}>
            {button_list}
          </TableCell>
        )
        return {
          Fund: security_name,
          Document: story_type,
          Date: dateTimeManager.dbToLongFormat(as_of_date),
          Action: { element: actions },
        }
      })
    },
    {
      staleTime: Infinity,
    },
  )
  const { data: performanceOverview, isLoading: isLoadingPerformanceOverview } =
    useQuery(
      ['analytics', 'performance overview'],
      async () => {
        const response = await getPerformanceOverview({ mode: 'FoF Only' })
        return response?.data?.map(
          (o) =>
            ({
              Fund: o.index,
              Date: o.Date,
              MTD: formatNumber(o.MTD, 'p2'),
              YTD: formatNumber(o.YTD, 'p2'),
              link: `/asset-detail/${o.fund_id}/`,
            } || []),
        )
      },
      {
        staleTime: Infinity,
      },
    )

  return (
    <Grid className="lt-analytics-container" container spacing={3}>
      <Grid className="lt-performance-one" item lg={6} xs={12}>
        <Card>
          <CardContent>
            <div className="card-title">
              <Typography variant="h6">Performance Overview</Typography>
              <Button
                className="view-all-button"
                color="primary"
                component={Link}
                endIcon={<ChevronRightIcon />}
                to="/performance-overview?view=FoF"
              >
                View All
              </Button>
            </div>
            <PlainTable
              data={
                isLoadingPerformanceOverview
                  ? Array(7).fill({ Name: '', Date: '', MTD: '', YTD: '' })
                  : performanceOverview
              }
              isLoading={isLoadingPerformanceOverview}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid className="lt-document-feed" item lg={6} xs={12}>
        <Card>
          <CardContent>
            <div className="card-title">
              <Typography variant="h6">Latest review & notes</Typography>
              <Button
                className="view-all-button"
                color="primary"
                component={Link}
                endIcon={<ChevronRightIcon />}
                to="/document-management"
              >
                View All
              </Button>
            </div>
            <PlainTable
              data={
                isLoadingDocs
                  ? Array(7).fill({
                      Fund: '',
                      Document: '',
                      Date: '',
                      Action: '',
                    })
                  : documents || []
              }
              isLoading={isLoadingDocs}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid className="lt-index-monitor" item lg={6} xs={12}>
        <Card>
          <CardContent>
            <div className="card-title">
              <Typography variant="h6">
                Index Monitor as of {marketOverview?.marketDate}
              </Typography>
              <Button
                className="view-all-button"
                color="primary"
                component={Link}
                endIcon={<ChevronRightIcon />}
                to="/index-monitors?view=index_monitor"
              >
                View All
              </Button>
            </div>
            <PlainTable
              data={
                isLoadingMarkOverview
                  ? Array(7).fill({
                      Index: '',
                      '1DCh': '',
                      WTD: '',
                      MTD: '',
                      QTD: '',
                      YTD: '',
                    })
                  : marketOverview?.benchmarks || []
              }
              isLoading={isLoadingMarkOverview}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
