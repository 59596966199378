import { useMutation, useQuery } from '@tanstack/react-query'
import mixpanel from 'mixpanel-browser'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useSecurities } from '../../hooks'
import { useMixpanel } from '../../hooks/useMixpanel'
import { createPortfolioOptimiserRun, getPortfolioOptimiserRun } from '../../services'
import usePortfolioOptimiserSerialiser from './hooks/usePortfolioOptimisationSerialiser'

const PortfolioOptimiserContext = React.createContext({})
export const usePortfolioOptimiser = () => {
  const context = React.useContext(PortfolioOptimiserContext)
  if (context === undefined) {
    return {}
  }
  return context
}
const defaultPollingDuration = 10000
const FETCH_MODE = {
  DONE: 'DONE',
  FETCH_ONCE: 'FETCH_ONCE',
  INITIAL: 'INITIAL',
  POLLING: 'POLLING',
}

export const runStatuses = {
  COMPLETE: 'COMPLETE',
  CREATED: 'CREATED',
  FAILED: 'FAILED',
  RUNNING: 'RUNNING',
}

const runFinishedStatuses = Object.values(runStatuses)
const runCompletedStatuses = [runStatuses.COMPLETE, runStatuses.CREATED]
const mixpanelTimeRunOptimiserEventName = 'Run Portfolio Optimiser'

const isRunFinished = (res) => runFinishedStatuses.includes(res?.data?.data?.status)

const PortfolioOptimiserProvider = ({
  children,
}) => {
  const { mpTrack } = useMixpanel()
  const { search_list: searchList } = useSecurities()
  const history = useHistory()
  const {
    deserialiseOptimiserRunData,
    serialiseOptimiserRunData,
  } = usePortfolioOptimiserSerialiser()
  const { runId: paramRunId } = useParams()
  const [fetchMode, setFetchMode] = useState({ mode: FETCH_MODE.INITIAL })
  const [runDataResponse, setRunDataResponse] = useState()
  const [openRerunDialog, setOpenRerunDialog] = useState(false)
  const [reRunFormData, setReRunFormData] = useState()
  const intervalIdRef = useRef()
  const runData = useMemo(
    () => (runDataResponse?.data && searchList?.length > 0 ? deserialiseOptimiserRunData(runDataResponse?.data) : null),
    [runDataResponse, searchList],
  )

  const { isFetching: isFetchingRun, refetch: fetchRun } = useQuery(
    ['optimiser run', fetchMode?.runId],
    () => getPortfolioOptimiserRun(fetchMode?.runId),
    { enabled: false },
  )

  const {
    error: submitFormError,
    isLoading: isSubmittingForm,
    mutateAsync: createRun,
  } = useMutation((data) => createPortfolioOptimiserRun(data))

  useEffect(() => {
    /**
     * If the page is new run page, we will not fetch any data
     */
    if (fetchMode?.mode === FETCH_MODE.INITIAL && paramRunId) {
      setFetchMode({ mode: FETCH_MODE.FETCH_ONCE, runId: paramRunId })
    }
  }, [paramRunId, fetchMode])

  const stopPolling = () => {
    setFetchMode({ mode: FETCH_MODE.DONE })
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = undefined
    }
  }

  const checkAndHandleRunFinished = (res) => {
    const runFinished = isRunFinished(res)

    if (runFinished) {
      stopPolling()
      setRunDataResponse(res)
    }

    return runFinished
  }

  const startPolling = () => {
    const pollingFunc = async () => {
      const res = await fetchRun()
      checkAndHandleRunFinished(res)
    }

    const id = setInterval(pollingFunc, defaultPollingDuration)
    intervalIdRef.current = id
  }

  useEffect(() => {
    return () => {
      stopPolling()
    }
  }, [intervalIdRef])

  const submitForm = async (data) => {
    const serialisedData = serialiseOptimiserRunData(data)
    const runId = serialisedData.portfolio_optimiser_run_id

    try {
      mixpanel.time_event(mixpanelTimeRunOptimiserEventName)
      setFetchMode({ mode: FETCH_MODE.POLLING, runId })
      const res = await createRun(serialisedData)
      checkAndHandleRunFinished({ data: res })
      history.replace(`/portfolio-optimiser/${runId}`)
      mixpanel.track(mixpanelTimeRunOptimiserEventName, {
        'Is Re-run': !!paramRunId,
        'Number of Assets': serialisedData.assets?.length,
        'Objective Function': serialisedData.objective_function,
        'Rebalancing Frequency': serialisedData.rebalancing_frequency,
        'Run ID': runId,
        'Run Name': serialisedData.name,
        'Visbility': serialisedData.visibility_type,
      })
    } catch (err) {
      stopPolling()
    }
  }

  useEffect(() => {
    /**
     * All fetching requests start here
     */
    const handleFetchRequest = async () => {
      switch (fetchMode?.mode) {
        case FETCH_MODE.POLLING: {
          startPolling()
          break
        }
        case FETCH_MODE.FETCH_ONCE: {
          try {
            const res = await fetchRun()
            const runId = res?.data?.data?.portfolio_optimiser_run_id

            if (!runId) {
              throw new Error('Run ID not found')
            }

            if (!checkAndHandleRunFinished(res)) {
              setFetchMode({ mode: FETCH_MODE.POLLING, runId })
            }
          } catch (err) {
            history.replace('/404')
          }
          break
        }
        default:
          break
      }
    }

    handleFetchRequest()
  }, [fetchMode])

  useEffect(() => {
    if (paramRunId) {
      mpTrack({
        eventName: 'View Portfolio Optimiser Run',
        properties: {
          'Name': runData?.name,
          'Run ID': paramRunId,
          'Run Started At': runData?.startedAt,
          'Visibility': runData?.visibilityType,
        }
      })
    }
  }, [paramRunId])

  const derivedProviderValues = {
    hasFailedError: runData?.result && (runData?.result?.status !== 200 || (runData?.result?.converged === false && !runData?.result?.weights?.PyPortfolioOpt)),
    isNewRunPage: !paramRunId,
    isPolling: fetchMode?.mode === FETCH_MODE.POLLING,
    isRunCompleted: runCompletedStatuses.includes(runData?.status || ''),
    isRunRunning: runData?.status === runStatuses.RUNNING,
    isSubmittingOrFetchingRun: isSubmittingForm || isFetchingRun,
  }

  return (
    <PortfolioOptimiserContext.Provider
      value={{
        isSubmittingForm,
        openRerunDialog,
        reRunFormData,
        runData,
        setOpenRerunDialog,
        setReRunFormData,
        setRunDataResponse,
        submitForm,
        submitFormError,
        ...derivedProviderValues,
      }}
    >
      {children}
    </PortfolioOptimiserContext.Provider>
  )
}

export default PortfolioOptimiserProvider
