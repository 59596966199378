import React, { useState, useContext } from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";

import DesktopToolbar from "./desktopToolbar";
import MenuList from "./menuList";
import PersistentDrawer from "./persistentDrawer";
import MobileDrawer from "./mobileDrawer";
import PageHeader from "./pageHeader";
import Footer from "./footer";
import { useProfile, useSecurities } from "../../hooks";
import { AuthContext } from "../../contexts";
import { useLocation } from "react-router-dom";
import { HEDGE_FUND_PATH, PRIVATE_MARKET_BASE_PATH } from "../../constants/routes.constants";

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: '0px 4px 4px rgba(10, 40, 57, 0.2)',
    height: 96,
    display: 'flex',
    justifyContent: 'center'
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
});

const disableBgImgPage = new Set(["/peer-groups"]);
const fullWidthContentPage = new Set(["/peer-groups"]);

function Navigation({ classes, ...props }) {
  const { hash, pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const profile = useProfile()
  const { search_list } = useSecurities()
  const { joyrideOpen: joyride_open, setJoyrideOpen } = useContext(AuthContext)
  const disableFooter = disableBgImgPage.has(pathname)
  const isLandingPage = pathname === '/' && !hash
  const isServiceHomePage = pathname === PRIVATE_MARKET_BASE_PATH || pathname === HEDGE_FUND_PATH
  const toggleDrawer = setOpen;

  const runJoyride = () => {
    setJoyrideOpen(true);
  };

  return (
    <React.Fragment>
      <div className="nav-bar">
        <CssBaseline />
        <AppBar position="fixed" className={clsx(classes.appBar)}>
          <DesktopToolbar
            search_list={search_list}
            {...props}
            profile={profile}
            toggleDrawer={setOpen}
            runJoyride={runJoyride}
            joyride_open={joyride_open}
          />
        </AppBar>
        {
          !isLandingPage && (
            <>
              <Hidden mdDown>
                <PersistentDrawer
                  toggleDrawer={toggleDrawer}
                  open={open}
                  profile={profile}
                >
                  <MenuList {...props} open={open} profile={profile} />
                </PersistentDrawer>
              </Hidden>
              <Hidden lgUp>
                <MobileDrawer
                  toggleDrawer={toggleDrawer}
                  open={open}
                  profile={profile}
                >
                  <MenuList
                    onMenuClick={() => {
                      setOpen(false);
                    }}
                    {...props}
                    profile={profile}
                    open={open}
                  />
                </MobileDrawer>
              </Hidden>
            </>
          )
        }
        <main className={clsx('content', { 'landing-page-content': isLandingPage, 'full-width-content': fullWidthContentPage.has(pathname) })}>
          {
            (!isLandingPage && !isServiceHomePage) &&
            <PageHeader {...props} />
          }
          {props.children}
        </main>
      </div>
      {
        !disableFooter && (
          <Footer />
        )
      }
    </React.Fragment>
  );
}

export default withStyles(styles, { withTheme: true })(Navigation);
