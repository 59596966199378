import moment from "moment";

export default class ReturnsProcessor {
  static processReturns = (data, end, fields) => {
    if (data.length > 0) {
      let data_list = [];
      for (let i = 0; i < data.length; i++) {
        data_list.splice(i, 0, moment(data[i]["perf_date"]));
        data[i]["date"] = moment(data[i]["perf_date"]).format("MMM-YY");
      }

      let dateStart = data_list[data_list.length - 1]
        .add(1, "month")
        .endOf("month")
        .startOf("day");
      let dateEnd = moment(end).endOf("month").startOf("day");
      let emptyDates = [];
      while (
        dateEnd > dateStart ||
        dateStart.format("M") === dateEnd.format("M")
      ) {
        emptyDates.push({
          perf_date: dateStart.format("YYYY-MM-DD"),
          perf_return: null,
          date: dateStart.format("MMM-YY"),
          ...fields,
        });
        dateStart.add(1, "month").endOf("month").startOf("day");
      }
      data = data.concat(emptyDates);
      return data;
    } else {
      let data = [];
      return data;
    }
  };

  static resetInceptionDate = (
    performanceData,
    end,
    inception_date,
    fields
  ) => {
    let data = [];
    if (performanceData.length === 0) {
      let dateStart = moment(inception_date).endOf("month").startOf("day");
      let dateEnd = moment(end).endOf("month").startOf("day");
      while (
        dateEnd > dateStart ||
        dateStart.format("M") === dateEnd.format("M")
      ) {
        data.push({
          perf_date: dateStart.format("YYYY-MM-DD"),
          perf_return: null,
          date: dateStart.format("MMM-YY"),
          ...fields,
        });
        dateStart.add(1, "month").endOf("month").startOf("day");
      }
    } else {
      let dateStart = moment(inception_date).endOf("month").startOf("day");
      let dateEnd = moment(performanceData[0]["perf_date"])
        .add(-1, "month")
        .endOf("month")
        .startOf("day");
      let emptyDates = [];
      while (
        dateEnd > dateStart ||
        dateStart.format("M") === dateEnd.format("M")
      ) {
        emptyDates.push({
          perf_date: dateStart.format("YYYY-MM-DD"),
          perf_return: null,
          date: dateStart.format("MMM-YY"),
          ...fields,
        });
        dateStart.add(1, "month").endOf("month").startOf("day");
      }
      data = emptyDates.concat(performanceData);
    }
    return data;
  };
}
