import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios';
import { VERCEL_API_PROXY_URL } from '../constants/env';
import { managePopupStatus } from '../services';

// Combined function to fetch or update popup status
// const managePopupStatus = async ({ userId, dismissed }: { userId: string; dismissed?: boolean }) => {
//   const response = await axios.post(`${VERCEL_API_PROXY_URL}/popup-status`, {
//     userId,
//     ...(dismissed !== undefined && { dismissed }),
//   });
//   return response.data.showPopup;
// };

export const usePopupStatus = (userId: string) => {
  const queryClient = useQueryClient();

  // Query to fetch the initial popup status
  const popupQuery = useQuery(['popup-status', userId], async () => {
    if (!userId) throw new Error('User ID is required');
    return await managePopupStatus({ userId });
  });

  // Mutation to update the popup status
  const popupMutation = useMutation(
    async (dismissed: boolean) => {
      const res = await managePopupStatus({ userId, dismissed });
      return res.data.showPopup
    },
    {
      onSuccess: async (data) => {
        // Update the query data after successful mutation
        await queryClient.setQueryData(['popup-status', userId], data);
      },
    }
  );

  return {
    showPopup: popupQuery.data,
    isLoading: popupQuery.isLoading,
    isError: popupQuery.isError,
    updatePopupStatus: async (dismissed: boolean) => await popupMutation.mutateAsync(dismissed),
    isUpdating: popupMutation.isLoading,
  };
};