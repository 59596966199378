import React from 'react'
import Typography from '@material-ui/core/Typography'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
// import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Form from './builderForm'

export default function StepperContainer({
  submitForm,
  fullAccess,
  partialAccess,
  setActiveStep,
  activeStep,
  saving,
  runName,
  runId,
  ...props
}) {
  const steps = ['Name Your Portfolio', 'Allocate Funds', 'Select Benchmarks']

  if (fullAccess) {
    steps.push('Fees & Rebalancing (Optional)')
  }

  const last = activeStep === steps.length - 1
  const allowSubmit = activeStep >= 2

  const hasNoErrors = (validation) => Object.keys(validation).length === 0

  const moveForward = async () => {
    const validation = await props.validateForm()
    if (hasNoErrors(validation)) {
      setActiveStep((i) => i + 1)
    } else {
    }
    props.setTouched(validation)
  }

  return (
    <Card>
      <div className="card-header">
        <Typography
          align="center"
          variant="h6"
        >
          Build Your Portfolio
        </Typography>
        {
          runName && (

            <Typography
              align="center"
              variant="subtitle1"
            >
              Derived from optimiser run -
              {' '}
              <Box
                color="black"
                component={Link}
                to={`/portfolio-optimiser/${runId}`}
              >
                {runName}
              </Box>
            </Typography>
          )
        }
      </div>
      <CardContent>
        <div className="stepper-container">
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>
                  <Typography>{label}</Typography>
                </StepLabel>
                <StepContent
                  transitionDuration={0}
                  TransitionProps={{ unmountOnExit: false }}
                >
                  <Form
                    {...props}
                    activeStep={activeStep}
                    fullAccess={fullAccess}
                    partialAccess={partialAccess}
                  />
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="control-container">
          <ButtonGroup>
            <Button
              disabled={activeStep === 0 || saving}
              onClick={() => {
                setActiveStep((i) => i - 1)
              }}
            >
              Back
            </Button>

            <Button
              color="primary"
              disabled={last || saving}
              onClick={moveForward}
              variant="contained"
            >
              Next
            </Button>
          </ButtonGroup>
          <Button
            color="primary"
            disabled={!allowSubmit || saving}
            onClick={submitForm}
            variant="contained"
          >
            {saving ? 'Saving....' : 'Save Portfolio'}
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}
