import React from "react";
import Dropzone from "react-dropzone";
import { useFormikContext, useField } from "formik";
import ErrorMessage from "./ErrorMessage";
import { Zone } from "../misc";
import { toast } from "react-toastify";

export const SingleFileDrop = ({
  name,
  label,
  fieldOverride = [],
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();

  const { value } = field || {};
  const { name: fileName } = value || {};
  const { setValue } = helpers || {};
  return (
    <>
      <Dropzone
        className="form-dropzone"
        onDrop={(files) => {
          const file = files[0];
          const { name: uploadName } = file || {};
          setValue(file);
          fieldOverride.forEach((f) => {
            setFieldValue(f, uploadName);
          });
        }}
        {...field}
      >
        {({ getRootProps, getInputProps }) => (
          <Zone {...getRootProps()} {...props}>
            <input {...getInputProps()} />
            {fileName || label}
          </Zone>
        )}
      </Dropzone>
      {meta.touched && meta.error && <ErrorMessage error={meta.error} />}
    </>
  );
};

export default ({ label, name, fields, limit, ...props }) => {
  const { setValues, values } = useFormikContext();
  const maxFiles = limit || 40;
  return (
    <Dropzone
      onDrop={(files) => {
        let file_array = [];
        const count = files.length;
        if (count > maxFiles) {
          toast.error(
            `You are trying to upload ${count}. The limit is ${maxFiles}.`
          );
          return;
        }
        files.forEach((f) => {
          file_array.push({ name: f.name, attachment: f, ...fields });
        });
        setValues({ ...values, [name]: file_array });
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Zone {...getRootProps()}>
          <input {...getInputProps()} />
          {label}
        </Zone>
      )}
    </Dropzone>
  );
};
