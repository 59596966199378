import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import React, { createContext, useContext, useState } from 'react'

import { getTradingCalendarCalculationResult } from '../../services'
import convertSnakeToCamel from '../../utils/convertSnakeToCamel'
import { TradingCalendarDataResponseData, TradingCalendarInputFormApiRequestData } from './models'


interface TradingCalendarContextType {
  submitTradingCalendarInput: UseMutateAsyncFunction<AxiosResponse<TradingCalendarDataResponseData>, unknown, TradingCalendarInputFormApiRequestData, unknown>;
  tradingCalendarResult: TradingCalendarDataResponseData;
  isLoadingTradingCalendarResult: boolean;
  resultDayType: any;
  setResultDayType: React.Dispatch<React.SetStateAction<any>>;
  lastFormValues: TradingCalendarInputFormApiRequestData | null;
  setLastFormValues: React.Dispatch<React.SetStateAction<TradingCalendarInputFormApiRequestData | null>>;
}

const TradingCalendarContext = createContext<TradingCalendarContextType | undefined>(undefined)

export const useTradingCalendar = (): TradingCalendarContextType => {
  const context = useContext(TradingCalendarContext)

  if (!context) {
    throw new Error("useTradingCalendar must be used within a TradingCalendarProvider")
  }

  return context
}

const TradingCalendarProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    data: tradingCalendarResponse,
    isLoading: isLoadingTradingCalendarResult,
    mutateAsync: submitTradingCalendarInput
  } = useMutation(
    (params: TradingCalendarInputFormApiRequestData) => getTradingCalendarCalculationResult(params)
  )
  const [resultDayType, setResultDayType] = useState()
  const [lastFormValues, setLastFormValues] = useState<TradingCalendarInputFormApiRequestData | null>(null)

  return (
    <TradingCalendarContext.Provider
      value={{
        isLoadingTradingCalendarResult,
        lastFormValues,
        resultDayType,
        setLastFormValues,
        setResultDayType,
        submitTradingCalendarInput,
        tradingCalendarResult: convertSnakeToCamel(tradingCalendarResponse?.data)
      }}
    >
      {children}
    </TradingCalendarContext.Provider>
  )
}

export default TradingCalendarProvider
