import React, { useCallback } from 'react'
import GenericTable from './genericTable'
import { strategies } from '../../data/funds'

const PraTable = (props) => {
  const { data, aggregated, index_col = 'index', loading, children } = props

  const rowRender = useCallback(
    (trElement, props) => {
      const { dataItem } = props
      const { [index_col]: line_name } = dataItem || {}
      const aggregates = strategies.indexOf(line_name) !== -1
      const aggregate_class_name = aggregates ? 'aggregate-row' : ''
      const class_name = `${trElement.props.className} ${aggregate_class_name}`
      const trProps = { className: class_name }
      return React.cloneElement(
        trElement,
        { ...trProps },
        trElement.props.children,
      )
    },
    [index_col],
  )

  const filterStrategy = useCallback(() => {
    if (!aggregated) {
      return data
    }
    return data.filter((element) => {
      const { [index_col]: line_name } = element || {}
      return strategies.indexOf(line_name) !== -1
    })
  }, [aggregated, data, index_col])

  const filteredData = filterStrategy()

  return (
    <div className="portfolio-table">
      <GenericTable
        loading={loading}
        scrollable="scrollable"
        index_width={`250px`}
        rowRender={rowRender}
        noAltColor
        {...props}
        data={filteredData}
      >
        {children}
      </GenericTable>
    </div>
  )
}

export default PraTable
