import React, { useContext } from 'react'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'
import { NumericTextBox } from '@progress/kendo-react-inputs'
import { cloneDeep } from 'lodash'
import { toast } from 'react-toastify'

const TableContext = React.createContext()

const TableContextProvider = (props) => {
  return (
    <TableContext.Provider
      value={{
        setData: props.setData,
      }}
    >
      {props.children}
    </TableContext.Provider>
  )
}

const PercentageCell = (props) => {
  const { dataItem, onChange, field, dataIndex } = props
  const { setData } = useContext(TableContext)
  const value = dataItem[field]
  const handlePaste = (e) => {
    e.preventDefault()
    e.target.blur()
    const pasted = e.clipboardData.getData('text')
    const rows = pasted.split('\n')
    rows.pop()
    let values = []
    for (let i = 0; i < rows.length; i++) {
      const element = rows[i]
      let cleanNumber
      if (element.includes('%')) {
        cleanNumber = Number(
          (Number(element.replace('%', '')) / 100).toFixed(4),
        )
      } else {
        cleanNumber = Number(element)
      }
      values.push(cleanNumber)
    }
    setData((existing) => {
      const newValuesLength = values.length
      const dataSliceLength = existing.slice(dataIndex, existing.length).length
      if (newValuesLength > dataSliceLength) {
        toast.error('The track you are pasting is longer than expected')
        return existing
      }

      const updated = cloneDeep(existing)
      for (let i = 0; i < values.length; i++) {
        updated[dataIndex + i][field] = values[i]
      }
      return updated
    })
  }

  const handleChange = (e) => {
    const { value } = e.target
    onChange({
      dataItem,
      field,
      syntheticEvent: e.syntheticEvent,
      value,
    })
  }

  return (
    <td onPaste={handlePaste}>
      <NumericTextBox
        value={value}
        spinners={false}
        format="p2"
        onChange={handleChange}
      />
    </td>
  )
}

export default function KendoPerformanceGrid({
  data,
  setData,
  indexCol,
  numberCol,
  ...props
}) {
  const itemChange = ({ value, field, dataItem, pastedValues }) => {
    if (!field) {
      return
    }
    const updatedData = cloneDeep(data)
    const item = updatedData.find((o) => o[indexCol] === dataItem[indexCol])
    item[field] = value
    setData(updatedData)
  }

  return (
    <TableContextProvider setData={setData}>
      <div className="kendo-performance-grid-container">
        <Grid
          onItemChange={itemChange}
          style={{ height: '800px' }}
          data={data}
          className="performance-grid"
          {...props}
        >
          <Column field={indexCol} title="Date" width={120} />
          <Column field={numberCol} title="Performance" cell={PercentageCell} />
        </Grid>
      </div>
    </TableContextProvider>
  )
}
