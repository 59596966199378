import React, { Component } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TrackingErrorTab, TotalExpenseTab } from "./";
import TextField from "@material-ui/core/TextField";

export class diagnosticsContainer extends Component {
  state = {
    current_view: "tracking_error",
    cell_name: "",
  };

  changeView = (e, new_view) => {
    this.setState({ current_view: new_view });
  };

  changeCellName = (e) => {
    this.setState({ cell_name: e.target.value });
  };

  renderTab = () => {
    const { current_view } = this.state;
    switch (current_view) {
      case "tracking_error":
        return <TrackingErrorTab />;
      case "total_expense":
        return <TotalExpenseTab />;
      default:
        break;
    }
  };

  render() {
    const { current_view, cell_name } = this.state;
    return (
      <React.Fragment>
        <div className="diagnostics-page">
          <Tabs
            value={current_view}
            onChange={this.changeView}
            indicatorColor="primary"
          >
            <Tab label="Tracking Error" value="tracking_error" />
            <Tab label="Total Expense Ratio" value="total_expense" />
          </Tabs>
          <div className="search-container">
            <TextField
              placeholder="Search for a fund"
              value={cell_name}
              onChange={this.changeCellName}
            />
          </div>
        </div>
        <div>{this.renderTab()}</div>
      </React.Fragment>
    );
  }
}

export default diagnosticsContainer;
