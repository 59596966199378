import axios from 'axios'
import { hostname } from '../constants'
import LocalStorageService from '../utils/localStorageService'
import { fetcher } from '../utils/fetcher'
import { get, post } from './api'

export const login = (data) => axios.post(`${hostname}/auth/login/`, data)

export const getMe = () => fetcher.get('/auth/user/me/')

export const logout = () => fetcher.post('/auth/logout/')

export const getUsers = () => get('/auth/user/')

export const updateUser = (payload) => post('/auth/user/', payload)

export const registerUser = (payload) => post('/api/users/', payload)

export const getBooklet = () => get('/api/v1/booklets/')

export const delBooklet = (id) => fetcher.delete(`/api/v1/booklets/${id}/`)

export const putBooklet = (data) => fetcher.put(`/api/v1/booklets/${data.id}/`, data)

export const postBooklet = (data) => post('/api/v1/booklets/', data)

export const getAvailableFundsOfBooklet = ({ date, bookletId }) => get(`/api/v1/booklets/${bookletId}/factsheet-availability?as-of-date=${date}/`)

export const getFundGroup = () => get('/api/v1/booklets/group-funds/')

export const requestNewPassword = (payload) => axios.post(`${hostname}/api/users/reset_password/`, payload)

export const confirmNewPassword = (payload) => axios.post(`${hostname}/api/users/reset_password_confirm/`, payload)

export const setNewPassword = (payload) => post('/api/users/set_password/', payload)

export const getOtpDevice = () => get('/auth/two-factor/get-device/')

export const otpCreateDevice = () => {
  return fetcher.get(`${hostname}/auth/two-factor/create-device/`)
}

export const otpConfirm = (data) => {
  const { passcode } = data
  return fetcher.post(`${hostname}/auth/two-factor/confirm/${passcode}/`)
}

export const otpEmergency = (data) => {
  const { emergency_code } = data
  return fetcher.post(`${hostname}/auth/emergency/login/${emergency_code}/`)
}

export const getOtpEmergencyTokens = () => get('/auth/emergency/create/')

export const deleteOtpDevice = (payload = {}) => post('/auth/two-factor/delete-device/', payload)
