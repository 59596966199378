import React, { useEffect, useState } from "react";
import { getCompanies, deleteCompany, updateCompanies } from "../../services";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined"
import { sortBy } from "lodash";
import { useAlert } from "react-alert";
import { useMemo } from "react";
import LoadingPanel from "../../components/misc/kendoLoader";

export default function Companies() {
  const [companyList, setCompanyList] = useState([]);
  const [newCompany, setNewCompany] = useState("");
  const [loading, setLoading] = useState(true)
  const alert = useAlert();

  const fetchCompanies = async () => {
    try {
      const response = await getCompanies();
      setCompanyList(sortBy(response.data, "name"));
    } catch (error) { }
    finally {
      setLoading(false)
    }
  };

  const createNewCompany = async () => {
    const originalCompanies = [...companyList];
    try {
      const response = await updateCompanies({ name: newCompany });
      setNewCompany("");
      setCompanyList([response.data, ...companyList]);
    } catch (error) {
      setCompanyList(originalCompanies);
    }
  };

  const deleteFromList = async (id) => {
    const originalCompanies = [...companyList];
    const newList = companyList.filter((o) => o.id !== id);
    setCompanyList(newList);
    try {
      await deleteCompany({ id });
    } catch (error) {
      alert.error("Some users are associated with this company");
      setCompanyList(originalCompanies);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const renderCompanyList = useMemo(() => (
    <List dense>
      {companyList.map((g) => (
        <ListItem
          key={g.id}
        >
          <ListItemText primary={g.name} secondary={`UID: ${g.id}`} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => deleteFromList(g.id)}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  ), [companyList])

  return (
    <div className="group-list-container">
      <div className="new-group-form">
        <TextField
          value={newCompany}
          onChange={(e) => setNewCompany(e.target.value)}
          placeholder="New Company"
        />
        <Button
          onClick={createNewCompany}
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </div>
      {
        loading &&
        <LoadingPanel />
      }
      {renderCompanyList}
    </div>
  );
}
