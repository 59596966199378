import React, { useState, useEffect, useCallback } from 'react'
import { process } from '@progress/kendo-data-query'
import { KendoLoader } from '../misc'
import { ExcelExport } from '@progress/kendo-react-excel-export'

export default function statefulTable(WrappedGrid, defaultPageSize) {
  return function StatefulGrid(props) {
    const {
      pageable,
      scrollable,
      pageSize,
      initial_state = {},
      aggregateFields = [],
    } = props

    const initialState = {
      skip: 0,
      take: pageSize || defaultPageSize,
      ...initial_state,
      ...(pageable === false && scrollable !== 'virtual' ? initial_state : {}),
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
      if (!state.take && pageSize) {
        setState((prevState) => ({ ...prevState, take: pageSize }))
      }
    }, [pageSize, state.take])

    const getState = useCallback(() => {
      let filtered_state = { ...state }
      let { group } = filtered_state
      if (group) {
        group.forEach((gr) => (gr.aggregates = aggregateFields))
      }
      return filtered_state
    }, [state, aggregateFields])

    const processData = useCallback(() => {
      let processed = process(props.data, getState())
      let { data } = processed || {}
      if (props.collapseAll) {
        data.forEach((row) => {
          row.expanded = false
          row.items?.forEach((item) => (item.expanded = false))
        })
      }
      if (props?.expandedFields?.length > 0) {
        props.expandedFields.forEach((expField) => {
          const { dataItem, expandField, value } = expField || {}
          let entry = data.find((o) => o.value === dataItem.value)
          if (entry) {
            entry[expandField] = value
          } else {
            data.forEach((sub_data) => {
              entry = sub_data.items?.find((o) => o.value === dataItem.value)
              if (entry) {
                entry[expandField] = value
              }
            })
          }
        })
      }
      return processed
    }, [props, props.data, getState])

    const processedData = processData()

    return (
      <>
        <ExcelExport data={processedData.data} ref={props.exportRef}>
          <WrappedGrid
            filterable={true}
            sortable={true}
            pageable={pageable}
            {...props}
            {...state}
            data={processedData}
            onDataStateChange={(e) => setState(e.dataState)}
          />
        </ExcelExport>
        {props.loading && <KendoLoader />}
      </>
    )
  }
}
