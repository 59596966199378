import React from "react";
import Grid from "@material-ui/core/Grid";
import { formatNumber } from "@telerik/kendo-intl";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import { meanSummary, tsLineChartData } from "../../data/temp";
HighchartsMore(Highcharts);

const MeanWithErrorChart = ({ chartData, title }) => {
  const { columns, data } = chartData || {};
  const [meanData, errorData] = data || [];

  let meansSeries = {
    name: "Annualised Return",
    type: "scatter",
    data: meanData,
    tooltip: {
      pointFormatter: function () {
        return `<span>${this.category}</span>: <b>${formatNumber(
          this.y,
          "p2"
        )}</b>`;
      },
    },
  };
  let errorSeries = {
    name: "Return Error",
    type: "errorbar",
    data: errorData,
  };

  const options = {
    title: {
      text: title,
    },
    xAxis: [
      {
        categories: columns,
      },
    ],
    yAxis: [
      {
        labels: {
          formatter: function () {
            return `<span>${formatNumber(this.value, "p0")}</span>`;
          },
        },
        title: {
          enabled: false,
        },
      },
    ],
    legend: { enabled: false },
    series: [meansSeries, errorSeries],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const LineChartWithCondifenceInterval = ({ chartData, title }) => {
  const { columns, data } = chartData || {};
  const [meanData, errorData] = data || [];

  let meansSeries = {
    name: "Annualised Return",
    data: meanData.map((o, idx) => [columns[idx], o]),
    tooltip: {
      pointFormatter: function () {
        return `<b>${formatNumber(this.y, "p2")}</b>`;
      },
    },
    zIndex: 1,
    marker: {
      fillColor: "white",
      lineWidth: 2,
      lineColor: Highcharts.getOptions().colors[0],
    },
  };
  let errorSeries = {
    name: "Return Error",
    data: errorData.map((o, idx) => [columns[idx], ...o]),
    type: "arearange",
    lineWidth: 0,
    linkedTo: ":previous",
    color: Highcharts.getOptions().colors[0],
    fillOpacity: 0.3,
    zIndex: 0,
    marker: {
      enabled: false,
    },
    tooltip: {
      pointFormatter: function () {
        return false;
      },
    },
  };

  const options = {
    title: {
      text: title,
    },
    xAxis: [
      {
        type: "datetime",
      },
    ],
    yAxis: [
      {
        labels: {
          formatter: function () {
            return `<span>${formatNumber(this.value, "p0")}</span>`;
          },
        },
        title: {
          enabled: false,
        },
      },
    ],
    legend: { enabled: false },
    series: [meansSeries, errorSeries],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default function Sandbox() {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <MeanWithErrorChart
            chartData={meanSummary}
            title="Annualised Returns Zebedee Core"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LineChartWithCondifenceInterval
            chartData={tsLineChartData}
            title="6M Annualised Returns Zebedee Core"
          />
        </Grid>
      </Grid>
    </div>
  );
}
