import axios from "axios"

import { hostname } from "../constants" // Replace with your actual hostname

const fetcher = axios.create({
  baseURL: hostname,
  headers: {
    "Content-Type": "application/json",
  }
})

export { fetcher }
