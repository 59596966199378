import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import moment from 'moment'
import qs from 'qs'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { TabContentContainer } from '../../components/misc/tab-content-container'
import { useSecurities } from '../../hooks'
import useAccessRights from '../../hooks/useAccessRights'
import { usePerformanceOverview } from '../../hooks/usePerformanceOverview'
import { getPerformanceOverview } from '../../services'
import {
  AaapOverview,
  AnalyticsOverview,
  NavOverview,
} from '../performanceOverview'

const Views = (props) => {
  const { currentView, internal } = props
  switch (currentView) {
    case 'AAAP':
      return (
        <TabContentContainer
          pageName="Performance Overview Page"
          tabName="AAAP Performance"
        >
          <AaapOverview {...props} />
        </TabContentContainer>
      )
    case 'NAV':
      return (
        <TabContentContainer
          pageName="Performance Overview Page"
          tabName="AAAP Navs"
        >
          <NavOverview {...props} />
        </TabContentContainer>
      )
    case 'FoF':
      if (internal) {
        return (
          <TabContentContainer
            pageName="Performance Overview Page"
            tabName="Analytics"
          >
            <AnalyticsOverview {...props} />
          </TabContentContainer>
        )
      }
      return <div />
    default:
      return null
  }
}

const currentYear = moment().year()
const yearList = [0, 1, 2, 3].map((x) => currentYear - x)

const today = moment()
const initialYear =
  today.month() === 0 && today.date() <= 15 ? yearList[1] : currentYear

export default function PerformanceOverview({ location }) {
  const { search } = location

  const [currentView, setCurrentView] = useState('AAAP')
  const [usedQueryParam, setUsedQueryParam] = useState(false)
  const [asOfYear, setAsOfYear] = useState(initialYear)

  const { search_list: searchList, webfolio_list: portfolios } = useSecurities()
  const { hasAccessInternalClient } = useAccessRights()

  const { data: performanceOverview, isLoading: loading } =
    usePerformanceOverview({ asOfYear, currentView })

  useEffect(() => {
    const query = qs.parse(search.replace('?', ''))
    const { view } = query || {}

    if (!usedQueryParam && view) {
      setCurrentView(view)
      setUsedQueryParam(true)
    }
  }, [search, usedQueryParam])

  const changeView = useCallback(
    (e, view) => {
      if (view !== currentView) {
        setCurrentView(view)
      }
    },
    [currentView],
  )

  const exportCsv = useCallback(async () => {
    const end_date = `${asOfYear}-12-31`
    const res = await getPerformanceOverview({
      end_date,
      export: true,
      mode: currentView,
    })
    return res.data
  }, [asOfYear, currentView])

  const applicableStrategies = useMemo(
    () => [...new Set(performanceOverview?.map((o) => o.strategy))].sort(),
    [performanceOverview],
  )

  return (
    <>
      <div className="dashboard-tabs">
        <Tabs
          indicatorColor="primary"
          onChange={changeView}
          value={currentView}
          variant="fullWidth"
        >
          <Tab disabled={loading} label="AAAP Performance" value="AAAP" />
          <Tab disabled={loading} label="AAAP Navs" value="NAV" />
          {hasAccessInternalClient && (
            <Tab disabled={loading} label="Analytics" value="FoF" />
          )}
        </Tabs>
      </div>
      <div>
        <Views
          asOfYear={asOfYear}
          currentView={currentView}
          fetchFunction={exportCsv}
          internal={hasAccessInternalClient}
          loading={loading}
          performance_overview={performanceOverview}
          portfolios={portfolios}
          search_list={searchList}
          setAsOfYear={setAsOfYear}
          strategy_list={applicableStrategies}
          yearList={yearList}
        />
      </div>
    </>
  )
}
