import React, { Component } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  ExposureMap,
  PerformanceUpdate,
  Funds,
  ExposureUpdate,
  BulkDocuments,
  BackfillUpdate,
  ExposureMonitor,
  GrossToNetConverter,
} from "../dataManagement";

export class dataManagement extends Component {
  state = {
    current_view: "funds",
  };

  changeView = (e, new_view) => {
    this.setState({ current_view: new_view });
  };

  renderTab = () => {
    const current_view = this.state.current_view;
    switch (current_view) {
      case "funds":
        return <Funds />;
      case "performance":
        return <PerformanceUpdate />;
      case "exposure_map":
        return <ExposureMap />;
      case "exposure_update":
        return <ExposureUpdate />;
      case "bulk_documents":
        return <BulkDocuments />;
      case "backfill":
        return <BackfillUpdate />;
      case "exposure_monitor":
        return <ExposureMonitor />;
      case "gross_to_net":
        return <GrossToNetConverter />;
      default:
        break;
    }
  };

  render() {
    const { current_view } = this.state;
    return (
      <React.Fragment>
        <div className="data-management-route">
          <Tabs
            value={current_view}
            variant="fullWidth"
            onChange={this.changeView}
            indicatorColor="primary"
          >
            <Tab label="Funds" value="funds" />
            <Tab label="Performance" value="performance" />
            <Tab label="Exposures" value="exposure_update" />
            <Tab label="Exposure Map" value="exposure_map" />
            <Tab label="Exposure Monitor" value="exposure_monitor" />
            <Tab label="Documents" value="bulk_documents" />
            <Tab label="Backfill" value="backfill" />
            <Tab label="Gross/Net Converter" value="gross_to_net" />
          </Tabs>
          {this.renderTab()}
        </div>
      </React.Fragment>
    );
  }
}

export default dataManagement;
