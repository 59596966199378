import React from "react"
import { GlobalSearch as Selector } from "../selectors"
import { useField, useFormikContext } from "formik"
import ErrorMessage from "./ErrorMessage"
import { Box } from "@material-ui/core"

export default ({ name, handleChange, wrapperProps, ...props }) => {
  const [field, meta] = useField(name)
  const { onChange } = field
  return (
    <Box {...wrapperProps}>
      <Selector
        {...field}
        {...props}
        onChange={(e) => {
          onChange(e)
          if (handleChange) {
            handleChange(e)
          }
        }}
      />
      {meta.touched && meta.error && <ErrorMessage error={meta.error} />}
    </Box>
  )
}

export const BulkSecurityList = ({ name, bulkArray, bulkField, ...props }) => {
  const { values, setValues } = useFormikContext()
  const value = values[name] || ""

  const bulkChange = (e, name) => {
    const { value: newValue } = e.target
    const { [bulkArray]: modArray } = values
    let newArray = [...modArray]

    for (let i = 0; i < newArray.length; i++) {
      let element = newArray[i]
      element[bulkField] = newValue
    }
    setValues({ ...values, [bulkArray]: newArray, [name]: newValue })
  }

  return (
    <Selector
      name={name}
      onChange={(e) => {
        bulkChange(e, name)
      }}
      value={value || ""}
      {...props}
    />
  )
}
