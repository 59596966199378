import React, { useState, useEffect } from "react";
import { GenericTable } from "../../components";
import { GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { dateTimeManager } from "../../utils";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { getMarketOverview } from "../../services";
import Typography from "@material-ui/core/Typography";
import MuiDatePicker from "../../components/forms/MuiDatePicker";

export default function IndexMonitor() {
  const [marketData, setMarketData] = useState({
    summary: [],
    stats_date: null,
  });
  const [overviewDate, setOverviewDate] = useState(new Date());

  useEffect(() => {
    const fetchOverview = async () => {
      try {
        const response = await getMarketOverview({
          end_date: dateTimeManager.jsToDateTimeDb(overviewDate),
        });
        setMarketData(response.data);
      } catch (error) { }
    };
    fetchOverview();
  }, [overviewDate]);

  const state_vars = {
    filter: undefined,
    group: [{ field: "group" }],
    skip: 0,
    sort: undefined,
    take: undefined,
  };

  const asOfDate = dateTimeManager.dbToLongFormat(marketData.stats_date);

  return (
    <div className="index-monitor">
      <GenericTable
        data={marketData.summary}
        index_col="monitor_display_name"
        groupable={false}
        initial_state={state_vars}
        // className="monitor-table"
        custom_cols={true}
        scrollable="scrollable"
        style={{ height: "70vh" }}
        negative_red
      >
        <GridToolbar>
          <div style={{ display: "flex", alignItems: "center" }}>
            <MuiDatePicker
              value={overviewDate}
              onChange={(newDate) => {
                setOverviewDate(newDate);
              }}
              format={"dd MMM yy"}
              disableToolbar={false}
            />
            <Typography style={{ marginLeft: "32px" }}>
              Data as of {asOfDate}{" "}
            </Typography>
          </div>
        </GridToolbar>
        <Column field="1DCh" />
        <Column field="WTD" />
        <Column field="MTD" />
        <Column field="QTD" />
        <Column field="YTD" />
      </GenericTable>
    </div>
  );
}
