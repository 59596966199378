import { Box } from '@material-ui/core'
import { Resizable } from 're-resizable'
import React, { useMemo } from 'react'

import GenericTable from '../../components/tables/genericTable'
import formatDate from '../../utils/formatDate'
import PortOptResultSection from './layout/PortOptResultSection'
import { usePortfolioOptimiser } from './RefactoredPortfolioOptimiserProvider'

export const preferredOrder = ['Optimal', 'Suggested', 'PyPortfolioOpt']

const PortOptStats = () => {
  const { runData } = usePortfolioOptimiser()
  const statsTableData = runData?.result?.stats?.statsTable
  const defaultTableWidth = useMemo(() => {
    if (!statsTableData?.[0]) return 1200

    const width = Object.keys(statsTableData[0]).length - 1 * 220

    return width < 900 ? 900 : width
  }, [statsTableData])
  const orderedKeyStatsTableData = useMemo(() => {
    if (!statsTableData) return null

    const mapped = statsTableData.map((row) => {
      const orderedRow = {}

      preferredOrder.forEach((key) => {
        if (row.hasOwnProperty(key)) {
          orderedRow[key] = row[key]
        }
      })

      Object.keys(row).forEach((key) => {
        if (!preferredOrder.includes(key)) {
          orderedRow[key] = row[key]
        }
      })

      return orderedRow
    })

    return mapped
  }, [statsTableData])

  return orderedKeyStatsTableData ? (
    <Box
      display="flex"
      justifyContent="center"
    >
      <Resizable
        defaultSize={{ width: defaultTableWidth }}
        resizeRatio={2}
      >
        <PortOptResultSection
          date={`${formatDate(runData?.result?.stats?.startDate)} - ${formatDate(runData?.result?.stats?.endDate)}`}
          disableMaxWidth
          header="Statistics Table"
          runData={runData}
        >
          <GenericTable
            data={orderedKeyStatsTableData || []}
            scrollable="scrollable"
          />
        </PortOptResultSection>
      </Resizable>
    </Box>
  ) : null
}

export default PortOptStats
