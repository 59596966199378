import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { useMemo } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';

export interface MuiDatePickerProps extends KeyboardDatePickerProps { }

const MuiDatePicker: React.FC<MuiDatePickerProps> = (props) => {
  const views = useMemo(() => {
    if (props.format === "MMM yyyy" || props.format === "MMM yy") {
      return ["year", "month"];
    }
    return ["year", "month", "date"];
  }, [props.format])

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
    >
      <KeyboardDatePicker
        disableToolbar
        format="MM/dd/yyyy"
        margin="normal"
        variant='inline'
        autoOk
        views={views}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

export default MuiDatePicker
