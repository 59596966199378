import React from "react";
import { LineTimeSeries } from "../../components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default function AumReport({
  exposure_analysis,
  exposure_analysis_input,
}) {
  const { report } = exposure_analysis || {};
  const { fund } = exposure_analysis_input || {};
  const { name: fund_name } = fund || {};
  if (report.length === 0) {
    return (
      <div>
        <Typography align="center">No AUM data is available</Typography>
      </div>
    );
  }
  return (
    <div>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} xl={6}>
          <LineTimeSeries
            data={report}
            title={`${fund_name} AUM Evolution`}
            category_field="value_date"
            format="{0:n0}"
          />
        </Grid>
      </Grid>
    </div>
  );
}
