import { Landing, Login, PasswordReset } from "../containers/routes"

export default [
  {
    component: Landing,
    navPath: true,
    path: "/home",
    title: "AAAP Select",
  },
  {
    component: PasswordReset,
    navPath: true,
    path: "/confirm-password/:userId/:token",
    title: "Confirm Password",
  },
  {
    component: Login,
    navPath: true,
    path: "/",
    title: "Sign-In",
  }
]
