import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import mixpanel from 'mixpanel-browser'
import PropTypes from 'prop-types'
import { Component } from 'react'

import router from '../../resources/history'
import { extractSeriesNames } from '../../utils'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    maxWidth: 500,
  },
}))(Tooltip)

export default class plainTable extends Component {
  static propTypes = {
    clickFunc: PropTypes.func,
    data: PropTypes.array,
    id_field: PropTypes.string,
    tableName: PropTypes.string,
  }

  onRowClick = (row) => {
    const { clickFunc } = this.props || []
    if (clickFunc) {
      clickFunc(row)
    }
  }

  renderRow = (row, id, headers, class_name, has_link, isLoading) => {
    return (
      <TableRow
        className={clsx({ linked: !row.disabledClick })}
        key={id}
        onClick={() => {
          if (!row.disabledClick) {
            this.onRowClick(row)
          }
        }}
      >
        {headers.map((h, id) => {
          const data = row[h]
          if (typeof data === 'object' && data !== null) {
            return data.element
          } else if (isLoading) {
            return (
              <TableCell key={id} className={class_name}>
                <Skeleton variant="text" />
              </TableCell>
            )
          } else if (id === 0 && has_link) {
            return (
              <TableCell
                className={class_name}
                key={id}
                onClick={() => {
                  if (this.props.tableName) {
                    mixpanel.track('Click Plain Table Row', {
                      Item:
                        row?.Name || Object.keys(row).map((key) => row[key]),
                      Table: this.props.tableName,
                    })
                  }
                  if (!row.disabledClick) {
                    router.push(row.link)
                  }
                }}
              >
                {data}
              </TableCell>
            )
          } else {
            return (
              <TableCell className={class_name} key={id}>
                {data}
              </TableCell>
            )
          }
        })}
      </TableRow>
    )
  }

  render() {
    const { isLoading, tableName, ...otherProps } = this.props || {} // excluding invalid props when passed to TableContainer
    const { clickFunc, data, id_field, tooltip_field } = otherProps
    const headers = extractSeriesNames(data)
    const link_idx = headers.indexOf('link')
    const has_link = link_idx !== -1
    if (has_link) {
      headers.splice(link_idx, 1)
    }
    if (id_field) {
      headers.splice(headers.indexOf(id_field), 1)
    }
    if (tooltip_field) {
      headers.splice(headers.indexOf(tooltip_field), 1)
    }
    const class_name = ''
    return (
      <>
        {
          <TableContainer className="plain-table-container" {...otherProps}>
            <Table
              aria-label="a dense sticky table"
              className="plain-table"
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {headers.map((h, id) => (
                    <TableCell key={id}>{h}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, id) =>
                  tooltip_field ? (
                    <HtmlTooltip
                      arrow
                      disableFocusListener
                      disableTouchListener
                      key={id}
                      title={
                        <>
                          <Typography color="inherit">
                            {row[tooltip_field]}
                          </Typography>
                        </>
                      }
                    >
                      {this.renderRow(
                        row,
                        id,
                        headers,
                        class_name,
                        has_link,
                        isLoading,
                      )}
                    </HtmlTooltip>
                  ) : (
                    this.renderRow(
                      row,
                      id,
                      headers,
                      class_name,
                      has_link,
                      isLoading,
                    )
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </>
    )
  }
}
