import { GridColumn as Column, Grid as KGrid } from '@progress/kendo-react-grid'
import { withResizeDetector } from 'react-resize-detector'
import { statefulTableGenerator } from '../../components/tables'
import { extractSeriesNames } from '../../utils/extractSeriesNames'
import { CustomCell } from '../misc'
import SkeletonTableLoader from '../skeletons/SkelatonPerformanceTableLoader'

const TableGrid = statefulTableGenerator(KGrid)

const GenericTable = (props) => {
  const {
    index_col = 'index',
    table_name = ' ',
    index_width = 'auto',
    index_auto = false,
    total_row = false,
    total_col = false,
    show_years = false,
    highlight_name = false,
    blue_heading = false,
    scrollable = 'none',
    lock_index = false,
    noAltColor = false,
    custom_cols = false,
    index_col_props = {},
    data,
    loading,
    width,
    containerWidth,
    children,
    equal_width,
    fixed_width,
    correlation,
    negative_red,
    ...more_props
  } = props

  const getIndexColWidth = () => {
    return Number(index_width.replace('px', ''))
  }

  const generateColumnList = () => {
    const col_names = extractSeriesNames(data)
    const col_width = fixed_width
      ? fixed_width
      : (width - getIndexColWidth()) / col_names.length
    return col_names.map((name, idx) => (
      <Column
        key={idx}
        field={name}
        title={name}
        width={equal_width ? col_width : 'auto'}
        cell={(cellProps) => (
          <CustomCell
            {...cellProps}
            table_name={table_name}
            correlation={correlation}
            negative_red={negative_red}
          />
        )}
      />
    ))
  }

  const total_row_cx = total_row ? 'total-row' : ''
  const total_col_cx = total_col ? 'total-col' : ''
  const highlight_name_cx = highlight_name ? 'highlight-name' : ''
  const blue_heading_cx = blue_heading ? 'blue-heading' : ''
  const alt_color_cx = noAltColor ? '' : 'alt-color'
  const show_years_cx = show_years ? 'summary-years' : ''

  return (
    <div className={`generic-table-container`}>
      {loading && (!data || data?.length < 1) ? (
        <>
          {children}
          <SkeletonTableLoader />
        </>
      ) : (
        <TableGrid
          className={`generic-table ${total_row_cx} ${total_col_cx} ${highlight_name_cx} ${blue_heading_cx} ${alt_color_cx} ${show_years_cx}`}
          filterable={false}
          sortable={false}
          pageable={false}
          scrollable={scrollable}
          containerWidth={containerWidth}
          data={data || []}
          {...more_props}
        >
          <Column
            filterable={false}
            title={table_name}
            field={index_col}
            width={index_auto ? 'auto' : index_width}
            locked={lock_index}
            {...index_col_props}
          />
          {!custom_cols && generateColumnList()}
          {children}
        </TableGrid>
      )}
    </div>
  )
}

export default withResizeDetector(GenericTable)
