import {
  ActionIcon,
  Box,
  Checkbox,
  Group,
  Indicator,
  Loader,
  Stack,
  Text
} from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { IconCheck, IconDownload } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import useAccessRights from '../../hooks/useAccessRights'
import { useMixpanel } from '../../hooks/useMixpanel'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import useUpdateDocsNotification from '../../hooks/useUpdateDocsNotification'
import {
  docsNotificationSelectToggled,
  notificationDocsDownloaded,
  selectDocsNotificationById,
  selectedDocsNotiDismissed
} from '../../redux/docsNotificationSlice'
import { downloadAaapDocument, downloadPeDocsByDocId } from '../../services'
import { buildFileFromResponse } from '../../utils'

interface DocsNotificationItemProps {
  notificationId: number
  refetch: () => void
  viewStatus: string
  aaapIdMap: Record<number, any>
  peIdMap: Record<number, number>
}

const DocsNotificationItem: React.FC<DocsNotificationItemProps> = ({
  notificationId,
  refetch,
  aaapIdMap,
  peIdMap
}) => {
  const { mpTrack } = useMixpanel()
  const dispatch = useAppDispatch()
  const {
    isUpdatingDocsNotification,
    setNotificationToDownloaded,
    setNotificationToReadStatus,
  } = useUpdateDocsNotification()
  const { hasAccessInternalClient } = useAccessRights()
  const notification = useAppSelector(state => selectDocsNotificationById(state, notificationId))
  const selected = useAppSelector(state => state.docsNotification.selectedDocsNotifications[notificationId])
  const { isFetching: downloadingDocs, refetch: downloadDocs } = useQuery(
    ['notification', 'document', 'download', notificationId],
    async () => {
      if (notification.storyType.toLowerCase().includes('apf')) {
        return downloadPeDocsByDocId(notificationId)
      } else {
        return downloadAaapDocument({ id: notificationId })
      }
    },
    { enabled: false }
  )

  const isLoading = isUpdatingDocsNotification || downloadingDocs

  const itemLink = useMemo(() => {
    let basePath
    let params = new URLSearchParams()
    let tabType

    if (notification.storyType === 'AAAP Factsheet') {
      return `/fact-sheets`
    }

    if (hasAccessInternalClient) {
      basePath = '/document-management'
      params.append('fund', String(notification.securityId))
      params.append('type', notification.storyType)
      params.append('date', String(notification.asOfDate))
    } else {
      if (notification.storyType.toLowerCase().includes('apf')) {
        const securityId = peIdMap?.[notification.securityId] || notification.securityId
        basePath = `/private-markets/funds/${securityId}`
        tabType = 'Documents'
      } else {
        const securityId = aaapIdMap?.[notification.securityId]?.[0] || notification.securityId
        basePath = `/asset-detail/${securityId}`
        const aaapDocsTabStoryTypes = ['aaap', 'fof']

        for (const storyType of aaapDocsTabStoryTypes) {
          if (notification.storyType.toLowerCase().includes(storyType)) {
            tabType = 'aaap_documents'
            break
          }
        }
      }

      params.append('tab', tabType || 'documents')
    }

    return `${basePath}${params.toString() ? '?' + params.toString() : ''}`
  }, [hasAccessInternalClient, notification, aaapIdMap, peIdMap])

  const clickReadButtonHandler = async (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    await setNotificationToReadStatus(notification.id, !notification.read)
    mpTrack({
      eventName: `Document Notification Read Status Changed`,
      properties: {
        Read: !notification.read,
        id: notification.id
      }
    })
    dispatch(selectedDocsNotiDismissed())

    refetch()
  }

  const onClickCheckboxHandler = () => {
    dispatch(docsNotificationSelectToggled({ id: notification.id }))
  }

  const clickNotificationHandler = () => {
    mpTrack({
      eventName: 'Document Notification Clicked',
      properties: {
        ...notification
      }
    })
  }

  const downloadFromLink = async (downloadLink: string) => {
    try {
      // Create a temporary anchor element
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = downloadLink;

      // Append to the document, click, and remove
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      return { isSuccess: true };
    } catch (error) {
      console.error('Error initiating download from S3:', error);
      return { isSuccess: false, error: error.message };
    }
  };

  const clickDownloadIconButtonHandler = async (event) => {
    try {
      event.stopPropagation()
      event.preventDefault()

      let success = false

      if (notification.attachment) {
        await downloadFromLink(notification.attachment)
        success = true
      } else {
        const response = await downloadDocs()
        success = response.isSuccess
        if (success) {
          await buildFileFromResponse(response.data)
        }
      }

      if (success) {
        await setNotificationToDownloaded(notification.id)
        dispatch(notificationDocsDownloaded(notification.id))
        notifications.show({
          message: notification.name,
          title: 'Document Downloaded',
        })
        refetch()
      }
    } catch (err) {
      notifications.show({
        message: 'Failed to download document',
        title: 'Error',
      })
    }
  }

  return (
    <Box
      className="notification-list-item"
      component="ul"
      key={notification.id}
      w="100%"
    >
      <Checkbox
        checked={selected}
        mr="xs"
        onChange={onClickCheckboxHandler}
      />
      <Group
        className="notification-list-item-link"
        component={Link}
        justify='space-between'
        onClick={clickNotificationHandler}
        to={itemLink}
        width="100%"
      >
        <Group pl="xs" maw="65%">
          <Stack gap={0}>
            <Indicator
              color="var(--mantine-color-skyBlue)"
              disabled={notification.read}
              ml="sm"
              offset={-12}
              position="middle-start"
              size={8}
            >
              <Text
                color="gray"
                size="xs"
              >
                {notification.securityName}
              </Text>
            </Indicator>
            <Indicator
              color="var(--mantine-color-skyBlue)"
              disabled
              ml="sm"
              offset={-12}
              position="middle-start"
              size={8}
            >
              <Text size="sm">
                {notification.name}
              </Text>
            </Indicator>
          </Stack>
        </Group>
        <Group style={{ flexWrap: 'nowrap' }}>
          <Text
            ml="xl"
            size="xs"
            style={{ textAlign: 'right', flex: '1 0 0' }}
          >
            {notification.storyType}
          </Text>
          <Group
            justify='flex-end'
            w="140px"
          >
            {
              !isLoading ?
                <>
                  <Group>
                    <Text
                      className="notification-list-item-time"
                      component="time"
                      size="xs"
                    >
                      {moment(notification.asOfDate).fromNow()}
                    </Text>
                  </Group>
                  <ActionIcon
                    className="notification-list-item-download-btn"
                    onClick={clickDownloadIconButtonHandler}
                    size="sm"
                    variant="transparent"
                  >
                    <IconDownload />
                  </ActionIcon>
                  <ActionIcon
                    className="notification-list-item-dismiss-btn"
                    onClick={clickReadButtonHandler}
                    size="sm"
                    variant="transparent"
                  >
                    <IconCheck />
                  </ActionIcon>
                </>
                : <Loader
                  mr={1}
                  size="xs"
                />
            }
          </Group>
        </Group>
      </Group>
    </Box >
  )
}

export default DocsNotificationItem