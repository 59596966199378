import { Button, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useMixpanel } from '../../hooks/useMixpanel'
interface DashboardBlockProps {
  viewAllLink?: string
  header?: string | React.ReactNode
  viewAllMpPayload?: any;
}

const DashboardBlock: React.FC<DashboardBlockProps & React.PropsWithChildren> = ({
  children,
  viewAllLink,
  header,
  viewAllMpPayload
}) => {
  const { mpTrack } = useMixpanel()

  const clickViewAllHandler = () => {
    if (viewAllMpPayload) {
      mpTrack(viewAllMpPayload)
    }
  }

  return (
    <div className="insights-card">
      <div className="insights-card-title">
        {
          header && typeof header === 'string' ? <Typography variant="h6">{header}</Typography> : header
        }
        {
          viewAllLink && (
            <Button
              className="view-all-button"
              color="primary"
              component={Link}
              endIcon={<ChevronRightIcon />}
              to={viewAllLink}
              onClick={clickViewAllHandler}
            >
              View All
            </Button>
          )
        }
      </div>
      <div className="insights-items-wrapper">
        {children}
      </div>
    </div>
  )
}

export default DashboardBlock
