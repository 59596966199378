import axios from "axios"

import { HUBSPOT_BLOG_POST_ENDPOINT, HUBSPOT_PROXY_URL, IS_PROD_ENV } from "../constants/env"
import { SecurityType } from "../models/SecurityTypes"

export enum BlogType {
  FUND_FAQS = 'fund-faqs',
  GLOBAL_FUND_FAQS = 'funds-faq-global',
  PE_FUND_FAQS = 'funds-faq-pe',
  FUND_SUMMARY = 'fund-summary'
}

export const fetchHubSpotFaqs = async (blogType: BlogType, blogSlug?: string | number) => {
  const res = await axios.post(
    HUBSPOT_PROXY_URL,
    {
      method: 'GET',
      url: `${HUBSPOT_BLOG_POST_ENDPOINT}?slug=${blogType}/${blogSlug}${IS_PROD_ENV ? '&state=PUBLISHED' : ''}`
    }
  )

  return res?.data?.results
}

export const FundTypeGlobalFaqHeaderMap = {
  [SecurityType.AAAP_CELL]: "AAAP",
  [SecurityType.FOF]: "FOF",
  [SecurityType.PRIVATE_EQUITY]: "Private Markets",
}

export const SecurityTypeToBlogSlugMap: Partial<Record<SecurityType, string>> = {
  [SecurityType.AAAP_CELL]: 'aaap',
  [SecurityType.FOF]: 'fof',
  [SecurityType.PRIVATE_EQUITY]: 'private-equity'
}

export const SecurityTypeWithFaqs = new Set([SecurityType.AAAP_CELL, SecurityType.FOF, SecurityType.PRIVATE_EQUITY, SecurityType.WEBFOLIO])
