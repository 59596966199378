import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export default function SwitchContol({
  checked,
  handleChange,
  label,
  color,
  name,
}) {
  const changeState = ({ target }) => {
    const { checked } = target || {};
    const payload = { target: { name, value: checked } };
    handleChange(payload);
  };
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={changeState}
            name={name}
            color={color}
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
