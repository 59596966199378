import React from "react";
import { KendoFlatSelector as Selector } from "../selectors";
import { useField, useFormikContext } from "formik";
import ErrorMessage from "./ErrorMessage";

export default ({ name, ...props }) => {
  const [field, meta] = useField(name);
  return (
    <>
      <Selector {...field} {...props} />
      {meta.touched && meta.error && <ErrorMessage error={meta.error} />}
    </>
  );
};

export const BulkComboBox = ({ name, bulkArray, bulkField, ...props }) => {
  const { values, setValues } = useFormikContext();
  const value = values[name] || "";

  const bulkChange = (e, name) => {
    const { value: newValue } = e.target;
    const { [bulkArray]: modArray } = values;
    let newArray = [...modArray];

    for (let i = 0; i < newArray.length; i++) {
      let element = newArray[i];
      element[bulkField] = newValue;
    }
    setValues({ ...values, [bulkArray]: newArray, [name]: newValue });
  };

  return (
    <Selector
      name={name}
      value={value || ""}
      onChange={(e) => {
        bulkChange(e, name);
      }}
      {...props}
    />
  );
};
