import React from "react";
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

// import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function kendoTextFilter(defaultItem) {
  return class extends React.Component {
    render() {
      return (
        <div className="k-filtercell">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            onChange={this.onChange}
            value={this.props.value || ""}
            InputProps={{
              classes: { input: "search-field-input" },
              endAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </div>
      );
    }

    hasValue = value => Boolean(value && value !== defaultItem);

    onChange = event => {
      const hasValue = this.hasValue(event.target.value);
      this.props.onChange({
        value: hasValue ? event.target.value : "",
        operator: hasValue ? "contains" : "",
        syntheticEvent: event.syntheticEvent
      });
    };
  };
}
