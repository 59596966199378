import React, { Component } from "react";
import { GenericTable } from "../../components";
import Grid from "@material-ui/core/Grid";

export default class correlation extends Component {
  getTable = (name, width = 200, more_props) => {
    const { internal_analysis } = this.props;
    const { report } = internal_analysis || {};
    return (
      <GenericTable
        table_name={name}
        data={report[name]}
        style={{ maxHeight: "800px" }}
        index_width={`${width}px`}
        scrollable="scrollable"
        correlation
        {...more_props}
      />
    );
  };
  render() {
    const tables = [
      "Simple Correlation",
      "Up Correlation",
      "Down Correlation",
      "Extreme Correlation",
    ];
    const { getTable } = this;
    const { internal_analysis, narrow } = this.props;
    const { report } = internal_analysis || {};
    return (
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} lg={narrow || 6}>
          <GenericTable
            table_name={"Funds"}
            data={report["Funds"]}
            index_width="400px"
          />
        </Grid>
        {tables.map((table, id) => (
          <Grid item xs={12} xl={narrow || 8} lg={narrow || 10} key={id}>
            {getTable(table, 250)}
          </Grid>
        ))}
      </Grid>
    );
  }
}
