import React from 'react'
import {
  DateInput,
  DaysBeforeInput,
  IsFirstDateCheckbox,
  NumberOfPeriodsInput,
  SubscriptionFrequencySelect
} from './TradingCalendarFormInputs'
import { Box } from '@material-ui/core'

const TradingCalendarByReportPeriodForm = () => (
  <>
    <Box
      display="flex"
      gridGap={8}
    >
      <SubscriptionFrequencySelect />
      <DateInput />
      <DaysBeforeInput />
    </Box>
    <NumberOfPeriodsInput />
    <IsFirstDateCheckbox />
  </>
)

export default TradingCalendarByReportPeriodForm
