import React, { useState } from 'react'
import { GridColumn, Grid, GridToolbar } from '@progress/kendo-react-grid'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import ActionCell from '../researchManagement/actionCell'
import { deleteDocument } from '../../services'
import DeleteDialog from '../researchManagement/deleteDialog'
import { useEffect } from 'react'
import { DocumentGrid } from '../researchManagement'
import { Card } from '@material-ui/core'

const DocumentsTable = ({
  documentData,
  security_details,
  hasInternalAccess,
}) => {
  const { id, name } = security_details || {}
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [documents, setDocuments] = useState([])
  const [documentTypes, setDocumentTypes] = useState([])
  const [documentType, setDocumentType] = useState('')
  const [item, setItem] = useState(null)

  const getDocumentTypes = (documentData) => {
    const documentTypes = ['All']
    Object.values(documentData).forEach((document) => {
      const documentType = document.story_type.name
      if (!documentTypes.includes(documentType)) {
        documentTypes.push(documentType)
      }
    })
    return documentTypes
  }

  useEffect(() => {
    if (documentData !== undefined) {
      const docuTypes = getDocumentTypes(documentData)
      setDocumentTypes(docuTypes)
      const documents = Object.values(documentData)
      setDocuments(documents)

      if (documentType !== '' && documentType !== 'All') {
        const filteredDocuments = documents.filter(
          (document) => document.story_type.name === documentType,
        )
        setDocuments(filteredDocuments)
      }
    }
  }, [documentData, documentType])

  const renderDateCell = (props) => {
    const { dataItem } = props
    const date = new Date(dataItem.as_of_date)
    const formattedDate = Intl.DateTimeFormat().format(date)
    return <td>{formattedDate}</td>
  }

  const renderUploadedByCell = (props) => {
    const { dataItem } = props
    return (
      <td>{`${dataItem.uploaded_by.first_name} ${dataItem.uploaded_by.last_name}`}</td>
    )
  }

  const openDeleteDialog = (dataItem) => {
    setItem(dataItem)
    setDeleteDialog(true)
  }

  const closeDialog = () => {
    setItem(null)
    setDeleteDialog(false)
  }

  const handleDelete = async (dataItem, documentData) => {
    const { id } = dataItem
    let originalList = documentData
    let updatedList = originalList.filter((doc) => doc.id !== id)
    setDocuments(updatedList)
    try {
      await deleteDocument({ id })
    } catch (error) {
      setDocuments(originalList)
    }
  }

  const handleOnChange = (event) => {
    setDocumentType(event.value)
  }

  const renderGrid = (documentData) => {
    if (documentData) {
      return (
        <>
          <Grid data={documents} className="rms-grid">
            <GridToolbar>
              <DropDownList
                data={documentTypes}
                onChange={handleOnChange}
                defaultValue="Select"
              />
            </GridToolbar>
            <GridColumn field="name" title="Fund" />
            <GridColumn field="story_type.name" title="Type" />
            <GridColumn field="as_of_date" title="Date" cell={renderDateCell} />
            {hasInternalAccess ? (
              <GridColumn
                field="uploaded_by"
                title="Uploaded_by"
                cell={renderUploadedByCell}
              />
            ) : null}
            <GridColumn
              field="attachment"
              title="Actions"
              cell={(props) => (
                <ActionCell
                  openDeleteDialog={openDeleteDialog}
                  openNotifyDialog={() => { }}
                  isPrivateEquity={true}
                  {...props}
                />
              )}
            />
          </Grid>
          <DeleteDialog
            open={deleteDialog}
            onClose={closeDialog}
            item={item}
            handleDelete={() => {
              closeDialog()
              handleDelete(item)
            }}
          />
        </>
      )
    } else {
      return (
        <DocumentGrid
          fixedFilters={{ security: id }}
          fixedSecurity={{ id, name }}
          showSelectedSecurity={false}
          isPrivateEquity={false}
        />
      )
    }
  }

  return (
    <>
      <div className="documents-container">
        <Card>{renderGrid(documentData)}</Card>
      </div>
    </>
  )
}

export default DocumentsTable
